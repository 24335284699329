import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService,
  MSAL_GUARD_CONFIG,
} from '@azure/msal-angular';
import { InteractionType, RedirectRequest } from '@azure/msal-browser';
import { Client } from '@microsoft/microsoft-graph-client';
import { User } from '@shared/Interface/user';
import { environment } from 'environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  /** Esta clase maneja lo relacionado con el B2C */

  baseUrl = environment.apiUrl;
  private currentUserSource = new ReplaySubject<User>(1);
  currenUser$ = this.currentUserSource.asObservable();
  private graphClient: Client;
  private interactionType = InteractionType.Redirect;
  private islogged: boolean = false;

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private http: HttpClient,
    private msalService: MsalService,
    private publicClientApplication: MsalService,
    private cookieService: CookieService
  ) {}

  login() {
    try {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginRedirect({
          ...this.msalGuardConfig.authRequest,
        } as RedirectRequest);
      } else {
        this.authService.loginRedirect();
      }
    } catch (error) {
      console.log('Mierror',error);
    }
  }

  isLoggeed(): boolean {
    this.islogged = false;
    let accounts = this.authService.instance.getAllAccounts();
    if (accounts) {
      this.authService.instance.setActiveAccount(accounts[0]);
      let user = this.msalService.instance.getActiveAccount();
      if (user) {
        this.islogged = true;
      }
    }
    return this.islogged;
  }

  async setCurrenUser(): Promise<void> {
    let activeAccount = this.authService.instance.getActiveAccount();
    console.warn('activo', activeAccount);
    if (!activeAccount) {
      this.islogged = true;
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
      let user = this.msalService.instance.getActiveAccount();
      localStorage.setItem('b2c', JSON.stringify(user));
    } else {
      localStorage.setItem('b2c', JSON.stringify(activeAccount));
    }
  }

  async getCurrentUserEmail() {
    await this.setCurrenUser();
    let user = JSON.parse(localStorage.getItem('b2c') ?? '{}');
    let email = '';
    if (user) {
      email = user.username;
    }
    return email;
  }

  logout(): void {
    this.islogged = false;
    this.msalService.logout();
    this.cookieService.deleteAll();
    localStorage.clear();
    sessionStorage.clear();
  }

  getDecodedToken(token: string) {
    return JSON.parse(atob(token.split('.')[1]));
  }
}
