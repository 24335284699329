
<div id="c_sl_pa" class="sec_cnt tipo-10 mt-4">
  <div class="d-none d-md-block">

    <div class="cont_tit d-flex">
      <div class="flex-fill col-1">{{ "mis_retos.fechaRegistro" | translate }}</div>
      <div class="flex-fill col-2">{{ "mis_retos.nombre_reto" | translate }}</div>
      <div class="flex-fill col-2">{{ "mis_retos.contacto" | translate }}</div>
      <div class="flex-fill col-1">{{ "mis_retos.duracion" | translate }}</div>
      <div class="flex-fill col-1">{{ "mis_retos.periodo_semanal" | translate }}</div>
      <div class="flex-fill col-1">{{ "mis_retos.periodo_semestral" | translate }}</div>
      <div class="flex-fill col-1">{{ "mis_retos.campus" | translate }}</div>
      <div class="flex-fill col-1">{{ "mis_retos.acciones" | translate }}</div>
      <div class="flex-fill d-none">{{ "mis_retos.avance" | translate }}</div>
      
    </div>

    <div class="roundedsquare cont_fl d-flex" *ngFor="let reto of retosActualesSFList">
      <div class="flex-fill col-1">
        {{ reto.fechaRegistro | date: 'dd/MMM/yyyy'}}
      </div>
      
      <div class="flex-fill col-2">
        <a href="/socio/pagina-retos-resumen?reto={{reto.idReto}}">{{ reto.reto }}</a>
        <div>{{ reto.escuela }}</div>
      </div>
      
      <div class="flex-fill col-2">
        {{ reto.contacto }}<br/>{{reto.correoContacto}}
      </div>

      <div class="flex-fill col-1">
        {{ reto.duracionSemanas }}
      </div>
      
      <div class="flex-fill col-1">
        {{ reto.periodoSemanal }}
      </div>

      <div class="flex-fill col-1">
        {{ reto.periodoSemestral }}
      </div>
      
      <div class="flex-fill col-1">
        {{ reto.campusCoordinador }}
      </div>

      <div class="flex-fill col-1">
        <app-button (callbackAction)="verDetalleReto(reto.idReto)">Ver</app-button>
      </div>
      
      <div class="flex-fill d-none">
        <div class="w-100 ">
          {{ reto.avanceSemanas +' de '+ reto. duracionSemanas}}
          <mat-progress-bar mode="determinate" [appProgressBarColor]="reto.porcentajeAvance == 100 ? '#4c964d' : '#93bcfd' "
                  [value]="reto.porcentajeAvance">
              </mat-progress-bar>
        </div>
      </div>
    </div>

    <div *ngIf="retosActualesSFList.length === 0">
      <app-sin-datos-table></app-sin-datos-table>
    </div>

  </div>

  <nav id="pagination" class="d-none d-md-block mt-4" *ngIf="totalPaginas > 0">
    <ul class="pagination justify-content-center">
      <li class="page-item" [class.disabled]="paginaActual==1" >
        <a class="page-link" (click) ="obtenerRegistrosActuales(paginaActual - 1)">{{ "paginador.anterior" | translate }} <img class="next_previous" alt="Anterior"
            src="assets/img/icon pag_ant.svg"></a>
      </li>
      <li class="page-item" *ngFor="let num of pages" [class.active]="paginaActual==num">
        <a class="page-link"  (click) ="obtenerRegistrosActuales(num)" >{{num}}</a>
      </li>
      <li class="page-item" [class.disabled]="paginaActual >=totalPaginas" >
        <a class="page-link" (click) ="obtenerRegistrosActuales(paginaActual + 1)" ><img class="next_previous" alt="Anterior" src="assets/img/icon pag_sig.svg"> {{ "paginador.siguiente" |
          translate }}</a>
      </li>
    </ul>
    <div class="text-center">{{ "paginador.total_paginador" | translate: {paginaActual: paginaActual, totalPaginas: totalPaginas } }}</div>
  </nav>


  <div class="d-block d-md-none">
    <div class="accordion" id="ac_1">
      <div class="accordion-item" *ngFor="let reto of retosActualesMovilSFList">
        <h2 class="accordion-header">
          <button class="accordion-button" type="button" data-bs-toggle="collapse"
            [attr.data-bs-target]="'#flush-collapse'+reto.idReto" aria-expanded="true">
            {{ reto.reto }}
          </button>
        </h2>
        <div id="flush-collapse{{reto.idReto}}" class="accordion-collapse collapse show" data-bs-parent="#ac_1">
          <div class="accordion-body">
            <strong>{{ "mis_retos.campus" | translate }}:</strong>&nbsp;{{ "mis_retos.campus" | translate }}<br><strong>{{ "mis_retos.carrera" | translate
            }}:</strong>&nbsp;{{ reto.programaAcademico }}<br><strong>{{ "mis_retos.semestre" | translate }}:</strong>&nbsp;{{ reto.semestre }}<br><strong>{{
            "mis_retos.inicio" | translate }}:</strong>&nbsp;{{ reto.fechaInicio }}<br><br>
              <div class="flex-fill d-flex justify-content-start">
                <div class="w-100">{{ reto.avanceSemanas +' de '+ reto. duracionSemanas}}
                <mat-progress-bar mode="determinate" [appProgressBarColor]="reto.porcentajeAvance == 100 ? '#4c964d' : '#93bcfd' "
                  [value]="reto.porcentajeAvance">
                </mat-progress-bar>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-2 d-flex justify-content-center" *ngIf="paginaActualMasResultados < totalPaginas">
      <button [disabled]="paginaActualMasResultados >= totalPaginas" class="mb_mas" (click)="obtenerMasResultados()" ><img class="tam" alt="Más resultados" src="assets/img/icon_mas_bullet.svg"> {{ 'problematicas.mas_resultados' | translate }}</button>
    </div>

    <div class="mt-2 d-flex justify-content-center" *ngIf="paginaActualMasResultados == totalPaginas && retosActualesMovilSFList.length <= registrosPagina && retosSFList.length > registrosPagina" >
      <button class="mb_mas" (click)="obtenerMasResultados()" ><img class="tam" alt="Más resultados" src="assets/img/icon_mas_bullet.svg"> {{ 'paginador.resultados_anteriores' | translate }} </button>
    </div>

    <div *ngIf="retosActualesMovilSFList.length === 0">
      <app-sin-datos-table></app-sin-datos-table>
    </div>

  </div>
</div>
