<div class="mt-4" *ngIf="hayCasosDeExito">

    <div class="subtitlecard">Casos de Éxito</div>
    <span class="retosExitosos" *ngFor="let ce of casosExito">
        <img 
            alt="{{ce.nombreSocioFormador}}" 
            src="{{ ce.urlLogoSocioFormador }}" 
            class="my-3 mx-1"
            title="{{ ce.nombreSocioFormador }}"
            (click)="mostrarDetalle(ce.idReto)"
            />
    </span>

</div>