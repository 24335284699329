export interface IUsuarioGetResponse {
  idUsuario: number,
  usuario: string,
  nombreUsuario: string,
  apellidosUsuario: string,
  clavePerfil: string,
  fechaRegistro: string,
  area: string,
  puesto: string,
  region: string,
  claveEstatus: string,
  idEscuela: number,
  escuela: string,
  idCampus: number,
  campus: string,
  urlFotoUsuario: string,
  nomina: string,
  avisoPrivacidad: boolean,
  contactoPrincipal: boolean,
  estatus: string,
  rolAcademico: string,
  departamento:string,
  domicilio: string,
  escuelaDepartamento: string,
}

export class UsuarioGetResponse implements IUsuarioGetResponse{
  idUsuario: number;
  usuario: string;
  nombreUsuario: string;
  apellidosUsuario: string;
  clavePerfil: string;
  fechaRegistro: string;
  area: string;
  puesto: string;
  region: string;
  claveEstatus: string;
  idEscuela: number;
  escuela: string;
  idCampus: number;
  campus: string;
  urlFotoUsuario: string;
  correo: string;
  nomina: string;
  avisoPrivacidad: boolean;
  contactoPrincipal: boolean;
  estatus: string;
  rolAcademico: string;
  departamento:string;
  domicilio: string;
  escuelaDepartamento: string;

  constructor(){
    this.idUsuario=0;
    this.usuario='';
    this.nombreUsuario='';
    this.apellidosUsuario='';
    this.clavePerfil='';
    this.fechaRegistro='';
    this.area='';
    this.puesto='';
    this.region='';
    this.claveEstatus='';
    this.idEscuela=0;
    this.escuela='';
    this.idCampus=0;
    this.campus='';
    this.urlFotoUsuario='';
    this.correo='';
    this.nomina='';
    this.avisoPrivacidad=false;
    this.contactoPrincipal=false;
    this.estatus='';
  }
}


export interface UsuarioCVDPResponsableGetResponse{
  apellidos: string,
  email: string,
  idUsuario: number,
  nombres: string,
  urlFotoUSuario: string,
  region: string,
  campus: string
}