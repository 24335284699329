<br />
<form [formGroup]="contactForm" (submit)="updateContact()">

  <div class="row form-perfil">

    <!-- Imagen de perfil -->
    <div class="col-md-3 text-center">
      <div>
        <img class="img_avt_ds" alt="logotipo" src="{{ imgUrl }}" />
      </div>
      <div><br />Imagen .JPG o .PNG</div>
      <small>Tamaño Máx: 1024x1024 < 3Mb</small>
          <div class="editFoto fileUploadButton" (click)="file.click()"><br />Editar Foto</div>
          <input class="hidden" type="file" (change)="selImagen($event)" accept="image/*" #file />
          <app-button-user *ngFor="let button of buttons; let i = index" (callbackAction)="onClickButton(button.url)"
            [buttons]="button"></app-button-user>
    </div>


    <div class="col-md-9 text-form">

      <div class="row">
        <div class="col" id="sec_tit">
          <h2>{{ titleProfile }}</h2>
        </div>
      </div>

      <!-- Nombre -->
      <div class="row">
        <div class="col">
          <label for="nombreUsuario">
            Nombre(s) <span class="text-danger">*</span></label>
          <app-text-input [formControl]="$any(contactForm.controls['nombreUsuario'])" [label]="'nombre'"
            [placeholder]="'ej. Beatriz'"></app-text-input>
        </div>
      </div>

      <!-- Apellidos -->
      <div class="row">
        <div class="col">
          <label for="apellidosUsuario">Apellidos <span class="text-danger">*</span></label>
          <app-text-input [formControl]="$any(contactForm.controls['apellidosUsuario'])" [label]="'apellidos'"
            [placeholder]="'ej. Beatriz'"></app-text-input>
        </div>
      </div>

      <!-- Correo electrónico -->
      <div class="row">
        <div class="col">
          <label for="email">Correo electrónico <span class="text-danger">*</span></label>
          <app-text-input [formControl]="$any(contactForm.controls['usuario'])" [label]="'usuario'"
            [placeholder]="'ej. beatriz.pinzon@ecomoda.com'" ></app-text-input>
        </div>
      </div>

      <!-- Nómina -->
      <div class="row">
        <div class="col">
          <label for="nomina">Nómina <span class="text-danger">*</span></label>
          <app-text-input [formControl]="$any(contactForm.controls['nomina'])" [label]="'Nómina'"
            [placeholder]="'Escribe tu nómina incluyendo L0 y 7 dígitos'"></app-text-input>

          <div *ngIf="contactForm.get('nomina')?.touched && contactForm.get('nomina')?.errors?.['pattern']"
            class="text-danger">
            El campo debe tener L0 y 7 dígitos. Ej: L01234567
          </div>

        </div>
      </div>

      <!-- Domicilio -->
      <div class="row"
        *ngIf="false">
        <div class="col">
          <label for="domicilio">Domicilio <span class="text-danger">*</span></label>
          <app-text-input [formControl]="$any(contactForm.controls['domicilio'])" [label]="'domicilio'" [placeholder]="
              'ej. Calle Falsa 123, Un Hogar para Nosotros, Zapopan, Jal...'
            "></app-text-input>
        </div>
      </div>

      <!-- Campus -->
      <div class="row">
        <div class="col">
          <label for="domicilio">Campus al que pertenece <span class="text-danger">*</span></label>
          <div class="select-dropdown">
            <select class="form-select" aria-label="Default select example" formControlName="idCampus">
              <option selected="" value="">Selecciona</option>
              <option *ngFor="let campus of campusData" value="{{ campus.idCampus }}"
                selected="{{ verifySelected(campus.idCampus, 'idCampus') }}">
                {{ campus.campus }}
              </option>
            </select>
          </div>

          <div
            *ngIf="contactForm.get('idCampus')?.touched && contactForm.get('idCampus')?.errors?.['required']"
            class="text-danger">
            El campo es requerido. Campus
          </div>
        </div>
      </div>

      <!-- Escuela -->
      <div class="row" *ngIf="[clavePerfil, userType].includes('academia')">
        <div class="col">
          <label for="domicilio">Escuela <span class="text-danger">*</span></label>
          <div class="select-dropdown">
            <select class="form-select" aria-label="Default select example" formControlName="idEscuela"
              (change)="obtenerDepartamentos($event)">
              <option selected="" value="">Selecciona</option>
              <option *ngFor="let escuela of escuelasData" value="{{ escuela.idEscuela }}"
                selected="{{ verifySelected(escuela.idEscuela, 'idEscuela') }}">
                {{ escuela.escuela }}
              </option>
            </select>
          </div>

          <div
            *ngIf="contactForm.get('idEscuela')?.touched && contactForm.get('idEscuela')?.errors?.['required']"
            class="text-danger">
            El campo es requerido. Escuela
          </div>

        </div>
      </div>

      <!-- Departamento -->
      <div class="row" *ngIf="[clavePerfil, userType].includes('academia')">
        <div class="col">
          <label for="departamento">Departamento <span class="text-danger">*</span></label>
          <div class="select-dropdown">
            <select class="form-select" aria-label="Default select example" formControlName="escuelaDepartamento">
              <option selected="" value="">Selecciona</option>
              <option *ngFor="let depto of departamentosData" value="{{ depto.idDepartamento }}"
                selected="{{ verifySelected(depto.idDepartamento, 'idDepartamento') }}">
                {{ depto.departamento }}
              </option>
            </select>
          </div>

          <div
            *ngIf="contactForm.get('escuelaDepartamento')?.touched && contactForm.get('escuelaDepartamento')?.errors?.['min']"
            class="text-danger">
            El campo es requerido. Departamento
          </div>

        </div>
      </div>

      <!-- Puesto -->
      <div class="row" *ngIf="false">
        <div class="col">
          <label for="puesto">Puesto <span class="text-danger">*</span></label>
          <app-text-input [formControl]="$any(contactForm.controls['puesto'])" [label]="'puesto'"
            [placeholder]="'ej. Asistente de Presidencia'"></app-text-input>
        </div>
      </div>

      <!-- Rol Académico -->
      <div class="row" *ngIf="[clavePerfil, userType].includes('academia')">
        <div class="col">
          <label for="rol_academico">Rol Académico <span class="text-danger"></span></label>
          <ng-select [items]=" rolAcademicoData$ | async" bindLabel="deRolAcademico" bindValue="idRolAcademico"
            formControlName="idRolAcademico" [placeholder]="'Selecciona una opción'">
          </ng-select>
        </div>
      </div>

      <!-- Nivel de Gestión-->
      <div class="row" *ngIf="mostrarNivelGestion">
        <div class="col">
          <label for="domicilio">Nivel de gestión <span class="text-danger">*</span></label>
          <div class="select-dropdown">
            <ng-select [items]="nivelGestionData$ | async" bindLabel="nivelGestion" bindValue="idNivelGestion"
            (change)="showRegion($event)"
              formControlName="idNivelGestion" [placeholder]="'Selecciona una opción'">
            </ng-select>
            <div
              *ngIf="contactForm.get('idNivelGestion')?.touched && contactForm.get('idNivelGestion')?.errors?.['required']"
              class="text-danger">
              El campo es obligatorio
            </div>
          </div>
        </div>
      </div>

      <!-- Región a la que da servicio -->
      <div class="row" *ngIf="mostrarRegion">
        <div class="col">
          <label for="domicilio">Región a la que da servicio <span class="text-danger">*</span></label>
          <div class="select-dropdown">
            <ng-select [items]="regionData" bindLabel="region" bindValue="idRegion" formControlName="idRegion"
              (change)="showCampus($event)"
              [placeholder]="'Selecciona una opción'">
            </ng-select>

            <div
              *ngIf="contactForm.get('idRegion')?.touched && contactForm.get('idRegion')?.errors?.['required']"
              class="text-danger">
              El campo es obligatorio
            </div>

          </div>
        </div>
      </div>

      <!-- Campus que da servicio -->
      <div class="row" *ngIf="mostrarCampus">
        <div class="col">
          <label for="domicilio">Campus al que da servicio <span class="text-danger">*</span></label>
          <div class="select-dropdown">
            <ng-select [items]="campusDataRegion" [multiple]="true" bindLabel="campus" bindValue="idCampus"
              formControlName="listaCampusAdicional" [placeholder]="'Selecciona una opción'">
            </ng-select>
            <div
              *ngIf="contactForm.get('listaCampusAdicional')?.touched && contactForm.get('listaCampusAdicional')?.errors?.['required']"
              class="text-danger">
              El campo es obligatorio
            </div>
          </div>
        </div>
      </div>

      <!-- Rol de sistema -->
      <div class="row" *ngIf="canShowRoles()">
        <div class="col">
          <label for="rol">Rol</label>
          <ng-select [items]=" rolesData$ | async" [multiple]="true" bindLabel="rol" bindValue="claveRol"
            formControlName="claveRoles" [placeholder]="'Selecciona una opción'">
          </ng-select>
        </div>
      </div>

      <!-- Boton de acción -->
      <div class="row">
        <div class="col-6 d-flex j1ustify-content-center">
          <app-button type="submit" [buttonType]="'yell'">{{ btn_lblAccion }}</app-button>
          <button class="btn-blue mx-3" (click)="regresar()" *ngIf="mostrarbtnCancelar" type="button">Cancelar</button>
          <button class="error_button" (click)="eliminarUsuario()" *ngIf="mostrarbtnCancelar" type="button">Eliminar</button>
        </div>
      </div>

    </div>
  </div>
</form>


<div class="modal fade" id="idModalCorreoExistente" tabindex="-1" aria-labelledby="ModalCorreoExistente"
  aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" id="ModalCorreoExistente">Alta de Usuario</h3>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <h3>{{ this.mensajeModal }}</h3>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" data-bs-dismiss="modal">
          Cerrar
        </button>
      </div>
    </div>
  </div>
</div>
