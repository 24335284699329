import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import Utils from '@shared/helpers/utils';

@Component({
  selector: 'app-card-accordion-socio',
  templateUrl: './card-accordion-socio.component.html',
  styleUrls: ['./card-accordion-socio.component.css'],
})
export class CardAccordionSocioComponent implements OnInit {
  
  @Input() matchObj: any;
  @Input() index: any;
  @Output() onJoinVinculacion: EventEmitter<any> = new EventEmitter<any>();

  lblDescripcion: string="";

  ngOnInit(): void {
    this.lblDescripcion = Utils.cutLongTextByXChars(this.matchObj.descripcion,130);
  }

  callbackVinculacion(matchObj: any) {
    this.onJoinVinculacion.emit(matchObj);
  }

  callbackJoinReto(retoObj: any) {
    this.onJoinVinculacion.emit(retoObj);
  }
}
