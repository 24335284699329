import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { UsuariosService } from '@shared/service/Usuarios/usuarios.service';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { AccountService } from '@shared/service/Account/account.service';
import { IdashboardUsuario } from '@shared/Interface/IdashboardUsuario';
import Swal from 'sweetalert2';
declare const window: any;

@Component({
  selector: 'app-valida-sesion',
  templateUrl: './valida-sesion.component.html',
  styleUrls: ['./valida-sesion.component.css'],
})
export class ValidaSesionComponent implements OnInit {
  frmModal: any;
  userData: IdashboardUsuario;
  MostrarAvisoPrivacidad: boolean = false;

  constructor(
    private msalService: MsalService,
    private routes: Router,
    private accountService: AccountService,
    private usuarioService: UsuariosService,
    private cookieService: CookieService,
    private toastr: ToastrService
  ) {}

  async ngOnInit() {
    this.IniciarSesion();
  }

  async IniciarSesion() {
    if (this.msalService.instance.getAllAccounts().length > 0) {
      let account = this.msalService.instance.getAllAccounts()[0];
      this.msalService.instance.setActiveAccount(account);

      this.frmModal = new window.bootstrap.Modal(
        document.getElementById('idAvisoPrivacidadModal')
      );

      // guardamos el userAccount del b2c
      localStorage.removeItem('b2c');
      localStorage.setItem('b2c', JSON.stringify(account));


      // ponemo la cookie del correo
      this.cookieService.delete('email');
      this.cookieService.set('email', account.username);

      // ponemos la cookie del usuario
      this.usuarioService.getUsuario(account.username, true).subscribe({
        next: (data) => {
          // Validar si el usuario esta activo
          if(data.claveEstatus !== 'activo'){
            Swal.fire( {
              title:'MPS - Usuario',
              text: 'Tu usuario no esta activo',
              timer: 3000
            })
              .then(() =>{
                this.accountService.logout();
              }
              );
              return;

          }
          this.userData = data;

          if (!data.avisoPrivacidad) {

            this.frmModal.show();
          } else {
            this.iralDashboard();
          }
        },
        error: (error) => {
          this.accountService.logout();
          this.toastr.error('El usuario no tiene cuenta en el sistema');
        }
      });
    }

  }

  salirAplicacion() {
    this.frmModal.hide();
    this.usuarioService.logout();
  }

  async refreshUsuario(email: string) {
    if(email == undefined || email == '')
    {
      email =  this.msalService.instance.getAllAccounts()[0].username;
    }
    this.usuarioService.getUsuario(email).subscribe((data) => {
      this.userData = data;
      this.cookieService.delete('usrActual');
      this.cookieService.set('usrActual', JSON.stringify(this.userData));
    });
  }

  iralDashboard() {
    this.frmModal.hide();
    this.cookieService.delete('usrActual');
    this.cookieService.set('usrActual', JSON.stringify(this.userData));

    this.routes.navigate(['/dashboard']);
  }

  guardarAvisoPrivacidad() {
    this.usuarioService
      .putUserAvisoPrivacidad(this.userData.idUsuario, null)
      .subscribe({
        next: (resOK) => {
          this.iralDashboard();
        },
        error: (error) => {
          this.toastr.error(error.error.mensaje);
        }
      });
  }

  cancelarAvisoPrivacidad() {
    this.accountService.logout();
  }
}
