import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-buttons',
  templateUrl: './buttons.component.html',
  styleUrls: ['./buttons.component.css'],
})
export class ButtonsComponent  {
  @Input()
  elementsButton: [] = [];
  @Output() callbackAction: EventEmitter<any> = new EventEmitter<any>();

  listenerCallback(button:any){
    button.callbackAction(button.objParams);
    
  }

  getClass(button: any) {
    return button.className === 'edit' ? 'lst_bt_edt' : 'lst_bt_brr';
  }

  getColor(button: any) {
    return button.className === 'edit' ? '#4A6BB6' : '#CE0032';
  }
}
