import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aviso-privacidad',
  templateUrl: './aviso-privacidad.component.html',
  styleUrls: ['./aviso-privacidad.component.css']
})
export class AvisoPrivacidadComponent implements OnInit {


  showReturnButton:boolean = false;

  ngOnInit(): void {
    let srb = localStorage.getItem('origen-ap') ?? '';
    if(srb=='form'){
      localStorage.removeItem('origen-ap');
      this.showReturnButton=false;
    }
    else{
      this.showReturnButton=true;
    }
  }

  regresa(){
    window.history.back();
  }

}
