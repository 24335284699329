import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Router, UrlTree } from "@angular/router";
import {
  IArea,
  IMedioContacto,
  IPeriodoSemanal,
} from "@shared/Interface/ICatalogos.interface";
import { RetosResponse } from "@shared/Interface/IRetosResponse";
import { RetosService } from "@shared/service/Retos/retos.service";
import { lastValueFrom, tap } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { UsuariosService } from "@shared/service/Usuarios/usuarios.service";
import {
  NgbDateStruct,
  NgbModal,
  NgbModalConfig,
} from "@ng-bootstrap/ng-bootstrap";
import Swal from "sweetalert2";
import { SociosFormadoresService } from "@shared/service/Socios_Formadores/socios-formadores.service";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { ISolicitudSocioComentarios } from "@shared/Interface/ISolicitudSocioComentarios";
import { CatalogosService } from "@shared/service/Catalogos/catalogos.service";
import { ContactosService } from "@shared/service/Contactos/contactos.service";
import { SolicitudesService } from "@shared/service/Solicitudes/solicitudes.service";
import { saveAs } from "file-saver";
import { ICartaColaboracion } from "@shared/Interface/IReto.interface";
import { env } from "process";
import { environment } from "environments/environment";
import Utils from "@shared/helpers/utils";


@Component({
  selector: "app-pagina-retos",
  templateUrl: "./pagina-retos.component.html",
  styleUrls: ["./pagina-retos.component.css"],
})
export class PaginaRetosComponent implements OnInit {
  @ViewChild("content") content: ElementRef;
  @ViewChild("content_firm") content_firm: ElementRef;

  SECCION_TODOS: number = 0;
  SECCION_SOCIO: number = 1;
  SECCION_RETO: number = 2;
  SECCION_CARTA: number = 3;
  SECCION_INSCRITOS: number = 4;

  lang: string = "es";
  idReto: number = 1;
  retoDetalle!: RetosResponse;
  urlTree: UrlTree;
  numSemanas: string;
  campusConcat: string = "";
  campusEtiqueta: string = "";

  campusCoordinador: string = "";

  CRNVinculados: string = "";
  CRNPrincipal: string = "";

  semestreNum: string = "*";
  semestreEtiqueta: string = "MultiSemestre";
  semestresConcat: string = "";

  idiomaNum: string = "*";
  idiomaEtiqueta: string = "MultIdioma";
  idiomaConcat: string = "";

  lblPeriodoSemanal: string = "";
  firstFormGroup: any;
  secondFormGroup: any;
  status: string = "En formalización";
  documentarReto: boolean = false;

  lstEntregables: any[] = [];
  lstCompromisos: any[] = [];
  lstRecursos: any[] = [];
  lstprofe: any[] = [];

  clavePerfil: string = "";
  statusCarta: string = "";

  lbl_firma_creacion: string = "-";
  lbl_firma_academico: string = "-";
  lbl_firma_SF: string = "-";
  lbl_texto_VerCartaFirmada: string = "Revisar y Firmar carta colaboración";

  formularioFormC!: UntypedFormGroup;
  filePdfFirmado: string;
  content_firm_modal;

  nombreContacto: string;
  personaFirmaCarta: string;
  fechaFirmaCarta: string;
  filePdfSeleccionado: string;

  Cartafirmada: boolean = false;
  vConfirmacion: boolean = false;
  vFrmContacto: boolean = true;
  mostrarCartaaFirmar: boolean = true;
  IdSocioFormador: number = 0; //** */
  Areas!: IArea[];
  MediosDeContacto!: IMedioContacto[];
  nombreSocioFormador: string = "";
  rfc_empresa: string = "";
  razonSocial: string = "";
  colaboracionSel: ICartaColaboracion;
  inscritos: number = 0;
  borrarCartas: boolean = false;
  bShowHideSFForm:boolean = false;
  bShowHideCartaForm:boolean = false;


  constructor(
    private retosService: RetosService,
    private router: Router,
    private toastr: ToastrService,
    private usuarioService: UsuariosService,
    private sociosFormadoresService: SociosFormadoresService,
    private config: NgbModalConfig,
    private modalService: NgbModal,
    private readonly fb: UntypedFormBuilder,
    private contactoService: ContactosService,
    private catalogosSvc: CatalogosService,
    private solicitudesService: SolicitudesService
  ) {
    this.urlTree = this.router.parseUrl(this.router.url);
    this.idReto = Number(this.urlTree.queryParams["reto"]);
  }

  async ngOnInit(): Promise<void> {
    this.lang = localStorage.getItem("lang") ?? "es";

    this.getReto();
    this.getProfesores();
    this.getEntregables();
    this.getCompromisos();
    this.getRecursos();

    this.clavePerfil = this.usuarioService.getCurrentUserData().clavePerfil;

    if (this.status !== "formalizacion") {
      this.documentarReto = true;
    }

    this.formularioFormC = this.initFormC();
  }

  changeLang(lang: string) {
    this.lang = lang;
    localStorage.setItem("lang", lang);
    window.location.reload();
  }

  async getReto() {
    try {
      let resultado = await lastValueFrom(
        this.retosService.getRetos(this.idReto)
      );
      if (resultado != null && resultado != undefined) {
        this.retoDetalle = resultado;

        this.infoSF = { IdSocioFormador: resultado.idSocioFormador ?? 0 };

        if (this.retoDetalle.idSocioFormador != 0) {
          this.obtenerCartaColaboracion();
        }

        this.numSemanas = this.retoDetalle.semanas
          .toUpperCase()
          .replace(" SEMANAS", "");

        this.status = this.retoDetalle.claveEstatus.trim();

        if (this.retoDetalle.campus.length > 1) {
          this.campusEtiqueta = "Multicampus";
          this.campusConcat = this.retoDetalle.campus
            .map((x) => x.campus)
            .join(", ");
        } else {
          this.campusConcat = this.retoDetalle.campus
            .map((x) => x.campus)
            .join(", ");
          this.campusEtiqueta = this.campusConcat;
        }

        if (this.campusConcat.length == 0) {
          this.campusConcat = this.retoDetalle.nombreCampus;
        }

        this.campusCoordinador = this.retoDetalle.campus
          .filter((x) => x.esCoordinador === true)
          .map((x) => x.campus)
          .join("");

        if (this.retoDetalle.idiomas.length > 1) {
          this.idiomaEtiqueta = this.retoDetalle.idiomas
            .map((x) => x.idioma)
            .join(", ");
        } else {
          this.idiomaEtiqueta = this.retoDetalle.idiomas
            .map((x) => x.idioma)
            .join(", ");
          this.idiomaNum = this.idiomaEtiqueta.substring(0, 2);
        }

        if (this.retoDetalle.semestres.length == 1) {
          this.semestreNum = this.retoDetalle.semestres
            .map((x) => x.semestre)
            .join("");
          this.semestreEtiqueta =
            this.retoDetalle.semestres.map((x) => x.semestre).join("") +
            " ° Semestre";
        }

        this.semestresConcat = this.retoDetalle.semestres
          .map((x) => x.semestre)
          .join(", ");

          this.CRNVinculados = "";
          this.CRNPrincipal =  "";

        if(this.retoDetalle.crns && this.retoDetalle.crns.length > 0){
          this.CRNVinculados = this.retoDetalle.crns.map((x) => x.crn).join(", ");
          this.CRNPrincipal = this.retoDetalle.crns
            .filter((x) => x.esPrincipal === true)
            .map((x) => x.crn)
            .join(", ");
        }

        this.lblPeriodoSemanal = this.retoDetalle.periodosSemanales
          .map((x: IPeriodoSemanal) => x.periodoSemanal)
          .join(", ");

        if (this.lblPeriodoSemanal.length == 0) {
          this.lblPeriodoSemanal = this.retoDetalle.periodoSemanal;
        }

        this.lblAlumnosInscritos = this.retoDetalle.inscritos.toString();
      }
    } catch (error:any) {
      Utils.processErrorResponse(error,"Error al obtener el detalle de un reto por su ID ");
    }
  }

  async getProfesores() {
    this.lstprofe = [];
    try {
      this.lstprofe = await lastValueFrom(
        this.retosService.getAllRetoProfesor(this.idReto)
      );
    } catch (error:any) {
      Utils.processErrorResponse(error);
    }
  }

  async getEntregables() {
    this.lstEntregables = [];
    try {
      this.lstEntregables = await lastValueFrom(
        this.retosService.getAllRetoEntregable(this.idReto)
      );
    } catch (error:any) {
      Utils.processErrorResponse(error);
    }
  }

  async getCompromisos() {
    this.lstCompromisos = [];
    try {
      this.lstCompromisos = await lastValueFrom(
        this.retosService.getAllRetoCompromiso(this.idReto)
      );
    } catch (error:any) {
      Utils.processErrorResponse(error);
    }
  }
  async getRecursos() {
    this.lstRecursos = [];
    try {
      this.lstRecursos = await lastValueFrom(
        this.retosService.getAllRetoRecurso(this.idReto)
      );
    } catch (error:any) {
      Utils.processErrorResponse(error);
    }
  }

  lblAlumnosInscritos: string = "0";
  selImagen(evento: any) {}

  async documentar(seccion: number) {
    try {
      //Guardar Entregable
      let body = this.createRetoMaestro(seccion);
      //let errors = this.informacionCompleta(body, false, seccion);

      // if (errors.length > 0) {
      //   let sErrores = '<ul><li class="text-start">'+errors.join('</li><li class="text-start">')+'</ul>';
      //   Swal.fire({
      //     title: "Completa la información",
      //     html: sErrores,
      //   });
      //   return;
      // }
      let res = await lastValueFrom(this.retosService.putRetos(body));
      this.toastr.success("Se ha guardado correctamente");
      localStorage.removeItem("localdb");
    } catch (err) {
      this.toastr.error("Ha ocurrido un error. Intenta más tarde.");
      console.log(err);
    }
  }

  async informacionCompleta(body: any, definitivo: boolean, seccion: number) {
    let errores: string[] = [];
    await this.getCompromisos();
    await this.getEntregables();
    await this.getRecursos();


    //TODO: validar la asignación de profesor coordindador:
    //PASO 1.- Asigna al profesor(a) coordinador(a) de reto.

    if([this.SECCION_SOCIO, this.SECCION_TODOS].includes(seccion)){
      let idSocio = body.idSocioFormador ?? 0;
      if(idSocio == 0){
        errores.push("Asigna al Socio Formador vinculado al reto.");
      }
    }

    if([this.SECCION_CARTA, this.SECCION_TODOS].includes(seccion) && ! this.retoConRawCase ){
      let idSocio = body.idSocioFormador ?? 0;
      if(idSocio == 0){
        if(!errores.includes('Asigna al Socio Formador vinculado al reto.')){
          errores.push("Asigna al Socio Formador vinculado al reto.");
        }
      }
       if(this.datosCartaColaboracion){
      //   if(!this.datosCartaColaboracion.tieneContacto){
      //     errores.push("Seleccionar un Contacto para la carta de Colaboración");
      //   }
      //   if(!this.datosCartaColaboracion.tieneFecha){
      //     errores.push("Seleccionar una fecha de firma para la carta de Colaboración");
      //   }
        if(!this.datosCartaColaboracion.tieneCarta){
          errores.push("Carga o crea la carta colaboración para el reto.");
        }
      }
    }

    if ([this.SECCION_RETO, this.SECCION_TODOS].includes(seccion) && ! this.retoConRawCase ) {
      if (body.idAlcance == 0) {
        errores.push("Selecciona el alcance del reto");
      }
      if (body.idModalidad == 0) {
        errores.push("Selecciona la modalidad del reto");
      }
      if (body.reto.toString().trim().length == 0) {
        errores.push("Escribe el nombre del reto");
      }
      if (body.descripcion.toString().trim().length == 0) {
        errores.push("Escribe la descripción del reto");
      }
      if (body.objetivo.toString().trim().length == 0) {
        errores.push("Escribe el objetivo del reto");
      }
      if (this.lstEntregables.length == 0 && definitivo) {
        errores.push("Define los entregables que harás al Socio Formador.");
      }
      if (this.lstCompromisos.length == 0 && definitivo) {
        errores.push("Añade los compromisos establecidos con el Socio Formador.");
      }
      if (this.lstRecursos.length == 0 && definitivo) {
        errores.push("Especifica los recursos acordados.");
      }
    }
    return errores;
  }

  createRetoMaestro(seccion: number) {
    let SocioFormador = this.infoSF.idSocioFormador ?? this.retoDetalle.idSocioFormador ??  0;
    return {
      idReto: this.retoDetalle.idReto,
      //idRetoMaestro: this.retoDetalle.idRetoMaestro,
      reto: this.retoDetalle.reto,
      descripcion: this.retoDetalle.descripcion,
      entregable: this.retoDetalle.entregable,
      objetivo: this.retoDetalle.objetivo,
      idAlcance: this.retoDetalle.idAlcance,
      idModalidad: this.retoDetalle.idModalidad,
      idSocioFormador: SocioFormador == 0 ? null : SocioFormador,
      inscritos: this.inscritos,

      // idUsuarioTec: this.retoDetalle.idUsuarioTec,
      // idEscuela: this.retoDetalle.idEscuela,
      // idUnidadFormativa: this.retoDetalle.idUnidadFormativa,
      // semestre: this.retoDetalle.semestre,
      // idRegion: this.retoDetalle.idRegion,
      // idIdioma: this.retoDetalle.idIdioma,
      // empresaFamiliar: this.retoDetalle.empresaFamiliar,
      // fechaInicio: this.retoDetalle.fechaInicio,
      // fechaFin: this.retoDetalle.fechaFin,
      // idSector: this.retoDetalle.idSector,
      // idIndustria: this.retoDetalle.idIndustria,
      // idTamano: this.retoDetalle.idTamano,
      // palabrasClave: this.retoDetalle.palabrasClave,
      // idPeriodo: this.retoDetalle.idPeriodo,
      // idSemanas: this.retoDetalle.idSemanas,
      // idProgramaAcademico: this.retoDetalle.idProgramaAcademico,

      // campus: this.retoDetalle.campus,
      // giros: this.retoDetalle.giros,
    };
  }

  dateFormat(date: NgbDateStruct): string {
    // from internal model -> your mode
    return date
      ? date.year +
          "-" +
          ("0" + date.month).slice(-2) +
          "-" +
          ("0" + date.day).slice(-2)
      : "";
  }

  formatDate(fecha) {
    return (
      fecha.year +
      "-" +
      leftPad(fecha.month + "", 2) +
      "-" +
      leftPad(fecha.day + "", 2)
    );
  }

  async enviarAFormalizacion() {
    try {
      //let body = this.createRetoMaestro(SECCION_TODOS);
      //let res = await lastValueFrom(this.retosService.putRetos(body));
      let res = await lastValueFrom(
        this.retosService.putRetoEstatus({
          idReto: this.retoDetalle.idReto,
          idUsuarioTec: this.retoDetalle.idUsuarioTec,
          claveEstatus: "formalizacion",
        })
      );

      this.toastr.success("Guardado con éxito");
      localStorage.removeItem("localdb");
      this.ngOnInit();
    } catch (error:any) {
      Utils.processErrorResponse(error);
    }
  }

  async enviarAEvaluacion(){
    try{
      let res = await lastValueFrom(
        this.retosService.putRetoEstatus({
          idReto: this.retoDetalle.idReto,
          idUsuarioTec: this.retoDetalle.idUsuarioTec,
          claveEstatus: "evaluacion",
        }));

        setTimeout(function(){location.reload()}, 500);
        localStorage.removeItem("localdb");
    }catch(err){
      this.toastr.error("Ocurrió un error, intente más tarde");
      console.log(err);
    }
  }
  async enviarAConcluido() {
    try{
      let res = await lastValueFrom(
        this.retosService.putRetoEstatus({
          idReto: this.retoDetalle.idReto,
          idUsuarioTec: this.retoDetalle.idUsuarioTec,
          claveEstatus: "concluido",
        })
      );

      setTimeout(function(){location.reload()}, 500);
      localStorage.removeItem("localdb");
    }catch(err){
      this.toastr.error("Ocurrió un error, intente más tarde");
      console.log(err);
    }
  }

  async enviarAEnCurso() {
    try {
      let body = this.createRetoMaestro(this.SECCION_TODOS);


      let errors = await this.informacionCompleta(body, true, this.SECCION_TODOS);
      if (errors.length > 0) {
        let sErrores = '<ul><li class="text-start">'+errors.join('</li><li class="text-start">')+'</ul>';
        let htmlMessage = '<p>Estás a punto de enviar la documentación de tu reto. Sin embargo, existe información incompleta:</p>' + sErrores
        Swal.fire({
          title: "Envío de la documentación",
          showCancelButton: true,
          confirmButtonText: "Estoy de acuerdo",
          cancelButtonText: "Cancelar",
          html:htmlMessage,
          customClass: {
            confirmButton: "btn-amarillo order-2 mx-1",
            cancelButton: "btn-brown order-1 mx-1",
          },
        }).then(async (resp) => {
          if(resp.isConfirmed){
            let res = await lastValueFrom(
              this.retosService.putRetoEstatus({
                idReto: this.retoDetalle.idReto,
                idUsuarioTec: this.retoDetalle.idUsuarioTec,
                claveEstatus: "en-curso",
              })
            );

            setTimeout(function(){location.reload()}, 500);
            this.toastr.success("Guardado con éxito");
            localStorage.removeItem("localdb");
          }
        });
        return;
      }

      let res = await lastValueFrom(
        this.retosService.putRetoEstatus({
          idReto: this.retoDetalle.idReto,
          idUsuarioTec: this.retoDetalle.idUsuarioTec,
          claveEstatus: "en-curso",
        })
      );

      setTimeout(function(){location.reload()}, 500);
      this.toastr.success("Guardado con éxito");
      localStorage.removeItem("localdb");
    } catch (error:any) {
      Utils.processErrorResponse(error);
    }
  }

  download() {
    console.log("download");
  }

  retoImpl(event: any) {
    this.retoDetalle.idAlcance = event.alcance_reto;
    this.retoDetalle.idModalidad = event.modalidad_reto;
    this.retoDetalle.reto = event.nombre_reto;
    this.retoDetalle.descripcion = event.descripcion_reto;
    this.retoDetalle.objetivo = event.objetivo_aprendizaje;
  }

  infoSF: any;
  retoConRawCase: Boolean = false;
  RAW_CASE_SOCIO_FORMADOR = environment.RawCaseIdSocioFormador;
  sobreSF(event: any) {
    this.infoSF = event;
    this.retoConRawCase = false;
    if(this.infoSF.idSocioFormador == this.RAW_CASE_SOCIO_FORMADOR){
      this.retoConRawCase = true;
    }
  }

  firmarCarta(event: any) {
    console.log("firma carta",event);
  }

  async documentarR(event: any) {
    this.documentarReto = event;
  }

  async enviarRecordatorio() {
    Swal.fire({
      title: "Enviar Recordatorio",
      text: "¿Desea enviar un email de recordatorio?",
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let body = {
          idReto: this.retoDetalle.idReto,
          idUsuarioTec: this.retoDetalle.idUsuarioTec,
        };

        // let res = await lastValueFrom(this.retosService.putRetoEstatus(body));
        this.toastr.success("Email Enviado con éxito");
      }
    });
  }

  documentarFirmaSocio() {
    // Swal.fire({
    //   title: "Firma carta Colaboración",
    //   text: "Desea firmar la carta colaboración",
    //   showCancelButton: true,
    //   confirmButtonText: "Si",
    //   cancelButtonText: "No",
    // }).then((result) => {
    //   if (result.isConfirmed) {
    //     this.toastr.warning("Error al firmar la carta");
    //   }
    // });

    this.verificarCartaColaboracion();
  }

  cartaColaboracion: any;
  async obtenerCartaColaboracion() {
    try {
      if (this.retoDetalle.idSocioFormador != 0) {
        let res = await lastValueFrom(
          this.sociosFormadoresService.getCartaColaboracion(
            this.retoDetalle.idSocioFormador
          )
        );
        if (res.length > 0) {
          this.cartaColaboracion = res.find(x => x.idReto == this.retoDetalle.idReto);
          if(this.cartaColaboracion){
            let fechaFirma = this.cartaColaboracion.fechaFirma;
            let fechaFirmaSF = this.cartaColaboracion.fechaPaso2Firma;
            if(!fechaFirma){
              return;
            }
            this.lbl_firma_creacion = fechaFirma.substring(
              0,
              10
            );
            this.lbl_firma_academico = fechaFirma.substring(
              0,
              10
            );

            this.statusCarta = "firmada_ac";

            if(!fechaFirmaSF){
              return;
            }

            this.lbl_firma_SF = fechaFirmaSF.substring(
              0,
              10
            );
            this.statusCarta = "firmada_sf";

          }

        }
      }
    } catch (err:any) {
      Utils.processErrorResponse(err);
    }
  }

  async verificarCartaColaboracion() {
    try {
      this.Cartafirmada = false;
      this.lbl_texto_VerCartaFirmada = "Revisar y Firmar carta colaboración";
      this.IdSocioFormador = this.usuarioService.getCurrentUserData().idSocioFormador;
      let response = await lastValueFrom(
        this.sociosFormadoresService.getCartaColaboracion(this.IdSocioFormador)
      );
      let convenioPendienteFirmar = response.filter(
        (x) => x.idReto == this.idReto //&& (x.paso1Firma == false || x.paso2Firma == false)
      );
      if (convenioPendienteFirmar.length > 0) {
        this.filePdfFirmado = convenioPendienteFirmar[0].urlCartaColaboracion;
        this.colaboracionSel = convenioPendienteFirmar[0];
        // Mostrar el ultimo convenio generado
        this.modalService.open(this.content, { size: "xl" });
        if (convenioPendienteFirmar[0].paso2Firma) {
          this.Cartafirmada = true;
          //this.lbl_texto_VerCartaFirmada = "Ver carta colaboración";
        }
      } else {
        this.toastr.warning("No hay carta colaboración");
        // Si tiene todos los convenios firmados, dejar pasar
        //this.cargarDashboard();
      }
    } catch (err:any) {
      this.toastr.warning("No hay carta colaboración");
      Utils.processErrorResponse(err);
    }
  }

  initFormC(): UntypedFormGroup {
    return this.fb.group({
      nombre_contacto: ["", [Validators.required, Validators.minLength(3)]],
      apellidos_contacto: ["", [Validators.required, Validators.minLength(3)]],
      area_departamento_contacto: [
        "",
        [Validators.required, Validators.minLength(3)],
      ],
      puesto_contacto: ["", [Validators.required, Validators.minLength(3)]],
      email_contacto: ["", [Validators.required, Validators.email]],
      telefono_oficina_contacto: ["", [Validators.pattern(/\d/)]],
      celular_contacto: ["", [Validators.pattern(/\d/)]],
      exatec_contacto: ["", [Validators.required]],
      enteraste_contacto: [null, [Validators.required]],
    });
  }

  closeConvenio(solicitarConfirmacion: boolean) {
    // if (solicitarConfirmacion) {
    //   Swal.fire({
    //     title: "¿Desea salir?",
    //     text: "Se cerrará su sesión hasta que el responsable firme la carta.",
    //     showCancelButton: true,
    //     cancelButtonText: "Cancelar",
    //     confirmButtonText: "Salir",
    //     buttonsStyling: false,
    //     customClass: {
    //       confirmButton: "btn-amarillo order-2 mx-1",
    //       cancelButton: "btn-brown order-1 mx-1",
    //     },
    //   }).then((result) => {
    //     if (result.isConfirmed) {
    //       this.usuarioService.logout();
    //     }
    //   });
    // } else {
    //   this.usuarioService.logout();
    // }
  }

  closeModalyCargarDashboard() {
    this.modalService.dismissAll();
    //this.cargarDashboard();
  }

  enviarRegistroContactoSecundario() {
    // enviar al registro de contacto secundario
    this.mostrarCartaaFirmar = false;
    // Medios contacto
    this.catalogosSvc
      .getMediosContacto()
      .pipe(
        tap(
          (resultado: IMedioContacto[]) => (this.MediosDeContacto = resultado)
        )
      )
      .subscribe();

    // Areas
    this.catalogosSvc
      .getAreas()
      .pipe(tap((resultado: IArea[]) => (this.Areas = resultado)))
      .subscribe();
  }
  regresaraMostrarCartaFirmar() {
    //this.vFrmContacto = true;
    this.mostrarCartaaFirmar = true;
  }

  async existeContacto(email: string) {
    try {
      let res = await lastValueFrom(this.usuarioService.getUsuario(email));
      return res ? true : false;
    } catch (err:any) {
      Utils.processErrorResponse(err);
      return false;
    }
  }

  async guardarInformacion() {
    this.formularioFormC.markAllAsTouched();
    if (this.formularioFormC.valid) {
      let existe = await this.existeContacto(
        this.formularioFormC.get("email_contacto")?.value
      );
      if (existe) {
        Swal.fire({
          title: "<strong>Revisa tu solicitud</strong>",
          icon: "error",
          html: "<p>El correo ya existe en el sistema, por favor revísalo.</p>",
          showCloseButton: true,
          showConfirmButton: false,
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn-amarillo order-2 mx-1",
            cancelButton: "btn-brown order-1 mx-1",
          },
        });
        return;
      }

      let socioFormadorModel: any = {
        socioFormador: {
          nombreSocioFormador: "",
          empresa: "",
          razonSocial: "",
          grupoComercial: "",
          rfc: "",

          idSector: "",
          paginaWeb: "",
          idCampus: "",
          idIndustria: "",
          idPresencia: "",
          relacionesTec: "",
          conoceRolSF: "",

          pais: "",
          cp: "",
          claveEstado: "",
          estado: "",
          ciudad: "",
          ciudadFiscal: "",
          colonia: "",
          calle: "",
        },

        contactoPrincipal: {
          nombre: this.formularioFormC.get("nombre_contacto")?.value,
          apellidos: this.formularioFormC.get("apellidos_contacto")?.value,
          area: this.formularioFormC.get("area_departamento_contacto")?.value,
          email: this.formularioFormC.get("email_contacto")?.value,
          telefonoOficina: this.formularioFormC.get("telefono_oficina_contacto")
            ?.value,
          telefonoMovil: this.formularioFormC.get("celular_contacto")?.value,
          puesto: this.formularioFormC.get("puesto_contacto")?.value,
          exatec: this.formularioFormC.get("exatec_contacto")?.value == "true",
          avisoPrivacidad: this.formularioFormC.get("terminos_condiciones")
            ?.value,
          contactoPrincipal: true,
          idMedioContacto: this.formularioFormC.get("enteraste_contacto")
            ?.value,
          datoMedioContacto: "",
          clave: "mvpretos",
        },
      };

      this.contactoService
        .postContactoSecundario(
          this.usuarioService.getCurrentUserData().idSocioFormador,
          socioFormadorModel.contactoPrincipal
        )
        .subscribe({
          next: async (resOk) => {
            //this.ocultarTodos();
            // Mensaje de confirmación
            this.vFrmContacto = false;
            this.vConfirmacion = true;

            localStorage.removeItem("localdb");
            // // Obtener correo del cvdp del campus que seleccionó
            // this.correoCVDP = "sociosformadores@servicios.tec.mx";
            // let campusCVDPPrincipal = this.Campus.filter(x=> x.idCampus == socioFormadorModel.socioFormador.idCampus);
            // if(campusCVDPPrincipal.length>0){
            //   let UsuarioCVDPPrincipal = await lastValueFrom(this.usuarioService.getUsuarioById(campusCVDPPrincipal[0].idCVDPPrincipal));
            //   this.correoCVDP = UsuarioCVDPPrincipal.usuario;
            // }
          },
          error: (err) => {
            let mensajeError =
              err.message +
              '<br/><br/> Si tienes dudas, ponte en contacto con <a href="mailto:sociosformadores@servicios.tec.mx">sociosformadores@servicios.tec.mx</a>';
            Swal.fire({
              title: "<strong>Error en tu solicitud</strong>",
              icon: "error",
              html: mensajeError,
              showCloseButton: true,
              showConfirmButton: false,
              buttonsStyling: false,
              customClass: {
                confirmButton: "btn-amarillo order-2 mx-1",
                cancelButton: "btn-brown order-1 mx-1",
                closeButton: "btn-brown order-1 mx-1",
              },
            });
          },
        });
    }
  }

  rechazar() {
    Swal.fire({
      input: "textarea",
      inputLabel: "Motivo de rechazo",
      inputPlaceholder: "Escribe el motivo del rechazo",
      text: "Si rechaza no podrá entrar al sistema.",
      inputAttributes: {
        "aria-label": "Escribe el motivo del rechazo",
      },
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Guardar",
      buttonsStyling: false,
      customClass: {
        confirmButton: "btn-amarillo order-2 mx-1",
        cancelButton: "btn-brown order-1 mx-1",
      },
    }).then(async (response) => {
      if (response.isConfirmed) {
        this.cambiarEstatusSeguimiento("aprobado", "rechazado", response.value);
        // Se  cierra la sesion
        this.usuarioService.logout();
      }
    });
  }

  async cambiarEstatusSeguimiento(
    cveEstatus: string,
    cveEstatusSeguimiento: string,
    comentarios: string
  ) {
    let idSocioFormador = this.usuarioService.getCurrentUserData()
      .idSocioFormador;
    let idUsuario = this.usuarioService.getCurrentUserData().idUsuario;

    let request: ISolicitudSocioComentarios = {
      idSocioFormador: idSocioFormador,
      claveEstatus: cveEstatus,
      claveEstatusSeguimiento: cveEstatusSeguimiento,
      comentarios: comentarios,
      idUsuarioRegistro: idUsuario,
    };

    await lastValueFrom(
      this.solicitudesService.postSolicitudSFP_comentarios(request)
    );
    localStorage.removeItem("localdb");
  }

  async firmaPaso1() {
    try {
      let usuario = await lastValueFrom(
        this.usuarioService.getUsuarioById(
          this.usuarioService.getCurrentUserData().idUsuario
        )
      );

      this.nombreContacto = `${usuario.nombreUsuario} ${usuario.apellidosUsuario}`;
      let res = await lastValueFrom(
        this.sociosFormadoresService.postFlujofirmas({
          tipo: "Colaboracion",
          pasoFirma: 1,
          id: this.colaboracionSel.idColaboracion,
          idUsuarioFirmante: this.usuarioService.getCurrentUserData().idUsuario,
        })
      );

      this.content_firm_modal = this.modalService.open(this.content_firm, {
        centered: true,
      });
    } catch (error:any) {
      Utils.processErrorResponse(error);
    }
  }

  async firmaPaso2(content_firm) {
    try {
      let idUSuarioFirmante = this.usuarioService.getCurrentUserData()
        .idUsuario;

      let res = await lastValueFrom(
        this.sociosFormadoresService.postFlujofirmas({
          tipo: "Colaboracion",
          pasoFirma: 2,
          id: this.colaboracionSel.idColaboracion,
          idUsuarioFirmante: idUSuarioFirmante,
        })
      );

      // borramos todos los convenios anteriores
      //this.borrarConveniosAnteriores();
      // generamos uno nuevo
      try {
        let results: any = await lastValueFrom(
          this.solicitudesService.postGeneraCartaPDF({
            listaIds: [
              {
                campo: "idSocioFormador",
                id: this.IdSocioFormador,
              },
              {
                campo: "idUsuario",
                id: idUSuarioFirmante,
              },
              {
                campo: "idColaboracion",
                id: this.colaboracionSel.idColaboracion,
              },
            ],
          })
        );
        this.filePdfFirmado = results.payload.urlFile;
      } catch (error) {
        console.log(error);
      }

      this.Cartafirmada = true;
      this.personaFirmaCarta = this.nombreContacto; //this.usuarioService.getCurrentUserData().nombreUsuario;
      let comentario = `Convenio firmado por [idUsuario:${idUSuarioFirmante}] ${
        this.personaFirmaCarta
      } el ${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()}`;
      this.cambiarEstatusSeguimiento("aprobado", "firmado", comentario);

      this.fechaFirmaCarta =
        new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString();
      this.content_firm_modal.close();
    } catch (error) {
      console.log(error);
    }
  }

  downloadPDF() {
    saveAs(this.filePdfFirmado, "CartaConvenio.pdf");
  }

  async borrarConveniosAnteriores() {
    try {
      if (this.IdSocioFormador > 0) {
        let result = await lastValueFrom(
          this.sociosFormadoresService.getCartaConvenio(this.IdSocioFormador)
        );
        let lstConvenio = result.sort((x, y) => y.idConvenio - x.idConvenio);
        let idUsuario = this.usuarioService.getCurrentUserData().idUsuario;

        for (let index = 1; index < lstConvenio.length; index++) {
          await lastValueFrom(
            this.sociosFormadoresService.deleteCartaConvenio(
              lstConvenio[index].idConvenio,
              idUsuario
            )
          );
        }
      }
    } catch (error:any) {
      Utils.processErrorResponse(error);
    }
  }

  async preguntarInscritos() {
    Swal.fire({
      input: "text",
      inputLabel: "Escribe el número de inscritos",
      inputPlaceholder: "",
      inputAttributes: {
        "aria-label": "Escribe el número de inscritos",
      },
      showCancelButton: true,
    }).then((response) => {
      if (response.isConfirmed) {
        this.lblAlumnosInscritos = response.value;
        this.inscritos = response.value;
        this.documentar(this.SECCION_INSCRITOS);
      }
    });
  }

  mostrarComentarios(){

    Swal.fire({
      title:'Comentarios al Socio Formador',
      text: 'Próximamente ...'

    })

  }
  datosCartaColaboracion:any;
  setContacto(event){
    this.datosCartaColaboracion = event;
  }

  sinCarta(){
    return this.datosCartaColaboracion.tieneCarta;
  }

  cambiodeSF(event){
    // se notifica al componente de las cartas que se actualice
    this.borrarCartas = true;
  }
  cartaEliminada(event){
    this.borrarCartas = false;
  }

  showhideSFForm(event){
    this.bShowHideSFForm = event;
  }
  showhideCartaForm(event){
    this.bShowHideCartaForm = event;
  }

}

function leftPad(str: string, len: number, ch = "0"): string {
  len = len - str.length + 1;
  return len > 0 ? new Array(len).join(ch) + str : str;
}
