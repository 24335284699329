import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-confirmacion-vinculacion',
  templateUrl: './confirmacion-vinculacion.component.html',
  styleUrls: ['./confirmacion-vinculacion.component.css']
})
export class ConfirmacionVinculacionComponent implements OnInit {
  titleConfirmReto = {
    title: 'Embotelladora AGA del Centro, S.A. de C.V.',
  };
  imgUrlSocio: string;

  constructor(private router: Router) { }

  ngOnInit(): void {
    let tmpMatchObj = localStorage.getItem('matchSelected')||'{}';
    const MatchObj = JSON.parse(tmpMatchObj);
    if (MatchObj) {
      this.titleConfirmReto = {
        title: MatchObj.socioFormador,
      };
      this.imgUrlSocio = MatchObj.urlLogoSocioFormador;
    }
  }
  irAmisSolicitudes() {
    localStorage.removeItem('matchSelected');
    localStorage.removeItem('idReto')
    localStorage.removeItem('idSocioFormador');
    localStorage.removeItem('MatchObj');
    this.router.navigate(['/dashboard']);
  }

}
