<button class="{{ getClass(button) }}" *ngFor="let button of elementsButton; let i = index" (click)="listenerCallback(button)">

  <svg *ngIf="getClass(button) === 'lst_bt_edt'" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 32 32"
    style="enable-background:new 0 0 32 32;" xml:space="preserve">
    <style type="text/css">
      .st0-b {
        fill: #E9F5FE;
      }

      .st1-b {
        fill-rule: evenodd;
        clip-rule: evenodd;
        fill: #4A6BB6;
      }

      .st2-b {
        fill: #4A6BB6;
      }
    </style>
    <path class="st0-b" d="M0,8c0-4.4,3.6-8,8-8h16c4.4,0,8,3.6,8,8v16c0,4.4-3.6,8-8,8H8c-4.4,0-8-3.6-8-8V8z" />
    <path class="st1-b" d="M22.7,14.2l1.1-1.1c0.1-0.1,0.2-0.2,0.3-0.3v7.5L20.3,24H8V10c0-0.5,0.2-1,0.6-1.4C9,8.2,9.5,8,10,8h9.2
      c-0.1,0.1-0.2,0.2-0.3,0.3l-1.1,1.1H10c-0.2,0-0.3,0.1-0.5,0.2C9.4,9.7,9.3,9.8,9.3,10v12.7h9.3v-4h4V14.2z M20,20v2.4l2.4-2.4H20z
      M14.3,20.7h-2.9v-2.9l9.1-9.1C20.8,8.3,21.4,8,21.9,8c0.5,0,1.1,0.2,1.5,0.6C23.8,9,24,9.5,24,10.1c0,0.6-0.2,1.1-0.6,1.5
      L14.3,20.7z M21.4,9.6l-8.7,8.7v1.1h1.1l8.7-8.7c0.1-0.1,0.2-0.3,0.2-0.5c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.3-0.2-0.5-0.2
      C21.7,9.4,21.5,9.4,21.4,9.6z" />
    <path class="st2-b" d="M24,0H8C3.6,0,0,3.6,0,8v16c0,4.4,3.6,8,8,8h16c4.4,0,8-3.6,8-8V8C32,3.6,28.4,0,24,0z M31,24c0,3.9-3.1,7-7,7
      H8c-3.9,0-7-3.1-7-7V8c0-3.9,3.1-7,7-7h16c3.9,0,7,3.1,7,7V24z" />
  </svg>
  
  <svg *ngIf="getClass(button) === 'lst_bt_brr'" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 32 32"
    style="enable-background:new 0 0 32 32;" xml:space="preserve">
    <style type="text/css">
      .st0 {
        fill: #FCD1CA;
      }

      .st1 {
        fill-rule: evenodd;
        clip-rule: evenodd;
        fill: #CE0032;
      }

      .st2 {
        fill: #CE0032;
      }
    </style>
    <path class="st0" d="M0,8c0-4.4,3.6-8,8-8h16c4.4,0,8,3.6,8,8v16c0,4.4-3.6,8-8,8H8c-4.4,0-8-3.6-8-8V8z" />
    <path class="st1" d="M22.7,10.7h-3.3V9.3c0-0.4-0.1-0.7-0.4-0.9C18.7,8.1,18.4,8,18,8h-4c-0.4,0-0.7,0.1-0.9,0.4
      c-0.2,0.3-0.4,0.6-0.4,0.9v1.3H9.3V12h1.3v10c0,0.5,0.2,1,0.6,1.4c0.4,0.4,0.9,0.6,1.4,0.6h6.7c0.5,0,1-0.2,1.4-0.6
      c0.4-0.4,0.6-0.9,0.6-1.4V12h1.3V10.7z M14,9.3h4v1.3h-4V9.3z M20,22c0,0.2-0.1,0.3-0.2,0.5s-0.3,0.2-0.5,0.2h-6.7
      c-0.2,0-0.3-0.1-0.5-0.2S12,22.2,12,22V12h8V22z M15.3,14.7H14V20h1.3V14.7z M16.7,14.7H18V20h-1.3V14.7z" />
    <path class="st2" d="M24,0H8C3.6,0,0,3.6,0,8v16c0,4.4,3.6,8,8,8h16c4.4,0,8-3.6,8-8V8C32,3.6,28.4,0,24,0z M31,24c0,3.9-3.1,7-7,7
      H8c-3.9,0-7-3.1-7-7V8c0-3.9,3.1-7,7-7h16c3.9,0,7,3.1,7,7V24z" />
  </svg>
</button>