<app-header-buscador [showSearchBar]="false"></app-header-buscador>

<app-typeform idForm="PskHkKRb" *ngIf="cartaGenerada"></app-typeform>

<div>

  <div>

    <!-- título Solicitud Socio Formador Potencial -->
    <div class="row">
      <div class="col-md-12">
        <label class="titlesprincipal">{{ "solicitud_sf_potencial-rechazo.titulo" | translate }}</label><br />
        <app-btn-regresar></app-btn-regresar>
      </div>
    </div>

  </div>


  <div class="row mt-5" *ngIf="socioFormador !== null && socioFormador != undefined">


    <div class="col-md-12">

      <!-- Estatus de la solicitud -->
      <div *ngIf="['registrado','revision','rechazado'].includes(solicitud.claveEstatus.trim())">
        <label class="subtitleseccard mb-2">
          {{ "solicitud_sf_potencial-rechazo.titulo_estatus" | translate }}
        </label>
      </div>
      <div class="container-datos-socios" *ngIf="['registrado','revision','rechazado'].includes(solicitud.claveEstatus.trim())">

        <div class="row">

          <form [formGroup]="statusSolicitudForm">
            <div class="row py-3">

              <!-- Primer contacto -->
              <div class="col-xs-12 col-sm-3 col-md-2  statuSolicitudCenter">

                <label class="rcontainer statuSolicitud">
                  <input type="radio" class="btn-check" id="primer-contacto" value="primer-contacto" name="claveEstatus"
                    formControlName="claveEstatus" />
                  <label class="btn btn-outline-primary" for="primer-contacto"></label>
                  <br>
                  Primer Contacto
                </label>
              </div>

              <!-- Conversación Inicial -->
              <div class="col-xs-12 col-sm-3 col-md-2 statuSolicitudCenter">
                <label class="rcontainer statuSolicitud">
                  <input type="radio" class="btn-check" id="conversacion" value="conversacion" name="claveEstatus"
                    formControlName="claveEstatus" />
                  <label class="btn btn-outline-primary" for="conversacion"></label>
                  <br>
                  Conversación Inicial
                </label>
              </div>

              <!-- Validacion de documentos -->
              <div class="col-xs-12 col-sm-3 col-md-3 statuSolicitudCenter">
                <label class="rcontainer statuSolicitud">
                  <input type="radio" class="btn-check" id="validacion-doc" value="validacion-doc" name="claveEstatus"
                    formControlName="claveEstatus" />
                  <label class="btn btn-outline-primary" for="validacion-doc"></label>
                  <br>
                  Validación de documentos
                </label>
              </div>

              <!-- Botón de Guardar -->
              <div class="col-sm-5 col-xs-12 d-flex justify-content-end ">
                <button type="button" class="btn-amarillo mt-1" (click)="guardarEstatus()">Guardar</button>
              </div>

              <!-- Sección de comentarios -->
              <div class="col-sm-9 my-3">
                <div class=" col-xs-12 col-sm-8 col-md-8 ">
                  <textarea style="border-radius: 5px; width: 100%; margin-left:7.5%" rows="5"
                    formControlName="comentarios" placeholder="Escribe un comentario"></textarea>
                </div>
                <div class="requerido"
                  *ngIf="statusSolicitudForm.get('comentarios')?.touched && statusSolicitudForm.get('comentarios')?.errors?.['required']">
                  Escribe un comentario.
                </div>
              </div>

            </div>
          </form>
        </div>

      </div>


      <div>
        <label class="subtitleseccard mb-3 mt-5">
          {{ "solicitud_sf_potencial-rechazo.titulo_datos_socio_formador" | translate }}
        </label>
      </div>

      <!-- alertas de estatus -->
      <div class="col-md-12">
        <!-- aprobado -->
        <div class="container-alert" *ngIf="solicitud !== undefined && solicitud.claveEstatus.trim() === 'aprobado' ">
          <div class="row">
            <div class="col-md-12 mt-2 mb-2">
              <img alt="Aprobar" class="icono-success" src="assets/img/success.svg" /> <span class="textsuccess">{{
                "solicitud_sf_potencial-rechazo.alerta_aprobar_solicitud" | translate }} {{ solicitud.actualizadoPor
                }} el {{ solicitud.fechaActualizacion | date:"dd/MMM/yy HH:mm" }}</span>
            </div>
          </div>
        </div>

        <!-- rechazado -->
        <div class="container-alert-rechazo"
          *ngIf="solicitud !== undefined && solicitud.claveEstatus.trim() === 'rechazado' ">
          <div class="row">
            <div class="col-md-12 mt-2 mb-2">

              <img alt="Rechazar" class="icono-success" src="assets/img/icons8-close.svg" />

              <span class="textrechazo">
                {{"solicitud_sf_potencial-rechazo.alerta_rechazo_solicitud" | translate }}
                {{ solicitud.actualizadoPor }} el
                {{ solicitud.fechaActualizacion | date:"dd/MMM/yy HH:mm" }}
                <div class="ms-5"> Motivo: {{ solicitud.motivoRechazo}}</div>
              </span>

            </div>
          </div>
        </div>

        <div class="row">

          <mat-accordion>
            <mat-expansion-panel class="my-2 px-3" (opened)="panelOpenState = true" (closed)="panelOpenState = false">

              <!-- Opción A -->
              <mat-expansion-panel-header class="heigh50">
                <div>
                  <span class="title-option">Ver comentarios </span><br />
                  Último status de seguimiento: {{ socioFormador.estatusSeguimiento }}
                </div>

              </mat-expansion-panel-header>

              <div class="maxHeight">
                <div class="col-12" *ngFor="let com of comentariosData">
                  <div>
                    Fecha: {{com.fechaRegistro}}<br />
                    Estatus: {{obtenerEstatusSeguimiento(com.claveEstatusSeguimiento)}}<br />
                    Comentarios: {{ com.comentarios}}
                  </div>
                  <hr>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>

        <!-- Datos del socio -->
        <div class="container-datos-socios">

          <div class="row">

            <div class="col-12" *ngIf="['registrado','revision','rechazado'].includes(solicitud.claveEstatus.trim())">
              <!-- botón de editar -->
              <div class="d-flex justify-content-end">
                <!-- Editar -->
                <div class="mb-3">
                  <button class="yell_button" (click)="editarSocioFormador(socioFormador.idSocioFormador)">
                    <img alt="" ngbTooltip="Editar" src="assets/img/editar.svg" class="btn_editar">
                    {{ 'academia_banco_socio_formador.editar_socio_formador' | translate }} Solicitud
                  </button>
                </div>
              </div>
            </div>

            <div class="col-xs-12">

              <div class="row">
                <div class="col-xs-12 col-sm-10">
                  <span class="txt-option socio-tit-desc">{{ socioFormador.nombreSocioFormador }}<br></span>
                  <span><a href="{{ socioFormador.paginaWeb }}" referrerpolicy="noopener noreferrer" target="_blank">{{
                      socioFormador.paginaWeb }}</a></span><br />
                  <span class="txt-option socio-desc">{{ socioFormador.empresa }}</span>
                </div>
              </div>

            </div>

            <!-- rfc -->
            <div class="col-md-12 mt-4">
              <span class="title-option">
                {{ "solicitud_sf_potencial-rechazo.RFC" | translate }}
              </span>
              <span class="txt-option">
                {{ socioFormador.rfc }}</span>
            </div>

            <!-- nombre organizacion -->
            <div class="col-md-12 mt-4">
              <span class="title-option">
                {{ "solicitud_sf_potencial-rechazo.nombre_empresa" | translate }}
              </span><br />
              <span class="txt-option"> {{ socioFormador.razonSocial }} </span>
            </div>

            <!-- Corporativo -->
            <div class="col-md-12 mt-4">
              <span class="title-option">
                {{ "solicitud_sf_potencial-rechazo.grupo_comercial_empresa" | translate }}
              </span><br />
              <span class="txt-option"> {{ socioFormador.grupoComercial }} </span>
            </div>

            <!-- página web -->
            <div class="col-md-12 mt-4">
              <span class="title-option">Sitio Web:</span><br />
              <span class="txt-option"> {{ socioFormador.paginaWeb }} </span>
            </div>

            <!-- CP -->
            <div class="col-md-2 mt-4">
              <span class="title-option">
                {{ "solicitud_sf_potencial-rechazo.codigo_postal" | translate }}
              </span><br />
              <span class="txt-option"> {{ socioFormador.cp }} </span>
            </div>

            <!-- pais -->
            <div class="col-md-2 mt-4">
              <span class="title-option">
                {{ "solicitud_sf_potencial-rechazo.pais" | translate }}
              </span><br />
              <span class="txt-option"> {{ socioFormador.pais }} </span>
            </div>

            <!-- estado -->
            <div class="col-md-2 mt-4">
              <span class="title-option">{{ "solicitud_sf_potencial-rechazo.estado" | translate }}</span><br />
              <span class="txt-option"> {{ (socioFormador.claveEstado !== null && socioFormador.claveEstado !==
                undefined)
                ? (getEstadosByClave(socioFormador.claveEstado)) : "" }} </span>
            </div>

            <!-- ciudad -->
            <div class="col-md-2 mt-4">
              <span class="title-option">{{ "solicitud_sf_potencial-rechazo.ciudad" | translate }}</span><br />
              <span class="txt-option"> {{ socioFormador.ciudad }} </span>
            </div>

            <!-- colonia -->
            <div class="col-md-2 mt-4">
              <span class="title-option">{{ "solicitud_sf_potencial-rechazo.colonia" | translate }}</span><br />
              <span class="txt-option"> {{ socioFormador.colonia }} </span>
            </div>

            <!-- calle -->
            <div class="col-md-2 mt-4">
              <span class="title-option">{{ "solicitud_sf_potencial-rechazo.calle" | translate }}</span><br />
              <span class="txt-option"> {{ socioFormador.calle }} </span>
            </div>

            <!-- industria -->
            <div class="col-md-12 mt-4">
              <span class="title-option">{{ "solicitud_sf_potencial-rechazo.industria" | translate }}</span><br />
              <span class="txt-option"> {{ socioFormador.industria }} </span>
            </div>

            <!-- sector -->
            <div class="col-md-2 mt-4">
              <span class="title-option">{{ "solicitud_sf_potencial-rechazo.sector" | translate }}</span><br />
              <span class="txt-option"> {{ socioFormador.sector }} </span>
            </div>

            <!-- presencia -->
            <div class="col-md-2 mt-4">
              <span class="title-option">{{ "solicitud_sf_potencial-rechazo.presencia" | translate }}</span><br />
              <span class="txt-option"> {{ socioFormador.presenciaC ? socioFormador.presenciaC : '-' }} </span>
            </div>

            <!-- tamaño -->
            <div class="col-md-3 mt-4">
              <span class="title-option">{{ "solicitud_sf_potencial-rechazo.tamano_empresa" | translate
                }}</span><br />
              <span class="txt-option"> {{ socioFormador.tamano }} </span>
            </div>

            <!-- campus donde desea ser ateniddo -->
            <div class="col-md-12 mt-4">
              <span class="title-option">{{ "solicitud_sf_potencial-rechazo.campus_atendido" | translate
                }}</span><br />
              <span class="txt-option"> {{ socioFormador.campus }} </span>
            </div>

            <!-- relacion con el tec -->
            <div class="col-md-4 mt-4">
              <span class="title-option">{{ "solicitud_sf_potencial-rechazo.relacion_tec" | translate
                }}</span><br />
              <span class="txt-option"> {{ relacionTec }} </span>
            </div>

            <!-- Conoce el rol de SF -->
            <div class="col-md-8 mt-4">
              <span class="title-option">
                {{ "solicitud_sf_potencial-rechazo.conoce_socio" | translate }}
              </span><br />
              <span class="txt-option">{{ socioFormador.conoceRolSF ? 'Si' : 'No' }} </span>
            </div>


            <!-- Información de Contacto -->
            <div class="col-md-12 mt-4">
              <span class="title-option">{{ "solicitud_sf_potencial-rechazo.info_contact" | translate }} </span>

              <div class="row my-3" *ngFor="let contacto of contactos">
                <!-- Nombre -->
                <div class="col-sm-6 col-xs-12">
                  <span class="title-suboption">{{ "solicitud_sf_potencial-rechazo.nombre" | translate }}</span><br />
                  <span class="txt-option">{{ contacto.nombre }} </span>
                </div>
                <!-- correo -->
                <div class="col-sm-6 col-xs-12">
                  <span class="title-suboption">{{ "solicitud_sf_potencial-rechazo.correo" | translate }}</span><br />
                  <span class="txt-option">{{ contacto.email }}</span>
                </div>
                <!-- apellidos-->
                <div class="col-sm-6 col-xs-12">
                  <span class="title-suboption">{{ "solicitud_sf_potencial-rechazo.apellidos" | translate
                    }}</span><br />
                  <span class="txt-option"> {{ contacto.apellidos }}</span>
                </div>
                <!-- telefono oficina -->
                <div class="col-sm-6 col-xs-12">
                  <span class="title-suboption">{{ "solicitud_sf_potencial-rechazo.tel_oficina" | translate }}
                  </span><br />
                  <span class="txt-option"> {{ contacto.telefonoOficina ? contacto.telefonoOficina : '-' }}</span>
                </div>
                <!-- area -->
                <div class="col-sm-6 col-xs-12">
                  <span class="title-suboption">{{ "solicitud_sf_potencial-rechazo.area" | translate }} </span><br />
                  <span class="txt-option">{{ getAreaDescription(contacto.area) }}</span>
                </div>

                <!-- telefono celular -->
                <div class="col-sm-6 col-xs-12">
                  <span class="title-suboption">{{ "solicitud_sf_potencial-rechazo.tel_celular" | translate }}
                  </span><br />
                  <span class="txt-option"> {{ contacto.telefonoMovil ? contacto.telefonoMovil : '-' }}</span>
                </div>

                <!-- puesto -->
                <div class="col-sm-6 col-xs-12">
                  <span class="title-suboption">{{ "solicitud_sf_potencial-rechazo.puesto" | translate }} </span><br />
                  <span class="txt-option">{{ contacto.puesto }} </span>
                </div>

                <!-- exatec -->
                <div class="col-sm-6 col-xs-12">
                  <span class="title-suboption">{{ "solicitud_sf_potencial-rechazo.eres_exatec" | translate
                    }}</span><br />
                  <span class="txt-option">{{ contacto.exatec ? 'Si' : 'No' }} </span>
                </div>

                <div class="col-sm-6 "></div>
                <!-- medio de contacto -->
                <div class="col-sm-6 col-xs-12">
                  <span class="title-suboption">{{ "solicitud_sf_potencial-rechazo.como_enteraste" | translate }}
                  </span><br />
                  <span class="txt-option">{{ obtenerMedioContacto(contacto.idMedioContacto, contacto.otroMedioContacto)
                    }} </span>
                </div>
                <br /><br />


              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!-- botonera -->
    <div class="row justify-content-center mt-5 pt-5"
      *ngIf="socioFormador !== null && socioFormador != undefined && solicitud !== undefined &&
      ['cvdp','cvdp_admin'].includes(userStore.clavePerfil) &&
            ['revision','registrado'].includes(solicitud.claveEstatus.trim())">

      <div class="col-md-3">
        <span class="#">
          <button class="green_button"
            *ngIf="['cvdp','cvdp_admin'].includes(userStore.clavePerfil) &&
            ['revision','registrado'].includes(solicitud.claveEstatus.trim())"
            (click)="handleAceptar()">
            <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 15.2 11.06"
              class="iCn replaced-svg">
              <defs>
                <style>
                  .cls-1 {
                    fill: #4c964d;
                  }
                </style>
              </defs>
              <path class="cls-1"
                d="M5.28,13.71a1.74,1.74,0,0,1-.64-.13,1.65,1.65,0,0,1-.54-.36L.44,9.56l1.4-1.39,3.44,3.44,9-9L15.64,4,6.47,13.22a1.69,1.69,0,0,1-.55.36A1.74,1.74,0,0,1,5.28,13.71Z"
                transform="translate(-0.44 -2.65)"></path>
            </svg>
            {{ "solicitud_sf_potencial-rechazo.btn_continuar" | translate }}
          </button></span>
      </div>

      <div class="col-md-3">
        <span class="#">
          <button class="pink_button"
          *ngIf="['cvdp','cvdp_admin'].includes(userStore.clavePerfil) &&
          ['revision','registrado'].includes(solicitud.claveEstatus.trim())"
            (click)="handleRechazar()">
            <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 12.91 12.91"
              class="iCn replaced-svg">
              <defs>
                <style>
                  .cls-1 {
                    fill: #ce0032;
                  }
                </style>
              </defs>
              <path class="cls-1"
                d="M14.47,13.14l-1.13,1.14L8,9,2.7,14.28,1.56,13.14,6.88,7.82,1.56,2.5,2.7,1.37,8,6.69l5.32-5.32L14.47,2.5,9.15,7.82Z"
                transform="translate(-1.56 -1.37)"></path>
            </svg>
            {{ "solicitud_sf_potencial-rechazo.btn_rechazar" | translate }}
          </button></span><br />
      </div>

      <div class="col-md-3">
        <span class="#">
          <button class="blue_button"
            *ngIf="['cvdp','cvdp_admin'].includes(userStore.clavePerfil) &&
              ['revision','registrado'].includes(solicitud.claveEstatus.trim())"
            (click)="handleReasignar()">
            {{ "solicitud_sf_potencial-rechazo.btn_reasignar" | translate }}
          </button>
        </span>
      </div>

    </div>


    <!-- Formulario para Continuar -->
    <div class="row" *ngIf="showFormularioContinuar">
      <div class="col-md-12" *ngIf="!showFormularioCancelacion && !showFormularioReasignacion">
        <!--Carta Solicitud-->
        <div>
          <label class="subtitleseccard my-2 mt-5">
            {{ "solicitud_sf_potencial-rechazo.carta_solicitud" | translate }}
          </label>
        </div>

        <mat-accordion>
          <mat-expansion-panel class="py-3 px-3" (opened)="panelOpenState = true" (closed)="panelOpenState = false">

            <!-- Opción A -->
            <mat-expansion-panel-header>

              <div>
                <span class="title-option">a. Ya cuento con una carta.</span> Si ya tienes la carta de solicitud de este
                Socio Formador llenada y
                firmada, cárgala aquí. Aplica para las cartas con características diferentes a los estándares. Si
                requiere el documento da <button type="button" class="btn btn-link btn_aqui"
                  (click)="mostrarOpcionesDeCarta()">AQUÍ </button> para descargarlo.
              </div>

            </mat-expansion-panel-header>
            <!-- Agregar nuevo convenio -->
            <div>
              <form class="frm_registro_sf" [formGroup]="convenioForm">

                <div class="col-3 mt-1">
                  <label for="fechaInicio">Fecha inicial de la carta de solicitud: <span class="requerido">*</span></label>
                </div>
                <div class="col-3">
                  <input class="form-control" type="date" id="fechaInicio" formControlName="fechaInicio">
                  <div class="requerido"
                    *ngIf="convenioForm.get('fechaInicio')?.touched && convenioForm.get('fechaInicio')?.errors?.['required'] ">
                    Este campo es requerido
                  </div>
                </div>


                <div class="col-3 mt-1">
                  <label for="fechaFin">Fecha final de la carta de solicitud: <span class="requerido">*</span></label>
                  <input class="form-control" type="date" id="fechaFin" formControlName="fechaFin">
                  <div class="requerido"
                    *ngIf="convenioForm.get('fechaFin')?.touched && convenioForm.get('fechaFin')?.errors?.['required'] ">
                    Este campo es requerido
                  </div>
                </div>

                <div class="col-3 mt-1">
                  <label for="personaFirma">Persona que firma: <span class="requerido">*</span></label>
                  <ng-select [items]="contactos" bindValue="idUsuario" bindLabel="nombreContacto"
                    formControlName="personaFirma">
                  </ng-select>
                  <div class="requerido" *ngIf="mostrarPersonaFirmaRequerido">
                    Este campo es requerido
                  </div>
                </div>

                <div class="col-5 mt-3">
                  <label for="fechaInicio">Convenio: &nbsp;</label>
                  <button type="button" class="btn-amarillo" (click)="file.click()">Seleccionar archivo</button>
                  <input class="d-none" type="file" (change)="selImagen($event)" accept="application/pdf" #file />
                  <div>{{ fileNameSelected }}</div>
                  <div class="requerido" *ngIf="mostrarArchivoRequerido">
                    Este campo es requerido
                  </div>

                </div>

              </form>

            </div>
            <div class="col-auto my-2">
              <p class="txtRed"><b>IMPORTANTE:</b> Al dar aceptar, la persona de contacto del Socio Formador recibirá un
                correo con su
                acceso a la plataforma</p>
            </div>

            <div class="row d-flex justify-content-end">

              <div class="col-md-3">
                <span class="#"><button class="green_button" type="button"
                    *ngIf="['cvdp','cvdp_admin'].includes(userStore.clavePerfil)" (click)="continuarCarta()">
                    <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 15.2 11.06"
                      class="iCn replaced-svg">
                      <defs>
                        <style>
                          .cls-1 {
                            fill: #4c964d;
                          }
                        </style>
                      </defs>
                      <path class="cls-1"
                        d="M5.28,13.71a1.74,1.74,0,0,1-.64-.13,1.65,1.65,0,0,1-.54-.36L.44,9.56l1.4-1.39,3.44,3.44,9-9L15.64,4,6.47,13.22a1.69,1.69,0,0,1-.55.36A1.74,1.74,0,0,1,5.28,13.71Z"
                        transform="translate(-0.44 -2.65)"></path>
                    </svg>
                    Aceptar
                  </button></span>
              </div>

            </div>

          </mat-expansion-panel>

          <!--Opcion B-->
          <mat-expansion-panel class="py-3 px-3" (opened)="panelOpenState = true" (closed)="panelOpenState = false">

            <mat-expansion-panel-header>

              <span class="title-option">b. Necesito crear una carta solicitud. </span>
              <p>&nbsp;Da clic aquí si deseas generarla y enviarla.
            </mat-expansion-panel-header>

            <!-- Generar y enviar una carta -->
            <div iv class="row" *ngIf="['cvdp', 'cvdp_admin'].includes(this.userStore.clavePerfil)">

              <div class="row">
                <form [formGroup]="convenioForm2" (submit)="generarContrato()">


                  <div class="col-xs-12 col-sm-6">
                    <p>Selecciona si el Socio Formador es persona física o persona moral. <span
                        class="requerido">*</span></p>

                    <ng-select formControlName="tipoPersona" id="tipoPersona" name="tipoPersona" placeholder="Selecciona una opción">
                      <ng-option value="fisica">Persona física</ng-option>
                      <ng-option value="moral">Persona moral</ng-option>
                    </ng-select>

                    <div class="requerido"
                      *ngIf="convenioForm2.get('tipoPersona')?.touched && convenioForm2.get('tipoPersona')?.errors?.['required']">
                      Este campo es requerido
                    </div>

                  </div>

                  <div class="col-xs-12 col-sm-4 ">
                    <div class="mt-1">
                      <label for="fechaInicio1">Fecha inicial de la carta de solicitud: <span class="requerido">*</span></label>
                      <input class="form-control mt-2" type="date" id="fechaInicio" formControlName="fechaInicio">
                      <div class="requerido"
                        *ngIf="convenioForm2.get('fechaInicio')?.touched && convenioForm2.get('fechaInicio')?.errors?.['required']">
                        Este campo es requerido
                      </div>
                    </div>
                  </div>

                  <div class="col-xs-12 col-sm-4 ">
                    <div class="mt-1">
                      <label for="fechaFin1">Fecha Final de la carta de solicitud: <span class="requerido">*</span></label>
                      <input class="form-control mt-2" type="date" id="fechaFin" formControlName="fechaFin">
                      <div class="requerido"
                        *ngIf="convenioForm2.get('fechaFin')?.touched && convenioForm2.get('fechaFin')?.errors?.['required']">
                        Este campo es requerido
                      </div>
                    </div>
                  </div>

                  <div class="col-12 float-end my-3 ">
                    <button type="submit" class="b1tn btn-amarillo">Generar</button>
                  </div>

                </form>

              </div>

              <div class="col-12">
                <ngx-extended-pdf-viewer class="pdf-content" [showBookModeButton]="false" [showDrawEditor]="false"
                  [showFindButton]="false" [showRotateButton]="false" [height]="'500px'" [showOpenFileButton]="false"
                  [showPropertiesButton]="false" [showTextEditor]="false" [showStampEditor]="false"
                  [src]="urlPDFGenerado2"></ngx-extended-pdf-viewer>
              </div>

            </div>

            <div>

              <div class="my-4">
                <div class="txtRed my-2">
                  IMPORTANTE:
                </div>
                <div class="txtRed">
                  <p>Antes de aceptar esta solicitud, asegúrate de haber recibido todos los documentos del SF y de validar que sus datos sean correctos. Si la persona de la organización que va a firmar la carta es diferente a la que envió la solicitud, asegúrate de autorizar como contacto secundario a la nueva persona.
                  </p>
                  <p>
                    Esta carta de solicitud tiene una vigencia de 5 años, si requieres una temporalidad o cacterísticas
                    diferentes, acércate con el área legal para personalizarlo y una vez que lo tengas listo, cárgalo en
                    <span class="">a. Ya cuento con una carta.</span>
                  </p>
                </div>

                <div class="my-4 d-flex align-items-center">
                  <input type="checkbox" value="si" id="documentosEntregados" [formControl]="chkDocumentosEntregados">
                  <label for="documentosEntregados" class="mx-3">El Socio Formador ha entregado todos los
                    documentos y los datos son correctos.</label>
                </div>
              </div>

            </div>

            <div class="row d-flex justify-content-end">

              <div class="col-md-3">
                <span class="#"><button class="green_button"
                    *ngIf="['cvdp','cvdp_admin'].includes(userStore.clavePerfil)" (click)="continuarCarta2()">
                    <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 15.2 11.06"
                      class="iCn replaced-svg">
                      <defs>
                        <style>
                          .cls-1 {
                            fill: #4c964d;
                          }
                        </style>
                      </defs>
                      <path class="cls-1"
                        d="M5.28,13.71a1.74,1.74,0,0,1-.64-.13,1.65,1.65,0,0,1-.54-.36L.44,9.56l1.4-1.39,3.44,3.44,9-9L15.64,4,6.47,13.22a1.69,1.69,0,0,1-.55.36A1.74,1.74,0,0,1,5.28,13.71Z"
                        transform="translate(-0.44 -2.65)"></path>
                    </svg>
                    Enviar
                  </button></span>
              </div>

            </div>

          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>

    <!-- Formulario de Cancelación o rechazo -->
    <div class="row" *ngIf="showFormularioCancelacion">
      <div class="col-md-12">
        <div class="container-motivos-rechazo">

          <div class="row">

            <div class="row">
              <div class="col-12 mt-4">
                <span class="txt-option">{{ "solicitud_sf_potencial-rechazo.motivo_rechazo_solicitud" | translate
                  }}</span> <span class="requerido">*</span><br /><br />
                <textarea style="border-radius: 5px; width: 100%;" rows="7" [(ngModel)]="motivosRechazo"></textarea>
              </div>
            </div>

            <div class="row" *ngIf="motivosRequeridos.trim().length > 0">
              <div class="col-md-12">
                <p class="mt-2 mensaje-requerido">{{ '*' + motivosRequeridos }}</p>
              </div>
            </div>

            <div class="requerido" *ngIf="mostrarComentarioRequerido">
              Este campo es requerido
            </div>

            <div class="row botones-accion">

              <div class="col-auto mt-4">
                <button class="enviar_button" (click)="actualizarSolicitud('rechazado', false,'C')"> {{
                  "solicitud_sf_potencial-rechazo.btn_enviar" | translate }}</button>
              </div>

            </div>

          </div>
        </div>
      </div>
    </div>

    <!-- Formulario de Reasignación -->
    <div class="row" *ngIf="showFormularioReasignacion">
      <div class="col-md-12">
        <div class="container-motivos-rechazo">
          <div class="row">

            <div class="row">
              <div class="col-md-12 col-lg-10 col-xl-6 col-sm-12 col-12 mt-4">
                <span class="txt-option">{{ "solicitud_sf_potencial-rechazo.reasignacion_label" | translate
                  }}</span><br /><br />
              </div>
            </div>
            <div class="row">

              <ng-select id="campusReasginar" name="campusReasginar" (change)="seleccionarCampus($event)"
                bindLabel="campus" bindValue="idCampus" [items]="campusData"
                placeholder="Selecciona un campus ... "></ng-select>

            </div>
            <div class="row botones-accion">
              <div class="col-auto mt-4">
                <button class="enviar_button" (click)="reasignarSolicitud()">
                  {{ "solicitud_sf_potencial-rechazo.btn_enviar" | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="row mt-4"
        *ngIf="socioFormador !== null && socioFormador != undefined && solicitud != undefined && solicitud.claveEstatus.trim() === 'rechazado'">
        <div>
          <label class="subtitleseccard mb-2">{{ "solicitud_sf_potencial-rechazo.motivo_rechazo_title" | translate
            }}</label>
        </div>
        <div class="col-md-10">
          <div class="container-contacto-enlace">
            <div class="row">
              <div class="col-md-12 mt-4">
                <span class="title-option">{{ "solicitud_sf_potencial-rechazo.area_canalizada_seleccionadas" |
                  translate
                  }}</span><br />
                <span class="txt-option"> {{ solicitud.areaCanalizacion }} </span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 mt-4">
                <span class="title-option">{{ "solicitud_sf_potencial-rechazo.detalles_rechazo_solicitud" | translate
                  }}</span><br />
                <span class="txt-option"> {{ solicitud.motivoRechazo }} </span>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>


    <!-- botonera -->
    <div class="row justify-content-center mt-5"
      *ngIf="socioFormador !== null && socioFormador != undefined && solicitud !== undefined ">

      <div class="col-md-3">
        <span class="#">
          <button class="green_button"
            *ngIf="['cvdp','cvdp_admin'].includes(userStore.clavePerfil) &&
            ['revision1','registrado1'].includes(solicitud.claveEstatus.trim())"
            (click)="handleAceptar()">
            <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 15.2 11.06"
              class="iCn replaced-svg">
              <defs>
                <style>
                  .cls-1 {
                    fill: #4c964d;
                  }
                </style>
              </defs>
              <path class="cls-1"
                d="M5.28,13.71a1.74,1.74,0,0,1-.64-.13,1.65,1.65,0,0,1-.54-.36L.44,9.56l1.4-1.39,3.44,3.44,9-9L15.64,4,6.47,13.22a1.69,1.69,0,0,1-.55.36A1.74,1.74,0,0,1,5.28,13.71Z"
                transform="translate(-0.44 -2.65)"></path>
            </svg>
            {{ "solicitud_sf_potencial-rechazo.btn_continuar" | translate }}
          </button></span>
      </div>

      <div class="col-md-3">
        <span class="#">
          <button class="pink_button"
          *ngIf="['cvdp','cvdp_admin'].includes(userStore.clavePerfil) &&
          ['aprobado'].includes(solicitud.claveEstatus.trim())"
            (click)="handleRechazar()">
            <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 12.91 12.91"
              class="iCn replaced-svg">
              <defs>
                <style>
                  .cls-1 {
                    fill: #ce0032;
                  }
                </style>
              </defs>
              <path class="cls-1"
                d="M14.47,13.14l-1.13,1.14L8,9,2.7,14.28,1.56,13.14,6.88,7.82,1.56,2.5,2.7,1.37,8,6.69l5.32-5.32L14.47,2.5,9.15,7.82Z"
                transform="translate(-1.56 -1.37)"></path>
            </svg>
            {{ "solicitud_sf_potencial-rechazo.btn_rechazar" | translate }}
          </button></span><br />
      </div>

      <div class="col-md-3">
        <span class="#">
          <button class="blue_button"
            *ngIf="['cvdp','cvdp_admin'].includes(userStore.clavePerfil) &&
              ['revision1','registrado1'].includes(solicitud.claveEstatus.trim())"
            (click)="handleReasignar()">
            {{ "solicitud_sf_potencial-rechazo.btn_reasignar" | translate }}
          </button>
        </span>
      </div>

    </div>
