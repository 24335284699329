
<div id="c_sl_pa" class="sec_cnt tipo-10 mt-4">
  <div class="d-none d-md-block">

    <div class="cont_tit  d-flex">
      <div class="flex-fill col-1">{{ "retos_activos.fechaRegistro" | translate }}</div>
      <div class="flex-fill col-2">{{ "retos_activos.nombre_reto" | translate }}</div>
      <div class="flex-fill col-1">{{ "retos_activos.duracion" | translate }}</div>
      <div class="flex-fill col-1">{{ "retos_activos.periodo_semanal" | translate }}</div>
      <div class="flex-fill col-2">{{ "retos_activos.socio_formador" | translate }}</div>
      <div class="flex-fill col-2">{{ "retos_activos.contacto_del_sf" | translate }}</div>
      <div class="flex-fill col-1">{{ "retos_activos.campus" | translate }}</div>
      <div class="flex-fill col-1">{{ "retos_activos.periodo_semestral" | translate }}</div>
      <div class="flex-fill col-1">{{ "retos_activos.acciones" | translate }}</div>     
      
      
  </div>

    <div class="roundedsquare cont_fl d-flex" *ngFor="let el of retosActualesAcadList">
      <div class="flex-fill col-1">
        {{ el.fechaRegistro | date:'dd/MMM/yyyy' }}
      </div>
      <div class="flex-fill col-2">
        <a href="/programacion-reto?id={{el.idReto}}">{{ el.reto }}</a>
        <div>{{ el.unidadFormativa }}</div>
      </div>
      
      <div class="flex-fill  col-1">
        {{ el.duracionSemanas }}
      </div>
      <div class="flex-fill col-1">
        {{ el.periodoSemanal }}
      </div>
      <div class="flex-fill col-2">
        <div>{{ el.nombreSocioFormador }}</div>
        <div>{{ el.rfc }}</div>
      </div>

      <div class="flex-fill col-2">
        <div>{{ el.contacto }}</div>
        <div>{{ el.correoContacto }}</div>
      </div>

      <div class="flex-fill col-1">
        {{ el.campusCoordinador }}
      </div>
      <div class="flex-fill col-1">
        {{ el.periodoSemestral }}
      </div>
      
      <div class="flex-fill col-1">
        <app-button (callbackAction)="VerReto(el.idReto)">Ver</app-button>
      </div>

      <div class="flex-fill d-none">
        <div class="w-100">
          {{ el.avanceSemanas + ' de '+el.duracionSemanas }}
          <mat-progress-bar mode="determinate" [appProgressBarColor]="el.porcentajeAvance == 100 ? '#4c964d' : '#93bcfd' " [value]="el.porcentajeAvance"></mat-progress-bar>
        </div>
      </div>
    </div>

    <div *ngIf="retosActualesAcadList.length === 0">
      <app-sin-datos-table></app-sin-datos-table>
  </div>
</div>

  <nav id="pagination" class="d-none d-md-block mt-4" *ngIf="totalPaginas > 0">
    <ul class="pagination justify-content-center">
      <li class="page-item" [class.disabled]="paginaActual==1" >
        <a class="page-link" (click) ="obtenerRegistrosActuales(paginaActual - 1)">{{ "paginador.anterior" | translate }} <img alt="Anterior" class="next_previous"
            src="assets/img/icon pag_ant.svg"></a>
      </li>
      <li class="page-item" *ngFor="let num of pages" [class.active]="paginaActual==num">
        <a class="page-link"  (click) ="obtenerRegistrosActuales(num)" >{{num}}</a>
      </li>
      <li class="page-item" [class.disabled]="paginaActual >=totalPaginas" >
        <a class="page-link" (click) ="obtenerRegistrosActuales(paginaActual + 1)" ><img alt="Siguiente" class="next_previous" src="assets/img/icon pag_sig.svg"> {{ "paginador.siguiente" |
          translate }}</a>
      </li>
    </ul>
    <div class="text-center">{{ "paginador.total_paginador" | translate: {paginaActual: paginaActual, totalPaginas: totalPaginas } }}</div>
  </nav>


  <div class="d-block d-md-none">
    <div class="accordion" id="ac_1">
      <div class="accordion-item" *ngFor="let el of retosActualesMovilAcadList; let pointerIndex=index">
        <h2 class="accordion-header">
          <button class="accordion-button" type="button" data-bs-toggle="collapse"
            [attr.data-bs-target]="'#flush-collapse'+el.idReto" aria-expanded="true">
            {{ el.reto }}
          </button>
        </h2>
        <div id="flush-collapse{{el.idReto}}" class="accordion-collapse collapse show" data-bs-parent="#ac_1">
          <div class="accordion-body">
            <strong>{{ "retos_activos.campus" | translate }}:</strong>&nbsp;{{ "retos_activos.campus" | translate }}<br><strong>{{ "retos_activos.carrera" | translate
            }}:</strong>&nbsp;{{ el.programaAcademico }}<br><strong>{{ "retos_activos.semestre" | translate }}:</strong>&nbsp;{{ el.semestre }}<br><strong>{{
            "retos_activos.inicio" | translate }}:</strong>&nbsp;{{ el.fechaInicio }}<br><br>
              <div class="flex-fill d-flex justify-content-start">
                <div class="w-100">{{ el.avanceSemanas + ' de '+el.duracionSemanas }}
                <mat-progress-bar mode="determinate" [appProgressBarColor]="el.porcentajeAvance == 100 ? '#4c964d' : '#93bcfd' " [value]="el.porcentajeAvance"></mat-progress-bar>
              </div>
              </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-2 d-flex justify-content-center" *ngIf="paginaActualMasResultados < totalPaginas">
      <button [disabled]="paginaActualMasResultados >= totalPaginas" class="mb_mas" (click)="obtenerMasResultados()" ><img alt="Más resultados" class="tam" src="assets/img/icon_mas_bullet.svg"> {{ 'problematicas.mas_resultados' | translate }}</button>
    </div>

    <div class="mt-2 d-flex justify-content-center" *ngIf="paginaActualMasResultados == totalPaginas && retosActualesMovilAcadList.length <= registrosPagina && retosAcadList.length > registrosPagina" >
      <button class="mb_mas" (click)="obtenerMasResultados()" ><img alt="" class="tam" src="/assets/img/icon_mas_bullet.svg"> {{ 'paginador.resultados_anteriores' | translate }} </button>
    </div>

    <div *ngIf="retosActualesMovilAcadList.length === 0">
      <app-sin-datos-table></app-sin-datos-table>
    </div>

  </div>
</div>
