import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { IUser } from "@shared/Interface/user";
import { HttpClient, HttpParams } from "@angular/common/http";
import { RetosResponse } from "@shared/Interface/IRetosResponse";
import { ISociosNotificacionesResponse } from "@shared/Interface/ISociosNotificacionesResponse";
import { map, Observable } from "rxjs";
import { ISocioFormadorResponse } from "@shared/Interface/ISocioFormadorResponse";
import { ISocioFormadorContactoResponse } from "@shared/Interface/ISocioFormadorContactoResponse";
import { IComentarioSocioFormadorResponse } from "@shared/Interface/IComentarioSocioFormadorResponse";
import { IComentarioSocioFormadorRequest } from "@shared/Interface/IComentarioSocioFormadorRequest";
import { moduleService, subModuleService } from "../routesServices";
import { ICartaColaboracion } from "@shared/Interface/IReto.interface";
import { ICartaConvenio } from "@shared/Interface/ICartaConvenio";

const { SOCIOS } = moduleService;
const { CONVENIOS } = subModuleService;

@Injectable({
  providedIn: "root",
})
export class SociosFormadoresService {
  baseUrl = environment.apiUrl;
  currentUser!: IUser;
  constructor(private http: HttpClient) {}

  /**
   * Api que regresa las notificaciones del socio
   * @param idSocio Identificador del socio formador
   * @returns Objeto tipo ISociosNotificacionesResponse
   */
  getNotificacionesByIdSocio(
    idSocio: any
  ): Observable<ISociosNotificacionesResponse> {
    return this.http.get<ISociosNotificacionesResponse>(
      this.baseUrl + "socios/" + idSocio + "/notificaciones"
    );
  }

  /**
   * Api que obtiene la información de un socio formador
   * @param idSocio tipo de dato number que identifica al socio formador
   * @returns objeto tipo ISocioFormadorResponse
   */
  getSociosFormadoresById(idSocio: any): Observable<ISocioFormadorResponse> {
    return this.http
      .get<ISocioFormadorResponse>(this.baseUrl + "socios/" + idSocio)
      .pipe(
        map((result) => {
          result.nombreSocioFormador = result.nombreSocioFormador ?? "";
          return result;
        })
      );
  }

  /**
   * Api que obtiene la información de un contacto del socio formador
   * @param idSocio tipo de dato number que identifica al socio formador
   * @param contacto identifica el tipo de contacto
   * @returns objeto tipo ISocioFormadorContactoResponse
   */
  getSociosFormadoresContacto(
    idSocio: any,
    contacto: any
  ): Observable<ISocioFormadorContactoResponse> {
    let queryFilter = "";
    if (contacto !== null && contacto !== undefined && contacto !== "") {
      queryFilter = "?principal=" + contacto;
    }
    return this.http.get<ISocioFormadorContactoResponse>(
      this.baseUrl + "socios/" + idSocio + "/contactos" + queryFilter
    );
  }

  /**
   * Api que actualiza los comentarios de un socio formador
   * @param comentarioRequest objeto con los comentarios
   * @returns objeto tipo IComentarioSocioFormadorResponse
   */
  putComentarioSocioFormador(
    comentarioRequest: IComentarioSocioFormadorRequest
  ): Observable<IComentarioSocioFormadorResponse> {
    return this.http.put<IComentarioSocioFormadorResponse>(
      this.baseUrl +
        "socios/" +
        comentarioRequest.idSocioFormador +
        "/comentarios",
      comentarioRequest
    );
  }

  /**
   * Api que obtiene la lista de socios formadores
   * @returns Lista de objetos tipo ISocioFormadorResponse[]
   */
  getAllSociosFormadores(
    keySearch: string,
    sectorId: number,
    industriaId: number,
    claveEstado: string,
    tamanoId: number,
    estatus: string,
    campus: number,
    validarConvenio: boolean,
    orden: boolean = false
  ): Observable<ISocioFormadorResponse[]> {

    let queryParams = new HttpParams();
    queryParams = queryParams.append("query", keySearch);
    queryParams = queryParams.append("sector", sectorId);
    queryParams = queryParams.append("industria", industriaId);
    queryParams = queryParams.append("estado", claveEstado);
    queryParams = queryParams.append("tamano", tamanoId);
    queryParams = queryParams.append("estatus", estatus);
    queryParams = queryParams.append("campus", campus);
    queryParams = queryParams.append("convenio", validarConvenio);
    queryParams = queryParams.append("orden", orden);


    return this.http.get<ISocioFormadorResponse[]>(`${this.baseUrl}${SOCIOS}`,
    {
      params: queryParams,
    }
    );

  }

  /** Api que los retos por id
   */
  getRetos(idSocio: number) {
    return this.http.get<RetosResponse>(
      this.baseUrl + "dashboard/socios/" + idSocio + "/retos?estatus=activo"
    );
  }

  postSocios(socio: any): Observable<any> {
    return this.http.post(this.baseUrl + "socios", socio);
  }

  putSocios(socio: any): Observable<any> {
    return this.http.put(this.baseUrl + "socios", socio);
  }

  putLogo(request: any): Observable<any> {
    return this.http.put(`${this.baseUrl}socios/logos`, request);
  }

  postCartaConvenio(body: any) {
    return this.http.post(`${this.baseUrl}socios/${CONVENIOS}`, body);
  }

  getCartaConvenio(idSocioFormador: number) {
    return this.http.get<ICartaConvenio[]>(
      `${this.baseUrl}socios/${idSocioFormador}/${CONVENIOS}`
    );
  }

  deleteCartaConvenio(idConvenio: number, idUsuario: number, logico:boolean = true) {
    let options = {
      body: {
        idConvenio: idConvenio,
        idUsuarioElimina: idUsuario,
      },
    };
    if(logico){
      return this.http.delete(`${this.baseUrl}${SOCIOS}/convenios`, options);
    }
    else{
      return this.http.delete(`${this.baseUrl}${SOCIOS}/convenios/${idConvenio}`);
    }
  }



  postCartaColaboracion(body: any) {
    return this.http.post(`${this.baseUrl}socios/cartasColaboracion`, body);
  }

  getCartaColaboracion(idSocio: number): Observable<ICartaColaboracion[]> {
    return this.http.get<ICartaColaboracion[]>(
      `${this.baseUrl}${SOCIOS}/${idSocio}/cartasColaboracion`
    );
  }

  deleteCartaColaboracion(idColaboracion: number, idUsuario: number) {
    let options = {
      body: {
        idCartaColaboracion: idColaboracion,
        idUsuarioElimina: idUsuario,
      },
    };
    return this.http.delete(
      `${this.baseUrl}${SOCIOS}/cartasColaboracion`,
      options
    );
  }

  cambiarEstatus(body: any) {
    return this.http.put(`${this.baseUrl}socios/estatus`, body);
  }

  buscarSocioPorRFC(rfc: string) {
    return this.http.get(`${this.baseUrl}${SOCIOS}/rfc/${rfc}`);
  }

  postSoloSocioFormador(body: any): Observable<ISocioFormadorResponse> {
    return this.http.post<ISocioFormadorResponse>(
      `${this.baseUrl}${SOCIOS}/soloSocio`,
      body
    );
  }

  postFlujofirmas(request:any){
    return this.http.post(`${this.baseUrl}administracion/cartas/flujoFirmas`,request);
  }

  actualizarLogo(body: any) {
    return this.http.put(`${this.baseUrl}socios/actualizaLogoHome`, body);
  }

  getEvaluaciones(idSocio: Number, tipo: string="todos", pagina: Number = 0, filasxpagina: Number = 5 ){
    ///v1/socios/{idSocio}/evaluaciones?tipoComentario=[todos|no|Felicitación|Sugerencia de mejora|Queja|Otra]&pagina=0&filasxpagina=5
    return this.http.get(`${this.baseUrl}socios/${idSocio}/evaluaciones?tipoComentario=todos&pagina=0&filasxpagina=5`);

  }
}
