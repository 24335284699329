<app-typeform idForm="MqJGJ1DW" *ngIf="updated"></app-typeform>

<form (ngSubmit)="onSubmit()" class="frm_registro_sf" [formGroup]="formularioForm">
    <fieldset [disabled]="!fieldSetEnable">
    <legend></legend>
    <div class="row px-0">
        <div class="col-md-3 text-center">
            <div>
                <img class="img_avt_ds" alt="Logotipo" src="{{ img }}" />
            </div>
            <div><br />Imagen .JPG o .PNG<br>
                <small>Tamaño Máx: 1024x1024 < 3Mb</small>
            </div>
            <div class="editFoto fileUploadButton" (click)="file.click()"><br />Editar Foto</div>
            <input class="hidden" type="file" (change)="selImagen($event)" accept="image/*" #file />
        </div>
        <div class="col-md-6 col-sm-12">
            <div>
                <div *ngIf="!fieldSetEnable" class="form-label"> No puedes editar información. </div>
                <!-- RFC -->
                <label for="rfc_empresa" class="form-label">RFC/No de tributación IRS <span
                        class="error">*</span></label>
                <input type="text" class="form-control" id="rfc_empresa" name="rfc_empresa"
                    (blur)="buscarRFC()"
                    formControlName="rfc_empresa" placeholder="Escribe el RFC/Número de tributación IRS aquí">
                <div class="col" *ngIf="procesandoBusqueda"><em>Buscando información ...</em></div>
                    <div *ngIf="formularioForm.get('rfc_empresa')?.touched && formularioForm.get('rfc_empresa')?.errors?.['required']"
                        class="error">
                        El campo es requerido
                    </div>
                    <div *ngIf="formularioForm.get('rfc_empresa')?.touched && formularioForm.get('rfc_empresa')?.errors?.['minlength']"
                        class="error">
                        Mínimo {{ formularioForm.get('rfc_empresa')?.errors?.['minlength']?.requiredLength }} letras
                    </div>
                    <div *ngIf="formularioForm.get('rfc_empresa')?.touched && formularioForm.get('rfc_empresa')?.errors?.['pattern']"
                        class="error">
                        Escribe únicamente letras y números, sin espacios ni caracteres especiales.
                    </div>
                </div>


                <!-- Nombre de la Organización -->
                <div>
                    <label for="nombre_empresa" class="form-label">Nombre de la Organización <span
                            class="error">*</span></label>
                    <input type="text" class="form-control" id="nombre_empresa" name="nombre_empresa"
                        formControlName="nombre_empresa" placeholder="¿Cómo se llama la Organización?">
                    <div *ngIf="formularioForm.get('nombre_empresa')?.touched && formularioForm.get('nombre_empresa')?.errors?.['required']"
                        class="error">
                        El campo es requerido
                    </div>
                    <div *ngIf="formularioForm.get('nombre_empresa')?.touched && formularioForm.get('nombre_empresa')?.errors?.['minlength']"
                        class="error">
                        Mínimo {{ formularioForm.get('nombre_empresa')?.errors?.['minlength']?.requiredLength }} letras
                    </div>
                </div>

                <!-- Razón Social -->
                <div *ngIf="showRazonSocial()">
                    <label for="razonsocial_empresa" class="form-label">Razón social <span
                            class="error">*</span></label>
                    <input type="text" class="form-control" id="razonsocial_empresa" name="razonsocial_empresa"
                        formControlName="razonsocial_empresa" placeholder="Escribe la razón social">
                    <div *ngIf="formularioForm.get('razonsocial_empresa')?.touched && formularioForm.get('razonsocial_empresa')?.errors?.['required']"
                        class="error">
                        El campo es requerido
                    </div>
                </div>


                <!-- Corporativo -->
                <div>
                    <label for="grupo_empresa" class="form-label">Corporativo </label>
                    <input type="text" class="form-control" id="grupo_empresa" name="grupo_empresa"
                        formControlName="grupo_empresa" placeholder="¿A qué grupo pertenece?">
                    <div *ngIf="formularioForm.get('grupo_empresa')?.touched && formularioForm.get('grupo_empresa')?.errors?.['required']"
                        class="error">
                        El campo es requerido
                    </div>
                </div>


                <!-- Sitio Web   -->
                <div>
                    <label for="web_empresa" class="form-label">Sitio web <span class="error">*</span></label>
                    <input type="text" class="form-control" id="web_empresa" name="web_empresa"
                        formControlName="web_empresa" placeholder="www.tunombredesitio.com">
                    <div *ngIf="formularioForm.get('web_empresa')?.touched && formularioForm.get('web_empresa')?.errors?.['required']"
                        class="error">
                        El campo es requerido
                    </div>
                </div>

                <!-- Descripción de la organización -->
                <div class="frm-descripcion_empresa">
                    <label for="descripcion_empresa" class="form-label">Descripción breve de la organización <span class="error">*</span></label>
                    <textarea type="textarea" class="form-control" id="descripcion_empresa" name="descripcion_empresa"
                        formControlName="descripcion_empresa" rows="6"
                        placeholder="¿Qué es lo que hace la organización?"></textarea>
                    <div *ngIf="formularioForm.get('descripcion_empresa')?.touched && formularioForm.get('descripcion_empresa')?.errors?.['required']"
                        class="error">
                        El campo es requerido
                    </div>
                </div>

                <!-- Sector -->
                <div>
                    <label for="sector_empresa" class="form-label">Sector: <span class="error">*</span></label>
                    <br><br>
                    <label class="r_alcance" for="sector_publico">
                        <input type="radio" class="alcance-input-hidden" id="sector_publico" name="sector_empresa"
                            [checked]="verifyChecked(1,'sector')" forControlName="sector_empresa" value=1
                            (change)="setSectorEmpresa(1)">
                        <div class="circulo">
                            <img alt="" src="assets/img/ico_publico_ngo.svg" width="168px">
                        </div>
                        <div class="alcance-titulo"></div>
                    </label>
                    <label class="r_alcance" for="sector_privado">
                        <input type="radio" class="alcance-input-hidden" id="sector_privado" name="sector_empresa"
                            [checked]="verifyChecked(2,'sector')" forControlName="sector_empresa" value=2
                            (change)="setSectorEmpresa(2)">
                        <div class="circulo">
                            <img alt="" src="assets/img/ico_privado_ngo.svg" width="168px">
                        </div>
                        <div class="alcance-titulo"></div>
                    </label>
                    <label class="r_alcance" for="sector_social">
                        <input type="radio" class="alcance-input-hidden" id="sector_social" name="sector_empresa"
                            [checked]="verifyChecked(3,'sector')" forControlName="sector_empresa" value=3
                            (change)="setSectorEmpresa(3)">
                        <div class="circulo">
                            <img alt="img_retos" src="assets/img/ico_social_ngo.svg" width="168px">
                        </div>
                        <div class="alcance-titulo"></div>
                    </label>
                    <div *ngIf="formularioForm.get('sector_empresa')?.touched && formularioForm.get('sector_empresa')?.errors?.['required']"
                        class="error">
                        El campo es requerido
                    </div>
                </div>

                <!-- Industria -->
                <div>
                    <label for="industria_empresa" class="form-label">Industria <span class="error">*</span></label>
                    <ng-select class="colorGray" [items]="Industrias" bindLabel="industria" bindValue="idIndustria"
                                    placeholder="Selecciona una opción" formControlName="industria_empresa"></ng-select>

                    <div *ngIf="formularioForm.get('industria_empresa')?.touched && formularioForm.get('industria_empresa')?.errors?.['required']"
                        class="error">
                        El campo es requerido
                    </div>
                </div>

                <!-- Tamaño de la empresa -->
                <div>
                    <label for="tamanio_empresa" class="form-label">Tamaño de la empresa <span
                            class="error">*</span></label>
                    <select class="form-select form-select-sm" id="tamanio_empresa" name="tamanio_empresa"
                        formControlName="tamanio_empresa" placeholder="Selecciona una opción">
                        <option value="" selected disabled>Selecciona una opción</option>
                        <option *ngFor="let el of TamanoEmpresa" [value]="el.idTamanoEmpresa">
                            {{ el.tamanoEmpresa }}
                        </option>
                    </select>
                    <div *ngIf="formularioForm.get('tamanio_empresa')?.touched && formularioForm.get('tamanio_empresa')?.errors?.['required']"
                        class="error">
                        El campo es requerido
                    </div>
                </div>

                <!-- Presencia -->
                <div>
                    <label for="presencia_empresa" class="form-label">Presencia <span class="error">*</span></label>
                    <br>
                    <label class="r_alcance" for="alcance_local">
                        <input type="radio" class="alcance-input-hidden" id="alcance_local" name="presencia_empresa"
                            [checked]="verifyChecked(1,'presencia')" forControlName="presencia_empresa" value=1
                            (change)="setPresenciaEmpresa(1)">
                        <div class="circulo">
                            <img alt="" src="assets/img/icon_local.png" width="168px">
                        </div>
                        <div class="alcance-titulo">Local</div>
                    </label>

                    <label class="r_alcance" for="alcance_regional">
                        <input type="radio" class="alcance-input-hidden" id="alcance_regional" name="presencia_empresa"
                            [checked]="verifyChecked(2,'presencia')" forControlName="presencia_empresa" value=2
                            (change)="setPresenciaEmpresa(2)">
                        <div class="circulo">
                            <img alt="" src="assets/img/icon_regional.png" width="168px">
                        </div>
                        <div class="alcance-titulo">Regional</div>
                    </label>

                    <label class="r_alcance" for="alcance_nacional">
                        <input type="radio" class="alcance-input-hidden" id="alcance_nacional" name="presencia_empresa"
                            [checked]="verifyChecked(3,'presencia')" forControlName="presencia_empresa" value=3
                            (change)="setPresenciaEmpresa(3)">
                        <div class="circulo">
                            <img alt="" src="assets/img/icon_nacional.png" width="168px">
                        </div>
                        <div class="alcance-titulo">Nacional</div>
                    </label>

                    <label class="r_alcance" for="alcance_internacional">
                        <input type="radio" class="alcance-input-hidden" id="alcance_internacional"
                            name="presencia_empresa" [checked]="verifyChecked(4,'presencia')"
                            forControlName="presencia_empresa" (change)="setPresenciaEmpresa(4)" value=4>
                        <div class="circulo">
                            <img alt="" src="assets/img/Icon_Internacional.png" width="168px">
                        </div>
                        <div class="alcance-titulo">Internacional</div>
                    </label>
                    <div *ngIf="formularioForm.get('presencia_empresa')?.touched && formularioForm.get('presencia_empresa')?.errors?.['required']"
                        class="error">
                        El campo es requerido
                    </div>
                </div>


                <div class="row">

                    <!-- Pais -->
                    <div class="col-md-6 col-sm-12"><!--***-->
                        <label for="Pais_empresa" class="form-label">País <span class="error">*</span></label>

                        <select class="form-select form-select-sm" id="pais_empresa" name="pais_empresa"
                            (change)="validarPais($event)"
                            formControlName="pais_empresa" placeholder="Selecciona una opción">
                            <option value="">Selecciona una opción</option>
                            <option *ngFor="let el of Paises" [value]="el.clavePais">
                                {{ el.pais }}
                            </option>
                        </select>


                        <div *ngIf="formularioForm.get('pais_empresa')?.touched && formularioForm.get('pais_empresa')?.errors?.['required']"
                            class="error">
                            El campo es requerido
                        </div>
                    </div>

                    <!-- CP -->
                    <div class="col-md-6 col-sm-12"><!--***-->
                        <label for="cp_empresa" class="form-label">C.P. <span class="error">*</span></label>
                        <input type="text" class="form-control-6" id="cp_empresa" name="cp_empresa"
                            formControlName="cp_empresa" placeholder="Escribe el C.P."
                            (blur)="buscarCP()"
                            >
                            <div class="form-text">Para empresas no establecidas en México, introducir EXT</div>
                        <div *ngIf="formularioForm.get('cp_empresa')?.touched && formularioForm.get('cp_empresa')?.errors?.['required']"
                            class="error">
                            El campo es requerido
                        </div>
                    </div>

                    <!-- Estado -->
                    <div class="col-md-6 col-sm-12"><!--***-->
                        <label for="estado_empresa" class="form-label">Estado <span class="error">*</span></label>


                        <select *ngIf="cpMexico" class="form-select form-select-sm" id="estado_empresa" name="estado_empresa"
                            formControlName="estado_empresa" placeholder="Selecciona una opción">
                            <option value="">Selecciona una opción</option>
                            <option *ngFor="let el of Estados" [value]="el.claveEstado">
                                {{ el.estado }}
                            </option>
                        </select>

                        <input type="text" *ngIf="!cpMexico" placeholder="Escribe el estado"
                                formControlName="estado2_empresa" class="form-control" />

                        <div *ngIf="formularioForm.get('estado_empresa')?.touched && formularioForm.get('estado_empresa')?.errors?.['required']"
                            class="error">
                            El campo es requerido
                        </div>
                    </div>


                    <!-- Ciudad -->
                    <div class="col-md-6 col-sm-12"><!--***-->
                        <label for="ciudad_empresa" class="form-label">Ciudad <span class="error">*</span></label>
                        <input type="text" class="form-control-6" id="ciudad_empresa" name="ciudad_empresa"
                            formControlName="ciudad_empresa" placeholder="Escribe la ciudad">
                        <div *ngIf="formularioForm.get('ciudad_empresa')?.touched && formularioForm.get('ciudad_empresa')?.errors?.['required']"
                            class="error">
                            El campo es requerido
                        </div>
                    </div>

                </div>

                <!-- Colonia -->
                <div >
                    <div class="">
                        <div class="form-group">
                            <label for="colonia_empresa" class="form-label">Colonia <span
                                    class="error">*</span></label>

                            <input type="text" *ngIf="!cpMexico" placeholder="Escribe la colonia"
                                formControlName="colonia2_empresa" class="form-control" />
                            <div class="select-dropdown" *ngIf="cpMexico">
                                <ng-select class=" colorGray" [items]="Colonias" bindLabel="colonia" bindValue="colonia"
                                    placeholder="Selecciona una opción" formControlName="colonia_empresa"></ng-select>
                            </div>

                            <div *ngIf="formularioForm.get('colonia_empresa')?.touched && formularioForm.get('colonia_empresa')?.errors?.['required']"
                                class="error">
                                El campo es requerido
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Calle -->
                <div>
                    <label for="calle_empresa" class="form-label">Calle y número<span class="error">*</span></label>
                    <input type="text" class="form-control" id="calle_empresa" name="calle_empresa"
                        formControlName="calle_empresa" placeholder="Escribe la calle aquí">
                    <div *ngIf="formularioForm.get('calle_empresa')?.touched && formularioForm.get('calle_empresa')?.errors?.['required']"
                        class="error">
                        El campo es requerido
                    </div>
                </div>

                <!-- LinkedIn -->
                <div><!--***-->
                    <label for="linkedin_empresa" class="form-label">URL de LinkedIn </label>
                    <input type="text" class="form-control" id="linkedin_empresa" name="linkedin_empresa"
                        formControlName="linkedin_empresa" placeholder="link de LinkedIn">
                    <div *ngIf="formularioForm.get('linkedin_empresa')?.touched && formularioForm.get('linkedin_empresa')?.errors?.['required']"
                        class="error">
                        El campo es requerido
                    </div>
                </div>

                <!-- Twitter -->
                <div><!--***-->
                    <label for="twitter_empresa" class="form-label">URL de twitter </label>
                    <input type="text" class="form-control" id="twitter_empresa" name="twitter_empresa"
                        formControlName="twitter_empresa" placeholder="link de Twitter">
                    <div *ngIf="formularioForm.get('twitte_empresa')?.touched && formularioForm.get('twitter_empresa')?.errors?.['required']"
                        class="error">
                        El campo es requerido
                    </div>
                </div>

                <!-- Facebook -->
                <div><!--***-->
                    <label for="facebook_empresa" class="form-label">URL de Facebook </label>
                    <input type="text" class="form-control" id="facebook_empresa" name="facebook_empresa"
                        formControlName="facebook_empresa" placeholder="link de Facebook">
                    <div *ngIf="formularioForm.get('facebook_empresa')?.touched && formularioForm.get('facebook_empresa')?.errors?.['required']"
                        class="error">
                        El campo es requerido
                    </div>
                </div>

                <!-- Instagram -->
                <div><!--***-->
                    <label for="instagram_empresa" class="form-label">URL de Instagram </label>
                    <input type="text" class="form-control" id="instagram_empresa" name="instagram_empresa"
                        formControlName="instagram_empresa" placeholder="link de Instagram">
                    <div *ngIf="formularioForm.get('instagram_empresa')?.touched && formularioForm.get('instagram_empresa')?.errors?.['required']"
                        class="error">
                        El campo es requerido
                    </div>
                </div>

                <!-- Campus -->
                <div>
                    <label for="campus_empresa" class="form-label">Campus donde desea ser atendido <span
                            class="error">*</span></label>
                    <select class="form-select form-select-sm" id="campus_empresa" name="campus_empresa"
                        formControlName="campus_empresa" placeholder="Selecciona una opción">
                        <option value="">Selecciona una opción</option>
                        <option *ngFor="let el of Campus" [value]="el.idCampus">
                            {{ el.campus }}
                        </option>
                    </select>
                    <div *ngIf="formularioForm.get('campus_empresa')?.touched && formularioForm.get('campus_empresa')?.errors?.['required']"
                        class="error">
                        El campo es requerido
                    </div>
                </div>

                <!-- Relacion con el Tec -->
                <div>
                    <label for="relacion_empresa" class="form-label">Relación con el TEC:  <span class="error">*</span></label>
                    <div class="textogris">Selecciona una o más opciones</div>
                    <br>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="col-12" *ngFor="let el of RelacionesTec">
                                <div class="form-check form-check-inline">
                                    <input type="checkbox" class="form-check-input" [value]="el.idRelacionTec"
                                        id="{{ el.idRelacionTec}}" formArrayName="relaciones"
                                        (change)="onCheckboxChange($event, 'relaciones')"
                                        [checked]="verifyChecked(el.idRelacionTec,'relacionesTec')">
                                    <label class="form-check-label" for="{{ el.relacionTec}}">{{el.relacionTec}}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="formularioForm.get('relaciones')?.touched && formularioForm.get('relaciones')?.errors?.['required']"
                        class="error">
                        El campo es requerido
                    </div>
                </div>

                <!-- Acercamiento -->
                <div class="row mt-3 "
                *ngIf="['socio'].includes(clavePerfil)"
                >
                    <div class="">
                        <div class="form-group">
                            <label for="acercamiento_empresa" class="form-label">Has tenido acercamiento con el Tec para
                                conocer sobre el rol del Socio Formador <span class="error">*</span></label>
                            <div *ngIf="formularioForm.get('acercamiento_empresa')?.touched && formularioForm.get('acercamiento_empresa')?.errors?.['required']"
                                class="error">
                                El campo es requerido
                            </div>

                            <div class="form-check form-check-inline ">
                                <input id="acercamiento" class="form-check-input-radio me-1" value=true type="radio" name="acercamiento_empresa"
                                [checked]="formularioForm.get('acercamiento_empresa')?.value == true"
                                    formControlName="acercamiento_empresa" />
                                <label class="radio-label me-3"> Si</label>
                                <input id="acercamiento" class="form-check-input-radio me-1" value=false type="radio" name="acercamiento_empresa"
                                [checked]="formularioForm.get('acercamiento_empresa')?.value == false"
                                    formControlName="acercamiento_empresa"  />
                                <label class="radio-label">No</label>
                            </div>

                        </div>
                    </div>
                </div>

                <div class="row mt-3" *ngIf="mostrarBotonera()">
                    <div class="col-12 my-3">
                        <button class="btn-amarillo mx-2" type="submit">{{ lblbtnAccion }}</button>
                        <button class="btn-blue mx-2" *ngIf="lblbtnCancelar!=''" type="button" (click)="btnRegresar()" >{{ lblbtnCancelar }}</button>
                    </div>
                    <div class="col-12">
                        <button class="error_button mx-2" type="button" (click)="InactivarCuenta()"
                            *ngIf="cuentaActiva && lblbtnCancelar==''">Inactivar Socio Formador</button>
                        <button class="btn-verde mx-2" type="button" (click)="ActivarCuenta()" *ngIf="!cuentaActiva">Activar
                            Socio Formador</button>
                    </div>
                </div>

            </div>
        </div>
    </fieldset>
</form>
