import { Component, OnInit } from '@angular/core';
import { AccountService } from '@shared/service/Account/account.service';

@Component({
  selector: 'app-footer-large',
  templateUrl: './footer-large.component.html',
  styleUrls: ['./footer-large.component.css']
})
export class FooterLargeComponent implements OnInit {
  isLogged:boolean=false;
  constructor(private accountService: AccountService) { }

  ngOnInit(): void {
    this.isLogged = this.accountService.isLoggeed();
  }

}
