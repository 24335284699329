import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import type {
  IContacto,
  IContactoResponse,
} from '@shared/Interface/IContacto.interface';
import { baseUrl, moduleService, subModuleService } from '../routesServices';

const { SOCIOS } = moduleService;
const { CONTACTOS } = subModuleService;

@Injectable({
  providedIn: 'root',
})
export class ContactosService {
  constructor(private http: HttpClient) {}

  putContactos(idSocio, body: any): Observable<IContactoResponse> {
    return this.http.put<IContactoResponse>(
      `${baseUrl}${SOCIOS}/${idSocio}/${CONTACTOS}`,
      body
    );
  }

  postContactos(idSocio, body: any): Observable<IContactoResponse> {
    return this.http.post<IContactoResponse>(
      `${baseUrl}${SOCIOS}/${idSocio}/${CONTACTOS}`,
      body
    );
  }

  postContactoSecundario(idSocio, body: any): Observable<IContactoResponse> {
    return this.http.post<IContactoResponse>(
      `${baseUrl}${SOCIOS}/${idSocio}/${CONTACTOS}?pendiente=true`,
      body
    );
  }

  getContactos(idSocio , principal = false): Observable<IContacto[]> {
    return this.http.get<IContacto[]>(
      `${baseUrl}${SOCIOS}/${idSocio}/${CONTACTOS}?principal=${principal}`
    );
  }

  getContactoxId(idSocio,idContacto):Observable<IContacto>{
    return this.http.get<IContacto>(`${baseUrl}${SOCIOS}/${idSocio}/contactos/${idContacto}`);
  }

  deleteContact(idSocio , idContact = 0): Observable<any> {
    return this.http.delete<any>(
      `${baseUrl}${SOCIOS}/${idSocio}/${CONTACTOS}/${idContact}`
    );
  }

  putContactosEstatus(body:any){
    return this.http.put(`${baseUrl}${SOCIOS}/${CONTACTOS}/estatus`,body);
  }

  /*
  * @deprecated Ya no debe ser usado, usar deleteContact
  */
  deleteContactosxAvisoPrivacidad(request){
    let options = {
      body: request
    };
    return this.http.delete(`${baseUrl}${SOCIOS}/${CONTACTOS}/eliminar`,options);
  }

  putContactosAprobar(request:any){
    return this.http.put(`${baseUrl}socios/contactos/aprobar`, request);
  }


  getContactosPorEstatus(estatus:string): Observable<IContacto[]> {
    return this.http.get<IContacto[]>(`${baseUrl}${SOCIOS}/contactosXEstatus/${(estatus??'')}`
    );
    //https://mpsapi.spsneurika.net:8443/v1/socios/contactosXEstatus/pendiente
  }

}
