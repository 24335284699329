<app-header-buscador [showSearchBar]="false"></app-header-buscador>

<app-typeform idForm="zihNawN9"></app-typeform>

<div class="sec_ad">
  <app-title-show-more
    [showMoreObject]="titleMatchSocio"
  ></app-title-show-more>
  <div
    id="result_match"
    class="carousel pointer-event slide"
    data-ride="carousel"
  >
    <div class="carousel-inner">
      <div class="carousel-item {{ i === 0 ? 'active' : '' }}" *ngFor="let match of matchData; let i = index">
        <div class="row" >
          <div class="col-md-4" *ngFor="let match of matchData[i]">
            <app-card-accordion-socio (onJoinVinculacion)="joinVinculacion(match)" [matchObj]="match"></app-card-accordion-socio>
          </div>
        </div>
      </div>
    </div>
    <button
      class="carousel-control-prev"
      type="button"
      data-bs-target="#result_match"
      data-bs-slide="prev"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="10"
        height="20"
        viewBox="0 0 10 20"
        fill="none"
        class="iCn replaced-svg"
      >
        <path
          d="M8.31798 20L0.806934 12.0667C0.290133 11.519 0 10.7777 0 10.005C0 9.23228 0.290133 8.49101 0.806934 7.94333L8.32667 0L10 1.7675L2.48027 9.71C2.40621 9.78814 2.36461 9.8941 2.36461 10.0046C2.36461 10.1151 2.40621 10.221 2.48027 10.2992L9.99052 18.2325L8.31798 20Z"
          fill="#4A6BB6"
        ></path>
      </svg>
    </button>
    <button
      class="carousel-control-next"
      type="button"
      data-bs-target="#result_match"
      data-bs-slide="next"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="10"
        height="20"
        viewBox="0 0 10 20"
        fill="none"
        class="iCn replaced-svg"
      >
        <path
          d="M1.68202 0L9.19307 7.93333C9.70987 8.48101 10 9.22228 10 9.995C10 10.7677 9.70987 11.509 9.19307 12.0567L1.67333 20L0 18.2325L7.51973 10.29C7.59379 10.2119 7.63539 10.1059 7.63539 9.99542C7.63539 9.88493 7.59379 9.77897 7.51973 9.70083L0.00948048 1.7675L1.68202 0Z"
          fill="#4A6BB6"
        ></path>
      </svg>
    </button>
  </div>


  <div class="row">
    <div class="col-12 NoEncontreSF">
      <div class="subtituloNoencuentrasf"> ¿No encuentras un Socio Formador que se ajuste a tus retos? </div>
      <div class="textoNoencuentrassf">  ¡Lo podemos solucionar!</div>
      <button type="button" (click)="irNoEcontreSocio()" class="btnNoencuentrasf" >Cuéntanos por qué</button>
    </div>
  </div>

</div>
