import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import type {
  IProblematica,
  IProblematicas,
} from '@shared/Interface/IProblematicas';
import { map, Observable } from 'rxjs';
import { baseUrl, moduleService } from '../routesServices';

const { PROBLEMATICAS } = moduleService;
@Injectable({
  providedIn: 'root',
})
export class ProblematicasService {
  constructor(private http: HttpClient) {}

  postProblematica(model: any): Observable<IProblematicas[]> {
    return this.http.post<IProblematicas[]>(
      `${baseUrl}${PROBLEMATICAS}`,
      model
    );
  }

  putProblematica(model: any): Observable<IProblematicas[]> {
    return this.http.put<IProblematicas[]>(`${baseUrl}${PROBLEMATICAS}`, model);
  }

  getProblematica(idProblematica: string | null): Observable<IProblematica> {
    return this.http.get<IProblematica>(
      `${baseUrl}${PROBLEMATICAS}/${idProblematica}`
    );
  }

  getProblematicaId(idProblematica: number): Observable<IProblematica> {
    return this.http.get<IProblematica>(
      `${baseUrl}${PROBLEMATICAS}/${idProblematica}`
    ).pipe(map(result => {
        result.claveEstatus = result.claveEstatus ??'';
      return result;
    }));
  }

  postProblematicaComentario(idProblematica:number,request:any):Observable<any>{
    return this.http.post<any>(`${baseUrl}${PROBLEMATICAS}/${idProblematica}/comentarios`, request);
  }

  getProblematicaComentario(idProblematica:number):Observable<any>{
    return this.http.get<any>(`${baseUrl}${PROBLEMATICAS}/${idProblematica}/comentarios`);
  }
}
