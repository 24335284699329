import { Injectable } from "@angular/core";
import { FeatureConfig } from "./IFeatureConfig";
import { HttpClient } from "@angular/common/http";
import { tap } from "rxjs";
import { environment } from "environments/environment";

@Injectable({
  providedIn: "root",
})
export class FeatureFlagService {
  config: FeatureConfig | null = null;
  configUrl = ``;
  constructor(private http: HttpClient) {}

  loadConfig() {
    // return this.http
    //   .get<FeatureConfig>(this.configUrl)
    //   .pipe(tap((config) => (this.config = config)))
    //   .toPromise();
    this.config = environment.feature;
  }

  isFeatureEnabled(featureName: string): boolean {
    if (this.config === null) {
      return false;
    }
    return this.config[featureName] ?? false;
  }

}
