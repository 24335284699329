import { IRelacionesTecResponse } from "./IRelacionesTecResponse";

export interface ISocioFormadorResponse {
  idSocioFormador: number,
  nombreSocioFormador: string,
  razonSocial: string,
  ciudad: string,
  empresa: string,
  empresaShort: string,
  estado:string,
  grupoComercial: string,
  numeroEmpleados: number,
  rfc: string,
  domicilioFiscal: string,
  calle: string,
  ciudadFiscal: string,
  cp: string,
  claveEstado: string,
  pais: string,
  telefonoEmpresa: string,
  alcance: string,
  area: string,
  claveEstatus: string,
  estatus: string,
  idSector: number,
  sector: string,
  idTamano: number,
  tamano: string,
  idPresencia: number,
  presencia: string,
  presenciaC: string,
  paginaWeb: string,
  twitter: string,
  linkedIn: string,
  facebook: string,
  instagram: string,
  idCampus: number,
  campus: string,
  idTipoSocioFormador: number,
  tipoSocioFormador: string,
  fechaRegistro: string,
  idIndustria: number,
  industria: string,
  relacionesTec: IRelacionesTecResponse[],
  urlLogo:string,
  urlLogoSocioFormador: string,
  idCVDPResponsable: number,
  nombreCVDPResponsable: string,
  colonia: string,
  idUsuarioRegistro: number,
  conoceRolSF: boolean,
  claveEstatusSeguimiento: string,
  estatusSeguimiento: string,
  verLogoHome: boolean,
  evaluacion: number,
  evaluadores: number,
}

export class  SocioFormadorResponse implements ISocioFormadorResponse{
  idSocioFormador: number;
  nombreSocioFormador: string;
  razonSocial: string;
  ciudad: string;
  empresa: string;
  empresaShort: string;
  estado:string;
  grupoComercial: string;
  numeroEmpleados: number;
  rfc: string;
  domicilioFiscal: string;
  calle: string;
  ciudadFiscal: string;
  cp: string;
  claveEstado: string;
  pais: string;
  telefonoEmpresa: string;
  alcance: string;
  area: string;
  claveEstatus: string;
  estatus: string;
  idSector: number;
  sector: string;
  idTamano: number;
  tamano: string;
  idPresencia: number;
  presencia: string;
  presenciaC: string;
  paginaWeb: string;
  twitter: string;
  linkedIn: string;
  facebook: string;
  instagram: string;
  idCampus: number;
  campus: string;
  idTipoSocioFormador: number;
  tipoSocioFormador: string;
  fechaRegistro: string;
  idIndustria: number;
  industria: string;
  relacionesTec: IRelacionesTecResponse[];
  urlLogo: string;
  urlLogoSocioFormador: string;
  idCVDPResponsable: number;
  nombreCVDPResponsable: string;
  colonia:string;
  idUsuarioRegistro: number;
  conoceRolSF: boolean;
  claveEstatusSeguimiento: string;
  estatusSeguimiento: string;
  verLogoHome: boolean;
  evaluacion: number;
  evaluadores: number;

  constructor(){
    this.idSocioFormador=0;
    this.nombreSocioFormador= '';
    this.razonSocial='';
    this.ciudad='';
    this.empresa='';
    this.empresaShort ='';
    this.estado='';
    this.grupoComercial='';
    this.numeroEmpleados=0;
    this.rfc='';
    this.domicilioFiscal='';
    this.calle='';
    this.ciudadFiscal='';
    this.cp='';
    this.claveEstado='';
    this.pais='';
    this.telefonoEmpresa='';
    this.alcance='';
    this.area='';
    this.claveEstatus='';
    this.estatus='';
    this.idSector=0;
    this.sector='';
    this.idTamano=0;
    this.tamano='';
    this.idPresencia=0;
    this.presencia='';
    this.presenciaC='';
    this.paginaWeb='';
    this.twitter='';
    this.linkedIn='';
    this.facebook='';
    this.instagram='';
    this.idCampus=0;
    this.campus='';
    this.idTipoSocioFormador=0;
    this.tipoSocioFormador='';
    this.fechaRegistro='';
    this.idIndustria=0;
    this.industria='';
    //this.relacionesTec: IRelacionesTecResponse[];
    this.urlLogo='';
    this.urlLogoSocioFormador='';
    this.idCVDPResponsable=0;
    this.nombreCVDPResponsable='';
    this.colonia='';
    this.claveEstatusSeguimiento='primer-contacto';
    this.estatusSeguimiento='Primer contacto';
    this.verLogoHome = false;
    this.evaluacion = 0;
    this.evaluadores = 0;
  }

}
