<div class="sec_ad">
  <app-title-show-more [showMoreObject]="titleShowMore"></app-title-show-more>
  <div class="tab-pane fade show active " id="tab_eaad" role="tab_panel" aria-labelledby="eaad">
    <div  *ngIf="recursosV.length>0">
      <!-- Carousel -->
      <div id="herr_vinc" class="carousel carousel-dark slide" data-bs-ride="carousel">
        <!-- Indicators/dots -->
        <div class="carousel-indicators">
          <button type="button" data-bs-target="#herr_vinc" data-bs-slide-to="0" *ngIf="recursosV.length>5" class="active"></button>
          <button type="button" data-bs-target="#herr_vinc" data-bs-slide-to="1" *ngIf="recursosV.length>5"></button>
        </div>
        <div class="carousel-inner">
          <div class="carousel-item active">
            <div class="card-wrapper">
              <div class="ce-card" *ngFor="let recurso of recursosV.slice(0, 5);">
                <a class="linkRecursosVinculacion" href="{{recurso.linkVinculacion}}" target="_blank" rel="noopener noreferrer">
                  <div class="card-body">
                    <img alt="" width="192px" src="{{recurso.urlImagenVinculacion}}" />
                    <h4 class="card-title">{{recurso.tituloVinculacion}}</h4>
                    <p class="card-text">
                      {{recurso.descripcionVinculacion}}
                    </p>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div class="carousel-item ">
            <div class="card-wrapper">
              <div class="ce-card" *ngFor="let recurso of recursosV.slice(5, 10);">
                <a class="linkRecursosVinculacion" href="{{recurso.linkVinculacion}}" target="_blank" rel="noopener noreferrer">
                  <div class="card-body">
                    <img alt="" width="192px" src="{{recurso.urlImagenVinculacion}}" />
                    <h4 class="card-title">{{recurso.tituloVinculacion}}</h4>
                    <p class="card-text">
                      {{recurso.descripcionVinculacion}}
                    </p>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>

        <!-- Left and right controls/icons -->
        <button class="carousel-control-prev" type="button" data-bs-target="#herr_vinc" data-bs-slide="prev">
          <svg xmlns="http://www.w3.org/2000/svg" width="10" height="20" viewBox="0 0 10 20" fill="none"
            class="iCn replaced-svg">
            <path
              d="M8.31798 20L0.806934 12.0667C0.290133 11.519 0 10.7777 0 10.005C0 9.23228 0.290133 8.49101 0.806934 7.94333L8.32667 0L10 1.7675L2.48027 9.71C2.40621 9.78814 2.36461 9.8941 2.36461 10.0046C2.36461 10.1151 2.40621 10.221 2.48027 10.2992L9.99052 18.2325L8.31798 20Z"
              fill="#4A6BB6"></path>
          </svg>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#herr_vinc" data-bs-slide="next">
          <svg xmlns="http://www.w3.org/2000/svg" width="10" height="20" viewBox="0 0 10 20" fill="none"
            class="iCn replaced-svg">
            <path
              d="M1.68202 0L9.19307 7.93333C9.70987 8.48101 10 9.22228 10 9.995C10 10.7677 9.70987 11.509 9.19307 12.0567L1.67333 20L0 18.2325L7.51973 10.29C7.59379 10.2119 7.63539 10.1059 7.63539 9.99542C7.63539 9.88493 7.59379 9.77897 7.51973 9.70083L0.00948048 1.7675L1.68202 0Z"
              fill="#4A6BB6"></path>
          </svg>
        </button>

      </div>
    </div>


    <div *ngIf="recursosV2.length>0">
      <app-title-show-more [showMoreObject]="{ title: 'Herramientas de Vinculación'}"></app-title-show-more>
      <!-- Carousel 2 -->
      <div id="herr_vinc2" class="carousel carousel-dark slide" data-bs-ride="carousel">
        <!-- Indicators/dots -->
        <div class="carousel-indicators">
          <button type="button" data-bs-target="#herr_vinc2" data-bs-slide-to="0" *ngIf="recursosV2.length>5" class="active"></button>
          <button type="button" data-bs-target="#herr_vinc2" data-bs-slide-to="1" *ngIf="recursosV2.length>5"></button>
        </div>
        <div class="carousel-inner">
          <div class="carousel-item active">
            <div class="card-wrapper">
              <div class="ce-card" *ngFor="let recurso of recursosV2.slice(0, 5);">
                <a class="linkRecursosVinculacion" href="{{recurso.linkVinculacion}}" target="_blank" rel="noopener noreferrer">
                  <div class="card-body">
                    <img alt="" width="192px" src="{{recurso.urlImagenVinculacion}}" />
                    <h4 class="card-title">{{recurso.tituloVinculacion}}</h4>
                    <p class="card-text">
                      {{recurso.descripcionVinculacion}}
                    </p>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div class="carousel-item ">
            <div class="card-wrapper">
              <div class="ce-card" *ngFor="let recurso of recursosV2.slice(5, 10);">
                <a class="linkRecursosVinculacion" href="{{recurso.linkVinculacion}}" target="_blank" rel="noopener noreferrer">
                  <div class="card-body">
                    <img alt="" width="192px" src="{{recurso.urlImagenVinculacion}}" />
                    <h4 class="card-title">{{recurso.tituloVinculacion}}</h4>
                    <p class="card-text">
                      {{recurso.descripcionVinculacion}}
                    </p>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>

        <!-- Left and right controls/icons -->
        <button class="carousel-control-prev" type="button" data-bs-target="#herr_vinc2" data-bs-slide="prev">
          <svg xmlns="http://www.w3.org/2000/svg" width="10" height="20" viewBox="0 0 10 20" fill="none"
            class="iCn replaced-svg">
            <path
              d="M8.31798 20L0.806934 12.0667C0.290133 11.519 0 10.7777 0 10.005C0 9.23228 0.290133 8.49101 0.806934 7.94333L8.32667 0L10 1.7675L2.48027 9.71C2.40621 9.78814 2.36461 9.8941 2.36461 10.0046C2.36461 10.1151 2.40621 10.221 2.48027 10.2992L9.99052 18.2325L8.31798 20Z"
              fill="#4A6BB6"></path>
          </svg>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#herr_vinc2" data-bs-slide="next">
          <svg xmlns="http://www.w3.org/2000/svg" width="10" height="20" viewBox="0 0 10 20" fill="none"
            class="iCn replaced-svg">
            <path
              d="M1.68202 0L9.19307 7.93333C9.70987 8.48101 10 9.22228 10 9.995C10 10.7677 9.70987 11.509 9.19307 12.0567L1.67333 20L0 18.2325L7.51973 10.29C7.59379 10.2119 7.63539 10.1059 7.63539 9.99542C7.63539 9.88493 7.59379 9.77897 7.51973 9.70083L0.00948048 1.7675L1.68202 0Z"
              fill="#4A6BB6"></path>
          </svg>
        </button>

      </div>
    </div>



    <div id="herr_vinc" class="carousel pointer-event" data-bs-ride="carousel">
      <div class="carousel-inner">
        <div class="carousel-item">
          <a class="linkRecursosVinculacion" href="https://tooltec.mx/" target="_blank" rel="noopener noreferrer" alt="Bolsa de Trabajo">
            <div class="card-body">
              <img alt="" src="assets/svg/logo_herramienta.svg" />
              <h4 class="card-title">Tooltec</h4>
              <p class="card-text">
                Plataforma que construye comunidad a través de herramientas de innovación.
              </p>
            </div>
          </a>
        </div>
      </div>
    </div>


  </div>