<button *ngIf="elementStatus.statusType === 'amarillo'" (click)="handleClick()" class="yell_button_nrml">{{ elementStatus.textCol }}</button>
<button *ngIf="elementStatus.statusType === 'primary'" (click)="handleClick()" class="blue_button_nrml">{{ elementStatus.textCol }}</button>


<img *ngIf="['ev_aprobado'].includes(elementStatus.statusType)"    
    (click)="handleClick()" 
    src="assets/img/status_aprobado.png" 
    style="width: 5.5rem;" 
    alt="{{ elementStatus.textCol }}"
    />

<img 
    *ngIf="elementStatus.statusType !== 'primary' && elementStatus.statusType !== 'amarillo' &&
    !['ev_aprobado'].includes(elementStatus.statusType)"
    
    (click)="handleClick()" 
    src="assets/svg/{{ elementStatus.statusType }}.svg" 
    style="width: 5.5rem;" 
    alt="{{ elementStatus.textCol }}"
    />

<span *ngIf="['ev_registrado1'].includes(elementStatus.statusType)">
{{ elementStatus.textCol }}</span>