import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-icon-border',
  templateUrl: './icon-border.component.html',
  styleUrls: ['./icon-border.component.css'],
})
export class IconBorderComponent  {
  @Input()
  icon = 'icon_nacional';
  @Input()
  title = 'Región Centro-Sur';

  
}
