import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import Utils from "@shared/helpers/utils";
import { RetosService } from "@shared/service/Retos/retos.service";
import { UsuariosService } from "@shared/service/Usuarios/usuarios.service";
import { ToastrService } from "ngx-toastr";
import { lastValueFrom } from "rxjs";
import Swal from "sweetalert2";

@Component({
  selector: "app-reto-entregables",
  templateUrl: "./reto-entregables.component.html",
  styleUrls: ["./reto-entregables.component.css"],
})
export class RetoEntregablesComponent implements OnInit {
  entregablesForm: FormGroup;
  postularForm: FormGroup;
  entregablesList: any[] = [];
  entregablesCount: number = 0;
  clavePerfil: string="";

  @Input() idReto: number;
  @Input() status: string;
  @Output() entregables: EventEmitter<any[]> = new EventEmitter<any[]>();
  @ViewChild('closebutton') closebutton;


  retoPlace: any = {
    descripcion:
      "Proyecto que contenga  el desarrollo de la solución mediante metodologías de ideación. La propuesta de solución debe ser deseable (mercado), viable (financiera) y factible (técnica) al Consejo Directivo de la Empresa, Conclusiones y Referencias.",
    temporalidad: "Una vez concluido el reto.",
  };

  constructor(
    private fb: FormBuilder,
    private retosService: RetosService,
    private toastr: ToastrService,
    private usuarioService: UsuariosService
  ) {}

  ngOnInit(): void {
    this.clavePerfil = this.usuarioService.getCurrentUserData().clavePerfil;
    this.getEntregables();
    this.entregablesForm = this.fb.group({
      idRetoEntregable: [0],
      idReto: [this.idReto],
      temporalidad: ["", Validators.required],
      descripcion: ["", Validators.required],
    });
    this.postularForm = this.fb.group({
      idReto: [this.idReto],
      video: ["", Validators.required],
      imagen: ["", Validators.required],
    });

  }

  public addEntregable() {
    this.entregablesForm.markAllAsTouched();
    if (this.entregablesForm.valid) {
      this.entregablesCount = this.entregablesCount + 1;
      this.entregablesForm.controls["idRetoEntregable"].setValue(
        this.entregablesCount
      );
      this.entregablesForm.patchValue({
        idReto: this.idReto
      });
      this.retosService
        .postRetoEntregable(this.entregablesForm.value)
        .subscribe({
          next: (resOK) => {
            //this.toastr.success("Guardado correctamente");
            this.getEntregables();
            this.entregablesList.push(this.entregablesForm.value);
            this.entregables.emit(this.entregablesForm.value);
            this.entregablesForm.patchValue({
              idRetoEntregable: null,
              temporalidad: null,
              descripcion: null,
            });

            this.entregablesForm.reset();

            this.closebutton.nativeElement.click();
          },
          error: (error) => {
            this.closebutton.nativeElement.click();
            console.log(error);
          },
        });
    }
  }

  cancelEntregable(){

  }
  async getEntregables() {
    try {
      this.entregablesList = [];
      this.entregablesList = await lastValueFrom(
        this.retosService.getAllRetoEntregable(this.idReto)
      );
    } catch (error:any) {
      Utils.processErrorResponse(error,"Error al obtener el detalle de entregables por su ID ");
    }
  }

  eliminar(idRetoEntregable: number) {

      Swal.fire({
        text:'¿Desea eliminar el entregable?',
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
        showCancelButton: true,
      }).then(async (response)=>
      {
        try {
        if(response.isConfirmed){
          await lastValueFrom(this.retosService.deleteRetoEntregable(idRetoEntregable));
          await this.getEntregables();
        }
      } catch (error) {
        console.log(error);
       }
      });

  }
}
