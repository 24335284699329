<app-header-buscador [showSearchBar]="false"></app-header-buscador>

<div class="sec_ad" *featureFlag="'F2_5_HU58'">
  <app-title-show-more [showMoreObject]="titleRegistroProblematica"></app-title-show-more>

  <div class="div-text-btn my-2">
    <div class="question-div">
      ¿Cuál es el problema con el que te gustaría vincularte a un reto
      académico?
    </div>
  </div>

  <div class="row my-2" *ngIf="false">
    <div class="col">
      <button class="btn-video d-md-block" (click)="showModal(mymodal)">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
          class="iCn replaced-svg">
          <path
            d="M19 24H5C3.67441 23.9984 2.40356 23.4711 1.46622 22.5338C0.528882 21.5964 0.00158786 20.3256 0 19L0 5C0.00158786 3.67441 0.528882 2.40356 1.46622 1.46622C2.40356 0.528882 3.67441 0.00158786 5 0L19 0C20.3256 0.00158786 21.5964 0.528882 22.5338 1.46622C23.4711 2.40356 23.9984 3.67441 24 5V19C23.9984 20.3256 23.4711 21.5964 22.5338 22.5338C21.5964 23.4711 20.3256 23.9984 19 24ZM5 2C4.20435 2 3.44129 2.31607 2.87868 2.87868C2.31607 3.44129 2 4.20435 2 5V19C2 19.7956 2.31607 20.5587 2.87868 21.1213C3.44129 21.6839 4.20435 22 5 22H19C19.7956 22 20.5587 21.6839 21.1213 21.1213C21.6839 20.5587 22 19.7956 22 19V5C22 4.20435 21.6839 3.44129 21.1213 2.87868C20.5587 2.31607 19.7956 2 19 2H5ZM9.342 17.005C8.92513 17.0036 8.51601 16.8922 8.156 16.682C7.80034 16.4784 7.50515 16.184 7.30063 15.8289C7.09611 15.4738 6.9896 15.0708 6.992 14.661V9.339C6.99166 8.92914 7.09911 8.52641 7.30358 8.1712C7.50805 7.81599 7.80234 7.52079 8.15692 7.31524C8.5115 7.10968 8.9139 7.00099 9.32376 7.00007C9.73361 6.99915 10.1365 7.10604 10.492 7.31L15.77 9.945C16.1366 10.1429 16.4436 10.4353 16.6592 10.7918C16.8748 11.1483 16.9912 11.556 16.9962 11.9726C17.0012 12.3892 16.8946 12.7995 16.6877 13.1611C16.4807 13.5227 16.1808 13.8223 15.819 14.029L10.443 16.716C10.1077 16.9073 9.728 17.0069 9.342 17.005ZM9.317 9.005C9.26189 9.00498 9.20774 9.01946 9.16 9.047C9.10814 9.07585 9.0651 9.11826 9.0355 9.1697C9.00591 9.22114 8.99087 9.27966 8.992 9.339V14.661C8.99234 14.7195 9.00788 14.7768 9.03709 14.8274C9.0663 14.8781 9.10818 14.9202 9.15861 14.9498C9.20905 14.9794 9.2663 14.9953 9.32475 14.996C9.3832 14.9968 9.44084 14.9823 9.492 14.954L14.868 12.266C14.908 12.2347 14.9399 12.1941 14.9608 12.1478C14.9817 12.1015 14.991 12.0507 14.988 12C14.9893 11.9405 14.9742 11.8818 14.9444 11.8304C14.9146 11.7789 14.8712 11.7365 14.819 11.708L9.545 9.073C9.47624 9.03082 9.39763 9.00737 9.317 9.005Z"
            fill="#4A6BB6"></path>
        </svg><span>&nbsp;Ver video</span>
      </button>
    </div>
  </div>

  <form [formGroup]="problematicaForm" (submit)="registroProblematica()" autocomplete="off">

    <div class="row questions-container open-text">
      <div class="col-sm-12 col-lg-12 ">
        <div class="row my-4">
          <div class="col-12">
            <span class="question-title-text text-blue">Sobre la Problemática </span>
          </div>

          <div class="col-md-12 mt-4">
            <span class="question-text">¿Qué áreas de tu empresa/organización están involucradas?</span>
          </div>

          <div class="col-md-12">
            <span class="answer-title-text">Selecciona una o más opciones</span>

            <!-- Areas -->
            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  <div class="col col-md-6" *ngFor="let area of areaData">
                    <input type="checkbox" class="btn-check" id="{{ area.area }}" autocomplete="off"
                      formArrayName="areas" value="{{ area.idArea }}" (change)="onCheckboxChange($event, 'areas')"
                      [checked]="verifyChecked(area.idArea, 'areas')" />
                    <label class="btn btn-outline-primary" for="{{ area.area }}"></label>
                    <span>{{ area.area }}</span>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-6">
                    <app-text-input [formControl]="
                        $any(problematicaForm.controls['otraArea'])
                      " [label]="'otraArea'" [placeholder]="'Escribe tu área'"></app-text-input>
                  </div>
                </div>
              </div>
            </div>

            <!-- Alcance Problemática-->
            <div class="row mt-5">
              <div class="col">
                <label for="" class="question-text">Alcance de la problemática <span
                    class="text-danger">*</span></label>
              </div>
            </div>

            <div class="row">
              <div class="col icons-checkbox" *ngFor="let alcance of alcanceData">
                <div class="icon-div">
                  <img src="assets/svg/icon_{{ alcance.alcance | lowercase }}.svg" alt="icon-local" />
                </div>
                <p>{{ alcance.alcance }}</p>
                <input type="radio" class="btn-check" id="{{ alcance.alcance }}" name="idAlcance"
                  value="{{ alcance.idAlcance }}" autocomplete="off" formControlName="idAlcance"
                  [checked]="verifyChecked(alcance.idAlcance, 'idAlcance')" />
                <label class="btn btn-outline-primary" for="{{ alcance.alcance }}"></label>
              </div>
            </div>

            <div class="row">
              <!-- Nombre Problemática -->
              <div class="col-12 mt-5">
                <label for="proyecto" class="question-text">Nombre de la problemática <span
                    class="text-danger">*</span></label>
                <app-text-input [formControl]="$any(problematicaForm.controls['proyecto'])" [label]="'Problemática'"
                  [placeholder]="'Escribe el nombre de tu proyecto'"></app-text-input>
              </div>

              <!-- Contexto -->
              <div class="col-md-12 mt-5">
                <label for="problematica" class="question-text">Contexto de la problemática <span
                    class="text-danger">*</span>
                </label>
                <textarea class="forma_area form-control" id="problematica" placeholder="¿De qué trata tu problemática?"
                  [ngClass]="{'is-invalid':problematicaForm.get('problematica')?.invalid && problematicaForm.get('problematica')?.touched}"
                  [formControl]="$any(problematicaForm.controls['problematica'])">
                  </textarea>
                <div class="text-danger"
                  *ngIf="problematicaForm.get('problematica')?.invalid && problematicaForm.get('problematica')?.touched">
                  El campo es requerido. Contexto de la problemática
                </div>
              </div>

              <!-- Objetivos -->
              <div class="col-md-12 mt-4">
                <label for="objetivos" class="question-text">Objetivos a lograr con el acompañamiento académico <span
                    class="text-danger">*</span></label>
                <textarea class="forma_area form-control" id="objetivos" placeholder="Cuéntanos tus objetivos"
                  [ngClass]="{'is-invalid':problematicaForm.get('objetivos')?.invalid && problematicaForm.get('objetivos')?.touched}"
                  [formControl]="$any(problematicaForm.controls['objetivos'])"></textarea>
                <div class="text-danger"
                  *ngIf="problematicaForm.get('objetivos')?.invalid && problematicaForm.get('objetivos')?.touched">
                  El campo es requerido. Objetivos a lograr
                </div>
              </div>

              <!-- *** Sobre la Vinculacion *** -->
              <div class="col-12">
                <span class="question-title-text text-blue">Sobre la Vinculación </span>
              </div>

              <div class="col-md-12 mt-4">
                <label for="idEscuela" class="question-text">Escuela con la que te gustaría trabajar <span
                    class="text-danger">*</span></label>
                <select class="form-select" (change)="onSelectEscuela($event)" formControlName="idEscuela">
                  <option value="">Selecciona una opción</option>
                  <option *ngFor="let escuela of escuelasData" value="{{ escuela.idEscuela }}"
                    selected="{{ verifySelected(escuela.idEscuela, 'idEscuela') }}">
                    {{ escuela.escuela }}
                  </option>
                </select>
                <div class="text-danger"
                  *ngIf="problematicaForm.get('idEscuela')?.invalid && problematicaForm.get('idEscuela')?.touched">
                  El campo es requerido. Escuela
                </div>
              </div>
            </div>



            <div class="row ">
              <!-- Programas -->
              <div class="col-md-12 mt-5">
                <span class="question-text">Programas académicos que te interesa que participen:</span>
              </div>
              <div class="col-md-12">
                <span class="answer-title-text">Selecciona una o más opciones</span>

                <div class="row" *ngIf="programasAcademicosData">
                  <div class="row offset-1 text-danger"
                    *ngIf="programasAcademicosData.length == 0 && problematicaForm.get('programasAcademicos')?.touched">
                    No hay programas asociados, por favor selecciona otra escuela.
                  </div>
                  <div class="col col-md-6" *ngFor="let programa of programasAcademicosData">

                    <input type="checkbox" class="btn-check"
                      id="{{ programa.idProgramaAcademico }}-{{programa.programaAcademico}}" autocomplete="off"
                      formArrayName="programasAcademicos" value="{{ programa.idProgramaAcademico }}"
                      (change)="onCheckboxChange($event, 'programasAcademicos')"
                      [checked]="verifyChecked(programa.idProgramaAcademico, 'idProgramaAcademico')" />

                    <label class="btn btn-outline-primary"
                      for="{{ programa.idProgramaAcademico }}-{{programa.programaAcademico}}">
                    </label>

                    <span class="text-programaAcademico">{{ programa.programaAcademico }}</span>

                  </div>
                </div>
              </div>

              <!-- Semestre -->
              <div class="col-md-12 mt-5">
                <label for="periodo" class="question-text"> De qué semestre son los alumnos con los que te gustaría
                  trabajar<span class="text-danger">*</span></label>
                <select class="form-select" id="semestre" formControlName="semestre">
                  <option value="">Selecciona una opción</option>
                  <option *ngFor="let semestre of semestreData" value="{{ semestre }}"
                    selected="{{ verifySelected(semestre, 'semestre') }}">
                    {{ semestre }}
                  </option>
                </select>
                <div class="text-danger"
                  *ngIf="problematicaForm.get('semestre')?.invalid && problematicaForm.get('semestre')?.touched">
                  El campo es requerido. Semestre
                </div>
              </div>

              <!-- Periodo -->
              <div class="col-md-12 mt-5">
                <span class="question-text mt-4">{{ 'pagina_retos_detalles.selecciona_periodo_semestral' | translate
                  }}</span><br />
                <div class="text-danger" *ngIf="pedirPeriodoAcademico">
                  Este campo es requerido
                </div>
                <select class="form-select " id="periodoSemestral" name="periodoSemestral" formControlName="idPeriodo"
                  (change)="seleccionarPeriodo($event)" placeholder="Selecciona un periodo">
                  <option value="">Seleccione una opción</option>
                  <option *ngFor="let el of this.periodoAcademicoData" value="{{ el.idPeriodo }} ">
                    {{ el.periodo }}
                  </option>
                </select>
              </div>



              <!-- Campus donde se lleva el reto -->
              <div class="col-md-12 mt-3">

                <div class="row">

                  <div class="col-md-12 mt-4">
                    <span class="question-text">
                      Campus donde te gustaría colaborar
                      <span class="text-danger">*</span>
                    </span>
                  </div>

                  <div class="text-danger" *ngIf="pedirListaCampus">
                    Este campo es requerido
                  </div>

                  <div class="col-md-12 espacio">
                    <span class="text-muted">Selecciona una o más opciones</span>
                    <div class="row mt-3">
                      <div class="col-md-12 answer-text1">
                        <div class="row">
                          <div class="col-6">
                            <div class="col col-md-12" *ngFor="let campus of seccion1">
                              <input type="checkbox" class="btn-check" id="{{ campus.idCampus}}-{{ campus.campus }}"
                                autocomplete="off" value="{{ campus.idCampus}}"
                                (change)="onCheckboxChangeCampus($event)" />
                              <label class="btn btn-outline-primary"
                                for="{{ campus.idCampus}}-{{ campus.campus }}"></label>
                              <span>{{ campus.campus }}</span>
                            </div>
                          </div>

                          <div class="col-6">
                            <div class="col col-md-12" *ngFor="let campus of seccion2">
                              <input type="checkbox" class="btn-check" id="{{ campus.idCampus}}-{{ campus.campus }}"
                                autocomplete="off" value="{{ campus.idCampus}}"
                                (change)="onCheckboxChangeCampus($event)" />

                              <label class="btn btn-outline-primary"
                                for="{{ campus.idCampus}}-{{ campus.campus }}"></label>
                              <span>{{ campus.campus }}</span>
                            </div>
                          </div>
                        </div>

                      </div>

                    </div>
                  </div>
                </div>
              </div>
              <!-- Campus -->
              <div class="col-md-12 mt-5">
                <label for="campus" class="question-text">De las anteriores, selecciona el campus con la que te gustaría
                  llevar la comunicación<span class="text-danger">*</span></label>
                <select class="form-select" name="campusReto" id="campusReto" formControlName="campusReto">
                  <option value="">Selecciona una opción</option>
                  <option *ngFor="let campus of campusSelectedData" value="{{ campus.idCampus}}"
                    >
                    {{ campus.campus }}
                  </option>
                </select>
              </div>
            </div>


            <div class="row mt-5">
              <div class="col-9 text-right"></div>
              <div class="col-2 text-right">
                <app-button ngForm buttonType="yell" extraClass="btn-full">
                  Enviar </app-button>
              </div>
              <div class="col-1 text-right"></div>
            </div>
            <div class="row">
              <div class="col">
                <div class="row" *ngIf="validationErrors.length > 0">
                  <ul class="text-danger">
                    <li *ngFor="let error of validationErrors">{{error}}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </form>
</div>

<ng-template #mymodal let-modal>
  <div class="modal-header modal-lg">
    <h4 class="modal-title" id="modal-basic-title">
      ¿Cómo hacer el registro de problemática?
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">X</span>
    </button>
  </div>
  <div class="modal-body">
    <iframe width="760" height="315" src="https://www.youtube.com/embed/n0UCvKvjA6Q?controls=0" title="Video player"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen></iframe>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">
      Ok
    </button>
  </div>
</ng-template>





<div class="sec_ad" *featureFlag="'F2_4_HU58'">
  <app-title-show-more [showMoreObject]="titleRegistroProblematica"></app-title-show-more>

  <div class="div-text-btn my-2">
    <div class="question-div">
      ¿Cuál es el problema con el que te gustaría vincularte a un reto
      académico?
    </div>
  </div>

  <div class="row my-2" *ngIf="false">
    <div class="col">
      <button class="btn-video d-md-block" (click)="showModal(mymodal)">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
          class="iCn replaced-svg">
          <path
            d="M19 24H5C3.67441 23.9984 2.40356 23.4711 1.46622 22.5338C0.528882 21.5964 0.00158786 20.3256 0 19L0 5C0.00158786 3.67441 0.528882 2.40356 1.46622 1.46622C2.40356 0.528882 3.67441 0.00158786 5 0L19 0C20.3256 0.00158786 21.5964 0.528882 22.5338 1.46622C23.4711 2.40356 23.9984 3.67441 24 5V19C23.9984 20.3256 23.4711 21.5964 22.5338 22.5338C21.5964 23.4711 20.3256 23.9984 19 24ZM5 2C4.20435 2 3.44129 2.31607 2.87868 2.87868C2.31607 3.44129 2 4.20435 2 5V19C2 19.7956 2.31607 20.5587 2.87868 21.1213C3.44129 21.6839 4.20435 22 5 22H19C19.7956 22 20.5587 21.6839 21.1213 21.1213C21.6839 20.5587 22 19.7956 22 19V5C22 4.20435 21.6839 3.44129 21.1213 2.87868C20.5587 2.31607 19.7956 2 19 2H5ZM9.342 17.005C8.92513 17.0036 8.51601 16.8922 8.156 16.682C7.80034 16.4784 7.50515 16.184 7.30063 15.8289C7.09611 15.4738 6.9896 15.0708 6.992 14.661V9.339C6.99166 8.92914 7.09911 8.52641 7.30358 8.1712C7.50805 7.81599 7.80234 7.52079 8.15692 7.31524C8.5115 7.10968 8.9139 7.00099 9.32376 7.00007C9.73361 6.99915 10.1365 7.10604 10.492 7.31L15.77 9.945C16.1366 10.1429 16.4436 10.4353 16.6592 10.7918C16.8748 11.1483 16.9912 11.556 16.9962 11.9726C17.0012 12.3892 16.8946 12.7995 16.6877 13.1611C16.4807 13.5227 16.1808 13.8223 15.819 14.029L10.443 16.716C10.1077 16.9073 9.728 17.0069 9.342 17.005ZM9.317 9.005C9.26189 9.00498 9.20774 9.01946 9.16 9.047C9.10814 9.07585 9.0651 9.11826 9.0355 9.1697C9.00591 9.22114 8.99087 9.27966 8.992 9.339V14.661C8.99234 14.7195 9.00788 14.7768 9.03709 14.8274C9.0663 14.8781 9.10818 14.9202 9.15861 14.9498C9.20905 14.9794 9.2663 14.9953 9.32475 14.996C9.3832 14.9968 9.44084 14.9823 9.492 14.954L14.868 12.266C14.908 12.2347 14.9399 12.1941 14.9608 12.1478C14.9817 12.1015 14.991 12.0507 14.988 12C14.9893 11.9405 14.9742 11.8818 14.9444 11.8304C14.9146 11.7789 14.8712 11.7365 14.819 11.708L9.545 9.073C9.47624 9.03082 9.39763 9.00737 9.317 9.005Z"
            fill="#4A6BB6"></path>
        </svg><span>&nbsp;Ver video</span>
      </button>
    </div>
  </div>

  <form [formGroup]="problematicaForm" (submit)="registroProblematica2_4()" autocomplete="off">

    <div class="row questions-container open-text">
      <div class="col-sm-12 col-lg-12 ">
        <div class="row my-4">
          <div class="col-12">
            <span class="question-title-text">Acerca de la Problemática</span>
          </div>

          <div class="col-md-12 mt-4">
            <span class="question-text">¿Qué áreas de tu empresa/organización están involucradas?</span>
          </div>

          <div class="col-md-12">
            <span class="answer-title-text">Selecciona una o más opciones</span>

            <!-- Areas -->
            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  <div class="col col-md-6" *ngFor="let area of areaData">
                    <input type="checkbox" class="btn-check" id="{{ area.area }}" autocomplete="off"
                      formArrayName="areas" value="{{ area.idArea }}" (change)="onCheckboxChange($event, 'areas')"
                      [checked]="verifyChecked(area.idArea, 'areas')" />
                    <label class="btn btn-outline-primary" for="{{ area.area }}"></label>
                    <span>{{ area.area }}</span>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-6">
                    <app-text-input [formControl]="
                        $any(problematicaForm.controls['otraArea'])
                      " [label]="'otraArea'" [placeholder]="'Escribe tu área'"></app-text-input>
                  </div>
                </div>
              </div>
            </div>

            <!-- Alcance Problemática-->
            <div class="row mt-5">
              <div class="col">
                <label for="" class="question-text">Alcance de la problemática <span class="text-danger">*</span></label>
              </div>
            </div>

            <div class="row">
              <div class="col icons-checkbox" *ngFor="let alcance of alcanceData">
                <div class="icon-div">
                  <img src="assets/svg/icon_{{ alcance.alcance | lowercase }}.svg" alt="icon-local" />
                </div>
                <p>{{ alcance.alcance }}</p>
                <input type="radio" class="btn-check" id="{{ alcance.alcance }}" name="idAlcance"
                  value="{{ alcance.idAlcance }}" autocomplete="off" formControlName="idAlcance"
                  [checked]="verifyChecked(alcance.idAlcance, 'idAlcance')" />
                <label class="btn btn-outline-primary" for="{{ alcance.alcance }}"></label>
              </div>
            </div>

            <div class="row">
              <!-- Nombre Problemática -->
              <div class="col-12 mt-5">
                <label for="proyecto" class="question-text">Nombre de la problemática <span class="text-danger">*</span></label>
                <app-text-input
                [formControl]="$any(problematicaForm.controls['proyecto'])"
                [label]="'Problemática'"
                [placeholder]="'Escribe el nombre de tu proyecto'"></app-text-input>
              </div>

              <!-- Contexto -->
              <div class="col-md-12 mt-5">
                <label for="problematica" class="question-text">Contexto de la problemática <span class="text-danger">*</span>
                </label>
                <textarea class="forma_area form-control"
                     id="problematica"
                     placeholder="¿De qué trata tu problemática?"
                     [ngClass]="{'is-invalid':problematicaForm.get('problematica')?.invalid && problematicaForm.get('problematica')?.touched}"
                    [formControl]="$any(problematicaForm.controls['problematica'])">
                  </textarea>
                  <div class="text-danger" *ngIf="problematicaForm.get('problematica')?.invalid && problematicaForm.get('problematica')?.touched">
                    El campo es requerido. Contexto de la problemática
                  </div>
              </div>

              <!-- Objetivos -->
              <div class="col-md-12 mt-4">
                <label for="objetivos" class="question-text">Objetivos a lograr con el acompañamiento académico <span class="text-danger">*</span></label>
                <textarea
                  class="forma_area form-control" id="objetivos"
                  placeholder="Cuéntanos tus objetivos"
                  [ngClass]="{'is-invalid':problematicaForm.get('objetivos')?.invalid && problematicaForm.get('objetivos')?.touched}"
                  [formControl]="$any(problematicaForm.controls['objetivos'])"
                  ></textarea>
                  <div class="text-danger" *ngIf="problematicaForm.get('objetivos')?.invalid && problematicaForm.get('objetivos')?.touched">
                    El campo es requerido. Objetivos a lograr
                  </div>
              </div>

              <div class="col-md-12 mt-4">
                <label for="idEscuela" class="question-text">Escuela que quieres que te acompañe en este reto <span class="text-danger">*</span></label>
                <select class="form-select"
                  (change)="onSelectEscuela($event)"
                  formControlName="idEscuela">
                  <option value="">Selecciona una opción</option>
                  <option *ngFor="let escuela of escuelasData" value="{{ escuela.idEscuela }}"
                    selected="{{ verifySelected(escuela.idEscuela, 'idEscuela') }}">
                    {{ escuela.escuela }}
                  </option>
                </select>
                <div class="text-danger" *ngIf="problematicaForm.get('idEscuela')?.invalid && problematicaForm.get('idEscuela')?.touched">
                  El campo es requerido. Escuela
                </div>
              </div>
            </div>



            <div class="row ">
              <!-- Programas -->
              <div class="col-md-12 mt-5">
                <span class="question-text">Programas académicos que te interesa que participen:</span>
              </div>
              <div class="col-md-12">
                <span class="answer-title-text">Selecciona una o más opciones</span>

                <div class="row" *ngIf="programasAcademicosData">
                  <div class="row offset-1 text-danger"
                  *ngIf="programasAcademicosData.length == 0 && problematicaForm.get('programasAcademicos')?.touched">
                    No hay programas asociados, por favor selecciona otra escuela.
                  </div>
                  <div class="col col-md-6" *ngFor="let programa of programasAcademicosData">

                    <input type="checkbox" class="btn-check"
                      id="{{ programa.idProgramaAcademico }}-{{programa.programaAcademico}}"
                      autocomplete="off"
                      formArrayName="programasAcademicos"
                      value="{{ programa.idProgramaAcademico }}"
                      (change)="onCheckboxChange($event, 'programasAcademicos')"
                      [checked]="verifyChecked(programa.idProgramaAcademico, 'idProgramaAcademico')" />

                      <label class="btn btn-outline-primary"
                        for="{{ programa.idProgramaAcademico }}-{{programa.programaAcademico}}">
                      </label>

                    <span class="text-programaAcademico">{{ programa.programaAcademico }}</span>

                  </div>
                </div>
              </div>

              <!-- Semestre -->
              <div class="col-md-12 mt-5">
                <label for="periodo" class="question-text">A qué semestre te interesa dirigirlo <span class="text-danger">*</span></label>
                <select class="form-select" id="semestre" formControlName="semestre">
                  <option value="">Selecciona una opción</option>
                  <option *ngFor="let semestre of semestreData" value="{{ semestre }}"
                    selected="{{ verifySelected(semestre, 'semestre') }}">
                    {{ semestre }}
                  </option>
                </select>
                <div class="text-danger" *ngIf="problematicaForm.get('semestre')?.invalid && problematicaForm.get('semestre')?.touched">
                  El campo es requerido. Semestre
                </div>
              </div>

              <!-- Periodo -->
              <div class="col-md-12 mt-5">
                <span class="question-text mt-4">{{ 'pagina_retos_detalles.selecciona_periodo_semestral' | translate
                  }}</span><br />
                <div class="text-danger" *ngIf="pedirPeriodoAcademico">
                  Este campo es requerido
                </div>
                <select class="form-select " id="periodoSemestral" name="periodoSemestral" formControlName="idPeriodo"
                  (change)="seleccionarPeriodo($event)" placeholder="Selecciona un periodo">
                  <option value="">Seleccione una opción</option>
                  <option *ngFor="let el of this.periodoAcademicoData" value="{{ el.idPeriodo }} ">
                    {{ el.periodo }}
                  </option>
                </select>
              </div>

              <!-- Campus -->
              <div class="col-md-12 mt-5">
                <label for="campus" class="question-text">Campus donde quieres trabajarlo</label>
                <select class="form-select" name="campus" id="campus" formControlName="campus">
                  <option value="">Selecciona una opción</option>
                  <option *ngFor="let campus of campusData" value="{{ campus.idCampus}}"
                    selected="{{ verifySelected(campus.idCampus, 'campus') }}">
                    {{ campus.campus }}
                  </option>
                </select>
              </div>
            </div>


            <div class="row mt-5">
              <div class="col-2">
                <app-button ngForm buttonType="yell" extraClass="btn-full">
                  Enviar problemática</app-button>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="row" *ngIf="validationErrors.length > 0">
                  <ul class="text-danger">
                    <li *ngFor="let error of validationErrors">{{error}}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </form>
</div>

