import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-convenios',
  templateUrl: './convenios.component.html',
  styleUrls: ['./convenios.component.css']
})
export class ConveniosComponent implements OnInit {





  sidebarExpanded = false;
  lang : string = 'es';
  conveniosData:[];

  title = {
    title: 'Convenios'
  };

  ngOnInit(): void {
    this.lang = localStorage.getItem('lang') ?? 'es';
  }

  changeLang(lang:string){
    this.lang  = lang;
    localStorage.setItem('lang',lang);
    window.location.reload();
  }

}
