<form (ngSubmit)="onSubmit()" class="frm_registro_sf" [formGroup]="formularioForm">
    <div class="row px-0">


        <div class="row">
            <div class="col-3">
                <label for="fechaInicio">Fecha Inicio:</label>
                <input type="text" id="fechaInicio">
            </div>
            <div class="col-3">
                <label for="fechaFin">Fecha Fin:</label>
                <input type="text" id="fechaFin">
            </div>
            <div class="col-3">
                <label for="fechaInicio">Subir convenio:</label>
                <input type="file" id="conveio">
            </div>

            <button type="button" class="btn-yellow">Guardar</button>
        </div>
    </div>
</form>