<div class="subtitlecard textblue mb-3">CASO DE ÉXITO</div>

<div class="section" *ngIf="!existeCE">

    <div class="txt-option">No hay caso de éxito registrado.</div>

</div>

<div class="section-reto row" *ngIf="existeCE">

    <div class="col-md-3 center ">
        <div class="imgContainer">
            <img src="{{lbl_ce_url_imagen}}" alt="Reto" class="imgcolumna_izq1 img-fluid">
        </div>
        <div class="col-md-12">
            <div class="">
                <a href="{{ lbl_ce_evidencia }}" class="txtcard textblue" target="_blank" rel="noopener noreferrer"> Ver Evidencia </a>
            </div>
            <div>
                <a href="{{ lbl_ce_url }}" class="txtcard textblue" target="_blank"
                    rel="noopener noreferrer">{{lbl_ce_url}}</a>
            </div>
        </div>
    </div>
    
    <div class="col-md-7">
        <p class="titlecard">RESULTADO DEL RETO</p>
        <p class="txtcard">{{ lbl_ce_resultado_reto }}
        </p>
    </div>
    



</div>