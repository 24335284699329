<div class="lmt_text">

  <a target="{{ target }}" rel="{{ rel }}" 
    *ngIf="elementTextCol.link  && !elementTextCol.link.toString().endsWith('.pdf')" 
    href="{{ elementTextCol.link }}">
    <p title="{{ elementTextCol.title}}">{{ element_45 }}</p>
  </a>

  <a 
    class="urlLink"
    *ngIf="elementTextCol.link && elementTextCol.link.toString().endsWith('.pdf')" 
    (click)="listenerCallback(elementTextCol)" 
    target="_blank" 
    rel="noopener noreferrer">
    <p title="{{ elementTextCol.title}}">{{ element_45 }}</p>
  </a>
  
  <p *ngIf="!elementTextCol.link" title="{{ elementTextCol.title }}">{{ element_45 }}</p>
  
  <p class="subtitle" title="{{ elementTextCol.subtitle }}">{{ subtitle_40 }}</p>

</div>