<app-header-buscador [showSearchBar]="false"></app-header-buscador>

<div class="sec_ad">

  <div class="row">

    <div class="col-12">
      <app-title-show-more [showMoreObject]="titleSolicitud"></app-title-show-more>
    </div>

  </div>

  <div>
    <app-btn-regresar></app-btn-regresar>
  </div>

  <!-- Muestra el estatus de la vinculación -->
  <div class="row mt-3">

    <div class="subtit1col mt-3 mb-1"><strong>Estatus de la solicitud</strong></div>

    <!-- recibida -->
    <div class="col-12">
      <div class="container-alert-info" *ngIf="solicitudData !== undefined &&
          solicitudData.claveEstatus.trim() === 'registrado' && muestraAlerta ">
        <div class="row">
          <div class="col-md-12 mt-2 mb-2">
            <img class="icono-info" alt="Alerta" src="assets/svg/download.svg" /> <span class="textinfo">Esta solicitud
              fue recibida el {{ solicitudData.fechaActualizacion | date: 'dd MMMM yyyy' }}.</span>
          </div>
        </div>
      </div>
    </div>

    <!-- revision -->
    <div class="col-12">
      <div class="container-alert-info" *ngIf="solicitudData !== undefined &&
      solicitudData.claveEstatus.trim() === 'revision'&& muestraAlerta ">
        <div class="col-md-12 mt-2 mb-2">
          <img class="icono-info" alt="Alerta" src="assets/svg/download.svg" /> <span class="textinfo">
            {{ solicitudData.actualizadoPor }} actualizó el estatus de esta solicitud a "En Revisión" el
            {{ solicitudData.fechaActualizacion | date: 'dd MMMM yyyy' }}.</span>
        </div>
      </div>

    </div>

    <!-- aprobada aceptada -->
    <div class="col-12" *ngIf="solicitudData !== undefined &&
      ['aprobado','aceptado','activo'].includes(solicitudData.claveEstatus.trim())
           && muestraAlerta">
      <div class="container-alert">
        <div class="col-md-12 mt-2 mb-2">
          <img class="icono-success" alt="Alerta" src="assets/img/success.svg" /> <span class="textsuccess">{{
            "solicitud_sf_potencial-rechazo.alerta_aprobar_solicitud" | translate }} {{ solicitudData.actualizadoPor
            }} el {{ solicitudData.fechaActualizacion | date: 'dd MMMM yyyy' }}</span>
        </div>
      </div>
      <div class=" mt-2" *ngIf="edit">
        Puedes cambiar el estatus de esta solicitud a "rechazada" o reasignarla a otro campus.
      </div>
    </div>

    <!-- rechazada noaceptada -->
    <div class="col-12" *ngIf="solicitudData !== undefined &&
      ['rechazado','no-aceptado'].includes(solicitudData.claveEstatus.trim())
           && muestraAlerta ">
      <div class=" container-alert-rechazo">
        <div class="col-md-12 mt-2 mb-2">
          <img class="icono-success" alt="Alerta" src="assets/img/icons8-close.svg" /> <span class="textrechazo">{{
            "solicitud_sf_potencial-rechazo.alerta_rechazo_solicitud" | translate }} el {{
            solicitudData.fechaActualizacion | date: 'dd MMMM yyyy' }}</span>
        </div>
        <div class="textrechazo ms-5 d-none"> Motivo: {{ solicitudData.motivoRechazo}}</div>
      </div>

      <div class="mt-2" *ngIf="edit">
        Puedes cambiar el estatus de esta solicitud a "aceptada" o reasignarla a otro campus.
      </div>
    </div>

    <!-- Ver Comentarios -->
    <div class="col-12" *ngIf="['cvdp','cvdp_admin'].includes(clavePerfil)">

      <mat-accordion>
        <mat-expansion-panel class="my-2 px-3" (opened)="panelOpenState = true" (closed)="panelOpenState = false">

          <mat-expansion-panel-header class="my-4 ">
            <div class="">
              <span class="title-option">Ver comentarios </span><br />

            </div>
          </mat-expansion-panel-header>

          <div class="maxHeight">
            <div class="col-12" *ngFor="let com of comentariosData">
              <div>
                Fecha: {{com.fechaRegistro}}<br />
                Estatus: {{obtenerEstatusSeguimiento(com.claveEstatus)}}<br />
                Comentarios: {{ com.comentarios}}
              </div>
              <hr>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>

    </div>

    <!-- Formulario de Comentarios -->
    <div class="col-12" *ngIf="['cvdp','cvdp_admin'].includes(clavePerfil)">
      <div class="bkgatomic_alert_w">
        <form [formGroup]="estatusSolForm" (submit)="guardarComentarios()">
          <div class="row">
            <div class="col-6">
              <div class="col-12">
                <span
                   *ngIf="!['rechazado','aprobado'].includes(solicitudData.claveEstatus)"
                   class="">
                   <strong>Selecciona el estatus de la solicitud</strong>
                  </span>
                <select
                *ngIf="!['rechazado','aprobado'].includes(solicitudData.claveEstatus)"
                class="form-select mb-4" name="status" id="status" formControlName="estatus">
                  <option value="revision">En revisión</option>
                  <option value="registrado">Recibido</option>
                </select>
              </div>

              <div class="col-12">
                <textarea style="border-radius: 5px; width: 100%;" formControlName="comentario" rows="4"
                  placeholder='Escribe un comentario sobre el estatus de la solicitud por ejemplo "Ya se contactó al profesor coordinador del reto", etc.'
                  required></textarea>
                  <div
              *ngIf="estatusSolForm.get('comentario')?.touched && estatusSolForm.get('comentario')?.errors?.['required']"
              class="text-danger">
              El campo es obligatorio
            </div>
              </div>
            </div>
            <div class="col-6 justify-content-end d-flex">
              <div>
                <button class="btn yell_button_small_w">Guardar</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>

  </div>

  <!-- Información del Socio Formador -->
  <div class="row">

    <div class="col-12">

      <div class="row">
        <div class="col-9">
          <div class="subtit1col mt-3 mb-1"><strong>Socio Formador que solicita la vinculación</strong></div>
        </div>

        <div class="col-3">
          <!-- Editar -->
          <div class="mb-1" *ngIf="['cvdp','cvdp_admin'].includes(clavePerfil)">
            <button class="yell_button" (click)="editar()"> <img alt="" src="assets/img/editar.svg"
                class="btn_editar">{{
              'academia_banco_socio_formador.editar_socio_formador' | translate }} Solicitud</button>
          </div>
        </div>
      </div>

      <div class="col bkgatomic_alert_w" *ngIf="socioFormador">
        <div class="row">
          <div class="col-1 atomic_spc">
            <img class="img_avt_mn" alt="Logotipo" src="{{ socioFormador.urlLogo }}" />
          </div>
          <div class="col-11 atomic_spc">
            <div class="titsans_contact_b">
              <u (click)="verSocioFormador(socioFormador.idSocioFormador)">
                <strong>{{socioFormador.nombreSocioFormador }}</strong>
              </u>
            </div>
            <div class="subtgris">{{ socioFormador.empresa }}</div>
          </div>
        </div>
        <div class="col-12 mt-4">
          <div class="title-option">
            Persona de contacto:
          </div>
          <div class="ms-2">
            {{ contactoPrincipal?.nombre || 'Sin contacto principal' }} {{ contactoPrincipal?.apellidos || '' }}
            <br />
            {{ contactoPrincipal?.email || ''}}
          </div>
        </div>
        <div class="col-12 mt-4">
          <div class="title-option">
            Campus de atención del Socio Formador:
          </div>
          <div class="ms-2"> {{ socioFormador.campus }} </div>

        </div>
      </div>
    </div>

  </div>

  <!-- Medio por el cual se realizó la busqueda del reto -->
  <div class="row">
    <div class="col-12">
      <div class="subtit1col mt-3 mb-1"><strong>Medio por el cual se realizó la búsqueda del reto:</strong></div>
      <!-- banco de retos -->
      <div class="col bkgatomic_alert_w" *ngIf="busquedaPorBancodeRetos">
        <div class="col-12 mt-1">
          <div class="title-option">
            Banco de Retos
          </div>
        </div>
      </div>

      <!-- con algoritmo -->
      <div class="col bkgatomic_alert_w" *ngIf="!busquedaPorBancodeRetos">
        <div class="col-12 mt-1 mb-3">
          <div class="title-option">
            Algoritmo
          </div>
        </div>
        <div class="ms-4">
          <div class="col-12 mt-1">
            <div class="title-option">
              Áreas de la empresa/organización que están involucradas:
            </div>
            <div class="ms-2">
              {{ lbl_M_areas }}
            </div>
          </div>
          <div class="col-12 mt-4">
            <div class="title-option">
              Alcance de la problemática o tema de interés:
            </div>
            <div class="ms-2">
              {{ lbl_M_alcance }}
            </div>
          </div>
          <div class="col-12 mt-4">
            <div class="title-option">
              Contexto de la problemática o tema de interés:
            </div>
            <div class="ms-2">
              {{ lbl_M_contexto }}
            </div>
          </div>
          <div class="col-12 mt-4">
            <div class="title-option">
              Objetivos que el Socio Formador busca lograr con el acompañamiento académico:
            </div>
            <div class="ms-2">
              {{ lbl_M_objetivos }}
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

  <!-- Información de la vinculación -->
  <div class="row">
    <div class="col-12">
      <div class="subtit1col mt-3 mb-1"><strong>Sobre la vinculación</strong></div>

      <!-- Mostrar la informacion de vinculacion - Lectura -->
      <div *ngIf="!edit" class="p-0">
        <div class="col bkgatomic_alert_w">
          <div class="col-12 mt-1">
            <div class="title-option">
              Periodo:
            </div>
            <div class="ms-2">
              {{ lbl_periodo }}
            </div>
          </div>
          <div class="col-12 mt-4">
            <div class="title-option">
              Campus donde le gustaría llevar a cabo el reto:
            </div>
            <div class="ms-2">
              {{ lbl_listado_campus }}
            </div>
          </div>
          <div class="col-12 mt-4">
            <div class="title-option">
              Campus donde le gustaría gestionar la vinculación:
            </div>
            <div class="ms-2">
              {{ lbl_campus_coordinador }} {{ lblCampusReasignado }}
            </div>
          </div>
        </div>
      </div>


      <!-- Mostrar la información de Vinculación al Socio - Edicion -->
      <section class="col-md-12 col-xs-12 mb-6" *ngIf="edit">
        <form [formGroup]="vinculacionEdicionForm" autocomplete="off">
          <div class="pt-3">

            <!-- Periodo -->
            <span class="question-text mt-4">Selecciona el periodo semestral</span><br />
            <div class="text-danger" *ngIf="pedirPeriodoAcademico">
              Este campo es requerido
            </div>
            <select class="form-select form-select-sm" id="periodoSemestral" name="periodoSemestral"
              formControlName="idPeriodo" (change)="seleccionarPeriodo($event)" placeholder="Selecciona un periodo">
              <option value="">Seleccione una opción</option>
              <option *ngFor="let el of this.periodoAcademicoData" value="{{ el.idPeriodo }}">
                {{ el.periodo }}
              </option>
            </select>

            <!-- Campus donde se lleva el reto -->
            <div class="col-md-12 mt-3">

              <div class="row">
                <div class="col-md-12 mt-4">
                  <span class="question-text">
                    Campus donde se llevará a cabo el reto
                    <span class="text-danger">*</span>
                  </span>
                </div>

                <div class="text-danger" *ngIf="pedirListaCampus">
                  Este campo es requerido
                </div>

                <div class="col-md-12 espacio">
                  <span class="text-muted">Selecciona una o más opciones</span>
                  <div class="row">
                    <div class="col-md-12 answer-text">
                      <div class="row">
                        <div class="col-6">
                          <div class="col col-md-12" *ngFor="let campus of seccion1">
                            <input type="checkbox" class="btn-check" id="{{ campus.idCampus}}-{{ campus.campus }}"
                              autocomplete="off" value="{{ campus.idCampus}}"
                              (change)="onCheckboxChange($event, 'campus')"
                              [checked]="verifyChecked(campus.idCampus, 'campus')" />
                            <label class="btn btn-outline-primary"
                              for="{{ campus.idCampus}}-{{ campus.campus }}"></label>
                            <span>{{ campus.campus }}</span>
                          </div>
                        </div>

                        <div class="col-6">
                          <div class="col col-md-12" *ngFor="let campus of seccion2">
                            <input type="checkbox" class="btn-check" id="{{ campus.idCampus}}-{{ campus.campus }}"
                              autocomplete="off" value="{{ campus.idCampus}}"
                              (change)="onCheckboxChange($event, 'campus')" />

                            <label class="btn btn-outline-primary"
                              for="{{ campus.idCampus}}-{{ campus.campus }}"></label>
                            <span>{{ campus.campus }}</span>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>
              </div>
            </div>

            <!-- Campus coordinador -->
            <div class="col-md-12 mt-4">

              <span class="question-text">Campus donde se gestionará la vinculación <span
                  class="text-danger">*</span></span>
            </div>

            <div class="text-danger" *ngIf="pedirCampusCoordinador">
              Este campo es requerido
            </div>

            <select class="form-select form-select-sm" id="campusReto" name="campusReto" (change)="seleccionar($event)"
              formControlName="campusCoordinador" placeholder="Selecciona un campus">
              <option value="">Seleccione una opción</option>
              <option *ngFor="let el of this.selectedCampus" value="{{ el.idCampus }}">
                {{ el.campus }}
              </option>
            </select>

          </div>
        </form>
      </section>

    </div>
  </div>

  <!-- Información del reto Lectura/Edicion -->
  <div class="row">
    <div class="col-12">
      <div class="subtit1col mt-3 mb-1"><strong>Reto a vincular</strong></div>
      <!-- Lectura -->
      <div class="colr bkgatomic_alert_w" *ngIf="!edit">

        <div class="col-12 ">
          <div class="title-option">Unidad Formativa:</div>
          <div class="ms-2">{{ retoInformacion.reto }}</div>
        </div>

        <div class="col mt-4">
          <div class="title-option">Escuela:</div>
          <div class="ms-2">
            {{ retoInformacion.escuela }}
          </div>
        </div>

        <div class="col mt-4">
          <div class="title-option">Programa académico:</div>
          <div class="ms-2">
            {{ lbl_programasAcademicos }}
          </div>
        </div>

        <div class="row">
          <div class="col-6 mt-4">
            <div class="title-option">Periodo Semestral:</div>
            <div class="ms-2">
              {{ lbl_periodosSemestrales }}
            </div>
          </div>

          <div class="col-6 mt-4">
            <div class="title-option">Periodo Semanal:</div>
            <div class="ms-2">
              {{ lbl_periodosSemanales }}
            </div>
          </div>
        </div>

        <!-- Iconos - Detalle del reto  -->
        <div class="row mt-5">
          <div class="col-md-12">
            <div class="ico_reto_container">
              <div class="ico_reto_container_local"></div>
              <div class="ico_reto_numsem"></div>
              <div class="ico_reto_container_inner_label">{{ lbl_alcance }}</div>
            </div>

            <div class="ico_reto_container">
              <div class="ico_reto_container_semestre"></div>
              <div class="ico_reto_numsem"></div>
              <div class="ico_reto_container_inner_label">{{ lbl_semestre }}</div>
            </div>

            <div class="ico_reto_container">
              <div class="ico_reto_container_calendario"></div>
              <div class="ico_reto_numsem">&nbsp;{{ lbl_semana_numero }}</div>
              <div class="ico_reto_container_inner_label">{{ lbl_semana }}</div>
            </div>

            <div class="ico_reto_container">
              <div class="ico_reto_container_lang"></div>
              <div class="ico_reto_numlang">{{ lbl_idioma_numero }}</div>
              <div class="ico_reto_container_inner_label">{{ lbl_idioma }}</div>
            </div>

            <div class="ico_reto_container" *ngIf="retoInformacion.sentidoHumano">
              <div class="ico_reto_container_SentidoHumano"></div>
              <div class="ico_reto_container_inner_label_SH">Reto con Sentido Humano</div>
            </div>
          </div>
        </div>


        <hr class="my-5" style="color: #BEDCFE;">

        <!-- Descripcion, objetivo entregable -->
        <div class="col-md-12">
          <div class="title-option mt-4">{{ "pagina_retos_detalles.descripcion" | translate }}</div>
          <div class="">{{ retoInformacion.descripcion }}</div>

          <div class="title-option mt-4">{{ "pagina_retos_detalles.objetivo" | translate }}</div>
          <div class=""> {{ retoInformacion.objetivo }} </div>

          <div class="title-option mt-4">{{ "pagina_retos_detalles.entregable" | translate }}</div>
          <div class=""> {{ retoInformacion.entregable }} </div>
        </div>

        <!-- Sector, tamano, industria, presencia, descripcion del SF -->
        <div class="col-12 mt-4">
          <div class="title-option " [innerHTML]="'pagina_retos_detalles.label_requisitos' | translate "></div>
          <div class="" [innerHTML]="'pagina_retos_detalles.label_requisitos_subtitulo' | translate"></div>

          <div class="row mt-4" *ngIf="retoInformacion.sectores">
            <div class="col-12"></div>
            <section class="col-md-12">
              <div>
                <span class="imgcard">
                  <img src="/assets/img/icon_empresa.png" alt="Sector" class="imgcarrousel_reto" /></span>
                <span class="txtcard ms-3" [innerHTML]="lbl_sf_sectores"></span><br />
              </div>
            </section>
          </div>


          <div class="row mt-4" *ngIf="retoInformacion.tamanos">
            <div class="col-12"></div>
            <section class="col-md-12">
              <div>
                <span class="imgcard"><img src="/assets/img/icon_empresa.png" alt="Tamaño"
                    class="imgcarrousel_reto" /></span>
                <span class="txtcard ms-3" [innerHTML]="lbl_sf_tamanos"></span><br />
              </div>
            </section>
          </div>

          <div class="row mt-4" *ngIf="retoInformacion.industrias">
            <div class="col-12"></div>
            <section class="col-md-12">
              <div>
                <span class="imgcard"><img src="/assets/img/icon_empresa.png" alt="Industria"
                    class="imgcarrousel_reto" /></span>
                <span class="txtcard ms-3" [innerHTML]="lbl_sf_industria"></span><br />
              </div>
            </section>
          </div>

          <div class="row mt-4" *ngIf="retoInformacion.presencias">
            <div class="col-12"></div>
            <section class="col-md-12">
              <div>
                <span class="imgcard"><img src="/assets/img/icon_empresa.png" alt="Presencia del Socio Formador"
                    class="imgcarrousel_reto" /></span>
                <span class="txtcard ms-3" [innerHTML]="lbl_sf_presencias"></span><br />
              </div>
            </section>
          </div>

          <div class="row mt-4" *ngIf="retoInformacion.descripcion">
            <div class="title-option mt-4">{{ "pagina_retos_detalles.descripcion_socio_formador" | translate }}</div>
            <div class="ms-2"> {{ lbl_sf_descripcion }} </div>
          </div>

        </div>

      </div> <!-- fin -->

      <!-- Edicion -->
      <div *ngIf="edit">
        <form [formGroup]="vinculacionForm" class="mt-4" (submit)="quieroVincularmeaReto()" autocomplete="off">

          <div class="col-6">
            <span class="question-text">Selecciona la Escuela <span class="text-danger">*</span></span>

            <div class="requerido" *ngIf="mostrarRequeridos && vinculacionForm.get('idEscuela')?.errors?.['required']">
              Este campo es requerido.
            </div>

            <ng-select aria-label="Default example" formControlName="idEscuela" (change)="getUnidadFormativa($event)">
              <ng-option *ngFor="let escuela of escuelaData" [value]="escuela.idEscuela">
                {{ escuela.escuela }}
              </ng-option>
            </ng-select>
            <br>
          </div>

          <div class="col-6">
            <span class="question-text">Selecciona la Unidad Formativa <span class="text-danger">*</span></span>


            <div class="requerido"
              *ngIf="mostrarRequeridos && vinculacionForm.get('idUnidadFormativa')?.errors?.['required']">
              Este campo es requerido.
            </div>

            <ng-select aria-label="Default example" formControlName="idUnidadFormativa"
              (change)="getRetoDetail($event)">
              <ng-option *ngFor="let unidad of unidadFormativaData" [value]="unidad.idUnidadFormativa">
                {{ unidad.clave }}_{{ unidad.unidadFormativa }}
              </ng-option>
            </ng-select>
            <br>
          </div>

          <!-- Informació del reto seleccionado -->
          <div *ngIf="tieneReto">

            <div class="colr bkgatomic_alert_w">

              <div class="row mb-4">
                <div class="col">
                  <img class="imgpchip_med" alt="icon" src="assets/svg/icon_info.svg" />&nbsp;
                  <span class="titatomic">
                    <strong>Información del reto asociado a la UF</strong>
                  </span>
                </div>
              </div>

              <div class="col mt-4">
                <div class="title-option">Escuela:</div>
                <div class="ms-2">
                  {{ retoInfo.escuela }}
                </div>
              </div>

              <div class="col-12 mt-4">
                <div class="title-option">Unidad Formativa:</div>
                <div class="ms-2">{{ retoInfo.reto }}</div>
              </div>



              <div class="col mt-4">
                <div class="title-option">Programa académico:</div>
                <div class="ms-2">
                  {{ lbl_programasAcademicos }}
                </div>
              </div>

              <div class="row">
                <div class="col-6 mt-4">
                  <div class="title-option">Periodo Semestral:</div>
                  <div class="ms-2">
                    {{ lbl_periodosSemestrales }}
                  </div>
                </div>

                <div class="col-6 mt-4">
                  <div class="title-option">Periodo Semanal:</div>
                  <div class="ms-2">
                    {{ lbl_periodosSemanales }}
                  </div>
                </div>
              </div>

              <!-- Iconos - Detalle del reto  -->
              <div class="row mt-5">
                <div class="col-md-12">
                  <div class="ico_reto_container">
                    <div class="ico_reto_container_local"></div>
                    <div class="ico_reto_numsem"></div>
                    <div class="ico_reto_container_inner_label">{{ lbl_alcance }}</div>
                  </div>

                  <div class="ico_reto_container">
                    <div class="ico_reto_container_semestre"></div>
                    <div class="ico_reto_numsem"></div>
                    <div class="ico_reto_container_inner_label">{{ lbl_semestre }}</div>
                  </div>

                  <div class="ico_reto_container">
                    <div class="ico_reto_container_calendario"></div>
                    <div class="ico_reto_numsem">&nbsp;{{ lbl_semana_numero }}</div>
                    <div class="ico_reto_container_inner_label">{{ lbl_semana }}</div>
                  </div>

                  <div class="ico_reto_container">
                    <div class="ico_reto_container_lang"></div>
                    <div class="ico_reto_numlang">{{ lbl_idioma_numero }}</div>
                    <div class="ico_reto_container_inner_label">{{ lbl_idioma }}</div>
                  </div>

                  <div class="ico_reto_container" *ngIf="retoInformacion.sentidoHumano">
                    <div class="ico_reto_container_SentidoHumano"></div>
                    <div class="ico_reto_container_inner_label_SH">Reto con Sentido Humano</div>
                  </div>
                </div>
              </div>


              <hr class="my-5" style="color: #BEDCFE;">

              <!-- Descripcion, objetivo entregable -->
              <div class="col-md-12">
                <div class="title-option mt-4">{{ "pagina_retos_detalles.descripcion" | translate }}</div>
                <div class="">{{ retoInfo.descripcion }}</div>

                <div class="title-option mt-4">{{ "pagina_retos_detalles.objetivo" | translate }}</div>
                <div class=""> {{ retoInfo.objetivo }} </div>

                <div class="title-option mt-4">{{ "pagina_retos_detalles.entregable" | translate }}</div>
                <div class=""> {{ retoInfo.entregable }} </div>
              </div>

              <!-- Sector, tamano, industria, presencia, descripcion del SF -->
              <div class="col-12 mt-4">
                <div class="title-option " [innerHTML]="'pagina_retos_detalles.label_requisitos' | translate "></div>
                <div class="" [innerHTML]="'pagina_retos_detalles.label_requisitos_subtitulo' | translate"></div>

                <div class="row mt-4" *ngIf="retoInfo.sectores">
                  <div class="col-12"></div>
                  <section class="col-md-12">
                    <div>
                      <span class="imgcard">
                        <img src="/assets/img/icon_empresa.png" alt="Sector" class="imgcarrousel_reto" /></span>
                      <span class="txtcard ms-3" [innerHTML]="lbl_sf_sectores"></span><br />
                    </div>
                  </section>
                </div>


                <div class="row mt-4" *ngIf="retoInfo.tamanos">
                  <div class="col-12"></div>
                  <section class="col-md-12">
                    <div>
                      <span class="imgcard"><img src="/assets/img/icon_empresa.png" alt="Tamaño"
                          class="imgcarrousel_reto" /></span>
                      <span class="txtcard ms-3" [innerHTML]="lbl_sf_tamanos"></span><br />
                    </div>
                  </section>
                </div>

                <div class="row mt-4" *ngIf="retoInfo.industrias">
                  <div class="col-12"></div>
                  <section class="col-md-12">
                    <div>
                      <span class="imgcard"><img src="/assets/img/icon_empresa.png" alt="Industria"
                          class="imgcarrousel_reto" /></span>
                      <span class="txtcard ms-3" [innerHTML]="lbl_sf_industria"></span><br />
                    </div>
                  </section>
                </div>

                <div class="row mt-4" *ngIf="retoInfo.presencias">
                  <div class="col-12"></div>
                  <section class="col-md-12">
                    <div>
                      <span class="imgcard"><img src="/assets/img/icon_empresa.png" alt="Presencia del Socio Formador"
                          class="imgcarrousel_reto" /></span>
                      <span class="txtcard ms-3" [innerHTML]="lbl_sf_presencias"></span><br />
                    </div>
                  </section>
                </div>

                <div class="row mt-4" *ngIf="retoInfo.descripcion">
                  <div class="title-option mt-4">{{ "pagina_retos_detalles.descripcion_socio_formador" | translate }}
                  </div>
                  <div class="ms-2"> {{ lbl_sf_descripcion }} </div>
                </div>

              </div>

            </div> <!-- fin -->


          </div>

        </form>

      </div>

    </div>
  </div>

  <!-- botonera - no edit-->
  <div class="row" *ngIf="!edit">
    <!-- (solicitudData.claveEstatus === 'revision' || solicitudData.claveEstatus === 'registrado' ) -->
    <div class="row justify-content-center"
      *ngIf="solicitudData !== undefined && muestraBotones && ['cvdp','cvdp_admin'].includes(clavePerfil)">

      <div class="col-md-3" *ngIf="'aprobado' !== solicitudData.claveEstatus">
        <span class="#"><button class="green_button" (click)="handleAceptar()">
            <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 15.2 11.06"
              class="iCn replaced-svg">
              <defs>
                <style>
                  .cls-1 {
                    fill: #4c964d;
                  }
                </style>
              </defs>
              <path class="cls-1"
                d="M5.28,13.71a1.74,1.74,0,0,1-.64-.13,1.65,1.65,0,0,1-.54-.36L.44,9.56l1.4-1.39,3.44,3.44,9-9L15.64,4,6.47,13.22a1.69,1.69,0,0,1-.55.36A1.74,1.74,0,0,1,5.28,13.71Z"
                transform="translate(-0.44 -2.65)"></path>
            </svg>
            Aceptar
          </button></span>
      </div>

      <div class="col-md-3" *ngIf="solicitudData.claveEstatus !== 'rechazado'">
        <span class="#"><button class="pink_button" (click)="handleRechazar()">
            <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 12.91 12.91"
              class="iCn replaced-svg">
              <defs>
                <style>
                  .cls-1 {
                    fill: #ce0032;
                  }
                </style>
              </defs>
              <path class="cls-1"
                d="M14.47,13.14l-1.13,1.14L8,9,2.7,14.28,1.56,13.14,6.88,7.82,1.56,2.5,2.7,1.37,8,6.69l5.32-5.32L14.47,2.5,9.15,7.82Z"
                transform="translate(-1.56 -1.37)"></path>
            </svg>
            Rechazar
          </button>
        </span>
      </div>

      <div class="col-md-3">
        <span class="#"><button class="blue_button" (click)="handleReasignar()">
            Reasignar
          </button>
        </span>
      </div>


    </div>


    <div class="row"
      *ngIf="solicitudData !== undefined && solicitudData.claveEstatus === 'aprobado' && muestraBotones && 'academia' === clavePerfil">
      <div class="col-md-3"></div>
      <div class="col-md-3" *ngIf="'' !== solicitudData.claveEstatus">
        <span class="#"><button class="green_button" (click)="handleAceptarAcademico()">
            <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 15.2 11.06"
              class="iCn replaced-svg">
              <defs>
                <style>
                  .cls-1 {
                    fill: #4c964d;
                  }
                </style>
              </defs>
              <path class="cls-1"
                d="M5.28,13.71a1.74,1.74,0,0,1-.64-.13,1.65,1.65,0,0,1-.54-.36L.44,9.56l1.4-1.39,3.44,3.44,9-9L15.64,4,6.47,13.22a1.69,1.69,0,0,1-.55.36A1.74,1.74,0,0,1,5.28,13.71Z"
                transform="translate(-0.44 -2.65)"></path>
            </svg>
            Aceptar
          </button></span>
      </div>
      <div class="col-md-3">
        <span class="#"><button class="pink_button" (click)="handleRechazarAcademico()">
            <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 12.91 12.91"
              class="iCn replaced-svg">
              <defs>
                <style>
                  .cls-1 {
                    fill: #ce0032;
                  }
                </style>
              </defs>
              <path class="cls-1"
                d="M14.47,13.14l-1.13,1.14L8,9,2.7,14.28,1.56,13.14,6.88,7.82,1.56,2.5,2.7,1.37,8,6.69l5.32-5.32L14.47,2.5,9.15,7.82Z"
                transform="translate(-1.56 -1.37)"></path>
            </svg>
            Rechazar
          </button></span><br />
      </div>
      <div class="col-md-3"></div>
    </div>


    <div class="row"
      *ngIf="solicitudData !== undefined && (solicitudData.claveEstatus === 'no-aceptado'||solicitudData.claveEstatus === 'rechazado') && clavePerfil =='cvdp' ">
      <div class="col-md-3"></div>
      <div class="col-md-3">
        <span class="#"><button class="pink_button" (click)="handleRevertirRechazo()">
            <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 12.91 12.91"
              class="iCn replaced-svg">
              <defs>
                <style>
                  .cls-1 {
                    fill: #ce0032;
                  }
                </style>
              </defs>
              <path class="cls-1"
                d="M14.47,13.14l-1.13,1.14L8,9,2.7,14.28,1.56,13.14,6.88,7.82,1.56,2.5,2.7,1.37,8,6.69l5.32-5.32L14.47,2.5,9.15,7.82Z"
                transform="translate(-1.56 -1.37)"></path>
            </svg>
            Revertir rechazo
          </button></span><br />
      </div>
      <div class="col-md-3"></div>
    </div>

  </div>

  <!-- botonera - edit -->
  <div class="row" *ngIf="edit">

    <div class="col-12 d-flex justify-content-center">

      <button type="button" class="btn yell_button_small_w mx-2" (click)="handleGuardar()"> Guardar</button>
      <button type="button" class="btn blue_button_small mx-2" (click)="handleCancelar()">Cancelar</button>

    </div>


    <div class="row"
      *ngIf="solicitudData !== undefined && solicitudData.claveEstatus === 'aprobado' && muestraBotones && 'academia' === storeUser.clavePerfil">
      <div class="col-md-3"></div>
      <div class="col-md-3">
        <span class="#"><button class="green_button" (click)="handleAceptarAcademico()">
            <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 15.2 11.06"
              class="iCn replaced-svg">
              <defs>
                <style>
                  .cls-1 {
                    fill: #4c964d;
                  }
                </style>
              </defs>
              <path class="cls-1"
                d="M5.28,13.71a1.74,1.74,0,0,1-.64-.13,1.65,1.65,0,0,1-.54-.36L.44,9.56l1.4-1.39,3.44,3.44,9-9L15.64,4,6.47,13.22a1.69,1.69,0,0,1-.55.36A1.74,1.74,0,0,1,5.28,13.71Z"
                transform="translate(-0.44 -2.65)"></path>
            </svg>
            Aceptar
          </button></span>
      </div>
      <div class="col-md-3">
        <span class="#"><button class="pink_button" (click)="handleRechazarAcademico()">
            <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 12.91 12.91"
              class="iCn replaced-svg">
              <defs>
                <style>
                  .cls-1 {
                    fill: #ce0032;
                  }
                </style>
              </defs>
              <path class="cls-1"
                d="M14.47,13.14l-1.13,1.14L8,9,2.7,14.28,1.56,13.14,6.88,7.82,1.56,2.5,2.7,1.37,8,6.69l5.32-5.32L14.47,2.5,9.15,7.82Z"
                transform="translate(-1.56 -1.37)"></path>
            </svg>
            Rechazar
          </button></span><br />
      </div>
      <div class="col-md-3"></div>
    </div>


    <div class="row"
      *ngIf="solicitudData !== undefined && (solicitudData.claveEstatus === 'no-aceptado'||solicitudData.claveEstatus === 'rechazado') && clavePerfil =='cvdp' ">
      <div class="col-md-3"></div>
      <div class="col-md-3">
        <span class="#"><button class="pink_button" (click)="handleRevertirRechazo()">
            <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 12.91 12.91"
              class="iCn replaced-svg">
              <defs>
                <style>
                  .cls-1 {
                    fill: #ce0032;
                  }
                </style>
              </defs>
              <path class="cls-1"
                d="M14.47,13.14l-1.13,1.14L8,9,2.7,14.28,1.56,13.14,6.88,7.82,1.56,2.5,2.7,1.37,8,6.69l5.32-5.32L14.47,2.5,9.15,7.82Z"
                transform="translate(-1.56 -1.37)"></path>
            </svg>
            Revertir rechazo
          </button></span><br />
      </div>
      <div class="col-md-3"></div>
    </div>

  </div>



  <!-- Acciones de la botonera -->
  <div class="row aceptar_Asignar_Academico" *ngIf="mostrarAceptarAcademico">
    <div class="col mt-4">
      <div>
        <div class="title-option">Académico asignado:</div>
      </div>
      <div *ngIf="solicitudData !== undefined && !['revision','registrado'].includes(solicitudData.claveEstatus)">
        {{ lblProfesorAsignado }}
      </div>
      <div
        *ngIf="solicitudData !== undefined && ['revision','registrado','rechazado'].includes(solicitudData.claveEstatus) ">

        <ng-select id="campusProfesorAsignado" name="campusProfesorAsignado" (change)="seleccionarProfesor($event)"
          bindValue="idUsuario" bindLabel="nombreUsuario" [items]="profesoresxCampus"
          placeholder="Selecciona un profesor ... "></ng-select>

      </div>
    </div>

    <div class="row mt-5">
      <app-button buttonType="yell" (callbackAction)="enviarSolicitud()">Enviar</app-button>
    </div>

  </div>

  <div class="row Re-Asignar_Academico" *ngIf="mostrarReasignar">
    <div class="col mt-4">
      <div>
        <div class="title-option">Campus asignado:</div>
      </div>
      <div *ngIf="solicitudData !== undefined && !['revision','registrado'].includes(solicitudData.claveEstatus) ">
        {{ lblProfesorAsignado }}
      </div>
      <div
        *ngIf="solicitudData !== undefined && ['revision','registrado','rechazado'].includes(solicitudData.claveEstatus)">

        <ng-select id="campusReasginar" name="campusReasginar" (change)="seleccionarCampus($event)" bindLabel="campus"
          bindValue="idCampus" [items]="campus" placeholder="Selecciona un campus ... "></ng-select>


      </div>
    </div>

    <div class="row mt-5">
      <app-button buttonType="yell" (callbackAction)="enviarSolicitud()">Enviar</app-button>
    </div>

  </div>


</div>
