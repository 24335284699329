<app-header-buscador [showSearchBar]="false"></app-header-buscador>

<app-btn-regresar></app-btn-regresar>

<div class="row">
  <div class="col" id="sec_tit">
    <h2>{{ titleProfile }}</h2>
  </div>
</div>

<form class="mt-5" [formGroup]="casosForm" (submit)="edit_crear()">
  <div class="row form-perfil">
    <div class="col-md-3 text-center">
      <div>
        <img class="img_avt_ds" alt="logotipo" src="{{ imgUrl }}" />
      </div>
      <div class="invalidfeedback " *ngIf="casosForm.get('urlImagenCasoExito')?.touched && casosForm.get('urlImagenCasoExito')?.errors?.['required']" >
          La imagen es obligatoria
      </div>
      <div><br />Imagen .JPG o .PNG</div>
      <small>Tamaño Máx: 1024x1024 < 3Mb</small><span class="text-danger">*</span>
      <div class="editFoto fileUploadButton" (click)="file.click()"><br />Editar Foto</div>
            <input class="hidden" type="file" (change)="selImagen($event)" accept="image/*" #file/>
      <app-button-user
        *ngFor="let button of buttons; let i = index"
        (callbackAction)="onClickButton(button.url)"
        [buttons]="button"
      ></app-button-user>
    </div>
    <div class="col-md-9 text-form">

      <div class="row">
        <div class="col">
          <label for="nombreCasoExito"
            >Nombre <span class="text-danger">*</span></label
          >
          <app-text-input
            [formControl]="$any(casosForm.controls['nombreCasoExito'])"
            [label]="'nombre'"
            [placeholder]="'ej. Reto Discovery Kids'"
          ></app-text-input>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <label for="descripcionCasoExito">Descripcion<span class="text-danger">*</span></label>
           <textarea  matInput class="form-control forma_linea"
            [formControl]="$any(casosForm.controls['descripcionCasoExito'])"
            rows="5"
            [placeholder]="
              'ej. A través de esta concentración los ...'
            "
          ></textarea>
          <div class="invalidfeedback " *ngIf="casosForm.get('descripcionCasoExito')?.touched && casosForm.get('descripcionCasoExito')?.errors?.['required']" >
            El campo es requerido. Descripción
        </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <label for="anoCasoExito">Año<span class="text-danger">*</span></label>
           <app-text-input
            [formControl]="$any(casosForm.controls['anoCasoExito'])"
            [label]="'año Caso Exito'"
            [placeholder]="
              'ej. 2023'
            "
          ></app-text-input>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <label for="escuela">Escuela<span class="text-danger">*</span></label>
          <div class="select-dropdown">
            <select
              class="form-select"
              aria-label="Default select example"
              formControlName="idEscuela"
            >
              <option selected="true" value="">Selecciona</option>
              <option
                *ngFor="let escuela of escuelasData"
                value="{{ escuela.idEscuela }}"
                selected="{{ verifySelected(escuela.idEscuela, 'idEscuela') }}"
              >
                {{ escuela.escuela }}
              </option>
            </select>
            <div class="invalidfeedback " *ngIf="casosForm.get('idCampus')?.touched && casosForm.get('idCampus')?.errors?.['required']" >
              El campo es requerido. Escuela
          </div>

          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <label for="campus">Campus<span class="text-danger">*</span></label>
          <div class="select-dropdown">
            <select
              class="form-select"
              aria-label="Default select example"
              formControlName="idCampus"

            >
              <option selected="true" value="">Selecciona</option>
              <option
                *ngFor="let campus of campusData"
                value="{{ campus.idCampus }}"
                selected="{{ verifySelected(campus.idCampus, 'idCampus') }}"
              >
                {{ campus.campus }}
              </option>
            </select>
            <div class="invalidfeedback " *ngIf="casosForm.get('idCampus')?.touched && casosForm.get('idCampus')?.errors?.['required']" >
              El campo es requerido. Campus
          </div>
          </div>
        </div>
      </div>

      <div class="row" >
        <div class="col">
          <label for="urlConectaCasoExito"
            >link </label
          >
          <app-text-input
            [formControl]="$any(casosForm.controls['urlConectaCasoExito'])"
            [label]="'urlConectaCasoExito'"
            [placeholder]="'ej. /bolsa_trabajo'"
          ></app-text-input>
        </div>
      </div>

      <div class="row">

        <div class="col-6 d-flex j1ustify-content-center">
          <app-button [buttonType]="'yell'"> {{ btnText }} </app-button>
        </div>
      </div>
    </div>
  </div>
</form>


<div
  class="modal fade"
  id="idModalCorreoExistente"
  tabindex="-1"
  aria-labelledby="ModalCorreoExistente"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">

        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <h3>{{ this.mensajeModal }}</h3>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" data-bs-dismiss="modal">
          Cerrar
        </button>
      </div>
    </div>
  </div>
</div>
