<div class="col-{{ column.sizeCol === '' ? '2' : column.sizeCol }} {{ column.classType }}">
  <app-header-type *ngIf="!column.hide && column.typeColumn === 'text'" [title]="value" [icon]="column.icon"></app-header-type>
  <app-link-type *ngIf="!column.hide && column.typeColumn === 'link'" [elementTextCol]="value"></app-link-type>
  <app-status-type *ngIf="!column.hide && column.typeColumn === 'status'" [elementStatus]="value"></app-status-type>
  <app-progress-type *ngIf="!column.hide && column.typeColumn === 'progress'" [elementsProgress]="value"></app-progress-type>
  <app-image-title *ngIf="!column.hide && column.typeColumn === 'imageTitle'" [elementsImageTitle]="value"></app-image-title>
  <app-buttons *ngIf="!column.hide && column.typeColumn === 'buttons'" [elementsButton]="value"></app-buttons>
  <app-date-type *ngIf="!column.hide && column.typeColumn === 'date'" [title]="value" [icon]="column.icon"></app-date-type>
  <app-file-type *ngIf="!column.hide && column.typeColumn === 'file'" [objFileWithOwner]="value"></app-file-type>
  <app-message-type *ngIf="!column.hide && column.typeColumn === 'message'" [button]="value"></app-message-type>
</div>