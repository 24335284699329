import { Component, OnInit } from '@angular/core';
import { UsuariosService } from '@shared/service/Usuarios/usuarios.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-main-layout-buscador',
  templateUrl: './main-layout-buscador.component.html',
  styleUrls: ['./main-layout-buscador.component.css']
})
export class MainLayoutBuscadorComponent implements OnInit  {

  constructor(private cookieService: CookieService, private usuarioService: UsuariosService){

  }
  async ngOnInit() {

    if(!this.cookieService.check('usrActual')){

        await this.usuarioService.regitraIniciarSesion();

    }

  }

}
