<div class="section">
    <form [formGroup]="postularForm">
        <div class="row mt-4">

            <div class="col-1 mt-3"><button class="step-button-active text-center"> 1 </button>
            </div>
            <div class="col-11 my-3">
                <span class="title-option"> Resultados del reto
                </span>
                <input class="form-control" id="resultados" type="text"
                    placeholder="Escribe aquí el resultado del reto." formControlName="resultados">
            </div>

            <div class="col-1 mt-3"><button class="step-button-active text-center"> 2 </button>
            </div>
            <div class="col-11 my-3">
                <span class="title-option"> Liga de video, noticia e información de interés
                </span>
                <input class="form-control"
                    placeholder="Escribe aquí un link de interés: https://www.noticia.com.mx" type="text"
                    formControlName="link">
            </div>

            <div class="col-1 mt-3"><button class="step-button-active text-center"> 3 </button>
            </div>
            <div class="col-11 mt-3">
                <span class="title-option"> Comparte una imagen de referencia
                </span>
                <br>
                <button type="button" class="yell_button my-3" (click)="file.click()">Seleccionar archivo</button>

                <input class="d-none" type="file" id="fileConvenioUpload" formControlName="imagen" (change)="selImagen($event, 'imagen')"
                    accept="images/*" #file />
                <span> {{ lbl_url_img_fn }} </span>
                <span class="bg-white rounded-3 m-3 p-2" *ngIf="lbl_url_img.length > 0">
                    <a href="{{ lbl_url_img }}" class="txt-option" rel="noopener noreferrer" target="_blank">Ver archivo</a>
                </span>
            </div>

            <div class="col-1"><button class="step-button-active text-center"> 4 </button>
            </div>
            <div class="col-11">
                <span class="title-option"> Comparte un documento como evidencia. </span><br/>
                <span class="txt-option">
                    Este documento será visualizado por
                    otros académicos en el banco de retos.
                </span>
                <br>
                <button type="button" class="yell_button my-3" (click)="file2.click()">Seleccionar archivo</button>

                <input class="d-none" type="file" id="fileConvenioUpload" formControlName="evidencia" (change)="selImagen($event,'evidencia')"
                    accept="*/*" #file2 />
                    <span > {{ lbl_url_evidencia_fn }} </span>

                <span class="bg-white rounded-3 m-3 p-2" *ngIf="lbl_url_evidencia.length > 0">
                    <a href="{{ lbl_url_evidencia }}" class="txt-option" rel="noopener noreferrer" target="_blank">Ver archivo</a>
                </span>
            </div>

        </div>

        <div class="row">
            <div class="col-12 d-flex justify-content-end">
                <button type="button" class="yell_button" (click)="guardarCasoExito()">Postular</button>
            </div>
        </div>


    </form>
</div>