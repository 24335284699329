import { Component, Input, OnInit } from "@angular/core";
import { IRetoResponse } from "@shared/Interface/IReto.interface";
import { CasosDeExitoService } from "@shared/service/Casos_de_Exito/casos-de-exito.service";
import { lastValueFrom } from "rxjs";

@Component({
  selector: "app-casos-exito",
  templateUrl: "./casos-exito.component.html",
  styleUrls: ["./casos-exito.component.css"],
})
export class CasosExitoComponent implements OnInit {
  @Input() oReto: IRetoResponse;

  lbl_ce_url_imagen: string = "";
  lbl_ce_resultado_reto: string = "";
  lbl_ce_descripcion_reto: string = "";
  lbl_ce_url: string = "";
  lbl_ce_evidencia: string = "";
  existeCE: boolean = false;

  constructor(private caseExitoService: CasosDeExitoService) {}

  ngOnInit(): void {
    this.getCasodeExito();
  }

  async getCasodeExito() {
    try {
      this.lbl_ce_resultado_reto = "";
      this.lbl_ce_url_imagen = "";
      this.lbl_ce_url = "";
      this.existeCE = false;
      let res = await lastValueFrom(
        this.caseExitoService.getCasoExitoPoridReto(this.oReto.idReto)
      );
      
      this.lbl_ce_resultado_reto = res.descripcionCasoExito;
      this.lbl_ce_url_imagen = res.urlImagenCasoExito;
      this.lbl_ce_url = res.urlConectaCasoExito;
      this.lbl_ce_evidencia = res.urlEvidencia;
      this.existeCE = true;

    } catch (err) {
      console.log(err);
    }
  }
}
