import {
  Component,
  OnInit,
} from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  FormArray,
  Validators,
} from "@angular/forms";
import { Router, UrlTree } from "@angular/router";
import {
  cProgramaAcademico,
  CUnidadFormativa,
  IAlcance,
  IEscuela,
  IIdiomas,
  IIndustria,
  IPeriodo,
  IPeriodoSemanal,
  IPeriodoSemestral,
  IPresencia,
  ISector,
  ISemana,
  ITamanoEmpresa,
  IUnidadFormativa,
} from "@shared/Interface/ICatalogos.interface";
import { cRetoMaestro } from "@shared/Interface/IReto.interface";
import { CatalogosService } from "@shared/service/Catalogos/catalogos.service";
import { RetosService } from "@shared/service/Retos/retos.service";
import { UsuariosService } from "@shared/service/Usuarios/usuarios.service";
import { Observable, tap } from "rxjs";
import { IProgramaAcademico } from "../../shared/Interface/ICatalogos.interface";
import { IRetoMaestro } from "../../shared/Interface/IReto.interface";
import { ToastrService } from "ngx-toastr";

import Swal from "sweetalert2";
import { IUser } from "@shared/Interface/user";


@Component({
  selector: "app-registra-reto-semilla2",
  templateUrl: "./registra-reto-semilla2.component.html",
  styleUrls: ["./registra-reto-semilla2.component.css"],
})
export class RegistraRetoSemilla2Component implements OnInit {

  retoForm: FormGroup;


  retosMaestrosData: IRetoMaestro[];
  retoData: IRetoMaestro;


  
  programaAcademicoData: cProgramaAcademico[];
  programaAcademicoData1: cProgramaAcademico[];
  periodoAcademicoData: IPeriodo[];
  
  semestreData = [1, 2, 3, 4, 5, 6, 7, 8];
  
  unidadFormativaSeleccionada: IUnidadFormativa;

  buttonSave = {
    title: "Guardar Reto",
    isLoading: false,
  };

  isUpdate = false;
  retoToUpdate: any;

  retoMaestro: any;

  titleRegistroReto = {
    title: `Edición de retos semilla`,
  };

  mostrarPOE: boolean = false;
  mostrarPA: boolean = false;
  urlTree: UrlTree;

  listaProgramasAcademicosxUnidadFormativa: IProgramaAcademico[] = [];


  alcanceData$: Observable<IAlcance[]>;
  sectoresData$: Observable<ISector[]>;
  industriasData$: Observable<IIndustria[]>;
  tamanioEmpresasData$: Observable<ITamanoEmpresa[]>;
  semanasData$: Observable<ISemana[]>;
  idiomasData$: Observable<IIdiomas[]>;
  presenciaData$: Observable<IPresencia[]>;
  escuelaData$: Observable<IEscuela[]>;
  periodoSemestralData$: Observable<IPeriodoSemestral[]>;
  periodoSemanalData$: Observable<IPeriodoSemanal[]>;

  unidadFormativaData:CUnidadFormativa[];

  mostrarHorasSS = false;

  constructor(
    private catalogosService: CatalogosService,
    private usuariosService: UsuariosService,
    private retosService: RetosService,
    private router: Router,
    private fb: FormBuilder,
    private toastr: ToastrService
  ) {
    this.alcanceData$ = this.catalogosService.getAlcance();
    this.sectoresData$ = this.catalogosService.getSectoresR();
    this.industriasData$ = this.catalogosService.getIndustriasR();
    this.tamanioEmpresasData$ = this.catalogosService.getTamanoEmpresa();
    this.semanasData$ = this.catalogosService.getSemanas();
    this.idiomasData$ = this.catalogosService.getIdiomas();
    this.presenciaData$ = this.catalogosService.getPresencia();
    this.escuelaData$ = this.catalogosService.getEscuelas();
    this.periodoSemanalData$ = this.catalogosService.getPeriodoSemanal();
    this.periodoSemestralData$ = this.catalogosService.getPeriodoSemestral();
  }

  

  ngOnInit(): void {
    
    
    let retoMaestroini:cRetoMaestro = cRetoMaestro.retoMaestroFromJSon({});
    
    this.initializeForm(retoMaestroini);    
    
    const userStore = this.usuariosService.getCurrentUserData();
    if (!userStore) this.router.navigate(["/"]);

    this.checkUpdate();
  
  }

  initializeForm(dataReto: IRetoMaestro) {
    let semestres         :any=[];
    let alcances          :any=[];
    let sectores          :any=[]; 
    let presencias        :any=[]; 
    let tamanos           :any=[];
    let idiomas           :any=[];
    let periodosSemanales :any=[];
    let periodosSemestrales:any=[];
    let industrias        :any=[];
    
    if(dataReto.idUnidadFormativa){
      
      semestres           = dataReto?.semestres?.map(x => (Number(x.semestre)));
      alcances            = dataReto?.alcances?.map(x=>(x.idAlcance));
      sectores            = dataReto?.sectores?.map(x=>(x.idSector));
      presencias          = dataReto?.presencias?.map( x => (x.idPresencia));
      tamanos             = dataReto?.tamanos?.map(x =>  x.idTamano);   
      idiomas             = dataReto?.idiomas.map(x => x.idIdioma);
      periodosSemanales   = dataReto.periodosSemanales.map(x=>x.idPeriodoSemanal);
      periodosSemestrales = dataReto.periodosSemestrales.map(x=>x.idPeriodoSemestral);
      industrias          = dataReto.industrias.map(x=>x.idIndustria);

    }

    this.retoForm = this.fb.group({
      idEscuela:              [dataReto?.idEscuela ?? 0, ],
      idUnidadFormativa:      [dataReto?.idUnidadFormativa ?? 0,  ],
      idEscuelaPOE:           [],
      programasOtrasEscuelas: [dataReto?.programasAcademicosOtrasEscuelas],
      reto:                   [dataReto?.reto ?? ""],
      descripcion:            [dataReto?.descripcion ?? "", ],
      entregable:             [dataReto?.entregable ?? "", ],
      objetivo:               [dataReto?.objetivo ?? "", ],
      idSemanas:              [dataReto?.idSemanas ?? 0, ],
      idiomas:                [idiomas],
      periodosSemestrales:    [periodosSemestrales],
      periodosSemanales:      [periodosSemanales],
      semestres:              this.fb.array(semestres),
      alcances:               this.fb.array(alcances),
      sectores:               this.fb.array(sectores),
      industrias:             [industrias],
      tamanos:                [tamanos],
      presencias:             this.fb.array(presencias),
      descripcionSocioFormador: [dataReto?.palabrasClave ?? "", ],
      sentidoHumano:        [dataReto?.sentidoHumano],
      horasServicioSocial:   [dataReto?.horasServicioSocial ?? 0, Validators.pattern('^[0-9]*')],
    });
  }

  checkUpdate() {
    const retoStored: any = localStorage.getItem("newReto");
    this.retoToUpdate = JSON.parse(retoStored);
    
    
    let idReto = Number(sessionStorage.getItem("idRetoMaestro") ?? this.retoToUpdate.idRetoMaestro ?? 0);

    if (idReto != 0) {

      this.isUpdate= true;
      
      this.getRetoMaestro(idReto);
      
    }
    
  }

  actualizaValidaciones() {
     
    this.retoForm.get("idEscuela")?.updateValueAndValidity();
    this.retoForm.get("idUnidadFormativa")?.updateValueAndValidity();
    this.retoForm.get("reto")?.updateValueAndValidity();
    this.retoForm.get("descripcion")?.updateValueAndValidity();
    this.retoForm.get("entregable")?.updateValueAndValidity();
    this.retoForm.get("objetivo")?.updateValueAndValidity();

    this.retoForm.get("idSemanas")?.updateValueAndValidity();
    this.retoForm.get("idiomas")?.updateValueAndValidity();
    this.retoForm.get("periodosSemestrales")?.updateValueAndValidity();
    this.retoForm.get("periodosSemanales")?.updateValueAndValidity();
    this.retoForm.get("semestres")?.updateValueAndValidity();
    this.retoForm.get("alcances")?.updateValueAndValidity();
    this.retoForm.get("sectores")?.updateValueAndValidity();
    this.retoForm.get("industrias")?.updateValueAndValidity();
    this.retoForm.get("tamanos")?.updateValueAndValidity();
    this.retoForm.get("presencias")?.updateValueAndValidity();
    this.retoForm.get("descripcionSocioFormador")?.updateValueAndValidity();
   
  }

  onCheckboxChange(event: any, typeData: string) {
    const selectedAreas = this.retoForm.controls[typeData] as FormArray;
    if (event.target.checked) {
      selectedAreas.push(new FormControl(parseInt(event.target.value)));
    } else {
      const index = selectedAreas.controls.findIndex(
        (x) => x.value === event.target.value
      );
      selectedAreas.removeAt(index);
    }
  }  


  extractIds(data: FormArray, id: string) {
    if (data) {
      return Object.values(data).map((value: any) => value[id]);
    }
    return "";
  }

  createRetoSemilla(userStore: IUser) {
    let frmRetoLocal = this.retoForm.value;

    let nombreReto =`${this.unidadFormativaSeleccionada.clave}_${this.unidadFormativaSeleccionada.unidadFormativa}`;
    let programasPOE ;

    let retoMaestroId;
    let idiomas = frmRetoLocal.idiomas;
    let industrias = frmRetoLocal.industrias;
    let periodosSemanales = frmRetoLocal.periodosSemanales;
    let periodosSemestrales = frmRetoLocal.periodosSemestrales;
    let tamanos = frmRetoLocal.tamanos;
    frmRetoLocal.horasServicioSocial = frmRetoLocal.sentidoHumano ? frmRetoLocal.horasServicioSocial: 0;

    if(this.isUpdate)
    {
      retoMaestroId       = this.retoData.idRetoMaestro;
      idiomas             = frmRetoLocal.idiomas;
      industrias          = frmRetoLocal.industrias;
      periodosSemanales   = frmRetoLocal.periodosSemanales;
      periodosSemestrales = frmRetoLocal.periodosSemestrales;
      tamanos             = frmRetoLocal.tamanos;
    }
    return {
      idRetoMaestro:    retoMaestroId,
      idUsuarioTec:     userStore.idUsuario,
      idEscuela:        frmRetoLocal.idEscuela,
      idUnidadFormativa: frmRetoLocal.idUnidadFormativa,
      reto:             nombreReto,
      descripcion:      frmRetoLocal.descripcion,
      entregable:       frmRetoLocal.entregable,
      objetivo:         frmRetoLocal.objetivo,
      programasOtrasEscuelas: frmRetoLocal.programasOtrasEscuelas,
      idSemanas:        frmRetoLocal.idSemanas,      
      idiomas:          idiomas,
      periodosSemestrales: periodosSemestrales,
      periodosSemanales: periodosSemanales,
      semestres:        frmRetoLocal.semestres,
      alcances:         frmRetoLocal.alcances,
      sectores:         frmRetoLocal.sectores,
      industrias:       industrias,
      tamanos:          tamanos,
      presencias:       frmRetoLocal.presencias,
      palabrasClave:    frmRetoLocal.descripcionSocioFormador,
      sentidoHumano:    frmRetoLocal.sentidoHumano,
      horasServicioSocial: frmRetoLocal.horasServicioSocial,
      
    };
  }
    
  saveReto() {
    const userStore = this.usuariosService.getCurrentUserData();
    let body = {};
    
    body = this.createRetoSemilla(userStore);
        
    if (this.retoForm.valid) {
      if (!this.isUpdate) {
        this.retosService.postRetosMaestro(body).subscribe({
          next: (resultado: any) => {
            if (resultado) {
              localStorage.setItem("newReto", JSON.stringify(resultado));
              this.toastr.success("Registrada Correctamente!");
              this.retoData.idRetoMaestro = resultado.idRetoMaestro;

              this.isUpdate=true;

              
            } else {
              this.toastr.error("Algo salió mal!");
            }
          },
          error: (error) => {
            Swal.fire({
              icon: "error",
              text: "Error en la solicitud, intente más tarde",
            });
          }
        });
      } else {
        this.retosService.putRetoMaestro(body).subscribe({
          next: (resultado: any) => {
            if (resultado) {
              localStorage.setItem("newReto", JSON.stringify(resultado));
              this.toastr.success("Registrada Correctamente!");
              
            } else {
              this.toastr.error("Algo salió mal!");
            }
          },
          error: (error) => {
            Swal.fire({
              icon: "error",
              text: "Error en la solicitud, intente más tarde.",
            });
          }
        });
      }
    } else {
      Swal.fire({
        icon: "error",
        text: "Todos los campos son obligatorios",
      });
      this.toastr.error("Todos los campos son obligatorios!");
      
    }
  }

  debugCamposValidar() {
    Object.keys(this.retoForm.controls).forEach((key) => {
      const controlErrors: any = this.retoForm?.get(key)?.errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach((keyError) => {
          console.log(
            "Key control: " + key + ", keyError: " + keyError + ", err value: ",
            controlErrors[keyError]
          );
        });
      }
    });
  }

  clickToCheck(typeCheck: string) {
    return true;
  }

  getRetosMaestros(idEscuela: number) {
    this.retosService
      .getRetosMaestros(idEscuela)
      .pipe(
        tap((resultado: IRetoMaestro[]) => (this.retosMaestrosData = resultado))
      )
      .subscribe();
  }

  getProgramasAcademicos(idEscuela: number) {
    this.catalogosService
      .getProgramasAcademicos(idEscuela)
      .pipe(
        tap(
          (resultado: cProgramaAcademico[]) =>
            (this.programaAcademicoData = resultado)
        )
      )
      .subscribe();
  }

  updateProgramasAcademicos1(event) {
    this.programaAcademicoData1 = [];
    this.getUnidadFormativa(event.idEscuela);
  }

  updateProgramasAcademicos(event) {
    this.programaAcademicoData = [];
    this.getProgramasAcademicos(event.idEscuela);
  }

  getRetoMaestro(idRetoMaestro: any) {
    this.retosService
      .getRetoMaestroDetail(idRetoMaestro)
      .pipe(tap((response) => {

          this.retoData = response;

          this.mostrarHorasSS = (response.sentidoHumano );
        
          this.initializeForm(response);
        
          this.updateTitle();

          // Se actualiza la unidad formativa
          this.getUnidadFormativa(response.idEscuela);          
          
        }))
      .subscribe();
  }

  getUnidadFormativa(idEscuela: number) {
    this.unidadFormativaData = [];  
    
    this.catalogosService.getUnidadFormativa(idEscuela).subscribe({
      next: (resultado: CUnidadFormativa[]) => {
        this.unidadFormativaData = resultado;
        
        if(this.isUpdate)
        {
          if(!this.retoData) return;
          this.unidadFormativaSeleccionada = <IUnidadFormativa>this.unidadFormativaData.find(x => x.idUnidadFormativa == this.retoData.idUnidadFormativa);

          this.retoForm.get('idUnidadFormativa')?.patchValue( this.retoData.idUnidadFormativa );
          this.actualizaValidaciones();
          
          this.muestraProgamasAcademico({idUnidadFormativa: this.retoData.idUnidadFormativa }); 
          
        }
      },
      error: (error) => {
        console.log(error);
      }
    });
  }


  verifySelected(id: string | number, inputForm: string) {
    return this.retoForm.get(inputForm)?.value === id ? true : null;
  }

  verifyChecked(id: string | number, inputForm: string) {
    const areas = this.retoForm.get(inputForm)?.value;
    if (typeof areas === "object" && areas) {
      return areas.includes(id);
    } else {
      return areas === id;
    }
  }

  cleanText(textToClean: string) {
    return textToClean
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
  }

  getRetoDetail(idRetoMaestro) {
    this.retoMaestro=null;
    if (!idRetoMaestro) return;

    idRetoMaestro &&
      this.retosService
        .getRetoMaestroDetail(idRetoMaestro)
        .pipe(
          tap((resultado: IRetoMaestro) => {
            this.initializeForm(resultado);
            this.retoMaestro = resultado;
          })
        )
        .subscribe();
  }

  updateTitle() {
    if (this.retoForm.value.semilla) {

      this.titleRegistroReto = { title: `${this.isUpdate ? "Actualizar" : " Crear"} un reto`, };
      document.getElementById("idEscuela")?.focus();

    } else {
      
      this.titleRegistroReto = { title: `${this.isUpdate ? "Actualizar" : "Crear"} un reto`,  };
      document.getElementById("idEscuela")?.focus();

    }

    this.actualizaValidaciones();
  }

  mostrarProgramasAcademicosExtra() {
    this.mostrarPOE = !this.mostrarPOE;
  }

  muestraProgamasAcademico($event) {
  

    this.unidadFormativaSeleccionada = $event;
    this.mostrarPA = false;
    if ($event.idUnidadFormativa != "Selecciona una opción") {
      this.mostrarPA = true;
    }

    this.isUpdate= true;
   
    this.getProgramasAcademicosxUnidadFormativa($event.idUnidadFormativa);
    
  }

  
  muestraProgamasAcademico_nvo($event) {
    this.unidadFormativaSeleccionada = $event;
    this.retosService.getRetosMaestrosxUnidadFormativa_original($event.idUnidadFormativa).subscribe({
      next: (resOK)=> {
        this.retosService
        .getRetoMaestroDetail(resOK[0].idRetoMaestro)
        .pipe(tap((response) => {
            this.retoData = response;
            this.initializeForm(response);

            this.isUpdate= true;            
            this.updateTitle();      
            
          }))
        .subscribe();
      },
      error: (error)=>{
        this.resetForm();
        console.log(error);
        this.isUpdate= false;
        this.updateTitle();
      }
    });
  }
      
  resetForm(){
    this.retoForm.get('idSemanas')?.reset();
    this.retoForm.get('idiomas')?.reset();
    this.retoForm.get('periodosSemestrales')?.reset();
    this.retoForm.get('periodosSemanales')?.reset();
    this.retoForm.get('semestres')?.reset();
    this.retoForm.get('alcances')?.reset();
    this.retoForm.get('descripcion')?.reset();
    this.retoForm.get('entregable')?.reset();
    this.retoForm.get('objetivo')?.reset();
    this.retoForm.get('sectores')?.reset();
    this.retoForm.get('industrias')?.reset();
    this.retoForm.get('tamanos')?.reset();
    this.retoForm.get('presencias')?.reset();
    this.retoForm.get('descripcionSocioFormador')?.reset();
    


    
  }
  
  cancelar() {
    sessionStorage.removeItem("idRetoMaestro");
    localStorage.removeItem("newReto");
    this.retoForm.reset();
    this.isUpdate = false;
    this.updateTitle();
  }

  getProgramasAcademicosxUnidadFormativa(idUnidadFormativa: number) {
    
    this.catalogosService
      .getProgramasAcademicosxUnidadFormativa(idUnidadFormativa)
      .subscribe({
        next: (resOK) => {
          this.listaProgramasAcademicosxUnidadFormativa = resOK;
        },
        error: (error) => {
          console.log(error);
        }
      });
  }
  
  mostrarHorasServicioSocial(event){
   this.mostrarHorasSS = event; 
  }

}

