import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IAcademiaSolicitudesSFResponse } from "@shared/Interface/IAcademiaSolicitudesSFResponse";
import { IAcademiaVinculacionRetosResponse } from "@shared/Interface/IAcademiaVinculacionRetosResponse";
import { ICVDPSolicitudesRetosResponse } from "@shared/Interface/ICVDPSolicitudesRetoResponse";
import { IdashboardUsuario } from "@shared/Interface/IdashboardUsuario";
import { IProblematicasResponse } from "@shared/Interface/IProblematicasResponse";
import { IRetosAcademicosResponse } from "@shared/Interface/IRetosAcademicosResponse";
import { RetosSocioFormadorResponse } from "@shared/Interface/IRetosSocioFormadorResponse";
import { IsocioIndicador } from "@shared/Interface/IsocioIndicador";
import { IVinculacionesResponse } from "@shared/Interface/IsocioVinculacion";
import { ISolicitudesAcademicosResponse } from "@shared/Interface/ISolicitudesAcademicosResponse";
import { ISolicitudesSinRetoResponse } from "@shared/Interface/ISolicitudesSinRetoResponse";
import { ISolicitudesSociosResponse } from "@shared/Interface/ISolicitudesSociosResponse";
import { IVinculacionesSFResponse } from "@shared/Interface/IVinculacionesSFResponse";
import { environment } from "environments/environment";
import { Observable } from "rxjs";
import { baseUrl, moduleService, subModuleService } from "../routesServices";
import { IRetosCVDPResponse } from "@shared/Interface/IRetosCVDPResponse";


const { DASHBOARD, PROBLEMATICAS, SOCIOS, RETOS } = moduleService;
const {
  CVDP,
  ACADEMICOS,
  VINCULACIONES,
  SOLICITUDES_SOCIOS,
  INDICADORES,
  SOLICITUDES_RETOS,
  SOLICITUDES_ACADEMICOS,
  SOLICITUDES_SIN_RETO,
  SOLICITUDES_SOCIO_FORMADOR,
} = subModuleService;

@Injectable({
  providedIn: "root",
})
export class DashboardService {
  baseUrl = environment.apiUrl;
  constructor(private http: HttpClient) {}

  getVinculacionesStatus(idUsuario: number, status: string) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("status", status);

    return this.http.get<IdashboardUsuario>(
      `${baseUrl}${SOCIOS}/${idUsuario}`,
      {
        params: queryParams,
      }
    );
  }

  //TODO: quitar los valores opcionales
  getVinculacionesSocio(idSocio: number, status: string, pagina?: number, filasxpagina?: number, qry?: string ) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("estatus", status);
    queryParams = queryParams.append("pagina", pagina ?? 0);
    queryParams = queryParams.append("filasxpagina", filasxpagina ?? 0);
    queryParams = queryParams.append("query", qry ?? '');
    return this.http.get<IVinculacionesResponse[]>(
      `${baseUrl}${DASHBOARD}/${SOCIOS}/${idSocio}/${VINCULACIONES}`,
      {
        params: queryParams,
      }
    );
  }

  getSolicitudesSocio(idUsuario: number, status: string, idCampus: number, idEscuela: number, idPeriodo: number, pagina?: number, hits?:number ) {
    let queryParams = new HttpParams();

    queryParams = queryParams.append("estatus", status);
    queryParams = queryParams.append("idCampus", idCampus);
    queryParams = queryParams.append("idEscuela", idEscuela ?? 0);
    queryParams = queryParams.append("idPeriodo", idPeriodo ?? 0);
    queryParams = queryParams.append("pagina", pagina ?? 1);
    queryParams = queryParams.append("filasxpagina", hits ?? environment.RegistrosPagina);

    return this.http.get<IVinculacionesResponse[]>(
      `${baseUrl}${DASHBOARD}/${CVDP}/${idUsuario}/${SOLICITUDES_SOCIOS}`,
      {
        params: queryParams,
      }
    );
  }

  getSolicitudesRetoSocio(idSocio: number, status: string, idCampus: number, idEscuela: number, idPeriodo: number, pagina?: number, hits?:number ) {
    let queryParams = new HttpParams();

    queryParams = queryParams.append("estatus", status);
    queryParams = queryParams.append("idCampus", idCampus);
    queryParams = queryParams.append("idEscuela", idEscuela ?? 0);
    queryParams = queryParams.append("idPeriodo", idPeriodo ?? 0);
    queryParams = queryParams.append("pagina", pagina ?? 1);
    queryParams = queryParams.append("filasxpagina", hits ?? environment.RegistrosPagina);

    return this.http.get<IVinculacionesResponse[]>(
      `${baseUrl}${DASHBOARD}/${CVDP}/${idSocio}/${SOLICITUDES_RETOS}`,
      {
        params: queryParams,
      }
    );
  }


  getSolicitudesAcademicos(idSocio: number, status: string, idCampus: number, idEscuela: number, idPeriodo: number, pagina?: number, hits?:number ) {
    let queryParams = new HttpParams();

    queryParams = queryParams.append("estatus", status);
    queryParams = queryParams.append("idCampus", idCampus);
    queryParams = queryParams.append("idEscuela", idEscuela ?? 0);
    queryParams = queryParams.append("idPeriodo", idPeriodo ?? 0);
    queryParams = queryParams.append("pagina", pagina ?? 1);
    queryParams = queryParams.append("filasxpagina", hits ?? environment.RegistrosPagina);
    return this.http.get<IVinculacionesResponse[]>(
      `${baseUrl}${DASHBOARD}/${CVDP}/${idSocio}/${SOLICITUDES_ACADEMICOS}`,
      {
        params: queryParams,
      }
    );
  }

//TODO: Revisar parametros
  getSolicitudesAcademicosxSocio(idSocio: number, status: string, idCampus: number, idEscuela: number, idPeriodo: number, pagina?: number, hits?:number, query?: string ) {
    let queryParams = new HttpParams();

    queryParams = queryParams.append("estatus", status);
    queryParams = queryParams.append("idCampus", idCampus);
    queryParams = queryParams.append("idEscuela", idEscuela ?? 0);
    queryParams = queryParams.append("idPeriodo", idPeriodo ?? 0);
    queryParams = queryParams.append("pagina", pagina ?? 1);
    queryParams = queryParams.append("filasxpagina", hits ?? environment.RegistrosPagina);
    queryParams = queryParams.append("query", query ?? '');
    return this.http.get<IVinculacionesResponse[]>(
      `${baseUrl}${DASHBOARD}/${SOCIOS}/${idSocio}/${SOLICITUDES_ACADEMICOS}`,
      {
        params: queryParams,
      }
    );
  }

  getSolicitudesSinRetos(idSocio: number, status: string, idCampus: number, idEscuela: number, idPeriodo: number, pagina?: number, hits?:number ) {
    let queryParams = new HttpParams();

    queryParams = queryParams.append("estatus", status);
    queryParams = queryParams.append("idCampus", idCampus);
    queryParams = queryParams.append("idEscuela", idEscuela ?? 0);
    queryParams = queryParams.append("idPeriodo", idPeriodo ?? 0);
    queryParams = queryParams.append("pagina", pagina ?? 1);
    queryParams = queryParams.append("filasxpagina", hits ?? environment.RegistrosPagina);

    return this.http.get<IVinculacionesResponse[]>(
      `${baseUrl}${DASHBOARD}/${CVDP}/${idSocio}/${SOLICITUDES_SIN_RETO}`,
      {
        params: queryParams,
      }
    );
  }

  // TODO: agregar parametros
  getProblematicasSocio(idSocio: number, status: string, pagina?: number, filasxpagina?: number,  query?: string) {
    let queryParams = new HttpParams();

    queryParams = queryParams.append("estatus", status);
    queryParams = queryParams.append("pagina", pagina ?? 0);
    queryParams = queryParams.append("filasxpagina", filasxpagina ?? 0);
    queryParams = queryParams.append("query", query ?? '');

    return this.http.get<IProblematicasResponse[]>(
      `${baseUrl}${DASHBOARD}/${SOCIOS}/${idSocio}/${PROBLEMATICAS}`,
      {
        params: queryParams,
      }
    );
  }

  // TODO: Agregar parametros
  getRetosSocio(idSocio: number, status: string, hits?: number, filasxpagina?: number,  query?: string) {
    let queryParams = new HttpParams();
    hits = hits ?? 0;
    queryParams = queryParams.append("estatus", status);
    queryParams = queryParams.append("pagina", hits ?? 0);
    queryParams = queryParams.append("filasxpagina", filasxpagina ?? 0);
    queryParams = queryParams.append("query", query ?? '');
    return this.http
      .get<IVinculacionesResponse[]>(
        `${baseUrl}${DASHBOARD}/${SOCIOS}/${idSocio}/${RETOS}`,
        {
          params: queryParams,
        }
      );

  }

  /*
  getVinculacionesAcademicos(idSocio: number, status: string) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("estatus", status);
    return this.http.get<IVinculacionesResponse[]>(
      `${baseUrl}${DASHBOARD}/${ACADEMICOS}/${idSocio}/${VINCULACIONES}`,
      {
        params: queryParams,
      }
    );
  }*/

  /*
  getSolicitudesSocioAcademicos(idSocio: number, status: string) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("estatus", status);
    return this.http.get<IVinculacionesResponse[]>(
      `${baseUrl}${DASHBOARD}/${ACADEMICOS}/${idSocio}/${SOLICITUDES_SOCIO_FORMADOR}`,
      {
        params: queryParams,
      }
    );
  }*/

  getRetosAcademicos(idSocio: number, status: string,
    idCampus: number,
    idEscuela: number,
    idPeriodo: number,
    pagina?: number,
    hits?: number,
    query? : string,
    idPeriodoSemanal? :number,
    idPeriodoSemestral? :number) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("estatus", status);
    queryParams = queryParams.append("idCampus", idCampus);
    queryParams = queryParams.append("idEscuela", idEscuela ?? 0);
    queryParams = queryParams.append("idPeriodo", idPeriodo ?? 0);
    queryParams = queryParams.append("pagina", pagina ?? 1);
    queryParams = queryParams.append("idPeriodoSemanal", idPeriodoSemanal ?? 0);
    queryParams = queryParams.append("filasxpagina", hits ?? environment.RegistrosPagina);
    queryParams = queryParams.append("query", query ?? '');
    queryParams = queryParams.append("idPeriodoSemestral", idPeriodoSemestral ?? 0);

    return this.http.get<IVinculacionesResponse[]>(
      `${baseUrl}${DASHBOARD}/${ACADEMICOS}/${idSocio}/${RETOS}`,
      {
        params: queryParams,
      }
    );
  }

  getindSocioIcadores(idSocio: number, type: string) {
    return this.http.get<IsocioIndicador>(
      `${baseUrl}${DASHBOARD}/${type}/${idSocio}/${INDICADORES}`
    );
  }

  /**
   * Api que obtiene los retos por un socio formador y estatus
   * @param idSocioFormador Id del socio formador
   * @param estatus estatus
   * @returns lista de objetos tipo RetosSocioFormadorResponse
   */
  // getRetosBySocioFormadorAndEstatus(
  //   idSocioFormador: any,
  //   estatus: any
  // ): Observable<RetosSocioFormadorResponse[]> {
  //   return this.http.get<RetosSocioFormadorResponse[]>(
  //     this.baseUrl +
  //       "dashboard/socios/" +
  //       idSocioFormador +
  //       "/retos?estatus=" +
  //       estatus +
  //       "&hits=0"
  //   );
  // }

  /**
   * Api que obtiene los retos por un socio formador
   * @param idSocioFormador Id del socio formador
   * @returns lista de objetos tipo RetosSocioFormadorResponse
   */
  getRetosBySocioFormador(
    idSocioFormador: any
  ): Observable<RetosSocioFormadorResponse[]> {
    return this.http.get<RetosSocioFormadorResponse[]>(
      this.baseUrl + "dashboard/socios/" + idSocioFormador + "/retos"
    );
  }

  /**Api que obtiene la lista de vinculaciones por estatus y relacionadas por el idSocioFormador
   *
   * @param idSocioFormador Id del socio formador
   * @param estatus estatus de vinculacion
   * @returns lista de objetos tipo IVinculacionesResponse
   */
  // AJUSTAR EL PARAMETRO DE ESTATUS CUANDO SE CAMBIE EL CONSUMO DE LA API
  // getVinculacionesBySocioFormadorAndEstatus(
  //   idSocioFormador: any,
  //   estatus: any
  // ): Observable<IVinculacionesResponse[]> {
  //   return this.http.get<IVinculacionesResponse[]>(
  //     this.baseUrl +
  //       "dashboard/socios/" +
  //       idSocioFormador +
  //       "/vinculaciones?estatus=" +
  //       estatus
  //   );
  // }

  /**Api que obtiene la lista de problematicas por estatus y relacionadas por el idSocioFormador
   *
   * @param idSocioFormador Id del socio formador
   * @param estatus estatus de problematica
   * @returns lista de objetos tipo IProblematicasResponse
   */
  // getProblematicasBySocioFormadorAndEstatus(
  //   idSocioFormador: any,
  //   estatus: any
  // ): Observable<IProblematicasResponse[]> {
  //   return this.http.get<IProblematicasResponse[]>(
  //     this.baseUrl +
  //       "dashboard/socios/" +
  //       idSocioFormador +
  //       "/problematicas?estatus=" +
  //       estatus
  //   );
  // }

  /**Api que obtiene la lista de academicos que buscan socio formador por estatus y relacionadas por el id de CVDP
   *
   * @param idCVDP Id del CVDP
   * @param estatus estatus solicitud
   * @returns lista de objetos tipo ISolicitudesAcademicosResponse
   */
  getSolicitudesAcademicosByCVDPAndEstatus(
    idUsuario: number,
    estatus: string,
    idCampus: number,
    idEscuela: number,
    idPeriodo: number,
    pagina: number,
    hits: number
  ): Observable<ISolicitudesAcademicosResponse[]> {

    let queryParams = new HttpParams();

    queryParams = queryParams.append("estatus", estatus);
    queryParams = queryParams.append("idCampus", idCampus);
    queryParams = queryParams.append("idEscuela", idEscuela ?? 0);
    queryParams = queryParams.append("idPeriodo", idPeriodo ?? 0);
    queryParams = queryParams.append("pagina", pagina ?? 1);
    queryParams = queryParams.append("filasxpagina", hits ?? environment.RegistrosPagina);

    return this.http.get<ISolicitudesAcademicosResponse[]>(
      `${this.baseUrl}/${DASHBOARD}/${CVDP}/${idUsuario}/solicitudesAcademicos`,{ params: queryParams });

      // this.baseUrl +
      //   "dashboard/cvdps/" +
      //   idUsuario +
      //   "/solicitudesAcademicos?estatus=" +
      //   estatus

  }

  /**Api que obtiene la lista de solcitudes que no encontraron un reto por estatus y relacionadas por el id de CVDP
   *
   * @param idCVDP Id del CVDP
   * @param estatus estatus solicitud
   * @returns lista de objetos tipo ISolicitudesSinRetoResponse
   */
  getSolicitudesSinRetoByCVDPAndEstatus(
    idUsuario: number,
    estatus: string,
    idCampus: number,
    idEscuela: number,
    idPeriodo: number,
    pagina: number,
    hits: number
  ): Observable<ISolicitudesSinRetoResponse[]> {

    let queryParams = new HttpParams();

    queryParams = queryParams.append("estatus", estatus);
    queryParams = queryParams.append("idCampus", idCampus);
    queryParams = queryParams.append("idEscuela", idEscuela ?? 0);
    queryParams = queryParams.append("idPeriodo", idPeriodo ?? 0);
    queryParams = queryParams.append("pagina", pagina ?? 1);
    queryParams = queryParams.append("filasxpagina", hits ?? environment.RegistrosPagina);

    return this.http.get<ISolicitudesSinRetoResponse[]>(`${this.baseUrl}${DASHBOARD}/${CVDP}/${idUsuario}/solicitudesSinReto`,{ params: queryParams});
    //   this.baseUrl +
    //     "dashboard/cvdps/" +
    //     idUsuario +
    //     "/solicitudesSinReto?estatus=" +
    //     estatus
    // );
  }

  /**Api que obtiene la lista de solicitudes de socios formadores que quieren vincularse a un reto por estatus y relacionadas por el id de academico
   *
   * @param idAcademico Id de academico
   * @param estatus estatus de solicitud
   * @returns lista de objetos tipo IVinculacionesSFResponse
   */
  getVinculacionesByAcademicoAndEstatus(
    idAcademico: any,
    estatus: any
  ): Observable<IVinculacionesSFResponse[]> {
    return this.http.get<IVinculacionesSFResponse[]>(
      this.baseUrl +
        "dashboard/academicos/" +
        idAcademico +
        "/vinculaciones?estatus=" +
        estatus
    );
  }

  /**
   * Api que obtiene las solicitudes a retos por id CVDP y estatus
   * @param idCVDP Id de CVDP
   * @param estatus estatus
   * @returns lista de objetos tipo ICVDPSolicitudesRetosResponse
   */
  getCVDPSolicitudRetoByEstatus(
    idUsuario: number,
    estatus: string,
    idCampus: number,
    idEscuela: number,
    idPeriodo: number,
    pagina?: number,
    hits?: number
  ): Observable<ICVDPSolicitudesRetosResponse[]> {

    let queryParams = new HttpParams();

    queryParams = queryParams.append("estatus", estatus);
    queryParams = queryParams.append("idCampus", idCampus);
    queryParams = queryParams.append("idEscuela", idEscuela ?? 0);
    queryParams = queryParams.append("idPeriodo", idPeriodo ?? 0);
    queryParams = queryParams.append("pagina", pagina ?? 1);
    queryParams = queryParams.append("filasxpagina", hits ?? environment.RegistrosPagina);

    return this.http.get<ICVDPSolicitudesRetosResponse[]>(
      `${this.baseUrl}${DASHBOARD}/${CVDP}/${idUsuario}/solicitudesRetos`,{ params: queryParams });

  }

  /**Api que obtiene la lista de socios que buscan socio formador por estatus y relacionadas por el id de CVDP
   *
   * @param idCVDP Id del CVDP
   * @param estatus estatus solicitud
   * @returns lista de objetos tipo ISolicitudesSociosResponse
   */
  getSolicitudesSociosByCVDPAndEstatus(
    idUsuario: any,
    estatus: any,
    idCampus: number, idEscuela: number, idPeriodo: number, pagina?: number, hits?:number
  ): Observable<ISolicitudesSociosResponse[]> {

    let queryParams = new HttpParams();

    queryParams = queryParams.append("estatus", estatus);
    queryParams = queryParams.append("idCampus", idCampus);
    queryParams = queryParams.append("idEscuela", idEscuela ?? 0);
    queryParams = queryParams.append("idPeriodo", idPeriodo ?? 0);
    queryParams = queryParams.append("pagina", pagina ?? 1);
    queryParams = queryParams.append("filasxpagina", hits ?? environment.RegistrosPagina);

    return this.http.get<ISolicitudesSociosResponse[]>(
      `${this.baseUrl}${DASHBOARD}/${CVDP}/${idUsuario}/solicitudesSocios`,{ params: queryParams });
  }

  /**
   *  Api que obtiene la lista de socios formadores que se vinculan a un reto
   * @param idAcademico Tipo de dato number que que identifica al academico
   * @param estatus Tipo de dato string que identifica al estatus
   * @returns Lista de objetos tipo IAcademiaVinculacionRetosResponse
   */
  getAcademiaVinculaciones(
    idAcademico: any,
    estatus: any,
    periodoSemestral: any = 0,
    periodoSemanal: any = 0,
    departamento: any = 0,
    query:any = ''
  ): Observable<IAcademiaVinculacionRetosResponse[]> {
    type NewType = IAcademiaVinculacionRetosResponse;

    let queryParams = new HttpParams();
    queryParams = queryParams.append("estatus", estatus);
    queryParams = queryParams.append("periodoSemestral", periodoSemestral);
    queryParams = queryParams.append("periodoSemanal", periodoSemanal);
    queryParams = queryParams.append("departamento", departamento);
    queryParams = queryParams.append("query", query);

    return this.http.get<NewType[]>(
      `${this.baseUrl}${DASHBOARD}/${ACADEMICOS}/${idAcademico}/${VINCULACIONES}`,
      {
        params: queryParams
      });

  }

  /**
   *  Api que obtiene la lista de solicitudes de socios formadores a un reto
   * @param idAcademico Tipo de dato number que que identifica al academico
   * @param estatus Tipo de dato string que identifica al estatus
   * @returns Lista de objetos tipo IAcademiaSolicitudesSFResponse
   */
  getAcademiaSolicitudesSF(
    idAcademico: any,
    estatus: any,
    periodoSemestral: any = 0,
    periodoSemanal: any = 0,
    departamento: any = 0,
    query:any = ''
  ): Observable<IAcademiaSolicitudesSFResponse[]> {

    let queryParams = new HttpParams();
    queryParams = queryParams.append("estatus", estatus);
    queryParams = queryParams.append("periodoSemestral", periodoSemestral);
    queryParams = queryParams.append("periodoSemanal", periodoSemanal);
    queryParams = queryParams.append("departamento", departamento);
    queryParams = queryParams.append("query", query);


  return this.http.get<IAcademiaSolicitudesSFResponse[]>(
      `${this.baseUrl}${DASHBOARD}/${ACADEMICOS}/${idAcademico}/${SOLICITUDES_SOCIO_FORMADOR}`,
      {
        params: queryParams
      }
    );
  }

  /**
   * Api que obtiene los retos por académico y estatus
   * @param idAcademico Id del usuario académico
   * @param estatus estatus
   * @returns lista de objetos tipo IRetosAcademicosResponse
   */
  getRetosByAcademicoAndEstatus(
    idAcademico: any,
    estatus: any,
    idCampus: number, idEscuela: number, idPeriodo: number, pagina?: number, hits?:number,
    idPeriodoSemanal?: number
  ): Observable<IRetosAcademicosResponse[]> {

    let queryParams = new HttpParams();

    queryParams = queryParams.append("estatus", estatus);
    queryParams = queryParams.append("idCampus", idCampus);
    queryParams = queryParams.append("idEscuela", idEscuela ?? 0);
    queryParams = queryParams.append("idPeriodo", idPeriodo ?? 0);
    queryParams = queryParams.append("idPeriodoSemanal", idPeriodoSemanal ?? 0);
    queryParams = queryParams.append("pagina", pagina ?? 1);
    queryParams = queryParams.append("filasxpagina", hits ?? environment.RegistrosPagina);

    return this.http.get<IRetosAcademicosResponse[]>(
      `${this.baseUrl}${DASHBOARD}/${ACADEMICOS}/${idAcademico}/${RETOS}`, {params: queryParams }
    );
  }

  getListadoRetosByCDVP(idCvdp:number, estatus:string, idCampus: number, idEscuela: number, idPeriodo: number, pagina?: number, hits?:number, query?:string, idPeriodoSemanal? :number, idPeriodoSemestral? :number):Observable<IRetosCVDPResponse[]>{
    let queryParams = new HttpParams();

    queryParams = queryParams.append("estatus", estatus);
    queryParams = queryParams.append("idCampus", idCampus);
    queryParams = queryParams.append("idEscuela", idEscuela ?? 0);
    queryParams = queryParams.append("idPeriodo", idPeriodo ?? 0);
    queryParams = queryParams.append("pagina", pagina ?? 1);
    queryParams = queryParams.append("idPeriodoSemestral", idPeriodoSemestral ?? 0);
    queryParams = queryParams.append("filasxpagina", hits ?? environment.RegistrosPagina);
    queryParams = queryParams.append("query", query ?? '');
    queryParams = queryParams.append("idPeriodoSemanal", idPeriodoSemanal ?? 0);

    return this.http.get<IRetosCVDPResponse[]>(`${this.baseUrl}${DASHBOARD}/${CVDP}/${idCvdp}/${RETOS}`,
    {
      params: queryParams,
    }
    );

  }

}
