<div class="row frm_registro_sf">

    <div class="col-12">
        <!-- Titulo sección -->
        <div class="frm_titulo_seccion my-sm-4" *ngIf="vBuscaRfc || vExisteRFC">Datos de la organización</div>

        <div>
            <!-- RFC -->
            <div class="row" *ngIf="vBuscaRfc">

                <div class="d-flex justify-content-center">
                    <div class="form-group form-group mt-sm-3">
                        <label for="rfc_empresa" class="form-label col-12">RFC/No de tributación IRS <span
                                class="requerido">*</span></label>
                        <input type="text" class="form-control" id="rfc_empresa" name="rfc_empresa" maxLength="13"
                            [formControl]="rfc_empresa" placeholder="Ingresa tu RFC" (keypress)="validaRfcInput($event)"
                            (blur)="toUpperCaseRFC()">
                        <div class="col" *ngIf="procesandoBusqueda"><em>Buscando información ...</em>
                        </div>
                        <div *ngIf="rfc_empresa.touched && rfc_empresa.errors?.['required']" class="error">
                            El campo es requerido, sólo letras mayúsculas y números
                        </div>
                        <div *ngIf="rfc_empresa.touched && rfc_empresa.errors?.['minlength']" class="error">
                            Mínimo {{ rfc_empresa.errors?.['minlength']?.requiredLength }}
                            letras
                        </div>
                        <div *ngIf="showRFCerror" class="error">
                          Escribe únicamente letras y números, sin espacios ni caracteres especiales.
                        </div>
                    </div>
                </div>

            </div>

            <!-- RFC botonera -->
            <div class="col-12 mt-3 text-center" *ngIf="vBuscaRfc">
                <button class="btn-amarillo mx-sm-3 mt-3 mw-220" type="button" id="btn_validar_rfc_empresa"
                    (click)="buscarRFC($event)">Validar</button>
                <button class="btn-blue  mx-sm-3 mt-3 mw-220" type="button" (click)="RegresarHome()">Regresar</button>
            </div>
        </div>

        <!-- Existe RFC -->
        <div class="col-12 mt-3" *ngIf="vExisteRFC">
            <div class="d-flex justify-content-center">
                <!-- Si la empresa existe aparece este texto -->
                <div class="mb-3">
                    <span>Empresa con RFC/No. de tributación IRS: {{ this.rfc_empresa.value}} / {{ this.razonSocial ||
                        this.nombreSocioFormador }}</span>
                </div>
            </div>
            <div class="d-flex justify-content-center">
                <div class="titulo">
                    <p>
                        Ya existe un Socio Formador registrado con este RFC. Si tienes alguna duda envía un correo a
                        <a href="mailto: sociosformadores@servicios.tec.mx">sociosformadores@servicios.tec.mx</a>
                    </p>
                    <p>
                        Si quieres sumarte como contacto para este Socio Formador, por favor, completa el formulario
                        y una vez enviado revisaremos tu solicitud.
                    </p>

                </div>
            </div>
            <div class="col-12 mt-3 text-center">
                <button class="btn-amarillo mx-sm-3 mt-3 order-sm-1" type="button"
                    (click)="mostrarFormularioContacto()">Completar formulario</button>
                <button class="btn-brown  mx-sm-3 mt-3 order-sm-0" (click)="regresarBuscarRFC()"
                    type="button">Cancelar</button>

            </div>
        </div>

    </div>

    <!-- Registro de Organización -->
    <form (ngSubmit)="onSubmitOrganizacion()" [formGroup]="formularioForm">
        <!-- Datos de la organización -->
        <div class="row mt-3" *ngIf="vFrmOrganizacion">
            <!-- Columna 1 -->
            <div class="col-12 col-sm-6">
                <!-- Nombre de la Organización -->
                <div class="row">
                    <div class="d-flex justify-content-center">
                        <div class="form-group">
                            <label for="nombre_empresa" class="form-label">Nombre de la Organización <span
                                    class="requerido">*</span></label>
                            <input type="text" class="form-control" id="nombre_empresa" name="nombre_empresa"
                                formControlName="nombre_empresa" placeholder="¿Cómo se llama la Organización?">
                            <div *ngIf="formularioForm.get('nombre_empresa')?.touched && formularioForm.get('nombre_empresa')?.errors?.['required']"
                                class="error">
                                El campo es requerido
                            </div>
                            <div *ngIf="formularioForm.get('nombre_empresa')?.touched && formularioForm.get('nombre_empresa')?.errors?.['minlength']"
                                class="error">
                                Mínimo {{
                                formularioForm.get('nombre_empresa')?.errors?.['minlength']?.requiredLength }}
                                letras
                            </div>
                            <div class="col-9 mt-4 ml-1" *ngIf="mensajeRFC.length>0" innerHTML="{{ mensajeRFC }}">
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Razón social -->
                <div class="row mt-3 " *ngIf="!existeRFC">
                    <div class="d-flex justify-content-center">
                        <div class="form-group">
                            <label for="grupo_empresa" class="form-label">Razón Social <span class="requerido">*</span>
                            </label>
                            <input type="text" class="form-control" id="razonSocial_empresa" name="razonSocial_empresa"
                                formControlName="razonSocial_empresa" placeholder="¿Cuál es la razón social?">
                            <div *ngIf="formularioForm.get('razonSocial_empresa')?.touched && formularioForm.get('razonSocial_empresa')?.errors?.['required']"
                                class="error">
                                El campo es requerido
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Grupo - Corporativo -->
                <div class="row mt-3" *ngIf="!existeRFC">
                    <div class="d-flex justify-content-center">
                        <div class="form-group">
                            <label for="grupo_empresa" class="form-label">Corporativo
                            </label>
                            <input type="text" class="form-control" id="grupo_empresa" name="grupo_empresa"
                                formControlName="grupo_empresa" placeholder="¿A qué grupo pertenece?">
                            <div *ngIf="formularioForm.get('grupo_empresa')?.touched && formularioForm.get('grupo_empresa')?.errors?.['required']"
                                class="error">
                                El campo es requerido
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Sitio Web -->
                <div class="row mt-3" *ngIf="!existeRFC">
                    <div class="d-flex justify-content-center">
                        <div class="form-group">
                            <label for="web_empresa" class="form-label">Sitio Web <span
                                    class="requerido">*</span></label>
                            <input type="text" class="form-control" id="web_empresa" name="web_empresa"
                                formControlName="web_empresa" placeholder="https://www.tunombredesitio.com">
                            <div *ngIf="formularioForm.get('web_empresa')?.touched && formularioForm.get('web_empresa')?.errors?.['required']"
                                class="error">
                                El campo es requerido
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Descripción breve de la organización -->
                <div class="row mt-3 frm-descripcion_empresa " *ngIf="!existeRFC">
                    <div class="d-flex justify-content-center">
                        <div class="form-group">
                            <label for="descripcion_empresa" class="form-label">Descripción breve de la
                                organización <span class="requerido">*</span></label>
                            <textarea type="textarea" class="form-control" id="descripcion_empresa"
                                name="descripcion_empresa" formControlName="descripcion_empresa" rows="6"
                                placeholder="¿Qué es lo que hace la organización?"></textarea>
                        </div>
                    </div>
                </div>



                <!-- Sector -->
                <div class="row mt-3" *ngIf="!existeRFC">
                    <div class="d-flex justify-content-center">
                        <div class="form-group">
                            <label for="sector_empresa" class="form-label">Sector:</label>
                            <label class="" for="sector_publico">
                                <input type="radio" class="alcance-input-hidden" id="sector_publico"
                                    name="sector_empresa" forControlName="sector_empresa" value=1
                                    (change)="setSectorEmpresa(1)">
                                <div class="circulo mx-2 mx-sm-3">
                                    <img alt="" src="assets/img/ico_publico_ngo.svg" width="168px">
                                </div>
                                <div class="alcance-titulo"></div>
                            </label>
                            <label class="" for="sector_privado">
                                <input type="radio" class="alcance-input-hidden" id="sector_privado"
                                    name="sector_empresa" forControlName="sector_empresa" value=2
                                    (change)="setSectorEmpresa(2)">
                                <div class="circulo mx-2 mx-sm-5">
                                    <img alt="" src="assets/img/ico_privado_ngo.svg" width="168px">
                                </div>
                                <div class="alcance-titulo"></div>
                            </label>
                            <label class="" for="sector_social">
                                <input type="radio" class="alcance-input-hidden" id="sector_social"
                                    name="sector_empresa" forControlName="sector_empresa" value=3
                                    (change)="setSectorEmpresa(3)">
                                <div class="circulo mx-2 mx-sm-4">
                                    <img alt="img_retos" src="assets/img/ico_social_ngo.svg" width="168px">
                                </div>
                                <div class="alcance-titulo"></div>
                            </label>
                        </div>
                    </div>
                </div>


                <!-- Industria -->
                <div class="row mt-3" *ngIf="!existeRFC">
                    <div class="d-flex justify-content-center">
                        <div class="form-group">
                            <label for="industria_empresa" class="form-label">Industria <span
                                    class="requerido">*</span></label>

                            <div class="select-dropdown">
                                <ng-select class=" colorGray" [items]="Industrias" bindLabel="industria"
                                    bindValue="idIndustria" placeholder="Selecciona una opción"
                                    formControlName="industria_empresa"></ng-select>
                            </div>

                            <div *ngIf="formularioForm.get('industria_empresa')?.touched && formularioForm.get('industria_empresa')?.errors?.['required']"
                                class="error">
                                El campo es requerido
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Tamaño de la organización -->
                <div class="row mt-3" *ngIf="!existeRFC">
                    <div class="d-flex justify-content-center">
                        <div class="form-group">
                            <label for="tamanio_empresa" class="form-label">Tamaño de la organización <span
                                    class="requerido">*</span></label>

                            <div class="select-dropdown">
                                <ng-select class=" colorGray" [items]="TamanoEmpresa" bindLabel="tamanoEmpresa"
                                    bindValue="idTamanoEmpresa" placeholder="Selecciona una opción"
                                    formControlName="tamanio_empresa"></ng-select>
                            </div>

                            <div *ngIf="formularioForm.get('tamanio_empresa')?.touched && formularioForm.get('tamanio_empresa')?.errors?.['required']"
                                class="error">
                                El campo es requerido
                            </div>
                        </div>
                    </div>
                </div>


                <!-- Presencia -->
                <div class="row mt-3" *ngIf="!existeRFC">
                    <div class="d-flex justify-content-center">
                        <div class="form-group">
                            <label for="presencia_empresa" class="form-label">Presencia <span
                                    class="requerido">*</span></label>
                            <div *ngIf="formularioForm.get('presencia_empresa')?.touched && formularioForm.get('presencia_empresa')?.errors?.['required']" class="error">
                                El campo es requerido
                            </div>
                            <div class="d-flex justify-content-evenly">
                                <label class="" for="alcance_local">
                                    <input type="radio" class="alcance-input-hidden" id="alcance_local"
                                        name="presencia_empresa" forControlName="presencia_empresa" value=1
                                        (change)="setPresenciaEmpresa(1)">
                                    <div class="circulo mx-1">
                                        <img alt="" src="assets/svg/icon_local.svg" width="168px">
                                    </div>
                                    <div class="alcance-titulo">Local</div>
                                </label>

                                <label class="" for="alcance_regional">
                                    <input type="radio" class="alcance-input-hidden" id="alcance_regional"
                                        name="presencia_empresa" forControlName="presencia_empresa" value=2
                                        (change)="setPresenciaEmpresa(2)">
                                    <div class="circulo mx-1">
                                        <img alt="" src="assets/svg/icon_regional.svg" width="168px">
                                    </div>
                                    <div class="alcance-titulo">Regional</div>
                                </label>
                            </div>
                            <div class="d-flex justify-content-evenly">
                                <label class="" for="alcance_nacional">
                                    <input type="radio" class="alcance-input-hidden" id="alcance_nacional"
                                        name="presencia_empresa" forControlName="presencia_empresa" value=3
                                        (change)="setPresenciaEmpresa(3)">
                                    <div class="circulo mx-1">
                                        <img alt="" src="assets/svg/icon_nacional.svg" width="168px">
                                    </div>
                                    <div class="alcance-titulo">Nacional</div>
                                </label>

                                <label class="" for="alcance_internacional">
                                    <input type="radio" class="alcance-input-hidden" id="alcance_internacional"
                                        name="presencia_empresa" forControlName="presencia_empresa" value=4
                                        (change)="setPresenciaEmpresa(4)">
                                    <div class="circulo mx-1">
                                        <img alt="" src="assets/svg/icon_internacional.svg" width="168px">
                                    </div>
                                    <div class="alcance-titulo">Internacional</div>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Columna 2 -->
            <div class="col-12 col-sm-6">

                <!-- Pais -->
                <div class="row " *ngIf="!existeRFC">
                    <div class="d-flex justify-content-center">
                        <div class="form-group">
                            <label for="pais_empresa" class="form-label">País <span class="requerido">*</span></label>

                            <div class="select-dropdown">
                                <ng-select class=" colorGray" [items]="Paises" bindLabel="pais" bindValue="clavePais"
                                    placeholder="Selecciona una opción" (change)="onChangePais()"
                                    formControlName="pais_empresa"></ng-select>
                            </div>

                            <div *ngIf="formularioForm.get('pais_empresa')?.touched && formularioForm.get('pais_empresa')?.errors?.['required']"
                                class="error">
                                El campo es requerido
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Código Postal -->
                <div class="row mt-3" *ngIf="!existeRFC">
                    <div class="d-flex justify-content-center">
                        <div class="form-group">
                            <label for="cp_empresa" class="form-label">Código Postal <span
                                    class="requerido">*</span></label>

                            <input type="text" class="form-control" id="cp_empresa" name="cp_empresa" maxlength="5"
                                (blur)="buscarCP()" formControlName="cp_empresa"
                                placeholder="Código postal relacionado">

                            <div class="form-text">Para empresas no establecidas en México, introducir EXT</div>
                            <div *ngIf="formularioForm.get('cp_empresa')?.touched && formularioForm.get('cp_empresa')?.errors?.['required']"
                                class="error">
                                El campo es requerido.
                            </div>
                            <div *ngIf="formularioForm.get('cp_empresa')?.touched && formularioForm.get('cp_empresa')?.errors?.['pattern']"
                                class="error">
                                Deben ser 5 dígitos o la palabra EXT para empresas extranjeras.
                            </div>

                        </div>
                    </div>
                </div>

                <!-- Estado -->
                <div class="row mt-3" *ngIf="!existeRFC">
                    <div class="d-flex justify-content-center">
                        <div class="form-group">
                            <label for="estado_empresa" class="form-label">Estado <span
                                    class="requerido">*</span></label>

                            <input type="text" *ngIf="!cpMexico" placeholder="Escribe el estado"
                                formControlName="estado2_empresa" class="form-control" />
                            <div class="select-dropdown" *ngIf="cpMexico">
                                <ng-select class=" colorGray" [items]="Estados" bindLabel="estado"
                                    bindValue="claveEstado" placeholder="Selecciona una opción"
                                    formControlName="estado_empresa"></ng-select>
                            </div>

                            <div *ngIf="formularioForm.get('estado_empresa')?.touched && formularioForm.get('estado_empresa')?.errors?.['required']"
                                class="error">
                                El campo es requerido
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Ciudad -->
                <div class="row mt-3" *ngIf="!existeRFC">
                    <div class="d-flex justify-content-center">
                        <div class="form-group">
                            <label for="ciudad_empresa" class="form-label">Ciudad <span
                                    class="requerido">*</span></label>
                            <input type="text" class="form-control" id="ciudad_empresa" name="ciudad_empresa"
                                formControlName="ciudad_empresa" placeholder="Escribe la Ciudad aquí">
                            <div *ngIf="formularioForm.get('ciudad_empresa')?.touched && formularioForm.get('ciudad_empresa')?.errors?.['required']"
                                class="error">
                                El campo es requerido
                            </div>
                        </div>
                    </div>

                </div>

                <!-- Colonia -->
                <div class="row mt-3" *ngIf="!existeRFC">
                    <div class="d-flex justify-content-center">
                        <div class="form-group">
                            <label for="colonia_empresa" class="form-label">Colonia <span
                                    class="requerido">*</span></label>

                            <input type="text" *ngIf="!cpMexico" placeholder="Escribe la colonia"
                                formControlName="colonia2_empresa" class="form-control" />
                            <div class="select-dropdown" *ngIf="cpMexico">
                                <ng-select class=" colorGray" [items]="Colonias" bindLabel="colonia" bindValue="colonia"
                                    placeholder="Selecciona una opción" formControlName="colonia_empresa"></ng-select>
                            </div>

                            <div *ngIf="formularioForm.get('colonia_empresa')?.touched && formularioForm.get('cp_empresa')?.errors?.['required']"
                                class="error">
                                El campo es requerido
                            </div>
                            <div *ngIf="formularioForm.get('colonia_empresa')?.touched && formularioForm.get('cp_empresa')?.errors?.['maxlength']"
                                class="error">
                                Máximo {{
                                formularioForm.get('colonia_empresa')?.errors?.['maxlength']?.requiredLength }}
                                dígitos
                            </div>
                        </div>
                    </div>
                </div>


                <!-- Calle -->
                <div class="row mt-3" *ngIf="!existeRFC">
                    <div class="d-flex justify-content-center">
                        <div class="form-group">
                            <label for="calle_empresa" class="form-label ">Calle y número <span
                                    class="requerido">*</span></label>
                            <input type="text" class="form-control" id="calle_empresa" name="calle_empresa"
                                formControlName="calle_empresa" placeholder="Escribe la calle aquí">
                            <div *ngIf="formularioForm.get('calle_empresa')?.touched && formularioForm.get('calle_empresa')?.errors?.['required']"
                                class="error">
                                El campo es requerido
                            </div>
                        </div>
                    </div>
                </div>


                <!-- Campus desea ser atendido -->
                <div class="row mt-3" *ngIf="!existeRFC">
                    <div class="d-flex justify-content-center">
                        <div class="form-group">
                            <label for="campus_empresa" class="form-label">Campus donde deseas ser atendido <span
                                    class="requerido">*</span></label>

                            <div class="select-dropdown">
                                <ng-select class=" colorGray" [items]="Campus" bindLabel="campus" bindValue="idCampus"
                                    placeholder="Selecciona una opción" formControlName="campus_empresa"></ng-select>
                            </div>

                            <div *ngIf="formularioForm.get('campus_empresa')?.touched && formularioForm.get('campus_empresa')?.errors?.['required']"
                                class="error">
                                El campo es requerido
                            </div>
                        </div>
                    </div>
                </div>



                <!-- Relación -->
                <div class="row mt-3" *ngIf="!existeRFC">
                    <div class="d-flex justify-content-center">
                        <div class="form-group">
                            <label for="relacion_empresa" class="form-label">Relación actual con el Tec de
                                Monterrey:</label>
                            <div class="form-text">Selecciona una o más opciones</div>
                            <br>
                            <div class="row mx-auto">
                                <div class="col-md-12">
                                    <div class="col-12" *ngFor="let el of RelacionesTec">
                                        <div class="form-check form-check-inline">
                                            <input type="radio" class="form-check-input" value="{{ el.idRelacionTec}}"
                                                id="{{ el.idRelacionTec}}" formArrayName="relaciones"
                                                (click)="onCheckboxChange($event, 'relaciones')">
                                            <label class="form-label"
                                                for="{{ el.relacionTec}}">{{el.relacionTec}}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Acercamiento -->
                <div class="row mt-3 " *ngIf="!existeRFC">
                    <div class="d-flex justify-content-center">
                        <div class="form-group">
                            <label for="acercamiento_empresa" class="form-label">Has tenido acercamiento con el Tec para
                                conocer sobre el rol del Socio Formador <span class="requerido">*</span></label>
                            <div *ngIf="formularioForm.get('acercamiento_empresa')?.touched && formularioForm.get('acercamiento_empresa')?.errors?.['required']"
                                class="error">
                                El campo es requerido
                            </div>

                            <div class="form-check form-check-inline ">
                                <input id="acercamiento" class="form-check-input-radio me-1" value="true" type="radio" name="acercamiento_empresa"
                                    formControlName="acercamiento_empresa" />
                                <label class="radio-label me-3"> Si</label>
                                <input id="acercamiento" class="form-check-input-radio me-1" value="false" type="radio" name="acercamiento_empresa"
                                    formControlName="acercamiento_empresa" selected="selected" />
                                <label class="radio-label">No</label>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
            <img class="frm-patron-9 d-none d-sm-block d-md-none" alt="" src="/assets/img/Figura_9.png">

        </div>

        <!-- botonera -->
        <div class="col-12 mt-3 d-sm-flex justify-content-center" *ngIf="vFrmOrganizacion">
            <button class="btn-amarillo " type="submit">Siguiente</button>

        </div>
    </form>

    <!-- Registro de Contacto -->
    <form (ngSubmit)="guardarInformacion()" [formGroup]="formularioFormC">
        <!-- Datos de Contacto -->
        <div class="col-12" *ngIf="vFrmContacto">
            <!-- Si la empresa existe aparece este texto -->
            <div class="datosOrganizacion mt-5">
                <span>Empresa con RFC/No. de tributación IRS: {{ this.rfc_empresa.value}} / {{ this.razonSocial ||
                    this.nombreSocioFormador }}</span>
            </div>

            <!-- Título Datos de Contacto -->
            <div class="frm_titulo_seccion mt-3">
                Datos de contacto
            </div>

            <div class="row">

                <div class="col-12 col-sm-6">
                    <div class="pl-60px">

                        <!-- Nombre -->
                        <div>
                            <div class="d-flex1 justify-content-center1">
                                <div class="form-group">

                                    <label for="nombre_contacto" class="form-label">Nombre <span
                                            class="requerido">*</span></label>
                                    <input type="text" class="form-control" id="nombre_contacto" name="nombre_contacto"
                                        formControlName="nombre_contacto" placeholder="Escribe el nombre aquí">
                                    <div *ngIf="formularioFormC.get('nombre_contacto')?.touched && formularioFormC.get('nombre_contacto')?.errors?.['required']"
                                        class="error">
                                        El campo es requerido
                                    </div>
                                    <div *ngIf="formularioFormC.get('nombre_contacto')?.touched && formularioFormC.get('nombre_contacto')?.errors?.['minlength']"
                                        class="error">
                                        Mínimo 3 letras
                                    </div>
                                </div>
                            </div>
                        </div>


                        <!-- Apellidos -->
                        <div>
                            <div class="d-flex1 justify-content-center1">
                                <div class="form-group">
                                    <label for="apellidos_contacto" class="form-label">Apellidos <span
                                            class="requerido">*</span></label>
                                    <input type="text" class="form-control" id="apellidos_contacto"
                                        name="apellidos_contacto" formControlName="apellidos_contacto"
                                        placeholder="Escribe los apellidos aquí">
                                    <div *ngIf="formularioFormC.get('apellidos_contacto')?.touched && formularioFormC.get('apellidos_contacto')?.errors?.['required']"
                                        class="error">
                                        El campo es requerido
                                    </div>
                                    <div *ngIf="formularioFormC.get('apellidos_contacto')?.touched && formularioFormC.get('apellidos_contacto')?.errors?.['minlength']"
                                        class="error">
                                        Mínimo 3 letras
                                    </div>
                                </div>
                            </div>
                        </div>



                        <!-- Area que pertenece -->
                        <div>
                            <div class="d-flex1 justify-content-center1">
                                <div class="form-group">
                                    <label for="area_departamento_contacto" class="form-label">Área a la que perteneces
                                        <span class="requerido">*</span></label>

                                    <div class="select-dropdown">
                                        <ng-select class=" colorGray" [items]="Areas" bindLabel="area"
                                            bindValue="idArea" placeholder="Selecciona una opción"
                                            formControlName="area_departamento_contacto"></ng-select>
                                    </div>

                                    <div *ngIf="formularioFormC.get('area_departamento_contacto')?.touched && formularioFormC.get('area_departamento_contacto')?.errors?.['required']"
                                        class="error">
                                        El campo es requerido
                                    </div>
                                    <div *ngIf="formularioFormC.get('area_departamento_contacto')?.touched && formularioFormC.get('area_departamento_contacto')?.errors?.['minlength']"
                                        class="error">
                                        Minimo 3 letras
                                    </div>
                                </div>
                            </div>
                        </div>


                        <!-- Puesto -->
                        <div>
                            <div class="d-flex1 justify-content-center1">
                                <div class="form-group">
                                    <label for="puesto_contacto" class="form-label">Puesto <span
                                            class="requerido">*</span></label>
                                    <input type="text" class="form-control" id="puesto_contacto" name="puesto_contacto"
                                        formControlName="puesto_contacto" placeholder="Escribe el puesto aquí">
                                    <div *ngIf="formularioFormC.get('puesto_contacto')?.touched && formularioFormC.get('puesto_contacto')?.errors?.['required']"
                                        class="error">
                                        El campo es requerido
                                    </div>
                                    <div *ngIf="formularioFormC.get('puesto_contacto')?.touched && formularioFormC.get('puesto_contacto')?.errors?.['minlength']"
                                        class="error">
                                        Mínimo 3 letras
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="col-12 col-sm-6">

                    <!-- Correo electrónico -->
                    <div>
                        <div class="d-flex1 justify-content-center1">
                            <div class="form-group">
                                <label for="email_contacto" class="form-label">Correo electrónico <span
                                        class="requerido">*</span></label>
                                <input type="text" class="form-control" id="email_contacto" name="email_contacto"
                                    formControlName="email_contacto" placeholder="Escribe el correo electrónico aquí">
                                <div *ngIf="formularioFormC.get('email_contacto')?.touched && formularioFormC.get('email_contacto')?.errors?.['required']"
                                    class="error">
                                    El campo es requerido
                                </div>
                                <div *ngIf="formularioFormC.get('email_contacto')?.touched && formularioFormC.get('email_contacto')?.errors?.['email']"
                                    class="error">
                                    Correo no válido
                                </div>
                            </div>
                        </div>
                    </div>



                    <!-- Telefono Oficina -->
                    <div>
                        <div class="d-flex1 justify-content-center1">
                            <div class="form-group">
                                <label for="telefono_oficina_contacto" class="form-label">Teléfono de oficina</label>
                                <input type="text" class="form-control" id="telefono_oficina_contacto"
                                    name="telefono_oficina_contacto" formControlName="telefono_oficina_contacto">
                                <div *ngIf="formularioFormC.get('telefono_oficina_contacto')?.errors?.['pattern']"
                                    class="error">
                                    Deben ser sólo números
                                </div>
                            </div>
                        </div>
                    </div>


                    <!-- Telefono de Celular -->
                    <div>
                        <div class="d-flex1 justify-content-center1">
                            <div class="form-group">
                                <label for="celular_contacto" class="form-label">Teléfono celular</label>
                                <input type="text" class="form-control" id="celular_contacto" name="celular_contacto"
                                    formControlName="celular_contacto">
                                <div *ngIf="formularioFormC.get('celular_contacto')?.errors?.['pattern']" class="error">
                                    Deben ser sólo números
                                </div>
                            </div>
                        </div>
                    </div>


                    <!-- Eres EXATEC -->
                    <div class="row ">
                        <div class="d-flex1 justify-content-center1">
                            <div class="form-group">
                                <label for="exatec_contacto" class="form-label">¿Eres EXATEC? <span
                                        class="requerido">*</span></label>
                                <div class="">
                                    <select class="form-control" formControlName="exatec_contacto">
                                        <option value="" selected disabled>Selecciona una opción</option>
                                        <option value="true">Si</option>
                                        <option value="false">No</option>
                                    </select>
                                    <div *ngIf="formularioFormC.get('exatec_contacto')?.touched && formularioFormC.get('exatec_contacto')?.errors?.['required']"
                                        class="error">
                                        El campo es requerido
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>


                    <!-- Cómo te enteraste -->
                    <div class="row">
                        <div class="d-flex1 justify-content-center1">
                            <div class="form-group">
                                <label for="enteraste_contacto" class="form-label">¿Cómo te enteraste de esta
                                    plataforma? <span class="requerido">*</span></label>

                                <div class="select-dropdown">
                                    <ng-select class=" colorGray" [items]="MediosDeContacto" bindLabel="medioContacto"
                                        bindValue="idMedioContacto" placeholder="Selecciona una opción"
                                        formControlName="enteraste_contacto"></ng-select>
                                </div>



                                <div *ngIf="formularioFormC.get('enteraste_contacto')?.touched && formularioFormC.get('enteraste_contacto')?.errors?.['required']"
                                    class="error">
                                    El campo es requerido
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <!-- Aviso de privacidad -->
                <div class="form-check mt-4">
                    <div class="d-flex justify-content-center">
                        <div class=" form-check">
                            <input type="checkbox" class="form-check-input" id="terminos_condiciones"
                                name="terminos_condiciones" formControlName="terminos_condiciones">
                            <label for="terminos_condiciones" class="font18px form-check-label">He leído y acepto
                                los
                                términos
                                del
                                <a class="aviso_privacidad"
                                    href="https://tec.mx/es/aviso-de-privacidad-plataforma-mps-tec">AVISO DE
                                    PRIVACIDAD</a>. <span class="requerido">*</span></label>
                            <div *ngIf="formularioFormC.get('terminos_condiciones')?.touched && formularioFormC.get('terminos_condiciones')?.errors?.['required']"
                                class="error">
                                Debe aceptar el aviso de privacidad
                            </div>
                        </div>
                    </div>
                </div>


            </div>




            <button class="btn-amarillo d-none" type="submit" [disabled]="formularioFormC.invalid">Enviar</button>


        </div>

        <!-- botonera -->
        <div class="col-12 mt-3 d-sm-flex justify-content-center" *ngIf="vFrmContacto">
            <button class="btn-amarillo mx-sm-3 mt-3 order-sm-1" type="submit">Enviar</button>
            <button class="btn-brown  mx-sm-3 mt-3 order-sm-0" type="button" (click)="Cancelar()">Cancelar</button>

        </div>
    </form>




    <div class="row text-center mt-5 mb-5" *ngIf="vConfirmacion">
        <div class="selft-align-center">
            <div class="titulo">
                <p>
                    Hemos recibido tu solicitud, para continuar con el proceso comparte los siguientes documentos a la cuenta de correo: {{ correoCVDP }}
                </p>
                <div class="text-start">
                    <ol>
                        <li>Tu identificación</li>
                        <li>Constancia de situación fiscal</li>
                        <li>Comprobante de domicilio. En caso de que el domicilio sea el mismo que aparece en la constancia, no es necesario enviar el comprobante. </li>
                    </ol>
                </div>
            </div>


            <div class="titulo" *ngIf="false">
                <p>
                    Tu solicitud ha sido enviada al responsable del Centro de Vinculación y Desarrollo Profesional del
                    Tec de Monterrey para validar tu acceso. Próximamente te llegará un correo electrónico del status de
                    tu solicitud.
                </p>
                <p> ¡Muchas gracias por tu interés de ser Socio Formador! </p>
                <p>
                    Si tienes alguna duda envía un correo a
                    <a href="mailto: sociosformadores@servicios.tec.mx">sociosformadores@servicios.tec.mx</a>
                </p>
            </div>
        </div>
        <div class="col-12 mt-3 d-sm-flex justify-content-center" *ngIf="false">
            <button class="btn-amarillo mx-sm-3 mt-3 " type="button" (click)="RegresarHome()">Aceptar</button>
        </div>
    </div>




    <div class="row text-center mt-5 mb-5" *ngIf="vCancelar">
        <div class="selft-align-center">
            <div class="titulo">
                <p class="requerido">
                    Los datos de tu solicitud serán borrados y no podrás recuperarlos, ¿estás de acuerdo?
                </p>

            </div>
        </div>
        <div class="col-12 mt-3 d-sm-flex justify-content-center">
            <button class="btn-amarillo mx-sm-3 mt-3 order-sm-1" type="button" (click)="RegresarHome()">Aceptar</button>
            <button class="btn-brown  mx-sm-3 mt-3 order-sm-0" type="button" (click)="regresar()">Cancelar</button>

        </div>
    </div>

</div>
