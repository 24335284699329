import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  FormArray,
} from '@angular/forms';
import { Router } from '@angular/router';
import {
  IAlcance,
  IArea,
  ICampus,
} from '@shared/Interface/ICatalogos.interface';
import { CatalogosService } from '@shared/service/Catalogos/catalogos.service';
import { ProblematicasService } from '@shared/service/Problematicas/problematicas.service';
import { UsuariosService } from '@shared/service/Usuarios/usuarios.service';
import { ToastrService } from 'ngx-toastr';
import { tap } from 'rxjs/operators';

interface VinculaReto {
  problematica: string;
}

@Component({
  selector: 'app-vincula-reto',
  templateUrl: './vincula-reto.component.html',
  styleUrls: ['./vincula-reto.component.css'],
})
export class VinculaRetoComponent implements OnInit {
  vinculacionForm: FormGroup;
  validationErrors: string[] = [];
  areaData!: IArea[];
  campusData: ICampus[];
  seccion1: ICampus[];
  seccion2: ICampus[];
  alcanceData!: IAlcance[];
  buttonSave = {
    title: 'Buscar Retos',
    isLoading: false,
  };

  titleVinculacion = {
    title: 'Vincúlate a un reto académico',
  };

  constructor(
    private fb: FormBuilder,
    private catalogosService: CatalogosService,
    private problematicasService: ProblematicasService,
    private router: Router,
    private toastr: ToastrService,
    private usuarioService: UsuariosService
  ) {}

  ngOnInit(): void {
    this.campusData = [];
    this.seccion1 = [];
    this.seccion2 = [];

    this.initializeForm();
    this.catalogosService
      .getAreas()
      .pipe(tap((resultado: IArea[]) => {
        this.areaData = resultado;
        // v2 - Se agrega la opción de "Otra"

          this.areaData.push({ idArea:0, area:"Otra"});
      }))
      .subscribe();

    this.catalogosService
      .getAlcance()
      .pipe(tap((resultado: IAlcance[]) => (this.alcanceData = resultado)))
      .subscribe();
  }

  initializeForm() {
    this.vinculacionForm = this.fb.group({
      proyecto: ['', Validators.required],
      problematica: ['', Validators.required],
      objetivos: ['', Validators.required],
      areas: this.fb.array([],Validators.required),
      entregables: [''],
      idAlcance: ['', Validators.required],
      otraArea: []
    });
  }

  searchRetos() {
    this.vinculacionForm.markAllAsTouched();
    if (this.vinculacionForm.valid) {
      this.buttonSave.title = 'Cargando..';
      this.buttonSave.isLoading = true;


      this.problematicasService
        .postProblematica({
          areas:this.vinculacionForm.value.areas.filter(x=>x != 0),
          idAlcance:Number(this.vinculacionForm.value.idAlcance),
          proyecto: this.vinculacionForm.value.proyecto,
          problematica: this.vinculacionForm.value.problematica,
          objetivos:this.vinculacionForm.value.objetivos,
          idContacto: this.usuarioService.getCurrentUserData().idContacto,
          otraArea:this.vinculacionForm.value.otraArea
        })
        .pipe(
          tap((resultado: any) => {
            if (resultado) {
              let ppc = this.vinculacionForm.value.proyecto +" "+ this.vinculacionForm.value.problematica;
              this.buttonSave.title = 'Buscar Retos';
              this.buttonSave.isLoading = true;
              localStorage.setItem('retosMatch', JSON.stringify(resultado));
              localStorage.setItem('ppc',JSON.stringify(ppc));
              sessionStorage.setItem('idLogBusquedaReto',resultado.idLogBusquedaReto);
              this.router.navigate(['vinculacion-reto-search']);
            }
          })
        )
        .subscribe();
    } else {
      this.toastr.error('Todos los campos son obligatorios');
    }

  }

  onCheckboxChange(event: any, typeData: string) {
    const selectedAreas = this.vinculacionForm.controls[typeData] as FormArray;
    if (event.target.checked) {
      selectedAreas.push(new FormControl(parseInt(event.target.value)));
    } else {
      const index = selectedAreas.controls.findIndex(
        (x) => x.value === event.target.value
      );
      selectedAreas.removeAt(index);
    }
  }

  redirectBancoRetos() {
    window.location.href = 'banco-retos';
  }
}
