import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { COLS_LISTADO_RETOS } from "@pages/dashboard/cvdp-dashboard/cvdp-dashboard.columns";
import {
  ICampus,
  IEscuela,
  IPeriodo,
  IPeriodoSemanal,
} from "@shared/Interface/ICatalogos.interface";
import { IRetosCVDPResponse } from "@shared/Interface/IRetosCVDPResponse";
import { DashboardService } from "@shared/service/Dashboard/dashboard.service";
import { UsuariosService } from "@shared/service/Usuarios/usuarios.service";
import { CatalogosService } from "@shared/service/Catalogos/catalogos.service";
import { tap, lastValueFrom } from "rxjs";
import {
  COLS_LISTADO_RETOS_CONC_ACAD,
  COLS_LISTADO_RETOS_CURS_ACAD,
  COLS_LISTADO_RETOS_FORM_ACAD,
} from "@pages/dashboard/academia-dashboard/academia-dashboard.columns";
import { InicioService } from "@shared/service/Inicio/inicio.service";

@Component({
  selector: "app-documentacion-reto",
  templateUrl: "./documentacion-reto.component.html",
  styleUrls: ["./documentacion-reto.component.css"],
})
export class DocumentacionRetoComponent implements OnInit {
  buscadorText: string = "";
  sidebarExpanded = false;
  lang: string = "es";

  title = {
    title: "Mis Retos",
  };

  //* Documentacion de retos
  listadoRetosFormalizacionColumns = COLS_LISTADO_RETOS_FORM_ACAD;
  listadoRetosFormalizacionData: any;
  listadoRetosFormalizacionDataPage: any;

  listadoRetosCursoColumns = COLS_LISTADO_RETOS_CURS_ACAD;
  listadoRetosCursoData: any;
  listadoRetosCursoDataPage: any;

  listadoRetosEvaluacionColumns = COLS_LISTADO_RETOS_CURS_ACAD;
  listadoRetosEvaluacionData: any;
  listadoRetosEvaluacionDataPage: any;

  listdaoRetosConcluidoColumns = COLS_LISTADO_RETOS_CONC_ACAD;
  listadoRetosConcluidoData: any;
  listadoRetosConcluidoDataPage: any;

  mostrarRetos: boolean = false;
  status: string = "formalizacion";

  localdb: any = [];

  ngOnInit(): void {
    this.lang = localStorage.getItem("lang") ?? "es";
    this.loadCatalogos();
    this.loadData();
  }

  changeLang(lang: string) {
    this.lang = lang;
    localStorage.setItem("lang", lang);
    window.location.reload();
  }

  constructor(
    private dashboardService: DashboardService,
    private changeDetector: ChangeDetectorRef,
    private usuarioService: UsuariosService,
    private catalogosService: CatalogosService,
    private inicioService: InicioService
  ) {}

  backgroundTable = "#F6F6F6";

  // ** Listado de Retos  (Activos / Mi historial de retos)
  listadoRetosColumns = COLS_LISTADO_RETOS;

  campusData: ICampus[] = [];
  escuelaData: IEscuela[] = [];
  periodoData: IPeriodo[] = [];
  periodoSemanalData: IPeriodoSemanal[] = [];

  filter: any = {
    query: "",
    campus: 0,
    escuela: 0,
    periodo: 0,
  };

  pagina: number = 0;
  hits: number = 10;

  // Se agrega función compatible con el buscador MainLayoutBuscador
  buscadorPress(keyWord: any) {
    // Aquí iría lo que el padre tenga que hacer

    localStorage.removeItem("localdb");
    localStorage.removeItem("localdbFilters");

    if (
      keyWord !== null &&
      keyWord !== undefined &&
      keyWord.toString().trim() !== ""
    ) {
      this.filter.query = keyWord;
    } else {
      this.filter.query = "";
    }
    this.loadData();
  }

  loadCatalogos() {
    this.catalogosService
      .getCampus()
      .pipe(tap((resultado: ICampus[]) => (this.campusData = resultado)))
      .subscribe();

    this.catalogosService
      .getEscuelas()
      .pipe(tap((resultado: IEscuela[]) => (this.escuelaData = resultado)))
      .subscribe();

      this.catalogosService
      .getPeriodo(false)
      .pipe(tap((resultado: IPeriodo[]) => (this.periodoData = resultado)))
      .subscribe();

      this.catalogosService
      .getPeriodoSemanal()
      .pipe(tap((resultado: IPeriodoSemanal[]) => (this.periodoSemanalData = resultado)))
      .subscribe();
  }

  getValueCatalogo(obj: any, index: string, id: number, indexValue: string) {
    let obje = obj.filter((x: any) => x[index] === id).pop();
    if (obje) {
      return obje[indexValue];
    } else {
      return null;
    }
  }

  addFilter(type: string, id: number) {
    localStorage.removeItem("localdb");
    localStorage.removeItem("localdbFilters");
    this.setFilter(type, id);
    this.loadData();
  }

  setFilter(type: string, id: number) {
    this.filter[type] = id;
  }

  cleanFilters() {
    localStorage.removeItem("localdbFilters");
    localStorage.removeItem("localdb");

    this.filter = {
      query: "",
      campus: 0,
      escuela: 0,
      periodo: 0,
      idPeriodoSemanal: 0,
      idPeriodoSemestral: 0,
    };
    this.loadData();
  }

  async loadData() {
    let userStore = this.usuarioService.getCurrentUserData();

    this.listadoRetosEvaluacionData = [];
    this.listadoRetosEvaluacionDataPage = [];
    this.listadoRetosConcluidoData = [];
    this.listadoRetosConcluidoDataPage = [];
    this.listadoRetosFormalizacionData = [];
    this.listadoRetosFormalizacionDataPage = [];
    this.listadoRetosCursoData = [];
    this.listadoRetosCursoDataPage = [];

    let idUsuario =
      userStore.clavePerfil === "cvdp_admin" ? 0 : userStore.idUsuario;

    this.chekfilters();
    let tmp = localStorage.getItem("localdb");
    if (tmp) {
      this.localdb = JSON.parse(tmp);
      if (this.localdb) {
        this.listadoRetosFormalizacionData = this.localdb.formalizacion;
      }
    } else {
      // Documentacion
      let responseRetos = await lastValueFrom(
        this.dashboardService.getRetosAcademicos(
          idUsuario,
          "formalizacion",
          this.filter.campus,
          this.filter.escuela,
          this.filter.periodo,
          this.pagina,
          this.hits,
          this.filter.query,
          this.filter.idPeriodoSemanal,
          this.filter.idPeriodoSemestral
        )
      );

      this.listadoRetosFormalizacionData = this.getListadoRetosFormalizacion(
        responseRetos
      );


      responseRetos = await lastValueFrom(
        this.dashboardService.getRetosAcademicos(
          idUsuario,
          "en-curso",
          this.filter.campus,
          this.filter.escuela,
          this.filter.periodo,
          this.pagina,
          this.hits,
          this.filter.query,
          this.filter.idPeriodoSemanal,
          this.filter.idPeriodoSemestral
        )
      );

      this.listadoRetosCursoData = this.getListadoRetosCursoyEval(
        responseRetos, "en-curso"
      );


      responseRetos = await lastValueFrom(
        this.dashboardService.getRetosAcademicos(
          idUsuario,
          "evaluacion",
          this.filter.campus,
          this.filter.escuela,
          this.filter.periodo,
          this.pagina,
          this.hits,
          this.filter.query,
          this.filter.idPeriodoSemanal,
          this.filter.idPeriodoSemestral
        )
      );

      this.listadoRetosEvaluacionData = this.getListadoRetosCursoyEval(
        responseRetos, "evaluacion"
      );

      responseRetos = await lastValueFrom(
        this.dashboardService.getRetosAcademicos(
          idUsuario,
          "concluido",
          this.filter.campus,
          this.filter.escuela,
          this.filter.periodo,
          this.pagina,
          this.hits,
          this.filter.query,
          this.filter.idPeriodoSemanal,
          this.filter.idPeriodoSemestral
        )
      );

      this.listadoRetosConcluidoData = this.getListadoRetosConcluido(
        responseRetos
      );

      this.saveFilters();
      let socioFormadorList = {
        formalizacion: this.listadoRetosFormalizacionData,
        "en-curso": this.listadoRetosCursoData,
        evaluacion: this.listadoRetosEvaluacionData,
        concluido: this.listadoRetosConcluidoData,
      };

      localStorage.setItem("localdb", JSON.stringify(socioFormadorList));
    }

    this.showPaginacionDataFormalizacion(
      this.listadoRetosFormalizacionData.slice(0, 10)
    );

    this.showPaginacionDataEncurso(this.listadoRetosCursoData.slice(0, 10));

    this.showPaginacionDataEvaluacion(
      this.listadoRetosEvaluacionData.slice(0, 10)
    );

    this.showPaginacionDataConcluido(
      this.listadoRetosConcluidoData.slice(0, 10)
    );

  }

  getListadoRetosFormalizacion(resultado) {
    let perfil = this.getPerfil();
    return resultado.map((solicitud: IRetosCVDPResponse) => ({
      idReto: solicitud.idReto,
      fechaRegistro: solicitud.fechaInicio,
      reto: {
        title: solicitud.reto,
        subtitle: solicitud.unidadFormativa,
        description: "",
        link: `/${perfil}/formalizacion?reto=${solicitud.idReto}`,
      },
      nombreSocioFormador: {
        title: solicitud.nombreSocioFormador,
        subtitle: solicitud.rfc,
      },
      duracion: solicitud.duracionSemanas ?? "-",
      periodoSemanal: solicitud.periodoSemanal,
      periodoSemestral: solicitud.periodo,
      imageTitle: {
        title: solicitud.campusCoordinador ?? "",
        subtitle: solicitud.escuela,
      },
      proyecto: solicitud.crn,
      claveEstatusSolicitud: {
        textCol: "Llenar Información",
        statusType: "amarillo",
        link: `/${perfil}/formalizacion?reto=${solicitud.idReto}`,
      },
    }));
  }

  getListadoRetosCursoyEval(resultado, estatus) {
    let perfil = this.getPerfil();
    return resultado.map((solicitud: IRetosCVDPResponse) => ({
      idReto: solicitud.idReto,
      fechaRegistro: solicitud.fechaInicio,
      reto: {
        title: solicitud.reto,
        subtitle: solicitud.unidadFormativa,
        description: "",
        link: `/${perfil}/${estatus}?reto=${solicitud.idReto}`,
      },
      duracion: solicitud.duracionSemanas ?? "-",
      periodoSemanal: solicitud.periodoSemanal,
      periodoSemestral: solicitud.periodo,
      imageTitle: {
        title: solicitud.campusCoordinador ?? "",
        subtitle: solicitud.escuela,
      },
      nombreSocioFormador: {
        title: solicitud.contacto,
        subtitle: solicitud.correoContacto,
      },
      proyecto: solicitud.crn,
      claveEstatusSolicitud: {
        textCol:
          solicitud.claveEstatus === "en-curso"
            ? "Ver"
            : "Registrar caso de éxito",
        statusType:
          solicitud.claveEstatus === "en-curso" ? "primary" : "amarillo",
        link: `/${perfil}/${estatus}?reto=${solicitud.idReto}`,
      },
    }));
  }

  getListadoRetosConcluido(resultado) {
    let perfil = this.getPerfil();
    return resultado.map((solicitud: IRetosCVDPResponse) => ({
      idReto: solicitud.idReto,
      fechaRegistro: solicitud.fechaInicio,
      reto: {
        title: solicitud.reto,
        subtitle: solicitud.unidadFormativa,
        description: solicitud.escuela,
        link: `/${perfil}/concluido?reto=${solicitud.idReto}`,
      },
      duracion: solicitud.semanas ?? "-",
      periodoSemanal: solicitud.periodoSemanal,
      periodoSemestral: solicitud.periodo,
      imageTitle: {
        title: solicitud.campusCoordinador ?? "",
        subtitle: solicitud.escuela,
      },
      nombreSocioFormador: {
        title: solicitud.nombreSocioFormador,
        subtitle: solicitud.rfc,
      },
      inscritos: solicitud.inscritos,
      claveEstatusSolicitud: {
        textCol: "Ver",
        statusType: "primary",
        link: `/${perfil}/concluido?reto=${solicitud.idReto}`,
      },
    }));
  }

  getListadoRetos(resultado) {
    return resultado.map((solicitud: IRetosCVDPResponse) => ({
      idReto: solicitud.idReto,
      fechaRegistro: solicitud.fechaInicio,
      reto: {
        title: solicitud.reto,
        subtitle: solicitud.unidadFormativa,
        description: "",
        link: `/socio/pagina-retos-resumen?reto=${solicitud.idReto}`,
      },
      nombreSocioFormador: {
        title: solicitud.nombreSocioFormador,
        subtitle: solicitud.rfc,
      },
      contactPerson: {
        title: solicitud.nombreContacto ?? "-",
        subtitle: solicitud.correoContacto ?? "-",
      },
      imageTitle: {
        title: solicitud.academico,
        subtitle: solicitud.correoAcademico,
      },
      periodoSemestral: solicitud.periodo,
      periodoSemanal: solicitud.periodoSemanal,
      claveEstatusSolicitud: {
        textCol: "Ver",
        statusType: "primary",
        link: `/socio/pagina-retos-resumen?reto=${solicitud.idReto}`,
      },
    }));
  }

  showPaginacionDataFormalizacion(objData: any) {
    if (objData.length) {
      this.listadoRetosFormalizacionDataPage = objData;
      this.changeDetector.detectChanges();
    }
  }

  showPaginacionDataEncurso(objData: any) {
    if (objData.length) {
      this.listadoRetosCursoDataPage = objData;
      this.changeDetector.detectChanges();
    }
  }

  showPaginacionDataEvaluacion(objData: any) {
    if (objData.length) {
      this.listadoRetosEvaluacionDataPage = objData;
      this.changeDetector.detectChanges();
    }
  }

  showPaginacionDataConcluido(objData: any) {
    if (objData.length) {
      this.listadoRetosConcluidoDataPage = objData;
      this.changeDetector.detectChanges();
    }
  }

  changeViewStatus(status) {
    this.status = status;
  }


  saveFilters(){
    let filters = {
        query:this.filter.query,
        campus:this.filter.campus,
        escuela:this.filter.escuela,
        periodo:this.filter.periodo,
        idPeriodoSemanal:this.filter.idPeriodoSemanal,
        idPeriodoSemestral:this.filter.idPeriodoSemestral,

    };
    localStorage.setItem('localdbFilters',JSON.stringify(filters));
    this.inicioService.changeFilterQuery(this.filter.query);
  }

  chekfilters(){
    let tmp1 = localStorage.getItem('localdbFilters');
    let ret=false;
    if(tmp1){
      let tmp = JSON.parse(tmp1);

        this.filter.query = tmp.query;
        this.filter.campus = tmp.campus;
        if (this.filter.campus > 0)
          this.setFilter('campus', this.filter.campus);
        this.filter.escuela = tmp.escuela;
        if (this.filter.escuela > 0)
          this.setFilter('escuela', this.filter.escuela);
        this.filter.periodo = tmp.periodo;
        if (this.filter.periodo > 0)
           this.setFilter('periodo', this.filter.periodo);
        this.filter.idPeriodoSemanal = tmp.idPeriodoSemanal;
        if (this.filter.idPeriodoSemanal > 0)
           this.setFilter('periodoSemanal', this.filter.idPeriodoSemanal);
        this.filter.idPeriodoSemestral = tmp.idPeriodoSemestral;
        if (this.filter.idPeriodoSemestral > 0)
           this.setFilter('periodoSemestral', this.filter.idPeriodoSemestral);


        ret = true;
    }
    return ret;
  }

  getPerfil(){

    let perfil='';
    switch (this.usuarioService.getCurrentUserData().clavePerfil) {
      case 'cvdp_admin':
        perfil = 'cvdp';
        break;
      case 'cvdp':
        perfil = 'cvdp';
        break;
      case 'academia':
        perfil = 'acad';
        break;
      case 'socio':
        perfil = 'sf';
        break;
    }
    return perfil;

  }
}
