<div>
    <div>
        <div class="sec_ad">
            <app-title-show-more [showMoreObject]="{title: 'Reportes'} "></app-title-show-more>
            <div id="container-klipfolio">
                <div style="display:inline-block" id="kf-embed-container-17d0db4a7e2e510825ed051f70cfadd8"></div>
                <script type="text/javascript" src="https://embed.klipfolio.com/a/js/embed.api"></script>
                <script type="text/javascript">
                KF.embed.embedKlip({
                   profile : "17d0db4a7e2e510825ed051f70cfadd8",
                   settings : {"width":793,"theme":"light","borderStyle":"round","borderColor":"#cccccc"},
                   title : "Banco de Socios Formadores"
                });
                </script>
            </div>


            <div class="ReportListContainer">
                <div class="row mt-4 reporte">
                    <div class="col-1">
                        <button class="blue_button" (click)="abrirReporte(1)">Generar</button>
                    </div>
                    <div class="col">
                        <div class="title-option">
                            Socios Formadores Vinculados a reto
                        </div>
                        <div class="txt-option">
                            Obtener los Socios Formadores que tenga un reto vinculado
                        </div>
                    </div>
                </div>  
                <div class="row mt-4 reporte">
                    <div class="col-1">
                        <button class="blue_button" (click)="abrirReporte(2)">Generar</button>
                    </div>
                    <div class="col">
                        <div class="title-option">
                            Base de Retos Semilla
                        </div>
                        <div class="txt-option">
                            Base de Retos Semilla
                        </div>
                    </div>
                </div>  
                <div class="row mt-4 reporte">
                    <div class="col-1">
                        <button class="blue_button" (click)="abrirReporte(3)">Generar</button>
                    </div>
                    <div class="col">
                        <div class="title-option">
                            Usuarios
                        </div>
                        <div class="txt-option">
                            Reporte de usuarios en la plataforma
                        </div>
                    </div>
                </div>  
            </div>

        </div>
    </div>
</div>