import { Component, OnInit } from '@angular/core';

import { UserData } from '@shared/Models/DataUser';
import { ProfileIndicator } from '@shared/Models/ProfileIndicator';


import { DashboardService } from '@shared/service/Dashboard/dashboard.service';
import { UsuariosService } from '@shared/service/Usuarios/usuarios.service';
import Utils from '@shared/helpers/utils';
import { AccountService } from '@shared/service/Account/account.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {
  profileIndicators: ProfileIndicator[] = [];
  dataUser!: UserData;

  constructor(
    private dashboardService: DashboardService,
    private usuariosService: UsuariosService,
    private accountService: AccountService
  ) {
    
  }

  ngOnInit(): void {
    let user = this.usuariosService.getCurrentUserData();
    this.dataUser = user;
    let idUserRequest;

    if(this.dataUser.clavePerfil=='socio')
    { // idsocioformador
      idUserRequest =user.idSocioFormador;
    }
    if(this.dataUser.clavePerfil=='cvdp' ||this.dataUser.clavePerfil=='cvdp_admin' ){
      // campus
      idUserRequest =user.idUsuario;
    }
    if(this.dataUser.clavePerfil=='academia'){
      // idUsuario
      idUserRequest =user.idUsuario;
    }

    const userStore = this.usuariosService.getCurrentUserData();
    if (!userStore) return;
    this.dashboardService
      .getindSocioIcadores(idUserRequest, Utils.getTypeUserProfile(userStore.clavePerfil))
      .subscribe((response: any) => {
        Object.keys(response).forEach((indicator) => {
          const indicatorObj = Utils.getIndicatorData(indicator);
          if (indicatorObj) {
            this.profileIndicators.push({
              icon: indicatorObj.icon,
              title: indicatorObj.title,
              value: response[indicator],
            });
          }
        });
      });
  }
}
