<app-header-buscador [showSearchBar]="false"></app-header-buscador>

<div class="sec_ad">

    <!-- Titulo -->
    <app-title-show-more [showMoreObject]="titleCargaDocumentos"></app-title-show-more>

    <!-- Tabs -->
    <div id="sec_tab" class="nav nav-pills d-flex flex-md-nowrap flex-wrap" role="presentation">

        <div class="active" data-bs-toggle="pill" data-bs-target="#documentos-activos"
            aria-controls="documentos-activos" prm="re_ac" role="tab" type="button" aria-selected="true">
            Activos
        </div>
        <div prm="re_hs" data-bs-toggle="pill" data-bs-target="#documentos-historial"
            aria-controls="documentos-historial" class="" role="tab" type="button" aria-selected="false">
            Históricos
        </div>

    </div>

    <!-- Cuerpo tabs -->
    <div class="tab-content" id="pills-tabContent">

        <!-- documentos activos -->
        <div class="tab-pane fade show active" id="documentos-activos" role="tabpanel"
            aria-labelledby="documentos-activos">

            <div class="row bg-white my-4 py-3 px-3 rounded">
                <div class="col-5">
                    <span class="title-option">Programación de retos</span><br>
                    Sube aquí la programación semestral de retos
                </div>
                <div class="col-3">
                    <input class="hidden" type="file" (change)="selImagen($event)" accept="text/csv" #file />
                </div>

                <div class="col-2 d-flex justify-content-end align-items-center d-none">
                    <button type="button" class="btn btn-blue">Previsualizar</button>
                </div>
                <div class="col-2 d-flex justify-content-end align-items-center d-none">
                    <button type="button" class="btn btn-amarillo">Guardar</button>
                </div>

            </div>

            <div class="row bg-white my-4 py-3 px-3 rounded">
                <div class="col-5">
                    <span class="title-option">Evaluación Vinculatec</span><br>
                    Sube aquí la encuesta de vinculatec
                </div>
                <div class="col-3">
                    <input type="file">
                </div>
                <div class="col-2 d-flex justify-content-end align-items-center d-none">
                    <button type="button" class="btn btn-blue">Previsualizar</button>
                </div>
                <div class="col-2 d-flex justify-content-end align-items-center d-none">
                    <button type="button" class="btn btn-amarillo">Guardar</button>
                </div>

            </div>

            <div class="row bg-white my-4 py-3 px-3 rounded">
                <div class="col-5">
                    <span class="title-option">Resutlados Vinculatec</span><br>
                    Sube aquí los resultados de la evaluación de Vinculatec
                </div>
                <div class="col-3">
                    <input type="file">
                </div>
                <div class="col-2 d-flex justify-content-end align-items-center d-none">
                    <button type="button" class="btn btn-blue">Previsualizar</button>
                </div>
                <div class="col-2 d-flex justify-content-end align-items-center d-none">
                    <button type="button" class="btn btn-amarillo">Guardar</button>
                </div>

            </div>


        </div>

        <!-- Documentos historicos -->
        <div class="tab-pane fade" id="documentos-historial" role="tabpanel" aria-labelledby="documentos-historial">

            <mat-accordion>
                <mat-expansion-panel class="py-3 px-3">
                    <!-- Programación de Retos -->
                    <mat-expansion-panel-header>
                        <div class="row">
                            <span class="title-option">
                                Programación de retos
                            </span>
                        </div>
                    </mat-expansion-panel-header>



                    <div class="row">
                        <div class="col-12 float-end my-4">
                            <button type="button" (click)="cargarArchivos()" class="btn btn-blue">Actualizar</button>
                        </div>
                        <div class="col-4 title-option">Programación de retos</div>
                        <div class="col-3 title option">Fecha de carga</div>
                        <div class="col-5">&nbsp;</div>
                    </div>

                    <div class="my-3 row bg-white " *ngFor="let file of archivosRetosData">
                        <div class="col-4">Archivo de carga - {{ file.idArchivoProceso }}</div>
                        <div class="col-4">{{ file.fechahRegistro }} - {{ file.estatus }}</div>
                        <div class="col-2 d-flex justify-content-end align-items-center">
                            <button type="button" class="btn btn-blue"
                                (click)="verResumen(file.idArchivoProceso)">Previsualizar</button>
                        </div>
                        <div class="col-2 d-flex justify-content-end align-items-center">
                            <button type="button" class="btn btn-amarillo"
                                (click)="guardarArchivo(file.idArchivoProceso)">Guardar</button>
                        </div>
                    </div>

                </mat-expansion-panel>
                <mat-expansion-panel class="py-3 px-3">
                    <!-- Evaluación Vinculatec -->
                    <mat-expansion-panel-header>
                        <div class="row">
                            <span class="title-option">
                                Evaluación Vinculatec
                            </span>
                        </div>
                    </mat-expansion-panel-header>

                    <div class="row">
                        <div class="col-4 title-option">Programación de retos</div>
                        <div class="col-3 title option">Fecha de carga</div>
                        <div class="col-5">&nbsp;</div>
                    </div>

                    <div class="my-3 row bg-white ">
                        <div class="col-4">Archivo de carga</div>
                        <div class="col-4">20/09/2023</div>
                        <div class="col-2 d-flex justify-content-end align-items-center">
                            <button type="button" class="btn btn-blue">Previsualizar</button>
                        </div>
                        <div class="col-2 d-flex justify-content-end align-items-center">
                            <button type="button" class="btn btn-amarillo">Guardar</button>
                        </div>
                    </div>

                </mat-expansion-panel>
                <mat-expansion-panel class="py-3 px-3">
                    <!-- Resultados Vinculatec -->
                    <mat-expansion-panel-header>
                        <div class="row">
                            <span class="title-option">
                                Resultados Vinculatec
                            </span>
                        </div>
                    </mat-expansion-panel-header>

                    <div class="row">
                        <div class="col-4 title-option">Programación de retos</div>
                        <div class="col-3 title option">Fecha de carga</div>
                        <div class="col-5">&nbsp;</div>
                    </div>

                    <div class="my-3 row bg-white " *ngFor="let file of archivosVinculatecACData">
                        <div class="col-4">Archivo de carga</div>
                        <div class="col-4">{{ file.fechahRegistro }} - {{ file.estatus }}</div>
                        <div class="col-2 d-flex justify-content-end align-items-center">
                            <button type="button" class="btn btn-blue">Previsualizar</button>
                        </div>
                        <div class="col-2 d-flex justify-content-end align-items-center">
                            <button type="button" class="btn btn-amarillo">Guardar</button>
                        </div>
                    </div>


                </mat-expansion-panel>
            </mat-accordion>

        </div>

    </div>

    <ng-template #modal_prev let-c="dismiss">
        <div class="modal-body">
            <p> El Archivo tiene el siguiente resumen. </p>
        </div>

        <div class="overflow-auto" style="height: 500px;">
            <div class="my-1 mx-2 row " *ngFor="let item of resumenData">
                <span class="col-7">{{ item.descripcion }}</span>
                <span class="col-5">{{ item.valor }}</span>
            </div>
        </div>

        <div class="modal-footer">
            <button type="button" class="btn btn-brown" (click)="c('dismiss')">Cerrar</button>
        </div>
    </ng-template>



</div>
