import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  FormArray,
} from "@angular/forms";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import {
  IAlcance,
  IArea,
  ICampus,
  IEscuela,
  IPeriodo,
  IProgramaAcademico,
} from "@shared/Interface/ICatalogos.interface";
import { CatalogosService } from "@shared/service/Catalogos/catalogos.service";
import { tap } from "rxjs/operators";
import { ActivatedRoute, Router } from "@angular/router";
import { ProblematicasService } from "@shared/service/Problematicas/problematicas.service";
import { IProblematica } from "@shared/Interface/IProblematicas";
import { ToastrService } from "ngx-toastr";
import { SolicitudesService } from "@shared/service/Solicitudes/solicitudes.service";
import { lastValueFrom } from "rxjs";
import { UsuariosService } from "@shared/service/Usuarios/usuarios.service";

@Component({
  selector: "app-registro-problematica",
  templateUrl: "./registro-problematica.component.html",
  styleUrls: ["./registro-problematica.component.css"],
})
export class RegistroProblematicaComponent implements OnInit {
  problematicaForm: FormGroup;
  idProblematica: string | null;
  validationErrors: string[] = [];
  areaData: IArea[] = [];
  campusData: ICampus[] = [];
  campusSelectedData: any=[];
  campusReto: number;
  alcanceData: any = [];
  escuelasData: IEscuela[] = [];
  programasAcademicosData: IProgramaAcademico[] = [];
  // periodoData: IPeriodo[] = [];
  semestreData = [1, 2, 3, 4, 5, 6, 7, 8];

  titleRegistroProblematica = {
    title: "Registro de Problemática",
  };
  closeResult: string;
  seccion1: ICampus[];
  seccion2: ICampus[];
  pedirListaCampus = false;

  periodoAcademicoData: IPeriodo[];
  pedirPeriodoAcademico = false;
  periodoAcademicoSeleccionado: number;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private catalogosService: CatalogosService,
    private modalService: NgbModal,
    private problematicasService: ProblematicasService,
    private toastr: ToastrService,
    private solicitudesService: SolicitudesService,
    private usuariosService: UsuariosService,
  ) {}

  ngOnInit(): void {
    this.initializeForm(null);
    this.idProblematica = this.route.snapshot.paramMap.get("idProblematica");
    if (!this.idProblematica) this.router.navigate(["vinculacion-reto"]);

    this.problematicasService
      .getProblematica(this.idProblematica)
      .pipe(
        tap((resultado: IProblematica) => {
          this.initializeForm(resultado);
        })
      )
      .subscribe();

    this.catalogosService
      .getAreas()
      .pipe(tap((resultado: IArea[]) => (this.areaData = resultado)))
      .subscribe();

    this.catalogosService
      .getAlcance()
      .pipe(tap((resultado: IAlcance[]) => (this.alcanceData = resultado)))
      .subscribe();

    this.catalogosService
      .getEscuelas()
      .pipe(tap((resultado: IEscuela[]) => (this.escuelasData = resultado)))
      .subscribe();

    this.catalogosService
      .getCampus()
      .pipe(tap((resultado: ICampus[]) => {
        this.campusData = resultado

      if (this.campusData) {
        let halfItems = Math.round(this.campusData.length / 2);
        this.seccion1 = this.campusData.slice(0, halfItems);
        this.seccion2 = this.campusData.slice(halfItems);
      }
      }))
      .subscribe();

      // Cargar Periodos   AGO-DIC 2023
    this.catalogosService
      .getPeriodo()
      .pipe(
        tap(
          (resultado: IPeriodo[]) =>
            (this.periodoAcademicoData = resultado)
        )
      )
      .subscribe();


  }

  getProgramasAcademicos(idEscuela: number) {
    this.catalogosService
      .getProgramasAcademicos(idEscuela)
      .pipe(
        tap(
          (resultado: IProgramaAcademico[]) =>
            (this.programasAcademicosData = resultado)
        )
      )
      .subscribe();
  }

  onSelectEscuela(event: Event) {
    const idEscuela = parseInt((event.target as HTMLInputElement).value);
    this.getProgramasAcademicos(idEscuela);
  }

  initializeForm(problematica: IProblematica | null) {
    if (problematica?.idEscuela) {
      this.getProgramasAcademicos(problematica?.idEscuela);
    }
    const areas = this.extractIds(problematica?.areas, "idArea") ?? [];
    const programas =
      this.extractIds(
        problematica?.programasAcademicos,
        "idProgramaAcademico"
      ) ?? [];

    this.problematicaForm = this.fb.group({
      proyecto: [problematica?.proyecto ?? "",          Validators.required],
      problematica: [problematica?.problematica ?? "",  Validators.required],
      objetivos: [problematica?.objetivos ?? "",        Validators.required],
      entregables: [problematica?.entregables ?? ""],
      areas: this.fb.array(areas),
      campus: [],
      campusReto: [],
      otraArea: [problematica?.otraArea ?? ""],
      idAlcance: [problematica?.idAlcance ?? "",        Validators.required],
      idEscuela: [
        problematica?.idEscuela !== 0 ? problematica?.idEscuela : "",
                                                        Validators.required,
      ],
      programasAcademicos: this.fb.array(programas),
      semestre: [
        problematica?.semestre !== 0 ? problematica?.semestre : "",
                                                        Validators.required,
      ],
      idPeriodo: [problematica?.idPeriodo,Validators.required]
    });


  }

  verifyChecked(id: string | number, inputForm: string) {
    const areas = this.problematicaForm.get(inputForm)?.value;
    if (typeof areas === "object") {
      return areas.includes(id);
    } else {
      return areas === id;
    }
  }

  verifySelected(id: string | number, inputForm: string) {
    return this.problematicaForm.get(inputForm)?.value === id ? true : false;
  }

  extractIds(data: any, id: string) {
    if (data) {
      return Object.values(data).map((value: any) => value[id]);
    }
    return ;
  }

  extractCampus(data: any) {
    if (data) {
      return [data[0].idCampus];
    }
    return ;
  }

  registroProblematica2_4() {
    this.problematicaForm.markAllAsTouched();
    if (this.problematicaForm.valid) {
      let campus:number[]=[];
      campus.push(this.problematicaForm.get("campus")?.value);

      this.problematicasService
        .putProblematica({
          idProblematica: this.idProblematica,
          proyecto: this.problematicaForm.get("proyecto")?.value,
          problematica: this.problematicaForm.get("problematica")?.value,
          objetivos: this.problematicaForm.get("objetivos")?.value,
          entregables: "",
          areas: this.problematicaForm.get("areas")?.value,
          campus: campus,
          otraArea: this.problematicaForm.get("otraArea")?.value,
          idAlcance: this.problematicaForm.get("idAlcance")?.value,
          idEscuela: this.problematicaForm.get("idEscuela")?.value,
          programasAcademicos: this.problematicaForm.get("programasAcademicos")
            ?.value,
          semestre: this.problematicaForm.get("semestre")?.value,
          idPeriodo: this.problematicaForm.get("idPeriodo")?.value,
          accion: 'solicitud'
        })
        .subscribe((resultado: any) => {
          if (resultado) {
            this.toastr.success("Registrada Correctamente!");
            this.router.navigate(["dashboard"]);
          } else {
            this.toastr.error("Algo salio mal!");
          }
        });
    } else {
      this.toastr.error("Todos los campos son obligatorios!");
    }
  }

  registroProblematica() {
    this.problematicaForm.markAllAsTouched();
    if (this.problematicaForm.valid) {
      let aCampus: number[] = [];
      aCampus = this.campusSelectedData.map(x =>x.idCampus);

      this.problematicasService
        .putProblematica({
          idProblematica: this.idProblematica,
          proyecto: this.problematicaForm.get("proyecto")?.value,
          problematica: this.problematicaForm.get("problematica")?.value,
          objetivos: this.problematicaForm.get("objetivos")?.value,
          entregables: "",
          areas: this.problematicaForm.get("areas")?.value,
          campusSeleccionados: this.problematicaForm.get("campusReto")?.value,
          campus: aCampus,
          otraArea: this.problematicaForm.get("otraArea")?.value,
          idAlcance: this.problematicaForm.get("idAlcance")?.value,
          idEscuela: this.problematicaForm.get("idEscuela")?.value,
          programasAcademicos: this.problematicaForm.get("programasAcademicos")
            ?.value,
          semestre: this.problematicaForm.get("semestre")?.value,
          idPeriodo: this.problematicaForm.get("idPeriodo")?.value,
          idCampusCoordinador: this.problematicaForm.get("campusReto")?.value,
          accion: 'solicitud'
        })
        .subscribe(async (resultado: any) => {
          if (resultado) {
            //let problematicaTmp = await lastValueFrom(this.problematicasService.getProblematica(resultado.idProblematica));

            let idLogBusquedaReto = sessionStorage.getItem('idLogBusquedaReto') ?? 0;

            this.solicitudesService.putSolicitud({
              idSolicitud: resultado.idSolicitud,
              idProblematica: resultado.idProblematica,
              idUsuario: this.usuariosService.getCurrentUserData().idUsuario,
              idLogBusquedaReto: Number(idLogBusquedaReto)
              }).subscribe();

            sessionStorage.removeItem('idLogBusquedaReto');

            this.toastr.success("Registrada Correctamente!");
            this.router.navigate(["dashboard"]);
          } else {
            this.toastr.error("Algo salio mal!");
          }
        });
    } else {
      this.toastr.error("Todos los campos son obligatorios!");
    }
  }

  showModal(content: any) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }


  onCheckboxChangeCampus(event: any) {
    if (event.target.checked) {
      const obj = this.campusData.find((x) => {
        return x.idCampus === +event.target.value;
      });
      this.campusSelectedData.push(obj!);
    } else {
      const index = this.campusSelectedData.findIndex(
        (x) => x.idCampus === +event.target.value
      );
      this.campusSelectedData.splice(index, 1);
    }

  }


  onCheckboxChange(event: any, typeData: string) {
    const selectedAreas = this.problematicaForm.controls[typeData] as FormArray;
    if (event.target.checked) {
      selectedAreas.push(new FormControl(event.target.value));
      if(typeData === 'campus'){
        this.campusSelectedData.push(this.campusData.find(x=>x.idCampus == event.target.value));
      }
    } else {
      const index = selectedAreas.value.findIndex(
        (x: number) => x === parseInt(event.target.value)
      );
      selectedAreas.removeAt(index);
      if(typeData === 'campus'){
        const idx = this.campusSelectedData.findIndex(x=> x.idCampus == event.target.value);
        this.campusSelectedData.splice(idx,1);
      }
    }

  }

  seleccionarPeriodo(event: any) {
    this.periodoAcademicoSeleccionado = event.target.value;
  }
}
