import {
  Component,
  OnInit,
  ChangeDetectorRef,
  AfterViewInit,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  FormArray,
} from '@angular/forms';
import { Router } from '@angular/router';
import {
  CUnidadFormativa,
  IAlcance,
  ICampus,
  IEscuela,
  IGiro,
  IIdiomas,
  IIndustria,
  IPeriodo,
  IRegion,
  ISector,
  ISemana,
  ITamanoEmpresa,
} from '@shared/Interface/ICatalogos.interface';
import { CatalogosService } from '@shared/service/Catalogos/catalogos.service';
import { RetosService } from '@shared/service/Retos/retos.service';
import { UsuariosService } from '@shared/service/Usuarios/usuarios.service';
import { tap } from 'rxjs';
import { IProgramaAcademico } from '../../shared/Interface/ICatalogos.interface';
import { IRetoMaestro } from '../../shared/Interface/IReto.interface';
import { ToastrService } from 'ngx-toastr';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectConfig } from '@ng-select/ng-select';
import {
  ValidateBoth,
  ValidateMaestro,
  ValidateProgramado,
} from './registro-reto.validator';
import Swal from 'sweetalert2';
import Utils from '@shared/helpers/utils';


@Component({
  selector: 'app-registra-reto',
  templateUrl: './registra-reto.component.html',
  styleUrls: ['./registra-reto.component.css'],
})
export class RegistraRetoComponent implements OnInit, AfterViewInit {
  retoForm: FormGroup;
  validationErrors: string[] = [];
  retosMaestrosData: IRetoMaestro[];
  escuelaData: any;
  programaAcademicoData: IProgramaAcademico[];
  periodoAcademicoData: IPeriodo[];
  campusData!: ICampus[];
  semestreData = [1, 2, 3, 4, 5, 6, 7, 8];
  regionesData: IRegion[];
  idiomasData: IIdiomas[];
  alcanceData!: IAlcance[];
  ubicacionData: any;
  sectoresData: ISector[];
  industriasData: IIndustria[];
  tamanioEmpresasData: ITamanoEmpresa[];
  unidadFormativaData: CUnidadFormativa[];
  semanasData: ISemana[];
  giroData: IGiro[];
  buttonSave = {
    title: 'Registrar este reto programado',
    isLoading: false,
  };
  isUpdate = false;
  retoToUpdate: any;
  retoInfo = {
    reto: 'Análisis de Negocios',
    descripcion:
      'Determinar costos de cada una de las fuentes de financiamiento del Socio Formador y posteriormente el costo de capital promedio ponderado. El reto se trabaja con los estados financieros y si es factible, con entrevistas con directivos del Socio Formador bajo la guía del profesor.',
    objetivo:
      'Determinar costos de cada una de las fuentes de financiamiento del Socio Formador y posteriormente el costo de capital promedio ponderado.',
    escuela: 'Escuela de Negocios',
    departamento: 'Cultura Organizacional.',
    unidadFormativa: 'Cultura Financiera.',
    alcance: 'local',
    campus: 'Monterrey',
    generalInfoIcons: [
      {
        icon: 'icon_local',
        title: 'Local',
      },
      {
        icon: 'icon_nacional',
        title: 'Nacional',
      },
      {
        icon: 'icon_language',
        title: 'Espanol',
      },
      {
        icon: 'icon_semestre',
        title: '4 Semestre',
      },
      {
        icon: 'icon_season',
        title: 'Agosto-Noviembre',
      },
      {
        icon: 'icon_calendar',
        title: 'Semanas',
      },
      {
        icon: 'icon_lang',
        title: 'Espanñol',
      },
    ],
  };
  retoMaestro: any;

  titleRegistroReto = {
    title: `${this.isUpdate ? 'Actualizar' : 'Programar'} un reto`,
  };
  esSemilla: boolean = false;
  mostrarObligatorios: boolean = false;

  constructor(
    private catalogosService: CatalogosService,
    private usuariosService: UsuariosService,
    private retosService: RetosService,
    private router: Router,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private retosServices: RetosService,
    private config: NgSelectConfig,
    private cd: ChangeDetectorRef
  ) {}

  ngAfterViewInit(): void {
    this.cd.detectChanges();
  }

  checkUpdate() {
    const retoStored: any = localStorage.getItem('newReto');
    if (!retoStored) return;

    this.retoToUpdate = JSON.parse(retoStored);

    if (this.retoToUpdate && this.retoToUpdate.idReto) {
      this.isUpdate = true;
      this.getReto(this.retoToUpdate.idReto);
    } else {
      this.isUpdate = true;
      this.getRetoMaestro(this.retoToUpdate.idRetoMaestro);
    }
    this.updateTitle();
  }

  ngOnInit(): void {
    this.initializeForm(null);
    this.checkUpdate();
    const userStore = this.usuariosService.getCurrentUserData();
    if (!userStore) this.router.navigate(['/']);

    this.getEscuela(userStore.idEscuela);
    this.getRetosMaestros(userStore.idEscuela);
    this.getProgramasAcademicos(userStore.idEscuela);
    this.getUnidadFormativa(userStore.idEscuela);

    this.catalogosService
      .getAlcance()
      .pipe(tap((resultado: IAlcance[]) => (this.alcanceData = resultado)))
      .subscribe();

    this.catalogosService
      .getRegiones()
      .pipe(tap((resultado: IRegion[]) => (this.regionesData = resultado)))
      .subscribe();

    this.catalogosService
      .getPeriodo()
      .pipe(
        tap((resultado: IPeriodo[]) => (this.periodoAcademicoData = resultado))
      )
      .subscribe();

    this.catalogosService
      .getIdiomas()
      .pipe(tap((resultado: IIdiomas[]) => (this.idiomasData = resultado)))
      .subscribe();

    this.catalogosService
      .getGiro()
      .pipe(tap((resultado: IGiro[]) => (this.giroData = resultado)))
      .subscribe();

    this.catalogosService
      .getSectoresR()
      .pipe(tap((resultado: ISector[]) => (this.sectoresData = resultado)))
      .subscribe();

    this.catalogosService
      .getIndustriasR()
      .pipe(tap((resultado: IIndustria[]) => (this.industriasData = resultado)))
      .subscribe();

    this.catalogosService
      .getTamanoEmpresa()
      .pipe(
        tap(
          (resultado: ITamanoEmpresa[]) =>
            (this.tamanioEmpresasData = resultado)
        )
      )
      .subscribe();

    this.catalogosService
      .getSemanas()
      .pipe(tap((resultado: ISemana[]) => (this.semanasData = resultado)))
      .subscribe();

    this.catalogosService
      .getUnidadFormativa(userStore.idEscuela)
      .pipe(
        tap(
          (resultado: CUnidadFormativa[]) =>
            (this.unidadFormativaData = resultado)
        )
      )
      .subscribe();
  }

  fromModel(value: string): NgbDateStruct | null {
    if (!value) return null;
    let parts = value.split('-');
    return { year: +parts[0], month: +parts[1], day: +parts[2] };
  }

  initializeForm(dataReto: any) {
    let giros = dataReto?.giros ?? [];
    let campus = dataReto?.campus ?? [];
    giros = this.extractIds(giros, 'idGiro') ?? [];
    campus = this.extractIds(campus, 'idCampus') ?? [];
    let fechaInicio = dataReto?.fechaInicio ?? null;
    let fechaFin = dataReto?.fechaFin ?? null;

    if (fechaInicio) {
      fechaInicio = this.fromModel(fechaInicio);
    }
    if (fechaFin) {
      fechaFin = this.fromModel(fechaFin);
    }

    this.retoForm = this.fb.group({
      semilla: this.esSemilla,
      idRetoMaestro: [dataReto?.idRetoMaestro ?? '', ValidateProgramado],
      idEscuela: [dataReto?.idEscuela ?? 0, ValidateBoth],
      idProgramaAcademico: [dataReto?.idProgramaAcademico ?? 0, ValidateBoth],
      idUnidadFormativa: [dataReto?.idUnidadFormativa ?? 0, ValidateBoth],
      idPeriodo: [dataReto?.idPeriodo ?? 0, ValidateProgramado],
      idSemanas: [dataReto?.idSemanas ?? 0, ValidateMaestro],
      fechaInicio: [fechaInicio ?? null, ValidateProgramado],
      fechaFin: [fechaFin ?? null, ValidateProgramado],
      idIdioma: [dataReto?.idIdioma ?? 0, ValidateBoth],
      semestre: [dataReto?.semestre ?? 0, ValidateBoth],
      idAlcance: [dataReto?.idAlcance ?? 0, ValidateBoth],
      idRegion: [dataReto?.idRegion ?? 0, ValidateProgramado],
      campus: this.fb.array(campus ?? [], [ValidateProgramado]),

      reto: [dataReto?.reto ?? '', ValidateBoth],
      descripcion: [dataReto?.descripcion ?? '', ValidateBoth],
      entregable: [dataReto?.entregable ?? '', ValidateBoth],
      idSector: [dataReto?.idSector ?? 0, ValidateBoth],
      idIndustria: [dataReto?.idIndustria ?? 0, ValidateBoth],
      idTamano: [dataReto?.idTamano ?? 0, ValidateBoth],
      empresaFamiliar: [dataReto?.empresaFamiliar ?? false],
      giros: this.fb.array(giros ?? [], ValidateBoth),
    });

    if (this.isUpdate && !this.retoToUpdate.idReto) {
      this.retoForm.patchValue({ semilla: true });
    }
  }

  actualizarCampus(event: any) {
    this.campusData = [];
    if (event.target.value !== '') {
      this.catalogosService
        .getCampusPorRegion(event.target.value)
        .subscribe((resOK) => {

          this.campusData = resOK;
        });
    }
  }

  extractIds(data: any, id: string) {
    if (data) {
      return Object.values(data).map((value: any) => value[id]);
    }
    return "";
  }

  onCheckboxChange(event: any, typeData: string) {
    const selectedAreas = this.retoForm.controls[typeData] as FormArray;
    if (event.target.checked) {
      selectedAreas.push(new FormControl(parseInt(event.target.value)));
    } else {
      const index = selectedAreas.controls.findIndex(
        (x) => x.value === event.target.value
      );
      selectedAreas.removeAt(index);
    }
  }

  dateFormat(date: NgbDateStruct): string {
    // from internal model -> your mode
    return date
      ? date.year +
          '-' +
          ('0' + date.month).slice(-2) +
          '-' +
          ('0' + date.day).slice(-2)
      : '';
  }

  createRetoSemilla(userStore: any) {
    return {
      idRetoMaestro: this.isUpdate ? this.retoToUpdate.idRetoMaestro : 0,
      reto: this.retoForm.value.reto,
      descripcion: this.retoForm.value.descripcion,
      entregable: this.retoForm.value.entregable,
      idUsuarioTec: userStore.idUsuario,
      idEscuela: this.retoForm.value.idEscuela,
      idUnidadFormativa: this.retoForm.value.idUnidadFormativa,
      semestre: this.retoForm.value.semestre,
      idRegion: this.retoForm.value.idRegion,
      idIdioma: this.retoForm.value.idIdioma,
      empresaFamiliar: this.retoForm.value.empresaFamiliar,
      idSector: this.retoForm.value.idSector,
      idIndustria: this.retoForm.value.idIndustria,
      idTamano: this.retoForm.value.idTamano,
      palabrasClave: '',
      idSemanas: this.retoForm.value.idSemanas,
      idProgramaAcademico: this.retoForm.value.idProgramaAcademico,
      idAlcance: this.retoForm.value.idAlcance,
      giros: this.retoForm.value.giros,
    };
  }

  createRetoMaestro(userStore: any) {
    return {
      idRetoMaestro: this.retoForm.value.idRetoMaestro,
      reto: this.retoForm.value.reto,
      descripcion: this.retoForm.value.descripcion,
      entregable: this.retoForm.value.entregable,
      idUsuarioTec: userStore.idUsuario,
      idEscuela: this.retoForm.value.idEscuela,
      idUnidadFormativa: this.retoForm.value.idUnidadFormativa,
      semestre: this.retoForm.value.semestre,
      idRegion: this.retoForm.value.idRegion,
      idIdioma: this.retoForm.value.idIdioma,
      empresaFamiliar: this.retoForm.value.empresaFamiliar,
      fechaInicio: this.dateFormat(this.retoForm.value.fechaInicio),
      fechaFin: this.dateFormat(this.retoForm.value.fechaFin),
      idSector: this.retoForm.value.idSector,
      idIndustria: this.retoForm.value.idIndustria,
      idTamano: this.retoForm.value.idTamano,
      palabrasClave: '',
      idPeriodo: this.retoForm.value.idPeriodo,
      idSemanas: this.retoForm.value.idSemanas,
      idProgramaAcademico: this.retoForm.value.idProgramaAcademico,
      idAlcance: this.retoForm.value.idAlcance,
      campus: this.retoForm.value.campus,
      giros: this.retoForm.value.giros,
    };
  }

  formatDate(fecha) {
    return (
      fecha.year +
      '-' +
      leftPad(fecha.month + '', 2) +
      '-' +
      leftPad(fecha.day + '', 2)
    );
  }

  validaFechasPeriodoAcademico(periodo) {

    let fini = this.retoForm.get('fechaInicio')?.value;
    let ffin = this.retoForm.get('fechaFin')?.value;
    let lerror: string[] = [];
    // Validamos que las fechas sean coherentes
    if (fini > ffin) {
      lerror.push('La fecha de inicio no puede ser mayor a la fecha fin.');
    }
    // Validamos que esten dentro del periodo
    if (
      this.formatDate(fini) < periodo.fechaInicio ||
      this.formatDate(fini) > periodo.fechaFin
    ) {
      lerror.push(`Fecha de inicio ${this.formatDate(fini)} incorrecta.`);
    }
    if (
      this.formatDate(ffin) < periodo.fechaInicio ||
      this.formatDate(ffin) > periodo.fechaFin
    ) {
      lerror.push(`Fecha de fin  ${this.formatDate(ffin)} incorrecta.`);
    }
    return lerror;
  }

  saveReto() {
    const userStore = this.usuariosService.getCurrentUserData();
    let body = {};
    let fechasValidas = false;

    if (this.retoForm.value.semilla) {
      body = this.createRetoSemilla(userStore);
      fechasValidas = true;
    } else {
      body = this.createRetoMaestro(userStore);
      let idPeriodoSeleccionado = this.retoForm.get('idPeriodo')?.value;
      let periodo = this.periodoAcademicoData.find(
        (x) => x.idPeriodo === parseInt(idPeriodoSeleccionado)
      );
      if (periodo != undefined) {
        let lerror = this.validaFechasPeriodoAcademico(periodo);
        if (lerror.length > 0) {
          Swal.fire({
            icon: 'warning',
            html: `Revisa las fechas, deben estar dentro del periodo. <br/><br/> <strong>${
              periodo.periodo
            } [${periodo.fechaInicio} / ${periodo.fechaFin}]
            </strong><br/><br/> <ul style="text-align:left;"><li>${lerror.join(
              '</li><li>'
            )}</ul> `,
          });
        } else {
          fechasValidas = true;
        }
      }
    }

    if (this.retoForm.valid && fechasValidas) {
      if (!this.isUpdate) {
        this.retosService.postRetos(body).subscribe({
          next: (resultado: any) => {
            if (resultado) {
              localStorage.setItem('newReto', JSON.stringify(resultado));
              this.toastr.success('Registrada Correctamente!');
              this.router.navigate(['reto-resumen']);
            } else {
              this.toastr.error('Algo salió mal!');
            }
          },
          error: (error) => {
            Swal.fire({
              icon: 'error',
              text: 'Error en la solicitud, intente más tarde',
            });
          }
        });
      } else {
        this.retosService.putRetos(body).subscribe({
          next: (resultado: any) => {
            if (resultado) {
              localStorage.setItem('newReto', JSON.stringify(resultado));
              this.toastr.success('Registrada Correctamente!');
              this.router.navigate(['reto-resumen']);
            } else {
              this.toastr.error('Algo salió mal!');
            }
          },
          error: (error) => {
            Swal.fire({
              icon: 'error',
              text: 'Error en la solicitud, intente más tarde.',
            });
          }
        });
      }
    } else {
      this.toastr.error('Todos los campos son obligatorios!');

      this.mostrarObligatorios = true;

      //this.debugCamposValidar();
    }
  }
  debugCamposValidar() {
    Object.keys(this.retoForm.controls).forEach((key) => {
      const controlErrors: any = this.retoForm?.get(key)?.errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach((keyError) => {
          console.log(
            'Key control: ' + key + ', keyError: ' + keyError + ', err value: ',
            controlErrors[keyError]
          );
        });
      }
    });
  }

  clickToCheck(typeCheck: string) {
    return true;
  }

  getRetosMaestros(idEscuela: number) {
    this.retosService
      .getRetosMaestros(idEscuela)
      .pipe(
        tap((resultado: IRetoMaestro[]) => (this.retosMaestrosData = resultado))
      )
      .subscribe();
  }

  getProgramasAcademicos(idEscuela: number) {
    this.catalogosService
      .getProgramasAcademicos(idEscuela)
      .pipe(
        tap(
          (resultado: IProgramaAcademico[]) =>
            (this.programaAcademicoData = resultado)
        )
      )
      .subscribe();
  }

  updateProgramasAcademicos(event) {
    this.programaAcademicoData = [];

    if (Utils.isNumber(event.target.value)) {
      this.getProgramasAcademicos(event.target.value);
      this.getUnidadFormativa(event.target.value);
    }
  }

  getUnidadFormativa(idEscuela: number) {
    this.catalogosService
      .getUnidadFormativa(idEscuela)
      .subscribe({
        next: (resultado: CUnidadFormativa[]) => {this.unidadFormativaData = resultado;},
        error:(error) => {
          Swal.fire({
            icon:'info',
            text:'No hay Unidades Formativas asociados'
          })
        }});
  }

  // updateUnidadFormativa(event){
  //   this.unidadFormativaData = [];
  //   if(Utils.isNumber(event.tarde.value)){
  //     this.getUnidadFormativa(event.tarde.value);
  //   }
  // }

  getEscuela(idEscuela: number) {
    this.catalogosService
      .getEscuelas(idEscuela)
      .pipe(
        tap((resultado: IEscuela[]) => {
          this.escuelaData = [resultado];
        })
      )
      .subscribe();
  }

  getReto(idReto: any) {
    this.retosServices
      .getRetos_idReto(idReto)
      .pipe(tap((response) => this.initializeForm(response)))
      .subscribe();
  }

  getRetoMaestro(idRetoMaestro: any) {
    this.retosServices
      .getRetoMaestroDetail(idRetoMaestro)
      .pipe(tap((response) => this.initializeForm(response)))
      .subscribe();
  }

  verifySelected(id: string | number, inputForm: string) {
    return this.retoForm.get(inputForm)?.value === id ? true : null;
  }


  verifyChecked(id: string | number, inputForm: string) {
    const areas = this.retoForm.get(inputForm)?.value;
    if (typeof areas === 'object') {
      return areas.includes(id);
    } else {
      return areas === id;
    }
  }

  cleanText(textToClean: string) {
    return textToClean
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
  }

  getRetoDetail(idRetoMaestro) {

    if (!idRetoMaestro) return;

    idRetoMaestro &&
      this.retosService
        .getRetoMaestroDetail(idRetoMaestro)
        .pipe(
          tap((resultado: IRetoMaestro) => {
            this.initializeForm(resultado);
            this.retoMaestro = resultado;
          })
        )
        .subscribe();
  }

  updateTitle() {
    if (this.retoForm.value.semilla) {
      this.titleRegistroReto = {
        title: `${this.isUpdate ? 'Actualizar' : 'Programar'} un reto semilla`,
      };
      this.esSemilla = true;
      document.getElementById('idEscuela')?.focus();
    } else {
      this.titleRegistroReto = {
        title: `${this.isUpdate ? 'Actualizar' : 'Programar'} un reto`,
      };
      this.esSemilla = false;
      document.getElementById('idEscuela')?.focus();
    }

    this.actualizaValidaciones();
  }

  actualizaValidaciones() {
    this.retoForm.get('idRetoMaestro')?.updateValueAndValidity();
    this.retoForm.get('idEscuela')?.updateValueAndValidity();
    this.retoForm.get('idProgramaAcademico')?.updateValueAndValidity();
    this.retoForm.get('idUnidadFormativa')?.updateValueAndValidity();
    this.retoForm.get('idPeriodo')?.updateValueAndValidity();
    this.retoForm.get('idSemanas')?.updateValueAndValidity();
    this.retoForm.get('fechaInicio')?.updateValueAndValidity();
    this.retoForm.get('fechaFin')?.updateValueAndValidity();
    this.retoForm.get('idIdioma')?.updateValueAndValidity();
    this.retoForm.get('semestre')?.updateValueAndValidity();
    this.retoForm.get('idAlcance')?.updateValueAndValidity();
    this.retoForm.get('idRegion')?.updateValueAndValidity();
    this.retoForm.get('campus')?.updateValueAndValidity();
    this.retoForm.get('reto')?.updateValueAndValidity();
    this.retoForm.get('descripcion')?.updateValueAndValidity();
    this.retoForm.get('entregable')?.updateValueAndValidity();
    this.retoForm.get('idSector')?.updateValueAndValidity();
    this.retoForm.get('idIndustria')?.updateValueAndValidity();
    this.retoForm.get('idTamano')?.updateValueAndValidity();
    this.retoForm.get('empresaFamiliar')?.updateValueAndValidity();
    this.retoForm.get('giros')?.updateValueAndValidity();
  }
}
function leftPad(str: string, len: number, ch = '0'): string {
  len = len - str.length + 1;
  return len > 0 ? new Array(len).join(ch) + str : str;
}
