import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SolicitudesService } from '@shared/service/Solicitudes/solicitudes.service';
import { UsuariosService } from '@shared/service/Usuarios/usuarios.service';

interface Retos {
  campus: string;
  descripcion: string;
  idReto: number;
  matchScore: number;
  periodo: string;
  programaAcademico: string;
  reto: string;
  semestre: number;
  unidadFormativa: string;
}
interface RetosData extends Retos {
  groupsData?: Retos[];
}

@Component({
  selector: 'app-matches-socios',
  templateUrl: './matches-socios.component.html',
  styleUrls: ['./matches-socios.component.css'],
})
export class MatchesSociosComponent implements OnInit {
  titleMatchSocio = {
    title:
      'De acuerdo con tu información, los Socios Formadores ideales para vincular son:',
  };
  userData: any;
  matchData: any;
  retosInfo: any;
  objBotton: any = {
    title: '¿No es lo que estás buscando?',
    subtitle: '¿Tu problemática no se acopla  a los retos mostrados?',
    buttons: [
      {
        title: '+ Crear nueva vinculación',
        type: 'blue',
        action: () =>
          this.navigateProblematica(this.getMatchData().idProblematica),
      },
    ],
  };
  constructor(
    private router: Router,
    private solicitudesService: SolicitudesService,
    private usuariosService: UsuariosService
  ) {}

  getUserData() {
    this.userData = this.usuariosService.getCurrentUserData();
    if (!this.userData) this.router.navigate(['/']);
    return this.userData;
  }

  getMatchData() {
    const retos: any = localStorage.getItem('sociosMatch');
    if (!retos) this.router.navigate(['vincula-socio']);
    this.retosInfo = JSON.parse(retos);
    return this.retosInfo;
  }

  ngOnInit(): void {
    this.matchData = this.generateGroupsOf(this.getMatchData().sociosMatch);
  }

  generateGroupsOf(data: any) {
    const groups: any = [];
    let group: any = [];
    let index = 1;
    data.forEach((record: any, i: number) => {
      if (index % 3 === 0) {
        group.push(record);
        groups.push(group);
        group = [];
      } else {
        group.push(record);
      }
      if (data.length === index && group.length) {
        groups.push(group);
        group = [];
      }
      index++;
    });
    return groups;
  }

  joinVinculacion(matchObj: any) {

    localStorage.setItem('idSocioFormador', matchObj.idSocioFormador);
    localStorage.setItem('matchSelected', JSON.stringify(matchObj));
    localStorage.setItem('idReto', JSON.stringify(this.retosInfo.idReto))
    localStorage.setItem('origenVinculacion','match');
    this.router.navigate(['socio-formador']);

  }

  navigateProblematica(idProblematica: string) {
    this.router.navigate([`problematica/${idProblematica}`]);
  }


  irNoEcontreSocio()
  {

    localStorage.setItem('idReto', JSON.stringify(this.retosInfo.idReto))

    this.router.navigateByUrl('/academia-no-encontre-socio-formador');
  }
}
