<div class="row">
  <div class="col-md-12 d-flex justify-content-center">
    <span class="questioncard">{{ objBotton.title }}</span>
  </div>
  <div class="col-md-12 d-flex justify-content-center">
    <span class="subtitlequstcard">{{ objBotton.subtitle }}</span>
  </div>
</div>
<div class="row">
  <div class="col d-flex justify-content-center" *ngFor="let button of objBotton.buttons">
    <app-button buttonType="{{ button.type }}" extraClass="{{ button?.extraClass ?? 'float-end' }}" (callbackAction)="button.action()">
      {{ button.title }}
    </app-button>
  </div>
</div>