<app-header-buscador lbl_placeholder="Buscar un Reto" (fatherNameFunction)="buscadorPress($event)">
</app-header-buscador>
<div>

  <app-title-show-more [showMoreObject]="title"></app-title-show-more>


<div class="sec_tab">

  <div class="row">

    <!-- Filtro de Campus -->
    <div class="col-md">
      <div class="btn-group order-by">
        <button class="btn dropdown-toggle" type="button" id="listado" data-bs-toggle="dropdown"
          data-bs-auto-close="true" aria-expanded="false" data-bs-offset="0,0"
          title="{{ filter.campus ? getValueCatalogo(campusData, 'idCampus', filter.campus, 'campus') : 'Campus' }}">
          {{ filter.campus ? getValueCatalogo(campusData, 'idCampus', filter.campus, 'campus') : 'Campus' }}
        </button>
        <ul class="dropdown-menu dropdown-menu-start" aria-labelledby="listado">
          <li *ngIf="campusData.length == 0" class="dropdown-item">Selecciona un campus</li>
          <li *ngFor="let campus of campusData; let i = index">
            <a class="dropdown-item {{ i === 0 ? 'active' : '' }}" (click)="addFilter('campus', campus.idCampus)">{{
              campus.campus }}</a>
          </li>
        </ul>
      </div>
    </div>


    <!-- Filtro de escuela -->
    <div class="col-md">
      <div class="btn-group order-by">
        <button class="btn dropdown-toggle" type="button" id="listado" data-bs-toggle="dropdown"
          data-bs-auto-close="true" aria-expanded="false" data-bs-offset="0,0"
          title="{{ filter.escuela ? getValueCatalogo(escuelaData, 'idEscuela', filter.escuela, 'escuela') : 'Escuela' }}">
          {{ filter.escuela ? getValueCatalogo(escuelaData, 'idEscuela', filter.escuela, 'escuela') : 'Escuela' }}
        </button>
        <ul class="dropdown-menu dropdown-menu-start" aria-labelledby="listado">
          <li *ngFor="let escuela of escuelaData; let i = index">
            <a class="dropdown-item {{ i === 0 ? 'active' : '' }}" (click)="addFilter('escuela', escuela.idEscuela)">{{
              escuela.escuela }}</a>
          </li>
        </ul>
      </div>
    </div>

    <!-- Filtro de Periodo -->
    <div class="col-md">
      <div class="btn-group order-by">
        <button class="btn dropdown-toggle" type="button" id="listado" data-bs-toggle="dropdown"
          data-bs-auto-close="true" aria-expanded="false" data-bs-offset="0,0"
          title="{{ filter.periodo ? getValueCatalogo(periodoData, 'idPeriodo', filter.periodo, 'periodo') : 'Periodo' }}">
          {{ filter.periodo ? getValueCatalogo(periodoData, 'idPeriodo', filter.periodo, 'periodo') : 'Periodo' }}
        </button>
        <ul class="dropdown-menu dropdown-menu-start" aria-labelledby="listado">
          <li *ngFor="let periodo of periodoData; let i = index">
            <a class="dropdown-item {{ i === 0 ? 'active' : '' }}" (click)="addFilter('periodo', periodo.idPeriodo)">{{
              periodo.periodo }}</a>
          </li>
        </ul>
      </div>
    </div>


    <!-- Reestablecer Filtros -->
    <div class="col-md d-flex justify-content-end">
      <app-button (callbackAction)="cleanFilters()"> Restablecer </app-button>
    </div>
  </div>


  <div class="mt-2 pt-0 mb-0 pb-0">
    <ng-template matStepLabel>En formalización</ng-template>
    <div class="mt-5">
      <div class="nav nav-tabs d-inline" id="accordionExample">
        <div class="steps">

          <div class="step-item active" data-bs-toggle="tab" data-bs-target="#tab-formalizacion"
            (click)="changeViewStatus('formalizacion')">
            <progress [ngClass]="status != 'formalizacion'  ? 'progress-active' : 'progress-inactive'"
              id="progress-active" [value]="status != 'formalizacion'  ? 20 : 0" max=20></progress>
            <div class="titlestepActive">
              En formalización
            </div>
            <button class="step-button-active text-center">
              1
            </button>

          </div>
          <div class="step-item" data-bs-toggle="tab" data-bs-target="#tab-curso" (click)="changeViewStatus('curso')">
            <progress
              [ngClass]="status == 'evaluacion' || status == 'concluido' ? 'progress-active' : 'progress-inactive'"
              id="progress-active" [value]="status == 'evaluacion' || status == 'concluido'  ? 20 : 0"
              max=20></progress>
            <div [ngClass]="status != 'formalizacion'  ? 'titlestepActive' : 'titlestepInactive'">
              En curso
            </div>
            <button [ngClass]="status != 'formalizacion'  ? 'step-button-active' : 'step-button'" class="text-center">
              2
            </button>

          </div>

          <div class="step-item" data-bs-toggle="tab" data-bs-target="#tab-evaluacion"
            (click)="changeViewStatus('evaluacion')">
            <progress [ngClass]="status == 'concluido' ? 'progress-active' : 'progress-inactive'" id="progress-active"
              [value]="status == 'concluido'  ? 20 : 0" max=20></progress>
            <div [ngClass]="status == 'evaluacion' || status == 'concluido' ? 'titlestepActive' : 'titlestepInactive'">
              En evaluación
            </div>
            <button [ngClass]="status == 'evaluacion' || status == 'concluido'  ? 'step-button-active' : 'step-button'"
              class="text-center">
              3
            </button>

          </div>
          <div class="step-item" data-bs-toggle="tab" data-bs-target="#tab-concluido"
            (click)="changeViewStatus('concluido')">
            <div [ngClass]="status == 'concluido'  ? 'titlestepActive' : 'titlestepInactive'">
              Concluidos
            </div>
            <button [ngClass]="status == 'concluido'  ? 'step-button-active' : 'step-button'" class="text-center">
              4
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>



  <div class="tab-content" id="pills-tabContent">
    <div class="tab-pane fade show active" id="tab-formalizacion" role="tabpanel"
      aria-labelledby="tab-reto-activos-tab">
      <app-table [backgroundColor]="backgroundTable" [columns]="listadoRetosFormalizacionColumns"
        [data]="listadoRetosFormalizacionDataPage"></app-table>

      <app-pagination *ngIf="listadoRetosFormalizacionData.length > 0" [objPagination]="listadoRetosFormalizacionData"
        (paginationDataChange)="showPaginationDataFormalizacion($event)"></app-pagination>

    </div>
    <div class="tab-pane fade" id="tab-curso" role="tabpanel" aria-labelledby="tab-reto-historial-tab">
      <app-table [backgroundColor]="backgroundTable" [columns]="listadoRetosCursoColumns"
        [data]="listadoRetosCursoDataPage"></app-table>

      <app-pagination *ngIf="listadoRetosCursoData.length" [objPagination]="listadoRetosCursoData"
        (paginationDataChange)="showPaginationDataCurso($event)"></app-pagination>

    </div>
    <div class="tab-pane fade" id="tab-evaluacion" role="tabpanel" aria-labelledby="tab-reto-historial-tab">
      <app-table [backgroundColor]="backgroundTable" [columns]="listadoRetosEvaluacionColumns"
        [data]="listadoRetosEvaluacionDataPage"></app-table>

      <app-pagination *ngIf="listadoRetosEvaluacionData.length" [objPagination]="listadoRetosEvaluacionData"
        (paginationDataChange)="showPaginationDataEvaluacion($event)"></app-pagination>

    </div>
    <div class="tab-pane fade" id="tab-concluido" role="tabpanel" aria-labelledby="tab-reto-historial-tab">
      <app-table [backgroundColor]="backgroundTable" [columns]="listadoRetosConcluidoColumns"
        [data]="listadoRetosConcluidoDataPage"></app-table>

      <app-pagination *ngIf="listadoRetosConcluidoData.length" [objPagination]="listadoRetosConcluidoData"
        (paginationDataChange)="showPaginationDataConcluido($event)"></app-pagination>

    </div>

  </div>

