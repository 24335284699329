import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ISidebarResponse } from '@shared/Interface/ISidebarResponse';
import { environment } from 'environments/environment';
import { BehaviorSubject, map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InicioService {

  private menuoption = new BehaviorSubject<string>("dashboard");
  currentMenuOption = this.menuoption.asObservable();

  private subMenuOption = new BehaviorSubject<string>("miperfil");
  currentSubMenuOption = this.subMenuOption.asObservable();

  baseUrl = environment.apiUrl;

  private filterQuery = new BehaviorSubject<string>("");
  currentFilterQuery = this.filterQuery.asObservable();

  constructor(private http: HttpClient) { }

  changeMenuItem(menuItem: string){
    this.flushLocalStorage();
    this.menuoption.next(menuItem);
  }

  changeSubMenuItem(subMenuItem: string){
    this.subMenuOption.next(subMenuItem);
  }

  changeFilterQuery(filterQuery: string){
    this.filterQuery.next(filterQuery);
  }

  /**
   * Api que obtiene las funciones del sidebar por el perfil del usuario
   * @param clavePerfil tipo de dato string que identifica a la clave del perfil
   * @returns Objeto tipo ISidebarPerfil
   */
  getSidebarPerfil(clavePerfil: any ):Observable<ISidebarResponse> {
    return this.http.get<ISidebarResponse>(this.baseUrl + "inicio/"+clavePerfil+"/sidebar");
  }

  getSidebar_Perfil(clavePerfil: string):Observable<ISidebarResponse>{
    return this.http.get<ISidebarResponse>('assets/perfiles.json').pipe(map((res)=>{
      return res[clavePerfil].menu;
    }));
  }

  getSideBarUsuario(idUsuario: number):Observable<ISidebarResponse>{
    return this.http.get<ISidebarResponse>(`${this.baseUrl}inicio/sidebar/${idUsuario}`);
  }


  flushLocalStorage(){

    localStorage.removeItem('localdb');
    localStorage.removeItem('localdbFilters');
    this.changeFilterQuery("");
  }
}
