import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { map, Observable, of } from "rxjs";
import { moduleService, subModuleService } from "../routesServices";
import {
  IAlcance,
  IArea,
  ICampus,
  IEscuela,
  IGiro,
  IIdiomas,
  IIndustria,
  IPeriodo,
  IPresencia,
  IProgramaAcademico,
  IRegion,
  ISemana,
  ITamanoEmpresa,
  ISector,
  ISemestre,
  IPeriodoSemestral,
  IPeriodoSemanal,
  CUnidadFormativa,
  cProgramaAcademico,
  IEscuelaDepartamento,
  IRolAcademico,
  ITipoRecurso,
  ICodigoPostalResponse,
  IMedioContacto,
  IPais,
  IModalidad,
} from "@shared/Interface/ICatalogos.interface";
import { IEstadosResponse } from "@shared/Interface/IEstadosResponse";
import { IAreaCanalizacionResponse } from "@shared/Interface/IAreaCanalizacionResponse";

import { IGiros } from "@shared/Interface/IGiros";
import { IsectorResponse } from "@shared/Interface/ISectorResponse";
import { ITamanoEmpresaResponse } from "@shared/Interface/ITamanoEmpresaResponse";
import { IIndustriaResponse } from "@shared/Interface/IIndustriaResponse";
import { IRelacionesTecResponse } from "@shared/Interface/IRelacionesTecResponse";
import { IRolesUsuarioResponse } from "@shared/Interface/IRolesUsuarioResponse";
import { ICatalogo, IEstatus, IIndustrias, INivelGestion } from "@shared/Interface/ICatalogo.interface";

const { CATALOGOS } = moduleService;
const {
  AREAS,
  CAMPUS,
  ALCANCES,
  ESCUELAS,
  REGIONES,
  PERIODOS,
  PERIODOS_SEMESTRAL,
  PERIODOS_SEMANAL,
  PROGRAMAS_ACADEMICOS,
  SECTORES,
  INDUSTRIAS,
  UNIDADES_FORMATIVA,
  IDIOMAS,
  GIROS,
  SEMANAS,
  TAMANIO_EMPRESA,
  PRESENCIA,
} = subModuleService;
@Injectable({
  providedIn: "root",
})
export class CatalogosService {
  baseUrl = environment.apiUrl;
  constructor(private http: HttpClient) {}

  getAreas(): Observable<IArea[]> {
    return this.http.get<IArea[]>(`${this.baseUrl}${CATALOGOS}/${AREAS}`);
  }

  getCampus(): Observable<ICampus[]> {
    return this.http.get<ICampus[]>(`${this.baseUrl}${CATALOGOS}/${CAMPUS}`);
  }

  getCampusxReto(idReto: number): Observable<ICampus[]> {
    return this.http.get<ICampus[]>(
      `${this.baseUrl}retos/retosMaestros/${idReto}/campus`
    );
  }

  getAlcance(): Observable<IAlcance[]> {
    return this.http.get<IAlcance[]>(`${this.baseUrl}${CATALOGOS}/${ALCANCES}`);
  }

  getEscuelas(idEscuela = 0): Observable<IEscuela[]> {
    let addEscuelaId = "";
    if (idEscuela !== 0) {
      addEscuelaId = `/${idEscuela}`;
    }
    return this.http.get<IEscuela[]>(
      `${this.baseUrl}${CATALOGOS}/${ESCUELAS}${addEscuelaId}`
    );
  }

  getRegiones(): Observable<IRegion[]> {
    return this.http.get<IRegion[]>(`${this.baseUrl}${CATALOGOS}/${REGIONES}`);
  }


  getPeriodo(validarVigencia?: boolean): Observable<IPeriodo[]> {
    const NUMPERIODOSXANIO = 4;
    const NUMPERIODOSAMOSTRAR = 9;
    validarVigencia = validarVigencia ?? true;
    return this.http
      .get<IPeriodo[]>(`${this.baseUrl}${CATALOGOS}/${PERIODOS}`)
      .pipe(
        map((lperiodos: IPeriodo[]) => {
          return lperiodos.map((x: IPeriodo) => {
            return {
              clavePeriodo : x.clavePeriodo ?? '',
              fechaFin: x.fechaFin ?? '',
              fechaInicio: x.fechaInicio ?? '',
              idPeriodo: x.idPeriodo,
              periodo: x.periodo,
              orden: x.periodo.split(' ')[0] === 'FEB-JUN' ? 1 : x.periodo.split(' ')[0] === 'VERANO' ? 2 : x.periodo.split(' ')[0] === 'AGO-DIC' ? 3 : 4
            }
          });
        })
        ,map((lperiodos: IPeriodo[])=>{
          if(!validarVigencia)
          {
            return lperiodos;
          }
          let index = this.findPeriodoActual(lperiodos) - 4;
          let restantes = (NUMPERIODOSXANIO - lperiodos[index].orden) + NUMPERIODOSAMOSTRAR ;
          return lperiodos.slice(index, index +restantes);
        })
      );
  }

  findPeriodoActual(lperiodos:IPeriodo[]):number{
    let mesActual = new Date().getMonth() + 1;
    let anioActual = new Date().getFullYear();
    let periodoActual = (mesActual == 1 ? 'INVIERNO': mesActual >= 2 && mesActual <= 6 ? 'FEB-JUN' : mesActual == 7 ? 'VERANO' : 'AGO-DIC');

    let index = lperiodos.map(x => x.periodo ).indexOf(`${periodoActual} ${anioActual}`);
  return  index;
  }


  getSemestre(): Observable<ISemestre[]> {
    let lSemestre: ISemestre[] = [];

    for (let index = 0; index < 9; index++) {
      lSemestre.push({
        idSemestre: index + 1,
        semestre: `${index + 1} Semestre`,
      });
    }

    //lSemestre = [{idSemestre: 1, semestre:'1er Semestre'}, {idSemestre:1,semestre:'2do Semestre'}];
    //return this.http.get<IPeriodo[]>(`${this.baseUrl}${CATALOGOS}/${PERIODOS}`);

    return of(lSemestre);
  }

  getPeriodoSemestral(): Observable<IPeriodoSemestral[]> {
    return this.http.get<IPeriodoSemestral[]>(
      `${this.baseUrl}${CATALOGOS}/${PERIODOS_SEMESTRAL}`
    );
  }

  getPeriodoSemanal(): Observable<IPeriodoSemanal[]> {
    return this.http.get<IPeriodoSemanal[]>(
      `${this.baseUrl}${CATALOGOS}/${PERIODOS_SEMANAL}`
    );
  }

  getUnidadFormativa(idEscuela: number): Observable<CUnidadFormativa[]> {
    let orden = "clave";
    return this.http
      .get<CUnidadFormativa[]>(
        `${this.baseUrl}${CATALOGOS}/${ESCUELAS}/${idEscuela}/${UNIDADES_FORMATIVA}?orden=${orden}`
      )
      .pipe(
        map((resultado) => {
          return resultado.map((unidad) =>
            CUnidadFormativa.unidadFormativaFromJson(unidad)
          );
        })
      );
  }

  getSectoresR(): Observable<ISector[]> {
    return this.http.get<ISector[]>(`${this.baseUrl}${CATALOGOS}/${SECTORES}`);
  }

  getIndustriasR(): Observable<IIndustria[]> {
    return this.http.get<IIndustria[]>(
      `${this.baseUrl}${CATALOGOS}/${INDUSTRIAS}`
    );
  }

  getTamanoEmpresa(): Observable<ITamanoEmpresa[]> {
    return this.http.get<ITamanoEmpresa[]>(
      `${this.baseUrl}${CATALOGOS}/${TAMANIO_EMPRESA}`
    );
  }

  getIdiomas(): Observable<IIdiomas[]> {
    return this.http.get<IIdiomas[]>(`${this.baseUrl}${CATALOGOS}/${IDIOMAS}`);
  }

  getGiro(): Observable<IGiro[]> {
    return this.http.get<IGiro[]>(`${this.baseUrl}${CATALOGOS}/${GIROS}`);
  }

  getSemanas(): Observable<ISemana[]> {
    return this.http.get<ISemana[]>(`${this.baseUrl}${CATALOGOS}/${SEMANAS}`);
  }

  getPresencia(): Observable<IPresencia[]> {
    return this.http.get<IPresencia[]>(
      `${this.baseUrl}${CATALOGOS}/${PRESENCIA}`
    );
  }

  /**
   * Api que obtiene todos los estados
   * @returns lista de objetos tipo IEstadosResponse
   */
  getEstados(): Observable<IEstadosResponse[]> {
    return this.http.get<IEstadosResponse[]>(this.baseUrl + "catalogos/estados");
  }

  /**
   * Api que obtiene todas las areasCanalizacion
   * @returns lista de objetos tipo IAreaCanalizacionResponse
   */
  getAreasCanalizacion(): Observable<IAreaCanalizacionResponse> {
    return this.http.get<IAreaCanalizacionResponse>(
      this.baseUrl + "catalogos/areasCanalizacion"
    );
  }

  /**
   * Api que obtiene todos los sectores
   * @returns lista de objetos tipo IsectorResponse
   */
  getSectores(): Observable<IsectorResponse> {
    return this.http.get<IsectorResponse>(this.baseUrl + "catalogos/sectores");
  }

  /**
   * Api que obtiene todos los tamaños de empresa
   * @returns Lista de objetos tipo ITamanoEmpresaResponse
   */
  getTamanosEmpresa(): Observable<ITamanoEmpresaResponse> {
    return this.http.get<ITamanoEmpresaResponse>(
      this.baseUrl + "catalogos/tamanosEmpresa"
    );
  }

  /**
   * Api que obtiene todas las industrias
   * @returns Lista de objetos tipo IIndustriaResponse
   */
  getIndustrias():Observable<IIndustrias[]>{
    return this.http.get<IIndustrias[]>(this.baseUrl+'catalogos/industrias')
    .pipe(
      map((value) =>{
        return value.map((x) => {
          return  {
              idIndustria: x.idIndustria,
              industria: x.tipoIndustria != null ? "(" + x.tipoIndustria + ") " + x.industria : x.industria,
              tipoIndustria: x.tipoIndustria
          }
        })
      }));
  }

  getProgramasAcademicos(idEscuela: number): Observable<cProgramaAcademico[]> {
    return this.http
      .get<cProgramaAcademico[]>(
        `${this.baseUrl}${CATALOGOS}/${ESCUELAS}/${idEscuela}/${PROGRAMAS_ACADEMICOS}`
      )
      .pipe(
        map((resultado) => {
          return resultado.map((programas) =>
            cProgramaAcademico.programaAcademicoFromJson(programas)
          );
        })
      );
  }

  /**
   * Api que obtiene todos los giros
   * @returns lista de giros
   */
  getGiros(): Observable<IGiros> {
    return this.http.get<IGiros>(this.baseUrl + "catalogos/giros");
  }

  getCampusPorRegion(idRegion: number): Observable<ICampus[]> {
    return this.http.get<ICampus[]>(
      `${this.baseUrl}catalogos/regiones/${idRegion}/campus`
    );
  }

  getProgramasAcademicosxUnidadFormativa(idUnidadFormativa: number) {
    return this.http.get<IProgramaAcademico[]>(
      `${this.baseUrl}catalogos/unidadesFormativas/${idUnidadFormativa}/programasAcademicos`
    );
  }

  getRelacionesTec(): Observable<IRelacionesTecResponse[]> {
    return this.http.get<IRelacionesTecResponse[]>(
      this.baseUrl + "catalogos/relacionesTec"
    );
  }

  getRolesUsuario(perfil: string): Observable<IRolesUsuarioResponse[]> {
    return this.http.get<IRolesUsuarioResponse[]>(
      `${this.baseUrl}catalogos/roles?perfil=${perfil}`
    );
  }

  getDepartamentosPorEscuela(
    idEscuela: number
  ): Observable<IEscuelaDepartamento[]> {
    return this.http.get<IEscuelaDepartamento[]>(
      `${this.baseUrl}catalogos/departamentosXEscuela?idEscuela=${idEscuela}`
    );

    // opcion 2
    //catalogos/escuelas/1/departamentos
  }

  getRolesAcademicos(): Observable<IRolAcademico[]> {
    return this.http.get<IRolAcademico[]>(
      `${this.baseUrl}catalogos/rolesAcademicos`
    );
  }

  getTipoRecursos(): Observable<ITipoRecurso[]>{
    return this.http.get<ITipoRecurso[]>(`${this.baseUrl}catalogos/tiposRecurso`);
  }















  // getTamanoEmpresa(): Observable<ITamanoEmpresa[]>{
  //   return this.http.get<ITamanoEmpresa[]>(this.baseURL+'catalogos/tamanosEmpresa');
  // }
  // getCampus():Observable<ICampus[]>{
  //   return this.http.get<ICampus[]>(this.baseURL+'catalogos/campus');
  // }
  // getRelacionesTec(): Observable<IRelacionesTecResponse[]>{
  //   return this.http.get<IRelacionesTecResponse[]>(this.baseURL+'catalogos/relacionesTec')
  // }

  // getEstados(): Observable<IEstadosResponse[]>{
  //   return this.http.get<IEstadosResponse[]>(this.baseURL+'catalogos/estados')
  // }



  getTipoEmpresas():Observable<ICatalogo[]>{
    return this.http.get<ICatalogo[]>(this.baseUrl+'TipoEmpresa');
  }
  getCiudades():Observable<ICatalogo[]>{
    return this.http.get<ICatalogo[]>(this.baseUrl+'Ciudad');
  }
  // getEstados():Observable<IEstadosResponse[]>{
  //   return this.http.get<IEstadosResponse[]>(this.baseURL+'Estados');
  // }

  getActividades():Observable<ICatalogo[]>{
    return this.http.get<ICatalogo[]>(this.baseUrl+'Actividad');
  }
  getNumeroEmpleados():Observable<ICatalogo[]>{
    return this.http.get<ICatalogo[]>(this.baseUrl+'NumeroEmpleados');
  }

  getPaises():Observable<IPais[]>{
    return this.http.get<IPais[]>(`${this.baseUrl}catalogos/paises`);
  }

  getInfoCp(codigoPostal: string):Observable<ICodigoPostalResponse>{
    return this.http.get<ICodigoPostalResponse>(`${this.baseUrl}catalogos/direccion?cp=${codigoPostal}`);
  }

  getMediosContacto():Observable<IMedioContacto[]>{
    return this.http.get<IMedioContacto[]>(`${this.baseUrl}catalogos/medioContacto`);
  }

  getEstatus():Observable<IEstatus[]>{
    return this.http.get<IEstatus[]>(`${this.baseUrl}catalogos/estatus`);
  }

  getModalidad():Observable<IModalidad[]>{
    return this.http.get<IModalidad[]>(`${this.baseUrl}catalogos/modalidad`);
  }

  getNivelesGestion():Observable<INivelGestion[]>{
    return this.http.get<INivelGestion[]>(`${this.baseUrl}catalogos/nivelesGestion`);
  }

  getDepartamentos(): Observable<IEscuelaDepartamento[]>{
    return this.http.get<IEscuelaDepartamento[]>(`${this.baseUrl}catalogos/escuelas/departamentos`);
  }
}
