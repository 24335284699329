import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-indicator-user',
  templateUrl: './indicator-user.component.html',
  styleUrls: ['./indicator-user.component.css'],
})
export class IndicatorUserComponent  {

  @Input()
  indicators: any[]=[];

  
}
