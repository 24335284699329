<div class="footer_sf">
  <div class="d-flex flex-wrap">
    <div class="col_footer_sf">
      <div>
        <img class="logo" alt="logo" src="assets/img/sello_p.svg">
        <img alt="" src="assets/img/MPSTec.svg">
      </div>
      <div class="col-12 mt-5">El marketplace de Socios Formadores es un espacio de conexión entre la academia y la industria a través de los retos del modelo Tec21.</div>
        <div class="col-12 mb-5 mt-4" *ngIf="!isLogged">¡Únete ya!</div>
        <div class="col-12 mb-5 mt-4" *ngIf="isLogged">¡Explóralo!</div>
    </div>

    <div class="col_footer_sf">
      <div class="mt-5">
        <h2 class="hola_footer ">¿Tienes dudas?</h2>
      </div>
      <div class="mt-4">
        <div>
          Chat: http://tec.mx/tecserviceschat
        </div>
        <div>
          Correo: <a href="mailto:tecservices@servicios.tec.mx">tecservices@servicios.tec.mx</a>
        </div>
        <div class="mt-3">
          <a class="f-link" target="_blank" rel="noopener noreferrer" href="assets/img/preguntasfrecuentes_sociosformadores.pdf">Preguntas Frecuentes</a>
        </div>
      </div>

    </div>


    <div class="col_footer_sf">
      <div class="mt-5">
        <h2 class="hola_footer">Plataformas</h2>
      </div>
      <div class="mt-4">
        Desarrollado por la Iniciativa de Plataformas en colaboración con el Centro de Vinculación y Desarrollo Profesional<br />
        plataformas@servicios.tec.mx
        <br>
      </div>
    </div>

  </div>

  <div class="row d-flex">
    <div class="col_footer_sf">
      <img alt="logo_tec mt-2" src="assets/img/logo_tec.svg">
    </div>
    <div class="col_footer_sf">
      <img alt="" src="/assets/img/tecserviceslogowhite.svg">
    </div>
    <div class="col_footer_sf">
      <img alt="Plataformas" src="assets/img/plataformas-cac_1.svg">
    </div>

  </div>
</div>

<div class="row py-4 footer_legal">
  <div class="justify-content-center d-flex">
    <a class="link-light text-decoration-none" target="_blank" rel="noopener noreferrer" href="https://mpstecstorage.blob.core.windows.net/mvpretos-app-assets/terminosycondiiones.pdf">TÉRMINOS Y CONDICIONES </a> 
    <span class="text-white">&nbsp;&nbsp;|&nbsp;&nbsp;</span> 
    <a class="link-light text-decoration-none" target="_blank" rel="noopener noreferrer" href="https://tec.mx/es/aviso-de-privacidad-plataforma-mps-tec">AVISO DE PRIVACIDAD</a>  
  </div>
</div>