<app-header></app-header>
<div class="container">
    <div class="hero-2 row">
        <img class="hero-der col-6 px-0 order-md-last " alt="" src="/assets/img/foto-2.png">
        <div class="hero-izq col-6 px-0 order-md-first">
            <div class="hero-texto1"> Registro de<br>Organizaciones</div>
            <div class="hero-texto2"> Contesta el siguiente formulario de registro para que procesemos tu solicitud para ser Socio Formador</div>
        </div>

    </div>
    <app-formulario ></app-formulario>
    <app-footer></app-footer>
</div>
