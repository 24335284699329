import { Component, Input, OnInit } from '@angular/core';

import { Router } from '@angular/router';
import Utils from '@shared/helpers/utils';
import { UserData } from '@shared/Models/DataUser';
import { UsuariosService } from '@shared/service/Usuarios/usuarios.service';

interface Button {
  url: string;
  text: string;
  visible: boolean;
}

interface Indicator {
  icon: string;
  value: number;
  title: string;
}

@Component({
  selector: 'app-user-header',
  templateUrl: './user-header.component.html',
  styleUrls: ['./user-header.component.css'],
})
export class UserHeaderComponent implements OnInit {
  @Input()
  indicators: Indicator[] = [];
  @Input()
  userData!: UserData;

  buttons: Button[];

  constructor(private router: Router, private usuarioService:UsuariosService) {}

  ngOnInit(): void {
    this.userData = this.usuarioService.getCurrentUserData();
    this.userData.urlFotoUsuario = this.userData.urlFotoUsuario + "?v=" + new Date().getMilliseconds();
    let cveRouter = Utils.getRouteFromProfile(this.userData.clavePerfil);
  
    this.buttons = [
      {
        url: `/${cveRouter}/mi-perfil`,
        text: 'Editar perfil',
        visible: true
      },
      {
        url: '/cvdp/gestion-usuarios',
        text: 'Gestión de usuarios',
        visible: (this.userData.clavePerfil == 'cvdp_admin')
      },
    ];

  }

  onClickButton(url: string) {
    this.router.navigateByUrl(url);
  }
}
