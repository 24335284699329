import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import {
  CUnidadFormativa,
  ICampus,
  IEscuela,
  IIndustria,
  IPresencia,
  IRegion,
  ISector,
  ITamanoEmpresa,
} from '@shared/Interface/ICatalogos.interface';
import { IReto, IRetoMaestro } from '@shared/Interface/IReto.interface';
import { CatalogosService } from '@shared/service/Catalogos/catalogos.service';
import { RetosService } from '@shared/service/Retos/retos.service';
import { UsuariosService } from '@shared/service/Usuarios/usuarios.service';
import { tap } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { InicioService } from '@shared/service/Inicio/inicio.service';
interface GeneralInfoMocks {
  icon: string;
  title: string;
}

interface RetoInfo {
  idReto: number;
  reto: string;
  descripcion: string;
  entregables: string; // objetivos?
  escuela: string;
  industria: string;
  unidadFormativa: string;
  alcance: string;
  campus: ICampus[];
  generalInfoIcons: GeneralInfoMocks[];
}
@Component({
  selector: 'app-vincula-usuarios3',
  templateUrl: './vincula-usuarios3.component.html',
  styleUrls: ['./vincula-usuarios3.component.css'],
})
export class VinculaUsuarios3Component implements OnInit {
  vinculacionForm: FormGroup;
  validationErrors: string[] = [];
  campusData: ICampus[];
  seccion1: ICampus[];
  seccion2: ICampus[];
  presenciaData!: IPresencia[];
  ubicacionData: any;
  sectoresData: ISector[];
  industriasData: IIndustria[];
  tamanioEmpresasData: ITamanoEmpresa[];
  unidadFormativaData: CUnidadFormativa[];
  retosAcademicosData: IReto[];
  regionesData: IRegion[];
  buttonSave = {
    title: 'Buscar Socios Formadores',
    isLoading: false,
  };

  programaAcademicoData: any;
  escuelaData: any;


  retoInfo: IRetoMaestro | null;

  titleVinculacion = {
    title: 'Vincula a un Socio Formador',
    plain_button: "Banco de SF",
    callbackClick: () => {
      this.irBancoSF();
    },
  };
  mostrarRequeridos:boolean=false;

  constructor(
    private catalogosService: CatalogosService,
    private usuariosService: UsuariosService,
    private retosService: RetosService,
    private router: Router,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private inicioService: InicioService
  ) {}

  ngOnInit(): void {
    this.campusData = [];
    this.seccion1 = [];
    this.seccion2 = [];
    
    this.getEscuela(0);

    this.initializeForm();

    this.catalogosService
      .getCampus()
      .pipe(tap((resultado: ICampus[]) => {
        this.campusData = resultado;
        if(this.campusData)
        {
          let halfItems = Math.round(this.campusData.length/2);
          this.seccion1 = this.campusData.slice(0,halfItems);
          this.seccion2 = this.campusData.slice(halfItems);
        }        
      }))
      .subscribe();

    this.catalogosService
      .getPresencia()
      .pipe(tap((resultado: IPresencia[]) => (this.presenciaData = resultado)))
      .subscribe();

    this.catalogosService
      .getSectoresR()
      .pipe(tap((resultado: ISector[]) => (this.sectoresData = resultado)))
      .subscribe();

    this.catalogosService
      .getRegiones()
      .pipe(tap((resultado: IRegion[]) => (this.regionesData = resultado)))
      .subscribe();

    this.catalogosService
      .getIndustriasR()
      .pipe(tap((resultado: IIndustria[]) => (this.industriasData = resultado)))
      .subscribe();

    this.catalogosService
      .getTamanoEmpresa()
      .pipe(
        tap(
          (resultado: ITamanoEmpresa[]) =>
            (this.tamanioEmpresasData = resultado)
        )
      )
      .subscribe();

    
  }


  getRetosAcademicosxUF($event){
    
    this.vinculacionForm.patchValue({idReto:''});
    this.retosService
    .getRetosMaestrosxUnidadFormativa($event)
    .subscribe({
      next: (resultado: IReto[]) =>  {

          this.retosAcademicosData = resultado;
          this.retoInfo = null;
        },
          error:(error)=>{
            this.retosAcademicosData = [];
            this.retoInfo = null;
              Swal.fire({
                icon:'info',
                text:'No hay retos asociados a la Unidad Formativa'                
              })
          }
        });
  }

  initializeForm() {
    let idEscuela = null;
    let esAcademico = this.usuariosService.getCurrentUserData().clavePerfil == "academia";
    if(esAcademico){
      idEscuela = this.usuariosService.getCurrentUserData().idEscuela;
    }
    this.vinculacionForm = this.fb.group({
      reto: [''],
      idReto: ['', ],
      idUnidadFormativa: ['', ],
      idSector: ['', ],
      idIndustria: ['', ],
      idTamano: ['', ],
      idPresencia: ['', ],
      regiones: this.fb.array([],),
      campus: this.fb.array([],),
      palabrasClave: ['',[Validators.required]],
      idEscuela:[idEscuela]
    });
    if(esAcademico && idEscuela ){
      this.getUnidadFormativa(idEscuela);
    }

  }

  lbl_idiomas='';
  lbl_semestre='';
  lbl_periodoSemanal='';
  lbl_periodoSemestral='';
  getRetoDetail(event: any) {
    const idUnidadFormativa = event;
    this.retosService
      .getRetosMaestrosxUnidadFormativa_original(idUnidadFormativa)
      .pipe(
        tap((res: IRetoMaestro[]) => {
          let resultado = res[0];

          this.retosService.getRetoMaestroDetail(resultado.idRetoMaestro)
          .subscribe({
            next: (resOK) => {
              this.lbl_idiomas = resOK.idiomas.map(x => x.idioma).join(', ');
              this.lbl_semestre = resOK.semestres.map(x => x.semestre).join(', ');
              this.lbl_periodoSemanal = resOK.periodosSemanales.map(x=>x.periodoSemanal).join(', ');
              this.lbl_periodoSemestral = resOK.periodosSemestrales.map(x=>x.periodoSemestral).join(', ');
              
              this.retoInfo = resOK;
          	},
            error:(err)=>{
              console.log(err);
            }})
          })).subscribe();
  }


  searchRetos() {
    try{
      this.vinculacionForm.markAllAsTouched();

      
    if (this.vinculacionForm.valid) {

      let texto = this.vinculacionForm.get('palabrasClave')?.value;
      let palabras = texto.split(' ').filter(x=> (x.trim() != ''));

      if(palabras.length < 5){
        this.vinculacionForm.get('palabrasClave')?.setErrors({'required': true});
        return;
      }

      this.buttonSave.title = 'Cargando..';
      this.buttonSave.isLoading = true;      
      
      let idEscuela = this.vinculacionForm.get("idEscuela")?.value;
      let idUnidadFormativa = this.vinculacionForm.get("idUnidadFormativa")?.value;
      let idAcademia = this.usuariosService.getCurrentUserData().idUsuario;
      
      this.retosService
        .postVinculaciones({
          idRetoMaestro: this.retoInfo?.idRetoMaestro,
          palabrasClave: this.vinculacionForm.get('palabrasClave')?.value,
          idAcademia: idAcademia,
		      idEscuela : idEscuela,
		      idUnidadFormativa: idUnidadFormativa
        })
        .subscribe({
          next: (resultado: any) => {
            if (resultado) {
              this.buttonSave.title = 'Registrar este Reto';
              this.buttonSave.isLoading = true;
              localStorage.setItem('idEscuela', this.vinculacionForm.get('idEscuela')?.value);
              localStorage.setItem('sociosMatch', JSON.stringify(resultado));
              sessionStorage.setItem('idLogBusquedaSocio', resultado.idLogBusquedaSocio);
              this.router.navigate(['matches-socios']);
            }
          },
          error: (error) =>{
            Swal.fire({
              icon: 'error',
              text: error    });
            this.buttonSave.title = 'Busca Socios Formadores';
            this.buttonSave.isLoading = false;
          }
        });
    } else {
      this.toastr.error('Todos los campos son obligatorios');
      this.mostrarRequeridos=true;
    }
  }catch(error){
    this.toastr.warning('Hubo un error en la solicitud, favor de intentar más tarde.');
    this.router.navigateByUrl('/dashboard');
  }
  }

  verifySelected(id: string | number, inputForm: string) {
    return this.vinculacionForm.get(inputForm)?.value === id ? true : null;
  }

  verifyChecked(id: string | number, inputForm: string) {
    const areas = this.vinculacionForm.get(inputForm)?.value;
    if (typeof areas === 'object') {
      return areas.includes(id);
    } else {
      return areas === id;
    }
  }



  getUnidadFormativa(event) {
    let idEscuela = event.target?.value ?? event;
    this.catalogosService
      .getUnidadFormativa(idEscuela)
      .subscribe({
        next: (resultado: CUnidadFormativa[]) => {this.unidadFormativaData = resultado;},
        error: (error) => {
          Swal.fire({
            icon:'info',
            text:'No hay Unidades Formativas asociados'                
          })
        }});
  }

  getEscuela(idEscuela: number) {
    this.catalogosService
      .getEscuelas(idEscuela)
      .pipe(
        tap((resultado: IEscuela[]) => {
          this.escuelaData = resultado;
        })
      )
      .subscribe();
  }

  irBancoSF() {
    this.inicioService.changeMenuItem("bancosocioformador");
      this.router.navigate(["/banco-socio-formador"]);
  }

}
