
import { Idioma } from "./IRetoMaestroResponse"

export interface RetosResponse{
  idReto: number,
  reto: string,
  descripcion: string,
  entregable: string,
  fechaRegistro: Date,
  claveEstatus: string,
  idUsuarioTec: number,
  nombreUsuarioTec: string,
  fechaSocioFormador: Date,
  idUsuarioSocioFormador: number,
  idEscuela: number,
  escuela: string,
  idUnidadFormativa: number,
  unidadFormativa: string,
  idDepartamento: number,
  departamento: string,
  semestre: number,
  idAlcance: number,
  alcance: string,
  idRegion: number,
  region: string,
  idCampus: number,
  s_campus: string,
  idPeriodoAcademico: number,
  periodoAcademico: string,
  idDuracionReto: number,
  duracionReto: string,
  idIdioma: number,
  idioma: string,
  idTipoSocioFormador: number,
  tipoSocioFormador: string,
  fechaInicio: Date,
  fechaFin: Date,
  idSector: number,
  sector: string,
  idIndustria: number,
  industria: string,
  idTamano: number,
  tamano: string,
  palabrasClave: string,
  idRetoMaestro: number,
  idGiro: number,
  giro: string,


  idPeriodo: number,
  idSemanas: number,
  idProgramaAcademico: number,
  urlFotoReto: string,
  estatus: string,
  usuarioTec: string,
  periodo: string,
  semanas: string,
  programaAcademico: string,
  campus: [
    {
      idCampus: number,
      campus: string,
      esCoordinador: boolean
    }
  ],
  giros: [
    {
      idGiro: number,
      giro: string
    }
  ],
  empresaFamiliar: string,
  idTipoSolicitud: number,
  
  periodoSemanal:string,
  periodoSemestral: string,
  objetivo:string,
  idiomas: Idioma[],
  nombreCoordinador: string,
  correoCoordinador: string,
  puestoCoordinador: string,
  telefonoCoordinador: string,
  crns:[{crn:string, grupo:string, esPrincipal:boolean}],
  nombreContacto: string,
  puestoContacto: string,
  correoContacto: string,
  telefonoContacto: string,
  semestres:[{semestre:number}],
  periodosSemanales:[],

  idModalidad: number,
  inscritos: number,
  nombreCampus: string,
  idSocioFormador: number,
  idSolicitud: number,
}

export class cRetosResponse implements RetosResponse{
  idReto: number
  reto: string
  descripcion: string
  entregable: string
  fechaRegistro: Date
  claveEstatus: string
  idUsuarioTec: number
  nombreUsuarioTec: string
  fechaSocioFormador: Date
  idUsuarioSocioFormador: number
  idEscuela: number
  escuela: string
  idUnidadFormativa: number
  unidadFormativa: string
  idDepartamento: number
  departamento: string
  semestre: number
  idAlcance: number
  alcance: string
  idRegion: number
  region: string
  idCampus: number
  s_campus: string
  idPeriodoAcademico: number
  periodoAcademico: string
  idDuracionReto: number
  duracionReto: string
  idIdioma: number
  idioma: string
  idTipoSocioFormador: number
  tipoSocioFormador: string
  fechaInicio: Date
  fechaFin: Date
  idSector: number
  sector: string
  idIndustria: number
  industria: string
  idTamano: number
  tamano: string
  palabrasClave: string
  idRetoMaestro: number
  idGiro: number
  giro: string
  idPeriodo: number
  idSemanas: number
  idProgramaAcademico: number
  urlFotoReto: string
  estatus: string
  usuarioTec: string
  periodo: string
  semanas: string
  programaAcademico: string
  campus: [{ idCampus: number; campus: string, esCoordinador:boolean }]
  giros: [{ idGiro: number; giro: string }]
  empresaFamiliar: string
  idTipoSolicitud:number
  periodoSemanal:string
  periodoSemestral: string
  objetivo: string
  idiomas:Idioma[]
  nombreCoordinador: string
  correoCoordinador: string
  puestoCoordinador: string
  telefonoCoordinador: string
  crns:[{crn:string, grupo:string, esPrincipal:boolean}]
  nombreContacto: string
  puestoContacto: string
  correoContacto: string
  telefonoContacto: string
  semestres:[{semestre:number}]
  periodosSemanales:[]
  idModalidad: number
  inscritos: number
  nombreCampus: string
  idSocioFormador: number;
  idSolicitud: number;

  constructor(){
    this.idReto=0;
    this.reto='';
    this.descripcion='';
    this.entregable='';
    
    this.claveEstatus='';
    this.idUsuarioTec=0;
    this.nombreUsuarioTec='';
    
    this.idUsuarioSocioFormador=0;
    this.idEscuela=0;
    this.escuela='';
    this.idUnidadFormativa=0;
    this.unidadFormativa='';
    this.idDepartamento=0;
    this.departamento='';
    this.semestre=0;
    this.idAlcance=0;
    this.alcance='';
    this.idRegion=0;
    this.region='';
    this.idCampus=0;
    this.s_campus='';
    this.idPeriodoAcademico=0;
    this.periodoAcademico='';
    this.idDuracionReto=0;
    this.duracionReto='';
    this.idIdioma=0;
    this.idioma='';
    this.idTipoSocioFormador = 0;
    this.tipoSocioFormador='';
    // this.fechaInicio: Date
    // this.fechaFin: Date
    this.idSector = 0;
    this.sector='';
    this.idIndustria = 0;
    this.industria='';
    this.idTamano = 0;
    this.tamano='';
    this.palabrasClave='';
    this.idRetoMaestro = 0;
    this.idGiro = 0;
    this.giro='';
    this.idPeriodo = 0;
    this.idSemanas = 0;
    this.idProgramaAcademico = 0;
    this.urlFotoReto='';
    this.estatus='';
    this.usuarioTec='';
    this.periodo='';
    this.semanas='';
    this.programaAcademico='';
    /*this.campus: [{ idCampus: number; campus=''; }]
    this.giros: [{ idGiro: number; giro: string }]*/
    this.empresaFamiliar='';
    this.idTipoSolicitud=0;
    this.nombreCoordinador="";
    this.correoCoordinador="";
    this.puestoCoordinador="";
    this.telefonoCoordinador="";
    this.idModalidad = 0;
    this.inscritos = 0;
    this.nombreCampus = '';
    this.idSocioFormador = 0;
    this.idSolicitud = 0;
    
  }

}