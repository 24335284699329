import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { FeatureFlagService } from './feature-flag.service';

@Directive({
  selector: '[featureFlag]'
})
export class FeatureFlagDirective {
  @Input() featureFlag : string;
  
  constructor(
    private tpl: TemplateRef<any>,
    private vcr: ViewContainerRef,
    private featureFlagService: FeatureFlagService
  ) { }

  ngOnInit() {
    const isEnabled = this.featureFlagService.isFeatureEnabled(this.featureFlag);
    if (isEnabled) {
      this.vcr.createEmbeddedView(this.tpl);
    }
  }


}
