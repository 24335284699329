import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-problematicas',
  templateUrl: './problematicas.component.html',
  styleUrls: ['./problematicas.component.css']
})
export class ProblematicasComponent implements OnInit {

  sidebarExpanded = false;
   lang : string = 'es';

  title = {
    title: 'Mis Problemáticas Registradas'
  };



  ngOnInit(): void {
    this.lang = localStorage.getItem('lang') ?? 'es';
  }

  changeLang(lang:string){
    this.lang  = lang;
    localStorage.setItem('lang',lang);
    window.location.reload();
  }
}
