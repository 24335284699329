export interface IArea {
  idArea: number;
  area: string;
}

export interface ICampus {
  idCampus: number;
  campus: string;
  idRegion: number;
  campusCoordinador:boolean;
  idCVDPPrincipal:number;
  esCoordinador?:any;
}

export class Campus implements ICampus{
  idCampus: number;
  campus: string;
  idRegion: number;
  campusCoordinador:boolean;
  idCVDPPrincipal:number;

  constructor(){
    this.idCampus = 0;
    this.campus='';
    this.idRegion=0;
    this.campusCoordinador=false;
    this.idCVDPPrincipal=0;
  }
}

export interface IAlcance {
  idAlcance: number;
  alcance: string;
}

export interface IEscuela {
  idEscuela: number;
  escuela: string;
}

export interface IRegion {
  idRegion: number;
  region: string;
}
export interface ISemestre {
  idSemestre: number;
  semestre: string;
}

export interface IPeriodo {
  idPeriodo: number;
  periodo: string;
  clavePeriodo: string;
  fechaInicio: string;
  fechaFin: string;
  orden: number;
}

export interface IPeriodoSemestral{
  idPeriodoSemestral: number,
  periodoSemestral: string
}

export interface IPeriodoSemanal{
  idPeriodoSemanal: number,
  periodoSemanal: string
}

export interface ISector {
  idSector: number;
  sector: string;
}

export interface IConvenio{
  idSocioFormador: number,
  urlCartaConvenio: string,
  fechaInicio: string,
  fechaFin: string
}

export interface IIndustria {
  idIndustria: number;
  industria: string;
}

export interface IUnidadFormativa {
  idEscuela: number;
  idUnidadFormativa: number;
  unidadFormativa: string;
  clave: string;
}
export class CUnidadFormativa{
  static unidadFormativaFromJson(obj: Object){
    return new CUnidadFormativa(
      obj['idEscuela'],
      obj['idUnidadFormativa'],
      obj['unidadFormativa'],
      obj['clave'],
    );
  }
  constructor(
    public idEscuela?,
    public idUnidadFormativa?,
    public unidadFormativa?,
    public clave?){}

  get nombreUnidadFormativa(){
    return `${this.clave}_${this.unidadFormativa}`;
  }
}

export interface ITamanoEmpresa {
  idTamanoEmpresa: number;
  tamanoEmpresa: string;
  idTamano: number;
  tamano: string;
}

export interface IIdiomas {
  idIdioma: number;
  idioma: string;
}

export interface IGiro {
  idGiro: number;
  giro: string;
}

export interface ISemana {
  idSemanas: number;
  semanas: string;
}

export interface IPresencia {
  idPresencia: number;
  presencia: string;
}

export interface IProgramaAcademico {
  idProgramaAcademico: number;
  programaAcademico: string;
  idEscuela: number;
  clave: string | null | number;
}

export class cProgramaAcademico{
static programaAcademicoFromJson(obj:Object){
  return new cProgramaAcademico(
  obj['idProgramaAcademico'],
  obj['programaAcademico'],
  obj['idEscuela'],
  obj['clave'],
  );
} 

constructor
(
  public idProgramaAcademico,
  public programaAcademico,
  public idEscuela,
  public clave,  

){}

get nombreProgramaAcademico(){
  return `${this.clave} - ${this.programaAcademico}`;
}
  
}

export interface IEscuelaDepartamento{
  idDepartamento:number,
  departamento:string,
  idEscuela:number   
}

export interface IRolAcademico{
  idRolAcademico:number,
  deRolAcademico:string
}

export interface IPais{
  clavePais: string,
  pais: string,
  orden: number
}

export interface IMedioContacto{

}

export interface IColonia{
  colonia: string
}

export interface ICodigoPostalResponse{
  claveEstado: string,
  estado: string,
  ciudad: string,
  colonias:[{colonia:string}]
}

export interface IMedioContacto{
  idMedioContacto: number,
  medioContacto:string,
  pedirDato:string
}

export interface IModalidad{
  idModalidad: number;
  Modalidad: string;
}

export interface ITipoRecurso{
    idTipoRecurso:number,
    tipoRecurso:string,
}
