<app-header-buscador lbl_placeholder="Buscar un Caso de Éxito"
    (fatherNameFunction)="buscadorPress($event)"></app-header-buscador>
<div>
    <div>
        <div class="sec_ad">
            <app-title-show-more [showMoreObject]="{title: 'casos_exito.title' | translate} "></app-title-show-more>

            <div id="sec_tab" class="nav nav-pills d-flex flex-md-nowrap flex-wrap" role="presentation">

                <div class="active" data-bs-toggle="pill" data-bs-target="#tab-socios-formadores"
                    aria-controls="tab-socios-formadores" prm="re_ac" role="tab" type="button" aria-selected="true"
                    >

                </div>


            </div>

            <div class="tab-content" id="pills-tabContent">
                <div class="tab-pane fade show active" id="tab-socios-formadores" role="tabpanel"
                    aria-labelledby="tab-socios-formadores-tab">
                    <!--INicia Listado de recurso de vinculaciòn-->
                    <div class="flex-fill">
                        <div class="d-flex justify-content-end">
                            <div class="d-none d-md-block add-user">
                                <button class="blue_button" (click)="crear()">{{
                                    'casos_exito.btn_crear_caso' | translate }}</button>
                            </div>

                            <div class="d-none d-md-block add-user">
                                <button class="blue_button" (click)="restablecer()">{{
                                    'academia_banco_socio_formador.btn_restablecer' | translate }}</button>
                            </div>

                            <div class="btn-group order-by">
                                <button class="btn dropdown-toggle" type="button" id="listado" data-bs-toggle="dropdown"
                                    data-bs-auto-close="true" aria-expanded="false" data-bs-offset="0,0">
                                    {{textOrdenarPor | translate }}
                                </button>
                                <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="listado">
                                    <!-- ajustar a parametros que recibe la api orden -> "nombre/socio"   -->
                                    <li><a class="dropdown-item" [class.active]="ordenamientoBy=='nombre'"
                                            (click)="orderBy('titulo')">{{'casos_exito.ordenTitulo' | translate }}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div id="c_sf" class="sec_cnt tipo-10">
                        <div class="d-none d-md-block">
                            <div class="cont_tit d-flex">
                                <div class="flex-fill col-2">{{'casos_exito.titulo' | translate }}</div>
                                <div class="flex-fill col-5">{{'casos_exito.descripcion' | translate }}</div>
                                <div class="flex-fill col-1 d-flex justify-content-center align-items-center">{{'casos_exito.imagen' | translate }}</div>
                                <div class="flex-fill col-1 d-flex justify-content-center align-items-center">{{'casos_exito.mostrar_inicio' | translate }}</div>
                                <div class="flex-fill col-1 d-flex justify-content-center align-items-center">{{'casos_exito.mostrar_reto' | translate }}</div>
                                <div class="flex-fill col-2 d-flex justify-content-center align-items-center">{{'casos_exito.acciones' | translate }}</div>
                            </div>
                            <div *ngFor="let el of casosList" class="roundedsquare cont_fl d-flex align-items-center">
                                <div class="flex-fill col-2">{{el.nombreCasoExito}}</div>
                                <div class="flex-fill col-5" title="{{ el.descripcionCasoExito }}">
                                    {{el.escuela}}<br>{{ cutTextLong(el.descripcionCasoExito) }}
                                </div>
                                <div class="flex-fill col-1 d-flex align-items-center">
                                    <img alt="Foto" class="img_usr" src="{{ el.urlImagenCasoExito }}">
                                </div>
                                <div class="flex-fill col-1 d-flex align-items-center">
                                    <input type="checkbox" class="chkbox"
                                        (change)="guardarCambioHome(el.idCasoExito, el.publicadoHome)" [checked]="el.publicadoHome">
                                </div>
                                <div class="flex-fill col-1 d-flex align-items-center"    >
                                    <input type="checkbox" class="chkbox" [disabled]="!(el.idReto > 0)"
                                    (change)="guardarCambioUF(el.idCasoExito,el.publicadoUnidadFormacion)" [checked]="el.publicadoUnidadFormacion">
                                </div>
                                <div class="flex-fill col-2">
                                    <button class="lst_bt_edt"
                                        (click)="editarVinculacion( el.idCasoExito)">&nbsp;</button>
                                    <button class="lst_bt_brr"
                                        (click)="eliminarVinculacion( el.idCasoExito)">&nbsp;</button>
                                </div>
                            </div>
                            <div *ngIf="casosList.length === 0">
                                <app-sin-datos-table></app-sin-datos-table>
                            </div>
                        </div>

                        <app-pagination *ngIf="casosListTotal.length" [objPagination]="casosListTotal"
                            (paginationDataChange)="showPaginationData($event)"></app-pagination>

                        <div class="d-block d-md-none">
                            <div class="accordion" id="ac_1">
                                <div *ngFor="let el of elementsTo" class="accordion-item">
                                    <h2 class="accordion-header" id="flush-heading{{el.idCasoExito}}">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                            [attr.data-bs-target]="'#flush-collapse'+el.idCasoExito"
                                            aria-expanded="true">
                                            {{el.nombreCasoExito}}
                                        </button>
                                    </h2>
                                </div>
                            </div>
                            <div class="d-flex justify-content-center"
                                *ngIf="paginaActualMasResultados < numeroPaginasTo">
                                <button class="mb_mas" (click)="obtenerMasResultados()"><img class="tam"
                                        alt="Más Resultados" src="assets/img/icon_mas_bullet.svg">
                                    {{'gestion_usuarios.cargar_mas_resultados' | translate }}</button>
                            </div>
                            <div class="d-block d-md-none mt-3">
                                <button class="blue_button float-end"> {{'gestion_usuarios.añadir_usuario' | translate
                                    }}</button>
                            </div>

                            <div *ngIf="elementsTo.length === 0">
                                <app-sin-datos-table></app-sin-datos-table>
                            </div>

                        </div>
                    </div>
                    <!--Termina Listado-->
                </div>


            </div>
        </div>
    </div>
</div>
