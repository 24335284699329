import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-link-type',
  templateUrl: './link-type.component.html',
  styleUrls: ['./link-type.component.css'],
})
export class LinkTypeComponent implements OnInit {
  @Input() elementTextCol: any;

  element_45:string;
  subtitle_40:string;
  target: string = '';
  rel: string = '';

  ngOnInit(): void {
    this.element_45 = this.elementTextCol.title;
    this.target='';
    this.rel ='';

    if(this.elementTextCol.openNewTab ?? false)
    {
      this.target = '_blank';
      this.rel='noopener noreferrer';
    }
    if(this.elementTextCol.title.length > 45)
    {
      this.element_45 = this.elementTextCol.title.substring(0,45) + "...";
    }
    this.subtitle_40 = this.elementTextCol.subtitle;

  }

  listenerCallback(button:any){
    button.callbackAction(button.objParams);
  }


}
