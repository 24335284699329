import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vinculaciones',
  templateUrl: './vinculaciones.component.html',
  styleUrls: ['./vinculaciones.component.css']
})
export class VinculacionesComponent implements OnInit {
  title = {
    title: 'Retos donde quiero participar'
  };

  sidebarExpanded = false;
   lang : string = 'es';


  ngOnInit(): void {
    this.lang = localStorage.getItem('lang') ?? 'es';
  }

  changeLang(lang:string){
    this.lang  = lang;
    localStorage.setItem('lang',lang);
    window.location.reload();
  }
}
