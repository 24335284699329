<div class="row form-perfil mb-4">
  <div class="col-md-9 text-form">

    <div *ngIf="vDatosDocente">
      <!-- Título -->
      <div class="row">
        <div class="col" id="sec_tit">
          <h2>Datos Docente</h2>
        </div>
      </div>

      <!-- Email -->
      <div [formGroup]="frmEmail">
        <div class="row">
          <div class="col">
            <label for="usuario">Correo electrónico <span class="text-danger">*</span></label>
            <app-text-input [formControl]="$any(frmEmail.controls['usuario'])" [label]="'Email'"
              [placeholder]="'ej. beatriz.pinzon@tec.mx'"></app-text-input>
            <div
              *ngIf="!frmEmail.controls.usuario.valid && (frmEmail.controls.usuario.dirty || frmEmail.controls.usuario.touched)">
              <span class="text-danger fs-6">Debe ser un email de profesor del Tecnológico de Monterrey</span>

            </div>

            <label class="form-text mt-3" *ngIf="existeEmail">Ya existe una cuenta con ese correo:
              <a href="/validasesion" class="linkNegritas">Inicia sesión</a>
              ó
              <a [routerLink]="" class="linkNegritas linkclikeable" (click)="recuperaContrasena()">recupera tu contraseña</a></label>
          </div>
        </div>


        <!-- botonera -->
        <div class="col-12 mt-3 text-center">
          <button class="btn-amarillo mx-sm-3 mt-3" type="button" (click)="validaEmail()">Validar</button>
          <button class="btn-blue  mx-sm-3 mt-3" type="button" (click)="regresar()">Regresar</button>
        </div>
      </div>
    </div>

    <div *ngIf="vRegistroDocente">
      <!-- Título -->
      <div class="row">
        <div class="col" id="sec_tit">
          <h2>Datos Docente</h2>
        </div>
      </div>


      <form [formGroup]="contactForm" (submit)="contactPwd()">

        <!-- Email -->
        <div class="row">
          <div class="col">
            <label for="usuario">Correo electrónico </label>
            <div>{{ this.frmEmail.get('usuario')?.value }}</div>
          </div>
        </div>




        <!-- Nombre -->
        <div class="row">
          <div class="col">
            <label for="nombreUsuario">Nombre(s) <span class="text-danger">*</span></label>
            <app-text-input [formControl]="$any(contactForm.controls['nombreUsuario'])" [label]="'Nombre'"
              [placeholder]="'Escribe el nombre aquí'"></app-text-input>
          </div>
        </div>

        <!-- Apellidos -->
        <div class="row">
          <div class="col">
            <label for="apellidosUsuario">Apellidos <span class="text-danger">*</span></label>
            <app-text-input [formControl]="$any(contactForm.controls['apellidosUsuario'])" [label]="'Apellidos'"
              [placeholder]="'Escribe los apellidos aquí'"></app-text-input>
          </div>
        </div>

        <!-- Nómina -->
        <div class="row">
          <div class="col">
            <label for="nomina">Nómina <span class="text-danger">*</span></label>
            <app-text-input [formControl]="$any(contactForm.controls['nomina'])" [label]="'Nómina'"
              [placeholder]="'Escribe tu nómina incluyendo L0 y 7 dígitos'"></app-text-input>

            <div *ngIf="contactForm.get('nomina')?.touched && contactForm.get('nomina')?.errors?.['pattern']"
              class="invalid-feedback-">
              El campo debe tener L0 y 7 dígitos. Ej: L01234567
            </div>
          </div>
        </div>

        <!-- Campus -->
        <div class="row">
          <div class="col">
            <label for="idCampus">Campus <span class="text-danger">*</span></label>

            <div class="select-dropdown">

              <ng-select class="colorGray" [items]="campusData$ | async" bindLabel="campus" bindValue="idCampus"
                placeholder="Selecciona una opción" formControlName="idCampus">
              </ng-select>

              <div *ngIf="contactForm.get('idCampus')?.touched && contactForm.get('idCampus')?.errors?.['required']"
                class="invalid-feedback-">
                {{ "text_input.campo_requerido" | translate: { campo: 'Campus' } }}
              </div>
            </div>
          </div>
        </div>

        <!-- Escuela -->
        <div class="row">
          <div class="col">
            <label for="idEscuela">Escuela <span class="text-danger">*</span></label>
            <div class="select-dropdown">

              <ng-select [items]="escuelasData$ | async" bindLabel="escuela" bindValue="idEscuela"
                placeholder="Selecciona una opción" formControlName="idEscuela" (change)="obtenerDepartamentos($event)">
              </ng-select>

              <div *ngIf="contactForm.get('idEscuela')?.touched && contactForm.get('idEscuela')?.errors?.['required']"
                class="invalid-feedback-">
                {{ "text_input.campo_requerido" | translate: { campo: 'Escuela' } }}
              </div>
            </div>
          </div>
        </div>

        <!-- Departamento -->
        <div class="row">
          <div class="col">
            <label for="departamento">Departamento <span class="text-danger">*</span></label>
            <div class="select-dropdown">

              <ng-select [items]="escuelaDepartamentosData" bindLabel="departamento" bindValue="idDepartamento"
                placeholder="Selecciona una opción" formControlName="idEscuelaDepartamento">
              </ng-select>

              <div
                *ngIf="contactForm.get('idEscuelaDepartamento')?.touched && contactForm.get('idEscuelaDepartamento')?.errors?.['required']"
                class="invalid-feedback-">
                {{ "text_input.campo_requerido" | translate: { campo: 'Departamento' } }}
              </div>
            </div>


          </div>
        </div>

        <!-- Rol -->
        <div class="row">
          <div class="col">
            <label for="rol">Rol <span class="text-danger">*</span></label>

            <div class="select-dropdown">

              <ng-select class="colorGray" [items]="rolesAcademicosData$ | async" bindLabel="deRolAcademico"
                bindValue="idRolAcademico" placeholder="Selecciona una opción"
                formControlName="idRolAcademico"></ng-select>


              <div
                *ngIf="contactForm.get('idRolAcademico')?.touched && contactForm.get('idRolAcademico')?.errors?.['required']"
                class="invalid-feedback-">
                {{ "text_input.campo_requerido" | translate: { campo: 'Rol' } }}
              </div>
            </div>

          </div>
        </div>


        <!-- botonera -->
        <div class="col-12 mt-3 text-center">
          <button class="btn-amarillo mx-sm-3 mt-3" type="submit">Continuar</button>
        </div>
      </form>
    </div>

  </div>

  <div class="col-md-9 text-form" *ngIf="vCreaPwd">

    <!-- Título -->
    <div class="row">
      <div class="col" id="sec_tit">
        <h2>Crear mi cuenta</h2>
      </div>
    </div>


    <!-- Crear contraseña -->
    <form [formGroup]="frmCreaPwd" (submit)="updateContact()">
      <div class="row">
        <div class="col">
          <label for="clave1Usuario">Crea una contraseña de mínimo 8 caracteres alfanuméricos <span
              class="text-danger">*</span></label>
          <input type="password" class="form-control" formControlName="clave1Usuario"
            placeholder="Escribe tu contraseña" />
          <div *ngIf="frmCreaPwd.get('clave1Usuario')?.touched && frmCreaPwd.get('clave1Usuario')?.errors?.['required']"
            class="invalid-feedback- ">
            {{ "text_input.campo_requerido" | translate: { campo: 'Contraseña' } }}
          </div>

          <div
            *ngIf="frmCreaPwd.get('clave1Usuario')?.touched && frmCreaPwd.get('clave1Usuario')?.errors?.['minlength']"
            class="invalid-feedback- ">
            Mínimo {{ frmCreaPwd.get('clave1Usuario')?.errors?.['minlength']?.requiredLength }} caracteres
            alfanuméricos.
          </div>

        </div>
      </div>

      <!-- Confirma contraseña -->
      <div class="row">
        <div class="col">
          <label for="clave2Usuario">Confirma tu contraseña <span class="text-danger">*</span></label>
          <input type="password" class="form-control" formControlName="clave2Usuario"
            placeholder="Confirma tu contraseña" />

          <div *ngIf="frmCreaPwd.get('clave2Usuario')?.touched && frmCreaPwd.get('clave2Usuario')?.errors?.['required']"
            class="invalid-feedback-">
            {{ "text_input.campo_requerido" | translate: { campo: 'Contraseña' } }}
          </div>

          <div
            *ngIf="frmCreaPwd.get('clave2Usuario')?.touched && frmCreaPwd.get('clave2Usuario')?.errors?.['minlength']"
            class="invalid-feedback-">
            Mínimo {{ frmCreaPwd.get('clave2Usuario')?.errors?.['minlength']?.requiredLength }} caracteres
            alfanuméricos.
          </div>

          <div *ngIf="frmCreaPwd.errors?.['passwordMissmatchError'] && frmCreaPwd.get('clave2Usuario')?.touched"
            class="invalid-feedback-">
            La contraseña no coincide
          </div>

        </div>
      </div>

      <!-- Aviso de privacidad -->
      <div class="form-check mt-4">
        <div class="d-flex justify-content-center">
          <div class="form-group form-check">
            <input type="checkbox" class="form-check-input" id="terminos_condiciones" name="terminos_condiciones"
              formControlName="terminos_condiciones">
            <label for="terminos_condiciones" class="font18px form-check-label">He leído y acepto
              los
              términos
              del
              <a class="aviso_privacidad" href="https://tec.mx/es/aviso-de-privacidad-plataforma-mps-tec" git>AVISO DE
                PRIVACIDAD</a>. <span class="requerido">*</span></label>
            <div
              *ngIf="frmCreaPwd.get('terminos_condiciones')?.touched && frmCreaPwd.get('terminos_condiciones')?.errors?.['required']"
              class="invalid-feedback-">
              Debe aceptar el aviso de privacidad
            </div>
          </div>
        </div>
      </div>

      <!-- botonera -->
      <div class="col-12 mt-3 text-center">
        <button class="btn-amarillo mx-sm-3 mt-3" type="submit">Crear cuenta</button>
      </div>
    </form>

  </div>

  <div class="col-md-9 text-form" *ngIf="vConfirma">

    <!-- Título -->
    <div class="row">
      <div class="col" id="sec_tit">
        <h2>Confirma tu cuenta</h2>
      </div>
    </div>

    <!-- Mensaje de confirmación -->
    <div class="">
      <span> Se ha creado tu cuenta con éxito. Para acceder a la plataforma, verificala desde el correo electrónico
        que nos proporcionaste.</span>
    </div>

    <!-- botonera -->
    <div class="col-12 mt-3 text-center">
      <button class="btn-amarillo mx-sm-3 mt-3" type="button" (click)="aceptar()">Aceptar</button>
    </div>

  </div>


  <div class="col-md-9 text-form" *ngIf="vRecuperaPwd">

    <!-- Título -->
    <div class="row">
      <div class="col" id="sec_tit">
        <h2>Recupera tu contraseña</h2>
      </div>
    </div>

    <!-- Email -->
    <div class="row">
      <div class="col">
        <label for="email">Correo electrónico <span class="text-danger">*</span></label>
        <app-text-input [formControl]="email_usuario_recupera" [label]="'Email'" ></app-text-input>
        <div *ngIf="!email_usuario_recupera.valid && (email_usuario_recupera.dirty || email_usuario_recupera.touched)">
          <span class="text-danger fs-6">Debe ser un email de profesor del Tecnológico de Monterrey</span>
        </div>
      </div>
    </div>

    <!-- botonera -->
    <div class="col-12 mt-3 text-center">
      <button class="btn-amarillo mx-sm-3 mt-3" type="button" (click)="enviarCodigo()">Enviar código de
        verificación</button>
      <button class="btn-brown mx-sm-3 mt-3" type="button" (click)="regresarInicio()">Cancelar</button>
    </div>

  </div>

  <div class="col-md-9 text-form" *ngIf="vEnviaCodigo">

    <!-- Título -->
    <div class="row">
      <div class="col" id="sec_tit">
        <h2>Recupera tu contraseña</h2>
      </div>
    </div>

    <!-- Email -->
    <form [formGroup]="frmCreaPwd" (submit)="updateContact()"></form>
    <div class="row">
      <div class="col">
        <label for="email">Correo electrónico <span class="text-danger">*</span></label>
        <app-text-input [formControl]="email_usuario_enviac" [label]="'Email'" [disabled]="true"></app-text-input>
        <div *ngIf="!email_usuario_enviac.valid && (email_usuario_enviac.dirty || email_usuario_enviac.touched)">
          <span class="text-danger fs-6 d-none">Debe ser un email de profesor del Tecnológico de Monterrey </span>
        </div>
      </div>
    </div>

    <label class="form-text mt-3">Se ha enviado el código de verificación a tu bandeja de entrada. Cópialo a
      continuación:</label>

    <!-- Código de Verificación -->
    <div class="row">
      <div class="col">
        <label for="email">Código de verificación <span class="text-danger">*</span></label>
        <app-text-input [label]="'codigo'" [formControl]="txt_codigo" [disabled]="true" maxlength="6"></app-text-input>
      </div>
      <label><a href="" class="linkNegritas" (click)="enviarCodigo()">Enviar código nuevo</a></label>
      <div *ngIf="!txt_codigo.valid && (txt_codigo.dirty || txt_codigo.touched)">
        <span class="text-danger fs-6">Debe ser un código de 6 dígitos</span>
      </div>


      <!-- botonera -->
      <div class="col-12 mt-3 text-center">
        <button class="btn-amarillo mx-sm-3 mt-3" type="button" (click)="validaCodigo()">Validar código</button>
        <button class="btn-brown mx-sm-3 mt-3" type="button" (click)="regresarInicio()">Cancelar</button>
      </div>

    </div>

  </div>

  <div class="col-md-9 text-form" *ngIf="vCreaNvoPwd">

    <!-- Título -->
    <div class="row">
      <div class="col" id="sec_tit">
        <h2>Crea tu nueva contraseña</h2>
      </div>
    </div>


    <form [formGroup]="frmCreaNvoPwd" (submit)="cambiaClave()">
      <div class="row">
        <div class="col">
          <label for="clave1Usuario">Crea una contraseña de mínimo 8 caracteres alfanuméricos <span
              class="text-danger">*</span></label>
          <input type="password" class="form-control" formControlName="clave1Usuario"
            placeholder="Escribe tu contraseña" />
          <div *ngIf="frmCreaNvoPwd.get('clave1Usuario')?.touched && frmCreaNvoPwd.get('clave1Usuario')?.errors?.['required']"
            class="invalid-feedback- ">
            {{ "text_input.campo_requerido" | translate: { campo: 'Contraseña' } }}
          </div>

          <div
            *ngIf="frmCreaNvoPwd.get('clave1Usuario')?.touched && frmCreaNvoPwd.get('clave1Usuario')?.errors?.['minlength']"
            class="invalid-feedback- ">
            Mínimo {{ frmCreaNvoPwd.get('clave1Usuario')?.errors?.['minlength']?.requiredLength }} caracteres
            alfanuméricos.
          </div>

        </div>
      </div>

      <!-- Confirma contraseña -->
      <div class="row">
        <div class="col">
          <label for="clave2Usuario">Confirma tu contraseña <span class="text-danger">*</span></label>
          <input type="password" class="form-control" formControlName="clave2Usuario"
            placeholder="Confirma tu contraseña" />

          <div *ngIf="frmCreaNvoPwd.get('clave2Usuario')?.touched && frmCreaNvoPwd.get('clave2Usuario')?.errors?.['required']"
            class="invalid-feedback-">
            {{ "text_input.campo_requerido" | translate: { campo: 'Contraseña' } }}
          </div>

          <div
            *ngIf="frmCreaNvoPwd.get('clave2Usuario')?.touched && frmCreaNvoPwd.get('clave2Usuario')?.errors?.['minlength']"
            class="invalid-feedback-">
            Mínimo {{ frmCreaNvoPwd.get('clave2Usuario')?.errors?.['minlength']?.requiredLength }} caracteres
            alfanuméricos.
          </div>

          <div *ngIf="frmCreaNvoPwd.errors?.['passwordMissmatchError'] && frmCreaNvoPwd.get('clave2Usuario')?.touched"
            class="invalid-feedback-">
            La contraseña no coincide
          </div>

        </div>
      </div>

      <!-- botonera -->
      <div class="col-12 mt-3 text-center">
        <button class="btn-amarillo mx-sm-3 mt-3" type="submit">Aceptar</button>
      </div>
    </form>

  </div>

  <div class="col-md-9 text-form" *ngIf="vConfirmaPwd">

    <!-- Título -->
    <div class="row">
      <div class="col" id="sec_tit">
        <h2>Tu contraseña ha sido actualizada</h2>
      </div>
    </div>

    <!-- Mensaje de confirmación -->
    <div class="">
      <span> Se ha actualizado tu contraseña con éxito. Inicia sesión para acceder a la plataforma.</span>
    </div>

    <!-- botonera -->
    <div class="col-12 mt-3 text-center">
      <button class="btn-amarillo mx-sm-3 mt-3" type="button" (click)="aceptar()">Iniciar Sesión</button>
    </div>

  </div>


  <div class="modal fade" id="idModalCorreoExistente" tabindex="-1" aria-labelledby="ModalCorreoExistente"
    aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title" id="ModalCorreoExistente">Alta de Usuario</h3>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <h3>{{ this.mensajeModal }}</h3>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal">
            Cerrar
          </button>
        </div>
      </div>
    </div>
  </div>
