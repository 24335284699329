import { Component } from '@angular/core';

@Component({
  selector: 'app-btn-regresar',
  templateUrl: './btn-regresar.component.html',
  styleUrls: ['./btn-regresar.component.css']
})
export class BtnRegresarComponent  {


  
  regresa() {
    window.history.back();
  }
}
