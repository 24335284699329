import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { IAreaCanalizacionResponse } from "@shared/Interface/IAreaCanalizacionResponse";
import {
  ISocioFormadorResponse,
  SocioFormadorResponse,
} from "@shared/Interface/ISocioFormadorResponse";
import { ISolicitudGetResponse } from "@shared/Interface/ISolicitudGetResponse";
import { CatalogosService } from "@shared/service/Catalogos/catalogos.service";
import { ProblematicasService } from "@shared/service/Problematicas/problematicas.service";
import { SolicitudesService } from "@shared/service/Solicitudes/solicitudes.service";
import { UsuariosService } from "@shared/service/Usuarios/usuarios.service";
import { ToastrService } from "ngx-toastr";
import { tap, lastValueFrom } from "rxjs";
import { SociosFormadoresService } from "../../shared/service/Socios_Formadores/socios-formadores.service";
import Swal from "sweetalert2";
import {
  FormGroup,
  FormControl,
  FormArray,
  Validators,
  FormBuilder,
} from "@angular/forms";
import {
  ICampus,
  IProgramaAcademico,
  IEscuela,
  IArea,
  IPeriodo,
} from "@shared/Interface/ICatalogos.interface";
import { IProblematica } from "@shared/Interface/IProblematicas";
import { DatePipe } from "@angular/common";
import { FeatureFlagService } from "app/featureModule/feature-flag.service";
import { ISolicitudComentarios } from "@shared/Interface/ISolicitudSocioComentarios";

declare const window: any;
@Component({
  selector: "app-solicitud-problematica",
  templateUrl: "./solicitud-problematica.component.html",
  styleUrls: ["./solicitud-problematica.component.css"],
})
export class SolicitudProblematicaComponent implements OnInit {
  F2_5_HU58: boolean;
  F2_4_HU58: boolean;

  idProblematica: any;
  idSocioFromador: any;
  idSolicitud: any;
  typeSolicitud: any;
  titleSolicitud = {
    title: "Solicitud de Socio Formador que no encontró reto",
  };
  titleSolicitudF2_4 = {
    title: "Socio Formador que no encontró reto",
  };
  socioFormador: ISocioFormadorResponse;
  problematica: any;
  userData: any;
  validationErrors: string[] = [];
  campusData: ICampus[] = [];
  problematicaForm: FormGroup;
  showFormularioCancelacion: boolean = false;
  areasCanalizacionList: IAreaCanalizacionResponse[] = [];
  idsCanalizacion: number[] = [];
  areaCanalizacionRequerido: string = "";
  motivosRechazo: string = "";
  motivosRequeridos: string = "";
  disableBotons: boolean = false;
  solicitud!: ISolicitudGetResponse;
  retosId: number = 0;
  idSocioFormador: number = 0;

  lbl_programasAcademicos = "";
  lbl_campus = "";
  idModalidad: number = 0;
  lbl_campus_coordinador = "";
  areas: any[] = [];

  formModal: any;

  edit: boolean = false;
  seccion1: ICampus[];
  seccion2: ICampus[];
  pedirListaCampus = false;
  semestreData = [1, 2, 3, 4, 5, 6, 7, 8];
  programasAcademicosData: IProgramaAcademico[] = [];
  escuelasData: IEscuela[] = [];
  alcanceData: any = [];
  areaData: IArea[] = [];

  periodoAcademicoData: IPeriodo[];
  pedirPeriodoAcademico = false;
  periodoAcademicoSeleccionado: number;
  lblFechaRegistro: string = "";

  atendidoForm: FormGroup;

  estatusSolForm: FormGroup;

  campusSeleccionado: number;
  campus: ICampus[];
  motivoRechazo: string = "";

  solicitudAceptada: boolean = true;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private sociosFormadoresService: SociosFormadoresService,
    private problematicasService: ProblematicasService,
    private solicitudesService: SolicitudesService,
    private usuariosService: UsuariosService,
    private catalogo: CatalogosService,
    private fb: FormBuilder,
    private datepipe: DatePipe,
    private featureFlagService: FeatureFlagService
  ) {}

  ngOnInit(): void {
    this.formModal = new window.bootstrap.Modal(
      document.getElementById("idModalResolver")
    );

    this.problematica = { claveEstatus: "" };

    this.socioFormador = new SocioFormadorResponse();
    this.getUserData();
    this.getEstatus();

    this.F2_4_HU58 = false; //this.featureFlagService.isFeatureEnabled("F2_4_HU58");
    this.F2_5_HU58 = true; //this.featureFlagService.isFeatureEnabled("F2_5_HU58");

    this.idProblematica = this.route.snapshot.paramMap.get("idProblematica");
    this.idSocioFromador = this.route.snapshot.paramMap.get("idSocioFromador");
    this.typeSolicitud = this.route.snapshot.paramMap.get("type");
    this.idSolicitud = this.route.snapshot.paramMap.get("idSolicitud");

    this.idSocioFromador && this.getSocioFormador(this.idSocioFromador);
    this.getProblematicaDetail(this.idProblematica);

    this.initializeForm(null);
    this.initializarAtendidoFrm();
    this.initFormEstatusSolForm();

    this.getAreasCanalizacion();
    this.getSolicitudById();

    this.catalogo
      .getEscuelas()
      .pipe(tap((resultado: IEscuela[]) => (this.escuelasData = resultado)))
      .subscribe();

    this.catalogo
      .getCampus()
      .pipe(
        tap((resultado: ICampus[]) => {
          this.campusData = resultado;

          if (this.campusData) {
            let halfItems = Math.round(this.campusData.length / 2);
            this.seccion1 = this.campusData.slice(0, halfItems);
            this.seccion2 = this.campusData.slice(halfItems);
          }
        })
      )
      .subscribe();

    // Cargar PeriodosSemestrales   AGO-DIC
    this.catalogo
      .getPeriodo()
      .pipe(
        tap((resultado: IPeriodo[]) => (this.periodoAcademicoData = resultado))
      )
      .subscribe();
  }

  getUserData() {
    this.userData = this.usuariosService.getCurrentUserData();
    if (!this.userData) this.router.navigate(["/"]);
    return this.userData;
  }

  handleAceptar() {
    this.showFormularioCancelacion = false;
    this.formModal.show();
  }

  handleAceptar2_4() {
    this.solicitudesService
      .putSolicitud({
        idSolicitud: this.idSolicitud,
        claveEstatus: "aprobado",
        idSocioFormador: this.idSocioFromador,
        idCVDP: this.getUserData().idUsuario,
        idAcademia: 0,
        idProblematica: this.idProblematica,
        idReto: 0,
        areaCanalizacion: "",
        motivoRechazo: "",
      })
      .subscribe((response) => {
        if (response) {
          Swal.fire({
            text: "Solicitud aprobada",
            icon: "success",
          }).then((res) => {
            window.history.back();
          });
        } else {
          this.toastr.error("Algo Salió mal");
        }
      });
  }

  getSocioFormador(idSocioformador: any) {
    this.sociosFormadoresService
      .getSociosFormadoresById(idSocioformador)
      .pipe(
        tap((response) => {
          this.socioFormador = response;
        })
      )
      .subscribe();
  }

  async getProblematicaDetail(idProblematica: any) {
    try {
      let result = await lastValueFrom(
        this.problematicasService.getProblematicaId(idProblematica)
      );

      this.problematica = {
        ...result,
        listAreas: result.areas,
        areas: this.getAreas(result.areas),
      };

      this.lbl_programasAcademicos = result.programasAcademicos
        .map((x) => x.programaAcademico)
        .join(", ");

      this.initializeForm(this.problematica);
      this.getProgramasAcademicos(this.problematica.idEscuela);
    } catch (error) {
      console.log(error);
    }
  }

  getAreas(areas: any) {
    return Object.values(areas)
      .map((value: any) => value["area"])
      .join(", ");
  }

  handleRechazar() {
    let areasCanalizacionString = "";
    let isValid = this.validarForm();
    if (!isValid) {
      return;
    }
    for (let index = 0; index < this.idsCanalizacion.length; index++) {
      let canalizacion = this.areasCanalizacionList.filter(
        (area) => area.idAreaCanalizacion === this.idsCanalizacion[index]
      );
      if (canalizacion.length > 0) {
        if (areasCanalizacionString === "") {
          areasCanalizacionString = canalizacion[0].areaCanalizacion;
        } else {
          areasCanalizacionString += ", " + canalizacion[0].areaCanalizacion;
        }
      }
    }

    this.solicitudesService
      .putSolicitud({
        // idUsuario: this.getUserData().idUsuario,
        // idSolicitud: this.idSolicitud,
        // claveEstatus: 'rechazado',
        idSolicitud: this.idSolicitud,
        claveEstatus: "rechazado",
        idSocioFormador: this.idSocioFromador,
        idCVDP: this.getUserData().idUsuario,
        idAcademia: 0,
        idProblematica: this.idProblematica,
        idReto: 0,
        areaCanalizacion: areasCanalizacionString,
        motivoRechazo: this.motivosRechazo,
      })
      .subscribe((response) => {
        if (response) {
          Swal.fire({
            text: "Solicitud rechazada",
            icon: "success",
          }).then((res) => {
            window.history.back();
          });
        } else {
          this.toastr.error("Algo Salió mal");
        }
      });
  }

  validarForm(): boolean {
    let isValid: boolean = true;
    this.areaCanalizacionRequerido = "";
    this.motivosRequeridos = "";

    if (this.idsCanalizacion.length === 0) {
      this.areaCanalizacionRequerido =
        "Seleccione al menos una área de canalización";
      isValid = false;
    }
    if (this.motivosRechazo === "") {
      this.motivosRequeridos = "Escriba un motivo para la cancelación";
      isValid = false;
    }
    return isValid;
  }

  async getAreasCanalizacion() {
    try {
      let resultado = <any>(
        await lastValueFrom(this.catalogo.getAreasCanalizacion())
      );
      if (resultado != null && resultado != undefined && resultado.length > 0) {
        this.areasCanalizacionList = resultado;
      }
    } catch (error) {
      console.error("Error al obtener todas las areas de canalización ", error);
    }
  }

  abrirFormularioRechazo() {
    this.showFormularioCancelacion = true;
    this.disableBotons = true;
  }

  seleccionarCheck(idCanalizacion: number) {
    try {
      let checkExiste = this.idsCanalizacion.includes(idCanalizacion);

      if (!checkExiste) {
        this.idsCanalizacion.push(idCanalizacion);
      } else {
        let index = this.idsCanalizacion.findIndex((c) => c === idCanalizacion);
        if (index >= 0) {
          this.idsCanalizacion.splice(index, 1);
        }
      }
    } catch (error) {
      console.error("Error al seleccionar un check ", error);
    }
  }

  revertirCancelacion() {
    this.showFormularioCancelacion = false;
    this.disableBotons = false;
    this.areaCanalizacionRequerido = "";
    this.motivosRequeridos = "";
  }

  async getSolicitudById() {
    try {
      let resultado = <any>(
        await lastValueFrom(
          this.solicitudesService.getSolicitudById(this.idSolicitud)
        )
      );
      if (resultado != null && resultado != undefined) {
        this.solicitud = resultado;
        this.retosId = this.solicitud.idRetoMaestro;
        this.idSocioFormador = this.solicitud.idSocioFormador;
        this.idModalidad = this.solicitud.idModalidad;
        this.lblFechaRegistro =
          this.datepipe.transform(
            this.solicitud?.fechaRegistro,
            "dd/MMM/yyyy"
          ) ?? "";

        this.lbl_campus = resultado.campus.map((x) => x.campus).join(", ");

        const coordinador = resultado.campus.filter(
          (campus) => campus.campusCoordinador
        );

        this.lbl_campus_coordinador = coordinador
          .map((x) => x.campus)
          .join(", ");


          this.estatusSolForm.get("estatus")?.patchValue(resultado.claveEstatus);

          this.getSolicitudComentarios();

      }
    } catch (error) {
      console.error(
        "Error al obtener el detalle de la solicitud por su Id ",
        error
      );
    }
  }

  editar() {
    this.edit = true;
    this.showFormularioCancelacion = false;
  }
  verifySelected(id: string | number, inputForm: string) {
    return this.problematicaForm.get(inputForm)?.value === id;
  }

  selectedCampus: ICampus[] = [];
  onCheckboxChange(event: any, typeData: string) {
    if (typeData === "campus") {
      if (event.target.checked) {
        const obj = this.campusData.find((x) => {
          return x.idCampus === +event.target.value;
        });
        this.selectedCampus.push(obj!);
      } else {
        const index = this.selectedCampus.findIndex(
          (x) => x.idCampus === +event.target.value
        );
        this.selectedCampus.splice(index, 1);
      }
    }
    const selectedAreas = this.problematicaForm.controls[typeData] as FormArray;
    if (event.target.checked) {
      selectedAreas.push(new FormControl(event.target.value));
    } else {
      const index = selectedAreas.value.findIndex(
        (x: number) => x === parseInt(event.target.value)
      );
      selectedAreas.removeAt(index);
    }
  }
  verifyChecked(id: string | number, inputForm: string) {
    const areas = this.problematicaForm.get(inputForm)?.value;
    if (typeof areas === "object") {
      return areas.includes(id);
    } else {
      return areas === id;
    }
  }

  onSelectEscuela(event: Event) {
    const idEscuela = parseInt((event.target as HTMLInputElement).value);
    this.getProgramasAcademicos(idEscuela);
  }

  getProgramasAcademicos(idEscuela: number) {
    this.catalogo
      .getProgramasAcademicos(idEscuela)
      .pipe(
        tap(
          (resultado: IProgramaAcademico[]) =>
            (this.programasAcademicosData = resultado)
        )
      )
      .subscribe();
  }
  extractIds(data: any, id: string) {
    if (data) {
      return Object.values(data).map((value: any) => value[id]);
    }
    return;
  }

  initializeForm(problematica: IProblematica | null) {
    if (problematica?.idEscuela) {
      this.getProgramasAcademicos(problematica?.idEscuela);
    }
    let areas = this.extractIds(problematica?.areas, "idArea") ?? [];
    let campusCoord = this.solicitud?.campus
      .filter((x) => x.campusCoordinador)
      .pop()?.idCampus;
    this.selectedCampus =
      this.solicitud?.campus.map((x) => {
        return { ...x, idCampus: Number(x.idCampus) };
      }) ?? [];
    let programas =
      this.extractIds(
        problematica?.programasAcademicos,
        "idProgramaAcademico"
      ) ?? [];

    this.problematicaForm = this.fb.group({
      proyecto: [problematica?.proyecto ?? "", Validators.required],
      problematica: [problematica?.problematica ?? "", Validators.required],
      objetivos: [problematica?.objetivos ?? "", Validators.required],
      entregables: [problematica?.entregables ?? ""],
      areas: this.fb.array(areas),
      campus: [this.selectedCampus.map((x) => x.idCampus)],
      campusCoordinador: [campusCoord ?? ""],
      otraArea: [problematica?.otraArea ?? ""],
      idAlcance: [problematica?.idAlcance ?? "", Validators.required],
      idEscuela: [
        problematica?.idEscuela !== 0 ? problematica?.idEscuela : "",
        Validators.required,
      ],
      programasAcademicos: this.fb.array(programas),
      semestre: [
        problematica?.semestre !== 0 ? problematica?.semestre : "",
        Validators.required,
      ],
      idPeriodo: problematica?.idPeriodo,
    });
  }

  seleccionarPeriodo(event: any) {
    this.periodoAcademicoSeleccionado = event.target.value;
  }

  initializarAtendidoFrm() {
    this.atendidoForm = this.fb.group({
      estatusReto: [],
      comentariosAtendido: ["", Validators.required],
    });
  }

  async guardarRespuesta() {
    try {
      this.atendidoForm.markAllAsTouched();

      if (this.atendidoForm.valid) {
        await lastValueFrom(
          this.solicitudesService.putSolicitud({
            idSolicitud: this.idSolicitud,
            claveEstatus: "aprobado",
            idSocioFormador: this.idSocioFromador,
            idCVDP: this.getUserData().idUsuario,
            idAcademia: 0,
            idProblematica: this.idProblematica,
            idReto: 0,
            areaCanalizacion: "",
            motivoRechazo: "",
            idModalidad: this.idModalidad,
          })
        );

        let estatusReto = this.atendidoForm.get('estatusReto')?.value;
        let comentario = estatusReto == 'Otro' ? 'Otro' : 'Se canalizó a: ' + estatusReto;
        comentario += ' - ' + this.atendidoForm.get('comentariosAtendido')?.value;

        this.saveComment('aprobado',comentario);


        Swal.fire({
          text: "Solicitud aprobada",
          icon: "success",
        }).then((res) => {
          if (res.isConfirmed) {
            this.formModal.hide();
            window.history.back();
          }
        });
      }
    } catch (err) {
      console.log(err);
      this.toastr.error("Algo Salió mal, intenta más tarde.");
    }
  }

  initFormEstatusSolForm() {
    this.estatusSolForm = this.fb.group({
      estatus: [],
      comentario: [],
    });
  }

  seleccionarCampus($event) {
    this.campusSeleccionado = $event?.target?.value ?? $event.idCampus;
  }

  enviarSolicitud() {
    //if (this.mostrarReasignar) {
    Swal.fire({
      input: "textarea",
      inputLabel: "Motivo de reasignación",
      inputPlaceholder: "Escribe el motivo de reasignación",
      inputAttributes: {
        "aria-label": "Escribe el motivo de reasignación",
      },
      showCancelButton: true,
    }).then((response) => {
      if (response.isConfirmed) {
        this.motivoRechazo = response.value;

        this.solicitudesService
          .putSolicitudEstatus({
            idUsuario: this.userData.idUsuario,
            idSolicitud: this.idSolicitud,
            claveEstatus: "reasignado",
            idAcademia: 0,
            idCampus: this.campusSeleccionado,
            motivoEstatus: this.motivoRechazo,
          })
          .subscribe({
            next: (resultOK) => {
              this.toastr.success("Solicitud reasignada con éxito.");
              this.router.navigateByUrl("/dashboard");
            },
            error: (error) => {
              this.toastr.error("Hubo un error, intenta más tarde.");
              console.log("reasignado", error);
            },
          });
      }
    });
    //}
  }

  cancelarEdicion() {
    Swal.fire({
      text: "Si cancelas se perderán los cambios, ¿Continuar?",
      showCancelButton: true,
      cancelButtonText: "No",
      confirmButtonText: "Si",
    }).then((result) => {
      if (result.isConfirmed) {
        this.edit = false;
      }
    });
  }

  async guardarEdicion() {
    try {
      let claveEstatus = this.estatusSolForm.get("estatus")?.value;
      //let comentario = this.estatusSolForm.get("comentario")?.value ?? "";
      let idPeriodo = this.problematicaForm.get("idPeriodo")?.value;
      let idEscuela = this.problematicaForm.get("idEscuela")?.value;
      let programasAcademicos = this.problematicaForm.get("programasAcademicos")
        ?.value;
      let campus = this.problematicaForm.get("campus")?.value;
      let semestre = this.problematicaForm.get("semestre")?.value;
      let idUsuario = this.usuariosService.getCurrentUserData().idUsuario;
      let areas = this.problematica.listAreas;

      let listCampus = this.selectedCampus.map((x) => {
        return {
          ...x,
          esCoordinador:
            this.problematicaForm.get("campusCoordinador")?.value == x.idCampus
              ? 1
              : 0,
          campusCoordinador:
            this.problematicaForm.get("campusCoordinador")?.value == x.idCampus
              ? true
              : false,
        };
      });

      let solicitudRequest = {
        idSolicitud: this.idSolicitud,
        claveEstatus: claveEstatus,
        //motivoRechazo: comentario,
        idPeriodo: idPeriodo,
        campus: listCampus,
      };

      let resultado = await lastValueFrom(
        this.solicitudesService.putSolicitud(solicitudRequest)
      );

      let problematicaRequest = {
        idProblematica: this.idProblematica,
        proyecto: this.problematica.proyecto,
        problematica: this.problematica.problematica,
        objetivos: this.problematica.objetivos,
        entregables: this.problematica.entregables,
        idEscuela: idEscuela,
        idAlcance: this.problematica.idAlcance,
        semestre: semestre,
        otraArea: this.problematica.otraArea,
        idPeriodo: idPeriodo,
        programasAcademicos: programasAcademicos,
        campus: listCampus.map((x) => x.idCampus),
        areas: areas.map((x) => x.idArea),
        idCampusCoordinador: this.problematicaForm.get("campusCoordinador")
          ?.value,
        accion: "update",
      };

      let resultadoProblematica = await lastValueFrom(
        this.problematicasService.putProblematica(problematicaRequest)
      );

      //await this.saveComment(claveEstatus, comentario);

      this.edit = false;
      this.toastr.success("Solicitud editada con éxito.");
      this.ngOnInit();
    } catch (error) {
      console.log(error);
    }
  }

  obtenerEstatusSeguimiento(estatus: string) {
    if (estatus)
      return this.estatusData.find((x) => x.claveEstatus == estatus).estatus;
  }

  async getEstatus() {
    try {
      this.estatusData = await lastValueFrom(
        this.catalogo.getEstatus()
      );
    } catch (error) {
      console.log(error);
    }
  }

  async getSolicitudComentarios() {
    try {
      this.comentariosData = [];
      this.comentariosData = await lastValueFrom(
        this.solicitudesService.getSolicitudComentarios(
          this.solicitud.idSolicitud
        )
      );
    } catch (error) {
      console.log(error);
    }
  }

  async saveComment(estatus: string, comentario: string) {
    try {
      let idUsuario = this.usuariosService.getCurrentUserData().idUsuario;
      let result = await lastValueFrom(
        this.solicitudesService.postSolicitudComentario({
          idSolicitud: this.solicitud.idSolicitud,
          claveEstatus: estatus,
          comentarios: comentario,
          idUsuarioRegistro: idUsuario,
        })
      );
      this.solicitudAceptada = true;

      await lastValueFrom(this.solicitudesService
        .putSolicitudEstatus({
          idSolicitud: this.idSolicitud,
          claveEstatus: estatus,
          idUsuario: idUsuario
        }));

    } catch (err) {
      this.toastr.error("Hubo un error al guardar el comentario");
      console.log(err);
    }
  }

  async guardarComentarios() {
    let estatus = this.estatusSolForm.get("estatus")?.value ?? this.solicitud.claveEstatus;
    let comentario = this.estatusSolForm.get("comentario")?.value;

    if (comentario.length == 0) {
      this.toastr.error("El comentario no puede estar vacio");
      return;
    }

    await this.saveComment(estatus, comentario);

    this.getSolicitudComentarios();
    this.toastr.success("Comentario guardado correctamente");
    this.estatusSolForm.get("comentario")?.patchValue('');



  }

  comentariosData: ISolicitudComentarios[] = [];
  panelOpenState = false;
  estatusData: any = [];
}
