import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { environment } from 'environments/environment';
@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css'],
})
export class PaginationComponent implements OnInit {
  @Input() objPagination: any= [];
  paginationRecords = 0;
  paginationPages: any;
  @Input()
  recordsPerPage = environment.RegistrosPagina;
  currentPage = 1;
  objData: any;
  @Output() paginationDataChange: EventEmitter<any> = new EventEmitter<any>();

  maxShowPages = 5;
  totalPages = 0;


  ngOnInit(): void {
    
    this.setPagination();
  }
  
  
  setPagination(){
    this.totalPages = 0;
    let pagination: any = [];
    if (this.objPagination.length) {
      this.paginationRecords = this.objPagination?.length;
      this.totalPages = Math.ceil(this.paginationRecords / this.recordsPerPage);
      for (let index = 1; index <= this.totalPages; index++) {
        pagination.push(index);
      }
      if (pagination) {

        if(pagination.length > this.maxShowPages){        
          if ( this.currentPage -1 < (this.totalPages-this.maxShowPages)){
            pagination = pagination.slice(this.currentPage-1,this.currentPage + this.maxShowPages-1);
          }
          else{
            if (pagination.length != this.maxShowPages ){
              pagination = pagination.slice(this.totalPages - this.maxShowPages ,this.totalPages );
            }
          }
        }
      
        
        this.paginationPages = pagination;
      }
      this.getDataPagination();
    }
  }

  updateCurrentPage(page: number) {
    this.currentPage = page;
    
    this.setPagination();
    window.scrollTo(0,0);
  }

  getDataPagination() {
    const initialValue =
      this.currentPage === 1 ? 0 : (this.currentPage - 1) * this.recordsPerPage;
    this.objData = this.objPagination.slice(
      initialValue,
      initialValue === 0
        ? this.recordsPerPage
        : this.currentPage * this.recordsPerPage
    );
    this.callBackPagination(this.objData);
  }

  callBackPagination(objData: any) {
    this.paginationDataChange.emit(objData);
  }
}
