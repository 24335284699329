<div class="row progwidth" style="width: 13rem">
  <div
    *ngIf="elementsProgress.percentage < 100"
    style="text-align: left;"
  >
    {{ elementsProgress.current }} de {{ elementsProgress.total }} 
  </div>
  <div style="margin-left: -55%" *ngIf="elementsProgress.percentage >= 100">Concluido</div>
  <div class="progress" style="height: 10px">
    <div
      *ngIf="elementsProgress.percentage < 100"
      class="progress-bar bg-info"
      role="progressbar"
      attr.style="width: {{ elementsProgress.percentage }}%"
      attr.aria-valuenow="{{ elementsProgress.current }}"
      aria-valuemin="0"
      attr.aria-valuemax="{{ elementsProgress.total }}"
    ></div>
    <div
      *ngIf="elementsProgress.percentage >= 100"
      class="progress-bar bg-success"
      role="progressbar"
      style="width: 130%"
      aria-valuenow="10"
      aria-valuemin="0"
      aria-valuemax="100"
    ></div>
  </div>
</div>
<!-- 
<div class="row" *ngIf="elementsProgress.percentage < 100">
  {{ elementsProgress.current }} de {{ elementsProgress.total }} Semanas
</div>

<div class="row" *ngIf="elementsProgress.percentage === 100">Concluido</div>

<div class="st-bar">
  <div class="progress">
    <div
      class="progress-bar bg-info"
      role="progressbar"
      style="width: 6.6%"
      aria-valuenow="10"
      aria-valuemin="0"
      aria-valuemax="100"
    ></div>
  </div>
</div> -->
