import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import Utils from '@shared/helpers/utils';
import { ITipoRecurso } from '@shared/Interface/ICatalogos.interface';
import { CatalogosService } from '@shared/service/Catalogos/catalogos.service';
import { RetosService } from '@shared/service/Retos/retos.service';
import { UsuariosService } from '@shared/service/Usuarios/usuarios.service';
import { lastValueFrom } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-reto-recursos',
  templateUrl: './reto-recursos.component.html',
  styleUrls: ['./reto-recursos.component.css']
})
export class RetoRecursosComponent implements OnInit {
  recursosForm: FormGroup;
  recursosList: any[] = [];
  tipoRecurso: ITipoRecurso[] = [];
  clavePerfil : string = "";

  @Input() idReto: number;
  @Input() status: string;
  @Output() recursos: EventEmitter<any[]> = new EventEmitter<any[]>();
  @ViewChild('closebuttonRecursos') closebutton;

  recursoPlace:any={
    'recurso': 'Humano',
    'descripcion': 'Escribe a que se refiere el recurso',
    'momento': 'Escribe aquí en que momento requieres el recurso'
  }
  constructor(
    private fb: FormBuilder,
    private retoService: RetosService,
    private catalogoService: CatalogosService,
    private usuarioService: UsuariosService
    ) {

   }

  ngOnInit(): void {
    this.recursosForm = this.fb.group({
      idReto: [this.idReto],
      idTipoRecurso: ['', Validators.required],
      temporalidad: ['', Validators.required],
      descripcion: ['', Validators.required]
    });
    this.clavePerfil = this.usuarioService.getCurrentUserData().clavePerfil;
    this.getTipoRecursos();
    this.getRecursos();
  }

  async getTipoRecursos(){
    try {
      this.tipoRecurso = await lastValueFrom(this.catalogoService.getTipoRecursos());
    } catch (error) {
      console.log(error);
    }

  }

  async addRecurso() {
    this.recursosForm.markAllAsTouched();
    if(this.recursosForm.valid){
      this.recursosList.push(this.recursosForm.value);
      this.recursos.emit(this.recursosList);
      try{
        let request = {
          idReto: this.idReto,
          idTipoRecurso: this.recursosForm.get('idTipoRecurso')?.value,
          temporalidad: this.recursosForm.get('temporalidad')?.value,
          descripcion: this.recursosForm.get('descripcion')?.value,
        }
        await lastValueFrom(this.retoService.postRetoRecurso(request));
        this.recursosForm.patchValue({ idTipoRecurso: null, momento: null, descripcion: null });
        this.getRecursos();
        this.recursosForm.reset();
        this.closebutton.nativeElement.click();
      }catch(error){
        this.closebutton.nativeElement.click();
        console.log(error);
      }
    }
  }

  async getRecursos(){
    try {
      this.recursosList = [];
      this.recursosList = await lastValueFrom( this.retoService.getAllRetoRecurso(this.idReto));

    } catch (error: any) {
      Utils.processErrorResponse(error);
    }
  }

  eliminar(idRetoRecurso: number) {

    Swal.fire({
      text:'¿Desea eliminar el recurso?',
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
      showCancelButton: true,
    }).then(async (response)=>
    {
      try {
      if(response.isConfirmed){
        await lastValueFrom(this.retoService.deleteRetoRecurso(idRetoRecurso));
        await this.getRecursos();
      }
    } catch (error:any) {
      Utils.processErrorResponse(error);
     }
    });

}

}
