import { Component } from '@angular/core';
import { RecursosService } from '@shared/service/Recursos_de_vinculacion/recursos.service';
import { IRecursosVinculacion } from '@shared/Interface/IRecursosVinculacion';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-vinculacion',
  templateUrl: './vinculacion.component.html',
  styleUrls: ['./vinculacion.component.css'],
})
export class VinculacionComponent  {
  titleShowMore = {
    title: 'Recursos de Vinculación',
    link: '/recursos-vinculacion-sf',
  };
  recursosV: IRecursosVinculacion[]=[];
  recursosV2: IRecursosVinculacion[]=[];

  constructor(private _recursosService:RecursosService) { 
    this.getVinculacion('activo');}


  async getVinculacion(estatus: string) {
    try {
      this.recursosV = [];
      let response = <any>await lastValueFrom(this._recursosService
        .getRecursosEstatus(estatus));
      
      if (response != null && response != undefined && response.length > 0) {    
          this.recursosV = response.filter(x => x.idTipoRecursoVinculacion == 1);
          this.recursosV2 = response.filter(x => x.idTipoRecursoVinculacion == 2);
        }      
    } catch (error) {
      console.log('Error al realizar la peticion', error);
    }
  }
  
}
