<div class="row mt-5" *ngIf="status!='formalizacion' || clavePerfil === 'socio'">

  <div class="subtitlecard textblue">COMPROMISOS Y RECURSOS DEL SOCIO FORMADOR </div>

  <div class="section my-4">

    <span class="title-option">COMPROMISOS</span>

    <div class="row  mt-2" *ngFor="let compromiso of compromisosList; let i=index">
      <div class="col-11">
        <span class="title-option-small mt-2">Compromiso {{i+1}} <br></span>

        <label class="title-option-small mt-1" for="descripcion{{i}}"><u>a. Descripción:</u>&nbsp;</label>
        <label class="txt-option">{{compromiso.descripcion}}</label>
        <br>

        <label class="title-option-small" for="momento{{i}}"><u>b. Momento de la entrega:</u>&nbsp;</label>
        <label class="txt-option-small">{{compromiso.momento}}</label> <br>
      </div>
    </div>

    <div *ngIf="compromisosList.length == 0">
      <span class="txt-option"> No hay compromisos registrados.</span>
    </div>

  </div>

</div>
<div class="section">
  <div class="row " *ngIf="status === 'formalizacion'  && ['cvdp','cvdp_admin','academia'].includes(clavePerfil)">
    <div class="col-1"><button _ngcontent-cen-c213="" class="step-button-active text-center"> 7 </button>
    </div>
    <div class="col-11">
      <span class="title-option">Menciona los compromisos que adquiere el Socio Formador. <span
          class="text-danger">*</span></span>

      <div class="row row-item my-2" *ngFor="let compromiso of compromisosList; let i=index">
        <div class="col-11">
          <span class="title-option-small mt-2">Compromiso {{i+1}} <br /></span>

          <label class="title-option-small mt-1" for="descripcion{{i}}">Descripción de la actividad:&nbsp;</label>
          <label class="txt-option-small">{{compromiso.descripcion}}</label> <br>

          <label class="title-option-small" for="momento{{i}}">Momento:&nbsp;</label>
          <label class="txt-option-small">{{compromiso.momento}}</label> <br>
        </div>
        <div class="col-1 d-flex align-items-center">
          <button type="button" class="lst_bt_brr"
            (click)="eliminar(compromiso.idReto,compromiso.idRetoCompromiso)"></button>
        </div>
      </div>

      <!-- Button trigger modal -->
      <button type="button" class="add_button mt-4 mb-4" data-bs-toggle="modal" data-bs-target="#compromisoFormModal">
        Agregar compromiso
      </button>

      <!-- Modal -->
      <div class="modal fade" id="compromisoFormModal" data-bs-backdrop="static" tabindex="-1"
        aria-labelledby="compromisoFormModalLabel" aria-hidden="true" data-bs-keyboard="false" tabindex="-1">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="compromisoFormModalLabel">Agregar un compromiso</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" #closebuttonCompromiso></button>
            </div>
            <div class="modal-body">
              <form [formGroup]="compromisosForm">
                <br>
                <span class="# "> <u> Descripción de la actividad: </u></span>
                <input class="form-control" id="descripcion" type="text" formControlName="descripcion"
                  placeholder="Escribe aquí la actividad que requieres por parte del Socio Formador">
                <div class="required"
                  *ngIf="compromisosForm.get('descripcion')?.touched && compromisosForm.get('descripcion')?.errors?.['required']">
                  Este campo es requerido</div>
                <br>
                <span class="# "> <u> Especifica en qué momento requieres la actividad (temporalidad)
                  </u></span>
                <input class="form-control" id="momento" type="text" formControlName="momento"
                  placeholder="Escribe aquí en qué momento requieres la actividad">
                <div class="required"
                  *ngIf="compromisosForm.get('momento')?.touched && compromisosForm.get('momento')?.errors?.['required']">
                  Este campo es requerido</div>
              </form>
            </div>
            <div class="modal-footer">
              <button type="button" class="add_form_button mt-4 mb-4" (click)="addCompromiso()">Guardar</button>
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
            </div>
          </div>
        </div>
      </div>




    </div>
  </div>
</div>
