import { Component, OnInit } from '@angular/core';
import { IRecursosVinculacion } from '@shared/Interface/IRecursosVinculacion';
import { RecursosService } from '@shared/service/Recursos_de_vinculacion/recursos.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-recursos-vinculacion-sf',
  templateUrl: './recursos-vinculacion-sf.component.html',
  styleUrls: ['./recursos-vinculacion-sf.component.css']
})
export class RecursosVinculacionSfComponent implements OnInit {
  data: IRecursosVinculacion[];
  data2: IRecursosVinculacion[];

  constructor(private _recursosService:RecursosService) {  }

  ngOnInit(): void { 
    this.getVinculacion('activo');
  }

  async getVinculacion(estatus: string) {
    try {
      this.data = [];
      this.data2 = [];
      let response = <any>await lastValueFrom(this._recursosService
        .getRecursosEstatus(estatus));
        
      if (response != null && response != undefined && response.length > 0) {
    
          this.data = response.filter(x=>x.idTipoRecursoVinculacion == 1);
          this.data2 = response.filter(x=>x.idTipoRecursoVinculacion == 2);
        }
      
    } catch (error) {
      console.log('Error al realizar la peticion', error);
    }
    
  }

}
