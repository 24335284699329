<div id="div" class="d-block d-md-none bg-white">

  <div id="sidebar_mb">
    <ul class="list-unstyled">


      <li *ngFor="let item of sidebarItems.funciones" (click)="irMenu(item.opcion ,item.ruta)"
        [ngClass]="{'active' : menuActivo === item.opcion}">
        <a >
          <span>{{ item.funcion }}</span>
        </a>
      </li>

      <li (click)="logout()">
        <a (click)="logout()"><span>Salir</span></a>
      </li>
    </ul>
  </div>
</div>


<div id="cuerpo" class="d-flex">

  <div class="sidebar d-none d-md-block">
    <div style="position:fixed;width: 8.89vw;height: 100%;background-color: #BEDCFE;z-index: -1;"></div>
    <nav id="sidebar" class="org">
      <div id="sidebar-header">
        <img alt="" src="assets/img/sello_n.svg">
      </div>

      <div id="sidebar-close">
        <img alt="" class="iCn" src="assets/img/icon_cerrar.svg">
      </div>


      <ul class="list-unstyled">

        <li *ngFor="let item of sidebarItems.funciones" (click)="irMenu(item.opcion ,item.ruta)"
          [ngClass]="{'active' : menuActivo === item.opcion}">
          <a >
            <img width="24px" alt="" class="iCn" [src]="getPathImg(item.icon)" />
            <span>{{ item.funcion }}</span>
          </a>
          <span class="tooltip">{{ item.funcion }}</span>
        </li>

      </ul>

    </nav>
  </div>
</div>