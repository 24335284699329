import {
  Component,
  Input,
  Output,
  Self,
  EventEmitter,
} from '@angular/core';
import {
  ControlValueAccessor,
  NgControl,
} from '@angular/forms';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.css'],
})
export class CheckboxComponent implements  ControlValueAccessor {
  @Input()
  name: string | number;
  @Input()
  label: string;
  @Input()
  type = 'checkbox';
  @Input() value: string | number;
  @Input() formArrayName: any;
  @Output() onCheckboxValue: EventEmitter<any> = new EventEmitter<any>();

  constructor(@Self() public ngControl: NgControl) {
    this.ngControl.valueAccessor = this;
  }



  writeValue(obj: any): void {
    // Implementar si es necesario
    //console.log("write");
  }
  registerOnChange(fn: any): void {
    // Implementar si es necesario
    //console.log("OnChange");
  }
  registerOnTouched(fn: any): void {
    // Implementar si es necesario
    //console.log("OnTouched");
  }

  onInputChange(event: Event) {
    // const selectedAreas = this.form.controls['idArea'] as FormArray;
    //console.log('event', event);
    this.onCheckboxValue.emit(event);
  }
  }
