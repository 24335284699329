<div class="modal fade" id="idAvisoPrivacidadModal" tabindex="-1" aria-labelledby="AvisoPrivacidadModal"
    aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content col-12">
            <div class="modal-header">
                <h1 class="modal-title" id="AvisoPrivacidadModal">Aviso de Privacidad</h1>
                <br /><br />
            </div>
            <div class="modal-body position-relative">
                <span>Para acceder a la plataforma de Socios Formadores, debe aceptar el <br /><br />
                    <a class=" position-absolute top-50 start-50 translate-middle" alt="Link Aviso de Privacidad"
                        target="_blank" rel="noopener noreferrer" href="https://tec.mx/es/aviso-de-privacidad-plataforma-mps-tec">Aviso de
                        Privacidad</a></span>
                <br /><br />
                <span>
                    Si decide no aceptar el Aviso de Privacidad su sesión se cerrará.
                </span>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                    (click)="cancelarAvisoPrivacidad()">
                    No Acepto
                </button>
                &nbsp;
                <button type="button" class="btn btn-primary" (click)="guardarAvisoPrivacidad()">
                    Si, He leído y Acepto
                </button>
            </div>
        </div>
    </div>
</div>