import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";

import { DashboardService } from "@shared/service/Dashboard/dashboard.service";

import { Router } from "@angular/router";
import { lastValueFrom, tap } from "rxjs";
import { IUser } from "@shared/Interface/user";
import { UsuariosService } from "@shared/service/Usuarios/usuarios.service";
import {
  COLS_ACADEMICO_ME_BUSCA,
  COLS_MIS_RETOS,
  COLS_PROBLEMATICAS,
  COLS_RETOS_QUIERO_PARTICIPAR,
} from "./socio-dashboard.columns";
import { SociosFormadoresService } from "@shared/service/Socios_Formadores/socios-formadores.service";
import { NgbModalConfig, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import Swal from "sweetalert2";
import { saveAs } from "file-saver";
import { ICartaConvenio } from "@shared/Interface/ICartaConvenio";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { IArea, IMedioContacto } from "@shared/Interface/ICatalogos.interface";
import { ContactosService } from "@shared/service/Contactos/contactos.service";
import { CatalogosService } from "@shared/service/Catalogos/catalogos.service";
import { ISolicitudSocioComentarios } from "@shared/Interface/ISolicitudSocioComentarios";
import { SolicitudesService } from "@shared/service/Solicitudes/solicitudes.service";
import {
  COLS_LISTADO_RETOS_FORM,
  COLS_LISTADO_RETOS_CURS,
  COLS_LISTADO_RETOS_CONC,
} from "../cvdp-dashboard/cvdp-dashboard.columns";
import { IRetosCVDPResponse } from "@shared/Interface/IRetosCVDPResponse";
import { FeatureFlagService } from "app/featureModule/feature-flag.service";
@Component({
  selector: "app-perfil-dashboard",
  templateUrl: "./perfil-dashboard.component.html",
  styleUrls: ["./perfil-dashboard.component.css"],
  providers: [NgbModalConfig, NgbModal],
})
export class PerfilDashboardComponent implements OnInit {
  @ViewChild("content") content: ElementRef;
  @ViewChild("content_firm") content_firm: ElementRef;

  F2_5_HU58: boolean;
  F2_4_HU58: boolean;

  retosData: any;
  retosHistorialData: any;
  problematicaData: any;
  problematicaSendData: any;
  problematicaDataRechazado: any;
  problematicaDataAprobado: any;

  nombreContacto: string;
  personaFirmaCarta: string;
  fechaFirmaCarta: string;
  filePdfSeleccionado: string;
  filePdfFirmado: string;
  content_firm_modal;
  Cartafirmada: boolean = false;
  convenioSel: ICartaConvenio;

  backgroundTable = "#F6F6F6";
  mostrarCartaaFirmar: boolean = true;

  titleVinculaciones = {
    title: "Retos donde quiero participar",
    link: "/socio/vinculaciones",
    button: "Buscar un nuevo reto",
    callbackClick: this.vinculacionReto,
  };

  titleRetos = {
    title: "Mis Retos",
    link: "/mis-retos",
  };
  titleProblematicas = {
    title: "Mis Problemáticas Registradas",
    link: "/socio/historial-problematicas",
  };

  titleAcademicosConmigo = {
    title: "Académicos que quieren trabajar con mi organización",
    link: "/socio/solicitud-academicos",
  };

  vinculacionColumns = COLS_RETOS_QUIERO_PARTICIPAR;
  vinculacionEnviadasData: any;
  vinculacionAbiertasData: any;
  vinculacionRechazadasData: any;
  vinculacionAprobadasData: any;

  retosColumns = COLS_MIS_RETOS;

  problematicasColumns = COLS_PROBLEMATICAS;

  userStore: IUser;
  IdSocioFormador: number = 0;

  formularioFormC!: UntypedFormGroup;

  //* Documentacion de retos
  listadoRetosFormalizacionColumns = COLS_LISTADO_RETOS_FORM;
  listadoRetosFormalizacionData: any;

  listadoRetosCursoColumns = COLS_LISTADO_RETOS_CURS;
  listadoRetosCursoData: any;

  listadoRetosEvaluacionColumns = COLS_LISTADO_RETOS_CURS;
  listadoRetosEvaluacionData: any;

  listdaoRetosConcluidoColumns = COLS_LISTADO_RETOS_CONC;
  listadoRetosConcluidoData: any;

  mostrarRetos: boolean = false;
  status: string = "formalizacion";

  //Academico que quiere vincularse conmigo
  academicoColumns = COLS_ACADEMICO_ME_BUSCA;

  constructor(
    private router: Router,
    private dashboardService: DashboardService,
    private usuarioService: UsuariosService,
    private sociosFormadoresService: SociosFormadoresService,
    private config: NgbModalConfig,
    private modalService: NgbModal,
    private readonly fb: UntypedFormBuilder,
    private contactoService: ContactosService,
    private catalogosSvc: CatalogosService,
    private solicitudesService: SolicitudesService,
    private featuraFlagService: FeatureFlagService
  ) {
    config.backdrop = "static";
    config.keyboard = false;
  }

  getRetosQuieroParticipal(resultado) {
    return resultado
      .map((vinculacion: any) => ({
        fechaSolicitud: vinculacion.fechaSolicitud,
        duracion: vinculacion.duracion ?? "-",
        periodoSemanal: vinculacion.periodoSemanal ?? "-",
        periodoSemestral: vinculacion.periodoSemestral ?? "-",
        campusCoordinador: vinculacion.campusCoordinador ?? "-",
        reto: {
          title: vinculacion.reto,
          subtitle: vinculacion.escuela ?? "-",
          link: `/socio/pagina-retos?idRetoMaestro=${vinculacion.idRetoMaestro}`,
        },
        status: {
          textCol: vinculacion.estatusSolicitud,
          statusType: `ev_${vinculacion.claveEstatusSolicitud}`,
        },
        claveEstatusSolicitud: {
          textCol: "Ver",
          statusType: "primary",
          link: `/socio/pagina-retos?idRetoMaestro=${vinculacion.idRetoMaestro}`,
        },
      }))
      .slice(0, 3);
  }

  getMisRetos(resultado) {
    return resultado
      .map((retos: any) => ({
        fechaRegistro: retos.fechaRegistro,
        contactPerson: {
          title: retos.contacto ?? "-",
          subtitle: retos.correoContacto ?? "-",
        },
        duracionSemanas: retos.duracionSemanas,
        periodoSemanal: retos.periodoSemanal ?? "-",
        periodoSemestral: retos.periodoSemestral ?? "-",
        campusCoordinador: retos.campusCoordinador,
        proyecto: {
          title: retos.reto,
          subtitle: retos.escuela || "-",
          link: `/socio/pagina-retos-resumen?reto=${retos.idReto}`,
        },
        porcentajeAvance: {
          total: retos.duracionSemanas,
          current: retos.avanceSemanas,
          percentage: retos.porcentajeAvance,
        },
        claveEstatusSolicitud: {
          textCol: "Ver",
          statusType: "primary",
          link: `/socio/pagina-retos-resumen?reto=${retos.idReto}`,
        },
      }))
      .slice(0, 3);
  }

  getListadoRetosFormalizacion(resultado) {
    return resultado
      .map((solicitud: IRetosCVDPResponse) => ({
        idReto: solicitud.idReto,
        fechaRegistro: solicitud.fechaRegistro,
        reto: {
          title: solicitud.reto,
          subtitle: solicitud.unidadFormativa,
          description: solicitud.escuela,
          link: `/socio/pagina-retos-resumen?reto=${solicitud.idReto}`,
        },
        nombreSocioFormador: {
          title: solicitud.nombreSocioFormador,
          subtitle: solicitud.rfc,
        },
        duracion: solicitud.duracionSemanas ?? "-",
        periodoSemanal: solicitud.periodoSemanal,
        periodoSemestral: solicitud.periodoSemestral,
        imageTitle: {
          title: solicitud.campusCoordinador ?? "",
          subtitle: solicitud.escuela,
        },
        proyecto: solicitud.alcance,
        claveEstatusSolicitud: {
          textCol: "Firmar",
          statusType: "amarillo",
          link: `/socio/pagina-retos-resumen?reto=${solicitud.idReto}`,
        },
      }))
      .slice(0, 3);
  }

  getListadoRetosCursoyEval(resultado) {
    return resultado
      .map((solicitud: IRetosCVDPResponse) => ({
        idReto: solicitud.idReto,
        fechaRegistro: solicitud.fechaRegistro,
        reto: {
          title: solicitud.reto,
          subtitle: solicitud.unidadFormativa,
          description: solicitud.escuela,
          link: `/socio/pagina-retos-resumen?reto=${solicitud.idReto}`,
        },
        duracion: solicitud.duracionSemanas ?? "-",
        periodoSemanal: solicitud.periodoSemanal,
        periodoSemestral: solicitud.periodoSemestral,
        imageTitle: {
          title: solicitud.campusCoordinador ?? "",
          subtitle: solicitud.escuela,
        },
        nombreSocioFormador: {
          title: solicitud.contacto,
          subtitle: solicitud.correoContacto,
        },
        claveEstatusSolicitud: {
          textCol: "Ver",
          statusType: "primary",
          link: `/socio/pagina-retos-resumen?reto=${solicitud.idReto}`,
        },
      }))
      .slice(0, 3);
  }

  getListadoRetosConcluido(resultado) {
    return resultado
      .map((solicitud: IRetosCVDPResponse) => ({
        idReto: solicitud.idReto,
        fechaRegistro: solicitud.fechaRegistro,
        reto: {
          title: solicitud.reto,
          subtitle: solicitud.unidadFormativa,
          description: solicitud.escuela,
          link: `/socio/pagina-retos-resumen?reto=${solicitud.idReto}`,
        },
        duracion: solicitud.duracionSemanas ?? "-",
        periodoSemanal: solicitud.periodoSemanal,
        periodoSemestral: solicitud.periodoSemestral,
        imageTitle: {
          title: solicitud.campusCoordinador ?? "",
          subtitle: solicitud.escuela,
        },
        nombreSocioFormador: {
          title: solicitud.nombreSocioFormador,
          subtitle: solicitud.rfc,
        },
        inscritos: solicitud.inscritos,
        claveEstatusSolicitud: {
          textCol: "Ver",
          statusType: "primary",
          link: `/socio/pagina-retos-resumen?reto=${solicitud.idReto}`,
        },
      }))
      .slice(0, 3);
  }

  getMisProblematicas(resultado) {
    return resultado
      .map((problematica: any) => ({
        fechaRegistro: problematica.fechaRegistro,
        proyecto: {
          title: problematica.proyecto,
          link: `/problematica-registrada?socio=${problematica.idSocio}&problematica=${problematica.idProblematica}`,
        },
        contactPerson: {
          title: problematica.contacto ?? "-",
          subtitle: problematica.correoContacto ?? "-",
        },
        alcance: problematica.escuela ?? "-",
        programaAcademico: problematica.programaAcademico,
        semestre: problematica.semestre ?? "-",
        campus: problematica.campus ?? "-",
        claveEstatusSolicitud: {
          textCol: "Ver",
          statusType: "primary",
          link: `/problematica-registrada?socio=${problematica.idSocio}&problematica=${problematica.idProblematica}`,
        },
      }))
      .slice(0, 3);
  }
  idRequestEndpoint: number;

  async cargarDashboard() {
    this.userStore = this.usuarioService.getCurrentUserData();
    this.IdSocioFormador = this.usuarioService.getCurrentUserData().IdSocioFormador;

    let pagina: number = 1;
    let filasxpagina: number = 3;
    let query: string = "";

    let responseReg;
    try {
      responseReg = await lastValueFrom(
        this.dashboardService.getVinculacionesSocio(
          this.userStore.idSocioFormador,
          "registrado",
          pagina,
          filasxpagina,
          query
        )
      );

      this.vinculacionEnviadasData = this.getRetosQuieroParticipal(
        responseReg.slice(0, 3)
      );
    } catch (err) {
      console.log(err);
    }

    let response;
    try {
      response = await lastValueFrom(
        this.dashboardService.getVinculacionesSocio(
          this.userStore.idSocioFormador,
          "revision",
          pagina,
          filasxpagina,
          query
        )
      );

      this.vinculacionAbiertasData = this.getRetosQuieroParticipal(
        response.slice(0, 3)
      );
    } catch (err) {
      console.log(err);
    }

    try {
      response = await lastValueFrom(
        this.dashboardService.getVinculacionesSocio(
          this.userStore.idSocioFormador,
          "rechazado",
          pagina,
          filasxpagina,
          query
        )
      );

      this.vinculacionRechazadasData = this.getRetosQuieroParticipal(
        response.slice(0, 3)
      );
    } catch (err) {
      console.log(err);
    }

    try {
      response = await lastValueFrom(
        this.dashboardService.getVinculacionesSocio(
          this.userStore.idSocioFormador,
          "aprobado",
          pagina,
          filasxpagina,
          query
        )
      );
    } catch (err) {
      console.log(err);
    }

    let responseAcep;
    try {
      responseAcep = await lastValueFrom(
        this.dashboardService.getVinculacionesSocio(
          this.userStore.idSocioFormador,
          "aceptado",
          pagina,
          filasxpagina,
          query
        )
      );
      // cambiar estatus
      let resAcept = responseAcep.map((x) => {
        return {
          ...x,
          claveEstatusSolicitud: "aprobado",
          estatusSolicitud: "Aprobado",
        };
      });

      let lista = [...response, ...resAcept];

      lista.sort((x, y) => {
        return y.idSolicitud - x.idSolicitud;
      });

      this.vinculacionAprobadasData = this.getRetosQuieroParticipal(
        lista.slice(0, 3)
      );
    } catch (err) {
      console.log(err);
    }

    // Documentacion

    this.IdSocioFormador = this.usuarioService.getCurrentUserData().idSocioFormador;
    let responseRetos;

    try {
      responseRetos = await lastValueFrom(
        this.dashboardService.getRetosSocio(
          this.IdSocioFormador,
          "formalizacion",
          pagina,
          filasxpagina,
          query
        )
      );
      this.listadoRetosFormalizacionData = this.getListadoRetosFormalizacion(
        responseRetos
      );
    } catch (error) {
      console.log(error);
    }

    try {
      responseRetos = await lastValueFrom(
        this.dashboardService.getRetosSocio(this.IdSocioFormador, "en-curso",
        pagina,
        filasxpagina,
        query
        )
      );
      this.listadoRetosCursoData = this.getListadoRetosCursoyEval(
        responseRetos
      );
    } catch (error) {
      console.log(error);
    }

    try {
      responseRetos = await lastValueFrom(
        this.dashboardService.getRetosSocio(
          this.IdSocioFormador,
          "evaluacion",
          pagina,
          filasxpagina,
          query
        )
      );
      this.listadoRetosEvaluacionData = this.getListadoRetosCursoyEval(
        responseRetos
      );
    } catch (error) {
      console.log(error);
    }

    try {
      responseRetos = await lastValueFrom(
        this.dashboardService.getRetosSocio(
          this.IdSocioFormador,
          "concluido",
          pagina,
          filasxpagina,
          query
        )
      );

      this.listadoRetosConcluidoData = this.getListadoRetosConcluido(
        responseRetos
      );
    } catch (error) {
      console.log(error);
    }

    // Problematicas

    try {
      response = await lastValueFrom(
        this.dashboardService.getProblematicasSocio(
          this.userStore.idSocioFormador,
          "registrado",
          pagina,
          filasxpagina,
          query
        )
      );
      this.problematicaSendData = this.getMisProblematicas(response);
    } catch (err) {
      console.log(err);
    }

    try {
      response = await lastValueFrom(
        this.dashboardService.getProblematicasSocio(
          this.userStore.idSocioFormador,
          "revision",
          pagina,
          filasxpagina,
          query
        )
      );
      this.problematicaData = this.getMisProblematicas(response);
    } catch (err) {
      console.log(err);
    }

    try {
      response = await lastValueFrom(
        this.dashboardService.getProblematicasSocio(
          this.userStore.idSocioFormador,
          "rechazado",
          pagina,
          filasxpagina,
          query
        )
      );
      this.problematicaDataRechazado = this.getMisProblematicas(response);
    } catch (error) {
      console.log(error);
    }

    try {
      response = await lastValueFrom(
        this.dashboardService.getProblematicasSocio(
          this.userStore.idSocioFormador,
          "aprobado",
          pagina,
          filasxpagina,
          query
        )
      );
      this.problematicaDataAprobado = this.getMisProblematicas(response);
    } catch (error) {
      console.log(error);
    }

    this.obtenerAcademicosQuierenTrabajarConmigo();
  }

  ngOnInit(): void {
    this.F2_5_HU58 = this.featuraFlagService.isFeatureEnabled("F2_5_HU58");
    this.F2_4_HU58 = this.featuraFlagService.isFeatureEnabled("F2_4_HU58");

    this.verificarConvenioVigente();
    this.formularioFormC = this.initFormC();
  }

  async verificarConvenioVigente() {
    try {
      this.IdSocioFormador = this.usuarioService.getCurrentUserData().idSocioFormador;
      let response = await lastValueFrom(
        this.sociosFormadoresService.getCartaConvenio(this.IdSocioFormador)
      );
      const hayConvenioPendiente = (agreement: any) => !agreement.paso1Firma || !agreement.paso2Firma;

      let convenioPendienteFirmar = response.filter(
        hayConvenioPendiente
      );

      if (convenioPendienteFirmar.length > 0) {
        // Mostrar el ultimo convenio generado
        this.modalService.open(this.content, { size: "xl" });
      } else {
        // Si tiene todos los convenios firmados, dejar pasar
        this.cargarDashboard();
      }
    } catch (err) {
      console.log(err);
      this.cargarDashboard();
    }
  }

  vinculacionReto() {
    window.location.href = "vinculacion-reto";
  }

  buscarReto() {
    window.location.href = "banco-retos";
  }

  closeConvenio(solicitarConfirmacion: boolean) {
    if (solicitarConfirmacion) {
      Swal.fire({
        title: "¿Desea salir?",
        text: "Se cerrará su sesión hasta que el responsable firme la carta.",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Salir",
        buttonsStyling: false,
        customClass: {
          confirmButton: "btn-amarillo order-2 mx-1",
          cancelButton: "btn-brown order-1 mx-1",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          this.usuarioService.logout();
        }
      });
    } else {
      this.usuarioService.logout();
    }
  }

  rechazar() {
    Swal.fire({
      input: "textarea",
      inputLabel: "Motivo de rechazo",
      inputPlaceholder: "Escribe el motivo del rechazo",
      text: "Si rechaza no podrá entrar al sistema.",
      inputAttributes: {
        "aria-label": "Escribe el motivo del rechazo",
      },
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Guardar",
      buttonsStyling: false,
      customClass: {
        confirmButton: "btn-amarillo order-2 mx-1",
        cancelButton: "btn-brown order-1 mx-1",
      },
    }).then(async (response) => {
      if (response.isConfirmed) {
        this.cambiarEstatusSeguimiento("aprobado", "rechazado", response.value);

        // Se  cierra la sesion
        this.usuarioService.logout();
      }
    });
  }

  async firmaPaso1() {
    try {
      let usuario = await lastValueFrom(
        this.usuarioService.getUsuarioById(
          this.usuarioService.getCurrentUserData().idUsuario
        )
      );

      this.nombreContacto = `${usuario.nombreUsuario} ${usuario.apellidosUsuario}`;

      let res = await lastValueFrom(
        this.sociosFormadoresService.postFlujofirmas({
          tipo: "Convenio",
          pasoFirma: 1,
          id: this.convenioSel.idConvenio,
          idUsuarioFirmante: this.usuarioService.getCurrentUserData().idUsuario,
        })
      );
      this.content_firm_modal = this.modalService.open(this.content_firm, {
        centered: true,
      });
    } catch (error) {
      console.log(error);
    }
  }

  async firmaPaso2(content_firm) {
    try {
      let idUSuarioFirmante = this.usuarioService.getCurrentUserData()
        .idUsuario;

      let res = await lastValueFrom(
        this.sociosFormadoresService.postFlujofirmas({
          tipo: "Convenio",
          pasoFirma: 2,
          id: this.convenioSel.idConvenio,
          idUsuarioFirmante: idUSuarioFirmante,
        })
      );

      // borramos todos los convenios anteriores
      this.borrarConveniosAnteriores();
      // generamos uno nuevo
      try {
        let results: any = await lastValueFrom(
          this.solicitudesService.postGeneraCartaPDF({
            listaIds: [
              {
                campo: "idSocioFormador",
                id: this.IdSocioFormador,
              },
              {
                campo: "idUsuario",
                id: idUSuarioFirmante,
              },
              {
                campo: "idConvenio",
                id: this.convenioSel.idConvenio,
              },
            ],
          })
        );
        this.filePdfFirmado = results.payload.urlFile;
      } catch (error) {
        console.log(error);
      }

      this.Cartafirmada = true;
      this.personaFirmaCarta = this.nombreContacto; //this.usuarioService.getCurrentUserData().nombreUsuario;
      let comentario = `Convenio firmado por [idUsuario:${idUSuarioFirmante}] ${
        this.personaFirmaCarta
      } el ${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()}`;
      this.cambiarEstatusSeguimiento("aprobado", "firmado", comentario);

      this.fechaFirmaCarta =
        new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString();
      this.content_firm_modal.close();
    } catch (error) {
      console.log(error);
    }
  }

  enviarRegistroContactoSecundario() {
    // enviar al registro de contacto secundario
    this.mostrarCartaaFirmar = false;
    // Medios contacto
    this.catalogosSvc
      .getMediosContacto()
      .pipe(
        tap(
          (resultado: IMedioContacto[]) => (this.MediosDeContacto = resultado)
        )
      )
      .subscribe();

    // Areas
    this.catalogosSvc
      .getAreas()
      .pipe(tap((resultado: IArea[]) => (this.Areas = resultado)))
      .subscribe();
  }
  regresaraMostrarCartaFirmar() {
    //this.vFrmContacto = true;
    this.mostrarCartaaFirmar = true;
  }

  downloadPDF() {
    saveAs(this.filePdfFirmado, "CartaConvenio.pdf");
  }

  convenioSeleccionado(event: ICartaConvenio) {
    this.filePdfSeleccionado = event.urlCartaConvenio;
    this.convenioSel = event;
  }

  closeModalyCargarDashboard() {
    this.modalService.dismissAll();
    this.cargarDashboard();
  }

  vConfirmacion: boolean = false;
  vFrmContacto: boolean = true;
  correoCVDP = "la del líder de campus que seleccionó";
  rfc_empresa: string = "";
  nombreSocioFormador: string = "";
  razonSocial: string = "";
  Areas!: IArea[];
  MediosDeContacto!: IMedioContacto[];

  initFormC(): UntypedFormGroup {
    return this.fb.group({
      nombre_contacto: ["", [Validators.required, Validators.minLength(3)]],
      apellidos_contacto: ["", [Validators.required, Validators.minLength(3)]],
      area_departamento_contacto: [
        "",
        [Validators.required, Validators.minLength(3)],
      ],
      puesto_contacto: ["", [Validators.required, Validators.minLength(3)]],
      email_contacto: ["", [Validators.required, Validators.email]],
      telefono_oficina_contacto: ["", [Validators.pattern(/\d/)]],
      celular_contacto: ["", [Validators.pattern(/\d/)]],
      exatec_contacto: ["", [Validators.required]],
      enteraste_contacto: [null, [Validators.required]],
    });
  }

  async existeContacto(email: string) {
    try {
      let res = await lastValueFrom(this.usuarioService.getUsuario(email));
      return res ? true : false;
    } catch (err) {
      console.log(err);
      return false;
    }
  }

  async guardarInformacion() {
    this.formularioFormC.markAllAsTouched();
    if (this.formularioFormC.valid) {
      let existe = await this.existeContacto(
        this.formularioFormC.get("email_contacto")?.value
      );
      if (existe) {
        Swal.fire({
          title: "<strong>Revisa tu solicitud</strong>",
          icon: "error",
          html: "<p>El correo ya existe en el sistema, por favor revísalo.</p>",
          showCloseButton: true,
          showConfirmButton: false,
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn-amarillo order-2 mx-1",
            cancelButton: "btn-brown order-1 mx-1",
          },
        });
        return;
      }

      let socioFormadorModel: any = {
        socioFormador: {
          nombreSocioFormador: "",
          empresa: "",
          razonSocial: "",
          grupoComercial: "",
          rfc: "",

          idSector: "",
          paginaWeb: "",
          idCampus: "",
          idIndustria: "",
          idPresencia: "",
          relacionesTec: "",
          conoceRolSF: "",

          pais: "",
          cp: "",
          claveEstado: "",
          estado: "",
          ciudad: "",
          ciudadFiscal: "",
          colonia: "",
          calle: "",
        },

        contactoPrincipal: {
          nombre: this.formularioFormC.get("nombre_contacto")?.value,
          apellidos: this.formularioFormC.get("apellidos_contacto")?.value,
          area: this.formularioFormC.get("area_departamento_contacto")?.value,
          email: this.formularioFormC.get("email_contacto")?.value,
          telefonoOficina: this.formularioFormC.get("telefono_oficina_contacto")
            ?.value,
          telefonoMovil: this.formularioFormC.get("celular_contacto")?.value,
          puesto: this.formularioFormC.get("puesto_contacto")?.value,
          exatec: this.formularioFormC.get("exatec_contacto")?.value == "true",
          avisoPrivacidad: this.formularioFormC.get("terminos_condiciones")
            ?.value,
          contactoPrincipal: true,
          idMedioContacto: this.formularioFormC.get("enteraste_contacto")
            ?.value,
          datoMedioContacto: "",
          clave: "mvpretos",
        },
      };

      this.contactoService
        .postContactoSecundario(
          this.usuarioService.getCurrentUserData().idSocioFormador,
          socioFormadorModel.contactoPrincipal
        )
        .subscribe({
          next: async (resOk) => {
            //this.ocultarTodos();
            // Mensaje de confirmación
            this.vFrmContacto = false;
            this.vConfirmacion = true;

            // // Obtener correo del cvdp del campus que seleccionó
            // this.correoCVDP = "sociosformadores@servicios.tec.mx";
            // let campusCVDPPrincipal = this.Campus.filter(x=> x.idCampus == socioFormadorModel.socioFormador.idCampus);
            // if(campusCVDPPrincipal.length>0){
            //   let UsuarioCVDPPrincipal = await lastValueFrom(this.usuarioService.getUsuarioById(campusCVDPPrincipal[0].idCVDPPrincipal));
            //   this.correoCVDP = UsuarioCVDPPrincipal.usuario;
            // }
          },
          error: (err) => {
            let mensajeError =
              err.message +
              '<br/><br/> Si tienes dudas, ponte en contacto con <a href="mailto:sociosformadores@servicios.tec.mx">sociosformadores@servicios.tec.mx</a>';
            Swal.fire({
              title: "<strong>Error en tu solicitud</strong>",
              icon: "error",
              html: mensajeError,
              showCloseButton: true,
              showConfirmButton: false,
              buttonsStyling: false,
              customClass: {
                confirmButton: "btn-amarillo order-2 mx-1",
                cancelButton: "btn-brown order-1 mx-1",
                closeButton: "btn-brown order-1 mx-1",
              },
            });
            console.log("Error Solcitud: ", err);
          },
        });
    }
  }

  async cambiarEstatusSeguimiento(
    cveEstatus: string,
    cveEstatusSeguimiento: string,
    comentarios: string
  ) {
    let idSocioFormador = this.usuarioService.getCurrentUserData()
      .idSocioFormador;
    let idUsuario = this.usuarioService.getCurrentUserData().idUsuario;

    let request: ISolicitudSocioComentarios = {
      idSocioFormador: idSocioFormador,
      claveEstatus: cveEstatus,
      claveEstatusSeguimiento: cveEstatusSeguimiento,
      comentarios: comentarios,
      idUsuarioRegistro: idUsuario,
    };

    await lastValueFrom(
      this.solicitudesService.postSolicitudSFP_comentarios(request)
    );
  }

  changeViewStatus(status) {
    this.status = status;
  }

  async borrarConveniosAnteriores() {
    try {
      if (this.IdSocioFormador > 0) {
        let result = await lastValueFrom(
          this.sociosFormadoresService.getCartaConvenio(this.IdSocioFormador)
        );
        let lstConvenio = result.sort((x, y) => y.idConvenio - x.idConvenio);
        let idUsuario = this.usuarioService.getCurrentUserData().idUsuario;

        for (let index = 1; index < lstConvenio.length; index++) {
          await lastValueFrom(
            this.sociosFormadoresService.deleteCartaConvenio(
              lstConvenio[index].idConvenio,
              idUsuario
            )
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  getAcademicoBuscaSF(resultado) {
    return resultado.map((solicitudes: any) => ({
      fechaRegistro: solicitudes.fechaRegistro,
      periodoSemestral: solicitudes.periodoSemestral ?? "-",
      periodoSemanal: solicitudes.periodoSemanal ?? "-",
      contactPerson: {
        title: solicitudes.nombreAcademico,
        subtitle: solicitudes.correoUsuario,
        description: solicitudes.campus,
        link: `/solicitud/Academico/${solicitudes.idAcademico}/${solicitudes.idRetoMaestro}/${solicitudes.idSolicitud}`,
        image: solicitudes.urlFotoUsuario,
      },
      imageTitle: {
        title:
          solicitudes.idSocioFormador == 0
            ? "No se encontró un Socio Formador"
            : solicitudes.detalleSolicitud,
        subtitle: solicitudes.rfc ?? "-",
      },
      unidadFormativa: {
        title: solicitudes.reto,
        subtitle: solicitudes.escuela,
      },
      claveEstatusSolicitud: {
        textCol: "Ver",
        statusType: "primary",
        link: `/solicitud/Academico/${solicitudes.idAcademico}/${solicitudes.idRetoMaestro}/${solicitudes.idSolicitud}`,
      },
    }));
  }

  solicitudesAcademicosRecibidasData: any = [];
  solicitudesAprobadaAcademicosData: any = [];
  solicitudesAceptadasAcademicosData: any = [];
  solicitudesRechazadasAcademicosData: any = [];

  async obtenerAcademicosQuierenTrabajarConmigo() {
    this.idRequestEndpoint = this.userStore.idSocioFormador;

    let idCampus = 0;
    let idEscuela = 0;
    let idPeriodo = 0;
    let pagina = 1;
    let hits = 3;

    // Académico que bucsa un Socio Formador
    try {
      let responseRegistrado = await lastValueFrom(
        this.dashboardService.getSolicitudesAcademicosxSocio(
          this.idRequestEndpoint,
          "registrado",
          idCampus,
          idEscuela,
          idPeriodo,
          pagina,
          hits
        )
      );

      let response = await lastValueFrom(
        this.dashboardService.getSolicitudesAcademicosxSocio(
          this.idRequestEndpoint,
          "revision",
          idCampus,
          idEscuela,
          idPeriodo,
          pagina,
          hits
        )
      );

      let lista = [...responseRegistrado, ...response];

      this.solicitudesAcademicosRecibidasData = this.getAcademicoBuscaSF(
        lista.slice(0, 3)
      );

      response = await lastValueFrom(
        this.dashboardService.getSolicitudesAcademicosxSocio(
          this.idRequestEndpoint,
          "aprobado",
          idCampus,
          idEscuela,
          idPeriodo,
          pagina,
          hits
        )
      );
      this.solicitudesAprobadaAcademicosData = this.getAcademicoBuscaSF(
        response.slice(0, 3)
      );

      response = await lastValueFrom(
        this.dashboardService.getSolicitudesAcademicosxSocio(
          this.idRequestEndpoint,
          "aceptado",
          idCampus,
          idEscuela,
          idPeriodo,
          pagina,
          hits
        )
      );
      this.solicitudesAceptadasAcademicosData = this.getAcademicoBuscaSF(
        response.slice(0, 3)
      );

      response = await lastValueFrom(
        this.dashboardService.getSolicitudesAcademicosxSocio(
          this.idRequestEndpoint,
          "rechazado",
          idCampus,
          idEscuela,
          idPeriodo,
          pagina,
          hits
        )
      );
      this.solicitudesRechazadasAcademicosData = this.getAcademicoBuscaSF(
        response.slice(0, 3)
      );
    } catch (e) {
      console.log(e);
    }
  }
}
