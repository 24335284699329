import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-chip-item',
  templateUrl: './chip-item.component.html',
  styleUrls: ['./chip-item.component.css'],
})
export class ChipItemComponent {
  @Input() chipObject: any;
  @Input() title: any;
  @Input() icon: any;
  @Input() placeholder: any;

  
}
