<app-header-buscador [showSearchBar]="false"></app-header-buscador>

<div class="sec_ad">
  <div class="row">
    <span class="tit_sans_big"><strong>Resultados de “Finanzas”</strong></span>
  </div>

  <div id="sec_tab" class="d-flex flex-md-nowrap flex-wrap tabs-3">
    <div class="flex-fill">
      <div class="d-flex justify-content-end">
        <div class="btn-group order-by">
          <button
            class="btn dropdown-toggle"
            type="button"
            id="listado"
            data-bs-toggle="dropdown"
            data-bs-auto-close="true"
            aria-expanded="false"
            data-bs-offset="0,0"
          >
            Ordenar por
          </button>
          <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="listado">
            <li><a class="dropdown-item active" >Nombre A-Z</a></li>
            <li><a class="dropdown-item active" >Programa</a></li>
            <li><a class="dropdown-item" >Semestre</a></li>
            <li><a class="dropdown-item active" >Campus</a></li>
            <li><a class="dropdown-item active" >Región</a></li>
            <li class="visually-hidden">
              <a class="dropdown-item" >Campus</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div *ngFor="let company of companiesData">
    <app-card-socio-search [objCompany]="company"></app-card-socio-search>
  </div>
  <app-pagination></app-pagination>
</div>
