import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { UsuariosService } from '../../../shared/service/Usuarios/usuarios.service';
import { Router, ActivatedRoute } from '@angular/router';
import { tap } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { CatalogosService } from '@shared/service/Catalogos/catalogos.service';
import {
  ICampus,
  IEscuela
} from '@shared/Interface/ICatalogos.interface';
import Swal from 'sweetalert2';
import { AzureBlobStorageService } from '@shared/service/AzureBlobStorage.Service';
import { environment } from 'environments/environment';
import { SociosFormadoresService } from '@shared/service/Socios_Formadores/socios-formadores.service';
import { CasosDeExitoService } from '@shared/service/Casos_de_Exito/casos-de-exito.service';

declare const window: any;
@Component({
  selector: 'app-registro-casos-exito',
  templateUrl: './registro-casos-exito.component.html',
  styleUrls: ['./registro-casos-exito.component.css']
})
export class RegistroCasosExitoComponent implements OnInit {


  casosForm: FormGroup;
  campusData: any;
  regionData: any;
  escuelasData: any;
  userData: any;
  idCasoExito: any;
  userType: any;
  clavePerfil: string;
  btnText: string;

  img = 'avatar_sin';
  imgUrl = 'assets/img/avatar_sin.jpg';
  titleProfile = 'Casos de Éxito';
  buttons = [
    {
      url: '',
      text: 'Editar Imagen',
    },
  ];

  formModal: any;
  mensajeModal: string = '';

  constructor(
    private fb: FormBuilder,
    private usuariosService: UsuariosService,
    private casosService: CasosDeExitoService,
    private router: Router,
    private toastr: ToastrService,
    private catalogosService: CatalogosService,
    private route: ActivatedRoute,
    private azureBlobStorage: AzureBlobStorageService,
    private sociosFormadores: SociosFormadoresService
  ) { }

  getUserData() {
    this.userData = this.usuariosService.getCurrentUserData();

    if (!this.userData) this.router.navigate(['/']);
 

    return this.userData;
  }

  ngOnInit(): void {

    this.formModal = new window.bootstrap.Modal(
      document.getElementById('idModalCorreoExistente')
    );

    this.catalogosService
      .getCampus()
      .pipe(tap((resultado: ICampus[]) => (this.campusData = resultado)))
      .subscribe();

    this.catalogosService
      .getEscuelas()
      .pipe(tap((resultado: IEscuela[]) => (this.escuelasData = resultado)))
      .subscribe();

    this.idCasoExito = this.route.snapshot.paramMap.get('idRecurso');
    this.userType = this.route.snapshot.paramMap.get('type');

    if (this.userType=='editCaso') {
    
      this.titleProfile = `Editar Caso de Éxito`;
      this.btnText = 'Editar Caso'
      this.getCasosToEdit(this.idCasoExito);
      
    } else {

      this.titleProfile = `Nuevo Editar Caso de Éxito`;
      this.btnText = 'Crear Caso'
      this.initializeForm(null);
      
    }
    
  }

  getCasosToEdit(id: number) {
    this.casosService
      .getCasosById(id)
      .pipe(
        tap((response) => {

          this.imgUrl = response.urlImagenCasoExito;
          return this.initializeForm({
            ...response,
          });
        })
      )
      .subscribe();
  }

  initializeForm(casos: any | null) {
    this.casosForm = this.fb.group({
      idCasoExito: [{ value: casos?.idCasoExito ?? '', disabled: true },],
      nombreCasoExito: [casos?.nombreCasoExito ?? '', Validators.required],
      descripcionCasoExito: [casos?.descripcionCasoExito ?? '', Validators.required],
      anoCasoExito: [casos?.anoCasoExito ?? '', Validators.required],
      idEscuela: [casos?.idEscuela ?? '', Validators.required],
      idCampus: [casos?.idCampus ?? '', Validators.required],
      urlImagenCasoExito: [casos?.urlImagenCasoExito ?? '', Validators.required],
      urlConectaCasoExito: [casos?.urlConectaCasoExito ?? ''],
      urlEvidencia: [casos?.urlEvidencia ?? ''],
      estatusCasoExito: ['activo']
    
    });
    if (this.idCasoExito != null) {
      this.titleProfile = `Editar Caso de Éxito`;
      this.btnText = 'Editar Caso'



    } else {
      this.titleProfile = `Nuevo Caso de Éxito`;
      this.btnText = 'Crear Caso'
    }

  }

  getFullName() {
    return `${this.casosForm.value?.nombreUsuario ?? ''} ${
      this.casosForm.value?.apellidosUsuario ?? ''
      }`;
  }
  edit_crear() {

    if (this.idCasoExito != null) {

      this.updateCasos()
    } else {

      this.crearCasos()
    }
  }

  updateCasos() {
    try {
      this.casosForm.markAllAsTouched();
      if (this.casosForm.valid) {
        if (this.userType && !this.idCasoExito) {
          this.casosService
            .putCasos({
              ...this.casosForm.value,
              idReto: 0
            })
            .subscribe({
              next: (resultado: any) => {
                if (resultado) {
                  this.toastr.success('Actualizado Correctamente!');
                  window.history.back();
                } else {
                  this.toastr.error('Algo Salio mal!');
                }
              },
              error: (error) => {
                this.mensajeModal = error.message;
                
                Swal.fire({
                  icon: 'error',
                  text: this.mensajeModal,
                  title: 'Error',
                });
              }
            });
          return;
        }
        this.casosService
          .putCasos({
            idCasoExito: this.idCasoExito ? this.idCasoExito : this.getUserData().idCasoExito,
            ...this.casosForm.value,
            idReto: 0
          })
          .pipe(
            tap((resultado: any) => {
              if (resultado) {

                this.toastr.success('Actualizado Correctamente!');
                window.history.back();
              } else {
                this.toastr.error('Algo Salió mal!');
              }
            })
          )
          .subscribe();
      } else {
        this.toastr.error('Se deben rellenar los campos requeridos!');
      }
    } catch (err) {
      console.log(err);
      this.formModal.show();
    }
  }

  crearCasos() {
    try {
      this.casosForm.markAllAsTouched();
      if (this.casosForm.valid) {
        this.casosService
          .postCasos({
            ...this.casosForm.value,
            idReto: 0
          })
          .subscribe({
            next: (resultado: any) => {
              if (resultado) {
                this.toastr.success('Creo Correctamente!');
                window.history.back();
              } else {
                this.toastr.error('Algo Salio mal!');
              }
            },
            error: (error) => {
              this.mensajeModal = error.message;
              
              Swal.fire({
                icon: 'error',
                text: this.mensajeModal,
                title: 'Error',
              });
            }
          });
        return;
      }
      else {
        this.toastr.error('Se deben rellenar los campos requeridos!');
      }
    } catch (err) {
      console.log(err);
      this.formModal.show();
    }
  }

  actualizarCampus(event: any) {
    this.campusData = [];
    if (event.target.value !== '') {
      this.catalogosService
        .getCampusPorRegion(event.target.value)
        .subscribe((resOK) => {
          this.campusData = resOK;
        });
    }
  }

  verifySelected(id: string | number, inputForm: string) {
    return this.casosForm.get(inputForm)?.value === id ? true : null;
  }

  onClickButton(url: string) {

  }

  async selImagen(event: any) {

    let idCasoExito = this.idCasoExito

    let selFile = (event.target as HTMLInputElement).files?.[0];
    if (!selFile) return;
    let fileName = this.getFileName(idCasoExito, selFile);

    if (this.clavePerfil === 'socio') {

      await this.uploadSocioImage(idCasoExito, selFile, fileName);
    } else {

      await this.uploadUserImage(idCasoExito, selFile, fileName);
    }
  }

  async uploadSocioImage(idCasoExito, selFile, fileName) {
    await this.azureBlobStorage
      .uploadFile(
        {
          containerName: environment.AzureBlob_containerNameSocio,
          file: selFile,
          filename: fileName,
        },
        environment.AzureBlob_ACCOUNT_NAME,
        environment.AzureBlob_socioSAS
      )
      .then((res) => {
        let fileUrlLogo = `${environment.AzureBlob_socioURL}${fileName}`;

        this.casosForm.controls['urlImagenCasoExito'].setValue(fileUrlLogo)

        this.imgUrl = this.casosForm.controls['urlImagenCasoExito'].value
      });
  }

  async uploadUserImage(idCasoExito, selFile, fileName) {

    this.azureBlobStorage
      .uploadFile(
        {
          containerName: environment.AzureBlob_containerNameUsuario,
          file: selFile,
          filename: fileName,
        },
        environment.AzureBlob_ACCOUNT_NAME,
        environment.AzureBlob_usuarSAS
      )
      .then((res) => {
        let fileUrlLogo = `${environment.AzureBlob_usuarURL}${fileName}`;

        this.casosForm.controls['urlImagenCasoExito'].setValue(fileUrlLogo)
      
        this.imgUrl = this.casosForm.controls['urlImagenCasoExito'].value


      });

  }

  getFileName(id, file) {

  
    if (id == null) {
      id = this.makeid(5);
    }
    return `caso_exito_${id}.${file.name.split('.')[1]}`.toLowerCase();
  }

  makeid(length) {
    let result = Date.now().toString();

    return result;

  }


}


