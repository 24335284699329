import { Component } from '@angular/core';

@Component({
  selector: 'app-sin-datos-table',
  templateUrl: './sin-datos-table.component.html',
  styleUrls: ['./sin-datos-table.component.css']
})
export class SinDatosTableComponent  {




}