import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { IArea } from "@shared/Interface/ICatalogos.interface";
import {
  IContacto,
} from "@shared/Interface/IContacto.interface";
import { Paginador } from "@shared/Interface/IPaginador.interface";
import { PaginadorLibsComponent } from "@shared/libs/paginador.component";
import { CatalogosService } from "@shared/service/Catalogos/catalogos.service";
import { ContactosService } from "@shared/service/Contactos/contactos.service";
import { UsuariosService } from "@shared/service/Usuarios/usuarios.service";
import { environment } from "environments/environment";
import { ToastrService } from "ngx-toastr";
import { lastValueFrom, tap } from "rxjs";
import Swal from "sweetalert2";

@Component({
  selector: "app-contactos-secundarios",
  templateUrl: "./contactos-secundarios.component.html",
  styleUrls: ["./contactos-secundarios.component.css"],
})
export class ContactosSecundariosComponent implements OnInit {
  sidebarExpanded = false;
  lang: string = "es";
  title = {
    title: "Autoregistro de Contactos Secundarios",
  };
  numberPerPage = environment.RegistrosPagina;
  pages: number[] = [];
  paginaActual = 1;
  numeroPaginas = 0;
  numeroPaginasTo = 0;
  usuarios: [] = [];
  usuariosList: IContacto[] = [];
  usuariosListTotal: IContacto[] = [];
  catalogoAreas: IArea[]  =[];
  elementsTo: IescuelaObje[] = [];
  elementsTotal: IescuelaObje[] = [];
  paginaActualMasResultados: number = 0;
  ordenamientoBy: string = "nombre"; //ajustar al parametro que recibe la api "nombre/campus"
  textOrdenarPor: string = "gestion_usuarios.ordenar_por";

  queryFilter:string = '';

  filter = { query: "" };



  constructor(
    private _usuariosService: UsuariosService,
    private paginadorLibs: PaginadorLibsComponent,
    private toastr: ToastrService,
    private router: Router,
    private changeDetector: ChangeDetectorRef,
    private _contactoService: ContactosService,
    private _catalogosService: CatalogosService
  ) {  }

  async ngOnInit() {
    this.catalogoAreas = await lastValueFrom(this._catalogosService.getAreas());

    this.lang = localStorage.getItem("lang") ?? "es";
    this.getUsuarios(this.ordenamientoBy);

    this._usuariosService.queryFilter$.subscribe( async text => {
      this.queryFilter = text;
      await this.getUsuarios(this.ordenamientoBy);
    });

  }


 async buscadorPress(keyWord: any) {
  if (
    keyWord !== null &&
    keyWord !== undefined &&
    keyWord.toString().trim() !== ""
  ) {
    this.filter.query = keyWord;
  } else {
    this.filter.query = "";
  }
  this._usuariosService.queryFilter$.emit(this.filter.query);
}

  async getUsuarios(ordenamiento: string) {
    try {
      this.usuariosList =[];

      this._contactoService.getContactosPorEstatus("pendiente").subscribe({
        next: (resOK) => {
          if(this.queryFilter != '')
          {
            this.usuariosList = resOK.filter((user :IContacto) => {
              return user.nombre.toUpperCase().includes(this.queryFilter.toUpperCase())
                  || user.apellidos.toUpperCase().includes(this.queryFilter.toUpperCase())

          });
        }
          else {
            this.usuariosList = resOK;
          }
        },
        error: (err) => {
          console.log(err);
        }
        });
    } catch (error) {
      console.error("Error al realizar la petición", error);
    }
  }

  getPaginacion() {
    let paginador: Paginador = this.paginadorLibs.getPaginacion(
      this.usuariosListTotal.length,
      this.numberPerPage
    );
    this.numeroPaginas = paginador.totalPaginas;
    this.pages = paginador.paginas;
  }

  async obtenerRegistrosActuales(numPagina: number) {
    this.usuariosList = this.paginadorLibs.obtenerRegistrosActuales(
      numPagina,
      this.numberPerPage,
      this.usuariosListTotal
    );
    this.paginaActual = numPagina;
  }

  obtenerMasResultados() {
    this.paginaActualMasResultados += 1;
    this.elementsTo = this.paginadorLibs.obtenerMasResultados(
      this.paginaActualMasResultados,
      this.numberPerPage,
      this.elementsTotal
    );
  }

  async agrugarBySF() {
    this.elementsTotal = [];
    let index = 1;
    this.usuariosListTotal.forEach((element) => {
      let sf: IescuelaObje = {
        id: index,
        usuarios: [],
      };
      sf.usuarios.push(element);


      this.elementsTotal.push(sf);

      index++;
    });

    this.obtenerMasResultados();
    let paginador: Paginador = this.paginadorLibs.getPaginacion(
      this.elementsTotal.length,
      this.numberPerPage
    );
    this.numeroPaginasTo = paginador.totalPaginas;
  }

  async orderBy(ordenamiento: string) {
    switch (ordenamiento) {
      case "nombre":
        this.textOrdenarPor = "gestion_usuarios.nombre_az";
        break;

      case "campus":
        this.textOrdenarPor = "gestion_usuarios.campus";
        break;
    }

    this.ordenamientoBy = ordenamiento;
    await this.getUsuarios(ordenamiento);
    this.obtenerRegistrosActuales(1);
  }

  changeLang(lang: string) {
    this.lang = lang;
    localStorage.setItem("lang", lang);
    window.location.reload();
  }

  AprobraContacto(idUsuario: number) {
    Swal.fire({
      title: "¿Desea aprobar el contacto?",
      text: "Se creará su cuenta y se le notificará por email.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Activar",
    }).then((result) => {
      if (result.isConfirmed) {
        this._contactoService
          .putContactosAprobar({idUsuario: idUsuario})
          .pipe(
            tap((resultado: any) => {
              if (resultado) {
                Swal.fire(
                  "Gestión de Usuarios.",
                  "Usuario activado.",
                  "success"
                );
                this.getUsuarios(this.ordenamientoBy);
              } else {
                this.toastr.error("Algo Salió mal!");
              }
            })
          )
          .subscribe();
      }
    });
  }

  async eliminarUsuario(idUsuario: number) {
    Swal.fire({
      title: "¿Desea eliminar la solicitud de contacto?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Borrar",
    }).then((result) => {
      if (result.isConfirmed) {
        this._contactoService
          .putContactosEstatus({idUsuario: idUsuario, estatus: 'eliminar'})
          .pipe(
            tap((resultado: any) => {
              if (resultado) {
                Swal.fire(
                  "Gestión de Usuarios.",
                  "Usuario Eliminado.",
                  "success"
                );
                this.getUsuarios(this.ordenamientoBy);
              } else {
                this.toastr.error("Algo Salió mal!");
              }
            })
          )
          .subscribe();
      }
    });
  }

  showPaginationData(objData: any) {
    if (objData.length) {
      this.usuariosList = objData;
      this.changeDetector.detectChanges();
    }
  }

  obtenerArea(idArea){
    let nArea = this.catalogoAreas.filter(x => x.idArea == idArea).pop();
    if(!nArea){
      return idArea;
    }
    return nArea.area;
  }
}


export interface IescuelaObje {
  id: number;
  usuarios: IContacto[];
}
