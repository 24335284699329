<div class="sec_ad">
    <div class="row">
      <div class="col-md-7 order-1 order-md-0">
        <div id="sec_tit">
          <div class="d-flex align-items-center">
            <span class="subtit1col">Reto Registrado</span>
          </div>
          <app-title-show-more [showMoreObject]="titleConfirmReto"></app-title-show-more>
          <div class="flex-fill">
          </div>
          <br />
        </div>
        <div class="d-flex align-items-center">
          <span class="subtit1col_b">HEMOS RECIBIDO TU SOLICITUD</span
          ><br /><br />
        </div>
        <div class="d-flex align-items-center">
          A la brevedad recibirás un correo a la cuenta que tienes registrada.
          <br /><br /><br /> 
          Es importante porque te notificaremos por ese medios los <br/>
          siguentes pasos para el desarrollo de tu reto.
          <br /><br /><br />
          Estás listo para ideas frescas, innovación y colaboración?.<br /><br /><br />
        </div>
        <div class="row">
          <div class="col-md">
          <app-button extraClass="" (callbackAction)="irAmisRetos()">Ver mi Retos</app-button>
          </div>
          &nbsp;
          <div class="col-md">
            <app-button buttonType="yell" extraClass="" (callbackAction)="irAVincularSocios()">Vincular Socios a mi Reto</app-button>
            </div>
          
        </div>
      </div>
      <div class="col-md-5">
        <img class="img_hdr" alt="Reto"  src="assets/img/reto-5-1.png" />
      </div>
    </div>
</div>
