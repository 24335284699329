<div class="sec_ad">

    <!-- Sección de estatus -->
    <app-title-show-more *ngIf="false" [showMoreObject]="titleRetos"></app-title-show-more>

    <div class="sec_ad ">

        <!-- Retos donde quiero participar -->
        <app-title-show-more [showMoreObject]="titleVinculaciones"></app-title-show-more>

        <div id="sec_tab" class="nav nav-pills d-flex flex-md-nowrap flex-wrap" role="presentation">
            <div class="active" data-bs-toggle="pill" data-bs-target="#retos-enviadas" aria-controls="retos-enviadas"
                prm="re_ac" role="tab" type="button" aria-selected="true">
                Solicitudes Enviadas
            </div>
            <div prm="re_hs" data-bs-toggle="pill" data-bs-target="#historial-abiertas"
                aria-controls="historial-abiertas" class="" role="tab" type="button" aria-selected="false">
                Solicitudes Abiertas
            </div>
            <div prm="re_hs" data-bs-toggle="pill" data-bs-target="#historial-rechazadas"
                aria-controls="historial-rechazadas" class="" role="tab" type="button" aria-selected="false">
                Solicitudes Rechazadas
            </div>
            <div prm="re_hs" data-bs-toggle="pill" data-bs-target="#historial-aprobadas"
                aria-controls="historial-aprobadas" class="" role="tab" type="button" aria-selected="false">
                Solicitudes Aprobadas
            </div>

        </div>

        <div class="tab-content" id="pills-tabContent">
            <div class="tab-pane fade show active" id="retos-enviadas" role="tabpanel"
                aria-labelledby="retos-enviadas-tab">
                <app-table [backgroundColor]="backgroundTable" [columns]="vinculacionColumns"
                    [data]="vinculacionEnviadasData"></app-table>
            </div>
            <div class="tab-pane fade" id="historial-abiertas" role="tabpanel" aria-labelledby="historial-abiertas-tab">
                <app-table [backgroundColor]="backgroundTable" [columns]="vinculacionColumns"
                    [data]="vinculacionAbiertasData"></app-table>
            </div>
            <div class="tab-pane fade" id="historial-rechazadas" role="tabpanel"
                aria-labelledby="historial-rechazadas-tab">
                <app-table [backgroundColor]="backgroundTable" [columns]="vinculacionColumns"
                    [data]="vinculacionRechazadasData"></app-table>
            </div>
            <div class="tab-pane fade" id="historial-aprobadas" role="tabpanel"
                aria-labelledby="historial-aprobadas-tab">
                <app-table [backgroundColor]="backgroundTable" [columns]="vinculacionColumns"
                    [data]="vinculacionAprobadasData"></app-table>
            </div>
        </div>




        <!-- Mis retos -->
        <app-title-show-more [showMoreObject]="titleRetos"></app-title-show-more>

        <div class="mt-2 pt-0 mb-0 pb-0" *featureFlag="'F2_5_HU58'">
            <ng-template matStepLabel>En formalización</ng-template>
            <div class="mt-5">
                <div class="nav nav-tabs d-inline" id="accordionExample">
                    <div class="steps">

                        <div class="step-item active" data-bs-toggle="tab" data-bs-target="#tab-formalizacion"
                            (click)="changeViewStatus('formalizacion')">
                            <progress [ngClass]="status != 'formalizacion'  ? 'progress-active' : 'progress-inactive'"
                                id="progress-active" [value]="status != 'formalizacion'  ? 20 : 0" max=20></progress>
                            <div class="titlestepActive">
                                En formalización
                            </div>
                            <button class="step-button-active text-center">
                                1
                            </button>

                        </div>
                        <div class="step-item" data-bs-toggle="tab" data-bs-target="#tab-curso"
                            (click)="changeViewStatus('curso')">
                            <progress
                                [ngClass]="status == 'evaluacion' || status == 'concluido' ? 'progress-active' : 'progress-inactive'"
                                id="progress-active" [value]="status == 'evaluacion' || status == 'concluido'  ? 20 : 0"
                                max=20></progress>
                            <div [ngClass]="status != 'formalizacion'  ? 'titlestepActive' : 'titlestepInactive'">
                                En curso
                            </div>
                            <button [ngClass]="status != 'formalizacion'  ? 'step-button-active' : 'step-button'"
                                class="text-center">
                                2
                            </button>

                        </div>

                        <div class="step-item" data-bs-toggle="tab" data-bs-target="#tab-evaluacion"
                            (click)="changeViewStatus('evaluacion')">
                            <progress [ngClass]="status == 'concluido' ? 'progress-active' : 'progress-inactive'"
                                id="progress-active" [value]="status == 'concluido'  ? 20 : 0" max=20></progress>
                            <div
                                [ngClass]="status == 'evaluacion' || status == 'concluido' ? 'titlestepActive' : 'titlestepInactive'">
                                En evaluación
                            </div>
                            <button
                                [ngClass]="status == 'evaluacion' || status == 'concluido'  ? 'step-button-active' : 'step-button'"
                                class="text-center">
                                3
                            </button>

                        </div>
                        <div class="step-item" data-bs-toggle="tab" data-bs-target="#tab-concluido"
                            (click)="changeViewStatus('concluido')">
                            <div [ngClass]="status == 'concluido'  ? 'titlestepActive' : 'titlestepInactive'">
                                Concluidos
                            </div>
                            <button [ngClass]="status == 'concluido'  ? 'step-button-active' : 'step-button'"
                                class="text-center">
                                4
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="tab-content " id="pills-tabContent" *featureFlag="'F2_5_HU58'">
            <div class="tab-pane fade show active" id="tab-formalizacion" role="tabpanel"
                aria-labelledby="tab-reto-activos-tab">
                <app-table [backgroundColor]="backgroundTable" [columns]="listadoRetosFormalizacionColumns"
                    [data]="listadoRetosFormalizacionData"></app-table>
            </div>
            <div class="tab-pane fade" id="tab-curso" role="tabpanel" aria-labelledby="tab-reto-historial-tab">
                <app-table [backgroundColor]="backgroundTable" [columns]="listadoRetosCursoColumns"
                    [data]="listadoRetosCursoData"></app-table>
            </div>
            <div class="tab-pane fade" id="tab-evaluacion" role="tabpanel" aria-labelledby="tab-reto-historial-tab">
                <app-table [backgroundColor]="backgroundTable" [columns]="listadoRetosEvaluacionColumns"
                    [data]="listadoRetosEvaluacionData"></app-table>
            </div>
            <div class="tab-pane fade" id="tab-concluido" role="tabpanel" aria-labelledby="tab-reto-historial-tab">
                <app-table [backgroundColor]="backgroundTable" [columns]="listdaoRetosConcluidoColumns"
                    [data]="listadoRetosConcluidoData"></app-table>
            </div>

        </div>

        <div id="sec_tab" class="nav nav-pills d-flex flex-md-nowrap flex-wrap" role="presentation"
            *featureFlag="'F2_4_HU58'">
            <div class="active" data-bs-toggle="pill" data-bs-target="#retos-activos" aria-controls="retos-activos"
                prm="re_ac" role="tab" type="button" aria-selected="true">
                Mis Retos activos
            </div>
            <div prm="re_hs" data-bs-toggle="pill" data-bs-target="#historial-retos" aria-controls="historial-retos"
                class="" role="tab" type="button" aria-selected="false">
                Mi Historial de Retos
            </div>

        </div>

        <div class="tab-content" id="pills-tabContent" *featureFlag="'F2_4_HU58'">
            <div class="tab-pane fade show active" id="retos-activos" role="tabpanel"
                aria-labelledby="retos-activos-tab">
                <app-table [backgroundColor]="backgroundTable" [columns]="retosColumns" [data]="retosData"></app-table>
            </div>
            <div class="tab-pane fade" id="historial-retos" role="tabpanel" aria-labelledby="historial-retos-tab">
                <app-table [backgroundColor]="backgroundTable" [columns]="retosColumns"
                    [data]="retosHistorialData"></app-table>
            </div>
        </div>




        <!-- Mis problemáticas registradas -->
        <div class="" *featureFlag="'F2_5_HU58'">

            <app-title-show-more [showMoreObject]="titleProblematicas"></app-title-show-more>

            <div id="sec_tab" class="nav nav-pills d-flex flex-md-nowrap flex-wrap" role="presentation">
                <div class="active" data-bs-toggle="pill" data-bs-target="#pills-send" aria-controls="pills-send"
                    prm="re_ac" role="tab" type="button" aria-selected="true">
                    Enviadas
                </div>
                <div prm="re_hs" data-bs-toggle="pill" data-bs-target="#pills-home" aria-controls="pills-homt"
                    prm="re_ac" role="tab" type="button" aria-selected="false">
                    En Revisión
                </div>
                <div prm="re_hs" data-bs-toggle="pill" data-bs-target="#pills-profile" aria-controls="pills-profile"
                    class="" role="tab" type="button" aria-selected="false">
                    Solicitudes Rechazadas
                </div>
                <div prm="re_hs" data-bs-toggle="pill" data-bs-target="#pills-approve" aria-controls="pills-approve"
                    class="" role="tab" type="button" aria-selected="false">
                    Solicitudes Aprobadas
                </div>

                <div class="d-none d-md-block flex-fill" *ngIf="false">
                    <div class="d-flex justify-content-end">
                        <app-button (callbackAction)="buscarReto()">
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"
                                fill="none" class="iCn replaced-svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M0.5 6.45834V2.125C0.5 1.69402 0.671205 1.2807 0.975952 0.975952C1.2807 0.671205 1.69402 0.5 2.125 0.5H6.45834V6.45834H0.5ZM1.58333 5.375H5.375V1.58333H2.125C1.98134 1.58333 1.84357 1.6404 1.74198 1.74198C1.6404 1.84357 1.58333 1.98134 1.58333 2.125V5.375ZM0.975952 13.0241C0.671205 12.7193 0.5 12.306 0.5 11.875V7.54167H6.45834V13.5H2.125C1.69402 13.5 1.2807 13.3288 0.975952 13.0241ZM5.375 8.625H1.58333V11.875C1.58333 12.0187 1.6404 12.1564 1.74198 12.258C1.84357 12.3596 1.98134 12.4167 2.125 12.4167H5.375V8.625ZM7.54167 7.54167V13.5H11.875C12.306 13.5 12.7193 13.3288 13.0241 13.0241C13.3288 12.7193 13.5 12.306 13.5 11.875V7.54167H7.54167ZM12.4167 11.875C12.4167 12.0187 12.3596 12.1564 12.258 12.258C12.1564 12.3596 12.0187 12.4167 11.875 12.4167H8.625V8.625H12.4167V11.875ZM10.7917 6.45834H9.70833V4.29167H7.54167V3.20834H9.70833V1.04167H10.7917V3.20834H12.9583V4.29167H10.7917V6.45834Z"
                                    fill="#0C2B3E"></path>
                            </svg> &nbsp;Registrar Problemática
                        </app-button>
                    </div>
                </div>
            </div>

            <div class="tab-content" id="pills-tabContent">
                <div class="tab-pane fade show active" id="pills-send" role="tabpanel" aria-labelledby="pills-send-tab">
                    <app-table [backgroundColor]="backgroundTable" [columns]="problematicasColumns"
                        [data]="problematicaSendData"></app-table>
                </div>
                <div class="tab-pane fade" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                    <app-table [backgroundColor]="backgroundTable" [columns]="problematicasColumns"
                        [data]="problematicaData"></app-table>
                </div>
                <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                    <app-table [backgroundColor]="backgroundTable" [columns]="problematicasColumns"
                        [data]="problematicaDataRechazado"></app-table>
                </div>
                <div class="tab-pane fade" id="pills-approve" role="tabpanel" aria-labelledby="pills-approve-tab">
                    <app-table [backgroundColor]="backgroundTable" [columns]="problematicasColumns"
                        [data]="problematicaDataAprobado"></app-table>
                </div>
            </div>

        </div>

        <div class="" *featureFlag="'F2_4_HU58'">

            <app-title-show-more [showMoreObject]="titleProblematicas"></app-title-show-more>

            <div id="sec_tab" class="nav nav-pills d-flex flex-md-nowrap flex-wrap" role="presentation">
                <div class="active" prm="re_hs" data-bs-toggle="pill" data-bs-target="#pills-home"
                    aria-controls="pills-homt" prm="re_ac" role="tab" type="button" aria-selected="false">
                    En Revisión
                </div>
                <div prm="re_hs" data-bs-toggle="pill" data-bs-target="#pills-profile" aria-controls="pills-profile"
                    class="" role="tab" type="button" aria-selected="false">
                    Solicitudes Rechazadas
                </div>
                <div prm="re_hs" data-bs-toggle="pill" data-bs-target="#pills-approve" aria-controls="pills-approve"
                    class="" role="tab" type="button" aria-selected="false">
                    Solicitudes Aprobadas
                </div>

                <div class="d-none d-md-block flex-fill" *ngIf="false">
                    <div class="d-flex justify-content-end">
                        <app-button (callbackAction)="buscarReto()">
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"
                                fill="none" class="iCn replaced-svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M0.5 6.45834V2.125C0.5 1.69402 0.671205 1.2807 0.975952 0.975952C1.2807 0.671205 1.69402 0.5 2.125 0.5H6.45834V6.45834H0.5ZM1.58333 5.375H5.375V1.58333H2.125C1.98134 1.58333 1.84357 1.6404 1.74198 1.74198C1.6404 1.84357 1.58333 1.98134 1.58333 2.125V5.375ZM0.975952 13.0241C0.671205 12.7193 0.5 12.306 0.5 11.875V7.54167H6.45834V13.5H2.125C1.69402 13.5 1.2807 13.3288 0.975952 13.0241ZM5.375 8.625H1.58333V11.875C1.58333 12.0187 1.6404 12.1564 1.74198 12.258C1.84357 12.3596 1.98134 12.4167 2.125 12.4167H5.375V8.625ZM7.54167 7.54167V13.5H11.875C12.306 13.5 12.7193 13.3288 13.0241 13.0241C13.3288 12.7193 13.5 12.306 13.5 11.875V7.54167H7.54167ZM12.4167 11.875C12.4167 12.0187 12.3596 12.1564 12.258 12.258C12.1564 12.3596 12.0187 12.4167 11.875 12.4167H8.625V8.625H12.4167V11.875ZM10.7917 6.45834H9.70833V4.29167H7.54167V3.20834H9.70833V1.04167H10.7917V3.20834H12.9583V4.29167H10.7917V6.45834Z"
                                    fill="#0C2B3E"></path>
                            </svg> &nbsp;Registrar Problemática
                        </app-button>
                    </div>
                </div>
            </div>

            <div class="tab-content" id="pills-tabContent">
                <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                    <app-table [backgroundColor]="backgroundTable" [columns]="problematicasColumns"
                        [data]="problematicaData"></app-table>
                </div>
                <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                    <app-table [backgroundColor]="backgroundTable" [columns]="problematicasColumns"
                        [data]="problematicaDataRechazado"></app-table>
                </div>
                <div class="tab-pane fade" id="pills-approve" role="tabpanel" aria-labelledby="pills-approve-tab">
                    <app-table [backgroundColor]="backgroundTable" [columns]="problematicasColumns"
                        [data]="problematicaDataAprobado"></app-table>
                </div>
            </div>

        </div>


        <!-- Academicos que quieren trabajar con mi organización -->
        <div class="">

            <app-title-show-more [showMoreObject]="titleAcademicosConmigo"></app-title-show-more>

            <div>

                <div id="sec_tab" class="nav nav-pills d-flex flex-md-nowrap flex-wrap" role="presentation">
                    <div class="active" data-bs-toggle="pill" data-bs-target="#tab-academico-recibidas"
                        aria-controls="tab-academico-recibidas" prm="re_ac" role="tab" type="button"
                        aria-selected="true">
                        Solicitudes recibidas
                    </div>
                    <div class="" data-bs-toggle="pill" data-bs-target="#tab-academico-aprobar"
                        aria-controls="tab-academico-aprobar " prm="re_ac" role="tab" type="button"
                        aria-selected="true">
                        Solicitudes aprobadas por CVDP
                    </div>
                    <div data-bs-toggle="pill" data-bs-target="#tab-academico-aceptado"
                        aria-controls="tab-academico-aceptado" class="" role="tab" type="button" aria-selected="false">
                        Solicitudes aceptadas
                    </div>
                    <div data-bs-toggle="pill" data-bs-target="#tab-academico-rechazadas"
                        aria-controls="tab-academico-rechazadas" class="" role="tab" type="button"
                        aria-selected="false">
                        Solicitudes rechazadas
                    </div>

                </div>
                <div class="tab-content" id="pills-tabContent">
                    <div class="tab-pane fade show active" id="tab-academico-recibidas" role="tabpanel"
                        aria-labelledby="tab-academico-recibidas-tab">
                        <app-table [backgroundColor]="backgroundTable" [columns]="academicoColumns"
                            [data]="solicitudesAcademicosRecibidasData"></app-table>
                    </div>
                    <div class="tab-pane fade" id="tab-academico-aprobar" role="tabpanel"
                        aria-labelledby="tab-academico-aprobar-tab">
                        <app-table [backgroundColor]="backgroundTable" [columns]="academicoColumns"
                            [data]="solicitudesAprobadaAcademicosData"></app-table>
                    </div>
                    <div class="tab-pane fade" id="tab-academico-aceptado" role="tabpanel"
                        aria-labelledby="tab-academico-aprobado-tab">
                        <app-table [backgroundColor]="backgroundTable" [columns]="academicoColumns"
                            [data]="solicitudesAceptadasAcademicosData"></app-table>
                    </div>
                    <div class="tab-pane fade" id="tab-academico-rechazadas" role="tabpanel"
                        aria-labelledby="tab-academico-rechazadas-tab">
                        <app-table [backgroundColor]="backgroundTable" [columns]="academicoColumns"
                            [data]="solicitudesRechazadasAcademicosData"></app-table>
                    </div>
                </div>
            </div>


        </div>




        <!-- Herramientas de Vinculacionn -->
        <div class="sec_ad">
            <div id="sec_tab" class="nav nav-pills d-flex flex-md-nowrap flex-wrap" role="presentation"></div>
            <div class="tab-content" id="pills-tabContent">
                <app-vinculacion></app-vinculacion>
            </div>
        </div>

    </div>
</div>




<!-- Modal de la carta solicitud -->
<ng-template #content>
    <div class="modal-header">
        <h4 *ngIf="!Cartafirmada && !vConfirmacion" class="modal-title" id="modal-basic-title">
            Carta Solicitud<br />¡Te damos la bienvenida!
        </h4>

        <h4 *ngIf="vConfirmacion" class="modal-title" id="modal-basic-title">
            El contacto ha sido registrado con éxito.
        </h4>

        <button *ngIf="!Cartafirmada" type="button" class="btn-close" aria-label="Close"
            (click)="closeConvenio(true)"></button>
        <button *ngIf="Cartafirmada" type="button" class="btn-close" aria-label="Close"
            (click)="closeModalyCargarDashboard()"></button>
    </div>
    <div class="modal-body">
        <div *ngIf="mostrarCartaaFirmar">
            <p *ngIf="!Cartafirmada">Antes de continuar deberás aceptar la carta de solicitud para ser Socio Formador
                del
                Tecnológico de Monterrey.
                Este documento formaliza oficialmente la relación de tu organización con la institución.</p>

            <div class="container-alert mb-3" *ngIf="Cartafirmada">
                <div class="row">
                    <div class="col-md-12 mt-2 mb-2">
                        <img alt="Aprobar" class="icono-success" src="assets/img/success.svg" />
                        <span class="textsuccess">
                            ¡La Carta de solicitud ha sido firmada con éxito!
                        </span>
                    </div>
                </div>
            </div>

            <app-convenio-viewer [IdSocioFormador]="IdSocioFormador" [showFilePDF]="filePdfFirmado"
                (filePdfEmitter)="convenioSeleccionado($event)"></app-convenio-viewer>


            <div class=" position-absolute end-0 me-4 my-3" *ngIf="Cartafirmada">
                <i>Carta firmada por {{ personaFirmaCarta }} el {{ fechaFirmaCarta }}</i>
            </div>

            <div class="requerido my-3" *ngIf="!Cartafirmada">
                <i class="py-2">NOTA: Si no eres tu quien firmará la carta, registra el nuevo usuario
                    <button type="button" class="btn btn-link btn_aqui"
                        (click)="enviarRegistroContactoSecundario()">AQUÍ</button>.</i>
            </div>
        </div>

        <div *ngIf="!mostrarCartaaFirmar">
            <form (ngSubmit)="guardarInformacion()" [formGroup]="formularioFormC">
                <!-- Datos de Contacto -->
                <div class="col-12" *ngIf="vFrmContacto">
                    <!-- Si la empresa existe aparece este texto -->
                    <div class="datosOrganizacion mt-5 d-none">
                        <span>Empresa con RFC/No. de tributación IRS: {{ this.rfc_empresa}} / {{ this.razonSocial
                            ||
                            this.nombreSocioFormador }}</span>
                    </div>

                    <!-- Título Datos de Contacto -->
                    <div class="frm_titulo_seccion mt-3">
                        Datos de contacto
                    </div>

                    <div class="row">

                        <div class="col-12 col-sm-6">
                            <div class="pl-60px">

                                <!-- Nombre -->
                                <div>
                                    <div class="d-flex1 justify-content-center1">
                                        <div class="form-group">

                                            <label for="nombre_contacto" class="form-label">Nombre <span
                                                    class="requerido">*</span></label>
                                            <input type="text" class="form-control" id="nombre_contacto"
                                                name="nombre_contacto" formControlName="nombre_contacto"
                                                placeholder="Escribe el nombre aquí">
                                            <div *ngIf="formularioFormC.get('nombre_contacto')?.touched && formularioFormC.get('nombre_contacto')?.errors?.['required']"
                                                class="error">
                                                El campo es requerido
                                            </div>
                                            <div *ngIf="formularioFormC.get('nombre_contacto')?.touched && formularioFormC.get('nombre_contacto')?.errors?.['minlength']"
                                                class="error">
                                                Mínimo 3 letras
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <!-- Apellidos -->
                                <div>
                                    <div class="d-flex1 justify-content-center1">
                                        <div class="form-group">
                                            <label for="apellidos_contacto" class="form-label">Apellidos <span
                                                    class="requerido">*</span></label>
                                            <input type="text" class="form-control" id="apellidos_contacto"
                                                name="apellidos_contacto" formControlName="apellidos_contacto"
                                                placeholder="Escribe los apellidos aquí">
                                            <div *ngIf="formularioFormC.get('apellidos_contacto')?.touched && formularioFormC.get('apellidos_contacto')?.errors?.['required']"
                                                class="error">
                                                El campo es requerido
                                            </div>
                                            <div *ngIf="formularioFormC.get('apellidos_contacto')?.touched && formularioFormC.get('apellidos_contacto')?.errors?.['minlength']"
                                                class="error">
                                                Mínimo 3 letras
                                            </div>
                                        </div>
                                    </div>
                                </div>



                                <!-- Area que pertenece -->
                                <div>
                                    <div class="d-flex1 justify-content-center1">
                                        <div class="form-group">
                                            <label for="area_departamento_contacto" class="form-label">Área a la que
                                                perteneces
                                                <span class="requerido">*</span></label>

                                            <div class="select-dropdown">
                                                <ng-select class=" colorGray" [items]="Areas" bindLabel="area"
                                                    bindValue="idArea" placeholder="Selecciona una opción"
                                                    formControlName="area_departamento_contacto"></ng-select>
                                            </div>

                                            <div *ngIf="formularioFormC.get('area_departamento_contacto')?.touched && formularioFormC.get('area_departamento_contacto')?.errors?.['required']"
                                                class="error">
                                                El campo es requerido
                                            </div>
                                            <div *ngIf="formularioFormC.get('area_departamento_contacto')?.touched && formularioFormC.get('area_departamento_contacto')?.errors?.['minlength']"
                                                class="error">
                                                Minimo 3 letras
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <!-- Puesto -->
                                <div>
                                    <div class="d-flex1 justify-content-center1">
                                        <div class="form-group">
                                            <label for="puesto_contacto" class="form-label">Puesto <span
                                                    class="requerido">*</span></label>
                                            <input type="text" class="form-control" id="puesto_contacto"
                                                name="puesto_contacto" formControlName="puesto_contacto"
                                                placeholder="Escribe el puesto aquí">
                                            <div *ngIf="formularioFormC.get('puesto_contacto')?.touched && formularioFormC.get('puesto_contacto')?.errors?.['required']"
                                                class="error">
                                                El campo es requerido
                                            </div>
                                            <div *ngIf="formularioFormC.get('puesto_contacto')?.touched && formularioFormC.get('puesto_contacto')?.errors?.['minlength']"
                                                class="error">
                                                Mínimo 3 letras
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="col-12 col-sm-6">

                            <!-- Correo electrónico -->
                            <div>
                                <div class="d-flex1 justify-content-center1">
                                    <div class="form-group">
                                        <label for="email_contacto" class="form-label">Correo electrónico <span
                                                class="requerido">*</span></label>
                                        <input type="text" class="form-control" id="email_contacto"
                                            name="email_contacto" formControlName="email_contacto"
                                            placeholder="Escribe el correo electrónico aquí">
                                        <div *ngIf="formularioFormC.get('email_contacto')?.touched && formularioFormC.get('email_contacto')?.errors?.['required']"
                                            class="error">
                                            El campo es requerido
                                        </div>
                                        <div *ngIf="formularioFormC.get('email_contacto')?.touched && formularioFormC.get('email_contacto')?.errors?.['email']"
                                            class="error">
                                            Correo no válido
                                        </div>
                                    </div>
                                </div>
                            </div>



                            <!-- Telefono Oficina -->
                            <div>
                                <div class="d-flex1 justify-content-center1">
                                    <div class="form-group">
                                        <label for="telefono_oficina_contacto" class="form-label">Teléfono de
                                            oficina</label>
                                        <input type="text" class="form-control" id="telefono_oficina_contacto"
                                            name="telefono_oficina_contacto"
                                            formControlName="telefono_oficina_contacto">
                                        <div *ngIf="formularioFormC.get('telefono_oficina_contacto')?.errors?.['pattern']"
                                            class="error">
                                            Deben ser sólo números
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <!-- Telefono de Celular -->
                            <div>
                                <div class="d-flex1 justify-content-center1">
                                    <div class="form-group">
                                        <label for="celular_contacto" class="form-label">Teléfono celular</label>
                                        <input type="text" class="form-control" id="celular_contacto"
                                            name="celular_contacto" formControlName="celular_contacto">
                                        <div *ngIf="formularioFormC.get('celular_contacto')?.errors?.['pattern']"
                                            class="error">
                                            Deben ser sólo números
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <!-- Eres EXATEC -->
                            <div class="row ">
                                <div class="d-flex1 justify-content-center1">
                                    <div class="form-group">
                                        <label for="exatec_contacto" class="form-label">¿Eres EXATEC? <span
                                                class="requerido">*</span></label>
                                        <div class="">
                                            <select class="form-control" formControlName="exatec_contacto">
                                                <option value="" selected disabled>Selecciona una opción</option>
                                                <option value="true">Si</option>
                                                <option value="false">No</option>
                                            </select>
                                            <div *ngIf="formularioFormC.get('exatec_contacto')?.touched && formularioFormC.get('exatec_contacto')?.errors?.['required']"
                                                class="error">
                                                El campo es requerido
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>


                            <!-- Cómo te enteraste -->
                            <div class="row">
                                <div class="d-flex1 justify-content-center1">
                                    <div class="form-group">
                                        <label for="enteraste_contacto" class="form-label">¿Cómo te enteraste de esta
                                            plataforma? <span class="requerido">*</span></label>

                                        <div class="select-dropdown">
                                            <ng-select class=" colorGray" [items]="MediosDeContacto"
                                                bindLabel="medioContacto" bindValue="idMedioContacto"
                                                placeholder="Selecciona una opción"
                                                formControlName="enteraste_contacto"></ng-select>
                                        </div>



                                        <div *ngIf="formularioFormC.get('enteraste_contacto')?.touched && formularioFormC.get('enteraste_contacto')?.errors?.['required']"
                                            class="error">
                                            El campo es requerido
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>



                    </div>




                    <button class="btn-amarillo d-none" type="submit"
                        [disabled]="formularioFormC.invalid">Enviar</button>


                </div>

                <!-- botonera -->
                <div class="col-12 mt-3 d-sm-flex justify-content-center" *ngIf="vFrmContacto">
                    <button class="btn-amarillo mx-sm-3 mt-3 order-sm-1" type="submit">Enviar</button>
                    <button class="btn-brown  mx-sm-3 mt-3 order-sm-0" type="button"
                        (click)="regresaraMostrarCartaFirmar()">Cancelar</button>

                </div>
            </form>
        </div>


        <div class="row text-center mt-5 mb-5" *ngIf="vConfirmacion">
            <div class="selft-align-center">

                <div class="titulo">
                    <p>
                        Una vez que el Centro de Vinculación y Desarrollo profesional del Tec lo apruebe, podrá ingresar
                        y firmar el documento.
                        <br>
                        Próximamente te llegará un correo electrónico del status de tu solicitud.
                        <br>
                        No podrás acceder hasta que se haya firmado la carta.
                    </p>
                    <p> ¡Muchas gracias por tu interés de ser Socio Formador! </p>
                    <p>
                        Si tienes alguna duda envía un correo a
                        <a href="mailto: sociosformadores@servicios.tec.mx">sociosformadores@servicios.tec.mx</a>
                    </p>
                </div>
            </div>
            <div class="col-12 mt-3 d-sm-flex justify-content-center">
                <button class="btn-amarillo mx-sm-3 mt-3 " type="button" (click)="closeConvenio(false)">Salir</button>
            </div>
        </div>



    </div>
    <div class="modal-footer" *ngIf="!vConfirmacion && mostrarCartaaFirmar">
        <button *ngIf="!Cartafirmada" type="button" class="btn btn-brown" (click)="closeConvenio(true)">Más
            tarde</button>
        <button *ngIf="!Cartafirmada" type="button" class="btn btn-blue" (click)="rechazar()">Rechazar</button>
        <button *ngIf="!Cartafirmada" type="button" class="btn btn-amarillo" (click)="firmaPaso1()">Firmar</button>
        <button *ngIf="Cartafirmada" type="button" class="btn btn-amarillo" (click)="downloadPDF()">Descargar
            PDF</button>
    </div>
</ng-template>


<!-- Confirmacion de la carta solicitud -->
<ng-template #content_firm let-c="dismiss">
    <div class="modal-body">
        <p> Yo, {{ nombreContacto }} reitero mi interés para formalizar la relación como Socio Formador con el
            Tecnológico de Monterrey. </p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-brown" (click)="c('dismiss')">Cancelar</button>
        <button type="button" class="btn btn-amarillo" (click)="firmaPaso2(content_firm)">Estoy de Acuerdo</button>
    </div>
</ng-template>