import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Paginador } from '@shared/Interface/IPaginador.interface';
import { ISolicitudesSinRetoResponse } from '@shared/Interface/ISolicitudesSinRetoResponse';
import { PaginadorLibsComponent } from '@shared/libs/paginador.component';
import { DashboardService } from '@shared/service/Dashboard/dashboard.service';
import { UsuariosService } from '@shared/service/Usuarios/usuarios.service';
import { environment } from 'environments/environment';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-solicitudes-sin-reto-spa',
  templateUrl: './solicitudes-por-aprobar.component.html',
  styleUrls: ['./solicitudes-por-aprobar.component.css'],
})
export class SolicitudesSinRetoSPAComponent implements OnInit {

  @Input() estatusType: string;
  idUsuario: number = 0;
  sidebarExpanded = false;
  lang: string = 'es';
  numberPerPage = environment.RegistrosPagina;
  pages: number[] = [];
  paginaActual = 1;
  numeroPaginas = 0;
  solicitudesList: ISolicitudesSinRetoResponse[] = [];
  solicitudesListTotal: ISolicitudesSinRetoResponse[] = [];
  elementsTo: ISolicitudesSinRetoResponse[] = [];
  paginaActualMasResultados: number = 1;

  textOrdenarPor: string = 'retos_busqueda_sf.ordenar_por';
  valorOrden: number = 0;

  constructor(
    private _dashboardService: DashboardService,
    private paginadorLibs: PaginadorLibsComponent,
    private usuarioService: UsuariosService,
    private router: Router
  ) {}

  async ngOnInit(): Promise<void> {
    this.lang = localStorage.getItem('lang') ?? 'es';

    let userStore = this.usuarioService.getCurrentUserData();
    if (userStore) {
      this.idUsuario = userStore.clavePerfil === 'cvdp_admin' ? 0: userStore.idUsuario;
    }

    await this.getSolicitudes();
    this.getPaginacion();
    this.obtenerRegistrosActuales(this.paginaActual);
  }

  async getSolicitudes() {
    try {
      let type = this.estatusType ?? 'revision';

      let idCampus: number = 0;
      let idEscuela: number = 0;
      let idPeriodo: number = 0;
      let pagina: number = 0;
      let hits: number = 50;

      let response = await lastValueFrom(this._dashboardService
        .getSolicitudesSinRetoByCVDPAndEstatus(this.idUsuario, type, idCampus, idEscuela, idPeriodo, pagina, hits));
      if (response != null && response != undefined && response.length > 0) {
        this.solicitudesListTotal = response;
      }
    } catch (error) {
      console.error('Error al realizar la peticion', error);
    }
  }

  getPaginacion() {
    let paginador: Paginador = this.paginadorLibs.getPaginacion(
      this.solicitudesListTotal.length,
      this.numberPerPage
    );
    this.numeroPaginas = paginador.totalPaginas;
    this.pages = paginador.paginas;
  }

  obtenerRegistrosActuales(numPagina: number) {
    this.solicitudesList = this.paginadorLibs.obtenerRegistrosActuales(
      numPagina,
      this.numberPerPage,
      this.solicitudesListTotal
    );
    this.paginaActual = numPagina;
    this.elementsTo = this.solicitudesList;
    this.paginaActualMasResultados = numPagina;
  }

  obtenerMasResultados() {
    this.paginaActualMasResultados += 1;
    this.elementsTo = this.paginadorLibs.obtenerMasResultados(
      this.paginaActualMasResultados,
      this.numberPerPage,
      this.solicitudesListTotal
    );
  }

  ordenarLista(keyword: string) {
    try {
      switch (keyword) {
        case 'nombreReto':
          this.textOrdenarPor = 'solicitudes_sin_reto.nombre_az';
          this.valorOrden = 1;
          this.ordenarByNombre();
          break;

        case 'SocioFormador':
          this.textOrdenarPor = 'solicitudes_sin_reto.socio_f';
          this.valorOrden = 2;
          this.ordenarBySF();
          break;
      }
    } catch (error) {
      console.error('Error al ordenar la lista ', error);
    }
  }

  ordenarByNombre() {
    this.solicitudesListTotal.sort(function (a, b) {
      if (a.proyecto < b.proyecto) return -1;
      else if (a.proyecto > b.proyecto) return 1;
      else return 0;
    });

    this.elementsTo.sort(function (a, b) {
      if (a.proyecto < b.proyecto) return -1;
      else if (a.proyecto > b.proyecto) return 1;
      else return 0;
    });
    this.obtenerRegistrosActuales(1);
  }

  ordenarBySF() {
    this.solicitudesListTotal.sort(function (a, b) {
      if (a.nombreContacto < b.nombreContacto) return -1;
      else if (a.nombreContacto > b.nombreContacto) return 1;
      else return 0;
    });

    this.elementsTo.sort(function (a, b) {
      if (a.nombreContacto < b.nombreContacto) return -1;
      else if (a.nombreContacto > b.nombreContacto) return 1;
      else return 0;
    });

    this.obtenerRegistrosActuales(1);
  }

  changeLang(lang: string) {
    this.lang = lang;
    localStorage.setItem('lang', lang);
    window.location.reload();
  }

  verSolicitudes(
    idSolicitud: number,
    idProblematica: number,
    idSocioFormador: number
  ) {
    let url = `/solicitud-problematica/${idProblematica}/${idSocioFormador}/${idSolicitud}`;
    window.location.href = url;
  }
}
