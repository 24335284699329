import { Column, optionsTextCol } from "@shared/components/table/table.component";

export const COLS_BUSQUEDA_SOCIOS_FORMADORES: Column[] = [
    {
      "title": "Fecha",
      "sizeCol": "1",
      "classType": "dashbd_rows_img justify-content-md-center",
      "typeColumn": optionsTextCol.date,
      "key": "fechaSolicitud",
    },
    {
      "title": "Nombre del Reto",
      "sizeCol": "4",
      "classType": "dashbd_rows_img justify-content-md-center",
      "typeColumn": optionsTextCol.imageTitle,
      "key": "reto",
    },
    {
      "title": "Socio Formador",
      "sizeCol": "3",
      "classType": "dashbd_rows_img justify-content-md-center",
      "typeColumn": optionsTextCol.imageTitle,
      "key": "link",
    },
    {
      "title": "Periodo Semestral",
      "sizeCol": "1",
      "classType": "dashbd_rows_img justify-content-md-center",
      "typeColumn": optionsTextCol.text,
      "key": "periodo",
    },
    {
      "title": "Periodo Semanal",
      "sizeCol": "1",
      "classType": "dashbd_rows_img justify-content-md-center",
      "typeColumn": optionsTextCol.text,
      "key": "periodoSemanal",
    },
    {
      "title": "Estatus",
      "sizeCol": "1",
      "classType": "dashbd_rows_img justify-content-md-center",
      "typeColumn": optionsTextCol.status,
      "key": "estatus",
    },
    {
      "title": "Acciones",
      "sizeCol": "1",
      "classType": "dashbd_rows_img justify-content-md-center",
      "typeColumn": optionsTextCol.status,
      "key": "claveEstatusSolicitud",
    },
  ];
  export const COLS_RETOS: Column[] = [
    {
      title: "Fecha",
      sizeCol: "1",
      classType: "dashbd_rows_img justify-content-md-center",
      typeColumn: optionsTextCol.date,
      key: "fechaRegistro",
    },
    {
      title: "Nombre del Reto",
      sizeCol: "2",
      classType: "dashbd_rows_img",
      typeColumn: optionsTextCol.imageTitle,
      key: "reto",
    },
    {
      title: "Duración",
      sizeCol: "1",
      classType: "dashbd_rows_img",
      typeColumn: optionsTextCol.text,
      key: "duracionSemanas",
    },
    {
      title: "Periodo Semanal",
      sizeCol: "1",
      classType: "dashbd_rows_img",
      typeColumn: optionsTextCol.text,
      key: "periodoSemanal",
    },
    {
      title: "Socio Formador",
      sizeCol: "2",
      classType: "dashbd_rows_img",
      typeColumn: optionsTextCol.imageTitle,
      key: "nombreSocioFormador",
    },
    {
      title: "Contacto del SF",
      sizeCol: "2",
      classType: "dashbd_rows_img",
      typeColumn: optionsTextCol.imageTitle,
      key: "contactPerson",
    },
    {
      title: "Campus",
      sizeCol: "1",
      classType: "dashbd_rows_img",
      typeColumn: optionsTextCol.text,
      key: "campus",
    },
    {
      title: "Periodo Semestral",
      sizeCol: "1",
      classType: "dashbd_rows_img",
      typeColumn: optionsTextCol.text,
      key: "periodoSemestral",
    },
    {
      title: "Acciones",
      sizeCol: "1",
      classType: "dashbd_rows_img",
      typeColumn: optionsTextCol.status,
      key: "claveEstatusSolicitud",
    },
    
  /**
   * {
      title: 'Avance',
      sizeCol: '',
      classType: 'dashbd_rows_prog',
      typeColumn: optionsTextCol.progress,
      key: 'porcentajeAvance',
    },
   */
  ];

  export const COLS_SOCIOS_FORMADORES_VINCULAR: Column[] = [
    {
      title: "Fecha",
      sizeCol: "1",
      classType: "dashbd_rows_img justify-content-md-center",
      typeColumn: optionsTextCol.date,
      key: "fechaSolicitud",
    },
    {
      title: "Socio Formador",
      sizeCol: "3",
      classType: "dashbd_rows_img justify-content-md-center",
      typeColumn: optionsTextCol.imageTitle,
      key: "nombreSocioFormador",
    },
    {
      title: "Reto a Vincular",
      sizeCol: "3",
      classType: "dashbd_rows_img justify-content-md-center",
      typeColumn: optionsTextCol.imageTitle,
      key: "reto",
    },
    {
      title: "Duración",
      sizeCol: "1",
      classType: "dashbd_rows_img justify-content-md-center",
      typeColumn: optionsTextCol.text,
      key: "duracionSemanas",
    },
    {
      title: "Periodo Semanal",
      sizeCol: "1",
      classType: "dashbd_rows_img justify-content-md-center",
      typeColumn: optionsTextCol.text,
      key: "periodoSemanal",
    },
    {
      title: "Periodo Semestral",
      sizeCol: "1",
      classType: "dashbd_rows_img justify-content-md-center",
      typeColumn: optionsTextCol.text,
      key: "periodoSemestral",
    },
    {
      title: "Campus",
      sizeCol: "1",
      classType: "dashbd_rows_img justify-content-md-center",
      typeColumn: optionsTextCol.text,
      key: "campus",
    },
    {
      title: "Acciones",
      sizeCol: "1",
      classType: "dashbd_rows_img justify-content-md-center",
      typeColumn: optionsTextCol.status,
      key: "claveEstatusSolicitud",
    },
  ];

  /**Documentacion de reto Academico*/

  export const COLS_LISTADO_RETOS_FORM_ACAD: Column[] = [
    {
      title: "Fecha",
      sizeCol: "1",
      classType: "dashbd_rows_img justify-content-md-center",
      typeColumn: optionsTextCol.date,
      key: "fechaRegistro",
    },
    {
      title: "Nombre del Reto",
      sizeCol: "3",
      classType: "dashbd_rows_img",
      typeColumn: optionsTextCol.imageTitle,
      key: "reto",
    },
    {
      title: "Duración",
      sizeCol: "1",
      classType: "dashbd_rows_img",
      typeColumn: optionsTextCol.text,
      key: "duracion",
    },
    {
      title: "Periodo semanal",
      sizeCol: "1",
      classType: "dashbd_rows_img",
      typeColumn: optionsTextCol.text,
      key: "periodoSemanal",
    },
    {
      title: "Periodo semestral",
      sizeCol: "1",
      classType: "dashbd_rows_img",
      typeColumn: optionsTextCol.text,
      key: "periodoSemestral",
    },
    {
      title: "Campus y Esc",
      sizeCol: "2",
      classType: "dashbd_rows_img",
      typeColumn: optionsTextCol.imageTitle,
      key: "imageTitle",
    },
    {
      title: "CRN",
      sizeCol: "1",
      classType: "dashbd_rows_img",
      typeColumn: optionsTextCol.text,
      key: "proyecto",
    },
    
    {
      title: "Acciones",
      sizeCol: "2",
      classType: "dashbd_rows_img",
      typeColumn: optionsTextCol.status,
      key: "claveEstatusSolicitud",
    },
  ];

  export const COLS_LISTADO_RETOS_CURS_ACAD: Column[] = [
    {
      title: "Fecha",
      sizeCol: "1",
      classType: "dashbd_rows_img justify-content-md-center",
      typeColumn: optionsTextCol.date,
      key: "fechaRegistro",
    },
    {
      title: "Nombre del Reto",
      sizeCol: "2",
      classType: "dashbd_rows_img",
      typeColumn: optionsTextCol.imageTitle,
      key: "reto",
    },
    {
      title: "Duración",
      sizeCol: "1",
      classType: "dashbd_rows_img",
      typeColumn: optionsTextCol.text,
      key: "duracion",
    },
    {
      title: "Periodo semanal",
      sizeCol: "1",
      classType: "dashbd_rows_img",
      typeColumn: optionsTextCol.text,
      key: "periodoSemanal",
    },
    {
      title: "Periodo semestral",
      sizeCol: "1",
      classType: "dashbd_rows_img",
      typeColumn: optionsTextCol.text,
      key: "periodoSemestral",
    },
    {
      title: "Campus y Esc",
      sizeCol: "1",
      classType: "dashbd_rows_img",
      typeColumn: optionsTextCol.imageTitle,
      key: "imageTitle",
    },
    {
      title: "CRN",
      sizeCol: "1",
      classType: "dashbd_rows_img",
      typeColumn: optionsTextCol.text,
      key: "proyecto",
    },
    {
      title: "Socio Formador",
      sizeCol: "2",
      classType: "dashbd_rows_img",
      typeColumn: optionsTextCol.imageTitle,
      key: "nombreSocioFormador",
    },
    
    {
      title: "Acciones",
      sizeCol: "2",
      classType: "dashbd_rows_img",
      typeColumn: optionsTextCol.status,
      key: "claveEstatusSolicitud",
    },
  ];

  export const COLS_LISTADO_RETOS_CONC_ACAD: Column[] = [
    {
      title: "Fecha",
      sizeCol: "1",
      classType: "dashbd_rows_img justify-content-md-center",
      typeColumn: optionsTextCol.date,
      key: "fechaRegistro",
    },
    {
      title: "Nombre del Reto",
      sizeCol: "2",
      classType: "dashbd_rows_img",
      typeColumn: optionsTextCol.imageTitle,
      key: "reto",
    },
    {
      title: "Duración",
      sizeCol: "1",
      classType: "dashbd_rows_img",
      typeColumn: optionsTextCol.text,
      key: "duracion",
    },
    {
      title: "Periodo semanal",
      sizeCol: "1",
      classType: "dashbd_rows_img",
      typeColumn: optionsTextCol.text,
      key: "periodoSemanal",
    },
    {
      title: "Periodo semestral",
      sizeCol: "1",
      classType: "dashbd_rows_img",
      typeColumn: optionsTextCol.text,
      key: "periodoSemestral",
    },
    {
      title: "Campus y Esc",
      sizeCol: "1",
      classType: "dashbd_rows_img",
      typeColumn: optionsTextCol.imageTitle,
      key: "imageTitle",
    },
    {
      title: "Socio Formador",
      sizeCol: "2",
      classType: "dashbd_rows_img",
      typeColumn: optionsTextCol.imageTitle,
      key: "nombreSocioFormador",
    },    
    {
      title: "Estudiantes Impactados",
      sizeCol: "1",
      classType: "dashbd_rows_img",
      typeColumn: optionsTextCol.text,
      key: "inscritos",
    },    
    {
      title: "Acciones",
      sizeCol: "2",
      classType: "dashbd_rows_img",
      typeColumn: optionsTextCol.status,
      key: "claveEstatusSolicitud",
    },
  ];