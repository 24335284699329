import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-body-table',
  templateUrl: './body-table.component.html',
  styleUrls: ['./body-table.component.css'],
})
export class BodyTableComponent  {
  @Input()
  columns: any;
  @Input()
  data: any;
  

  getValueTable(value: any, column: any) {
    return value[column.key];
  }

  getSizeTable(value: any, key: any) {
    return value[key];
  }
}
