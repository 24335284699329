import { Component, OnInit } from "@angular/core";
import {
  COLS_LISTADO_RETOS_FORM,
  COLS_LISTADO_RETOS_CURS,
  COLS_LISTADO_RETOS_CONC,
} from "@pages/dashboard/cvdp-dashboard/cvdp-dashboard.columns";
import { IRetosCVDPResponse } from "@shared/Interface/IRetosCVDPResponse";
import { DashboardService } from "@shared/service/Dashboard/dashboard.service";
import { UsuariosService } from "@shared/service/Usuarios/usuarios.service";
import { lastValueFrom } from "rxjs";

@Component({
  selector: "app-retos",
  templateUrl: "./mis-retos.component.html",
  styleUrls: ["./mis-retos.component.css"],
})
export class MisRetosComponent implements OnInit {
  buscadorText: string = "";
  sidebarExpanded = false;
  lang: string = "es";

  titleRetos = {
    title: "Mis Retos",
  };

  backgroundTable = "#F6F6F6";
  IdSocioFormador: number;

  //* Documentacion de retos
  listadoRetosFormalizacionColumns = COLS_LISTADO_RETOS_FORM;
  listadoRetosFormalizacionData: any;

  listadoRetosCursoColumns = COLS_LISTADO_RETOS_CURS;
  listadoRetosCursoData: any;

  listadoRetosEvaluacionColumns = COLS_LISTADO_RETOS_CURS;
  listadoRetosEvaluacionData: any;

  listdaoRetosConcluidoColumns = COLS_LISTADO_RETOS_CONC;
  listadoRetosConcluidoData: any;

  mostrarRetos: boolean = false;
  status: string = "formalizacion";

  constructor(
    private usuarioService: UsuariosService,
    private dashboardService: DashboardService
  ) {}

  async ngOnInit(): Promise<void> {
    this.lang = localStorage.getItem("lang") ?? "es";

    // Documentacion
    if (!this.mostrarRetos) {
      this.IdSocioFormador = this.usuarioService.getCurrentUserData().idSocioFormador;
      let responseRetos;
      try {
        responseRetos = await lastValueFrom(
          this.dashboardService.getRetosSocio(
            this.IdSocioFormador,
            "formalizacion"
          )
        );
        this.listadoRetosFormalizacionData = this.getListadoRetosFormalizacion(
          responseRetos
        );
      } catch (error) {
        console.log(error);
      }

      try {
        responseRetos = await lastValueFrom(
          this.dashboardService.getRetosSocio(this.IdSocioFormador, "en-curso")
        );
        this.listadoRetosCursoData = this.getListadoRetosCursoyEval(
          responseRetos, "en-curso"
        );
      } catch (error) {
        console.log(error);
      }

      try {
        responseRetos = await lastValueFrom(
          this.dashboardService.getRetosSocio(
            this.IdSocioFormador,
            "evaluacion"
          )
        );
        this.listadoRetosEvaluacionData = this.getListadoRetosCursoyEval(
          responseRetos, "evaluacion"
        );
      } catch (error) {
        console.log(error);
      }

      try {
        responseRetos = await lastValueFrom(
          this.dashboardService.getRetosSocio(this.IdSocioFormador, "concluido")
        );

        this.listadoRetosConcluidoData = this.getListadoRetosConcluido(
          responseRetos
        );
      } catch (error) {
        console.log(error);
      }
    }
  }

  changeLang(lang: string) {
    this.lang = lang;
    localStorage.setItem("lang", lang);
    window.location.reload();
  }

  getListadoRetosFormalizacion(resultado) {
    return resultado.map((solicitud: IRetosCVDPResponse) => ({
      idReto: solicitud.idReto,
      fechaRegistro: solicitud.fechaRegistro,
      reto: {
        title: solicitud.reto,
        subtitle: solicitud.unidadFormativa,
        description: solicitud.escuela,
        link: `/sf/formalizacion?reto=${solicitud.idReto}`,
      },
      nombreSocioFormador: {
        title: solicitud.nombreSocioFormador,
        subtitle: solicitud.rfc,
      },
      duracion: solicitud.semanas ?? "-",
      periodoSemanal: solicitud.periodoSemanal,
      periodoSemestral: solicitud.periodoSemestral,
      imageTitle: {
        title: solicitud.campus ?? "",
        subtitle: solicitud.escuela,
      },
      proyecto: solicitud.alcance,
      claveEstatusSolicitud: {
        textCol: "Firmar",
        statusType: "amarillo",
        link: `/sf/formalizacion?reto=${solicitud.idReto}`,
      },
    }));
  }

  getListadoRetosCursoyEval(resultado, estatus) {
    return resultado.map((solicitud: IRetosCVDPResponse) => ({
      idReto: solicitud.idReto,
      fechaRegistro: solicitud.fechaRegistro,
      reto: {
        title: solicitud.reto,
        subtitle: solicitud.unidadFormativa,
        description: solicitud.escuela,
        link: `/sf/${estatus}?reto=${solicitud.idReto}`,
      },
      duracion: solicitud.semanas ?? "-",
      periodoSemanal: solicitud.periodoSemanal,
      periodoSemestral: solicitud.periodoSemestral,
      imageTitle: {
        title: solicitud.campus ?? "",
        subtitle: solicitud.escuela,
      },
      nombreSocioFormador: {
        title: solicitud.nombreContacto,
        subtitle: solicitud.correoContacto,
      },
      claveEstatusSolicitud: {
        textCol: "Ver",
        statusType: "primary",
        link: `/sf/${estatus}?reto=${solicitud.idReto}`,
      },
    }));
  }

  getListadoRetosConcluido(resultado) {
    return resultado.map((solicitud: IRetosCVDPResponse) => ({
      idReto: solicitud.idReto,
      fechaRegistro: solicitud.fechaRegistro,
      reto: {
        title: solicitud.reto,
        subtitle: solicitud.unidadFormativa,
        description: solicitud.escuela,
        link: `/sf/concluido?reto=${solicitud.idReto}`,
      },
      duracion: solicitud.semanas ?? "-",
      periodoSemanal: solicitud.periodoSemanal,
      periodoSemestral: solicitud.periodoSemestral,
      imageTitle: {
        title: solicitud.campus ?? "",
        subtitle: solicitud.escuela,
      },
      nombreSocioFormador: {
        title: solicitud.nombreSocioFormador,
        subtitle: solicitud.rfc,
      },
      claveEstatusSolicitud: {
        textCol: "Ver",
        statusType: "primary",
        link: `/sf/concluido?reto=${solicitud.idReto}`,
      },
    }));
  }

  changeViewStatus(status) {
    this.status = status;
  }
}
