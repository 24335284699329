import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
  Input
} from "@angular/core";
import { Router } from "@angular/router";
import { IFuncionesSidebarResponse } from "@shared/Interface/IFuncionesSidebarResponse";
import { IPerfilSidebarResponse } from "@shared/Interface/IPerfilSideBarResponse";
import { ISidebarResponse } from "@shared/Interface/ISidebarResponse";
import { InicioService } from "@shared/service/Inicio/inicio.service";
import { UsuariosService } from "@shared/service/Usuarios/usuarios.service";

declare const $: any;


@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"],
})
export class SidebarComponent implements OnInit, AfterViewInit {
  clavePerfil: string = "socio";
  sidebarItems: ISidebarResponse;
  menuActivo: string = "dashboard";


  constructor(
    private inicioService: InicioService,
    private usuarioService: UsuariosService,
    private router: Router,
    private cd: ChangeDetectorRef,
    private inicio: InicioService
  ) {
    let func: IFuncionesSidebarResponse[] = [];
    let perf: IPerfilSidebarResponse = { clavePerfil: "", perfil: "" };

    this.sidebarItems = {
      perfil: perf,
      funciones: func,
    };
  }

  ngAfterViewInit(): void {
    this.cd.detectChanges();
  }

  ngOnInit(): void {
    
    this.inicio.currentMenuOption.subscribe((menu) => (this.menuActivo = menu));

    

    setTimeout(()=>{this.getSolicitudes();}, 1000);

    $(document).ready(function () {
      $("#sidebar ul.list-unstyled li").on({
        click: function () {
          $("#sidebar").removeClass("active");
        },
      });
    });
  }

  irMenu(opcion: string, link: string) {
    this.menuActivo = opcion;
    this.inicio.changeMenuItem(opcion);
    this.router.navigateByUrl(link);
  }

  getPathImg(img) {
    return `assets/img/${img}`;
  }

  getSolicitudes() {
    let idUsuario = this.usuarioService.getCurrentUserData().idUsuario;
    this.inicioService.getSideBarUsuario(idUsuario).subscribe({
      next: (resOk) => {
        this.sidebarItems = resOk;

        let resFun = this.sidebarItems.funciones;

        let hash = {};
        resFun = resFun.filter(function (current) {
          let exists = !hash[current.opcion];
          hash[current.opcion] = true;
          return exists;
        });

        resFun = resFun.sort((a,b)=>{
          if (Number(a.orden) < Number(b.orden)) return -1;
          if (Number(a.orden) > Number(b.orden)) return 1;
          return 0;
        });

        this.sidebarItems.funciones = resFun;
      },
      error: (error) => {
        console.log("Error al consultar el sidebar", error);
      },
    });
  }

  loadScript() {
    const script = document.createElement("script");
    script.src = "assets/js/sidebar-icons.js";
    script.async = true;
    document.body.appendChild(script);
  }

  logout() {
     this.usuarioService.logout();
  }
}
