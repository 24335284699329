import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.css'],
})
export class ButtonComponent  {
  @Input()
  buttonType = 'blue';
  @Input()
  extraClass = '';
  @Input() isDisabled = false;
  @Output() callbackAction: EventEmitter<any> = new EventEmitter<any>();



  listenerActionClick() {
    this.callbackAction.emit();
  }
}
