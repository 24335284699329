import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DashboardComponent } from "@pages/dashboard/dashboard.component";

import { MisRetosComponent } from "@pages/mis-retos/mis-retos.component";

import { AcademicoBuscaSFComponent } from "@pages/academico-busca-sf/academico-busca-sf.component";
import { GestionUsuarioComponent } from "@pages/gestion-usuarios/gestion-usuarios.component";
import { HomesfComponent } from "@pages/homesf/homesf.component";
import { LoginComponent } from "@pages/login/login.component";
import { NotificacionesComponent } from "@pages/notificaciones/notificaciones.component";
import { PaginaRetosComponent } from "@pages/pagina-retos/pagina-retos.component";
import { ProblematicasComponent } from "@pages/problematicas/problematicas.component";
import { RegistroSFComponent } from "@pages/registro-sf/registro-sf.component";
import { RetosComponent } from "@pages/retos/retos.component";
import { SolicitudesSinRetoComponent } from "@pages/solicitudes-sin-reto/solicitudes-sin-reto.component";
import { VinculacionesComponent } from "@pages/vinculaciones/vinculaciones.component";

import { MsalGuard } from "@azure/msal-angular";
import { AcademicoBancoSFComponent } from "@pages/academia-banco-socio-formador/academia-banco-socio-formador.component";
import { SolicitudesVinculacionRetosComponent } from "@pages/academia/solicitudes-vinculacion-retos/solicitudes-vinculacion-retos.component";
import { SFSolicitandoRetosComponent } from "@pages/CVDP/SF-solicitando-reto/SF-solicitando-reto.component";
import { RetosBusquedaSFComponent } from "@pages/retos-busqueda-sf/retos-busqueda-sf.component";
import { SolicitudesComponent } from "@pages/solicitud-socios-formadores/solicitudes.component";
import { ConfirmacionVinculacionComponent } from "@pages/academia/confirmacion-vinculacion/confirmacion-vinculacion.component";
import { RetoRegistradoComponent } from "@pages/academia/reto-registrado/reto-registrado.component";
import { SocioFormadorComponent } from "@pages/academia/socio-formador/socio-formador.component";
import { SolicitudVinculacionARetoComponent } from "@pages/academia/solicitud-vinculacion-a-reto/solicitud-vinculacion-a-reto.component";
import { AvisoPrivacidadComponent } from "@pages/aviso-privacidad/aviso-privacidad.component";
import { BancoRetosComponent } from "@pages/banco-retos/banco-retos.component";
import { MatchesSociosComponent } from "@pages/matches-socios/matches-socios.component";
import { PreguntasfrecuentesComponent } from "@pages/preguntasfrecuentes/preguntasfrecuentes.component";
import { ProblematicaRegistradaComponent } from "@pages/problematica-registrada/problematica-registrada.component";
import { RecursosVinculacionSfComponent } from "@pages/recursos-vinculacion-sf/recursos-vinculacion-sf.component";
import { RegistraRetoComponent } from "@pages/registra-reto/registra-reto.component";
import { PerfilContactComponent } from "@pages/registro-perfil/perfil-contact/perfil-contact.component";
import { RegistroPerfilComponent } from "@pages/registro-perfil/registro-perfil.component";
import { RegistroProblematicaComponent } from "@pages/registro-problematica/registro-problematica.component";
import { RetoConfirmacionComponent } from "@pages/reto-confirmacion/reto-confirmacion.component";
import { RetoResumenComponent } from "@pages/reto-resumen/reto-resumen.component";
import { SfNoEncontreSfComponent } from "@pages/sf-no-encontre-sf/sf-no-encontre-sf.component";
import { SocioSearchComponent } from "@pages/socio-search/socio-search.component";
import { SolicitudAcademicoComponent } from "@pages/solicitud-academico/solicitud-academico.component";
import { SolicitudProblematicaComponent } from "@pages/solicitud-problematica/solicitud-problematica.component";
import { SolicitudComponent } from "@pages/solicitud/solicitud.component";
import { ValidaSesionComponent } from "@pages/valida-sesion/valida-sesion.component";
import { VinculaRetoComponent } from "@pages/vincula-reto/vincula-reto.component";
import { VinculaRetosBusquedaComponent } from "@pages/vincula-retos-busqueda/vincula-retos-busqueda.component";
import { VinculaRetosSearchComponent } from "@pages/vincula-retos-search/vincula-retos-search.component";
import { MainLayoutBuscadorComponent } from "@shared/components/main-layout-buscador/main-layout-buscador.component";
import { UsuariosAcademiaComponent } from "@pages/usuarios-academia/usuarios-academia.component";
import { ConveniosComponent } from "@pages/CVDP/convenios/convenios.component";
import { RegistraRetoSemillaComponent } from "@pages/registra-reto-semilla/registra-reto-semilla.component";
import { VinculaUsuarios2Component } from "@pages/vincula-usuarios2/vincula-usuarios2.component";
import { RegistraRetoSemilla2Component } from "@pages/registra-reto-semilla2/registra-reto-semilla2.component";
import { VinculaUsuarios3Component } from "@pages/vincula-usuarios3/vincula-usuarios3.component";
import { CasosDeExitoComponent } from "@pages/casos-de-exito/casos-de-exito.component";
import { RecursosDeVinculacionComponent } from "@pages/recursos-de-vinculacion/recursos-de-vinculacion.component";
import { ContactosSecundariosComponent } from "@pages/contactos-secundarios/contactos-secundarios.component";
import { RegistroRecursosVinculacionComponent } from "@pages/recursos-vinculacion-sf/registro-recursos-vinculacion/registro-recursos-vinculacion.component";
import { ReportesComponent } from "./reportes/reportes.component";
import { RegistroCasosExitoComponent } from "@pages/casos-de-exito/registro-casos-exito/registro-casos-exito.component";
import { DetalleComponent } from "./reportes/detalle/detalle.component";
import { GestionSocioformadorComponent } from "@pages/gestion-socioformador/gestion-socioformador.component";
import { GestionNotificacionesComponent } from "@pages/gestion-notificaciones/gestion-notificaciones.component";
import { MisRetosCvdpComponent } from "@pages/mis-retos-cvdp/mis-retos-cvdp.component";
import { RetoTerminadoComponent } from "@pages/reto-terminado/reto-terminado.component";
import { GestionContactosComponent } from "@pages/gestion-contactos/gestion-contactos.component";
import { SolicitudesSFPotencialRechazoComponent } from "@pages/CVDP/solicitud-sf-potencial-rechazo/solicitud-sf-potencial-rechazo.component";
import { CargaDocumentosComponent } from "@pages/carga-documentos/carga-documentos.component";
import { DocumentacionRetoComponent } from "@pages/documentacion-reto/documentacion-reto.component";
import { RoutesComponent } from "@pages/dashboard/routes/routes.component";
import { VinculacionesConSocioComponent } from "@pages/vinculaciones-con-socio/vinculaciones-con-socio.component";

const routes: Routes = [
  {
    path: "validasesion",
    component: ValidaSesionComponent,
    canActivate: [MsalGuard],
  },
  { path: "", component: HomesfComponent },
  { path: "login", component: LoginComponent },
  { path: "preguntasfrecuentes", component: PreguntasfrecuentesComponent },
  { path: "aviso-privacidad", component: AvisoPrivacidadComponent },
  {
    //TODO: Quitar Modificado-Revisar en login static b2c
    path: "registro-docentes",
    redirectTo: "acad/registro",
  },
  {
    //TODO: Quitar Modificado-Revisar en login static b2c
    path: "registro-sf",
    redirectTo: "sf/registro-sf",
    /* component: RegistroSFComponent*/
  },
  // Final publico
  {
    path: "acad/registro",
    component: UsuariosAcademiaComponent,
  },
  {
    path: "sf/registro-sf",
    component: RegistroSFComponent,
  },
  {
    path: "",
    runGuardsAndResolvers: "always",
    component: MainLayoutBuscadorComponent,
    children: [
      // Documentacion de reto cvdp
      {
        path: "retos",
        redirectTo: "cvdp/retos-programados",
        /*component: MisRetosCvdpComponent */
      },

      // Documentacion de reto academico
      {
        //TODO: quitar Modificado
        path: "retos-activos",
        redirectTo: "acad/mis-retos",
      },
      {
        //TODO: Quitar Modificado
        path: "mi-perfil",
        component: RoutesComponent,
        /*redirectTo: "acad/mi-perfil",*/
      },
      {
        //TODO: Quitar Modificado
        path: "academia/retos-busquedo-socio",
        redirectTo: "acad/solicitud-sf",
      },
      {
        path: "academia/solicitud-vinculacion-retos-existente",
        redirectTo: "acad/solicitud-reto",
      },
      {
        path: "admin/gestion-socioformador",
        redirectTo: "registro-sf",
      },
      {
        path: "registro-sf",
        component: RoutesComponent,
      },
      {
        path: "vincula-socios-formadores",
        redirectTo: "acad/vincula-sf-algoritmo",
      },
      {
        path: "dashboard",
        component: RoutesComponent,
      },
      //Final Acad
      {
        path: "acad",
        children: [
          { path: "dashboard", component: DashboardComponent },
          { path: "mi-perfil", component: RegistroPerfilComponent },
          { path: "solicitud-sf", component: RetosBusquedaSFComponent },
          {
            path: "solicitud-reto",
            component: SolicitudesVinculacionRetosComponent,
          },
          { path: "registro-sf", component: GestionSocioformadorComponent },
          {
            path: "vincula-sf-algoritmo",
            component: VinculaUsuarios3Component,
          },
          { path: "formalizacion", component: PaginaRetosComponent },
          { path: "en-curso", component: PaginaRetosComponent },
          { path: "evaluacion", component: PaginaRetosComponent },
          { path: "concluido", component: PaginaRetosComponent },
          { path: "mis-retos", component: DocumentacionRetoComponent },
          { path: "banco-sf", component: AcademicoBancoSFComponent },
          { path: "banco-retos", component: BancoRetosComponent },
        ],
      },

      //TODO: quitar modificado
      {
        path: "admin/casos-de-exito",
        redirectTo: "cvdp/casos-exito",
        /*component: CasosDeExitoComponent */
      },
      //TODO: Quitar Modificado
      /*{ path: "cvdp/gestion-usuarios", component: GestionUsuarioComponent  },*/
      {
        path: "cvdp/solicitudes-socios-potenciales",
        redirectTo: "cvdp/solicitud-socio-potencial",
        /*component: SolicitudesComponent,*/
      },
      {
        path: "cvdp/academico-busca-socio",
        redirectTo: "cvdp/solicitud-academia-sf",
        /*component: AcademicoBuscaSFComponent,*/
      },
      {
        path: "cvdp/solicitudes-socios-retos",
        redirectTo: "cvdp/solicitud-sf-reto",
        /*component: SFSolicitandoRetosComponent,*/
      },
      {
        path: "cvdp/solicitudes-no-encontraron-reto",
        redirectTo: "cvdp/solicitud-sf-problematicas",
        /*component: SolicitudesSinRetoComponent,*/
      },
      {
        path: "admin/reportes",
        redirectTo: "cvdp/reportes",
        /*component: ReportesComponent,*/
      },
      {
        path: "admin/reporte/detalle",
        redirectTo: "cvdp/reportes/detalle",
        /*component: DetalleComponent,*/
      },
      {
        path: "registrar-caso-exito/:type",
        redirectTo: "cvdp/registro-caso-exito/:type",
        /*component: RegistroCasosExitoComponent,*/
      },
      // Final CVDP
      {
        path: "cvdp",
        children: [
          { path: "dashboard", component: DashboardComponent },
          { path: "mi-perfil", component: RegistroPerfilComponent },
          {
            path: "solicitud-socio-potencial",
            component: SolicitudesComponent,
          },
          {
            path: "solicitud-academia-sf",
            component: AcademicoBuscaSFComponent,
          },
          { path: "solicitud-sf-reto", component: SFSolicitandoRetosComponent },
          {
            path: "solicitud-sf-problematicas",
            component: SolicitudesSinRetoComponent,
          },
          { path: "registro-sf", component: GestionSocioformadorComponent },
          { path: "reportes", component: ReportesComponent },
          { path: "reportes/detalle", component: DetalleComponent },
          { path: "formalizacion", component: PaginaRetosComponent },
          { path: "en-curso", component: PaginaRetosComponent },
          { path: "evaluacion", component: PaginaRetosComponent },
          { path: "concluido", component: PaginaRetosComponent },
          {
            path: "registro-caso-exito/:type",
            component: RegistroCasosExitoComponent,
          },
          { path: "banco-sf", component: AcademicoBancoSFComponent },
          { path: "banco-retos", component: BancoRetosComponent },
          { path: "casos-exito", component: CasosDeExitoComponent },
          { path: "retos-programados", component: MisRetosCvdpComponent },
          { path: "gestion-usuarios", component: GestionUsuarioComponent },
        ],
      },
      //Final CVDP
      {
        path: "cvdp",
        children: [],
      },

      // SOCIO
      //TODO: Quitar Banco SF
      {
        path: "banco-socio-formador",
        redirectTo: "banco-sf",
        /*component: AcademicoBancoSFComponent */
      },

      {
        path: "banco-sf",
        component: RoutesComponent,
      },

      {
        //TODO: revisar, este no se puede quitar, por el share
        path: "banco-socio-formador/:rfc",
        redirectTo: "sf/banco-sf/:rfc",
        /*component: AcademicoBancoSFComponent,*/
      },

      //Final Socio
      {
        path: "sf",
        children: [
          { path: "dashboard", component: DashboardComponent },
          { path: "mi-perfil", component: RegistroPerfilComponent },
          { path: "solicitud-reto", component: VinculacionesComponent },
          { path: "mis-retos", component: MisRetosComponent },
          {
            path: "solicitud-academia",
            component: VinculacionesConSocioComponent,
          },
          { path: "vinculacion-reto", component: VinculaRetoComponent },
          { path: "problematicas", component: ProblematicasComponent },
          {
            path: "recursos-vinculacion",
            component: RecursosVinculacionSfComponent,
          },
          { path: "formalizacion", component: PaginaRetosComponent },
          { path: "en-curso", component: PaginaRetosComponent },
          { path: "evaluacion", component: PaginaRetosComponent },
          { path: "concluido", component: PaginaRetosComponent },
          { path: "banco-sf", component: AcademicoBancoSFComponent },
          { path: "banco-sf/:rfc", component: AcademicoBancoSFComponent },
          { path: "banco-retos", component: BancoRetosComponent },
          {
            path: "banco-retos/:idRetoMaestro",
            component: BancoRetosComponent,
          },
        ],
      },

      // Banco Retos
      {
        path: "banco-retos",
        component: RoutesComponent,
        /* component: BancoRetosComponent */
      },
      { path: "banco-retos/:idRetoMaestro", component: BancoRetosComponent },

      { path: "reto-registrado", component: RetoRegistradoComponent },
      {
        path: "contactos-secundarios",
        component: ContactosSecundariosComponent,
      },
      {
        path: "admin/recursos-de-vinculacion",
        component: RecursosDeVinculacionComponent,
      },

      // banco SF  **********************************
      { path: "socio-formador", component: SocioFormadorComponent },

      // SF components
      {
        path: "vinculacion-reto-search",
        component: VinculaRetosSearchComponent,
      },
      {
        path: "vinculacion-reto-busqueda",
        component: VinculaRetosBusquedaComponent,
      },
      { path: "reto-resumen", component: RetoResumenComponent },
      { path: "socio-search", component: SocioSearchComponent },
      {
        path: "registro-reto",
        component: RegistraRetoComponent,
      },
      {
        path: "registro-reto-semilla",
        component: RegistraRetoSemillaComponent,
      },
      { path: "reto-confirmacion", component: RetoConfirmacionComponent },
      {
        path: "problematica/:idProblematica",
        component: RegistroProblematicaComponent,
      },
      {
        path: "problematica-registrada",
        component: ProblematicaRegistradaComponent,
      },
      { path: "solicitud-academico", component: SolicitudAcademicoComponent },
      {
        path: "solicitud/:type/:idReto/:idSolicitud",
        component: SolicitudComponent,
      },
      {
        path:
          "solicitud-problematica/:idProblematica/:idSocioFromador/:idSolicitud",
        component: SolicitudProblematicaComponent,
      },
      {
        path: "solicitud/:type/:idAcademico/:idReto/:idSolicitud",
        component: SolicitudComponent,
      },
      // TODO: borrar
      //{ path: "registro/:idUsusario", component: RegistroPerfilComponent },
      {
       path: "edit-user/:userType/:idUsuario",
       component: RegistroPerfilComponent,
      },
      {
       path: "edit-user/:userType/:idSocio/:idUsuario",
       component: RegistroPerfilComponent,
      },
      {
        path: "new-user/:userType",
        component: RegistroPerfilComponent,
      },
      { path: "mi-perfil/:idUsuario", component: RegistroPerfilComponent },

      { path: "mis-contactos", component: PerfilContactComponent },
      { path: "notificaciones", component: NotificacionesComponent },
      { path: "socio/pagina-retos", component: RetosComponent },
      //TODO: quitar
      {
        path: "socio/vinculaciones",
        redirectTo: "sf/solicitud-reto",
        /* component: VinculacionesComponent*/
      },
      {
        path: "mis-retos",
        redirectTo: "sf/mis-retos",
        /*component: MisRetosComponent */
      },
      {
        path: "socio/solicitud-academicos",
        redirectTo: "sf/solicitud-academia",
        /*component: VinculacionesConSocioComponent*/
      },
      {
        path: "vinculacion-reto",
        redirectTo: "sf/vinculacion-reto",
        /*component: VinculaRetoComponent */
      },
      {
        path: "socio/historial-problematicas",
        redirectTo: "sf/problematicas",
        /*component: ProblematicasComponent*/
      },
      {
        path: "recursos-vinculacion-sf",
        redirectTo: "sf/recursos-vinculacion",
        /*component: RecursosVinculacionSfComponent,*/
      },
      {
        path: "socio/pagina-retos-resumen",
        component: PaginaRetosComponent,
      },
      {
        path: "cvdp/solicitud-sf-potencial",
        component: SolicitudesSFPotencialRechazoComponent,
      },
      { path: "matches-socios", component: MatchesSociosComponent },
      {
        path: "confirmacion-vinculacion",
        component: ConfirmacionVinculacionComponent,
      },
      {
        path:
          "solicitud-vinculacion-a-reto/:idSocioFormador/:idReto/:idSolicitud",
        component: SolicitudVinculacionARetoComponent,
      },
      {
        path: "academia-no-encontre-socio-formador",
        component: SfNoEncontreSfComponent,
      },
      {
        path: "cvdp/convenios",
        component: ConveniosComponent,
      },
      {
        path: "programacion-reto",
        component: VinculaUsuarios2Component,
      },
      {
        path: "marcar-reto-terminado",
        component: RetoTerminadoComponent,
      },
      {
        path: "gestion-reto",
        component: RegistraRetoSemilla2Component,
      },
      {
        path: "admin/notificaciones",
        component: GestionNotificacionesComponent,
      },
      {
        path: "registrar-caso-exito/:type/:idRecurso",
        component: RegistroCasosExitoComponent,
      },
      {
        path: "admin/gestion-contactos",
        component: GestionContactosComponent,
      },
      {
        path: "admin/carga-de-documentos",
        component: CargaDocumentosComponent,
      },

      {
        path: "registrar-recursos-de-vinculacion/:type/:idRecurso",
        component: RegistroRecursosVinculacionComponent,
      },
      {
        path: "registrar-recursos-de-vinculacion/:type",
        component: RegistroRecursosVinculacionComponent,
      },
    ],
    canActivate: [MsalGuard],
  },
  { path: "**", redirectTo: "", pathMatch: "full" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "top",
      enableTracing: false,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
