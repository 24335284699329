<app-header-buscador [showSearchBar]="false"></app-header-buscador>

<div class="sec_ad">
  <div>
    <img class="img_hdr" alt="Vinculación" src="assets/img/img_vinculacion_fullsize.jpg" />
  </div>
  <app-title-show-more [showMoreObject]="titleRegistroReto"></app-title-show-more>
  <div class="div-text-btn mb-3">
    <div class="question-div">
      Une a los Socios Formadores a nuevos desafíos para mostrar el potencial de
      las Unidades Formativas.
    </div>
  </div>

  <form [formGroup]="retoForm" (submit)="saveReto()" autocomplete="off">

    <div class="row questions-container open-text">

      <div class="col-md-6">
        <div class="row">

          <div class="col-md-12 mb-5">
            <span class="question-title-text">Acerca del Reto</span>
          </div>

          <div class="col-md-12">
            <span class="question-text">Selecciona la escuela <span class="requerido">*</span></span>

            <div class="requerido" *ngIf="retoForm.get('idEscuela')?.errors?.['required']">
              Este campo es requerido
            </div>

            <div class="select-dropdown">
              <ng-select [items]="escuelaData$ | async" bindLabel="escuela" bindValue="idEscuela"
                placeholder="Selecciona una opción" (change)="updateProgramasAcademicos1($event)"
                formControlName="idEscuela" ></ng-select>
            </div>

          </div>

          <div class="col-md-12">
            <span class="question-text">Selecciona la Unidad de Formación <span class="requerido">*</span> </span>

            <div class="requerido" *ngIf="retoForm.get('idUnidadFormativa')?.errors?.['required']">
              Este campo es requerido
            </div>

            <div class="select-dropdown">
              <ng-select [items]="unidadFormativaData" bindLabel="nombreUnidadFormativa"  bindValue="idUnidadFormativa"
                placeholder="Selecciona una opción" (change)="muestraProgamasAcademico_nvo($event)"
                formControlName="idUnidadFormativa" ></ng-select>
            </div>

          </div>

          <div class="col-md-12 mt-3" *ngIf="mostrarPA">
            <span class="question-text">Programas académicos: </span>
            <ul class="listProgramasAcademicos mt-2" *ngFor="let unidad of listaProgramasAcademicosxUnidadFormativa">
              <li> {{unidad.clave }} {{ unidad.programaAcademico }}</li>
            </ul>
          </div>




          <div class="col-md-12 mb-1">
            <br><br>
            <span class="question-text">Aplica para programas académicos de otras escuelas <input type="checkbox"
                (click)="mostrarProgramasAcademicosExtra()"> </span>
            <br><br>

            <div *ngIf="mostrarPOE">
              <span class="question-text">Selecciona la escuela </span>

              <div class="requerido" *ngIf="retoForm.get('idEscuela_POE')?.errors?.['required']">
                Este campo es requerido
              </div>

              <div class="select-dropdown">
                <ng-select [items]="escuelaData$ | async" bindLabel="escuela" bindValue="idEscuela"
                  placeholder="Selecciona una opción" formControlName="idEscuelaPOE"
                  (change)="updateProgramasAcademicos($event)" #ngs_idEscuelaPOE></ng-select>
              </div>
            </div>
          </div>


          <div class="col-md-12 mb-5" *ngIf="mostrarPOE">
            <span class="question-text">Selecciona los Programas académicos </span>

            <div class="requerido" *ngIf="retoForm.get('programasOtrasEscuelas')?.errors?.['required']">
              Este campo es requerido
            </div>


            <div class="select-dropdown">

              <ng-select [multiple]="true" [items]="programaAcademicoData" bindLabel="nombreProgramaAcademico"
                 placeholder="Selecciona una opción"
                formControlName="programasOtrasEscuelas" #ngs_programaAcademicoPOE>
              </ng-select>

            </div>

          </div>

          <div class="row">
            <div class="col-md-6">
              <span class="question-text">Duración del reto: <span class="requerido">*</span> </span>

              <div class="requerido" *ngIf="retoForm.get('idSemanas')?.errors?.['required']">
                Este campo es requerido
              </div>

              <div class="select-dropdown">

                <ng-select [items]="semanasData$ | async" bindLabel="semanas" bindValue="idSemanas"
                  placeholder="Selecciona una opción" formControlName="idSemanas" ></ng-select>

              </div>

            </div>


            <div class="col-md-6">
              <span class="question-text">Idiomas en que se imparte: <span class="requerido">*</span> </span>

              <div class="requerido" *ngIf="retoForm.get('idIdioma')?.errors?.['required']">
                Este campo es requerido
              </div>

              <div class="select-dropdown">
                <ng-select [multiple]="true" [items]="idiomasData$ | async" bindLabel="idioma" bindValue="idIdioma"
                  placeholder="Selecciona idiomas" formControlName="idiomas"></ng-select>
              </div>

            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <span class="question-text">Periodo Semestral: <span class="requerido">*</span> </span>

              <div class="requerido" *ngIf="retoForm.get('periodosSemestrales')?.errors?.['required']">
                Este campo es requerido
              </div>
|
              <div class="select-dropdown">

                <ng-select [multiple]="true" [items]="periodoSemestralData$ | async" bindLabel="periodoSemestral"
                  bindValue="idPeriodoSemestral" placeholder="Selecciona una opción"
                  formControlName="periodosSemestrales" ></ng-select>

              </div>

            </div>


            <div class="col-md-6">
              <span class="question-text">Periodo semanal: <span class="requerido">*</span> </span>

              <div class="requerido" *ngIf="retoForm.get('periodosSemanales')?.errors?.['required']">
                Este campo es requerido
              </div>

              <div class="select-dropdown">
                <ng-select [multiple]="true" [items]="periodoSemanalData$ | async" bindLabel="periodoSemanal"
                  bindValue="idPeriodoSemanal" placeholder="Selecciona una opción" formControlName="periodosSemanales"
                  #ngs_idPeriodoSemanal></ng-select>

              </div>

            </div>

            <div class="col-md-12 mb-3">
              <br><br>
              <span class="question-text">Semestres en el que se imparte el reto: <span class="requerido">*</span>
              </span>

              <div class="requerido" *ngIf="retoForm.get('semestres')?.errors?.['required']">
                Este campo es requerido
              </div>

              <div class="row">
                <div class="col icons-checkbox pt-2" *ngFor="let semestre of semestreData; let i  = index"  >
                  <p>{{ semestre }}</p>

                  <input type="checkbox" class="btn-check" id="semestre_{{ semestre }}" formArrayName="semestres"
                    name="semestres"
                    (change)="onCheckboxChange($event, 'semestres')"
                    [checked]="verifyChecked(semestre,'semestres')"
                    [value]="semestre" autocomplete="off"
                    />
                  <label class="btn btn-outline-primary" for="semestre_{{ semestre }}"></label>

                </div>
              </div>

            </div>

            <div class="col-md-12 mt-4 mb-4">
              <span class="question-text">Alcance: <span class="requerido">*</span> </span>
            </div>

            <div class="requerido" *ngIf="retoForm.get('alcances')?.errors?.['required']">
              Este campo es requerido
            </div>

            <div class="col icons-checkbox" *ngFor="let alcance of alcanceData$ | async">
              <div class="icon-div">
                <img src="assets/svg/icon_{{ alcance.alcance | lowercase }}.svg" alt="icon-local" />
              </div>
              <p>{{ alcance.alcance }}</p>
              <input type="checkbox" class="btn-check" id="a_{{ alcance.alcance }}" name="a_idAlcance"
                [value]="alcance.idAlcance"
                [checked]="verifyChecked(alcance.idAlcance, 'alcances')"
                (change)="onCheckboxChange($event,'alcances')" />
              <label class="btn btn-outline-primary" for="a_{{ alcance.alcance }}"></label>
            </div>


            <div class="row mt-3">

              <div class="col-md-12">
                <span class="question-text">Descripción del reto: <span class="requerido">*</span> </span>

                <div class="requerido" *ngIf="retoForm.get('descripcion')?.errors?.['required']">
                  Este campo es requerido
                </div>

                <textarea class="forma_area" [formControl]="$any(retoForm.controls['descripcion'])"
                  [placeholder]="'Una frase que describa el objetivo de tu reto'"></textarea>
              </div>

            </div>
            <div class="row">

              <div class="col-md-12">
                <span class="question-text">Entregable: <span class="requerido">*</span> </span>

                <div class="requerido" *ngIf="retoForm.get('entregable')?.errors?.['required']">
                  Este campo es requerido
                </div>

                <textarea class="forma_area" id="entregable" placeholder="Describe los resultados a lograr en el reto"
                  formControlName="entregable"></textarea>
              </div>

            </div>

            <div class="row">

              <div class="col-md-12">
                <span class="question-text">Objetivo de Aprendizaje para el alumno: <span class="requerido">*</span>
                </span>

                <div class="requerido" *ngIf="retoForm.get('objetivo')?.errors?.['required']">
                  Este campo es requerido
                </div>



                <textarea class="forma_area" id="objetivo" formControlName="objetivo"
                  [placeholder]="'Una frase que describa el objetivo de tu reto'"></textarea>
              </div>

            </div>

            <!-- limite -->


          </div>


        </div>
      </div>


      <div class="col-md-6">

        <div class="col-md-12 mb-5">
          <span class="question-title-text">Características del Socio Formador:</span>
        </div>

        <div class="row mb-5">

          <div class="col-md-12">
            <span class="question-text">Sector: <span class="requerido">*</span> </span>
          </div>

          <div class="requerido" *ngIf="retoForm.get('sectores')?.errors?.['required']">
            Este campo es requerido
          </div>

          <div class="col icons-checkbox" *ngFor="let sector of sectoresData$ | async">
            <div class="icon-div mb-5">
              <img class="sector-image" src="assets/svg/ico_{{ cleanText(sector.sector) }}_ngo.svg"
                alt="icon-{{ sector.sector }}" />
            </div>
            <input type="checkbox" class="btn-check" id="{{ sector.sector }}" name="sectores"
              [value]="sector.idSector" autocomplete="off"
              [checked]="verifyChecked(sector.idSector,'sectores')"
              (change)="onCheckboxChange($event,'sectores')" />
            <label class="btn btn-outline-primary" for="{{ sector.sector }}"></label>
          </div>

        </div>

        <div class="row mb-3">
          <div class="col-md-12">
            <span class="question-text">Industria: <span class="requerido">*</span> </span>

            <div class="requerido" *ngIf="retoForm.get('idIndustria')?.errors?.['required']">
              Este campo es requerido
            </div>

            <div class="select-dropdown">
              <ng-select [multiple]="true" [items]="industriasData$ | async" bindLabel="industria" bindValue="idIndustria"
                placeholder="Selecciona una opción" formControlName="industrias" #ngs_industrias></ng-select>

            </div>

          </div>
        </div>
        <div class="row ">
          <div class="col-md-12">
            <span class="question-text">Tamaño de empresa: <span class="requerido">*</span> </span>

            <div class="requerido" *ngIf="retoForm.get('idTamano')?.errors?.['required']">
              Este campo es requerido
            </div>

            <div class="select-dropdown">

              <ng-select [multiple]="true" [items]="tamanioEmpresasData$ | async" bindLabel="tamanoEmpresa"
                bindValue="idTamanoEmpresa" placeholder="Selecciona una opción" formControlName="tamanos"
                #ngs_tamanos></ng-select>

            </div>

          </div>
        </div>

        <div class="row">
          <div class="col-md-12 mt-4 mb-4">
            <span class="question-text">Presencia: <span class="requerido">*</span> </span>
          </div>

          <div class="requerido" *ngIf="retoForm.get('presencias')?.errors?.['required']">
            Este campo es requerido
          </div>

          <div class="col icons-checkbox" *ngFor="let presencia of presenciaData$ | async">
            <div class="icon-div">
              <img src="assets/svg/icon_{{ presencia.presencia | lowercase }}.svg" alt="icon-local" />
            </div>
            <p>{{ presencia.presencia }}</p>
            <input type="checkbox" class="btn-check" id="{{ presencia.presencia }}" name="idPresencia"
              [value]="presencia.idPresencia"
              [checked]="verifyChecked(presencia.idPresencia,'presencias')"
              (change)="onCheckboxChange($event,'presencias')" />
            <label class="btn btn-outline-primary" for="{{ presencia.presencia }}"></label>
          </div>
        </div>

        <div class="row mt-4">

          <div class="col-md-12">
            <span class="question-text">Descripción: <span class="requerido">*</span>
            </span>

            <div class="requerido" *ngIf="retoForm.get('descripcionSocioFormador')?.errors?.['required']">
              Este campo es requerido
            </div>


            <textarea class="forma_area" id="descripcionSocioFormador"
            formControlName="descripcionSocioFormador"
              [placeholder]="'Describe  el tipo de Socio Formador sugerido para este reto'"></textarea>
          </div>

        </div>

        <div class="row mt-4">

          <div class="col-md-12 ">
            <span class="question-text">Es un reto con Sentido Humano: </span>
            &nbsp;
            <ng-select formControlName="sentidoHumano" (change)="mostrarHorasServicioSocial($event)">
              <ng-option [value]="false" >No </ng-option>
              <ng-option [value]="true"  >Si </ng-option>
            </ng-select>
          </div>

        </div>

        <div class="row mt-4" *ngIf="mostrarHorasSS">
            <div class="col-ms-12">
              <span class="question-text">Horas de Servicio Social que acredita: </span>
              <input type="number" min="0" formControlName="horasServicioSocial" class="form-control">
              <div class="requerido" *ngIf="retoForm.get('horasServicioSocial')?.errors?.['pattern']">
                Este campo sólo admite números positivos
              </div>
            </div>
        </div>

        <div class="row mt-4">
          <div class="col-6" *ngIf="isUpdate">
            <app-button ngForm buttonType="blue" (callbackAction)="cancelar()" extraClass="btn-full"
              [isDisabled]="buttonSave.isLoading">
              Cancelar</app-button>
          </div>
          <div class="col-6">
            <button class="yell_button1" type="submit">
              {{ buttonSave.title }}
            </button>
            <div class="d-none">
            <app-button ngForm buttonType="yell" extraClass="btn-full yell_button1" [isDisabled]="buttonSave.isLoading">
              {{ buttonSave.title }}
              </app-button>
            </div>
          </div>
        </div>

      </div>
    </div>
  </form>
</div>
