import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import Utils from '@shared/helpers/utils';

@Component({
  selector: 'app-card-accordion',
  templateUrl: './card-accordion.component.html',
  styleUrls: ['./card-accordion.component.css'],
})
export class CardAccordionComponent implements OnInit {
  @Input() retoObj: any;
  @Input() index: any;
  @Output() onJoinReto: EventEmitter<any> = new EventEmitter<any>();

  retoCampus : string="";
  retoProgramaAcademico: string="";
  retoDescripcion: string="";
  retoNombre: string ="";
  idiomas: string = "";
  semestres: string= "";



  ngOnInit(): void {
    
    this.retoNombre = Utils.cutLongTextByXChars(this.retoObj.reto,150);
    this.retoCampus = Utils.cutLongTextByXChars(this.retoObj.campus??'', 45);
    this.retoProgramaAcademico = Utils.cutLongTextByXChars(this.retoObj.programaAcademico??'',45);
    this.retoDescripcion = Utils.cutLongTextByXChars(this.retoObj.descripcion??'', 100);
    this.idiomas = this.retoObj.idiomas.map(x => x.idioma).join(', ');
    this.semestres = this.retoObj.semestres.map(x => x.semestre).join(', ');
    
    
   }

  callbackJoinReto(retoObj: any) {
    this.onJoinReto.emit(retoObj);
  }
}
