import { Component, Input, OnInit } from '@angular/core';
import { Paginador } from '@shared/Interface/IPaginador.interface';
import { RetosSocioFormadorResponse } from '@shared/Interface/IRetosSocioFormadorResponse';
import { PaginadorLibsComponent } from '@shared/libs/paginador.component';
import { DashboardService } from '@shared/service/Dashboard/dashboard.service';
import { UsuariosService } from '@shared/service/Usuarios/usuarios.service';
import { environment } from 'environments/environment';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-retos-activos',
  templateUrl: './retos-activos.component.html',
  styleUrls: ['./retos-activos.component.css']
})
export class MisRetosActivosComponent implements OnInit {

  lang: string = 'es';
  themeColor: string = "#0c2b3e";
  retosSFList: RetosSocioFormadorResponse[] = [];
  retosActualesSFList: RetosSocioFormadorResponse[] = [];
  retosActualesMovilSFList: RetosSocioFormadorResponse[] = [];

  idSocioFormador: number;
  @Input() estatusType:string;
  registrosPagina: number = environment.RegistrosPagina;
  paginaActual: number = 1;
  paginaActualMasResultados: number = 1;
  totalPaginas:number = 0;
  pages: number[] = [];

  constructor(private dashboardService: DashboardService,
    private paginadorLibs: PaginadorLibsComponent,
    private usuarioService: UsuariosService,
    ) { }

  async ngOnInit(): Promise<void> {
    this.idSocioFormador = this.usuarioService.getCurrentUserData().idSocioFormador;
    this.lang = localStorage.getItem('lang') ?? 'es';
    await this.getListaRetosActivos();
    this.getObtenerPaginacion();
    this.obtenerRegistrosActuales(1);
  }

  changeLang(lang: string) {
    this.lang = lang;
    localStorage.setItem('lang', lang);
    window.location.reload();
  }

  async getListaRetosActivos(){
    try {
      let type= this.estatusType || 'activo';
      let resultado = <any>await lastValueFrom(this.dashboardService.getRetosSocio(this.idSocioFormador, type));
      this.retosSFList = resultado;
    } catch (error) {
      console.error(`Error al obtener los retos de Socios Formadores estatus ${this.estatusType}`);
    }
  }


  obtenerRegistrosActuales(numPagina:number) {
    this.retosActualesSFList = this.paginadorLibs.obtenerRegistrosActuales(numPagina, this.registrosPagina, this.retosSFList);
    this.retosActualesMovilSFList = this.retosActualesSFList;
    this.paginaActual = numPagina;
    this.paginaActualMasResultados = numPagina;

  }

  obtenerMasResultados(){
    this.paginaActualMasResultados += 1;
    this.retosActualesMovilSFList = this.paginadorLibs.obtenerMasResultados(this.paginaActualMasResultados, this.registrosPagina, this.retosSFList);

  }

  getObtenerPaginacion(){
    let paginador: Paginador = this.paginadorLibs.getPaginacion(this.retosSFList.length, this.registrosPagina);
    this.totalPaginas = paginador.totalPaginas;
    this.pages = paginador.paginas;

  }

  verDetalleReto(idReto){
    window.location.href=`/socio/pagina-retos-resumen?reto=${idReto}`;
  }

}
