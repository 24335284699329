<div class="d-block d-sm-none">
  <div class="m-footer row ">
    <div class="col1 col-sm-12 ">
      
      <img alt="Socio Formador Tec" title="menor sm" class="logo" src="/assets/img/Sello_4.png">
      
      <div class="row m-f-texto mt-3">
        <span class="col-12">El marketplace de Socios Formadores es un espacio de conexión entre la academia y la industria a través de los retos del modelo Tec21.
          <span *ngIf="!isLogged">¡Únete ya!</span>
          <span *ngIf="isLogged">¡Explóralo!</span>
        </span>
      </div>
      
      <div class="mt-3 mb-2">
        <img alt="logo_tec" src="assets/img/logo_tec.svg" width="100%">
      </div>

      <div class="m-f-texto">
        Desarrollado por la Iniciativa de Plataformas en colaboración con el Centro de Vinculación y Desarrollo Profesional <br/><a href="plataformas@servicios.tec.mx" >plataformas@servicios.tec.mx</a>
      </div>

    </div>
    <div class="col2 col-sm-12 mt-5  mb-4">
      
      <div class="m-f-texto">
        <div>
          ¿Tienes dudas?<br/>
          Chat: <a href="http://tec.mx/tecserviceschat">http://tec.mx/tecserviceschat</a><br/>
        Correo: <a href="mailto:tecservices@servicios.tec.mx"><u>tecservices@servicios.tec.mx</u></a><br/>
          <a class="f-link" rel="noopener noreferrer"
            href="assets/img/preguntasfrecuentes_sociosformadores.pdf"><u>Preguntas Frecuentes</u></a>
        </div>
      </div>
    </div>

    <div class="row d-flex">
      
      <div class="col-12 mt-4">
        <img alt="Plataformas" src="assets/img/plataformas-cac_1.svg" width="200px">
      </div>

      <div class="col-12 mt-4">
        <img alt="" src="/assets/img/tecserviceslogowhite.svg" width="!70px">
      </div>
      
    </div>
  </div>

</div>

<div class="d-none d-sm-block">
  <div class="footer row ">
    <img alt="" *ngIf="ShowImage" class="sf-img-patron-4 d-none d-lg-block" src="/assets/img/Figura_4.png">

    <div class="col1 col-sm-12 col-md-4">
      <img alt="" title="mayor sm" class="logo p65" src="/assets/img/Sello_4.png">
      <img alt="" class="p65" src="assets/img/MPSTec.svg">
      <div class="f-texto row ">
        <div class="col-12">El marketplace de Socios Formadores es un espacio de conexión entre la academia y la industria a través de los retos del modelo Tec21.</div>
        <div class="col-12 mb-5 mt-4" *ngIf="!isLogged">¡Únete ya!</div>
        <div class="col-12 mb-5 mt-4" *ngIf="isLogged">¡Explóralo!</div>
      </div>

    </div>
    <div class="col2 col-sm-12 col-md-4">
      <div class="f-titulo">
        ¿Tienes dudas?
      </div>
      <div class="f-texto">
        <div>
          Chat: http://tec.mx/tecserviceschat
        </div>
        <div>
          Correo: <a href="mailto:tecservices@servicios.tec.mx">tecservices@servicios.tec.mx</a>
        </div>
        <div>
          <a class="f-link" target="_blank" rel="noopener noreferrer"
            href="assets/img/preguntasfrecuentes_sociosformadores.pdf">Preguntas Frecuentes</a>
        </div>
      </div>
    </div>

    <div class="col3 col-sm-12 col-md-4">
      <div class="f-titulo">
        Plataformas
      </div>

      <div class="f-texto">
        Desarrollado por la Iniciativa de Plataformas en colaboración con el Centro de Vinculación y Desarrollo
        Profesional <br />
        plataformas@servicios.tec.mx
      </div>

    </div>

    <div class="row d-flex">
      <div class="col-4">
        <img alt="logo_tec mt-2" src="assets/img/logo_tec.svg">
      </div>
      <div class="col-4 col_footer_sf">
        <img alt="" src="/assets/img/tecserviceslogowhite.svg">
      </div>
      <div class="col-4 col_footer_sf">
        <img alt="Plataformas" src="assets/img/plataformas-cac_1.svg">
      </div>
    </div>
  </div>
  
</div>

<div class="row py-4 footer_legal">
  <div class="justify-content-center d-flex">
    <a class="link-light text-decoration-none" target="_blank" rel="noopener noreferrer" href="https://mpstecstorage.blob.core.windows.net/mvpretos-app-assets/terminosycondiiones.pdf">TÉRMINOS Y CONDICIONES </a> 
    <span class="text-white">&nbsp;&nbsp;|&nbsp;&nbsp;</span> 
    <a class="link-light text-decoration-none" target="_blank" rel="noopener noreferrer" href="https://tec.mx/es/aviso-de-privacidad-plataforma-mps-tec">AVISO DE PRIVACIDAD</a>  
  </div>
</div>