<app-header-buscador [showSearchBar]="false"></app-header-buscador>

<div class="sec_ad">


  <app-title-show-more [showMoreObject]="{ title: 'Recursos de vinculación'}"></app-title-show-more>
  <div *ngIf="data.length==0"> No hay Recursos de Vinculación </div>
  <div *ngIf="data.length>0"id="herr_vinc" class="">
    <div class="row">

      <div class="item col-3" *ngFor="let recurso of data">
        <a class="linkRecursosVinculacion" href="{{recurso.linkVinculacion}}" target="_blank" rel="noopener noreferrer">
          <div class="card-body">
            <img alt="Recurso Vinculación" src="{{recurso.urlImagenVinculacion}}" />
            <h4 class="card-title">{{recurso.tituloVinculacion}}</h4>
            <p class="card-text">
              {{recurso.descripcionVinculacion}}
            </p>
          </div>
        </a>
      </div>
    </div>
  </div>

  <div *ngIf="data2.length>0">
    <app-title-show-more [showMoreObject]="{ title: 'Herramientas de Vinculación'}"></app-title-show-more>

    <div id="herr_vinc" class="">
      <div class="row">

        <div class="item col-3" *ngFor="let recurso of data2">
          <a class="linkRecursosVinculacion" href="{{recurso.linkVinculacion}}" target="_blank" rel="noopener noreferrer">
            <div class="card-body">
              <img alt="Recurso Vinculación" src="{{recurso.urlImagenVinculacion}}" />
              <h4 class="card-title">{{recurso.tituloVinculacion}}</h4>
              <p class="card-text">
                {{recurso.descripcionVinculacion}}
              </p>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>

  <div id="herr_vinc" class="d-none">
    <div class="row">
      <div class="item col-3">
        <a class="linkRecursosVinculacion" href="https://tooltec.mx/" target="_blank" alt="Bolsa de Trabajo" rel="noopener noreferrer">
          <div class="card-body">
            <img alt="Tooltec" src="assets/svg/logo_herramienta.svg" />
            <h4 class="card-title">Tooltec</h4>
            <p class="card-text">
              Plataforma que construye comunidad a través de herramientas de innovación.
            </p>
          </div>
        </a>
      </div>
    </div>
  </div>


</div>
