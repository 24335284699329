import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-date-type',
  templateUrl: './date-type.component.html',
  styleUrls: ['./date-type.component.css'],
})
export class DateTypeComponent  {
  @Input() title: string = '';
  @Input() icon: boolean = false;
  
}
