import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import { NgbModalConfig, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
  COLS_PROBLEMATICAS,
  COLS_PROBLEMATICAS_TODOS,
} from "@pages/dashboard/cvdp-dashboard/cvdp-dashboard.columns";
import {
  ICampus,
  IEscuela,
  IPeriodo,
} from "@shared/Interface/ICatalogos.interface";
import { CatalogosService } from "@shared/service/Catalogos/catalogos.service";
import { DashboardService } from "@shared/service/Dashboard/dashboard.service";
import { ProblematicasService } from "@shared/service/Problematicas/problematicas.service";
import { SolicitudesService } from "@shared/service/Solicitudes/solicitudes.service";
import { UsuariosService } from "@shared/service/Usuarios/usuarios.service";
import { FeatureFlagService } from "app/featureModule/feature-flag.service";
import { tap, lastValueFrom } from "rxjs";

@Component({
  selector: "app-solicitudes-sin-reto",
  templateUrl: "./solicitudes-sin-reto.component.html",
  styleUrls: ["./solicitudes-sin-reto.component.css"],
  providers: [NgbModalConfig, NgbModal],
})
export class SolicitudesSinRetoComponent implements OnInit {
  F2_5_HU58: boolean;
  F2_4_HU58: boolean;

  sidebarExpanded = false;
  lang: string = "es";

  @ViewChild("comentarios") comentarios: ElementRef;

  ngOnInit(): void {
    this.F2_5_HU58 = this.featureFlagService.isFeatureEnabled('F2_5_HU58');
    this.F2_4_HU58 = this.featureFlagService.isFeatureEnabled('F2_4_HU58');

    this.lang = localStorage.getItem("lang") ?? "es";

    this.loadCatalogos();
    if(this.F2_5_HU58){
      this.loadData();
    }

    if(this.F2_4_HU58){
      this.loadData2_4();
    }
  }

  changeLang(lang: string) {
    this.lang = lang;
    localStorage.setItem("lang", lang);
    window.location.reload();
  }

  constructor(
    private dashboardService: DashboardService,
    private changeDetector: ChangeDetectorRef,
    private usuarioService: UsuariosService,
    private catalogosService: CatalogosService,
    private solicitudesService: SolicitudesService,
    private config: NgbModalConfig,
    private modalService: NgbModal,
    private featureFlagService: FeatureFlagService,
    private problematicasService: ProblematicasService
  ) {}
  backgroundTable = "#F6F6F6";
  //* Problematicas */
  sinRetoColumns = COLS_PROBLEMATICAS;
  sinRetosTodosColumns = COLS_PROBLEMATICAS_TODOS;

  solicitudesSinRetosData: any = [];
  solicitudesAprobadasSinRetosData: any = [];
  solicitudesRechazadasSinRetosData: any = [];

  solicitudesSinRetosDataPage: any = [];
  solicitudesAprobadasSinRetosDataPage: any = [];
  solicitudesRechazadasSinRetosDataPage: any = [];

  campusData: ICampus[] = [];
  escuelaData: IEscuela[] = [];
  periodoData: IPeriodo[] = [];

  filter: any = {
    query: "",
    campus: 0,
    escuela: 0,
    periodo: 0,
  };

  pagina: number = 0;
  hits: number = 0;

  loadCatalogos() {
    this.catalogosService
      .getCampus()
      .pipe(tap((resultado: ICampus[]) => (this.campusData = resultado)))
      .subscribe();

    this.catalogosService
      .getEscuelas()
      .pipe(tap((resultado: IEscuela[]) => (this.escuelaData = resultado)))
      .subscribe();

    this.catalogosService
      .getPeriodo(false)
      .pipe(tap((resultado: IPeriodo[]) => (this.periodoData = resultado)))
      .subscribe();
  }

  getValueCatalogo(obj: any, index: string, id: number, indexValue: string) {
    let obje = obj.filter((x: any) => x[index] === id).pop();
    if (obje) {
      return obje[indexValue];
    } else {
      return null;
    }
  }

  addFilter(type: string, id: number) {
    this.filter[type] = id;
    this.loadData();
  }

  cleanFilters() {
    this.filter = {
      query: "",
      campus: 0,
      escuela: 0,
      periodo: 0,
    };
    this.loadData();
  }

  async loadData() {
    let userStore = this.usuarioService.getCurrentUserData();

    this.solicitudesSinRetosData = [];
    this.solicitudesAprobadasSinRetosData = [];
    this.solicitudesRechazadasSinRetosData = [];
    this.solicitudesSinRetosDataPage = [];
    this.solicitudesAprobadasSinRetosDataPage = [];
    this.solicitudesRechazadasSinRetosDataPage = [];

    let idUsuario =
      userStore.clavePerfil === "cvdp_admin" ? 0 : userStore.idUsuario;

    let response = await lastValueFrom(
      this.dashboardService.getSolicitudesSinRetos(
        idUsuario,
        "registrado",
        this.filter.campus,
        this.filter.escuela,
        this.filter.periodo,
        this.pagina,
        this.hits
      )
    );
    this.solicitudesSinRetosData = this.getProblematicasSinReto(response);

    response = await lastValueFrom(
      this.dashboardService.getSolicitudesSinRetos(
        idUsuario,
        "revision",
        this.filter.campus,
        this.filter.escuela,
        this.filter.periodo,
        this.pagina,
        this.hits
      )
    );
    this.solicitudesRechazadasSinRetosData = this.getProblematicasSinReto(
      response
    );

    response = await lastValueFrom(
      this.dashboardService.getSolicitudesSinRetos(
        idUsuario,
        "todas",
        this.filter.campus,
        this.filter.escuela,
        this.filter.periodo,
        this.pagina,
        this.hits
      )
    );
    this.solicitudesAprobadasSinRetosData = this.getProblematicasSinRetoComentarios(
      response
    );
  }


  async loadData2_4() {
    let userStore = this.usuarioService.getCurrentUserData();

    this.solicitudesSinRetosData = [];
    this.solicitudesAprobadasSinRetosData = [];
    this.solicitudesRechazadasSinRetosData = [];
    this.solicitudesSinRetosDataPage = [];
    this.solicitudesAprobadasSinRetosDataPage = [];
    this.solicitudesRechazadasSinRetosDataPage = [];

    let idUsuario =
      userStore.clavePerfil === "cvdp_admin" ? 0 : userStore.idUsuario;

    let response = await lastValueFrom(
      this.dashboardService.getSolicitudesSinRetos(
        idUsuario,
        "registrado",
        this.filter.campus,
        this.filter.escuela,
        this.filter.periodo,
        this.pagina,
        this.hits
      )
    );
    let responseRevision = await lastValueFrom(
      this.dashboardService.getSolicitudesSinRetos(
        idUsuario,
        "revision",
        this.filter.campus,
        this.filter.escuela,
        this.filter.periodo,
        this.pagina,
        this.hits
      )
    );

    let lista = [...response, ...responseRevision];

    this.solicitudesSinRetosData = this.getProblematicasSinReto(lista);


    response = await lastValueFrom(
      this.dashboardService.getSolicitudesSinRetos(
        idUsuario,
        "rechazado",
        this.filter.campus,
        this.filter.escuela,
        this.filter.periodo,
        this.pagina,
        this.hits
      )
    );
    this.solicitudesRechazadasSinRetosData = this.getProblematicasSinReto(
      response
    );

    response = await lastValueFrom(
      this.dashboardService.getSolicitudesSinRetos(
        idUsuario,
        "aprobado",
        this.filter.campus,
        this.filter.escuela,
        this.filter.periodo,
        this.pagina,
        this.hits
      )
    );
    this.solicitudesAprobadasSinRetosData = this.getProblematicasSinRetoComentarios(
      response
    );
  }

  getProblematicasSinReto(resultado) {
    return resultado.map((solicitudes: any) => ({
      fechaSolicitud: solicitudes.fechaRegistro,
      campusCoordinador: solicitudes.campus ?? "-",
      campus: solicitudes.campusSolicitado ?? "-",
      alcance: solicitudes.escuela ?? "-",
      link: {
        title: solicitudes.proyecto,
      },
      imageTitle: {
        title: solicitudes.nombreSocioFormador,
        subtitle: solicitudes.rfc,
        link: `/solicitud-problematica/${solicitudes.idProblematica}/${solicitudes.idSocioFormador}/${solicitudes.idSolicitud}`,
      },
      contactPerson: {
        title: solicitudes.nombreContacto,
        subtitle: solicitudes.correoContacto,
      },
      unidadFormativa: {
        title: solicitudes.industria,
      },
      claveEstatusSolicitud: {
        textCol: "Ver",
        statusType: "primary",
        link: `/solicitud-problematica/${solicitudes.idProblematica}/${solicitudes.idSocioFormador}/${solicitudes.idSolicitud}`,
      },
    }));
  }

  getProblematicasSinRetoComentarios(resultado) {
    return resultado.map((solicitudes: any) => ({
      fechaSolicitud: solicitudes.fechaRegistro,
      campusCoordinador: solicitudes.campus ?? "-",
      campus: solicitudes.campusSolicitado ?? "-",
      alcance: solicitudes.escuela ?? "-",
      link: {
        title: solicitudes.proyecto,
      },
      imageTitle: {
        title: solicitudes.nombreSocioFormador,
        subtitle: solicitudes.rfc,
        link: `/solicitud-problematica/${solicitudes.idProblematica}/${solicitudes.idSocioFormador}/${solicitudes.idSolicitud}`,
      },
      contactPerson: {
        title: solicitudes.nombreContacto,
        subtitle: solicitudes.correoContacto,
      },
      unidadFormativa: {
        title: solicitudes.industria,
      },
      message: {
        objParams: {
          idSolicitud: solicitudes.idSolicitud,
          idSocioformador: solicitudes.idSocioFormador,
        },
        callbackAction: (solicitudData) => {
          this.getComentarios(
            solicitudData.idSolicitud
          );
        },
      },
    }));
  }

  showPaginationDataRevision(objData: any) {
    if (objData.length) {
      this.solicitudesSinRetosDataPage = objData;
      this.changeDetector.detectChanges();
    }
  }

  showPaginationDataAprobado(objData: any) {
    if (objData.length) {
      this.solicitudesAprobadasSinRetosDataPage = objData;
      this.changeDetector.detectChanges();
    }
  }

  showPaginationDataRechazado(objData: any) {
    if (objData.length) {
      this.solicitudesRechazadasSinRetosDataPage = objData;
      this.changeDetector.detectChanges();
    }
  }

  comentariosData: any = [];
  async getComentarios(idSolicitud) {
      this.comentariosData = [];
      try {
        let result = await lastValueFrom(
          this.solicitudesService.getSolicitudComentarios(idSolicitud)
        );
        this.comentariosData = result;

    } catch (err) {
      console.log(err);
    }
    //this.comentariosData = [{comentario: "esta es una prueba" },{comentario: "esta es una prueba" },{comentario: "esta es una prueba" }];

    this.modalService.open(this.comentarios, { size: "xl" });
  }


}

