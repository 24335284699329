import { Component, Input, OnInit, Renderer2 } from "@angular/core";

import { createPopup } from "@typeform/embed";
import { environment } from "environments/environment";


declare function showTypeForm(idForm: any):any ;


@Component({
  selector: "app-typeform",
  templateUrl: "./typeform.component.html",
  styleUrls: ["./typeform.component.css"],
})
export class TypeformComponent implements OnInit {
  @Input() idForm: string;
  @Input() sleep: number = 5;

  sleepTimes = [
    {idForm:'zihNawN9',sleep:20}, // acad
    {idForm:'lY2Q6SnV',sleep:20},
    {idForm:'txhN1CtX',sleep:3},
    {idForm:'xog3Xwoi',sleep:20},
    {idForm:'JCEtSFKS',sleep:20}, // SF
    {idForm:'uyTIYuij',sleep:3},
    {idForm:'MqJGJ1DW',sleep:3},
    {idForm:'rooix9jw',sleep:20}, //CVDP
    {idForm:'PskHkKRb',sleep:3},
    {idForm:'C9XbqAYO',sleep:3},
    {idForm:'H4LxUk35',sleep:3}
  ]

  constructor(private renderer: Renderer2) {}

  ngOnInit(): void {

    //check if today is between 20240722 and 20240823
    // console.log('environment: ' ,environment.production);
    // console.log('Fechas: ', new Date().getTime() >= new Date(2024, 6, 22).getTime() && new Date().getTime() <= new Date(2024, 7, 23).getTime());
    if( (new Date().getTime() >= new Date(2024, 6, 22).getTime() && new Date().getTime() <= new Date(2024, 7, 23).getTime())){
    //if(!environment.production){
      let sleep = this.sleepTimes.find(s => s.idForm == this.idForm)?.sleep || this.sleep;

      setTimeout(()=> {
        createPopup(this.idForm, { size: 90 }).open();
        showTypeForm(this.idForm);
      }, (sleep * 1000));
    }

  }
}
