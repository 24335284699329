import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-image-title',
  templateUrl: './image-title.component.html',
  styleUrls: ['./image-title.component.css'],
})
export class ImageTitleComponent implements OnInit {
  @Input()
  elementsImageTitle: any;

  title_40: string;
  subtitle_40: string;
  

  imagenPerfil: string ="https://mvretosstorage.blob.core.windows.net/mvpretos-app-assets/avatar_sin.jpg";

  ngOnInit(): void {
    if (this.elementsImageTitle.title) {
      this.title_40 = this.elementsImageTitle.title;
      if (this.elementsImageTitle.title.length > 40) {
        this.title_40 = this.elementsImageTitle.title.substring(0, 40) + ' ...';
      }
    }

    if (this.elementsImageTitle.subtitle) {
      this.subtitle_40 = this.elementsImageTitle.subtitle;
      if (this.elementsImageTitle.subtitle.length > 40) {
        this.subtitle_40 =
          this.elementsImageTitle.subtitle.substring(0, 40) + ' ...';
      }
    }
  }
}
