<div id="c_sl_pa" class="sec_cnt tipo-10 mt-4">
  <div class="d-none d-md-block">

    <div class="cont_tit d-flex">
      <div class="flex-fill col-1">{{'problematicas.fecha' | translate }}</div>
      <div class="flex-fill col-3">{{'problematicas.nombre_reto' | translate }}</div>
      <div class="flex-fill col-2">{{'problematicas.contacto' | translate }}</div>
      <div class="flex-fill col-1">{{'problematicas.escuela' | translate }}</div>
      <div class="flex-fill col-2">{{'problematicas.programa_academico' | translate}}</div>
      <div class="flex-fill col-1">{{'problematicas.semestre' | translate}}</div>
      <div class="flex-fill col-1">{{'problematicas.campus' | translate}}</div>
      <div class="flex-fill col-1">{{'problematicas.acciones' | translate}}</div>
    </div>

    <div class="roundedsquare cont_fl d-flex" *ngFor="let el of problematicasList">
      <div class="flex-fill col-1">
        {{ el.fechaRegistro | date: 'dd/MMM/yy'}}
      </div>
      <div class="flex-fill col-3">
        <a [routerLink]="['/problematica-registrada']" [queryParams]="{socio: el.idSocio, problematica: el.idProblematica}" > {{ el.proyecto }}</a>
      </div>
      <div class="flex-fill col-2">
        {{ el.contacto }}<br/> {{el.correoContacto }}
      </div>
      <div class="flex-fill col-1">
        {{el.escuela}}
      </div>
      <div class="flex-fill col-2">
        {{ el.programaAcademico }}
      </div>
      <div class="flex-fill col-1">
        {{ el.semestre }}
      </div>
      <div class="flex-fill col-1">
        {{ el.campus }}
      </div>
      <div class="flex-fill col-1">
        <app-button (callbackAction)="verDetalle(el.idSocio, el.idProblematica)">Ver</app-button>
      </div>
      
    </div>

    <div *ngIf="problematicasList.length === 0">
      <app-sin-datos-table></app-sin-datos-table>
    </div>

  </div>

  <nav id="pagination" class="d-none d-md-block mt-4" *ngIf="numeroPaginas > 0">
    <ul class="pagination justify-content-center">
      <li class="page-item " [class.disabled]="paginaActual==1">
        <a class="page-link" (click)="obtenerRegistrosActuales(paginaActual-1)">{{ "paginador.anterior" | translate }} <img alt="Anterior" class="next_previous"
            src="assets/img/icon pag_ant.svg"></a>
      </li>
      <li class="page-item" *ngFor="let num of pages" [class.active]="paginaActual==num">
        <a class="page-link" (click) ="obtenerRegistrosActuales(num)">{{num}}</a>
      </li>
      <li class="page-item" [class.disabled]="paginaActual>=numeroPaginas">
        <a class="page-link" (click)="obtenerRegistrosActuales(paginaActual+1)"><img alt="Siguiente" class="next_previous" src="assets/img/icon pag_sig.svg"> {{ "paginador.siguiente" |
          translate }}</a>
      </li>
    </ul>
    <div class="text-center">{{ "paginador.total_paginador" | translate: {paginaActual: paginaActual, totalPaginas: numeroPaginas } }}</div>
  </nav>


  <div class="d-block d-md-none">
    <div class="accordion" id="ac_1">
      <div class="accordion-item" *ngFor="let el of elementsTo">
        <h2 class="accordion-header">
          <button class="accordion-button" type="button" data-bs-toggle="collapse"
            [attr.data-bs-target]="'#flush-collapse'+el.idProblematica" aria-expanded="true">
            {{el.proyecto}}
          </button>
        </h2>
        <div id="flush-collapse{{el.idProblematica}}" class="accordion-collapse collapse show" data-bs-parent="#ac_1">
          <div class="accordion-body">
            <strong [innerHTML]="'problematicas.campus' | translate "></strong><br/>{{el.campus}}<br/><br/>
            <strong [innerHTML]="'problematicas.semestre' | translate "></strong><br/>{{el.periodo}}<br/><br/>
            <strong [innerHTML]="'problematicas.programa_academico' | translate "></strong><br/>{{el.programaAcademico}}<br/><br/>
            <strong [innerHTML]="'problematicas.estatus' | translate "></strong><br />
              <div class="flex-fill d-flex justify-content-start">
                <img alt="Revisión" class="tam" src="assets/img/status_revision.png">
              </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-2 d-flex justify-content-center" *ngIf="paginaActualMasResultados < numeroPaginas">
      <button class="mb_mas" (click)="obtenerMasResultados()"><img alt="Más resultado" class="tam-icono-mas" src="assets/img/icon_mas_bullet.svg"> {{ 'problematicas.mas_resultados' | translate }}</button>
    </div>

    <div class="mt-2 d-flex justify-content-center" *ngIf="paginaActualMasResultados == numeroPaginas && elementsTo.length <= numberPerPage && problematicasListTotal.length > numberPerPage" >
      <button class="mb_mas" (click)="obtenerMasResultados()" ><img alt="Anterior" class="tam-icono-mas" src="assets/img/icon_mas_bullet.svg"> {{ 'paginador.resultados_anteriores' | translate }} </button>
    </div>

    <div *ngIf="elementsTo.length === 0">
      <app-sin-datos-table></app-sin-datos-table>
    </div>

  </div>
</div>
