import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { InicioService } from '@shared/service/Inicio/inicio.service';
import { SociosFormadoresService } from '@shared/service/Socios_Formadores/socios-formadores.service';
import { UsuariosService } from '@shared/service/Usuarios/usuarios.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-header-buscador',
  templateUrl: './header-buscador.component.html',
  styleUrls: ['./header-buscador.component.css'],
})
export class HeaderBuscadorComponent implements OnInit {
  nombreUsuario!: string;
  idSocioFormador!: number;
  idUsuario: string;
  idCampus: string;
  imagenPerfil!: string;
  clavePerfil!: string;
  NumeroMensajes: string = '0';
  valorBuscador: string = '';

  @Input() showSearchBar: boolean = true;
  @Input() lbl_placeholder: string;

  @Output() fatherNameFunction = new EventEmitter<string>();

  constructor(
    private usuarioService: UsuariosService,
    private sociosFormadoresService: SociosFormadoresService,
    private router: Router,
    private InicioService: InicioService
  ) {}

  async ngOnInit() {
    let userStore = this.usuarioService.getCurrentUserData();
    this.nombreUsuario = userStore.nombreUsuario;
    this.idSocioFormador = userStore.idSocioFormador;
    this.imagenPerfil = userStore.urlFotoUsuario;
    this.clavePerfil = userStore.clavePerfil;
    this.idUsuario = userStore.idUsuario;
    this.idCampus = userStore.idCampus;

    await this.obtenerNumeroMensajes();

    this.InicioService.currentFilterQuery.subscribe(cf => this.valorBuscador = cf);
  }
  async obtenerNumeroMensajes(){
    let fechaNotificaicon = localStorage.getItem('NotiFec')|| '';
    let miDate = new Date();
    let hoy = miDate.getFullYear()+''+miDate.getMonth()+''+miDate.getDay();
    if(fechaNotificaicon == hoy )
    {
      this.NumeroMensajes = '0';
      return;
    }

    try{
      let idSocio = this.usuarioService.getCurrentUserData().idSocioFormador;
      let items;
      if (this.clavePerfil == 'socio') {
        items = <any> await lastValueFrom(this.sociosFormadoresService.getNotificacionesByIdSocio(idSocio));
      }
      if (this.clavePerfil == 'academia') {
        items = <any>(await lastValueFrom(this.usuarioService.getNotificacionesByIdAcacemia(this.idUsuario)));
      }
      if (this.clavePerfil == 'cvdp' || this.clavePerfil == 'cvdp_admin') {
        items= <any>(await lastValueFrom(this.usuarioService.getNotificacionesByIdCVDP(this.idCampus)));
      }

      this.NumeroMensajes = items.length;
      if(items.length > 9)
        {
          this.NumeroMensajes = "9+";
        }
    }catch(error)
    {
      console.log('error', error);
    }
  }


  childFunction() {
    // Aquí haces lo que el componente hijo tenga que hacer y lanzas el evento al padre
    this.fatherNameFunction.emit(this.valorBuscador);
  }

  cleanSearchBox(){
    this.valorBuscador = '';
  }

  logout() {
     this.usuarioService.logout();
  }
}
