<nav class="row navbar navbar-expand-lg navbar-light bg-white mx-0">
    <div class="container-fluid">
        <a class="navbar-brand" href="\">
            <div id="logo-mb" class="col-10 d-flex align-items-center">
                <img alt="MPS" class="imgMenu" src="assets/img/sello_n.svg">
                <span class="subtitleMenu">MPS<br />Tec</span>
            </div>
        </a>


        <div class="collapse navbar-collapse bg-white position-relative" id="navbarSF">

            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                <li class="nav-item align-items-center">
                    REGISTRO:
                </li>

                <li class="nav-item d-none d-md-block registroLink">
                    <a class="nav-link" routerLink="/sf/registro-sf" href="">
                        <button class="ini-sesion btn-blue">
                            Organización</button>
                    </a>
                </li>

                <li class="nav-item d-none d-md-block registroLink">
                    <a class="nav-link" routerLink="/acad/registro" href="">
                        <button class="ini-sesion btn-hard-blue colorwhite">
                            Academia</button>
                    </a>
                </li>



                <li class="nav-item d-none d-md-block btnInicioSesion" *ngIf="MostrarBotonInicio">
                    <a class="nav-link" (click)="login()">
                        <button class="ini-sesion btn-amarillo">Iniciar
                            sesión</button></a>
                </li>
            </ul>

        </div>


        <a class="nav-link ms-auto" (click)="login()">
            <button class="ini-sesion btn-blue d-block d-lg-none ">Iniciar sesión</button>
        </a>
    </div>
</nav>
