<nav id="pagination" class="d-none d-md-block" *ngIf="totalPages > 0">
  <ul class="pagination justify-content-center">
    <li class="page-item {{ currentPage -1 === 0 ? 'disabled' : ''}}">
      <a class="page-link" (click)="updateCurrentPage(currentPage - 1)"
        >Anterior
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="10"
          height="20"
          viewBox="0 0 10 20"
          fill="none"
          class="iCn replaced-svg"
        >
          <path
            d="M8.31798 20L0.806934 12.0667C0.290133 11.519 0 10.7777 0 10.005C0 9.23228 0.290133 8.49101 0.806934 7.94333L8.32667 0L10 1.7675L2.48027 9.71C2.40621 9.78814 2.36461 9.8941 2.36461 10.0046C2.36461 10.1151 2.40621 10.221 2.48027 10.2992L9.99052 18.2325L8.31798 20Z"
            fill="#4A6BB6"
          ></path>
        </svg>
      </a>
    </li>
    <li class="page-item" *ngIf="(totalPages - maxShowPages) > 0 && currentPage > 1 " title="Inicio"><a (click)="updateCurrentPage(1)" >...</a></li>
    <li
      class="page-item {{ currentPage === page ? 'active' : '' }}"
      *ngFor="let page of paginationPages"
    >
      <a class="page-link" (click)="updateCurrentPage(page)">{{ page }}</a>
    </li>
    <li class="page-item" *ngIf="currentPage < totalPages - maxShowPages" title="Fin"><a (click)="updateCurrentPage(totalPages - maxShowPages)">...</a></li>
    <li class="page-item {{ currentPage >= (totalPages ) ? 'disabled' : '' }}">
      <a class="page-link" (click)="updateCurrentPage(currentPage + 1)">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="10"
          height="20"
          viewBox="0 0 10 20"
          fill="none"
          class="iCn replaced-svg"
        >
          <path
            d="M1.68202 0L9.19307 7.93333C9.70987 8.48101 10 9.22228 10 9.995C10 10.7677 9.70987 11.509 9.19307 12.0567L1.67333 20L0 18.2325L7.51973 10.29C7.59379 10.2119 7.63539 10.1059 7.63539 9.99542C7.63539 9.88493 7.59379 9.77897 7.51973 9.70083L0.00948048 1.7675L1.68202 0Z"
            fill="#4A6BB6"
          ></path>
        </svg>
        Siguiente
      </a>
    </li>
  </ul>
  <div class="text-center">{{ currentPage }} de {{ totalPages }}</div>
</nav>
