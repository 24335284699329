import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { map, Observable } from 'rxjs';
import { RetosResponse } from '@shared/Interface/IRetosResponse';
import {
  IActivacionResponse,
  IReto,
  IRetoAcademico,
  IRetoMaestro,
  IRetoResponse,
  IRetosMatch,
  IRetoVnculacion,
} from '@shared/Interface/IReto.interface';
import { baseUrl, moduleService, subModuleService } from '../routesServices';

const { RETOS, SOCIOS, LOGS } = moduleService;
const { ACADEMICOS, RETOS_MAESTROS, ESCUELAS, VINCULACIONES, ACTIVACIONES,RETOS_MATCH, UNIDADES_FORMATIVA } =
  subModuleService;
@Injectable({
  providedIn: 'root',
})
export class RetosService {
  baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  getRetos_query(
    query = '',
    escuela = 0,
    periodoSemestral = 0,
    semanas = 0,
    programa = 0,
    semestre = 0,
    periodoSemanal = 0,
    departamento = 0
  ): Observable<IReto[]> {
    let queryParams = new HttpParams();

    queryParams = queryParams.append("query", query);
    queryParams = queryParams.append("escuela", escuela);
    queryParams = queryParams.append("periodoSemestral", periodoSemestral);
    queryParams = queryParams.append("semanas", semanas);
    queryParams = queryParams.append("programaAcademico", programa);
    queryParams = queryParams.append("semestre", semestre);
    queryParams = queryParams.append("periodoSemanal", periodoSemanal);
    queryParams = queryParams.append("departamento", departamento);

    //`${baseUrl}${RETOS_MAESTROS}?query=${query}&escuela=${escuela}&programaAcademico=${programa}&periodoSemestral=${periodoSemestral}&semanas=${semanas}&periodoSemanal=${periodoSemanal}&semestre=${semestre}`
    return this.http.get<IReto[]>(
      `${baseUrl}${RETOS_MAESTROS}`,{
        params: queryParams
      }
    );
 }
  /**
   * Api que regresa la información de un reto
   * @param idReto Id del reto
   * @returns Objeto tipo RetosResponse
   */
  getRetos(idReto: any): Observable<RetosResponse> {
    return this.http.get<RetosResponse>(this.baseUrl + 'retos/' + idReto)
    .pipe(map(response => {
          response.alcance = (response.alcance == null? "Nacional":response.alcance);
          response.semanas = (response.semanas == null ? "1 semanas":response.semanas);
          response.idioma = (response.idioma == null ? "-": response.idioma);
          response.periodo = (response.periodo??'N/A');
          response.programaAcademico = (response.programaAcademico==null?"-":response.programaAcademico);
          response.sector =(response.sector == null ? "-": response.sector);
          response.tamano = (response.tamano == null ? "-" : response.tamano);
          response.entregable = (response.entregable == null ? "-": response.entregable);
          response.industria = (response.industria == null ? "-" : response.industria);
          response.palabrasClave = (response.palabrasClave == null ? "-": response.palabrasClave);
          response.usuarioTec = (response.usuarioTec == null ? "0": response.usuarioTec);
          response.region = (response.region??'-');

            return response;
      }));
  }

  getRetos_idReto(idReto: any): Observable<RetosResponse> {
    return this.getRetos(idReto);
  }

  getRetosAcademicos(idAcademico: number): Observable<IRetoAcademico[]> {
    return this.http.get<IRetoAcademico[]>(
      `${this.baseUrl}${RETOS}/${ACADEMICOS}/${idAcademico}`
    );
  }

  getRetosMaestros(idEscuela: number): Observable<IRetoMaestro[]> {
    return this.http.get<IRetoMaestro[]>(
      `${this.baseUrl}${RETOS}/${RETOS_MAESTROS}/${ESCUELAS}/${idEscuela}`
    );
  }

  getRetosMaestrosxUnidadFormativa(idUnidadFormativa: number): Observable<IReto[]> {
    return this.http.get<IReto[]>(
      `${this.baseUrl}${RETOS}/${UNIDADES_FORMATIVA}/${idUnidadFormativa}`
    );
  }

  getRetosMaestrosxUnidadFormativa_original(idUnidadFormativa: number): Observable<IRetoMaestro[]> {
    return this.http.get<IRetoMaestro[]>(
      `${this.baseUrl}${RETOS}/${RETOS_MAESTROS}/${UNIDADES_FORMATIVA}/${idUnidadFormativa}`
    );
  }

  /**
   * @deprecated
   * @param model
   * @returns
   */
  postRetos(model: any): Observable<IRetoResponse> {
    return this.http.post<IRetoResponse>(`${baseUrl}${RETOS}`, model);
  }

  postRetosMaestro(model: any): Observable<IRetoResponse> {
    return this.http.post<IRetoResponse>(`${baseUrl}${RETOS_MAESTROS}`, model);
  }

  putRetos(model: any): Observable<IRetoResponse> {
    return this.http.put<IRetoResponse>(`${baseUrl}${RETOS}`, model);
  }

  putRetoMaestro(model: any): Observable<IRetoResponse> {
    return this.http.put<IRetoResponse>(`${baseUrl}${RETOS_MAESTROS}`, model);
  }

  putActiveReto(model: any): Observable<IActivacionResponse> {
    return this.http.put<IActivacionResponse>(
      `${baseUrl}${RETOS}/${ACTIVACIONES}`,
      model
    );
  }

  postVinculaciones(model: any): Observable<IRetoVnculacion[]> {
    return this.http.post<IRetoVnculacion[]>(
      `${baseUrl}${RETOS}/${VINCULACIONES}/${SOCIOS}`,
      model
    );
  }

  getRetoMaestroDetail(idRetoMaestro: number): Observable<IRetoMaestro> {
    return this.http.get<IRetoMaestro>(
      `${baseUrl}${RETOS}/${RETOS_MAESTROS}/${idRetoMaestro}`
    );
  }


  postMatch(model:any): Observable<any>{
    return this.http.post<IRetosMatch>(
      `${baseUrl}${RETOS}/${LOGS}/${RETOS_MATCH}`,
      model
    )
  }

  putFotos(request:any):Observable<any>{
    return this.http.put(`${this.baseUrl}retos/fotos`, request);
  }

  putRetoProfesorCoordinador(request:any){
    return this.http.put(`${this.baseUrl}retos/profesorCoordinador`, request);
  }

  putretoSocioContacto(request: any){
    return this.http.put(`${this.baseUrl}retos/contactoEmpresa`, request)
  }
  putRetoVinculacion(request:any){
    return this.http.put(`${this.baseUrl}retos/vinculacion`, request);
  }

  putRetoPersonalizado(request:any){
    return this.http.put(`${this.baseUrl}retos/datosGenerales`,request);
  }

  postLogSocioElegido(request:any){
    return this.http.post(`${this.baseUrl}retos/vinculaciones/socios/elegidos`, request);
  }

  postVinculacionProblematica(request: any){
    return this.http.post(`${this.baseUrl}problematicas/vinculaciones`, request);
  }

  //* Entregables
  postRetoEntregable(request:any){
    return this.http.post(`${this.baseUrl}retos/entregables`, request);
  }

  putRetoEntregables(request:any):Observable<any>{
    return this.http.put(`${this.baseUrl}retos/entregables`, request);
  }

  getRetoEntregable(idReto: number, idEntregable: number): Observable<any[]> {
    return this.http.get<any>(`${this.baseUrl}${RETOS}/${idReto}/entregables/${idEntregable}`);
  }

  getAllRetoEntregable(idReto: number){
    return this.http.get<any>(`${this.baseUrl}${RETOS}/${idReto}/entregables`);
  }

  deleteRetoEntregable(idEntregable:number){
    let options = {
      body: {idRetoEntregable: idEntregable}
    };
    return this.http.delete<any>(`${this.baseUrl}${RETOS}/entregable`,options);
  }

  //* Contactos
  postRetoContacto(request: any){
    return this.http.post(`${this.baseUrl}${RETOS}/contacto`, request);
  }

  putRetoContacto(request: any){
    return this.http.put(`${this.baseUrl}${RETOS}/contacto`, request);
  }

  getRetoContactos(idReto: number, idContacto:number): Observable<any[]> {
    return this.http.get<any>(`${this.baseUrl}${RETOS}/${idReto}/contacto/${idContacto}`);
  }

  getAllRetoContacto(idReto:number){
    return this.http.get<any>(`${this.baseUrl}${RETOS}/${idReto}/contacto`);
  }

  deleteRetoContacto(idRetoContacto:number){
    let options = {
      body: {idRetoContacto: idRetoContacto}
    };
    return this.http.delete<any>(`${this.baseUrl}${RETOS}/contacto/`, options);
  }

  //* Profesores
  postRetoProfesor(request: any){
    return this.http.post(`${this.baseUrl}${RETOS}/profesor`, request);
  }

  putRetoProfesor(request: any){
    return this.http.put(`${this.baseUrl}${RETOS}/profesor`, request);
  }

  getRetoProfesor(idReto:number, idProfesor: number):Observable<any[]>{
    return this.http.get<any>(`${this.baseUrl}${RETOS}/${idReto}/profesores/${idProfesor}`);
  }

  getAllRetoProfesor(idReto:number):Observable<any[]>{
    return this.http.get<any>(`${this.baseUrl}${RETOS}/${idReto}/profesores`);
  }

  deleteProfesor(idReto:number, idProfesor: number){
    return this.http.delete<any>(`${this.baseUrl}${RETOS}/${idReto}/profesor/${idProfesor}`);
  }

  //* Compromisos

  postRetoCompromiso(request: any){
    return this.http.post(`${this.baseUrl}${RETOS}/compromisos`, request);
  }

  putRetoCompromiso(request: any){
    return this.http.put(`${this.baseUrl}${RETOS}/compromisos`, request);
  }

  getRetoCompromiso(idReto:number, idCompromiso: number){
    return this.http.get<any>(`${this.baseUrl}${RETOS}/${idReto}/compromisos/${idCompromiso}`);
  }

  getAllRetoCompromiso(idReto:any){
    return this.http.get<any>(`${this.baseUrl}${RETOS}/${idReto}/compromisos`);
  }

  deleteRetoCompromiso(idReto:number, idCompromiso: number){
    let options={
      body: {
        idRetoCompromiso: idCompromiso
      }
    }
    return this.http.delete<any>(`${this.baseUrl}${RETOS}/compromisos/`,options);
  }

  //* Recursos
  postRetoRecurso(request: any){
    return this.http.post(`${this.baseUrl}${RETOS}/recursos`, request);
  }

  putRetoRecurso(request: any){
    return this.http.put(`${this.baseUrl}${RETOS}/recursos`, request);
  }

  getRetoRecurso(idReto:number, idRecurso: number){
    return this.http.get<any>(`${this.baseUrl}${RETOS}/${idReto}/recursos/${idRecurso}`);
  }

  getAllRetoRecurso(idReto:number){
    return this.http.get<any>(`${this.baseUrl}${RETOS}/${idReto}/recursos`);
  }

  deleteRetoRecurso(idRecurso: number){
    let options = {
      body: {
        idRetoRecurso: idRecurso
      }
    };
    return this.http.delete<any>(`${this.baseUrl}${RETOS}/recursos`, options);
  }

  putRetoEstatus(request:any){
    return this.http.put(`${this.baseUrl}${RETOS}/estatus`, request);
  }

  getEvaluaciones(idRetoMaestro: Number, tipo: string="todos", pagina: Number = 0, filasxpagina: Number = 5 ){
    //GET /v1/retos/retosMaestros/203/evaluaciones?tipoComentario=[todos|no|Felicitación|Sugerencia de mejora|Queja|Otra]&pagina=0&filasxpagina=5
    return this.http.get(`${this.baseUrl}retos/retosMaestros/${idRetoMaestro}/evaluaciones?tipoComentario=todos&pagina=0&filasxpagina=5`);
  }
}

