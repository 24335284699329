import { Component, OnInit } from "@angular/core";
import {
  FormArray,
  FormControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import {
  IIndustrias,
  ITamanoEmpresa,
} from "@shared/Interface/ICatalogo.interface";
import { tap } from "rxjs/operators";
import { CatalogosService } from "@shared/service/Catalogos/catalogos.service";
import {
  ICampus,
  ICodigoPostalResponse,
  IColonia,
  IPais,
  IMedioContacto,
  IArea,
} from "@shared/Interface/ICatalogos.interface";
import { IRelacionesTecResponse } from "@shared/Interface/IRelacionesTecResponse";
import { SociosFormadoresService } from "@shared/service/Socios_Formadores/socios-formadores.service";
import Swal from "sweetalert2";
import { ContactosService } from "@shared/service/Contactos/contactos.service";
import { IEstadosResponse } from "@shared/Interface/IEstadosResponse";
import { lastValueFrom } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { UsuariosService } from "@shared/service/Usuarios/usuarios.service";
import { ISocioFormadorResponse } from "@shared/Interface/ISocioFormadorResponse";

@Component({
  selector: "app-formulario",
  templateUrl: "./formulario.component.html",
  styleUrls: ["./formulario.component.css"],
})
export class FormularioComponent implements OnInit {
  Industrias!: IIndustrias[];
  TamanoEmpresa!: ITamanoEmpresa[];
  Campus!: ICampus[];
  RelacionesTec!: IRelacionesTecResponse[];
  Estados!: IEstadosResponse[];
  Colonias!: IColonia[];
  Paises!: IPais[];
  MediosDeContacto!: IMedioContacto[];
  Areas!: IArea[];

  idCampusNacional : number = 53;

  formularioForm!: UntypedFormGroup;
  formularioFormC!: UntypedFormGroup;
  sectorEmpresa!: number;
  presenciaEmpresa!: number;

  procesandoBusqueda: boolean = false;
  mensajeRFC: string;
  existeRFC: boolean = false;
  socioFormadorExistente;
  razonSocial: string="";
  nombreSocioFormador: string="";

  vBuscaRfc: boolean = true;
  vExisteRFC: boolean = false;
  vFrmOrganizacion: boolean = false;
  vFrmContacto: boolean = false;
  vConfirmacion: boolean = false;
  vCancelar: boolean = false;

  cpMexico: boolean = true;

  rfc_empresa = new FormControl("", [Validators.required]);
  correoCVDP = "la del líder de campus que seleccionó";

  constructor(
    private readonly fb: UntypedFormBuilder,
    private catalogosSvc: CatalogosService,
    private socioFormadorService: SociosFormadoresService,
    private _contactoService: ContactosService,
    private _toastr: ToastrService,
    private _usaurioService: UsuariosService
  ) {}

  ngOnInit(): void {
    this.limpiarCampos();
    this.formularioForm = this.initForm();
    this.formularioFormC = this.initFormC();

    /** Catalogos
     * Industrias
     * Tamaño de empresa
     * Campus
     * Relaciones Tec
     */
    // INdustrias
    this.catalogosSvc
      .getIndustrias()
      .pipe(
        tap(
          (resultado: IIndustrias[]) =>
            (this.Industrias = resultado.filter((x) => x.industria != "Todas"))
        )
      )
      .subscribe();

    // Tamano Empresa
    this.catalogosSvc
      .getTamanoEmpresa()
      .pipe(
        tap((resultado: ITamanoEmpresa[]) => (this.TamanoEmpresa = resultado))
      )
      .subscribe();

    //Campus
    /** Para el registro se quita el campus Nacional */
    this.catalogosSvc
      .getCampus()
      .pipe(tap((resultado: ICampus[]) => (this.Campus = resultado.filter((c) =>  c.idCampus != this.idCampusNacional ))))
      .subscribe();

    // Relaciones Tec
    this.catalogosSvc
      .getRelacionesTec()
      .pipe(
        tap(
          (resultado: IRelacionesTecResponse[]) =>
            (this.RelacionesTec = resultado)
        )
      )
      .subscribe();

    // Estados
    this.getEstados();

    //Paises
    this.catalogosSvc
      .getPaises()
      .pipe(tap((resultado: IPais[]) => (this.Paises = resultado)))
      .subscribe();

    // Medios contacto
    this.catalogosSvc
      .getMediosContacto()
      .pipe(
        tap(
          (resultado: IMedioContacto[]) => (this.MediosDeContacto = resultado)
        )
      )
      .subscribe();

    // Areas
    this.catalogosSvc
      .getAreas()
      .pipe(tap((resultado: IArea[]) => (this.Areas = resultado)))
      .subscribe();
  }

  getEstados() {
    this.catalogosSvc
      .getEstados()
      .pipe(tap((resultado: IEstadosResponse[]) => (this.Estados = resultado)))
      .subscribe();
  }

  onSubmitOrganizacion() {
    this.formularioForm.markAllAsTouched();
    if (this.formularioForm.valid) {
      this.ocultarTodos();
      this.vFrmContacto = true;
    }
  }

  onSubmit(): void {
    if (this.formularioForm.valid) {
      let socioFormadorModel: any = {
        socioFormador: {
          nombreSocioFormador: this.formularioForm.get("nombre_empresa")?.value,
          empresa: this.formularioForm.get("descripcion_empresa")?.value,
          grupoComercial: this.formularioForm.get("grupo_empresa")?.value,
          rfc: this.formularioForm.get("rfc_empresa")?.value,

          calle: this.formularioForm.get("calle_empresa")?.value,
          ciudadFiscal: this.formularioForm.get("ciudad_empresa")?.value,
          cp: this.formularioForm.get("cp_empresa")?.value,
          claveEstado: this.formularioForm.get("estado_empresa")?.value,
          pais: this.formularioForm.get("pais_empresa")?.value,

          idSector: this.sectorEmpresa,
          idTamano: parseInt(this.formularioForm.get("tamanio_empresa")?.value),
          paginaWeb: this.formularioForm.get("web_empresa")?.value,
          idCampus: parseInt(this.formularioForm.get("campus_empresa")?.value),
          idIndustria: parseInt(
            this.formularioForm.get("industria_empresa")?.value
          ),
          idPresencia: this.presenciaEmpresa,
          relacionesTec: this.formularioForm.get("relaciones")?.value,
          conoceRolSF: this.formularioForm.get("acercamiento_empresa")?.value
        },
        contactoPrincipal: {
          nombre: this.formularioForm.get("nombre_contacto")?.value,
          apellidos: this.formularioForm.get("apellidos_contacto")?.value,
          area: this.formularioForm.get("area_departamento_contacto")?.value,
          email: this.formularioForm.get("email_contacto")?.value,
          telefonoOficina: this.formularioForm.get("telefono_oficina_contacto")
            ?.value,
          telefonoMovil: this.formularioForm.get("celular_contacto")?.value,
          puesto: this.formularioForm.get("puesto_contacto")?.value,
          exatec: this.formularioForm.get("exatec_contacto")?.value == "true",
          avisoPrivacidad: this.formularioForm.get("terminos_condiciones")
            ?.value,
          contactoPrincipal: true,
          clave: "mvpretos"
        },
      };
      if (this.existeRFC) {
        this._contactoService
          .postContactoSecundario(
            this.socioFormadorExistente.idSocioFormador,
            socioFormadorModel.contactoPrincipal
          )
          .subscribe({
            next: (resOk) => {
              Swal.fire({
                html:
                  '<div class="row b-0 p-0 m-0 h100">' +
                  '<div class="col-7 alert_title">Hemos recibido tu solicitud<br/><br/>' +
                  '<div class="alert_body">Revisaremos tu información y a la brevedad recibirás ' +
                  "un correo a la cuenta que tienes registrada.</div>" +
                  "</div>" +
                  '<div class="col-5 alert_succes_background">' +
                  '<div><span class="material-symbols-outlined"><img alt="" src="/assets/img/done.png"/></span></div>' +
                  "</div>" +
                  "</div>",
                showCloseButton: true,
                showConfirmButton: false,
                customClass: {
                  htmlContainer: "swal2-html-container-fsf",
                  popup: "alert_success_border",
                  closeButton: "swal2-close1",
                },
              }).then((result) => {
                window.location.href = "\\";
              });
            },
            error: (err) => {
              let mensajeError =
                err.message +
                '<br/><br/> Si tienes dudas, ponte en contacto con <a href="mailto:sociosformadores@servicios.tec.mx">sociosformadores@servicios.tec.mx</a>';
              Swal.fire({
                title: "<strong>Error en tu solicitud</strong>",
                icon: "error",
                html: mensajeError,
                showCloseButton: true,
                showConfirmButton: false,
              });
              console.log("Error Solcitud: ", err);
            },
          });
      } else {
        this.socioFormadorService.postSocios(socioFormadorModel).subscribe({
          next: (resOK) => {
            Swal.fire({
              html:
                '<div class="row b-0 p-0 m-0 h100">' +
                '<div class="col-7 alert_title">Hemos recibido tu solicitud<br/><br/>' +
                '<div class="alert_body">Revisaremos tu información y a la brevedad recibirás ' +
                "un correo a la cuenta que tienes registrada.</div>" +
                "</div>" +
                '<div class="col-5 alert_succes_background">' +
                '<div><span class="material-symbols-outlined"><img alt="" src="/assets/img/done.png"/></span></div>' +
                "</div>" +
                "</div>",
              showCloseButton: true,
              showConfirmButton: false,
              customClass: {
                htmlContainer: "swal2-html-container-fsf",
                popup: "alert_success_border",
                closeButton: "swal2-close1",
              },
            }).then((result) => {
              window.location.href = "/";
            });
          },
          error: (err) => {
            let mensajeError =
              err.message +
              '<br/><br/> Si tienes dudas, ponte en contacto con <a href="mailto:sociosformadores@servicios.tec.mx">sociosformadores@servicios.tec.mx</a>';
            Swal.fire({
              title: "<strong>Error en tu solicitud</strong>",
              icon: "error",
              html: mensajeError,
              showCloseButton: true,
              showConfirmButton: false,
            });
            console.log("Error Solcitud: ", err);
          },
        });
      }
    }
  }

  async existeContacto(email: string) {
    try {
      let res = await lastValueFrom(this._usaurioService.getUsuario(email));
      return res ? true : false;
    } catch (err) {
      console.log(err);
      return false;
    }
  }

  async guardarInformacion() {
    this.formularioFormC.markAllAsTouched();
    if (this.formularioFormC.valid) {
      let existe = await this.existeContacto(
        this.formularioFormC.get("email_contacto")?.value
      );
      if (existe) {
        Swal.fire({
          title: "<strong>Revisa tu solicitud</strong>",
          icon: "error",
          html: "<p>El correo ya existe en el sistema, por favor revísalo.</p>",
          showCloseButton: true,
          showConfirmButton: false,
        });
        return;
      }

      let socioFormadorModel: any = {
        socioFormador: {
          nombreSocioFormador: this.formularioForm.get("nombre_empresa")?.value,
          empresa: this.formularioForm.get("descripcion_empresa")?.value,
          razonSocial: this.formularioForm.get("razonSocial_empresa")?.value,
          grupoComercial: this.formularioForm.get("grupo_empresa")?.value,
          rfc: this.rfc_empresa.value,

          idSector: this.sectorEmpresa,
          idTamano: parseInt(this.formularioForm.get("tamanio_empresa")?.value),
          paginaWeb: this.formularioForm.get("web_empresa")?.value,
          idCampus: parseInt(this.formularioForm.get("campus_empresa")?.value),
          idIndustria: parseInt(
            this.formularioForm.get("industria_empresa")?.value
          ),
          idPresencia: this.presenciaEmpresa,
          relacionesTec: this.formularioForm.get("relaciones")?.value,
          conoceRolSF: this.formularioForm.get("acercamiento_empresa")?.value,

          pais: this.formularioForm.get("pais_empresa")?.value,
          cp: this.formularioForm.get("cp_empresa")?.value,
          claveEstado: this.formularioForm.get("estado_empresa")?.value,
          estado:
            this.formularioForm.get("estado_empresa")?.value ||
            this.formularioForm.get("estado2_empresa")?.value,

          ciudad: this.formularioForm.get("ciudad_empresa")?.value,
          ciudadFiscal: this.formularioForm.get("ciudad_empresa")?.value,

          colonia:
            this.formularioForm.get("colonia_empresa")?.value ||
            this.formularioForm.get("colonia2_empresa")?.value,

          calle: this.formularioForm.get("calle_empresa")?.value,
        },

        contactoPrincipal: {
          nombre: this.formularioFormC.get("nombre_contacto")?.value,
          apellidos: this.formularioFormC.get("apellidos_contacto")?.value,
          area: this.formularioFormC.get("area_departamento_contacto")?.value,
          email: this.formularioFormC.get("email_contacto")?.value,
          telefonoOficina: this.formularioFormC.get("telefono_oficina_contacto")
            ?.value,
          telefonoMovil: this.formularioFormC.get("celular_contacto")?.value,
          puesto: this.formularioFormC.get("puesto_contacto")?.value,
          exatec: this.formularioFormC.get("exatec_contacto")?.value == "true",
          avisoPrivacidad: this.formularioFormC.get("terminos_condiciones")
            ?.value,
          contactoPrincipal: true,
          idMedioContacto: this.formularioFormC.get("enteraste_contacto")?.value,
          datoMedioContacto: "",
          clave: "mvpretos"
        },
      };
      if (this.existeRFC) {
        this._contactoService
          .postContactoSecundario(
            this.socioFormadorExistente.idSocioFormador,
            socioFormadorModel.contactoPrincipal
          )
          .subscribe({
            next: async (resOk) => {
              this.ocultarTodos();
              // Mensaje de confirmación
              this.vConfirmacion = true;


              // Obtener correo del cvdp del campus que seleccionó
              this.correoCVDP = "sociosformadores@servicios.tec.mx";
              let campusCVDPPrincipal = this.Campus.filter(x=> x.idCampus == socioFormadorModel.socioFormador.idCampus);
              if(campusCVDPPrincipal.length>0){
                let UsuarioCVDPPrincipal = await lastValueFrom(this._usaurioService.getUsuarioById(campusCVDPPrincipal[0].idCVDPPrincipal));
                this.correoCVDP = UsuarioCVDPPrincipal.usuario;
              }


            },
            error: (err) => {
              let mensajeError =
                err.message +
                '<br/><br/> Si tienes dudas, ponte en contacto con <a href="mailto:sociosformadores@servicios.tec.mx">sociosformadores@servicios.tec.mx</a>';
              Swal.fire({
                title: "<strong>Error en tu solicitud</strong>",
                icon: "error",
                html: mensajeError,
                showCloseButton: true,
                showConfirmButton: false,
              });
              console.log("Error Solcitud: ", err);
            },
          });
      } else {
        this.socioFormadorService.postSocios(socioFormadorModel).subscribe({
          next: async (resOK) => {
            // Mensaje de confirmación
            this.ocultarTodos();
            this.vConfirmacion = true;
            // Obtener correo del cvdp del campus que seleccionó
            this.correoCVDP = "sociosformadores@servicios.tec.mx";
            let campusCVDPPrincipal = this.Campus.filter(x=> x.idCampus == socioFormadorModel.socioFormador.idCampus);
            if(campusCVDPPrincipal.length>0){
              let UsuarioCVDPPrincipal = await lastValueFrom(this._usaurioService.getUsuarioById(campusCVDPPrincipal[0].idCVDPPrincipal));
              this.correoCVDP = UsuarioCVDPPrincipal.usuario;
            }
          },
          error: (err) => {
            let mensajeError =
              err.message +
              '<br/><br/> Si tienes dudas, ponte en contacto con <a href="mailto:sociosformadores@servicios.tec.mx">sociosformadores@servicios.tec.mx</a>';
            Swal.fire({
              title: "<strong>Error en tu solicitud</strong>",
              icon: "error",
              html: mensajeError,
              showCloseButton: true,
              showConfirmButton: false,
            });
            console.log("Error Solcitud: ", err);
          },
        });
      }
    }
  }

  onCheckboxChange(event: any, typeData: string) {
    const selectedArray = this.formularioForm.controls[typeData] as FormArray;
    const index = selectedArray.controls.findIndex(
      (x) => x.value + "" === event.target.value
    );

    if (index >= 0) {
      event.target.checked = false;
      selectedArray.removeAt(index);
    } else {
      selectedArray.push(new FormControl(parseInt(event.target.value)));
    }
  }

  setSectorEmpresa(valor: number) {
    this.sectorEmpresa = valor;
  }
  setPresenciaEmpresa(valor: number) {
    this.presenciaEmpresa = valor;
    this.formularioForm.get("presencia_empresa")?.patchValue(valor);
  }

  initForm(): UntypedFormGroup {
    return this.fb.group({
      nombre_empresa: [null, [Validators.required, Validators.minLength(3)]],
      descripcion_empresa: [null, [Validators.required]],
      razonSocial_empresa:["",[Validators.required]],
      grupo_empresa: [null],

      calle_empresa: [null, [Validators.required]],
      ciudad_empresa: [null, [Validators.required]],
      cp_empresa: [
        null,
        [Validators.required, Validators.pattern(/^((EXT|ext)|\d{5})$/)],
      ],
      estado_empresa: [null, [Validators.required]],
      estado2_empresa: [null],
      pais_empresa: [null, [Validators.required]],
      colonia_empresa: [null, [Validators.required]],
      colonia2_empresa: [null],

      industria_empresa: [null, [Validators.required]],
      tamanio_empresa: [null, [Validators.required]],
      presencia_empresa: ["", [Validators.required]],
      sector_empresa: [""],
      web_empresa: [null, [Validators.required]],
      campus_empresa: [null, [Validators.required]],
      relaciones: this.fb.array([]),
      acercamiento_empresa: [null, [Validators.required]] // es requerido
    });
  }

  initFormC(): UntypedFormGroup {
    return this.fb.group({
      nombre_contacto: ["", [Validators.required, Validators.minLength(3)]],
      apellidos_contacto: ["", [Validators.required, Validators.minLength(3)]],
      area_departamento_contacto: [
        "",
        [Validators.required, Validators.minLength(3)],
      ],
      puesto_contacto: ["", [Validators.required, Validators.minLength(3)]],
      email_contacto: ["", [Validators.required, Validators.email]],
      telefono_oficina_contacto: ["", [Validators.pattern(/\d/)]],
      celular_contacto: ["", [Validators.pattern(/\d/)]],
      exatec_contacto: ["", [Validators.required]],
      enteraste_contacto: [null, [Validators.required]],
      terminos_condiciones: ["", [Validators.required]],
    });
  }

  buscarRFC(rfcText) {
    this.rfc_empresa.markAsTouched();
    if (this.rfc_empresa.valid) {
      this.procesandoBusqueda = true;
      this.mensajeRFC = "";

      let rfc = this.rfc_empresa.value?.trim() || "";
      this.socioFormadorService.buscarSocioPorRFC(rfc).subscribe({
        next: (resOK) => {
          this.procesandoBusqueda = false;
          this.existeRFC = true;
          this.razonSocial = (<ISocioFormadorResponse>resOK).razonSocial;
          this.nombreSocioFormador = (<ISocioFormadorResponse>resOK).nombreSocioFormador;
          this.socioFormadorExistente = resOK;
          this.mensajeRFC =
            "Ya existe un Socio Formador registrado con este RFC. Si quieres sumarte como contacto para este SF, por favor completa el formulario y una vez enviado revisaremos tu solicitud. Si tienes alguna duda envía un correo a <a href='mailto:sociosformadores@servicios.tec.mx'>sociosformadores@servicios.tec.mx.</a>";

          this.ocultarTodos();
          this.vExisteRFC = true;
        },
        error: (err) => {
          this.limpiarCampos();
          console.log(err);

          this.ocultarTodos();
          this.vFrmOrganizacion = true;
        },
      });
    }
  }

  limpiarCampos() {
    this.procesandoBusqueda = false;
    this.existeRFC = false;
    this.socioFormadorExistente = {};
    this.mensajeRFC = "";
  }

  ocultarTodos() {
    this.vBuscaRfc = false;
    this.vExisteRFC = false;
    this.vFrmOrganizacion = false;
    this.vFrmContacto = false;
    this.vConfirmacion = false;
    this.vCancelar = false;
  }

  regresarBuscarRFC() {
    this.ocultarTodos();
    this.rfc_empresa.patchValue("");
    this.vBuscaRfc = true;
  }
  RegresarHome() {
    window.location.href = "/";
  }

  mostrarFormularioOrganizacion() {
    this.ocultarTodos();
    this.vFrmOrganizacion = true;
  }

  mostrarFormularioContacto() {
    this.ocultarTodos();
    this.vFrmContacto = true;
  }

  Cancelar() {
    this.ocultarTodos();
    this.vCancelar = true;
  }
  regresar() {
    this.ocultarTodos();
    this.vFrmContacto = true;
  }

  onChangePais() {
    let oPais = this.formularioForm.get("pais_empresa")?.value;
    this.formularioForm.get("cp_empresa")?.patchValue("");
    if (oPais != "MEX") {
      this.formularioForm.get("cp_empresa")?.setValue("EXT");
    }
    this.buscarCP();
  }

  async buscarCP() {
    try {
      this.cpMexico = true;
      // Verificamos que el CP sea valido
      if (!this.formularioForm.get("cp_empresa")?.valid) {
        this._toastr.error("Escribe un Código Postal válido");
        return;
      }

      this.formularioForm.get("ciudad_empresa")?.patchValue(null);
      this.formularioForm.get("estado_empresa")?.patchValue(null);
      this.formularioForm.get("colonia_empresa")?.patchValue(null);
      this.formularioForm.get("estado2_empresa")?.patchValue(null);
      this.formularioForm.get("colonia2_empresa")?.patchValue(null);

      // Habilitamos los controles al estado default => México, CP
      this.formularioForm.get("estado2_empresa")?.disable();
      this.formularioForm.get("colonia2_empresa")?.disable();
      this.formularioForm.get("estado_empresa")?.enable();
      this.formularioForm.get("ciudad_empresa")?.enable();

      if (this.formularioForm.get("cp_empresa")?.value.toUpperCase() == "EXT") {
        // Es Extranjero
        this.cpMexico = false;
        this.Estados = [];
        this.formularioForm.get("estado2_empresa")?.enable();
        this.formularioForm.get("colonia2_empresa")?.enable();
        this.formularioForm.get("estado_empresa")?.disable();
        this.formularioForm.get("colonia_empresa")?.disable();
      } else {
        // Estados
        this.getEstados();
      }

      let resultado: ICodigoPostalResponse = await lastValueFrom(
        this.catalogosSvc.getInfoCp(
          this.formularioForm.get("cp_empresa")?.value
        )
      );

      if (resultado) {
        this.formularioForm.get("ciudad_empresa")?.patchValue(resultado.ciudad ?? resultado.estado);

        this.formularioForm
          .get("estado_empresa")
          ?.patchValue(resultado.claveEstado);

        this.Colonias = resultado.colonias;

        // Deshabilitamos estado y ciudad
        this.formularioForm.get("estado_empresa")?.disable();
        this.formularioForm.get("ciudad_empresa")?.disable();
      } else {
        this._toastr.error(
          "No hay informaicón pra el CP: " +
            this.formularioForm.get("cp_empresa")?.value
        );
      }
    } catch (err) {
      console.log(err);
    }
  }
  showRFCerror = false;
  validaRfcInput(e) {  // Accept only alpha numerics, not special characters
    let regex = new RegExp("^[a-zA-Z0-9&]+$");
    this.showRFCerror= false;
    let str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
        return true;
    }
    else{
      this.showRFCerror= true;
      this._toastr.warning('Escribe únicamente letras y números, sin espacios ni caracteres especiales.');
    }

    e.preventDefault();
    return false;
  }

  toUpperCaseRFC(){
    this.rfc_empresa.patchValue(this.rfc_empresa.value?.toUpperCase()??'')
  }

  onPaste(e) {
    e.preventDefault();
    return false;
  }

}
