import { Component, OnInit, Input } from '@angular/core';
import { UsuariosService } from '@shared/service/Usuarios/usuarios.service';
import { SociosFormadoresService } from '@shared/service/Socios_Formadores/socios-formadores.service';
import { IUser } from '@shared/Interface/user';
import { ProblematicasService } from '@shared/service/Problematicas/problematicas.service';
import { IProblematica } from '@shared/Interface/IProblematicas';
import Swal from 'sweetalert2';
import { SolicitudesService } from '@shared/service/Solicitudes/solicitudes.service';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { ISocioFormadorResponse } from '@shared/Interface/ISocioFormadorResponse';
import { ToastrService } from 'ngx-toastr';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-problematica-registrada',
  templateUrl: './problematica-registrada.component.html',
  styleUrls: ['./problematica-registrada.component.css'],
})
export class ProblematicaRegistradaComponent implements OnInit {
  @Input()
  idProblematica: number;
  idSocio: number;
  Socio: ISocioFormadorResponse;
  dataUser!: IUser;
  problematica: IProblematica;
  public objetivos: string;
  public entregables: string | null;
  public areas: string;
  public problematicaDesc: string;
  public imgUser: any;
  public usuario: any;
  constructor(
    private usuarioService: UsuariosService,
    private solicitudesService: SolicitudesService,
    private problematicas: ProblematicasService,
    private sociosFormadoresService: SociosFormadoresService,
    private location: Location,
    private router: ActivatedRoute,
    private routr: Router,
    private toastr: ToastrService
  ) {}

  async ngOnInit(): Promise<void> {
    let resultado1 = this.usuarioService.getCurrentUserData();
    this.dataUser = resultado1;

    this.router.queryParams.subscribe((data) => {
      this.idProblematica = data['problematica'];
      this.getProblematicas(this.idProblematica);
    });
  }
  async getSocioFormador(idSocio: number) {
    try {
      this.Socio = (await lastValueFrom(this.sociosFormadoresService.getSociosFormadoresById(idSocio)));
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'Error al obtener el detalle de la problemática ',
        title: 'Error',
      });
    }
  }

  async getProblematicas(idProblematica: number) {
    try {
      //this.dataUser.idSocioFormador
      this.problematica = <any>(
        await lastValueFrom(this.problematicas.getProblematicaId(idProblematica))
      );
      this.objetivos = this.problematica.objetivos;
      this.entregables = this.problematica.entregables;
      this.problematicaDesc = this.problematica.problematica;

      let areasList: string[] = [];
      let areas = this.problematica.areas;
      for (const prop in areas) {
        areasList.push(areas[prop].area);
      }
      this.areas = areasList.join(', ');
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'Error al obtener el detalle de la problemática ',
        title: 'Error',
      });
    }
  }
  async cancelarSolicitud() {
    let request = {
      idUsuario: this.dataUser.idUsuario,
      idSolicitud: this.problematica.idProblematica,
      claveEstatus: 'cancelado',
    };
    try {
      if (this.problematica.claveEstatus == 'cancelado') {
        this.toastr.success('Solicitud cancelada previamente.');
        return;
      }
      await lastValueFrom(this.solicitudesService.putSolicitudEstatus(request));
      this.toastr.success('Solicitud cancelada.');
      this.routr.navigate(['socio/historial-problematicas']);
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'Error al obtener el cancelar de la problemática ',
        title: 'Error',
      });
    }
  }

  regresar() {
    this.location.back();
  }

  mostrarBotonCancelarSolicitud(){
    let mostrarBotonSolicitud =true;
    if(this.problematica.claveEstatus == 'rechazado' ){
      mostrarBotonSolicitud=false;
    }
    if (this.problematica.claveEstatus == 'cancelado')
    {
      mostrarBotonSolicitud = false;
    }

    return mostrarBotonSolicitud ;

  }
}
