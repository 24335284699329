import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class ReportesService {
  baseUrl = environment.apiUrl;
  

  constructor(private http: HttpClient) { }

  postGenerarReporte(pIdReporte:number, pEncabezado: number, pParametros: string):Observable<any>
  {
    let body = {
      idReporte: pIdReporte,
      encabezado: pEncabezado,
      parametros: pParametros
    }
    
   return this.http.post(`${this.baseUrl}administracion/reportes`,body); 
  }




}
