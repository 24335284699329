<div class="row fondocard_h">
  <div class="row">
    <div class="col-md-4">
      <img alt="Logo" class="imgcarrousel_izq" src="{{ objCompany.img }}" />
    </div>
    <div class="col-md-5">
      <div class="row">
        <div class="col colmatch">
          <span class="titsans">{{ objCompany.nombre }}</span><br /><br /><br />
        </div>
        <div class="row">
          <div class="col-12 colmatch">
            <div class="row progwidth">
              <div class="col">
                <div class="row">{{ objCompany.matchScore }}% Match</div>
              </div>
              <div class="progress" style="height: 10px">
                <div
                  class="progress-bar bg-info"
                  role="progressbar"
                  attr.style="width: {{ objCompany.matchScore }}%"
                  attr.aria-valuenow="{{ objCompany.matchScore}}"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col clearfix">
            <br />
            <app-chip-item [chipObject]="objCompany.carrera"></app-chip-item>
            <br />
            <div class="chip">
              <app-chip-item [chipObject]="objCompany.semestre"></app-chip-item>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-3 align-self-end">
      <span class="#"
        ><app-button buttonType="yell">Más información</app-button></span
      ><br />
    </div>
  </div>
</div>