import { Component } from '@angular/core';

@Component({
  selector: 'app-usuarios-academia',
  templateUrl: './usuarios-academia.component.html',
  styleUrls: ['./usuarios-academia.component.css']
})
export class UsuariosAcademiaComponent  {

  

}
