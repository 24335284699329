import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError(this.handleError)
    );
  }

  handleError(error){
    let mensaje: string="";
    if(error){
      switch (error.status){
        case 400:
          mensaje = "Solicitud incorrecta.  " + error.error.mensaje;
        break;
        case 401:
          mensaje = "No autorizado, Vuevla a iniciar Sesión";
        break;
        case 402:
          mensaje = "Vuelva a iniciar Sesión";
        break;
        case 403:
          mensaje = "No tiene acceso a este recurso";
        break;
        case 404:
          mensaje = error.error?.mensaje || "No se encuentran resultados";
        break;
        case 405:
          mensaje = error.error.mensaje;
        break;
        case 409:
          mensaje = error.error;
          break;
        case 500:
          mensaje = "Error de aplicación, Consulte al Administrador";
        break;
        default:
          mensaje = error.error ?? "Error al consultar, Consulte al Administrador";
          console.log("Not handle error: ",error);
        break;
      }
    }

    return throwError(() => {
      const customError:any = new Error(mensaje);
      customError.status = error.status;
      return customError;
    });

  }
}
