<app-header-buscador [showSearchBar]="false"></app-header-buscador>

<div class="sec_ad">

  <app-btn-regresar></app-btn-regresar>
  <!-- Sección de estatus -->
  <app-title-show-more [showMoreObject]="titleRetos"></app-title-show-more>


  <div class="mt-2 pt-0 mb-0 pb-0">
    <ng-template matStepLabel>En formalización</ng-template>
    <div class="mt-5">
      <div class="nav nav-tabs d-inline" id="accordionExample">
        <div class="steps">

          <div class="step-item active" data-bs-toggle="tab" data-bs-target="#tab-formalizacion"
            (click)="changeViewStatus('formalizacion')">
            <progress [ngClass]="status != 'formalizacion'  ? 'progress-active' : 'progress-inactive'"
              id="progress-active" [value]="status != 'formalizacion'  ? 20 : 0" max=20></progress>
            <div class="titlestepActive">
              En formalización
            </div>
            <button class="step-button-active text-center">
              1
            </button>

          </div>
          <div class="step-item" data-bs-toggle="tab" data-bs-target="#tab-curso" (click)="changeViewStatus('curso')">
            <progress
              [ngClass]="status == 'evaluacion' || status == 'concluido' ? 'progress-active' : 'progress-inactive'"
              id="progress-active" [value]="status == 'evaluacion' || status == 'concluido'  ? 20 : 0"
              max=20></progress>
            <div [ngClass]="status != 'formalizacion'  ? 'titlestepActive' : 'titlestepInactive'">
              En curso
            </div>
            <button [ngClass]="status != 'formalizacion'  ? 'step-button-active' : 'step-button'" class="text-center">
              2
            </button>

          </div>

          <div class="step-item" data-bs-toggle="tab" data-bs-target="#tab-evaluacion"
            (click)="changeViewStatus('evaluacion')">
            <progress [ngClass]="status == 'concluido' ? 'progress-active' : 'progress-inactive'" id="progress-active"
              [value]="status == 'concluido'  ? 20 : 0" max=20></progress>
            <div [ngClass]="status == 'evaluacion' || status == 'concluido' ? 'titlestepActive' : 'titlestepInactive'">
              En evaluación
            </div>
            <button [ngClass]="status == 'evaluacion' || status == 'concluido'  ? 'step-button-active' : 'step-button'"
              class="text-center">
              3
            </button>

          </div>
          <div class="step-item" data-bs-toggle="tab" data-bs-target="#tab-concluido"
            (click)="changeViewStatus('concluido')">
            <div [ngClass]="status == 'concluido'  ? 'titlestepActive' : 'titlestepInactive'">
              Concluidos
            </div>
            <button [ngClass]="status == 'concluido'  ? 'step-button-active' : 'step-button'" class="text-center">
              4
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="tab-content" id="pills-tabContent">
    <div class="tab-pane fade show active" id="tab-formalizacion" role="tabpanel"
      aria-labelledby="tab-reto-activos-tab">
      <app-table [backgroundColor]="backgroundTable" [columns]="listadoRetosFormalizacionColumns"
        [data]="listadoRetosFormalizacionData"></app-table>
    </div>
    <div class="tab-pane fade" id="tab-curso" role="tabpanel" aria-labelledby="tab-reto-historial-tab">
      <app-table [backgroundColor]="backgroundTable" [columns]="listadoRetosCursoColumns"
        [data]="listadoRetosCursoData"></app-table>
    </div>
    <div class="tab-pane fade" id="tab-evaluacion" role="tabpanel" aria-labelledby="tab-reto-historial-tab">
      <app-table [backgroundColor]="backgroundTable" [columns]="listadoRetosEvaluacionColumns"
        [data]="listadoRetosEvaluacionData"></app-table>
    </div>
    <div class="tab-pane fade" id="tab-concluido" role="tabpanel" aria-labelledby="tab-reto-historial-tab">
      <app-table [backgroundColor]="backgroundTable" [columns]="listdaoRetosConcluidoColumns"
        [data]="listadoRetosConcluidoData"></app-table>
    </div>

  </div>



</div>
