import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-progress-type',
  templateUrl: './progress-type.component.html',
  styleUrls: ['./progress-type.component.css'],
})
export class ProgressTypeComponent  {
  @Input() elementsProgress: any;

  
}
