<app-header-buscador [showSearchBar]="false"></app-header-buscador>

<app-btn-regresar></app-btn-regresar>
<div class="" *ngIf="idReporteMostrar == 1">
    <app-title-show-more
        [showMoreObject]="{title: 'Reporte: Socios Formadores Vinculados a Reto'} "></app-title-show-more>
    <div class="tab-content" id="pills-tabContent">
        <div class="tab-pane fade show active" id="tab-socios-formadores" role="tabpanel"
            aria-labelledby="tab-socios-formadores-tab">
            <div class="tab-pane fade show active" id="retos-activos" role="tabpanel"
                aria-labelledby="retos-activos-tab">
                <div class="row mt-5">
                    <div class="col-8">
                        <div>
                            <div class="title-option">
                                Filtros:
                            </div>
                            <div clas="row">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-4">
                                            <span>
                                                Periodo Semestral:
                                            </span>
                                        </div>
                                        <div class="col-6">
                                            <ng-select [items]="periodoData$ | async"
                                                [formControl]="ctrlPeriodoSemestral" bindLabel="periodo"
                                                bindValue="idPeriodo">
                                            </ng-select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-4">
                                            <span>
                                                Escuela:
                                            </span>
                                        </div>
                                        <div class="col-6">
                                            <ng-select [items]="escuelaData$ | async" [formControl]="ctrlEscuela"
                                                bindLabel="escuela" bindValue="idEscuela">
                                            </ng-select>
                                        </div>
                                    </div>


                                </div>
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-4">
                                            <span>
                                                Campus:
                                            </span>
                                        </div>
                                        <div class="col-4">
                                            <ng-select [items]="campusData$ | async" [formControl]="ctrlCampus"
                                                bindLabel="campus" bindValue="idCampus">
                                            </ng-select>
                                        </div>
                                    </div>
                                </div>


                            </div>

                        </div>
                    </div>
                    <div class="col-4">
                        <button class="blue_button" (click)="obtenerReporte(1)">Generar</button>
                        &nbsp;
                        <button class="blue_button" (click)="exportData()">Exportar</button>

                    </div>
                </div>


                <table id="tblReportes" class="mt-5" aria-describedby="Título">
                    <thead>
                        <tr>
                            <th *ngFor="let col of columnas" > {{ col.title }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let data of DataPantallaActual">
                            <td *ngFor="let col of columnas;" > {{ data[col.title] }} </td>
                        </tr>
                    </tbody>
                </table>

                <app-pagination *ngIf="DataPantalla.length" [objPagination]="DataPantalla"
                    (paginationDataChange)="showPaginationData($event)" #pagina [recordsPerPage]="registrosPorPagina"></app-pagination>


            </div>
        </div>
    </div>
</div>

<div class="" *ngIf="idReporteMostrar == 2">
    <app-title-show-more [showMoreObject]="{title: 'Reporte: Base de Retos Semilla'} "></app-title-show-more>
    <div class="tab-content" id="pills-tabContent">
        <div class="tab-pane fade show active" id="tab-socios-formadores" role="tabpanel"
            aria-labelledby="tab-socios-formadores-tab">
            <div class="tab-pane fade show active" id="retos-activos" role="tabpanel"
                aria-labelledby="retos-activos-tab">
                <div class="row mt-5">
                    <div class="col-8">
                        <div>
                            <div class="title-option">
                                Filtros:
                            </div>
                            <div clas="row">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-2">
                                            <span>
                                                Escuela:
                                            </span>
                                        </div>
                                        <div class="col-4">
                                            <ng-select [items]="escuelaData$ | async" [formControl]="ctrlEscuela2"
                                                bindLabel="escuela" bindValue="idEscuela">
                                            </ng-select>
                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>
                    </div>
                    <div class="col-4">
                        <button class="blue_button" (click)="obtenerReporte(2)">Generar</button>
                        &nbsp;
                        <button class="blue_button" (click)="exportData()">Exportar</button>

                    </div>
                </div>

                <table id="tblReportes" class="mt-5" aria-describedby="Título">
                    <thead>
                        <tr>
                            <th *ngFor="let col of columnas"> {{ col.title }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let data of DataPantallaActual">
                            <td *ngFor="let col of columnas;"> {{ data[col.title] }} </td>
                        </tr>
                    </tbody>
                </table>

                <app-pagination *ngIf="DataPantalla.length" [objPagination]="DataPantalla"
                    (paginationDataChange)="showPaginationData($event)" #pagina [recordsPerPage]="registrosPorPagina"></app-pagination>



            </div>
        </div>
    </div>
</div>

<div class="" *ngIf="idReporteMostrar == 3">
    <app-title-show-more [showMoreObject]="{title: 'Reporte: Usuarios'} "></app-title-show-more>
    <div class="tab-content" id="pills-tabContent">
        <div class="tab-pane fade show active" id="tab-socios-formadores" role="tabpanel"
            aria-labelledby="tab-socios-formadores-tab">
            <div class="tab-pane fade show active" id="retos-activos" role="tabpanel"
                aria-labelledby="retos-activos-tab">
                <div class="row mt-5">
                    <div class="col-8">
                        <div>
                            <div class="title-option">
                                Filtros:
                            </div>
                            <div clas="row">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-4">
                                            <span>
                                                Rol:
                                            </span>
                                        </div>
                                        <div class="col-3">
                                            <ng-select [items]="rolesData" bindLabel="perfil" bindValue="cvePerfil"
                                                [formControl]="ctrlRoles"></ng-select>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="col-4">
                        <button class="blue_button" (click)="obtenerReporte(3)">Generar</button>
                        &nbsp;
                        <button class="blue_button" (click)="exportData()">Exportar</button>

                    </div>
                </div>

                <table id="tblReportes" class="mt-5" aria-describedby="Título">
                    <thead>
                        <tr>
                            <th *ngFor="let col of columnas"> {{ col.title }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let data of DataPantallaActual">
                            <td *ngFor="let col of columnas;"> {{ data[col.title] }} </td>
                        </tr>
                    </tbody>
                </table>

                <app-pagination *ngIf="DataPantalla.length" [objPagination]="DataPantalla"
                    (paginationDataChange)="showPaginationData($event)" #pagina [recordsPerPage]="registrosPorPagina"></app-pagination>

            </div>
        </div>
    </div>
</div>
