import { AbstractControl } from '@angular/forms';

export function ValidateBoth(control: AbstractControl) {
  if (
    control.value === undefined ||
    control.value === null ||
    control.value == '' ||
    control.value == 0
  ) {
    return { required: true };
  }
  return null;
}

export function ValidateProgramado(control: AbstractControl) {

    let esSemilla = control.parent?.get('semilla')?.value;

    if (esSemilla) {
        return null;
    }else{
      if (
        control.value === undefined ||
        control.value === null ||
        control.value == '' ||
        control.value == 0
      ) {
        return { required: true };
      }
    }

    return null;
}


export function ValidateMaestro (control: AbstractControl) {
    let esSemilla = control.parent?.get('semilla')?.value;
     
    if (esSemilla) {
      if (
        control.value === undefined ||
        control.value === null ||
        control.value == '' ||
        control.value == 0
      ) {
        return { required: true };
      }
    }

    return null;
}

