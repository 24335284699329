import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  UntypedFormGroup,
} from "@angular/forms";
import { Router } from "@angular/router";
import { ICartaConvenio } from "@shared/Interface/ICartaConvenio";
import {
  CUnidadFormativa,
  IArea,
  IEscuela,
  IPeriodo,
  IPeriodoSemanal,
} from "@shared/Interface/ICatalogos.interface";
import { IContacto } from "@shared/Interface/IContacto.interface";
import { IRelacionesTecResponse } from "@shared/Interface/IRelacionesTecResponse";
import { IReto, IRetoMaestro } from "@shared/Interface/IReto.interface";
import {
  ISocioFormadorResponse,
  SocioFormadorResponse,
} from "@shared/Interface/ISocioFormadorResponse";
import {
  IUsuarioGetResponse,
  UsuarioCVDPResponsableGetResponse,
  UsuarioGetResponse,
} from "@shared/Interface/IUsuarioGetResponse";
import { IUser } from "@shared/Interface/user";
import {
  Column,
  optionsTextCol,
} from "@shared/components/table/table.component";
import { AzureBlobStorageService } from "@shared/service/AzureBlobStorage.Service";
import { CatalogosService } from "@shared/service/Catalogos/catalogos.service";
import { ContactosService } from "@shared/service/Contactos/contactos.service";
import { RetosService } from "@shared/service/Retos/retos.service";
import { SociosFormadoresService } from "@shared/service/Socios_Formadores/socios-formadores.service";
import { SolicitudesService } from "@shared/service/Solicitudes/solicitudes.service";
import { UsuariosService } from "@shared/service/Usuarios/usuarios.service";
import { environment } from "environments/environment";
import { ToastrService } from "ngx-toastr";
import { tap, lastValueFrom } from "rxjs";
import Swal from "sweetalert2";
import { saveAs } from "file-saver";
import { NgbModal, NgbModalConfig } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-socio-formador",
  templateUrl: "./socio-formador.component.html",
  styleUrls: ["./socio-formador.component.css"],
})
export class SocioFormadorComponent implements OnInit {
  @ViewChild("content") content: ElementRef;

  content_modal;

  lang: string = "es";
  idSocio: number;
  relacionesTec: string = "";
  socioFormadorDetalle: ISocioFormadorResponse;
  MatchObjSocio: any;
  tieneReto: boolean = false;
  userStore: IUser;
  idReto: number;
  NombreReto: string;
  CVDPResponsable: IUsuarioGetResponse;
  contextoOrigen: string;

  cartaConvenio: any;
  listCartaConvenio: any;
  img: string;
  userData: IUser;
  convenioForm!: UntypedFormGroup;

  listCartaColaboracion: any;

  imgQuieroVincularlo: string = "assets/img/icon_abajo.svg";

  relacionesTecData: IRelacionesTecResponse[];

  // Vinculacion
  vinculacionForm: FormGroup;
  mostrarRequeridos: boolean = false;
  pedirPeriodo: boolean = false;
  pedirPeriodoSemanal: boolean = false;
  pedirModalidad: boolean = false;
  periodoAcademicoData: IPeriodo[];
  periodoSemanalData: IPeriodoSemanal[];
  periodoSemanalChk: number[] = [];

  //CVDPs
  listaCVDPs: UsuarioCVDPResponsableGetResponse[];

  backgroundTable = "#F6F6F6";

  retoInfo: any;
  retosAcademicosData: IReto[];
  unidadFormativaData: CUnidadFormativa[];
  mostrarDivSolVinculacion: boolean = false;
  lbl_idiomas = "";
  lbl_semestre = "";
  lbl_periodoSemanal = "";
  lbl_periodoSemestral = "";
  fileNameSelected;
  mostrarDivConvenio = false;
  imgSubirConvenio: string = "assets/img/icon_abajo.svg";
  contactos: IContacto[];
  escuelaData: any;
  mostrarFormulario: boolean = false;
  periodoAcademicoSeleccionado: number = 0;
  ModalidadSeleccionada: number = 0;

  idLogBusquedaSocio: number = 0;

  PERIDO_SEMANAL_SEMESTRAL = 4;

  areaData: IArea[];

  listModalidad: any[] = [
    { idModalidad: 1, modalidad: "Presencial" },
    { idModalidad: 2, modalidad: "Virtual" },
    { idModalidad: 3, modalidad: "Híbrido" },
  ];
  modalidad: number = 0;
  constructor(
    private socioFormadorService: SociosFormadoresService,
    private router: Router,
    private solicitudesService: SolicitudesService,
    private usuarioService: UsuariosService,
    private retoService: RetosService,
    private catalogosService: CatalogosService,
    private fb: FormBuilder,
    private azureBlobStorage: AzureBlobStorageService,
    private toastr: ToastrService,
    private contactosService: ContactosService,
    private config: NgbModalConfig,
    private modalService: NgbModal
  ) {
    this.userStore = this.usuarioService.getCurrentUserData();
    config.backdrop = "static";
    config.keyboard = false;
  }

  async ngOnInit() {
    this.getAreas();

    this.lang = localStorage.getItem("lang") ?? "es";

    let tmpidReto = localStorage.getItem("idReto") ?? 0;
    this.idReto = parseInt(tmpidReto.toString());
    let tmpMatchObj = localStorage.getItem("matchSelected") ?? "{}";
    this.MatchObjSocio = JSON.parse(tmpMatchObj);
    let tmp = localStorage.getItem("idSocioFormador") ?? 0;
    this.idSocio = parseInt(tmp.toString());

    this.socioFormadorDetalle = new SocioFormadorResponse();
    this.CVDPResponsable = new UsuarioGetResponse();

    this.idLogBusquedaSocio = Number(
      sessionStorage.getItem("idLogBusquedaSocio")
    );

    this.relacionesTecData = await lastValueFrom(
      this.catalogosService.getRelacionesTec()
    );

    this.initializeForm();

    this.userStore = this.usuarioService.getCurrentUserData();
    this.contextoOrigen = localStorage.getItem("origenVinculacion") ?? "";
    if (this.contextoOrigen == "banco") {
      localStorage.removeItem("idReto");
      localStorage.removeItem("matchSelected");
    }

    if (this.idReto) {
      this.tieneReto = true;
    }

    await this.obtenerInfo();

    this.convenioForm = this.initForm();

    /* Este código muestra u oculta la columna de acciones
    if (
      ["cvdp", "cvdp_admin"].includes(this.userStore.clavePerfil)
    ) {
      this.convenioColumns[this.convenioColumns.length-1].hide = false;
      this.colaboracionColumns[this.colaboracionColumns.length-1].hide = false;
    }
    */

    if (
      ["cvdp", "cvdp_admin", "academia"].includes(this.userStore.clavePerfil)
    ) {
      this.getAllCartaConvenios();
      this.getAllCartaColaboracion();
      this.getContactos();
      this.periodoAcademicoData = await lastValueFrom(
        this.catalogosService.getPeriodo()
      );
      this.periodoSemanalData = await lastValueFrom(
        this.catalogosService.getPeriodoSemanal()
      );
    }
    if ("academia" === this.userStore.clavePerfil) {
      this.getAcademicos();
    }
  }

  async obtenerInfo() {
    await this.getSocioFormador();
    await this.getReto();

    this.getEscuela(0);
  }

  initializeForm() {
    this.vinculacionForm = this.fb.group({
      idEscuela: ["", Validators.required],
      idUnidadFormativa: ["", Validators.required],
    });
  }

  changeLang(lang: string) {
    this.lang = lang;
    localStorage.setItem("lang", lang);
    window.location.reload();
  }

  async getUsuarioCVDP() {
    try {
      if (
        this.socioFormadorDetalle != null &&
        this.socioFormadorDetalle !== undefined
      ) {
        if (this.socioFormadorDetalle.idCVDPResponsable != 0) {
          let resultado = await lastValueFrom(
            this.usuarioService.getUsuarioById(
              this.socioFormadorDetalle.idCVDPResponsable
            )
          );
          if (resultado != null && resultado != undefined) {
            this.CVDPResponsable = resultado;
          }
        }
      }
    } catch (error) {
      console.error(
        "Error al obtener el detalle de un Usuario por su ID ",
        error
      );
    }
  }

  async getReto() {
    try {
      if (this.idReto != 0) {
        let reto = <any>(
          await lastValueFrom(
            this.retoService.getRetoMaestroDetail(this.idReto)
          )
        );
        if (reto) {
          this.NombreReto = reto.reto;
        }
      }
    } catch (error) {
      console.error("Error al obtener el detalle del reto por su ID", error);
    }
  }

  async getSocioFormador() {
    this.socioFormadorService.getSociosFormadoresById(this.idSocio).subscribe({
      next: (resOK: ISocioFormadorResponse) => {
        this.socioFormadorDetalle = resOK;
        this.relacionesTec = resOK.relacionesTec
          .map((relTec) => {
            let idRelTec = Number(relTec);
            return this.relacionesTecData
              .filter(
                (y: IRelacionesTecResponse) => y.idRelacionTec === idRelTec
              )
              .pop()?.relacionTec;
          })
          .join(", ");

        this.getCVDPS(this.socioFormadorDetalle.idCampus);
      },
      error: (error) => {
        Swal.fire({
          icon: "error",
          text: "Error al obtener el detalle de un Socio Formador por su ID",
        });
        console.error(
          "Error al obtener el detalle de un Socio Formador por su ID ",
          error
        );
      },
    });
  }

  verificaCheckPeriodoSemanal(event) {
    let idPeriodoSemanal: number = Number(event.target.value);
    let index = this.periodoSemanalChk.findIndex((x) => x === idPeriodoSemanal);
    if (idPeriodoSemanal == this.PERIDO_SEMANAL_SEMESTRAL && event.target.checked) {
      this.periodoSemanalChk = [1, 2, 3, 4];
    } else {
      if (idPeriodoSemanal == this.PERIDO_SEMANAL_SEMESTRAL)
        this.periodoSemanalChk = [];
      else if (this.periodoSemanalChk.findIndex(
              (x) => x == this.PERIDO_SEMANAL_SEMESTRAL
          ) > 0
        ){
          this.periodoSemanalChk.splice(
            this.periodoSemanalChk.findIndex(
              (x) => x == this.PERIDO_SEMANAL_SEMESTRAL
            ),
            1
          );
        }

      if (index < 0) {
        this.periodoSemanalChk.push(idPeriodoSemanal);
      } else {
        this.periodoSemanalChk.splice(index, 1);
      }
    }
  }

  async quieroVincularme() {
    this.pedirPeriodo = false;
    this.pedirPeriodoSemanal = false;
    this.pedirModalidad = false;

    // Se tiene el arreglo del periodo semanal
    if (!this.periodoAcademicoSeleccionado) this.pedirPeriodo = true;
    if (this.periodoSemanalChk.length == 0) this.pedirPeriodoSemanal = true;
    if (this.ModalidadSeleccionada == 0) this.pedirModalidad = true;

    if (this.mostrarSeccionOtroProfesor && !this.otroAcademicoSeleccionado) {
      this.toastr.error("Seleccionar el otro Académico");
      return;
    }

    let idUsuarioAcademico = this.userStore.idUsuario;

    if (this.mostrarSeccionOtroProfesor && this.otroAcademicoSeleccionado) {
      idUsuarioAcademico = this.otroAcademicoSeleccionado.idUsuario;
    }

    if (this.pedirPeriodo || this.pedirPeriodoSemanal || this.pedirModalidad) {
      return;
    }

    this.solicitudesService
      .postSolicitud({
        idTipoSolicitud: 5,
        descripcionSolicitud: `Solicitud de Vinculación de Reto ${this.NombreReto} a Socio Formador ${this.socioFormadorDetalle.nombreSocioFormador}`,
        idSocioFormador: this.socioFormadorDetalle.idSocioFormador,
        idCVDP: 0,
        idAcademia: idUsuarioAcademico,
        idUsuario: this.userStore.idUsuario,
        idProblematica: 0,
        idRetoMaestro: this.idReto, // actualizar a retomaestro
        idCampus: this.userStore.idCampus,
        idPeriodo: this.periodoAcademicoSeleccionado,
        periodosSemanales: this.periodoSemanalChk,
        idModalidad: this.modalidad,
      })
      .subscribe((data) => {
        this.guardarLog(data);
        this.router.navigate(["confirmacion-vinculacion"]);
      });
  }

  guardarLog(data) {
    if (this.MatchObjSocio.matchScore) {
      this.retoService
        .postLogSocioElegido({
          idLogBusquedaSocio: this.idLogBusquedaSocio,
          matchScore: this.MatchObjSocio.matchScore,
          idSocioFormador: this.idSocio,
        })
        .subscribe();
    }

    if (this.idLogBusquedaSocio > 0) {
      this.solicitudesService
        .putSolicitud({
          idSolicitud: data.idSolicitud,
          idLogBusquedaSocio: this.idLogBusquedaSocio,
        })
        .subscribe();

      sessionStorage.removeItem("idLogBusquedaSocio");
    }
  }

  async quieroVincularmeaReto() {
    this.mostrarRequeridos = true;
    this.pedirPeriodo = false;
    this.pedirPeriodoSemanal = false;
    this.pedirModalidad = false;

    // Se tiene el arreglo del periodo semanal
    if (!this.periodoAcademicoSeleccionado) this.pedirPeriodo = true;
    if (this.periodoSemanalChk.length == 0) this.pedirPeriodoSemanal = true;
    if (this.ModalidadSeleccionada == 0) this.pedirModalidad = true;

    if (this.mostrarSeccionOtroProfesor && !this.otroAcademicoSeleccionado) {
      this.toastr.error("Seleccionar el otro Académico");
      return;
    }
    let idUsuarioAcademico = this.userStore.idUsuario;

    if (this.mostrarSeccionOtroProfesor && this.otroAcademicoSeleccionado) {
      idUsuarioAcademico = this.otroAcademicoSeleccionado.idUsuario;
    }

    if (this.pedirPeriodo || this.pedirPeriodoSemanal || this.pedirModalidad) {
      this.toastr.error("Completa la información");
      return;
    }
    this.vinculacionForm.markAllAsTouched();

    if (this.vinculacionForm.valid) {
      this.solicitudesService
        .postSolicitud({
          idTipoSolicitud: 5,
          descripcionSolicitud: `Solicitud de Vinculación de Reto ${this.retoInfo.reto} a socio Formador ${this.socioFormadorDetalle.nombreSocioFormador}`,
          idSocioFormador: this.socioFormadorDetalle.idSocioFormador,
          idCVDP: 0,
          idAcademia: idUsuarioAcademico,
          idUsuario: this.userStore.idUsuario,
          idProblematica: 0,
          idRetoMaestro: this.retoInfo.idRetoMaestro,
          idCampus: this.userStore.idCampus,
          idPeriodo: this.periodoAcademicoSeleccionado,
          periodosSemanales: this.periodoSemanalChk,
          idModalidad: this.ModalidadSeleccionada,
        })
        .subscribe((data) => {
          this.guardarLog(data);
          this.router.navigate(["confirmacion-vinculacion"]);
        });
    }
  }

  getRetosAcademicosxUF($event) {
    this.vinculacionForm.patchValue({ idReto: "" });
    this.retoService.getRetosMaestrosxUnidadFormativa($event).subscribe({
      next: (resultado: IReto[]) => {
        this.retosAcademicosData = resultado;
        this.retoInfo = null;
      },
      error: (error) => {
        this.retosAcademicosData = [];
        this.retoInfo = null;
        Swal.fire({
          icon: "info",
          text: "No hay retos asociados a la Unidad Formativa",
        });
      },
    });
  }
  verifySelected(id: string | number, inputForm: string) {
    return this.vinculacionForm.get(inputForm)?.value === id ? true : null;
  }

  MostrarSolicitudVinculacionAcademico() {
    if (this.userStore.clavePerfil == "academia") {
      if (this.contextoOrigen == "banco") {
        return true;
      }
    }
    return false;
  }

  toogleDivSolVinculacion() {
    this.mostrarDivSolVinculacion = !this.mostrarDivSolVinculacion;

    this.imgQuieroVincularlo = this.mostrarDivSolVinculacion
      ? "assets/img/icon_arriba.svg"
      : "assets/img/icon_abajo.svg";

    if (["academia"].includes(this.userStore.clavePerfil)) {
      this.vinculacionForm
        .get("idEscuela")
        ?.patchValue(this.userStore.idEscuela);
      this.getUnidadFormativa(this.userStore.idEscuela);
    }
  }

  getRetoDetail(event: any) {
    const idUnidadFormativa = event;
    this.tieneReto = false;
    this.retoService
      .getRetosMaestrosxUnidadFormativa_original(idUnidadFormativa)
      .pipe(
        tap((res: IRetoMaestro[]) => {
          let resultado = res[0];

          this.retoService
            .getRetoMaestroDetail(resultado.idRetoMaestro)
            .subscribe({
              next: (resOK) => {
                this.lbl_idiomas = resOK.idiomas
                  .map((x) => x.idioma)
                  .join(", ");
                this.lbl_semestre = resOK.semestres
                  .map((x) => x.semestre)
                  .join(", ");
                this.lbl_periodoSemanal = resOK.periodosSemanales
                  .map((x) => x.periodoSemanal)
                  .join(", ");
                this.lbl_periodoSemestral = resOK.periodosSemestrales
                  .map((x) => x.periodoSemestral)
                  .join(", ");

                this.tieneReto = true;
                this.retoInfo = resOK;
              },
              error: (err) => {
                console.log(err);
              },
            });
        })
      )
      .subscribe();
  }

  addGeneralIcons(retoData: any) {
    const generalIcons: any = [];
    const iconsType: Record<string, any> = {
      alcance: {
        icon: "icon",
      },
      idioma: {
        icon: "icon_language",
      },
      semestre: {
        icon: "icon_semestre",
      },
      periodo: {
        icon: "icon_season",
      },
      semanas: {
        icon: "icon_calendar",
      },
    };

    Object.keys(retoData).forEach((retoKey: any) => {
      if (iconsType[retoKey]) {
        generalIcons.push({
          title:
            retoKey === "semestre"
              ? `${retoData[retoKey]} Semestre`
              : retoData[retoKey],
          icon:
            retoKey === "alcance"
              ? `${iconsType[retoKey].icon}_${this.cleanText(
                  retoData[retoKey]
                )}`
              : iconsType[retoKey].icon,
        });
      }
    });
    return generalIcons;
  }
  cleanText(textToClean: string) {
    return textToClean
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
  }

  async selImagen(event: any) {
    let idUsr = this.idSocio;
    let selFile = (event.target as HTMLInputElement).files?.[0];
    this.fileNameSelected = selFile?.name;
    if (!selFile) return;
    let fileName = this.getFileName(
      idUsr,
      this.convenioForm.get("fechaInicio")?.value,
      this.convenioForm.get("fechaFin")?.value
    );

    if (selFile.type != "application/pdf") {
      this.fileNameSelected = "Solo archivos PDF.";
    } else {
      await this.azureBlobStorage
        .uploadFile(
          {
            containerName: environment.AzureBlob_containerNameSocio,
            file: selFile,
            filename: fileName,
          },
          environment.AzureBlob_ACCOUNT_NAME,
          environment.AzureBlob_socioSAS
        )
        .then((res) => {
          let fileUrlLogo = `${environment.AzureBlob_socioURL}${fileName}`;
          this.convenioForm.controls['urlCartaConvenio'].patchValue(fileUrlLogo);
          this.img = fileUrlLogo;
        });
    }
  }

  guardarConvenio() {
    this.convenioForm.markAllAsTouched();
    if(this.convenioForm.valid){
      let idUsuario = this.usuarioService.getCurrentUserData().idUsuario;
      this.socioFormadorService
        .postCartaConvenio({
          idSocioFormador: this.idSocio,
          fechaInicio: this.convenioForm.get("fechaInicio")?.value,
          fechaFin: this.convenioForm.get("fechaFin")?.value,
          personaFirma: this.convenioForm.get("personaFirma")?.value,
          urlCartaConvenio: this.img,
          idUsuario: idUsuario,
          idCategoria: 6,
        })
        .subscribe((resOK) => {
          Swal.fire({
            icon: "success",
            text: "Convenio cargado con éxito.",
          });
          this.getAllCartaConvenios();
          this.convenioForm.reset();
        });
      this.toogleDivCargaConvenio();
    }
  }

  getAllCartaConvenios() {
    this.listCartaConvenio = [];
    this.socioFormadorService.getCartaConvenio(this.idSocio).subscribe({
      next: (res: ICartaConvenio[]) => {
        let listado;
        if (
          ["academia"].includes(
            this.usuarioService.getCurrentUserData().clavePerfil
          )
        ) {
          listado = res.pop() ?? {};
          this.listCartaConvenio.push({
            fechaInicio: listado.fechaInicio,
            fechaTermino: listado.fechaFin,
            personaFirma: listado.personaFirma,
            link: {
              link: listado.urlCartaConvenio,
              title: `Convenio ${listado.fechaInicio} ${listado.fechaFin}`,
              openNewTab: false,
              objParams:{
                urlCarta: listado.urlCartaConvenio
              },
              callbackAction:(objParams) => {
                this.verDocumento(objParams.urlCarta);
              }
            },
          });
        } else {
          listado = res;
          this.listCartaConvenio = listado.map((x) => ({
            fechaInicio: x.fechaInicio,
            fechaTermino: x.fechaFin,
            personaFirma: x.personaFirma,
            link: {
              link: x.urlCartaConvenio,
              title: `Convenio ${x.fechaInicio} ${x.fechaFin}`,
              openNewTab: false,
              objParams:{
                urlCarta: x.urlCartaConvenio
              },
              callbackAction:(objParams) => {
                this.verDocumento(objParams.urlCarta);
              }
            },
            buttons: [
              {
                className: "delete",
                objParams: x.idConvenio,
                callbackAction: (idConvenio) => {
                  this.eliminarCartaConvenio(idConvenio);
                },
              },
            ],
          }));
        }
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  getAllCartaColaboracion() {
    this.listCartaColaboracion = [];
    this.socioFormadorService.getCartaColaboracion(this.idSocio).subscribe({
      next: (resOk) => {
        let listado;

        if (
          ["academia"].includes(
            this.usuarioService.getCurrentUserData().clavePerfil
          )
        ) {
          listado = resOk.pop() ?? {};
          this.listCartaColaboracion.push({
            fechaInicio: listado.fechaFirma,
            personaFirma: listado.nombreAcademico,
            reto: listado.reto,
            link: {
              title: `Carta Colaboración ${listado.fechaFirma.substring(
                0,
                10
              )}`,
              link: listado.urlCartaColaboracion,
              openNewTab: false,
              objParams:{
                urlCarta: listado.urlCartaColaboracion
              },
              callbackAction:(objParams) => {
                this.verDocumento(objParams.urlCarta);
              }
            },
          });
        } else {
          listado = resOk;
          this.listCartaColaboracion = listado.map((x) => ({
            fechaInicio: x.fechaFirma,
            personaFirma: x.nombreAcademico,
            reto: x.reto,
            link: {
              title: `Carta Colaboración ${x.fechaFirma.substring(0, 10)}`,
              link: x.urlCartaColaboracion,
              openNewTab: false,
              objParams:{
                urlCarta: x.urlCartaColaboracion
              },
              callbackAction:(objParams) => {
                this.verDocumento(objParams.urlCarta);
              }
            },
            buttons: [
              {
                className: "delete",
                objParams: x.idColaboracion,
                callbackAction: (idCartaColaboracion) => {
                  this.eliminarCartaColaboracion(idCartaColaboracion);
                },
              },
            ],
          }));
        }
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  getFileName(id, fechaInicio, fechaFin) {
    fechaInicio = fechaInicio.replace("/");
    fechaFin = fechaFin.replace("/");
    return `cartaconvenio_${id}_${fechaInicio}_${fechaFin}.pdf`;
  }

  getUserData() {
    this.userData = this.usuarioService.getCurrentUserData();
    if (!this.userData) this.router.navigate(["/"]);
    return this.userData;
  }

  initForm(): UntypedFormGroup {
    return this.fb.group({
      fechaInicio: ["", [Validators.required]],
      fechaFin: ["", [Validators.required]],
      personaFirma: ["", [Validators.required]],
      urlCartaConvenio: ["", [Validators.required]],
    });
  }

  onSubmit(): void {
    this.convenioForm.markAllAsTouched();
    if (this.convenioForm.valid) {
      let idUsuario = this.usuarioService.getCurrentUserData().idUsuario;
      let convenioModel: any = {
        idSocioformador: this.userStore.idSocioFormador,
        fechaInicio: this.convenioForm.get("fechaInicio")?.value,
        fechaFin: this.convenioForm.get("fechaFin")?.value, //
        personaFirma: this.convenioForm.get("personaFirma")?.value,
        urlCartaConvenio: this.convenioForm.get("urlCartaConvenio")?.value,
        idUsuario: idUsuario,
        idCategoria: 6,
      };

      try {
        this.socioFormadorService.postCartaConvenio(convenioModel).subscribe({
          next: (resOK) => {
            Swal.fire({
              title: "<strong>Carta Convenio</strong>",
              icon: "success",
              html: "Convenio cargado correctamente",
              showCloseButton: true,
              showConfirmButton: false,
            });
            this.convenioForm.reset();
            this.fileNameSelected = "";
          },
          error: (err) => {
            Swal.fire({
              title: "<strong>Error en tu solicitud</strong>",
              icon: "error",
              html: err.error.mensaje,
              showCloseButton: true,
              showConfirmButton: false,
            });
            console.log("Error Solcitud: ", err);
          },
        });
      } catch (e) {
        console.log(e);
      }
    }
  }

  toogleDivCargaConvenio() {
    this.mostrarDivConvenio = !this.mostrarDivConvenio;

    this.imgSubirConvenio = this.imgSubirConvenio
      ? "assets/img/icon_arriba.svg"
      : "assets/img/icon_abajo.svg";
  }

  eliminarCartaConvenio(idConvenio: number) {
    let idUsuario = this.usuarioService.getCurrentUserData().idUsuario;
    Swal.fire({
      title: "Convenios",
      text: "¿Desea eliminar el convenio?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Eliminar",
    }).then((result) => {
      if (result.isConfirmed) {
        this.socioFormadorService
          .deleteCartaConvenio(idConvenio, idUsuario)
          .pipe(
            tap((resultado: any) => {
              if (resultado) {
                Swal.fire(
                  "Socio Formador - Convenio",
                  "Convenio eliminado.",
                  "success"
                );
                this.ngOnInit();
                this.convenioForm.reset();
                this.fileNameSelected = "";
              } else {
                this.toastr.error("Algo Salió mal!");
              }
            })
          )
          .subscribe();
      }
    });
  }

  eliminarCartaColaboracion(idCartacolaboracion: number) {
    let idUsuario = this.usuarioService.getCurrentUserData().idUsuario;
    Swal.fire({
      title: "Carta Colaboración",
      text: "¿Desea eliminar la carta de colaboración?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Eliminar",
    }).then((result) => {
      if (result.isConfirmed) {
        this.socioFormadorService
          .deleteCartaColaboracion(idCartacolaboracion, idUsuario)
          .pipe(
            tap((resultado: any) => {
              if (resultado) {
                Swal.fire(
                  "Socio Formador - colaboración",
                  "Carta colaboración eliminada.",
                  "success"
                );
                this.ngOnInit();
                this.convenioForm.reset();
              } else {
                this.toastr.error("Algo Salió mal!");
              }
            })
          )
          .subscribe();
      }
    });
  }

  MostrarContactos() {
    return ["cvdp", "cvdp_admin"].includes(
      this.usuarioService.getCurrentUserData().clavePerfil
    );
  }

  getContactos() {
    this.contactosService
      .getContactos(this.idSocio, false)
      .subscribe((resOK) => {
        this.contactos = resOK;
      });
  }

  getCVDPS(idCampus) {
    // Si es academico, trae los datos del academico
    let idCampusAcademico;
    if (this.usuarioService.getCurrentUserData().clavePerfil == "academia") {
      idCampusAcademico = this.usuarioService.getCurrentUserData().idCampus;
      if (idCampusAcademico) {
        idCampus = idCampusAcademico;
      }
    }

    this.usuarioService.getUsuariosCVDPByCampusResponsable(idCampus).subscribe({
      next: (resOK) => {
        this.listaCVDPs = resOK;
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  getEscuela(idEscuela: number) {
    this.catalogosService
      .getEscuelas(idEscuela)
      .pipe(
        tap((resultado: IEscuela[]) => {
          this.escuelaData = resultado;
        })
      )
      .subscribe();
  }

  getUnidadFormativa(event) {
    let idEscuela = event;
    this.catalogosService.getUnidadFormativa(idEscuela).subscribe({
      next: (resultado: CUnidadFormativa[]) => {
        this.unidadFormativaData = resultado;
      },
      error: (error) => {
        this.unidadFormativaData = [];
        this.tieneReto = false;
        this.retoInfo = null;
        this.vinculacionForm.get("idUnidadFormativa")?.patchValue({
          idUnidadFormativa: 0,
          clave: "",
          unidadFormativa: "",
        });

        Swal.fire({
          icon: "info",
          text: "No hay Unidades Formativas asociados",
        });
      },
    });
  }

  async mostarFormularioVinculacion() {
    this.mostrarFormulario = !this.mostrarFormulario;
    this.imgQuieroVincularlo = this.mostrarFormulario
      ? "assets/img/icon_arriba.svg"
      : "assets/img/icon_abajo.svg";
  }

  seleccionarPeriodo(event: any) {
    this.periodoAcademicoSeleccionado = Number(event.target.value);
  }
  seleccionarModalidad(event: any) {
    this.ModalidadSeleccionada = Number(event.target.value);
  }

  verificaChecked(idPeriodoSemanal) {
    return this.periodoSemanalChk.includes(idPeriodoSemanal);
  }

  async getAreas() {
    this.areaData = [];
    try {
      this.areaData = await lastValueFrom(this.catalogosService.getAreas());
    } catch (err) {
      console.log(err);
    }
  }

  getAreaNombre(area: string) {
    let res = "";
    res =
      this.areaData.filter((x) => x.idArea == Number(area)).pop()?.area ?? area;
    return res;
  }

  mostrarSeccionOtroProfesor: boolean = false;
  changeAcademicoEnNombreDeOtro(event) {
    this.mostrarSeccionOtroProfesor = !this.mostrarSeccionOtroProfesor;
    if (this.mostrarSeccionOtroProfesor) {
      this.otroAcademicoSeleccionado = null;
    }
  }

  seleccionarOtroAcademico(event) {
    this.otroAcademicoSeleccionado = event;
  }
  otroAcademicoSeleccionado;
  async getAcademicos() {
    try {
      let idUsuario = this.userStore.idUsuario;
      this.academicosData = await lastValueFrom(
        this.usuarioService.getAcademicosxNivelGestion(idUsuario)
      );
    } catch (error) {
      console.log(error);
    }
  }

  verDocumento(doc){

    this.filePdfFirmado = doc;
    this.content_modal = this.modalService.open(this.content, { size: "xl" });
  }

  closeConvenio() {
    this.content_modal.close();
  }

  downloadPDF() {
    //let nombre = this.filePdfFirmado.split("/")[4];
    saveAs(this.filePdfFirmado, "Carta.pdf");
  }

  filePdfFirmado: string;

  academicosData: any[] = [];

  convenioColumns: Column[] = [
    {
      title: "Fecha de inicio",
      sizeCol: "3",
      classType: "dashbd_rows_thin justify-content-md-center",
      typeColumn: optionsTextCol.date,
      key: "fechaInicio",
      headerClassName: "dashbd_header_small",
    },
    {
      title: "Fecha de Término",
      sizeCol: "2",
      classType: "dashbd_rows_thin justify-content-md-center",
      typeColumn: optionsTextCol.date,
      key: "fechaTermino",
      headerClassName: "dashbd_header_small",
    },
    {
      title: "Persona que firma",
      sizeCol: "3",
      classType: "dashbd_rows_thin justify-content-md-center",
      typeColumn: optionsTextCol.text,
      key: "personaFirma",
      headerClassName: "dashbd_header_small",
    },
    {
      title: "Documento",
      sizeCol: "3",
      classType: "dashbd_rows_thin justify-content-md-center",
      typeColumn: optionsTextCol.link,
      key: "link",
      headerClassName: "dashbd_header_small",
    },
    {
      title: "Acciones",
      sizeCol: "1",
      classType: "dashbd_rows_thin justify-content-md-center",
      typeColumn: optionsTextCol.buttons,
      key: "buttons",
      headerClassName: "dashbd_header_small",
      hide: true,
    },
  ];

  colaboracionColumns: Column[] = [
    {
      title: "Fecha de firma",
      sizeCol: "3",
      classType: "dashbd_rows_thin justify-content-md-center",
      typeColumn: optionsTextCol.date,
      key: "fechaInicio",
      headerClassName: "dashbd_header_small",
    },
    {
      title: "Académico que firma",
      sizeCol: "2",
      classType: "dashbd_rows_thin justify-content-md-center",
      typeColumn: optionsTextCol.text,
      key: "personaFirma",
      headerClassName: "dashbd_header_small",
    },
    {
      title: "Reto",
      sizeCol: "3",
      classType: "dashbd_rows_thin justify-content-md-center",
      typeColumn: optionsTextCol.text,
      key: "reto",
      headerClassName: "dashbd_header_small",
    },
    {
      title: "Documento",
      sizeCol: "3",
      classType: "dashbd_rows_thin justify-content-md-center",
      typeColumn: optionsTextCol.link,
      key: "link",
      headerClassName: "dashbd_header_small",
    },
    {
      title: "Acciones",
      sizeCol: "1",
      classType: "dashbd_rows_thin justify-content-md-center",
      typeColumn: optionsTextCol.buttons,
      key: "buttons",
      headerClassName: "dashbd_header_small",
      hide: true,
    },
  ];
}
