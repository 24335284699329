<div id="cuerpo" class="d-flex">
	<div class="content">
		<div class="sec_ad">
			<div id="sec_tab" class="d-flex flex-md-nowrap flex-wrap">
				<div class="flex-fill mr-2">
					<div class="d-flex justify-content-end">
						<div class="btn-group order-by">
							<button class="btn dropdown-toggle" type="button" id="listado" data-bs-toggle="dropdown"
								data-bs-auto-close="true" aria-expanded="false" data-bs-offset="0,0"
								[innerHTML]="textOrdenarPor | translate">
							</button>
							<ul class="dropdown-menu dropdown-menu-end" aria-labelledby="listado">
								<li><a class="dropdown-item" (click)="ordenarLista('nombreReto')"
										[class.active]="valorOrden== 1 "
										[innerHTML]="'solicitudes_sin_reto.nombre_az' | translate"></a></li>
								<li><a class="dropdown-item" (click)="ordenarLista('SocioFormador')"
										[class.active]="valorOrden== 2 "
										[innerHTML]="'solicitudes_sin_reto.socio_f' | translate"></a></li>
							</ul>
						</div>
					</div>
				</div>
			</div>

			<div id="c_sl_pa" class="sec_cnt tipo-10">
				<div class="d-none d-md-block">
					<div class="cont_tit d-flex">
						<div class="flex-fill col-1" [innerHTML]="'solicitudes_sin_reto.fecha' | translate"></div>
						<div class="flex-fill col-3" [innerHTML]="'solicitudes_sin_reto.socio_formador' | translate">
						</div>
						<div class="flex-fill col-2" [innerHTML]="'solicitudes_sin_reto.contacto' | translate"></div>
						<div class="flex-fill col-2"
							[innerHTML]="'solicitudes_sin_reto.nombre_problematica' | translate"></div>
						<div class="flex-fill col-1" [innerHTML]="'solicitudes_sin_reto.campus_atencion' | translate">
						</div>
						<div class="flex-fill col-1" [innerHTML]="'solicitudes_sin_reto.escuela' | translate"></div>
						<div class="flex-fill col-1" [innerHTML]="'solicitudes_sin_reto.campus_solicitado' | translate">
						</div>
						<div class="flex-fill col-1" [innerHTML]="'solicitudes_sin_reto.acciones' | translate"></div>
					</div>
					<div *ngFor="let el of solicitudesList" class="roundedsquare cont_fl d-flex align-items-center">
						<div class="flex-fill col-1">{{el.fechaRegistro |date:'dd/MMM/yy'}}</div>
						<div class="flex-fill col-3">
							<div>
								<a [routerLink]="[]"
									(click)="verSolicitudes(el.idSolicitud, el.idProblematica, el.idSocioFormador)">{{el.nombreSocioFormador}}</a>
							</div><span>{{el.rfc}}</span>
						</div>
						<div class="flex-fill col-2">
							<div>{{el.nombreContacto}}
							</div>
							<span>{{el.correoContacto}}</span>
						</div>
						<div class="flex-fill col-2">
							<div class="lmt_text">{{el.proyecto}}
							</div>
						</div>

						<div class="flex-fill col-1">{{el.campus }}</div>
						<div class="flex-fill col-1">{{el.escuela }}</div>
						<div class="flex-fill col-1">{{el.campusSolicitado }}</div>
						<div class="flex-fill col-1">
							<app-button
								(callbackAction)="verSolicitudes(el.idSolicitud, el.idProblematica, el.idSocioFormador)">
								{{ 'solicitudes_sin_reto.ver' | translate }}
							</app-button>
						</div>
					</div>

					<div *ngIf="solicitudesList.length === 0">
						<app-sin-datos-table></app-sin-datos-table>
					</div>

				</div>
				<nav id="pagination" class="d-none d-md-block mt-4" *ngIf="numeroPaginas > 0">
					<ul class="pagination justify-content-center">
						<li class="page-item" [class.disabled]="paginaActual==1">
							<a class="page-link" (click)="obtenerRegistrosActuales(paginaActual-1)">{{
								"paginador.anterior" |
								translate }} <img alt="Anterior" class="next_previous"
									src="assets/img/icon pag_ant.svg"></a>
						</li>
						<li class="page-item" *ngFor="let num of pages" [class.active]="paginaActual==num">
							<a class="page-link" (click)="obtenerRegistrosActuales(num)">{{num}}</a>
						</li>
						<li class="page-item" [class.disabled]="paginaActual>=numeroPaginas">
							<a class="page-link" (click)="obtenerRegistrosActuales(paginaActual+1)"><img alt="Siguiente"
									class="next_previous" src="assets/img/icon pag_sig.svg"> {{ "paginador.siguiente" |
								translate }}</a>
						</li>
					</ul>
					<div class="text-center">{{ "paginador.total_paginador" | translate: {paginaActual: paginaActual,
						totalPaginas:
						numeroPaginas } }}</div>
				</nav>
				<div class="d-block d-md-none">
					<div class="accordion" id="ac_1">
						<div *ngFor="let el of elementsTo" class="accordion-item">
							<h2 class="accordion-header">
								<button class="accordion-button" type="button" data-bs-toggle="collapse"
									[attr.data-bs-target]="'#fl'+el.idSolicitud" aria-expanded="true">
									<strong>{{el.nombreSocioFormador}}</strong>&nbsp;- {{el.nombreContacto}}
								</button>
							</h2>
							<div id="fl{{el.idSolicitud}}" class="accordion-collapse collapse show"
								data-bs-parent="#ac_1">
								<div class="accordion-body">
									<div><strong
											[innerHTML]="'solicitudes_sin_reto.fecha' | translate"></strong><br />{{el.fechaRegistro
										| date:'dd/MMM/yy'}}</div>
									<div><strong
											[innerHTML]="'solicitudes_sin_reto.nombre_problematica' | translate"></strong><br /><a
											[routerLink]="[]"
											(click)="verSolicitudes(el.idSolicitud, el.idProblematica, el.idSocioFormador)">{{el.proyecto}}</a>
									</div>
									<div><strong
											[innerHTML]="'solicitudes_sin_reto.socio_formador' | translate"></strong><br />{{el.nombreSocioFormador}}<br /><span>{{el.nombreContacto}}</span>
									</div>
									<div><strong
											[innerHTML]="'solicitudes_sin_reto.industria_sf' | translate"></strong><br />{{el.industria}}
									</div>
									<div><button class="blue_button_nrml"
											(click)="verSolicitudes(el.idSolicitud, el.idProblematica, el.idSocioFormador)"
											[innerHTML]="'solicitudes_sin_reto.ver' | translate"></button></div>
								</div>
							</div>
						</div>
					</div>
					<div class="d-flex justify-content-center" *ngIf="paginaActualMasResultados < numeroPaginas">
						<button class="mb_mas" (click)="obtenerMasResultados()"><img alt="Más resultados" class="tam"
								src="assets/img/icon_mas_bullet.svg"> {{ "solicitudes_sin_reto.cargar_mas_resultados" |
							translate }}</button>
					</div>

					<div class="mt-2 d-flex justify-content-center"
						*ngIf="paginaActualMasResultados == numeroPaginas && elementsTo.length <= numberPerPage && solicitudesListTotal.length > numberPerPage">
						<button class="mb_mas" (click)="obtenerMasResultados()"><img alt="Anterior" class="tam"
								src="assets/img/icon_mas_bullet.svg"> {{ 'paginador.resultados_anteriores' | translate
							}} </button>
					</div>

					<div *ngIf="elementsTo.length === 0">
						<app-sin-datos-table></app-sin-datos-table>
					</div>

				</div>
			</div>
		</div>
	</div>
</div>