<div class="my-4" *ngIf="hayCasosDeExito">

    <div class="subtitlecard">Casos de Éxito</div>
    <span class="retosExitosos" *ngFor="let ce of casosExito">
        <img 
            alt="{{ce.nombreReto}}" 
            src="{{ ce.urlImagenResultado }}" 
            class="my-4 mx-3"
            title="{{ ce.nombreReto }}"
            (click)="mostrarDetalle(ce.idReto)"
            />
    </span>

</div>