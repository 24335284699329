import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-not-search',
  templateUrl: './not-search.component.html',
  styleUrls: ['./not-search.component.css'],
})
export class NotSearchComponent  {
  @Input()
  objBotton: any = {
    title: '¿No es lo que estás buscando?',
    subtitle: 'Cuéntanos más detalles sobre tu problemática.',
    buttons: [
      {
        title: '+ Crear nueva vinculación',
        type: 'blue',
        action: () => {
          console.log('test');
        },
      },
    ],
  };


}
