<div class="carousel-item_match">
  <div class="card-body">
    <img class="imgcarrousel_match" alt="Logotipo" src="{{ matchObj.urlLogoSocioFormador }}" />
    <div class="row">
      <div class="col-md-12 mt-2">
        <div class="row">
          <div class="col contmatch">
            <span class="matchgde">{{ matchObj.matchScore }}% Match</span>
          </div>
          <div class="progress" style="height: 10px">
            <div
              class="progress-bar bg-info"
              role="progressbar"
              attr.style="width: {{ matchObj.matchScore }}%"
              attr.aria-valuenow="{{ matchObj.matchScore }}"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col mt-3">
        <span class="subtitlecard">{{ matchObj.socioFormador }}</span
        ><br /><br />
      </div>
    </div>
    <div class="row">
      <div class="col mt-3">
        <span class="txtcard" title="{{ matchObj.descripcion }}">{{ lblDescripcion }}</span>
      </div>
    </div>
    <div class="row">
      <div class="col clearfix mt-3">
        <mat-chip-list class="mat-chip-list-stacked">
          <mat-chip *ngIf="matchObj.presencia== null && matchObj.presencia== undefined">Presencia: {{ matchObj.presencia }}</mat-chip>
          <mat-chip *ngIf="matchObj.industria !== null && matchObj.industria !== undefined">Industria: {{ matchObj.industria }}</mat-chip>
          <mat-chip *ngIf="matchObj.sector !== null && matchObj.sector !== undefined">Sector: {{ matchObj.sector }}</mat-chip>
          <mat-chip *ngIf="matchObj.tamano !== null && matchObj.tamano !== undefined">Tamaño: {{ matchObj.tamano }}</mat-chip>
          <mat-chip *ngIf="matchObj.campus !== null && matchObj.campus !== undefined">Convenio firmado en: {{ matchObj.campus }}</mat-chip>
          <mat-chip *ngIf="matchObj.tipoSocioFormador !== null && matchObj.tipoSocioFormador !== undefined">{{ matchObj.tipoSocioFormador }}</mat-chip>
        </mat-chip-list>
      </div>
    </div>
    <div class="row">
      <div class="col mt-9">
        <span class="#">
          <app-button
            buttonType="yell"
            (callbackAction)="callbackJoinReto(matchObj)"
            >¡Ver detalle!</app-button
          >
        </span>
      </div>
    </div>
  </div>
</div>
