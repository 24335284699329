<app-header-buscador [showSearchBar]="false"></app-header-buscador>

<app-typeform idForm="txhN1CtX" *ngIf="status == 'en-curso'"></app-typeform>

<app-btn-regresar></app-btn-regresar>
<div class="mt-2" *ngIf="retoDetalle !== null && retoDetalle !== undefined">
  <div class="section-reto">

    <div class="iconos-reto">
      <div class="row">
        <div class="col-md-7">
          <p> <span class="titleform_sm_sans_2col">
              {{ "pagina_retos_detalles.titulo_reto" | translate }}
            </span><br>
            <span class="titleform_2col">
              {{ retoDetalle.reto }}
            </span><br>
            <span class="subtitleform_2c">
              {{ "pagina_retos_detalles.titulo_uf" | translate }}
              {{ retoDetalle.unidadFormativa }}</span><br>
            <span class="subtitlecard">
              Escuela de {{retoDetalle.escuela}}
            </span><br>
            <br>
          </p>
        </div>

        <!-- imagen del reto -->
        <div class="col-md-5 center">
          <img src="/assets/img/img_reto_fullsize.jpg" alt="Reto" class="imgcolumna_izq">
          <p class="d-none">Imagen .JPG o .PNG</p>
          <p class="d-none notspace">Tamaño Máx: 1024x1024 < 3Mb</p>
              <!-- Cambiar imagen del reto -->
              <div class="d-none editFoto fileUploadButton notspace" (click)="file.click()">Editar Foto</div>
              <input class="hidden" type="file" (change)="selImagen($event)" accept="image/*" #file />
        </div>

      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="ico_reto_container">
            <div class="ico_reto_container_local"></div>
            <div class="ico_reto_numsem"></div>
            <div class="ico_reto_container_inner_label">{{ retoDetalle.alcance }}</div>
          </div>

          <div class="ico_reto_container">
            <div class="ico_reto_container_semestre"></div>
            <div class="ico_reto_numsem"></div>
            <div class="ico_reto_container_inner_label">{{ semestreEtiqueta }}</div>
          </div>

          <div class="ico_reto_container">
            <div class="ico_reto_container_calendario"></div>
            <div class="ico_reto_numsem">{{ numSemanas }}</div>
            <div class="ico_reto_container_inner_label">{{ retoDetalle.semanas }}</div>
          </div>

          <div class="ico_reto_container">
            <div class="ico_reto_container_lang"></div>
            <div class="ico_reto_numlang">{{ idiomaNum }}</div>
            <div class="ico_reto_container_inner_label">{{ idiomaEtiqueta }}</div>
          </div>
        </div>
      </div>
      <div class="col-md-4"></div>

    </div>

    <div class="row mt-4">
      <div class="col-md-12">
        <div class="row">
          <div class="col">

            <div class="">

              <!-- Descargar -->
              <div class="mb-3 d-flex flex-row-reverse d-none">
                <button *ngIf="status=='En curso'" class="download_button" (click)="download()">
                  <img alt="" ngbTooltip="Descargar PDF" src="assets/img/download.svg" class="btn_download">
                  Descargar en PDF</button>
              </div>


              <div class="txtcard ">
                <span class="title-option">Periodo semestral: </span>
                <span class="txt-option"> {{ retoDetalle.periodo }}</span>
              </div>
              <div class="txtcard ">
                <span class="title-option">Periodo semanal: </span>
                <span class="txt-option"> {{ lblPeriodoSemanal }} </span>
              </div>
              <div class="txtcard ">
                <span class="title-option">Campus: </span>
                <span class="txt-option"> {{ campusConcat }} </span>
              </div>
              <div class="txtcard ">
                <span class="title-option">CRN: </span>
                <span class="txt-option"> {{ CRNPrincipal }}</span>
              </div>
              <div class="txtcard d-flex align-items-center">
                <span class="title-option">Alumnos inscritos:&nbsp;</span>
                <span class="txt-option"> {{ lblAlumnosInscritos }} </span>
                <span>
                  <button type="button" class="blue_button ms-3"
                    *ngIf="status == 'formalizacion' && ['cvdp','cvdp_admin','academia'].includes(clavePerfil)"
                    (click)="preguntarInscritos()">Cambiar</button>
                </span>
              </div><br>
              <div class="txtcard ">
                <span class="title-option">Fecha de inicio: </span>
                <span class="txt-option"> {{ retoDetalle.fechaInicio | date:
                  'dd MMM yyyy'}} </span>
              </div>
              <div class="txtcard ">
                <span class="title-option">Fecha de término: </span>
                <span class="txt-option"> {{ retoDetalle.fechaFin | date:
                  'dd MMM yyyy' }} </span>
              </div>
            </div>

            <!-- Estatus carta colaboración -->
            <div class="row" *ngIf="(['cvdp_admin','cvdp'].includes(clavePerfil)) && status == 'formalizacion'">
              <div class="mt-3 subtitlecard textblue">ESTATUS DE LA CARTA DE COLABORACIÓN</div>

              <div class="row mt-3">
                <div class="offset-1 steps col-7">
                  <!-- elaborada -->
                  <div class="step-item">
                    <progress [ngClass]="['elaborada','firmada_ac','firmada_sf'].includes(statusCarta)
                          ? 'progress-active-carta'
                          : 'progress-inactive-carta'" id="progress-active-carta"
                      [value]="['firmada_ac','firmada_sf'].includes(statusCarta) ? 20 : 0" max=20></progress>

                    <div [ngClass]="['elaborada','firmada_ac','firmada_sf'].includes(statusCarta)
                        ? 'titlestepActiveCarta'
                        : 'titlestepInactiveCarta' ">
                      Elaborada
                    </div>
                    <button [ngClass]="['elaborada','firmada_ac','firmada_sf'].includes(statusCarta)
                        ? 'step-button-active'
                        : 'step-button' " class="text-center">
                      1
                    </button>
                    <div class="txt-option">{{ lbl_firma_creacion }}</div>
                  </div>

                  <!-- Firmada acad -->
                  <div class="step-item">
                    <progress [ngClass]="['firmada_sf'].includes(statusCarta)
                          ? 'progress-active-carta'
                          : 'progress-inactive-carta'" id="progress-active-carta"
                      [value]="['firmada_sf'].includes(statusCarta)   ? 20 : 0" max=20></progress>
                    <div [ngClass]="['firmada_ac','firmada_sf'].includes(statusCarta)
                          ? 'titlestepActiveCarta'
                          : 'titlestepInactiveCarta'" class="">
                      Firmada por el académico
                    </div>
                    <button [ngClass]="['firmada_ac','firmada_sf'].includes(statusCarta)
                        ? 'step-button-active'
                        : 'step-button' " class="text-center">
                      2
                    </button>
                    <div class="txt-option">{{ lbl_firma_academico }}</div>
                  </div>

                  <!-- firmada Socio Formador -->
                  <div class="step-item">
                    <div [ngClass]="['firmada_sf'].includes(statusCarta)
                    ? 'titlestepActiveCarta'
                    : 'titlestepInactiveCarta'">
                      Firmada por el SF
                    </div>
                    <button [ngClass]="['firmada_sf'].includes(statusCarta) ? 'step-button-active' : 'step-button'"
                      class=" text-center">
                      3
                    </button>
                    <div class="txt-option">{{ lbl_firma_SF}}</div>
                  </div>
                </div>

                <div class="col-4 d-flex justify-content-end mt-2 d-none" *ngIf="statusCarta != 'firmada_sf'">
                  <button type="button" class="add_button" (click)="enviarRecordatorio()">Enviar recordatorio</button>
                </div>

              </div>

            </div>


            <!-- Sección de estatus -->
            <div class="mt-2 pt-0 mb-0 pb-0">

              <div class="mt-3 subtitlecard textblue">ESTATUS DEL RETO</div>
              <div class="text-secondary">
                Enseguida podrás visualizar el estatus de tu reto.<br />
                1.- <span class="text-secondary-bold">En Formalización </span> se refiere a la etapa previa al arranque
                del reto y aquí podrá indicar quién es el profesor coordinador, con qué Socio Formador trabajarás y
                documentar el trabajo que harás con él, lo que te permitirá construir y firmar la carta de
                colaboración.<br />
                2.- El reto pasará a <span class="text-secondary-bold">En curso</span> una vez que arranque y aquí
                podrás visualizar la información registrada en la sección de En Formalización.<br />
                3.- El reto estará <span class="text-secondary-bold">En evaluación</span> una vez que llegue a su
                término. Aquí podrás visualizar la evaluación que asignaste a la organización y esta evaluación se
                sumará a las evaluaciones que hayan hecho otros profesores para tener la evaluación global del SF que
                podrás consultar en el Banco de Socios Formadores.<br />
                4.- <span class="text-secondary-bold">En Concluido</span> aparecerá tu reto una vez que haya terminado.
                Aqui podrás postularlo como caso de éxito para que sea visible en el banco de Socios Formadores.


              </div>

              <ng-template matStepLabel>En formalización</ng-template>
              <div class="container mt-5">
                <div class="accordion" id="accordionExample">
                  <div class="steps">

                    <div class="step-item">
                      <progress [ngClass]="status != 'formalizacion'  ? 'progress-active' : 'progress-inactive'"
                        id="progress-active" [value]="status != 'formalizacion'  ? 20 : 0" max=20></progress>
                      <div class="titlestepActive">
                        En formalización
                      </div>
                      <button class="step-button-active text-center">
                        1
                      </button>

                    </div>
                    <div class="step-item">
                      <progress
                        [ngClass]="status == 'evaluacion' || status == 'concluido' ? 'progress-active' : 'progress-inactive'"
                        id="progress-active" [value]="status == 'evaluacion' || status == 'concluido'  ? 20 : 0"
                        max=20></progress>
                      <div [ngClass]="status != 'formalizacion'  ? 'titlestepActive' : 'titlestepInactive'">
                        En curso
                      </div>
                      <button [ngClass]="status != 'formalizacion'  ? 'step-button-active' : 'step-button'"
                        class="text-center">
                        2
                      </button>

                    </div>

                    <div class="step-item">
                      <progress [ngClass]="status == 'concluido' ? 'progress-active' : 'progress-inactive'"
                        id="progress-active" [value]="status == 'concluido'  ? 20 : 0" max=20></progress>
                      <div
                        [ngClass]="status == 'evaluacion' || status == 'concluido' ? 'titlestepActive' : 'titlestepInactive'">
                        En evaluación
                      </div>
                      <button
                        [ngClass]="status == 'evaluacion' || status == 'concluido'  ? 'step-button-active' : 'step-button'"
                        class="text-center">
                        3
                      </button>

                    </div>
                    <div class="step-item">
                      <div [ngClass]="status == 'concluido'  ? 'titlestepActive' : 'titlestepInactive'">
                        Concluido
                      </div>
                      <button [ngClass]="status == 'concluido'  ? 'step-button-active' : 'step-button'"
                        class="text-center">
                        4
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>


          <!-- Profesores -->
          <app-sobre-profesores class="mb-4" [lstprofe]="lstprofe" [retoDetalle]="retoDetalle"
            [status]='status'></app-sobre-profesores>


          <!-- Evaluación -->
          <div class="d-flex justify-content-end" *ngIf="status === 'evaluacion'">
            <div class="row">
              <div class="col-12"> Evaluación en este reto</div>
              <div class="col-5 d-flex justify-content-end">
                <input id="radio1" type="radio" name="estrellas" value="5">
                <!-- --><label class="star" for="radio1">★</label>
                <!-- --><input id="radio2" type="radio" name="estrellas" value="4">
                <!-- --><label class="star" for="radio2">★</label>
                <!-- --><input id="radio3" type="radio" name="estrellas" value="3">
                <!-- --><label class="star" for="radio3">★</label>
                <!-- --><input id="radio4" type="radio" name="estrellas" value="2">
                <!-- --><label class="star" for="radio4">★</label>
                <!-- --><input id="radio5" type="radio" name="estrellas" value="1">
                <!-- --><label class="star" for="radio5">★</label>
              </div>

              <button type="button" class="col-1 img_btn_comentario mt-1" (click)="mostrarComentarios()"></button>

            </div>
          </div>

          <!-- Sobre el Socio Formador -->
          <app-sobre-sf [idReto]="idReto" [status]='status' [oReto]="retoDetalle" (sobreSF)="sobreSF($event)"
          (sobreSFeliminado)="cambiodeSF($event)"
          (mostrarFormulario)="showhideSFForm($event)"
            (firmarCarta)="firmarCarta($event)">
          </app-sobre-sf>



          <div class="container mt-4" *ngIf="['cvdp_admin','cvdp','academia'].includes(clavePerfil) && bShowHideSFForm" >

            <div class="row">
              <div class="col-7"></div>
              <div class="col-3 mt-6 bold ml-5" *ngIf="status ==='formalizacion'">
                <button (click)="documentar(SECCION_SOCIO)" type="button" class="blue_button">Guardar </button>
              </div>
            </div>

          </div>


          <!-- reto a implementar -->
          <app-reto-implementar class="mt-3" [idReto]="idReto" [status]='status' [oReto]="retoDetalle"
            *ngIf="! retoConRawCase"
            (retoImplementar)="retoImpl($event)" (documentarR)="documentarR($event)">
          </app-reto-implementar>

          <div class="container mt-4" *ngIf="['cvdp_admin','cvdp','academia'].includes(clavePerfil) && retoConRawCase == true">

            <div class="row" *ngIf="! retoConRawCase">
              <div class="col-7"></div>
              <div class="col-3 mt-6 bold ml-5" *ngIf="status ==='formalizacion'">
                <button (click)="documentar(SECCION_RETO)" type="button" class="blue_button">Guardar </button>
              </div>
            </div>

          </div>


          <!-- casos de éxito -->
          <div class="" *ngIf="status === 'concluido' && retoConRawCase == false">
            <app-casos-exito [oReto]="retoDetalle"></app-casos-exito>
          </div>


        </div>

        <!-- Carta Colaboracion -->
        <div class="mt-2" *ngIf=" ! retoConRawCase">
          <app-carta-colaboracion [idReto]='idReto' [status]='status' [idSocioFormador]='infoSF.idSocioFormador'
          [borrarCarta]='borrarCartas'
          (sobreSFCV)="cartaEliminada($event)"
          (mostrarFormCartaColaboracion)="showhideCartaForm($event)"
            (setContactoEvent)="setContacto($event)" [oReto]="retoDetalle"></app-carta-colaboracion>
        </div>


      </div>

      <!-- botonera -->
      <div class="container mt-4" *ngIf="['cvdp_admin','cvdp','academia'].includes(clavePerfil) ">

        <div class="row ">
          <div class="col-7">&nbsp;</div>
          <div class="col-3 mt-6 bold ml-5" *ngIf="(['formalizacion'].includes(status) || !sinCarta()) && retoConRawCase == false && bShowHideCartaForm">
            <button (click)="documentar(SECCION_CARTA)" type="button" class="blue_button my-2">Guardar</button>
          </div>
          <div class="col-1"></div>
        </div>

        <div class="row d-flex justify-content-end">

          <div class="col-3 mt-6 bold ml-5 d-none" *ngIf="['formalizacion'].includes(status) || !sinCarta()">
            <button (click)="documentar(SECCION_CARTA)" type="button" class="blue_button my-2">Guardar</button>
          </div>

          <div class="col-2 mt-6 bold" *ngIf="status ==='formalizacion'">
            <button (click)="enviarAEnCurso()" type="button" class="yell_button my-2">Enviar</button>
          </div>

          <div class="col-2 mt-6 bold d-flex justify-content-end" *ngIf="['en-curso','evaluacion','concluido'].includes(status)">
            <button (click)="enviarAFormalizacion()" type="button" class="blue_button my-2">Editar</button>
          </div>

          <div class="col-2 mt-6 bold" *ngIf="status === 'en-curso'">
            <button (click)="enviarAEvaluacion()" type="button" class="yell_button my-2">Enviar</button>
          </div>

          <div class="col-2 mt-6 bold" *ngIf="status === 'evaluacion'">
            <button (click)="enviarAConcluido()" type="button" class="yell_button my-2">Enviar</button>
          </div>

        </div>


      </div>

      <div class="container mt-4" *ngIf="['socio'].includes(clavePerfil)">
        <div class="row">
          <div class="col-7"></div>
          <div class="col-3 mt-6 bold ml-5">
            <button (click)="documentarFirmaSocio()" type="button" class="blue_button">
              {{ lbl_texto_VerCartaFirmada }}</button>
          </div>

        </div>

      </div>

      <hr style="color: #BEDCFE">
    </div>



  </div>



  <!-- Modal de la carta Colaboracion -->

  <ng-template #content let-c="dismiss">
    <div class="modal-header">
      <h4 *ngIf="!Cartafirmada && !vConfirmacion" class="modal-title" id="modal-basic-title">
        Carta colaboración<br />
      </h4>

      <h4 *ngIf="vConfirmacion" class="modal-title" id="modal-basic-title">
        El contacto ha sido registrado con éxito.
      </h4>

      <button *ngIf="!Cartafirmada" type="button" class="btn-close" aria-label="Close" (click)="c('dismiss')"></button>
      <button *ngIf="Cartafirmada" type="button" class="btn-close" aria-label="Close"
        (click)="closeModalyCargarDashboard()"></button>
    </div>
    <div class="modal-body">
      <div *ngIf="mostrarCartaaFirmar">
        <p class="" *ngIf="!Cartafirmada">El siguiente documento formaliza el trabajo de tu organización con la academia
          a través de los retos del modelo Tec21.
          Te invitamos a revisar la información y aceptar para comenzar con la colaboración..</p>

        <div class="container-alert mb-3" *ngIf="Cartafirmada">
          <div class="row">
            <div class="col-md-12 mt-2 mb-2">
              <img alt="Aprobar" class="icono-success" src="assets/img/success.svg" />
              <span class="textsuccess">
                ¡La Carta de colaboración ha sido firmada con éxito!
              </span>
            </div>
          </div>
        </div>

        <app-convenio-viewer [IdSocioFormador]="IdSocioFormador" [showFilePDF]="filePdfFirmado"></app-convenio-viewer>


        <div class=" position-absolute end-0 me-4 my-3 d-none" *ngIf="Cartafirmada">
          <i>Carta firmada por {{ personaFirmaCarta }} el {{ fechaFirmaCarta }}</i>
        </div>

        <div class="requerido my-3" *ngIf="!Cartafirmada">
          <i class="py-2">NOTA: Si no eres tu quien firmará la carta, registra el nuevo usuario
            <button type="button" class="btn btn-link btn_aqui"
              (click)="enviarRegistroContactoSecundario()">AQUÍ</button>.</i>
        </div>
      </div>

      <div *ngIf="!mostrarCartaaFirmar">
        <form (ngSubmit)="guardarInformacion()" [formGroup]="formularioFormC">
          <!-- Datos de Contacto -->
          <div class="col-12" *ngIf="vFrmContacto">
            <!-- Si la empresa existe aparece este texto -->
            <div class="datosOrganizacion mt-5 d-none">
              <span>Empresa con RFC/No. de tributación IRS: {{ this.rfc_empresa}} / {{ this.razonSocial
                ||
                this.nombreSocioFormador }}</span>
            </div>

            <!-- Título Datos de Contacto -->
            <div class="frm_titulo_seccion mt-3">
              Datos de contacto
            </div>

            <div class="row">

              <div class="col-12 col-sm-6">
                <div class="pl-60px">

                  <!-- Nombre -->
                  <div>
                    <div class="d-flex1 justify-content-center1">
                      <div class="form-group">

                        <label for="nombre_contacto" class="form-label">Nombre <span class="requerido">*</span></label>
                        <input type="text" class="form-control" id="nombre_contacto" name="nombre_contacto"
                          formControlName="nombre_contacto" placeholder="Escribe el nombre aquí">
                        <div
                          *ngIf="formularioFormC.get('nombre_contacto')?.touched && formularioFormC.get('nombre_contacto')?.errors?.['required']"
                          class="error">
                          El campo es requerido
                        </div>
                        <div
                          *ngIf="formularioFormC.get('nombre_contacto')?.touched && formularioFormC.get('nombre_contacto')?.errors?.['minlength']"
                          class="error">
                          Mínimo 3 letras
                        </div>
                      </div>
                    </div>
                  </div>


                  <!-- Apellidos -->
                  <div>
                    <div class="d-flex1 justify-content-center1">
                      <div class="form-group">
                        <label for="apellidos_contacto" class="form-label">Apellidos <span
                            class="requerido">*</span></label>
                        <input type="text" class="form-control" id="apellidos_contacto" name="apellidos_contacto"
                          formControlName="apellidos_contacto" placeholder="Escribe los apellidos aquí">
                        <div
                          *ngIf="formularioFormC.get('apellidos_contacto')?.touched && formularioFormC.get('apellidos_contacto')?.errors?.['required']"
                          class="error">
                          El campo es requerido
                        </div>
                        <div
                          *ngIf="formularioFormC.get('apellidos_contacto')?.touched && formularioFormC.get('apellidos_contacto')?.errors?.['minlength']"
                          class="error">
                          Mínimo 3 letras
                        </div>
                      </div>
                    </div>
                  </div>



                  <!-- Area que pertenece -->
                  <div>
                    <div class="d-flex1 justify-content-center1">
                      <div class="form-group">
                        <label for="area_departamento_contacto" class="form-label">Área a la que
                          perteneces
                          <span class="requerido">*</span></label>

                        <div class="select-dropdown">
                          <ng-select class=" colorGray" [items]="Areas" bindLabel="area" bindValue="idArea"
                            placeholder="Selecciona una opción"
                            formControlName="area_departamento_contacto"></ng-select>
                        </div>

                        <div
                          *ngIf="formularioFormC.get('area_departamento_contacto')?.touched && formularioFormC.get('area_departamento_contacto')?.errors?.['required']"
                          class="error">
                          El campo es requerido
                        </div>
                        <div
                          *ngIf="formularioFormC.get('area_departamento_contacto')?.touched && formularioFormC.get('area_departamento_contacto')?.errors?.['minlength']"
                          class="error">
                          Minimo 3 letras
                        </div>
                      </div>
                    </div>
                  </div>


                  <!-- Puesto -->
                  <div>
                    <div class="d-flex1 justify-content-center1">
                      <div class="form-group">
                        <label for="puesto_contacto" class="form-label">Puesto <span class="requerido">*</span></label>
                        <input type="text" class="form-control" id="puesto_contacto" name="puesto_contacto"
                          formControlName="puesto_contacto" placeholder="Escribe el puesto aquí">
                        <div
                          *ngIf="formularioFormC.get('puesto_contacto')?.touched && formularioFormC.get('puesto_contacto')?.errors?.['required']"
                          class="error">
                          El campo es requerido
                        </div>
                        <div
                          *ngIf="formularioFormC.get('puesto_contacto')?.touched && formularioFormC.get('puesto_contacto')?.errors?.['minlength']"
                          class="error">
                          Mínimo 3 letras
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <div class="col-12 col-sm-6">

                <!-- Correo electrónico -->
                <div>
                  <div class="d-flex1 justify-content-center1">
                    <div class="form-group">
                      <label for="email_contacto" class="form-label">Correo electrónico <span
                          class="requerido">*</span></label>
                      <input type="text" class="form-control" id="email_contacto" name="email_contacto"
                        formControlName="email_contacto" placeholder="Escribe el correo electrónico aquí">
                      <div
                        *ngIf="formularioFormC.get('email_contacto')?.touched && formularioFormC.get('email_contacto')?.errors?.['required']"
                        class="error">
                        El campo es requerido
                      </div>
                      <div
                        *ngIf="formularioFormC.get('email_contacto')?.touched && formularioFormC.get('email_contacto')?.errors?.['email']"
                        class="error">
                        Correo no válido
                      </div>
                    </div>
                  </div>
                </div>



                <!-- Telefono Oficina -->
                <div>
                  <div class="d-flex1 justify-content-center1">
                    <div class="form-group">
                      <label for="telefono_oficina_contacto" class="form-label">Teléfono de
                        oficina</label>
                      <input type="text" class="form-control" id="telefono_oficina_contacto"
                        name="telefono_oficina_contacto" formControlName="telefono_oficina_contacto">
                      <div *ngIf="formularioFormC.get('telefono_oficina_contacto')?.errors?.['pattern']" class="error">
                        Deben ser sólo números
                      </div>
                    </div>
                  </div>
                </div>


                <!-- Telefono de Celular -->
                <div>
                  <div class="d-flex1 justify-content-center1">
                    <div class="form-group">
                      <label for="celular_contacto" class="form-label">Teléfono celular</label>
                      <input type="text" class="form-control" id="celular_contacto" name="celular_contacto"
                        formControlName="celular_contacto">
                      <div *ngIf="formularioFormC.get('celular_contacto')?.errors?.['pattern']" class="error">
                        Deben ser sólo números
                      </div>
                    </div>
                  </div>
                </div>


                <!-- Eres EXATEC -->
                <div class="row ">
                  <div class="d-flex1 justify-content-center1">
                    <div class="form-group">
                      <label for="exatec_contacto" class="form-label">¿Eres EXATEC? <span
                          class="requerido">*</span></label>
                      <div class="">
                        <select class="form-control" formControlName="exatec_contacto">
                          <option value="" selected disabled>Selecciona una opción</option>
                          <option value="true">Si</option>
                          <option value="false">No</option>
                        </select>
                        <div
                          *ngIf="formularioFormC.get('exatec_contacto')?.touched && formularioFormC.get('exatec_contacto')?.errors?.['required']"
                          class="error">
                          El campo es requerido
                        </div>
                      </div>
                    </div>
                  </div>

                </div>


                <!-- Cómo te enteraste -->
                <div class="row">
                  <div class="d-flex1 justify-content-center1">
                    <div class="form-group">
                      <label for="enteraste_contacto" class="form-label">¿Cómo te enteraste de esta
                        plataforma? <span class="requerido">*</span></label>

                      <div class="select-dropdown">
                        <ng-select class=" colorGray" [items]="MediosDeContacto" bindLabel="medioContacto"
                          bindValue="idMedioContacto" placeholder="Selecciona una opción"
                          formControlName="enteraste_contacto"></ng-select>
                      </div>



                      <div
                        *ngIf="formularioFormC.get('enteraste_contacto')?.touched && formularioFormC.get('enteraste_contacto')?.errors?.['required']"
                        class="error">
                        El campo es requerido
                      </div>
                    </div>
                  </div>
                </div>

              </div>



            </div>




            <button class="btn-amarillo d-none" type="submit" [disabled]="formularioFormC.invalid">Enviar</button>


          </div>

          <!-- botonera -->
          <div class="col-12 mt-3 d-sm-flex justify-content-center" *ngIf="vFrmContacto">
            <button class="btn-amarillo mx-sm-3 mt-3 order-sm-1" type="submit">Enviar</button>
            <button class="btn-brown  mx-sm-3 mt-3 order-sm-0" type="button"
              (click)="regresaraMostrarCartaFirmar()">Cancelar</button>

          </div>
        </form>
      </div>


      <div class="row text-center mt-5 mb-5" *ngIf="vConfirmacion">
        <div class="selft-align-center">

          <div class="titulo">
            <p>
              Una vez que el Centro de Vinculación y Desarrollo profesional del Tec lo apruebe, podrá ingresar y firmar
              el documento.
              <br>
              Próximamente te llegará un correo electrónico del status de tu solicitud.
              <br>

            </p>
            <p>
              Si tienes alguna duda envía un correo a
              <a href="mailto: sociosformadores@servicios.tec.mx">sociosformadores@servicios.tec.mx</a>
            </p>
          </div>
        </div>
        <div class="col-12 mt-3 d-sm-flex justify-content-center">
          <button class="btn-amarillo mx-sm-3 mt-3 " type="button" (click)="c('dismiss')">Salir</button>
        </div>
      </div>



    </div>
    <div class="modal-footer" *ngIf="!vConfirmacion && mostrarCartaaFirmar">
      <button *ngIf="!Cartafirmada" type="button" class="btn btn-brown" (click)="c('dismiss')">Más
        tarde</button>
      <button *ngIf="!Cartafirmada" type="button" class="btn btn-blue d-none" (click)="rechazar()">Rechazar</button>
      <button *ngIf="!Cartafirmada" type="button" class="btn btn-amarillo" (click)="firmaPaso1()">Firmar</button>
      <button *ngIf="Cartafirmada" type="button" class="btn btn-amarillo" (click)="downloadPDF()">Descargar
        PDF</button>
    </div>
  </ng-template>

  <ng-template #content_firm let-c="dismiss">
    <div class="modal-body">
      <p> Yo, {{ nombreContacto }} reitero mi interés para colaborar con el
        Tecnológico de Monterrey a través de un reto del Modelo Tec21. </p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-brown" (click)="c('dismiss')">Cancelar</button>
      <button type="button" class="btn btn-amarillo" (click)="firmaPaso2(content_firm)">Estoy de Acuerdo</button>
    </div>
  </ng-template>
