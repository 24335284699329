import { Component, Input } from '@angular/core';

export interface StatusType {
  textCol: string;
  statusType: string;
}

interface ProgressType {
  total: number;
  current: number;
  percentage: number;
}

interface ImageTitle {
  title: string;
  subtitle?: string;
  link?: string;
  image?: string;
}

interface LinkType {
  link?: string;
  title: string;
}

interface FileType{
  id?: number;
  url?:string;
}

export interface Data {
  id: number;
  fechaSolicitud?: string;
  fechaInicio?: string;
  reto?: LinkType;
  proyecto?: string;
  campus?: string;
  semestre?: number;
  programaAcademico?: string;
  nameRequest?: string;
  alcance?: string;
  nombreSocioFormador?: string;
  unidadFormativa?: LinkType;
  contactPerson?: ImageTitle;
  claveEstatusSolicitud?: StatusType;
  status?: StatusType;
  porcentajeAvance?: ProgressType;
  imageTitle?: ImageTitle;
  retoVincular?: ImageTitle;
  link?: LinkType;
  periodoSemestral?: string;
  fechaRegistro?: string;
  estatus?: string;
  buttons?: any;
  periodoSemanal?:string;
  campusCoordinador?:string;
  periodo?:string;
  duracionSemanas?:string;
  objFileWithOwner?: FileType;
  rfc?: string;
  duracion?:string;
  fechaTermino?: string;
  personaFirma?: ImageTitle;
  message?: any;
  inscritos?: number;
}

export interface Column {
  title: string;
  sizeCol: string;
  classType: string;
  typeColumn: string;
  key: keyof Data;
  icon?: boolean;
  headerClassName?: string;
  hide?: boolean
}

export const optionsTextCol = {
  text: 'text',
  link: 'link',
  button: 'button',
  status: 'status',
  progress: 'progress',
  imageTitle: 'imageTitle',
  buttons: 'buttons',
  date:'date',
  file:'file',
  message:'message'
};

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css'],
})
export class TableComponent  {
  
   
  
  columnsBase: Column[] = [
    {
      title: 'reto',
      sizeCol: '',
      classType: 'dashbd_rows',
      typeColumn: optionsTextCol.text,
      key: 'reto',
    },
    {
      title: 'Nombre Solicitud',
      sizeCol: '4',
      classType: 'dashbd_rows',
      typeColumn: optionsTextCol.text,
      key: 'nameRequest',
    },
    {
      title: 'Alcance',
      sizeCol: '',
      classType: 'dashbd_rows',
      typeColumn: optionsTextCol.link,
      key: 'alcance',
    },
    {
      title: 'Estatus',
      sizeCol: '2',
      classType: 'dashbd_rows',
      typeColumn: optionsTextCol.status,
      key: 'claveEstatusSolicitud',
    },
    {
      title: 'Progress',
      sizeCol: '',
      classType: 'dashbd_rows_prog',
      typeColumn: optionsTextCol.progress,
      key: 'porcentajeAvance',
    },
  ];

  @Input()
  backgroundColor = '#E9F5FE';
  @Input()
  columns = this.columnsBase;
  @Input()
  data: any;
  
  checkData() { 
    return this.data?.length;
  }

}
