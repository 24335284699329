<app-header-buscador [showSearchBar]="false"></app-header-buscador>

<app-typeform idForm="uyTIYuij" ></app-typeform>

<div class="sec_ad">
    <div class="row">
      <div class="col-md-7 order-1 order-md-0">
        <div id="sec_tit">
          <div class="d-flex align-items-center">
            <span class="subtit1col">RETO</span>
          </div>
          <app-title-show-more [showMoreObject]="titleConfirmReto"></app-title-show-more>
          <div class="flex-fill">
            <span class="subtit1col">UF: {{ carrer }}</span>
          </div>
          <br />
        </div>
        <div class="d-flex align-items-center">
          <span class="subtit1col_b">HEMOS RECIBIDO TU SOLICITUD</span
          ><br /><br />
        </div>
        <div class="d-flex align-items-center">
          A la brevedad recibirás un correo para notificarte los siguientes
          pasos.<br />

          ¿Estás listo para ideas frescas, innovación y colaboración académica?<br /><br /><br /><br />
        </div>
        <div class="row">
          <div class="col-md">
          <app-button extraClass="" (callbackAction)="sendBancoRetos()">Ir al Inicio</app-button>
          </div>
          &nbsp;
        </div>
      </div>
      <div class="col-md-5">
        <img alt="Reto" class="img_hdr" src="assets/img/img_reto_fullsize.jpg" />
      </div>
    </div>
</div>
