<app-header-buscador [showSearchBar]="false"></app-header-buscador>

<div class="sec_ad">
  <div>
    <img class="img_hdr" alt="Vinculación" src="assets/img/img_vinculacion_fullsize.jpg" />
  </div>
  <app-title-show-more [showMoreObject]="titleVinculacion"></app-title-show-more>

  <div class="div-text-btn">
    <div class="question-div">
      Une a los Socios Formadores a nuevos desafíos para mostrar el potencial de
      las Unidades Formativas.
      <br /><br />
    </div>
  </div>

  <form [formGroup]="vinculacionForm" (submit)="searchRetos()" autocomplete="off">
    <div class="row questions-container open-text">
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-12">
            <span class="question-title-text">Acerca de la Vinculación</span>
          </div>


          <div class="col-md-12">
            <span class="question-text">Selecciona tu Escuela <span class="requerido">*</span></span>

             <div class="requerido" *ngIf="vinculacionForm.get('idEscuela')?.errors?.['required']">
              Este campo es requerido
            </div>

            <div class="select-dropdown">
              <select class="form-select" id="idEscuela" aria-label="Default select example" formControlName="idEscuela"
              (change)="getUnidadFormativa($event)">
                <option selected>Selecciona</option>
                <option *ngFor="let escuela of escuelaData" value="{{ escuela.idEscuela }}" selected="{{
                    verifySelected(escuela.idEscuela, 'idEscuela')
                  }}">
                  {{ escuela.escuela }}
                </option>
              </select>

            </div>
          </div>

          <div class="col-md-12">
            <span class="question-text">Selecciona la Unidad Formativa <span class="requerido">*</span></span>

            <div class="requerido" *ngIf="vinculacionForm.get('idUnidadFormativa')?.errors?.['required']">
              Este campo es requerido.
            </div>

            <ng-select aria-label="Default " (change)="getRetoDetail($event)" formControlName="idUnidadFormativa">
              <ng-option *ngFor="let reto of unidadFormativaData" [value]="reto.idUnidadFormativa"
                selected="verifySelected(reto.idUnidadFormativa, 'idUnidadFormativa')">
                {{ reto.clave }} - {{ reto.unidadFormativa}}
              </ng-option>
            </ng-select>
          </div>

          <!-- Información del reto seleccionado -->
          <div class="col bkgatomic" *ngIf="!retoInfo">
            <span class="titatomic">Selecciona un reto para ver su información
            </span>
          </div>

          <div class="col bkgatomic" *ngIf="retoInfo">
            <div class="row mb-5">
              <div class="col">
                <img class="imgpchip_med" alt="Reto" src="assets/svg/icon_info.svg" />&nbsp;
                <span class="titatomic">
                  <strong>Información del reto asociado a la UF</strong>
                </span>
              </div>
            </div>
            <div class="row">
              <div class="col-12 mb-4">
                <span class="titsans">{{ retoInfo.reto }}</span>
              </div>
              <div class="col-12">
                <strong>Descripción:</strong>
              </div>
              <div class="col-12 mb-2">
                <p>{{ retoInfo.descripcion }}</p>
              </div>
              <div class="col-12">
                <strong>Objetivo del reto:</strong>
              </div>
            </div>
            <div class="col-12 mb-2">
              <p>{{ retoInfo.objetivo }}</p>
            </div>
            <div class="col-12">
              <strong>Idioma:</strong>
            </div>
            <div class="col-12 mb-2">
              <span>{{ lbl_idiomas }}</span>
            </div>

            <div class="col-12">
              <strong>Semestre:</strong>
            </div>
            <div class="col-12 mb-2">
              <span>{{ lbl_semestre }}</span>
            </div>

            <div class="col-12">
              <strong>Duración:</strong>
            </div>
            <div class="col-12 mb-2">
              <span>{{ retoInfo.semanas }}</span>
            </div>

            <div class="col-12">
              <strong>Periodo Semanal:</strong>
            </div>
            <div class="col-12 mb-2">
              <span>{{ lbl_periodoSemanal }}</span>
            </div>

            <div class="col-12">
              <strong>Periodo Semestral:</strong>
            </div>
            <div class="col-12 mb-2">
              <span>{{ lbl_periodoSemestral }}</span>
            </div>

            <div class="col-12">
              <strong>Entregable:</strong>
            </div>
            <div class="col-12 mb-2">
              <span>{{ retoInfo.entregable }}</span>
            </div>

            <div class="col-12" >
              <strong>Programas Académicos:</strong><br>
              <span *ngFor="let paa of retoInfo.programasAcademicos">
                {{ paa.clave }} - {{ paa.programaAcademico }}<br/>
              </span>
            </div>

            <div class="row" *ngIf="false">
              <div class="col d-flex justify-content-center">
                <app-button extraClass="">Crear nuevo reto a partir de existente</app-button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Perfil del socio formador sugerido -->
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-12">
            <span class="question-title-text">Acerca del Socio Formador</span>
          </div>
        </div>


        <div class="col bkgatomic" *ngIf="!retoInfo">
          <span class="titatomic">Selecciona un reto para ver su información
          </span>
        </div>

        <div class="col bkgatomic" *ngIf="retoInfo">
          <div class="row mb-5">
            <div class="col">
              <img class="imgpchip_med" alt="Reto" src="assets/svg/icon_info.svg" />&nbsp;
              <span class="titatomic">
                <strong>Perfil del Socio Formador Sugerido</strong>
              </span>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="col-12">
                <strong>Sector:</strong>
              </div>
              <div class="col-12 mb-2" *ngFor="let reto of retoInfo.sectores">
                <p>{{ reto.sector }}</p>
              </div>
            </div>
            <div class="col-6">
              <div class="col-12" >
                <strong>Tamaño de Empresa:</strong>
              </div>
              <div class="col-12 mb-2" *ngFor="let tamano of retoInfo.tamanos">
                <p>{{ tamano.tamano }}</p>
              </div>
            </div>
            <div class="col-6">
              <div class="col-12">
                <strong>Industria:</strong>
              </div>
              <div class="col-12 mb-2" *ngFor="let indu of retoInfo.industrias">
                <p>{{ indu.industria }}</p>
              </div>
            </div>
            <div class="col-6">
              <div class="col-12">
                <strong>Presencia:</strong>
              </div>
              <div class="col-12 mb-2" *ngFor="let presencia of retoInfo.presencias">
                <p>{{ presencia.presencia }}</p>
              </div>
            </div>


            <div class="col-12">
              <strong>Características:</strong>
            </div>
            <div class="col-12 mb-2">
              <p> {{ retoInfo.palabrasClave||'Sin descripción'}} </p>
            </div>

          </div>



          <div class="row mb-5">
            <div class="col-md-12">
              <span class="question-text1">Acerca del Socio Formador requerido: <span class="requerido">*</span></span>
            </div>

            <div class="requerido" *ngIf="mostrarRequeridos && vinculacionForm.get('palabrasClave')?.errors?.['required']">
              Este campo es requerido. Debes capturar al menos 5 palabras.
            </div>
            <div class="col-12">
              <textarea class="forma_area"
                [placeholder]="'Con al menos 5 palabras, cuéntanos más sobre el Socio Formador que estás buscando.'" formControlName="palabrasClave">
              </textarea>
            </div>

          </div>


          <div class="row">
            <div class="col-12">
              <app-button ngForm buttonType="yell"
                  extraClass="btn-full"
                  [isDisabled]="buttonSave.isLoading">
                {{ buttonSave.title }}
              </app-button>
            </div>
          </div>

        </div>

      </div>

    </div>
  </form>
</div>
