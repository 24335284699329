<app-header-buscador lbl_placeholder="Buscar Socio Formador" (fatherNameFunction)="buscadorPress($event)">
</app-header-buscador>
<div>

  <app-title-show-more [showMoreObject]="{title:'academia_banco_socio_formador.titulo' | translate} ">
  </app-title-show-more>

  <div id="sec_tab">

    <div class="row ">

      <div class="col-md text-center mb-4 ">
        <div class="btn-group order-by">
          <button class="btn dropdown-toggle" type="button" id="listado" data-bs-toggle="dropdown"
            data-bs-auto-close="true" aria-expanded="false" data-bs-offset="0,0">
            {{ textSector }}
          </button>
          <ul class="dropdown-menu dropdown-menu-start" aria-labelledby="listado">
            <li *ngFor="let sector of sectorLista" (click)="selectFilter('sector', sector.idSector)">
              <a class="dropdown-item" [id]="sector.idSector" [class.active]="sector.idSector == selectSector">{{
                sector.sector }}</a>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-md text-center mb-4">
        <div class="btn-group order-by">
          <button class="btn dropdown-toggle" type="button" id="listado" data-bs-toggle="dropdown"
            data-bs-auto-close="true" aria-expanded="false" data-bs-offset="0,0">
            {{ textIndustria }}
          </button>
          <ul class="dropdown-menu dropdown-menu-start" aria-labelledby="listado">
            <li *ngFor="let industria of industriaList" (click)="selectFilter('industria', industria.idIndustria)">
              <a class="dropdown-item" [id]="industria.idIndustria"
                [class.active]="industria.idIndustria == selectIndustria">{{ industria.industria }}</a>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-md text-center mb-4">
        <div class="btn-group order-by">
          <button class="btn dropdown-toggle" type="button" id="listado" data-bs-toggle="dropdown"
            data-bs-auto-close="true" aria-expanded="false" data-bs-offset="0,0">
            {{ textEstado }}
          </button>
          <ul class="dropdown-menu dropdown-menu-start" aria-labelledby="listado">
            <li *ngFor="let estado of estadosList" (click)="selectFilter('estado', estado.claveEstado)">
              <a class="dropdown-item" [id]="estado.claveEstado" [class.active]="estado.claveEstado == selectEstado">{{
                estado.estado }}</a>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-md text-center mb-4">
        <div class="btn-group order-by">
          <button class="btn dropdown-toggle" type="button" id="listado" data-bs-toggle="dropdown"
            data-bs-auto-close="true" aria-expanded="false" data-bs-offset="0,0">
            {{ textTamano }}
          </button>
          <ul class="dropdown-menu dropdown-menu-start" aria-labelledby="listado">
            <li *ngFor="let tamanoEmpresa of tamanosEmpresaList"
              (click)="selectFilter('tamano_empresa', tamanoEmpresa.idTamanoEmpresa)">
              <a class="dropdown-item" [id]="tamanoEmpresa.idTamanoEmpresa"
                [class.active]="tamanoEmpresa.idTamanoEmpresa == selectTamano">{{ tamanoEmpresa.tamanoEmpresa }}</a>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-md text-center mb-4">
        <div class="btn-group order-by">
          <button class="btn dropdown-toggle" type="button" id="listado" data-bs-toggle="dropdown"
            data-bs-auto-close="true" aria-expanded="false" data-bs-offset="0,0">
            {{ textCampus }}
          </button>
          <ul class="dropdown-menu dropdown-menu-start" aria-labelledby="listado">
            <li *ngFor="let campus of campusList" (click)="selectFilter('campus', campus.idCampus)">
              <a class="dropdown-item" [id]="campus.idCampus" [class.active]="campus.idCampus == selectCampus">{{
                campus.campus }}</a>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-md text-center mb-4"><button class="blue_button" (click)="restablecer()">{{
          'academia_banco_socio_formador.btn_restablecer' | translate }}</button></div>
    </div>

    <button class="btn-blue" (click)="agregarSocioFormador()" *ngIf="MostrarBotonAgregarSocioFormador">Agregar</button>

    <div class="row mt-4 mb-4"
      *ngIf="palabraBusqueda !== null && palabraBusqueda !== undefined && palabraBusqueda.trim() !== ''">
      <p class="textResultadoBusqueda"> {{ 'academia_banco_socio_formador.resultado_busqueda' | translate }} <label
          class="textBusqueda"> {{ palabraBusqueda }}
        </label> </p>
    </div>
  </div>

  <div class="row fondocard_h" *ngFor="let socioFormador of socioFormadorActualesList">
    <div class="row ">

      <!-- logotipo -->
      <div class="col-sm-2 d-flex justify-content-center">
        <img class="imgcarrousel_izq" alt="Logotipo" src="{{ socioFormador.urlLogo }}">
      </div>

      <!-- Datos del socio formador -->
      <div class="col-sm-8">
        <div class="row">
          <div class="col colmatch mt-2">
            <div class="titsans">{{ socioFormador.nombreSocioFormador }}</div>
            <div class="sub-titsans">{{ socioFormador.razonSocial }}</div>
            <div *ngIf="MostrarEvaluacion">
              <app-evaluacion [evaluacion]="socioFormador.evaluacion" [evaluadores]="socioFormador.evaluadores" [clavePerfil]="clavePerfil" ></app-evaluacion>
            </div>
            <hr style="color: #BEDCFE">
            <div class="sub-titsans" title="{{ socioFormador.empresa }}">{{ socioFormador.empresaShort }}</div>

          </div>
        </div>
      </div>

      <!-- botonera -->

      <div class="col-sm-2 mt-3">
        <div class="row">

          <!-- Btn de compartir url -->
          <div class="col-12 text-center">

            <button class="btn-blue-share" (click)="generarShareUrl(socioFormador)"
              (cdkCopyToClipboardCopied)="copiado()">
              <svg ngbTooltip="Compartir" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                class="bi bi-share" viewBox="0 0 16 16">
                <path
                  d="M13.5 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5zm-8.5 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm11 5.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3z" />
              </svg>
            </button>
          </div>

        </div>
        <div class="row mt-4">
          <div class="col-4">
            <!-- Inactivar -->
            <div class="# mt-2 text-end" *ngIf="MostrarBotonInactivar && socioFormador.claveEstatus=='activo'">
              <svg ngbTooltip="Inactivar" (click)="inactivarSocioFormador(socioFormador.idSocioFormador)"
                xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#A8DF9D" class="bi bi-toggle-on"
                viewBox="0 0 16 16">
                <path d="M5 3a5 5 0 0 0 0 10h6a5 5 0 0 0 0-10H5zm6 9a4 4 0 1 1 0-8 4 4 0 0 1 0 8z" />
              </svg>

              <button (click)="inactivarSocioFormador(socioFormador.idSocioFormador)" class="red_button" *ngIf="false">{{
              'academia_banco_socio_formador.inactivar_socio_formador' | translate }}</button>
            </div>

            <!-- Activar -->
            <div class="# mt-2 text-end" *ngIf="MostrarBotonInactivar && socioFormador.claveEstatus=='inactivo'">
              <svg ngbTooltip="Activar" (click)="activarSocioFormador(socioFormador.idSocioFormador)"
                xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#F06067" class="bi bi-toggle-off"
                viewBox="0 0 16 16">
                <path
                  d="M11 4a4 4 0 0 1 0 8H8a4.992 4.992 0 0 0 2-4 4.992 4.992 0 0 0-2-4h3zm-6 8a4 4 0 1 1 0-8 4 4 0 0 1 0 8zM0 8a5 5 0 0 0 5 5h6a5 5 0 0 0 0-10H5a5 5 0 0 0-5 5z" />
              </svg>

              <button (click)="activarSocioFormador(socioFormador.idSocioFormador)" class="green_button" *ngIf="false">{{
              'academia_banco_socio_formador.activar_socio_formador' | translate }}</button>
            </div>
          </div>
          <div class="col-4">
            <!-- Editar -->
            <div class="# mb-3" *ngIf="mostrarBotonEditar(socioFormador.idUsuarioRegistro)">
              <img alt="" ngbTooltip="Editar" (click)="editarSocioFormador(socioFormador.idSocioFormador)"
                src="assets/img/editar.svg" class="btn_editar">
              <button (click)="editarSocioFormador(socioFormador.idSocioFormador)" class="yell_button" *ngIf="false">{{
              'academia_banco_socio_formador.editar_socio_formador' | translate }}</button>
            </div>
          </div>
          <!-- Aqui va el nuevo logo -->
          <div class="col-4">
            <!-- Inactivar -->
            <div class="# mt-2 text-end" *ngIf="MostrarBotonInactivar && !socioFormador.verLogoHome" >
              <button class="icon_home" (click)="mostrarHome(socioFormador,true)"><img class="image_home"
                  alt="Editar" src="assets/img/home2.png"></button>

            </div>


            <div class="# mt-2 text-end" *ngIf="MostrarBotonInactivar && socioFormador.verLogoHome" >
              <button (click)="mostrarHome(socioFormador,false)" class="icon_home"><img class="image_home"
                  alt="Editar" src="assets/img/home.png">
              </button>

            </div>
          </div>


        </div>
      </div>



    </div>

    <div class="row top10px" *ngIf="MostrarBotonDetalle">


      <div class="col-sm-8  offset-sm-2">
        <mat-chip-list class="mat-chip-list-stacked1">
          <mat-chip>Página Web: {{ socioFormador.paginaWeb||'' }}</mat-chip>
          <mat-chip>Convenio firmado en: {{ socioFormador.campus||'' }}</mat-chip>
        </mat-chip-list>
      </div>

      <!-- botón de más información -->
      <div class="col-md-2 ">
        <span class="#"><button (click)="verSocioFormador(socioFormador.idSocioFormador)" class="yell_button">{{
            'academia_banco_socio_formador.btn_mas_info' | translate }}</button></span><br>
      </div>

    </div>

  </div>

  <div class="col d-flex justify-content-center" *ngIf="socioFormadorActualesMovilList.length === 0">
    <span class="questioncard">No hay Socios Formadores</span>
  </div>


  <app-pagination *ngIf="socioFormadorList.length" [objPagination]="socioFormadorList"
    (paginationDataChange)="showPaginationData($event)" [recordsPerPage]="20"></app-pagination>


</div>