import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import Utils from "@shared/helpers/utils";
import { RetosService } from "@shared/service/Retos/retos.service";
import { UsuariosService } from "@shared/service/Usuarios/usuarios.service";
import { lastValueFrom } from "rxjs";
import Swal from "sweetalert2";

@Component({
  selector: "app-reto-compromisos",
  templateUrl: "./reto-compromisos.component.html",
  styleUrls: ["./reto-compromisos.component.css"],
})
export class RetoCompromisosComponent implements OnInit {
  compromisosForm: FormGroup;
  compromisosList: any[] = [];
  compromisosCount: number = 0;
  clavePerfil: string = "";

  @Input() idReto: number;
  @Input() status: string;
  @Output() compromisos: EventEmitter<any[]> = new EventEmitter<any[]>();
  @ViewChild('closebuttonCompromiso') closebutton;

  retoPlace: any = {
    descripcion:
      "Escribe aquí el primer compromiso por parte del Socio Formador.",
    momento: "Escribe aquí en qué momento requires la actividad",
  };

  constructor(
    private fb: FormBuilder,
    private retosService: RetosService,
    private usuarioService: UsuariosService
    ) {}

  ngOnInit(): void {
    this.compromisosForm = this.fb.group({
      idReto: [this.idReto],
      momento: ["", Validators.required],
      descripcion: ["", Validators.required],
    });

    this.clavePerfil = this.usuarioService.getCurrentUserData().clavePerfil;
    this.getCompromisos();
  }

  async addCompromiso() {
    try {
      this.compromisosForm.markAllAsTouched();
      if (this.compromisosForm.valid) {
        this.compromisosCount = this.compromisosCount + 1;
        this.compromisosList.push(this.compromisosForm.value);
        //Guardar
        let request = {
          idReto: this.idReto,
          compromiso: this.compromisosForm.get('descripcion')?.value,
          temporalidad: this.compromisosForm.get('momento')?.value
        }
        await lastValueFrom(
          this.retosService.postRetoCompromiso(request)
        );
        //AL obtener repuesta
        this.compromisosForm.patchValue({ momento: '', descripcion: '' });
        this.getCompromisos();
        //this.compromisosForm.patchValue({ entregables: this.compromisosList });
        this.compromisos.emit(this.compromisosList);
        this.compromisosForm.reset();
        this.closebutton.nativeElement.click();
      }
    } catch (error) {
      this.closebutton.nativeElement.click();
      console.log(error);
    }
  }

  async getCompromisos() {
    this.compromisosList = [];
    try {
      let res = await lastValueFrom(
        this.retosService.getAllRetoCompromiso(this.idReto)
      );
      this.compromisosList = res.map(x =>{ return {
        idReto: x.idReto,
        idRetoCompromiso: x.idRetoCompromiso,
        momento: x.temporalidad,
        descripcion: x.compromiso
      };});
    } catch (error:any) {
      Utils.processErrorResponse(error);
    }
  }

  eliminar(idReto: number, idRetoCompromiso: number) {
    Swal.fire({
      text: "¿Desea eliminar el compromiso?",
      confirmButtonText: "Si",
      cancelButtonText: "No",
      showCancelButton: true,
    }).then(async (response) => {
      try {
        if (response.isConfirmed) {
          await lastValueFrom(
            this.retosService.deleteRetoCompromiso(idReto, idRetoCompromiso)
          );
          await this.getCompromisos();
        }
      } catch (error) {
        console.log(error);
      }
    });
  }
}
