<app-header-buscador [showSearchBar]="false"></app-header-buscador>

<app-typeform idForm="H4LxUk35" *ngIf="solicitudAceptada" ></app-typeform>


<div class="sec_ad">

  <div class="row">

    <div class="col-12">
      <app-title-show-more [showMoreObject]="titleSolicitud"></app-title-show-more>
    </div>

  </div>

  <app-btn-regresar></app-btn-regresar>


  <div class="row">

    <!-- Estatus de la solicitud -->
    <div class="col-12 mt-3">
      <span class="subtit1col mt-3 mb-1 ms-2"><strong>Estatus de la solicitud</strong></span>
      <div class="" *ngIf="!edit">
        <div class="col-12 title-creacion border">
          <img alt="" src="assets/svg/download.svg" style="width: 1.5rem;" />
          <span class="ms-2"> <b></b> Esta solicitud fue recibida el día
            {{ lblFechaRegistro }}</span>
        </div>
      </div>
    </div>

    <!-- alertas -->
    <div class="col-12" *ngIf="problematica.claveEstatus === 'revision'">
      <div class="col bkgatomic_alert_a">
        <img class="imgpchip_med" alt="en revisión" src="assets/img/icon_alert_porterminar.svg">&nbsp;<span
          class="titatomic_r"><strong>Tu
            solicitud se encuentra en revisión</strong></span>
      </div>
    </div>

    <div class="col-12" *ngIf="problematica.claveEstatus === 'cancelado'">
      <div class="col bkgatomic_alert_r">
        <img class="imgpchip_med" alt="Cancelado" src="assets/img/icon_alert_porterminar.svg">&nbsp;<span
          class="titatomic_r"><strong>Tu
            solicitud se encuentra cancelada</strong></span>
      </div>
    </div>

    <div class="col-12" *ngIf="problematica.claveEstatus === 'rechazado'">
      <div class="col bkgatomic_alert_r">
        <img class="imgpchip_med" alt="Rechazado" src="assets/img/icon_alert_porterminar.svg">&nbsp;<span
          class="titatomic_r"><strong>Tu
            solicitud se encuentra rechazada</strong></span>
      </div>
    </div>

    <div class="col-12" *ngIf="problematica.claveEstatus === 'aprobado'">
      <div class="col bkgatomic_alert_ap">
        <img class="imgpchip_med" alt="Aprobado" src="assets/img/icon_alert_si.svg">&nbsp;<span
          class="titatomic_r"><strong>Tu
            solicitud esta aprobada</strong></span>
      </div>
    </div>


    <!-- Ver comentarios -->
    <div class="col-12">

      <mat-accordion>
        <mat-expansion-panel class="my-2 px-3" (opened)="panelOpenState = true" (closed)="panelOpenState = false">

          <mat-expansion-panel-header class="my-4 ">
            <div class="">
              <span class="title-option">Ver comentarios </span><br />

            </div>
          </mat-expansion-panel-header>

          <div class="maxHeight">
            <div class="col-12" *ngFor="let com of comentariosData">
              <div>
                Fecha: {{com.fechaRegistro}}<br />
                Estatus: {{obtenerEstatusSeguimiento(com.claveEstatus)}}<br />
                Comentarios: {{ com.comentarios}}
              </div>
              <hr>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>

    </div>

    <!-- Formulario de Comentarios -->
    <div class="col-12">
      <div class="bkgatomic_alert_w">
        <form [formGroup]="estatusSolForm" (submit)="guardarComentarios()">
          <div class="row">
            <div class="col-6">
              <div class="col-12">
                <span
                *ngIf="!['rechazado','aprobado'].includes(solicitud.claveEstatus)"
                class=""><strong>Selecciona el estatus de la solicitud</strong></span>
                <select
                *ngIf="!['rechazado','aprobado'].includes(solicitud.claveEstatus)"
                class="form-select mb-4" name="status" id="status" formControlName="estatus">
                  <option value="revision">En revisión</option>
                  <option value="registrado">Recibido</option>
                </select>
              </div>

              <div class="col-12">
                <textarea style="border-radius: 5px; width: 100%;" formControlName="comentario" rows="4"
                  placeholder='Escribe un comentario sobre el estatus de la solicitud por ejemplo "Ya se contactó al profesor coordinador del reto", etc.'
                  required></textarea>
              </div>
            </div>
            <div class="col-6 justify-content-end d-flex">
              <div >
              <button class="btn yell_button_small_w">Guardar</button>
            </div>
            </div>
          </div>
        </form>
      </div>
    </div>



    <!-- Información del Socio Formador -->
    <div class="mt-3 mb-1">
      <div class="row">

        <div class="col-9 d-flex align-items-end">
          <span class="subtit1col ms-2"><strong>Socio Formador que solicita la vinculación</strong></span>
        </div>

        <div class="col-3">
          <!-- Editar -->
          <div class="mb-3" *ngIf="['cvdp','cvdp_admin'].includes(userData.clavePerfil)">
            <button class="yell_button" (click)="editar()"> <img alt="" src="assets/img/editar.svg" class="btn_editar">{{
              'academia_banco_socio_formador.editar_socio_formador' | translate }} Solicitud</button>
          </div>
        </div>

      </div>
    </div>

    <div class="col-12">
      <div class="bkgatomic_alert_w">
        <div class="row col-12">
          <div class="col-2 p-2">
            <img class="img-socio" alt="Aprobado" src="{{socioFormador.urlLogo}}">

          </div>
          <div class="col-10 mt-4">
            <b> <u> <span class="titsans_contact_b"> {{socioFormador.nombreSocioFormador}} </span> </u></b> <br>
            <span class="subtgris"> {{socioFormador.empresa}} <br></span>
          </div>
        </div>

        <div class="col-12 mt-1">
          <div class="title-option">
            Persona de contacto:
          </div>
          <div class="ms-2">
            {{ problematica.nombreContacto }}
            <br />
            {{ problematica.email }}
          </div>
        </div>
        <div class="col-12 mt-4">
          <div class="title-option">
            Campus de atención del Socio Formador:
          </div>
          <div class="ms-2">
            {{socioFormador.campus}}
          </div>
        </div>
      </div>
    </div>



    <!-- Información de la búsqueda del algoritmo -->
    <div class="mt-4 mb-1">
      <span class="subtit1col ms-2" *ngIf="!edit"><strong>Información descrita en la búsqueda a través del
          algoritmo</strong></span>
      <span class="subtit1col ms-2" *ngIf="edit"><strong>Medio por el cual se realizó la búsqueda de reto
          </strong></span>
    </div>

    <div class="col-12">
      <div class=" bkgatomic_alert_w">
        <h3 class="mb-2 title-option" *ngIf="edit">Algoritmo</h3>
        <div class="col-12 mt-1">
          <div class="title-option">
            Áreas de la empresa/organización que están involucradas:
          </div>
          <div class="ms-2">
            {{problematica.areas}}.
          </div>
        </div>
        <div class="col-12 mt-4">
          <div class="title-option">
            Alcance de la problemática o tema de interés:
          </div>
          <div class="ms-2">
            {{problematica.alcance}}
          </div>
        </div>
        <div class="col-12 mt-4">
          <div class="title-option">
            Nombre de la problemática o tema de interés:
          </div>
          <div class="ms-2">
            {{problematica.proyecto}}
          </div>
        </div>

        <div class="col-12 mt-4">
          <div class="title-option">
            Contexto de la problemática o tema de interés:
          </div>
          <div class="ms-2">
            {{problematica.problematica}}
          </div>
        </div>
        <div class="col-12 mt-4">
          <div class="title-option">
            Objetivos que el Socio Formador busca lograr con el acompañamiento académico:
          </div>
          <div class="ms-2">
            {{problematica.objetivos}}
          </div>
        </div>
      </div>
    </div>



    <!-- Información de la vinculación -->
    <div class=" mt-4 mb-1">
      <span class="subtit1col ms-2"><strong>Sobre la vinculación</strong></span><br />
    </div>

    <div class="col-12">
      <div class="bkgatomic_alert_w" *ngIf="!edit">

        <div class="col-12 mt-1">
          <div class="title-option">
            Escuela con la que te gustaría trabajar:
          </div>
          <div class="ms-2">
            {{ problematica.escuela }}
          </div>
        </div>
        <div class="col-12 mt-4">
          <div class="title-option">
            Programas académicos que te interesan que participen:
          </div>
          <div class="ms-2">
            {{ lbl_programasAcademicos }}
          </div>
        </div>
        <div class="col-12 mt-4">
          <div class="title-option">
            De que semestre son los alumnos con los que te gustaría trabajar:
          </div>
          <div class="ms-2">
            {{ problematica.semestre }}
          </div>
        </div>

        <div class="col-12 mt-4">
          <div class="title-option">
            Periodo semestral en el que te gustaría vincularte:
          </div>
          <div class="ms-2">
            {{problematica.periodo}}
          </div>
        </div>
        <div class="col-12 mt-4">
          <div class="title-option">
            Campus donde te gustaría colaborar:
          </div>
          <div class="ms-2">
            {{ lbl_campus }}
          </div>
        </div>
        <div class="col-12 mt-4">
          <div class="title-option">
            Campus donde te gustaría llevar la comunicación:
          </div>
          <div class="ms-2">
            {{ lbl_campus_coordinador }}
          </div>
        </div>
      </div>

      <div class="bkgatomic_alert_w" *ngIf="edit">
        <form [formGroup]="problematicaForm" autocomplete="off">

          <div class="row questions-container open-text">
            <div class="col-sm-12 col-lg-12 ">
              <div class="row">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-12 ">
                      <label for="idEscuela" class="question-text mt-1 mb-1">Escuela con la que te gustaría trabajar
                        <span class="text-danger">*</span></label>
                      <select class="form-select" (change)="onSelectEscuela($event)" formControlName="idEscuela">
                        <option value="">Selecciona una opción</option>
                        <option *ngFor="let escuela of escuelasData" value="{{ escuela.idEscuela }}">
                          {{ escuela.escuela }}
                        </option>
                      </select>
                      <div class="text-danger"
                        *ngIf="problematicaForm.get('idEscuela')?.invalid && problematicaForm.get('idEscuela')?.touched">
                        El campo es requerido. Escuela
                      </div>
                    </div>
                  </div>

                  <div class="row ">
                    <!-- Programas -->
                    <div class="col-md-12 mt-4 mb-1">
                      <span class="question-text">Programas académicos que te interesa que participen:</span>
                    </div>
                    <div class="col-md-12">
                      <span class="answer-title-text text-secondary">Selecciona una o más opciones</span>

                      <div class="row" *ngIf="programasAcademicosData">
                        <div class="row offset-1 text-danger"
                          *ngIf="programasAcademicosData.length == 0 && problematicaForm.get('programasAcademicos')?.touched">
                          No hay programas asociados, por favor selecciona otra escuela.
                        </div>
                        <div class="col col-md-6" *ngFor="let programa of programasAcademicosData">

                          <input type="checkbox" class="btn-check"
                            id="{{ programa.idProgramaAcademico }}-{{programa.programaAcademico}}" autocomplete="off"
                            formArrayName="programasAcademicos" value="{{ programa.idProgramaAcademico }}"
                            (change)="onCheckboxChange($event, 'programasAcademicos')"
                            [checked]="verifyChecked(programa.idProgramaAcademico, 'programasAcademicos')" />

                          <label class="btn btn-outline-primary"
                            for="{{ programa.idProgramaAcademico }}-{{programa.programaAcademico}}">
                          </label>

                          <span class="text-programaAcademico">{{ programa.programaAcademico }}</span>

                        </div>
                      </div>
                    </div>

                    <!-- Semestre -->
                    <div class="col-md-12">
                      <label for="periodo" class="question-text mt-4 mb-1"> De qué semestre son los alumnos con los que
                        te
                        gustaría
                        trabajar<span class="text-danger">*</span></label>
                      <select class="form-select" id="semestre" formControlName="semestre">
                        <option value="">Selecciona una opción</option>
                        <option *ngFor="let semestre of semestreData" value="{{ semestre }}">
                          {{ semestre }}
                        </option>
                      </select>
                      <div class="text-danger"
                        *ngIf="problematicaForm.get('semestre')?.invalid && problematicaForm.get('semestre')?.touched">
                        El campo es requerido. Semestre
                      </div>
                    </div>

                    <!-- Periodo -->
                    <div class="col-md-12 mt-4 mb-1">
                      <span class="question-text ">
                        {{ 'pagina_retos_detalles.selecciona_periodo_semestral' | translate }}
                      </span>

                      <select class="form-select " id="idPeriodo" name="idPeriodo" formControlName="idPeriodo"
                        (change)="seleccionarPeriodo($event)" placeholder="Selecciona un periodo">
                        <option value="">Seleccione una opción</option>
                        <option *ngFor="let el of this.periodoAcademicoData" value="{{ el.idPeriodo }}">
                          {{ el.periodo }}
                        </option>
                      </select>

                      <div class="text-danger" *ngIf="pedirPeriodoAcademico">
                        Este campo es requerido
                      </div>
                    </div>

                    <!-- Campus donde se lleva el reto -->
                    <div class="col-md-12 ">

                      <div class="row">

                        <div class="col-md-12 mt-4 mb-1">
                          <span class="question-text">
                            Campus donde se llevará acabo el reto
                            <span class="text-danger">*</span>
                          </span>
                        </div>

                        <div class="text-danger" *ngIf="pedirListaCampus">
                          Este campo es requerido
                        </div>

                        <div class="col-md-12 espacio">
                          <span class="text-muted">Selecciona una o más opciones</span>
                          <div class="row">
                            <div class="col-md-12 answer-text">
                              <div class="row">
                                <div class="col-6">
                                  <div class="col col-md-12" *ngFor="let campus of seccion1">
                                    <input type="checkbox" class="btn-check"
                                      id="{{ campus.idCampus}}-{{ campus.campus }}" autocomplete="off"
                                      value="{{ campus.idCampus}}" [checked]="verifyChecked(campus.idCampus, 'campus')"
                                      (change)="onCheckboxChange($event, 'campus')" />
                                    <label class="btn btn-outline-primary"
                                      for="{{ campus.idCampus}}-{{ campus.campus }}"></label>
                                    <span>{{ campus.campus }}</span>
                                  </div>
                                </div>

                                <div class="col-6">
                                  <div class="col col-md-12" *ngFor="let campus of seccion2">
                                    <input type="checkbox" class="btn-check"
                                      id="{{ campus.idCampus}}-{{ campus.campus }}" autocomplete="off"
                                      value="{{ campus.idCampus}}" [checked]="verifyChecked(campus.idCampus, 'campus')"
                                      (change)="onCheckboxChange($event, 'campus')" />

                                    <label class="btn btn-outline-primary"
                                      for="{{ campus.idCampus}}-{{ campus.campus }}"></label>
                                    <span>{{ campus.campus }}</span>
                                  </div>
                                </div>
                              </div>

                            </div>

                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- Campus Coordinador -->
                    <div class="col-md-12">
                      <label for="campus" class="question-text mt-4 mb-1">Campus donde se gestionará la vinculación
                        <span class="text-danger">*</span></label>
                      <select class="form-select" name="campusCoordinador" id="campusCoordinador"
                        formControlName="campusCoordinador">
                        <option value="" selected>Selecciona una opción</option>
                        <option *ngFor="let campus of selectedCampus" value="{{ campus.idCampus}}">
                          {{ campus.campus }}
                        </option>
                      </select>
                    </div>
                  </div>


                  <div class="row mt-5">
                    <div class="col-12 d-flex justify-content-center">
                      <button type="button" class="btn yell_button_small_w mx-2" (click)="guardarEdicion()">
                        Guardar </button>
                      <button type="button" class="btn blue_button_small mx-2" (click)="cancelarEdicion()">
                        Cancelar </button>
                    </div>

                  </div>
                  <div class="row">
                    <div class="col">
                      <div class="row" *ngIf="validationErrors.length > 0">
                        <ul class="text-danger">
                          <li *ngFor="let error of validationErrors">{{error}}</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </form>
      </div>
    </div>


    <!-- botonera -->
    <div class="col-12 mt-4" *ngIf="!edit">
      <div class="col-12 d-flex justify-content-center">
        <div class="col-2 mx-2">
          <span class="#"><button class="green_button" (click)="handleAceptar()">
              <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 15.2 11.06"
                class="iCn replaced-svg">
                <defs>
                  <style>
                    .cls-1 {
                      fill: #4c964d;
                    }
                  </style>
                </defs>
                <path class="cls-1"
                  d="M5.28,13.71a1.74,1.74,0,0,1-.64-.13,1.65,1.65,0,0,1-.54-.36L.44,9.56l1.4-1.39,3.44,3.44,9-9L15.64,4,6.47,13.22a1.69,1.69,0,0,1-.55.36A1.74,1.74,0,0,1,5.28,13.71Z"
                  transform="translate(-0.44 -2.65)"></path>
              </svg>
              Resolver
            </button></span>
        </div>
        <div class="col-2 mx-2">
          <span class="#"><button class="blue_button" (click)="abrirFormularioRechazo()">

              Reasignar
            </button></span><br />
        </div>

      </div>
    </div>


    <div class="row Re-Asignar_Academico" *ngIf="showFormularioCancelacion">
      <div class="col mt-4">
        <div>
          <div class="title-option">Campus asignado:</div>
        </div>

        <div *ngIf="solicitud !== undefined && ['revision','registrado'].includes(solicitud.claveEstatus)">

          <ng-select id="campusReasginar" name="campusReasginar" (change)="seleccionarCampus($event)" bindLabel="campus"
            bindValue="idCampus" [items]="campusData" placeholder="Selecciona un campus ... "></ng-select>


        </div>
      </div>

      <div class="row mt-5">
        <app-button buttonType="yell" (callbackAction)="enviarSolicitud()">Enviar</app-button>
      </div>

    </div>

    <!-- Formulario de Cancelación/Reasignación DEPRECATED -->
    <div class="row" *ngIf="false">
      <div class="col-md-10">
        <div class="container-motivos-rechazo">
          <div class="row">
            <div class="col-md-12 mt-4">
              <span class="txt-option">{{ "solicitud_sf_potencial-rechazo.area_canalizada" | translate }}</span><br />
              <span class="little-text-option">
                {{ "solicitud_sf_potencial-rechazo.label_seleccionar_opciones" | translate }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-12">

              <div class="col-12">
                <div class="col col-md-12" *ngFor="let area of areasCanalizacionList">
                  <input type="checkbox" class="btn-check"
                    id="{{ area.idAreaCanalizacion }}-{{ area.areaCanalizacion }}" autocomplete="off"
                    value="{{ area.idAreaCanalizacion }}"
                    [checked]="verifyChecked(area.idAreaCanalizacion, 'idAreaCanalizacion')"
                    (click)="seleccionarCheck(area.idAreaCanalizacion)" />

                  <label class="btn btn-outline-primary"
                    for="{{ area.idAreaCanalizacion }}-{{ area.areaCanalizacion }}"></label>
                  <span>{{ area.areaCanalizacion }}</span>
                </div>
              </div>

            </div>
          </div>
          <div class="row" *ngIf="areaCanalizacionRequerido.trim().length > 0">
            <div class="col-md-12">
              <p class="mt-4 mensaje-requerido">{{ '*' + areaCanalizacionRequerido }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-lg-10 col-xl-6 col-sm-12 col-12 mt-4">
              <span class="txt-option">{{ "solicitud_sf_potencial-rechazo.motivo_rechazo_solicitud" | translate
                }}</span><br /><br />
              <textarea style="border-radius: 5px; width: 100%;" rows="7" [(ngModel)]="motivosRechazo"
                [placeholder]="'solicitud_sf_potencial-rechazo.placeholder_detalles_rechazo' | translate "></textarea>
            </div>
          </div>
          <div class="row" *ngIf="motivosRequeridos.trim().length > 0">
            <div class="col-md-12">
              <p class="mt-2 mensaje-requerido">{{ '*' + motivosRequeridos }}</p>
            </div>
          </div>
          <div class="row botones-accion">
            <div class="col-auto mt-4" *ngIf="false">
              <button class="revertir_button" (click)="revertirCancelacion()">
                {{ "solicitud_sf_potencial-rechazo.btn_revertir_rechazo" | translate }}</button>
            </div>
            <div class="col-auto mt-4">
              <button class="enviar_button" (click)="handleRechazar()">
                {{ "solicitud_sf_potencial-rechazo.btn_enviar" | translate }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Resumen rechazado -->
    <div class="row mt-4" *ngIf="solicitud != undefined && solicitud.claveEstatus.trim() === 'rechazado'">
      <div>
        <label class="subtitleseccard mb-2">{{ "solicitud_sf_potencial-rechazo.motivo_rechazo_title" | translate
          }}</label>
      </div>
      <div class="col-md-10">
        <div class="container-contacto-enlace">
          <div class="row">
            <div class="col-md-12 mt-4">
              <span class="title-option">{{ "solicitud_sf_potencial-rechazo.area_canalizada_seleccionadas" | translate
                }}</span><br />
              <span class="txt-option"> {{ solicitud.areaCanalizacion }} </span>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 mt-4">
              <span class="title-option">{{ "solicitud_sf_potencial-rechazo.detalles_rechazo_solicitud" | translate
                }}</span><br />
              <span class="txt-option"> {{ solicitud.motivoRechazo }} </span>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!-- Mostrar como Atendido y guardar su solución -->
    <div class="modal fade" id="idModalResolver" tabindex="-1" aria-labelledby="ModalCorreoExistente"
      aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content p-3">
          <div class="modal-header">
            <h5 class="modal-title-resolver" id="ModalCorreoExistente"><b> Indica que sucedió con ésta problemática de
                un
                Socio Formador que no encontró reto:</b></h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">

            <form (submit)="guardarRespuesta()" [formGroup]="atendidoForm">
              <div class="row">
                <div class="col-12 ps-4 my-2">
                  <label class="rcontainer">
                    <input type="radio" value="reto" formControlName="estatusReto" name="estatusReto">
                    <span class="checkmark"></span>
                    Se canalizó a un reto
                  </label>
                </div>
                <div class="col-12 ps-4 my-2">
                  <label class="rcontainer">
                    <input type="radio" value="area" name="estatusReto" formControlName="estatusReto">
                    <span class="checkmark"></span>
                    Se canalizó a otra área
                  </label>
                </div>
                <div class="col-12 ps-4 my-2">
                  <label class="rcontainer">
                    <input type="radio" value="otro" name="estatusReto" formControlName="estatusReto">
                    <span class="checkmark"></span>
                    Otro
                  </label>
                </div>
                <div class="col-12">
                  <div class="container-contacto-enlace">
                    <div class="col-md-12 col-lg-12 col-xl-12 col-sm-12 col-12 mt-4">
                      <span class="title-option">{{ "solicitud_sf_potencial-rechazo.comentarios_contacto" | translate
                        }}</span><span class="text-danger">*</span><br />
                      <textarea style="border-radius: 5px; width: 100%;" rows="7" formControlName="comentariosAtendido"
                        placeholder='Agrega un comentario para dar más especificaciones sobre el estatus de la problemática.'
                        required></textarea>
                    </div>

                  </div>
                </div>
              </div>
              <div class="row justify-content-end ">
                <button type="submit" class="yell_button_small mx-2">
                  Aceptar
                </button>
                <button type="button" class="brown_button mx-2" data-bs-dismiss="modal">
                  Cancelar
                </button>
              </div>
            </form>
          </div>
          <div class="modal-footer d-none">

          </div>
        </div>
      </div>
    </div>

  </div>

</div>


<div class="sec_ad" *featureFlag="'F2_4_HU58'">
  <app-title-show-more [showMoreObject]="titleSolicitudF2_4"></app-title-show-more>
  <div>
    <app-btn-regresar></app-btn-regresar>
  </div>
  <div class="row">
    <div class="col row_spacer">
      <br>
      <span class="subtit1col"><strong>Socio Formador que solicita:</strong></span><br /><br />
      <div class="row">
        <div class="col-1 atomic_spc">
          <img class="img_avt_mn" alt="Socio Formador" src="assets/img/avatar_1.jpg" />
        </div>
        <div class="col-11 atomic_spc mt-5">
          <span class="titsans_contact_b"><strong>{{ socioFormador.nombreSocioFormador}}</strong></span><br />
          <span class="subtgris">{{ socioFormador.empresa }}</span><br />
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="problematica.claveEstatus==='revision' || this.problematica.claveEstatus == 'registrado'">
    <div class="col bkgatomic_alert_a">
      <img class="imgpchip_med" alt="en revisión" src="assets/img/icon_alert_porterminar.svg">&nbsp;<span
        class="titatomic_r"><strong>Tu
          solicitud se encuentra en revisión</strong></span>
    </div>
  </div>

  <div class="row" *ngIf="problematica.claveEstatus==='cancelado'">
    <div class="col bkgatomic_alert_r">
      <img class="imgpchip_med" alt="Cancelado" src="assets/img/icon_alert_porterminar.svg">&nbsp;<span
        class="titatomic_r"><strong>Tu
          solicitud se encuentra cancelada</strong></span>
    </div>
  </div>

  <div class="row" *ngIf="problematica.claveEstatus==='rechazado'">
    <div class="col bkgatomic_alert_r">
      <img class="imgpchip_med" alt="Rechazado" src="assets/img/icon_alert_porterminar.svg">&nbsp;<span
        class="titatomic_r"><strong>Tu
          solicitud se encuentra rechazada</strong></span>
    </div>
  </div>

  <div class="row" *ngIf="problematica.claveEstatus==='aprobado'">
    <div class="col bkgatomic_alert_ap">
      <img class="imgpchip_med" alt="Aprobado" src="assets/img/icon_alert_si.svg">&nbsp;<span
        class="titatomic_r"><strong>Tu
          solicitud esta aprobada</strong></span>
    </div>
  </div>

  <div class="row bkgatomic_alert_w">
    <div class="col-md-12">
      <div class="row">
        <div class="col ">
          <strong>Escuela:</strong>&nbsp;
        </div>
        <p>{{ this.problematica.escuela }}</p>
      </div>
      <div class="row">
        <div class="col ">
          <strong>Programa Académico:</strong>&nbsp;
        </div>
        <p>{{ lbl_programasAcademicos }}</p>
      </div>
      <div class="row">
        <div class="col ">
          <strong>Semestre:</strong>&nbsp;
        </div>
        <p>{{ problematica.semestre }}</p>
      </div>
      <div class="row">
        <div class="col ">
          <strong>Campus:</strong>&nbsp;
        </div>
        <p>{{ lbl_campus }}</p>
      </div>
      <div class="row">
        <div class="col ">
          <strong>Descripción del tipo de problemática que busca:</strong>&nbsp;
        </div>
        <p>{{ this.problematica.proyecto }}</p>
      </div>
      <div class="row">
        <div class="col ">
          <strong>Áreas de la Empresa/Organización involucradas:</strong>
        </div>
        <p>{{ this.problematica.areas }}</p>
      </div>
      <div class="row">
        <div class="col ">
          <strong>Problemática que le interesa resolver:</strong>
        </div>
        <p>{{ this.problematica.problematica }}</p>
      </div>
      <div class="row">
        <div class="col ">
          <strong>Resultados que busca obtener:</strong>
        </div>
        <p>{{ this.problematica.objetivos }}</p>
      </div>
    </div>
  </div>


  <div class="row"
    *ngIf="this.problematica.claveEstatus == 'revision' || this.problematica.claveEstatus == 'registrado'">
    <div class="row">
      <div class="col-md-3"></div>
      <div class="col-md-3">
        <span class="#"><button class="green_button" (click)="handleAceptar2_4()">
            <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 15.2 11.06"
              class="iCn replaced-svg">
              <defs>
                <style>
                  .cls-1 {
                    fill: #4c964d;
                  }
                </style>
              </defs>
              <path class="cls-1"
                d="M5.28,13.71a1.74,1.74,0,0,1-.64-.13,1.65,1.65,0,0,1-.54-.36L.44,9.56l1.4-1.39,3.44,3.44,9-9L15.64,4,6.47,13.22a1.69,1.69,0,0,1-.55.36A1.74,1.74,0,0,1,5.28,13.71Z"
                transform="translate(-0.44 -2.65)"></path>
            </svg>
            Aceptar
          </button></span>
      </div>
      <div class="col-md-3">
        <span class="#"><button class="pink_button" (click)="abrirFormularioRechazo()">
            <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 12.91 12.91"
              class="iCn replaced-svg">
              <defs>
                <style>
                  .cls-1 {
                    fill: #ce0032;
                  }
                </style>
              </defs>
              <path class="cls-1"
                d="M14.47,13.14l-1.13,1.14L8,9,2.7,14.28,1.56,13.14,6.88,7.82,1.56,2.5,2.7,1.37,8,6.69l5.32-5.32L14.47,2.5,9.15,7.82Z"
                transform="translate(-1.56 -1.37)"></path>
            </svg>
            Rechazar
          </button></span><br />
      </div>
      <div class="col-md-3"></div>
    </div>
  </div>



  <div class="row" *ngIf="showFormularioCancelacion">
    <div class="col-md-10">
      <div class="container-motivos-rechazo">
        <div class="row">
          <div class="col-md-12 mt-4">
            <span class="txt-option">{{ "solicitud_sf_potencial-rechazo.area_canalizada" | translate }}</span><br />
            <span class="little-text-option"> {{ "solicitud_sf_potencial-rechazo.label_seleccionar_opciones" | translate
              }}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-lg-5 col-xl-5 col-sm-6 col-12"
            *ngFor="let areaCanalizacion of areasCanalizacionList">
            <p class="mt-4"><mat-checkbox (click)="seleccionarCheck(areaCanalizacion.idAreaCanalizacion)"> {{
                areaCanalizacion.areaCanalizacion }}</mat-checkbox></p>
          </div>
        </div>
        <div class="row" *ngIf="areaCanalizacionRequerido.trim().length > 0">
          <div class="col-md-12">
            <p class="mt-4 mensaje-requerido">{{ '*' + areaCanalizacionRequerido }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-lg-10 col-xl-6 col-sm-12 col-12 mt-4">
            <span class="txt-option">{{ "solicitud_sf_potencial-rechazo.motivo_rechazo_solicitud" | translate
              }}</span><br /><br />
            <textarea style="border-radius: 5px; width: 100%;" rows="7" [(ngModel)]="motivosRechazo"
              [placeholder]="'solicitud_sf_potencial-rechazo.placeholder_detalles_rechazo' | translate "></textarea>
          </div>
        </div>
        <div class="row" *ngIf="motivosRequeridos.trim().length > 0">
          <div class="col-md-12">
            <p class="mt-2 mensaje-requerido">{{ '*' + motivosRequeridos }}</p>
          </div>
        </div>
        <div class="row botones-accion">
          <div class="col-auto mt-4" *ngIf="false">
            <button class="revertir_button" (click)="revertirCancelacion()">
              {{ "solicitud_sf_potencial-rechazo.btn_revertir_rechazo" | translate }}</button>
          </div>
          <div class="col-auto mt-4">
            <button class="enviar_button" (click)="handleRechazar()"> {{ "solicitud_sf_potencial-rechazo.btn_enviar" |
              translate }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-4" *ngIf="solicitud != undefined && solicitud.claveEstatus.trim() === 'rechazado'">
    <div>
      <label class="subtitleseccard mb-2">{{ "solicitud_sf_potencial-rechazo.motivo_rechazo_title" | translate
        }}</label>
    </div>
    <div class="col-md-10">
      <div class="container-contacto-enlace">
        <div class="row">
          <div class="col-md-12 mt-4">
            <span class="title-option">{{ "solicitud_sf_potencial-rechazo.area_canalizada_seleccionadas" | translate
              }}</span><br />
            <span class="txt-option"> {{ solicitud.areaCanalizacion }} </span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mt-4">
            <span class="title-option">{{ "solicitud_sf_potencial-rechazo.detalles_rechazo_solicitud" | translate
              }}</span><br />
            <span class="txt-option"> {{ solicitud.motivoRechazo }} </span>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
