<!-- Evaluación -->
<div class="d-flex" *ngIf="showEvaluations">

    <div>
        <star-rating [starType]="'svg'" [showHalfStars]="true" [rating]="evaluacion" [readOnly]="true" [labelPosition]="'right'" [labelText]="evaluacion">
        </star-rating>
    </div>
    <div class="mx-1" *ngIf="ShowCommentsButton">
        ({{ evaluadores }})
    </div>

    <div *ngIf="ShowCommentsButton">
        <img class="img_btn_comentario" (click)="mostrarEvaluaciones()" alt="Evaluación" src="assets/img/comments.png">
    </div>

</div>


<!-- Modal de la carta solicitud -->
<ng-template #contentEvaluaciones>
    <div class="modal-header">
        Evaluaciones
        <button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-12 txt-title">
                {{ evaluationTitle }}
            </div>
            <div class="col-12 d-flex">
                <div>
                    <star-rating [starType]="'svg'" [showHalfStars]="true" [rating]="evaluacion" [readOnly]="true" [labelPosition]="'right'" [labelText]="evaluacion">
                    </star-rating>
                </div>
                <div class="mx-1">
                    ({{ listaEvaluciones.length }})
                </div>

            </div>
        </div>
        <div class="eval-container ms-4 mt-2" *ngFor="let item of listaEvaluciones">
            <hr>
            <div class="eval-title">
                {{ typeEvaluation ==='RETO'? 'Socio Formador:' : 'Nombre Académico:' }}
                {{ item.nombre_RS }}
            </div>
            <div class="eval-unidadFormacion" *ngIf="typeEvaluation == 'SOCIO'" >
                Unidad de formación:
                {{ item.unidadFormativa }}
            </div>
            <div class="eval-periodoSemanal" *ngIf="typeEvaluation == 'SOCIO'" >
                Periodo Semanal:
                {{ item.periodoSemanal }}
            </div>
            <div class="eval-periodo">
                Periodo:
                {{ item.periodo }}
            </div>
            <div class="eval-fecha">
                Fecha de evaluación:
                {{ item.fecha | date:"dd/MMM/yy"}}
            </div>
            <div class="eval-startrating d-flex">
                <star-rating [starType]="'svg'" [showHalfStars]="true" [rating]="item.evaluacion" [readOnly]="true"
                    [labelPosition]="'right'" [labelText]="item.evaluacion">
                </star-rating>
            </div>
            <hr>
        </div>


    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-amarillo" (click)="close()">Cerrar</button>
    </div>


</ng-template>