import { Component, Input, OnInit } from "@angular/core";
import { IRetoExitosoResponse } from "@shared/Interface/ICasosExito";
import { CasosDeExitoService } from "@shared/service/Casos_de_Exito/casos-de-exito.service";
import { lastValueFrom } from "rxjs";
import Swal from "sweetalert2";

@Component({
  selector: "app-retos-exitosos",
  templateUrl: "./retos-exitosos.component.html",
  styleUrls: ["./retos-exitosos.component.css"],
})
export class RetosExitososComponent implements OnInit {
  @Input()
  IdSocioFormador: number;

  hayCasosDeExito: boolean = false;

  casosExito: IRetoExitosoResponse[];

  constructor(private casosDeExitoService: CasosDeExitoService) {}

  ngOnInit(): void {
    this.cargarCasosDeExito();
  }

  async cargarCasosDeExito() {
    this.hayCasosDeExito = false;
    this.casosExito = [];
    if (this.IdSocioFormador) {
      this.casosExito = await lastValueFrom(
        this.casosDeExitoService.getnRetosExitososxSocio(this.IdSocioFormador,100)
      );

      this.casosExito.sort((a, b) => {return  b.idReto - a.idReto });
      this.casosExito = this.casosExito.slice(0, 5);

      if (this.casosExito.length > 0) {
        this.hayCasosDeExito = true;
      }
    }
  }

  mostrarDetalle(idReto) {
    let caso = this.casosExito.find((x) => x.idReto == idReto);
    let mensaje =
      '<div class="row text-start w-100">' +
      '<div class="col-6">' +
      ' <div class="col-12 "><h5>Reto:</h5></div>' +
      ' <div class="col-12"><h6>&nbsp;&nbsp;' +
      caso?.nombreReto +
      "</h6></div>" +
      "</div>" +
      '<div class="col-6">' +
      ' <img alt="' +
      caso?.nombreReto +
      '" title="' +
      caso?.nombreReto +
      '"' +
      '    style="width: 300px;height: 200px;object-fit: cover;"' +
      '    class="imgCasoExito" src="' +
      caso?.urlImagenResultado +
      '">' +
      "</div>" +
      ' <div class="col-12"><h5>Descripción:</h5></div>' +
      ' <div class="col-12"><h6>&nbsp;&nbsp;' +
      caso?.descripcionReto +
      "</h6></div>";
    if (caso?.urlInformacionResultado) {
      mensaje +=
        ' <div class="col-12"><h5>Liga de interés:</h5></div>' +
        ' <div class="col-12"><a target="_blank" href="' +
        caso?.urlInformacionResultado +
        '">' +
        caso?.urlInformacionResultado +
        "</a></div>";
    }

    Swal.fire({
      title: "Detalle del Reto",
      width: 800,
      html: mensaje,
    });
  }
}
