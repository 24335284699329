import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import {
  CUnidadFormativa,
  ICampus,
  IEscuela,
  IIndustria,
  IPeriodo,
  IPeriodoSemanal,
  IPeriodoSemestral,
  IPresencia,
  IRegion,
  ISector,
  ITamanoEmpresa,
} from "@shared/Interface/ICatalogos.interface";
import {
  ICartaColaboracion,
  IReto,
  IRetoMaestro,
} from "@shared/Interface/IReto.interface";
import { CatalogosService } from "@shared/service/Catalogos/catalogos.service";
import { RetosService } from "@shared/service/Retos/retos.service";
import { UsuariosService } from "@shared/service/Usuarios/usuarios.service";
import { tap, lastValueFrom } from "rxjs";
import { ToastrService } from "ngx-toastr";
import Swal from "sweetalert2";
import { IUser } from "@shared/Interface/user";
import { SociosFormadoresService } from "@shared/service/Socios_Formadores/socios-formadores.service";
import { ISocioFormadorResponse } from "@shared/Interface/ISocioFormadorResponse";
import { environment } from "environments/environment";
import { AzureBlobStorageService } from "@shared/service/AzureBlobStorage.Service";
import { SolicitudesService } from "@shared/service/Solicitudes/solicitudes.service";
import { ISolicitudResponse } from "@shared/Interface/ISolicitudResponse";
import { ContactosService } from "@shared/service/Contactos/contactos.service";
import { IContacto } from "@shared/Interface/IContacto.interface";
import { formatDate } from "@angular/common";

interface GeneralInfoMocks {
  icon: string;
  title: string;
}

interface RetoInfo {
  idReto: number;
  reto: string;
  descripcion: string;
  entregables: string; // objetivos?
  escuela: string;
  industria: string;
  unidadFormativa: string;
  alcance: string;
  campus: ICampus[];
  generalInfoIcons: GeneralInfoMocks[];
}

@Component({
  selector: "app-vincula-usuarios2",
  templateUrl: "./vincula-usuarios2.component.html",
  styleUrls: ["./vincula-usuarios2.component.css"],
})
export class VinculaUsuarios2Component implements OnInit {
//TODO: Esta ya no se usa
  infoVinculacionForm: FormGroup;
  retoProfesorForm: FormGroup;
  retoParticularForm: FormGroup;
  socioForm: FormGroup;

  // Controlar si muestra SF con convenio
  conConvenio: boolean= false;

  //validationErrors:     string[] = [];
  campusData: ICampus[];
  escuelas: IEscuela[];
  escuelaData: any;
  industriasData: IIndustria[];
  mostrarRequeridos: boolean = false;
  presenciaData!: IPresencia[];
  periodos: IPeriodo[];
  periodosSemanales: IPeriodoSemanal[];
  periodosSemestrales: IPeriodoSemestral[];
  sectoresData: ISector[];
  retosAcademicosData: IReto[];
  regionesData: IRegion[];
  retoInfo: any;
  retoGuardado: any;
  socioFormadorData: ISocioFormadorResponse;
  tamanioEmpresasData: ITamanoEmpresa[];
  unidadFormativaData: CUnidadFormativa[];
  ubicacionData: any;
  urlCartaColaboracion: string = "";

  lbl_reto_idiomas: string = "";
  lbl_reto_semestres: string = "";

  buttonSave = {
    title: "Registrar Programación",
    isLoading: false,
  };

  titleVinculacion = {
    title: "Documentación de retos",
  };

  selectedCampus;

  socioFormadorList: ISocioFormadorResponse[] = [];
  SF_sector = "";
  SF_industria = "";
  SF_tamano = "";
  SF_presencia = "";
  SF_empresa = "";
  SF_razon = "";

  Coordinador: any = {
    profesor: "",
    correo: "",
    puesto: "",
    telefono: "",
  };
  socioFormadorContactosPrincipal: IContacto[];
  socioFormadorContactos: IContacto[];

  clavePerfil: string = "";

  constructor(
    private catalogosService: CatalogosService,
    private usuariosService: UsuariosService,
    private retosService: RetosService,
    private router: Router,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private socioFormadorService: SociosFormadoresService,
    private activateRoute: ActivatedRoute,
    private _toastr: ToastrService,
    private _azureBlobStorage: AzureBlobStorageService,
    private _solicitudServices: SolicitudesService,
    private _contactoServices: ContactosService
  ) {}

  ngOnInit() {
    this.campusData = [];

    this.clavePerfil = this.usuariosService.getCurrentUserData().clavePerfil;

    this.cargarCatalogos();

    this.initializeForm();

    this.cargarRetoGuardado();

    this.getEscuela(0);

    this.getSociosFormadores("", 0, 0, "", 0);
  }

  cargarCatalogos() {
    this.catalogosService.getPeriodo().subscribe((resOK) => {
      this.periodos = resOK;
    });

    this.catalogosService.getCampus().subscribe((resOK) => {
      this.campusData = resOK;
    });

    this.catalogosService.getPeriodoSemanal().subscribe({
      next: (resOK) => {
        this.periodosSemanales = resOK;
      },
      error: (error) => {
        console.log(error);
      },
    });

    this.catalogosService.getPeriodoSemestral().subscribe({
      next: (resOK) => {
        this.periodosSemestrales = resOK;
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  async getSociosFormadores(
    keySearch: string,
    sectorId: number,
    industriaId: number,
    claveEstado: string,
    tamanoId: number
  ) {
    try {
      this.socioFormadorList = [];
      let resultado = <any>(
        await lastValueFrom(this.socioFormadorService
          .getAllSociosFormadores(
            keySearch,
            sectorId,
            industriaId,
            claveEstado,
            tamanoId,
            "",
            0,
            this.conConvenio
          ))
      );
      if (resultado != null && resultado != undefined) {
        this.socioFormadorList = resultado.sort((a,b)=>
                (a.nombreSocioFormador < b.nombreSocioFormador)
                ? -1
                : ((b.nombreSocioFormador < a.nombreSocioFormador)
                    ? 1
                    :0 )
              );

      }
    } catch (error) {
      console.error(
        "Error al obtener todos los Socio Formador por filtros",
        error
      );
    }
  }

  getDatosSocioformador(event) {
    if (this.socioFormadorList) {
      this.socioFormadorData = this.socioFormadorList.filter(
        (x) => x.idSocioFormador == event
      )[0];
      this.SF_razon = this.socioFormadorData.razonSocial;
      this.SF_empresa = this.socioFormadorData.empresa;
      this.SF_industria = this.socioFormadorData.industria ?? "Sin Información";
      this.SF_presencia =
        this.socioFormadorData.presenciaC ?? "Sin Información";
      this.SF_sector = this.socioFormadorData.sector ?? "Sin Información";
      this.SF_tamano = this.socioFormadorData.tamano ?? "Sin Información";
    }
  }

  cargarProfesorCoordinador() {
    let user: IUser = this.usuariosService.getCurrentUserData();
    if (this.retoGuardado) {
      this.Coordinador.profesor = this.retoGuardado.nombreCoordinador ?? user.nombreUsuario;
      this.Coordinador.correo =   this.retoGuardado.correoCoordinador ?? user.usuario;
      this.Coordinador.puesto =  this.retoGuardado.puestoCoordinador ?? user.puesto;
      this.Coordinador.telefono = this.retoGuardado.telefonoCoordinador;
    } else {
      this.Coordinador.profesor = user.nombreUsuario;
      this.Coordinador.correo = user.usuario;
      this.Coordinador.puesto = user.puesto;
      this.Coordinador.telefono = "";
    }

    this.retoProfesorForm.patchValue({
      nombreCoordinador: this.Coordinador.profesor,
      correoCoordinador: this.Coordinador.correo,
      puestoCoordinador: this.Coordinador.puesto,
      telefonoCoordinador: this.Coordinador.telefono,
    });
  }

  async cargarSocioFormadorContacto(retoGuardado) {
    try {
      if (!retoGuardado.nombreContacto) {
        let listaContactos = await lastValueFrom(this._contactoServices
          .getContactos(retoGuardado.idSocioFormador));
        if (!listaContactos) {
          this.toastr.error(
            "No hay contatos registrados para este Socio Formador."
          );
          return;
        }
        let contacto = listaContactos
          .filter((x) => x.contactoPrincipal === true)
          .pop();
        if (!contacto) {
          contacto = listaContactos[0];
        }

        retoGuardado.nombreContacto = contacto.nombre;
        retoGuardado.correoContacto = contacto.email;
        retoGuardado.puestoContacto = contacto.puesto;
        retoGuardado.telefonoContacto =
          contacto.telefonoMovil || contacto.telefonoOficina;
      }
    } catch (err) {
      console.log(err);
    }

    this.socioForm.patchValue({
      idSocioFormador: (retoGuardado.idSocioFormador == 0?'':retoGuardado.idSocioFormador),
      nombreContacto: retoGuardado.nombreContacto,
      correoContacto: retoGuardado.correoContacto,
      puestoContacto: retoGuardado.puestoContacto,
      telefonoContacto: retoGuardado.telefonoContacto,
    });

    this.socioFormadorService
      .getSociosFormadoresById(this.retoGuardado.idSocioFormador)
      .subscribe((resOK) => {
          this.SF_empresa = resOK.nombreSocioFormador;
          this.SF_industria = resOK.industria;
          this.SF_presencia = resOK.presenciaC;
          this.SF_razon = resOK.razonSocial;
          this.SF_tamano = resOK.tamano;
      });
    if (retoGuardado.claveEstatus.trim() === "activo") {
      this._contactoServices
        .getContactos(this.retoGuardado.idSocioFormador)
        .subscribe({
          next: (resOK) => {
            this.socioFormadorContactos = resOK;
            this.socioFormadorContactosPrincipal = resOK.filter(
              (x) => x.contactoPrincipal === true
            );
          },
          error: (error) => {
            console.log(error);
          }
        });
    }
  }

  cargarRetoGuardado() {
    let idReto = Number(
      localStorage.getItem("idReto") ?? this.retoGuardado?.idReto ?? 0
    );
    let idRetoM = Number(
      this.activateRoute.snapshot.queryParamMap.get("id") ?? 0
    );
    if (idRetoM > 0) {
      idReto = idRetoM;
    }

    // Si existe el reto cargamos sus datos
    if (idReto != 0) {
      this.retosService.getRetos(idReto).subscribe((resOK) => {
        this.retoGuardado = resOK;

        // Carga profesor Coordinador
        this.cargarProfesorCoordinador();

        // Cargar Socio Formador
        this.cargarSocioFormadorContacto(this.retoGuardado);

        // Cargar info vinculacion
        this.cargarInfoVinculacion(this.retoGuardado);

        // Cargar info reto personalzado
        this.retoParticularForm.patchValue({
          nombreReto: this.retoGuardado.reto,
          descripcionReto: this.retoGuardado.descripcion,
          entregable: this.retoGuardado.entregable,
        });

        // seleccionamos la escuela
        this.retoProfesorForm.patchValue({ idEscuela: resOK.idEscuela });

        // cargamos las unidades formativas
        this.catalogosService.getUnidadFormativa(resOK.idEscuela).subscribe({
          next: (resultado: CUnidadFormativa[]) => {
            this.unidadFormativaData = resultado;
            // Cargamos el reto cargado
            this.getRetoDetail(this.retoGuardado.idUnidadFormativa);
          },
          error: (error) => {
            console.log(error);
          }
        });
        this.retoProfesorForm.patchValue({
          idUnidadFormativa: resOK.idUnidadFormativa,
          idReto: resOK.idReto,
        });

        this.retoProfesorForm.controls["idEscuela"].enable();
        this.retoProfesorForm.controls["idUnidadFormativa"].enable();
        this.socioForm.controls["idSocioFormador"].enable();

        if (resOK.idTipoSolicitud == 2) {
          this.retoProfesorForm.controls["idEscuela"].disable();
          this.retoProfesorForm.controls["idUnidadFormativa"].disable();
          this.socioForm.controls["idSocioFormador"].disable();
        }
        if (resOK.idTipoSolicitud == 5) {
          this.retoProfesorForm.controls["idEscuela"].disable();
          this.retoProfesorForm.controls["idUnidadFormativa"].disable();
        }
      });
    } else {
      // Sino existe, cargamos los valores iniciales
      this.getEscuela(0);
      // Cargamos los datos del profesor
      this.cargarProfesorCoordinador();
    }

    localStorage.removeItem("idReto");
  }

  cargarInfoVinculacion(retoGuardado) {
    //obtener la carta de vinculacion
    this.socioFormadorService
      .getCartaColaboracion(retoGuardado.idSocioFormador)
      .subscribe((resOK: ICartaColaboracion[]) => {
        if (resOK) {
          this.urlCartaColaboracion =
            resOK.filter((x) => x.idReto == retoGuardado.idReto).pop()
              ?.urlCartaColaboracion ?? "";
        }
      });
    let campusSelectedArray = retoGuardado.campus.map((x) => x.idCampus);
    this.selectedCampus = this.campusData.filter((x) =>
      campusSelectedArray.includes(x.idCampus)
    );

    this.infoVinculacionForm.patchValue({
      idPeriodoSemestral: retoGuardado.idPeriodoSemestral,
      periodosSemanales: retoGuardado.periodosSemanales.map((x)=> x.idPeriodoSemanal),
      CRNPrincipal: retoGuardado.crns
        .filter((x) => x.esPrincipal === true)
        .map((x) => x.crn)
        .pop(),
      CRNVinculados: retoGuardado.crns
        .filter((x) => x.esPrincipal === false)
        .map((x) => {
          return { label: x.crn };
        }),
      listadoCampus: retoGuardado.campus.map((x) => x.idCampus),
      campusCoordina: retoGuardado.campus
        .filter((x) => x.esCoordinador === true)
        .map((x) => x.idCampus)
        .pop(),
    });
    // campusReto: retoGuardado.idCampus,
    // campusCoordinaReto:
  }

  async selImagen(event: any) {
    // Valida idSocioFormador
    if(!this.retoGuardado){
      console.error("Error: Debe seleccionar y guardar un Reto");
      this._toastr.error("Error: Selecciona y guarda un Reto");
      return;
    }
    let idUsr = this.retoGuardado.idSocioFormador ?? this.socioForm.get("idSocioFormador")?.value;
    let idReto = this.retoGuardado.idReto;
    let selFile = (event.target as HTMLInputElement).files?.[0];

    // Valida idSocioFormador
    if(!idUsr){
      console.error("Error: Debe seleccionar y guardar el Socio Formador");
      this._toastr.error("Error: Selecciona y guarda un Socio Formador");
      return;
    }
    // Valida existencia
    if (!selFile) return;
    // Valida tamaño
    if (selFile.size > 2e6) {
      console.error("Error: Tamaño máximo de 2MB");
      this._toastr.error("Error: Tamaño máximo de 2MB");
      return;
    }
    // Obtiene nombre
    let fileName = this.getFileName(idUsr, idReto, selFile);
    // guarda en bd
    await this.uploadSocioImage(idUsr, selFile, fileName, idReto);
  }

  async uploadSocioImage(idUsr, selFile, fileName, idReto) {
    await this._azureBlobStorage
      .uploadFile(
        {
          containerName: environment.AzureBlob_containerNameSocio,
          file: selFile,
          filename: fileName,
        },
        environment.AzureBlob_ACCOUNT_NAME,
        environment.AzureBlob_socioSAS
      )
      .then((res) => {
        let fileUrlLogo = `${environment.AzureBlob_socioURL}${fileName}`;
        let currentDate = new Date();
        this.socioFormadorService
          .postCartaColaboracion({
            idSocioFormador: idUsr,
            idReto: idReto,
            urlCartaColaboracion: fileUrlLogo,
            idAcademico: this.usuariosService.getCurrentUserData().idUsuario,
            fechaFirma: formatDate(currentDate,"yyyy-MM-dd", "Es-MX")
          })
          .subscribe((resOK) => {
            this.urlCartaColaboracion = fileUrlLogo;
          });
      });
  }

  getFileName(idsf, idReto, file) {
    return `socio_carta_colaboracion_${idsf}_${idReto}.${file.name
      .split(".")
      .pop()}`.toLowerCase();
  }

  getRetosAcademicosxUF($event) {
    this.retosService.getRetosMaestrosxUnidadFormativa($event).subscribe({
      next: (resultado: IReto[]) => {
        this.retosAcademicosData = resultado;
        this.retoInfo = null;
      }
      ,error: (error) => {
        this.retosAcademicosData = [];
        this.retoInfo = null;
        Swal.fire({
          icon: "info",
          text: "No hay retos asociados a la Unidad Formativa",
        });
      }
    }
    );
  }

  initializeForm() {
    this.retoProfesorForm = this.fb.group({
      idEscuela: [""],
      idUnidadFormativa: [""],
      nombreCoordinador: [],
      correoCoordinador: [],
      puestoCoordinador: [],
      telefonoCoordinador: [],
    });

    this.socioForm = this.fb.group({
      idSocioFormador: [],
      nombreContacto: [],
      puestoContacto: [],
      correoContacto: [],
      telefonoContacto: [],
    });
    this.infoVinculacionForm = this.fb.group({
      cartaColaboracion: [],
      idPeriodoSemestral: [],
      periodosSemanales: [],
      CRNPrincipal: ["", [Validators.min(4), Validators.max(6)]],
      CRNVinculados: [],
      listadoCampus: [],
      campusCoordina: [],
    });
    this.retoParticularForm = this.fb.group({
      nombreReto: [],
      descripcionReto: [],
      entregable: [],
    });
  }

  addGeneralIcons(retoData: any) {
    const generalIcons: any = [];
    const iconsType: Record<string, any> = {
      alcance: {
        icon: "icon",
      },
      idioma: {
        icon: "icon_language",
      },
      semestre: {
        icon: "icon_semestre",
      },
      periodo: {
        icon: "icon_season",
      },
      semanas: {
        icon: "icon_calendar",
      },
    };

    Object.keys(retoData).forEach((retoKey: any) => {
      if (iconsType[retoKey]) {
        generalIcons.push({
          title:
            retoKey === "semestre"
              ? `${retoData[retoKey]} Semestre`
              : retoData[retoKey],
          icon:
            retoKey === "alcance"
              ? `${iconsType[retoKey].icon}_${this.cleanText(
                  retoData[retoKey]
                )}`
              : iconsType[retoKey].icon,
        });
      }
    });
    return generalIcons;
  }

  getRetoDetail(event: any) {
    const idUnidadFormativa = event;
    this.retosService
      .getRetosMaestrosxUnidadFormativa_original(idUnidadFormativa)
      .pipe(
        tap((res: IRetoMaestro[]) => {

          let resultado = res[0];
          this.retoInfo = {
            idReto: resultado.idRetoMaestro,
            reto: resultado.reto,
            descripcion: resultado.descripcion,
            entregable: resultado.entregable || "Sin Información",
            objetivo: resultado.objetivo || "Sin Información",
            idEscuela: this.retoProfesorForm.get("idEscuela")?.value,
            idUnidadFormativa: idUnidadFormativa,
            escuela: resultado.escuela || "Sin Información",
            industria: resultado.industrias || "Sin Información",
            alcances: resultado.alcances?.map((x) => x.alcance).join(", "),
            programas: resultado.programasOtrasEscuelas || "Sin Información",
            programasAcademicos: resultado.programasAcademicos,
            campus: "",
            semestres: resultado.semestres
              ?.map((x) => x.semestre.toString())
              .join(", "),
            semanas: resultado.semanas || "Sin Información",
            idiomas: resultado.idiomas?.map((x) => x.idioma).join(", "),
            generalInfoIcons: this.addGeneralIcons(resultado),
          };
        })
      )
      .subscribe();
  }



  clickToCheck(typeCheck: string) {

    return true;
  }


  cleanText(textToClean: string) {
    return textToClean
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
  }



  getProgramasAcademicosxEscuela(event) {

    this.catalogosService
      .getUnidadFormativa(event)
      .pipe(
        tap((resultado: CUnidadFormativa[]) => {
          if (resultado.length > 0) {
            this.unidadFormativaData = resultado;
          } else {
            Swal.fire({
              title: "Vinculación de Socio Formador",
              text:
                "Por el momento no hay Unidades Formativas asociadas a tu escuela.",
              icon: "warning",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK",
            }).then((result) => {
              window.location.href = "dashboard";
            });
          }
        })
      )
      .subscribe();
  }

  getEscuela(idEsc: number) {
    this.catalogosService
      .getEscuelas(idEsc)
      .pipe(
        tap((resultado: IEscuela[]) => {
          this.escuelaData = resultado;
          if (this.retoInfo) {
            this.retoProfesorForm.patchValue({
              idEscuela: this.retoInfo.idEscuela,
            });
            this.getUnidadFormativaxEscuela(this.retoInfo.idEscuela);
          }
        })
      )
      .subscribe();
  }

  getUnidadFormativaxEscuela(idEscuela) {
    this.catalogosService.getUnidadFormativa(idEscuela).subscribe({
      next: (resultado: CUnidadFormativa[]) => {
        this.unidadFormativaData = resultado;

        if (this.retoInfo) {
          this.retoProfesorForm.patchValue({
            idUnidadFormativa: this.retoInfo.idUnidadFormativa,
          });
        }
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  getUnidadFormativa(event) {
    let idEscuela = event.target.value;
    this.getUnidadFormativaxEscuela(idEscuela);
  }

  updateRetoProfesorCoordinador() {
    if (!this.retoGuardado) {
      let userData: IUser = this.usuariosService.getCurrentUserData();


      let idRetoMaestro = this.retoInfo.idReto;

      let nombreRetoMaestro =
        this.retoInfo.nombreRetoMaestro || this.retoInfo.reto;
      let descripcion = `Solicitud de programación de reto ${nombreRetoMaestro}`;
      let body = {
        idTipoSolicitud: 6,
        idRetoMaestro: idRetoMaestro,
        idCampus: userData.idCampus,
        descripcionSolicitud: descripcion,
        idUsuario: userData.idUsuario,
        idAcademia: userData.idUsuario,
      };

      this._solicitudServices.postSolicitud(body).subscribe({
        next: (resOK: ISolicitudResponse) => {
          this._solicitudServices
            .getSolicitudById(resOK.idSolicitud)
            .subscribe({
              next: (resOKSol) => {
                localStorage.setItem("idReto", resOKSol.idReto.toString());
                this.retoGuardado = {
                  idReto: resOKSol.idReto,
                  idEscuela: this.retoProfesorForm.get("idEscuela")?.value,
                  idUnidadFormativa: this.retoProfesorForm.get(
                    "idUnidadFormativa"
                  )?.value,
                };

                this.toastr.success("Creado correctamente");
                this.updateProfesorCoordinador();
                this.cargarRetoGuardado();
              },
              error: (error) => {
                console.log(error);
              },
            });
        },
        error: (error) => {
          console.log(error);
        },
      });
    } else {
      this.updateProfesorCoordinador();
    }
  }

  updateProfesorCoordinador() {
    this.retosService
      .putRetoProfesorCoordinador({
        idReto: this.retoGuardado.idReto,
        nombreCoordinador: this.retoProfesorForm.get("nombreCoordinador")
          ?.value,
        correoCoordinador: this.retoProfesorForm.get("correoCoordinador")
          ?.value,
        puestoCoordinador: this.retoProfesorForm.get("puestoCoordinador")
          ?.value,
        telefonoCoordinador: this.retoProfesorForm.get("telefonoCoordinador")
          ?.value,
      })
      .subscribe({
        next: (resOK) => {
          this.toastr.success("Profesor guardado correctamente");
        },
        error: (error) => {
          console.log(error);
        },
      });
  }

  updateSocio() {
    let idSocioFormador = this.retoGuardado.idSocioFormador;
    if (idSocioFormador == 0) {
      idSocioFormador = this.socioForm.get("idSocioFormador")?.value;
    }
    this.retosService
      .putretoSocioContacto({
        idReto: this.retoGuardado.idReto,
        idSocioFormador: idSocioFormador,
        nombreContacto: this.socioForm.get("nombreContacto")?.value,
        correoContacto: this.socioForm.get("correoContacto")?.value,
        puestoContacto: this.socioForm.get("puestoContacto")?.value,
        telefonoContacto: this.socioForm.get("telefonoContacto")?.value,
      })
      .subscribe({
        next: (resOK) => {
          this.retoGuardado.idSocioFormador = idSocioFormador;
          this.toastr.success("Guardado correctamente");
        },
        error: (error) => {
          console.log(error);
        },
      });
  }

  updateVinculacion() {
    let crnprincipal = this.infoVinculacionForm.get("CRNPrincipal")?.value;
    if (crnprincipal) {
      if (!Number(crnprincipal)) {
        this.toastr.error("El CRN Principal debe ser numérico, 4-5 dígitos");
        return;
      }
      if (
        crnprincipal.toString().length < 4 ||
        crnprincipal.toString().length > 5
      ) {
        this.toastr.error(
          "El CRN Principal debe ser un número de 4 a 5 dígitos"
        );
        return;
      }
    }

    let crnvinculados = this.infoVinculacionForm
      .get("CRNVinculados")
      ?.value.map((x) => {
        return x.label;
      });

    //Verificamos que el crn principal exista en los vinculados, sino lo agregamos
    if (!crnvinculados.includes(crnprincipal)) {
      crnvinculados.push(crnprincipal);
    }

    this.retosService
      .putRetoVinculacion({
        idReto: this.retoGuardado.idReto,
        idPeriodoSemestral: this.infoVinculacionForm.get("idPeriodoSemestral")?.value,
        periodosSemanales: this.infoVinculacionForm.get("periodosSemanales")?.value,
        crnPrincipal: crnprincipal,
        crnVinculados: crnvinculados,
        campusReto: this.infoVinculacionForm.get("listadoCampus")?.value,
        campusRetoCoordinador: this.infoVinculacionForm.get("campusCoordina")?.value,
      })
      .subscribe({
        next: (resOK) => {
          this.toastr.success("Guardado correctamente");
        },
        error: (error) => {
          console.log(error);
        },
      });
  }

  updateRetoParticular() {
    this.retosService
      .putRetoPersonalizado({
        idReto: this.retoGuardado.idReto,
        reto: this.retoParticularForm.get("nombreReto")?.value,
        descripcion: this.retoParticularForm.get("descripcionReto")?.value,
        entregable: this.retoParticularForm.get("entregable")?.value,
      })
      .subscribe({
        next: (resOK) => {
          this.toastr.success("Guardado correctamente");
        },
        error: (error) => {
          console.log(error);
        },
      });
  }

  onChangeValue($event) {
    this.selectedCampus = $event;
  }

  addCrnVinculado(crn) {
    let rvBol = true;

    if (!Number(crn)) {
      Swal.fire({
        title: "CRN Vinculado ",
        text: "El CRN debe ser un número de 4 a 5 digitos",
      });
      rvBol = false;
    }
    if (crn.toString().length < 4 || crn.toString().length > 5) {
      Swal.fire({
        title: "CRN Vinculado ",
        text: "El CRN  debe ser un número de 4 a 5 digitos",
      });

      rvBol = false;
    }
    return { name: crn, tag: rvBol };
  }

  marcarTerminado(){
    if(!this.retoGuardado.idSocioFormador){
    Swal.fire({
      title: "Reto terminado",
      text: "Debe tener asignado un Socio Formador",
      icon: "warning"
    });
    return;
    }
    sessionStorage.setItem('acad-idReto-terminado',this.retoGuardado.idReto);
    sessionStorage.setItem('acad-nbReto-terminado',this.retoGuardado.reto);
    this.router.navigate(['marcar-reto-terminado']);
  }
}
