import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ICasosExito, IResultadoReto } from "@shared/Interface/ICasosExito";
import Utils from "@shared/helpers/utils";
import { AzureBlobStorageService } from "@shared/service/AzureBlobStorage.Service";
import { CasosDeExitoService } from "@shared/service/Casos_de_Exito/casos-de-exito.service";
import { UsuariosService } from "@shared/service/Usuarios/usuarios.service";
import { environment } from "environments/environment";
import { ToastrService } from "ngx-toastr";
import { lastValueFrom } from "rxjs/internal/lastValueFrom";
import Swal from "sweetalert2";

@Component({
  selector: "app-reto-terminado",
  templateUrl: "./reto-terminado.component.html",
  styleUrls: ["./reto-terminado.component.css"],
})
export class RetoTerminadoComponent implements OnInit {
  idReto: number = 0;
  idRetoResultado: number = 0;
  nombreReto: string = "";
  resultadoRetoForm: FormGroup;
  imgUrl = "assets/img/avatar_sin.jpg";

  constructor(
    private casosDeExitoService: CasosDeExitoService,
    private fb: FormBuilder,
    private toastrService: ToastrService,
    private azureBlobStorage: AzureBlobStorageService,
    private casosExitoService: CasosDeExitoService,
    private usuarioService: UsuariosService
  ) {}

  ngOnInit(): void {
    this.idReto = Number(sessionStorage.getItem("acad-idReto-terminado") ?? 0);
    this.nombreReto = sessionStorage.getItem("acad-nbReto-terminado") ?? "";

    this.initForm().catch((e) => console.log(e));
  }

  async initForm() {
    let idReto = this.idReto;
    let casoExito: IResultadoReto;

    this.resultadoRetoForm = this.fb.group({
      resultado: [
        "",
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(2000),
        ],
      ],
      urlInformacionResultado: ["", Validators.required],
      urlFotoResultado: ["", Validators.required],
      reto_exitoso: [false, Validators.required],
    });

    try {
      casoExito = await lastValueFrom(
        this.casosDeExitoService.getResultadoReto(idReto)
      );
      this.idRetoResultado = casoExito.idRetoResultado;
      this.resultadoRetoForm.patchValue({
        resultado: casoExito.resultado,
        urlInformacionResultado: casoExito.urlInformacionResultado,
        urlFotoResultado: casoExito.urlFotoResultado,
      });

      this.imgUrl = casoExito.urlFotoResultado;
    } catch (e) {
      console.log(e);
    }
  }

  async actualizaResultadoReto() {
    let resultado = await lastValueFrom(
      this.casosDeExitoService.putResultadoReto({
        idRetoResultado: this.idRetoResultado,
        idReto: this.idReto,
        resultado: this.resultadoRetoForm.get("resultado")?.value,
        urlInformacionResultado: this.resultadoRetoForm.get(
          "urlInformacionResultado"
        )?.value,
        urlFotoResultado: this.resultadoRetoForm.get("urlFotoResultado")?.value,
      })
    );
    if (resultado) {
      if (this.resultadoRetoForm.get("reto_exitoso")?.value) {
        this.insertarCasoExito();
      }
      Swal.fire({
        title: "Guardado correctamente",
        icon: "success",
      });
    }
  }

  async generaResultadoReto() {
    let resultado = await lastValueFrom(
      this.casosDeExitoService.postResultadoReto({
        idReto: this.idReto,
        resultado: this.resultadoRetoForm.get("resultado")?.value,
        urlInformacionResultado: this.resultadoRetoForm.get(
          "urlInformacionResultado"
        )?.value,
        urlFotoResultado: this.resultadoRetoForm.get("urlFotoResultado")?.value,
      })
    );

    if (resultado) {
      if (this.resultadoRetoForm.get("reto_exitoso")?.value) {
        this.insertarCasoExito();
      }

      Swal.fire({
        title: "Guardado correctamente",
        icon: "success",
      });
    }
  }

  async guardarCasoExistoso() {
    try {
      this.resultadoRetoForm.markAllAsTouched();
      if (this.resultadoRetoForm.valid) {
        if (this.idRetoResultado > 0) {
          this.actualizaResultadoReto();
        } else {
          this.generaResultadoReto();
        }
      } else {
        this.toastrService.error("Llena todos los campos");
        Utils.getFormValidationErrors(this.resultadoRetoForm);
      }
    } catch (err) {
      console.log(err);
      this.toastrService.error("Ocurrió un error, intente más tarde");
    }
  }

  insertarCasoExito() {
    // Obtenemos si existe el caso de éxito

    let idEscuela = this.usuarioService.getCurrentUserData().idEscuela;
    let idCampus = this.usuarioService.getCurrentUserData().idCampus;
    let anio = new Date().getFullYear();
    this.crearCasos({
      nombreCasoExito: this.nombreReto,
      descripcionCasoExito: this.resultadoRetoForm.get("resultado")?.value,
      anoCasoExito: anio,
      idEscuela: idEscuela,
      idCampus: idCampus,
      urlImagenCasoExito: this.resultadoRetoForm.get("urlFotoResultado")?.value,
      urlConectaCasoExito: this.resultadoRetoForm.get("urlInformacionResultado")
        ?.value,
      estatusCasoExito: "activo",
      idReto:this.idReto
    });
  }

  crearCasos(casoExito) {
    try {
      this.casosExitoService.postCasos(casoExito).subscribe({
        error: (error) => {
          Swal.fire({
            icon: "error",
            text: error,
            title: "Error",
          });
        },
      });
      return;
    } catch (err) {
      console.log(err);
    }
  }

  async selImagen(event: any) {
    let idCasoExito = this.idReto;

    let selFile = (event.target as HTMLInputElement).files?.[0];
    if (!selFile) return;
    let fileName = this.getFileName(idCasoExito, selFile);

    await this.uploadUserImage(idCasoExito, selFile, fileName);
  }

  async uploadUserImage(idCasoExito, selFile, fileName) {
    this.azureBlobStorage
      .uploadFile(
        {
          containerName: environment.AzureBlob_containerNameRetos,
          file: selFile,
          filename: fileName,
        },
        environment.AzureBlob_ACCOUNT_NAME,
        environment.AzureBlob_retosSAS
      )
      .then((res) => {
        let fileUrlLogo = `${environment.AzureBlob_retosURL}${fileName}`;

        this.resultadoRetoForm.controls["urlFotoResultado"].setValue(
          fileUrlLogo
        );

        this.imgUrl = this.resultadoRetoForm.controls["urlFotoResultado"].value;
      });
  }

  getFileName(id, file) {
    if (id == null) {
      id = this.makeid(5);
    }
    return `res_caso_exito_${id}.${file.name.split(".")[1]}`.toLowerCase();
  }

  makeid(length) {
    let result = Date.now().toString();

    return result;
  }
}
