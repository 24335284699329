<div id="c_sl_pa" class="sec_cnt tipo-10 mt-4">
  <div class="d-none d-md-block">

    <div class="cont_tit d-flex">
      <div class="flex-fill col-1">{{'vinculaciones.fecha' | translate }}</div>
      <div class="flex-fill col-3">{{'vinculaciones.nombre_solicitud' | translate}}</div>
      <div class="flex-fill col-1">{{'vinculaciones.duracion' | translate}}</div>
      <div class="flex-fill col-1">{{'vinculaciones.periodo_semanal' | translate}}</div>
      <div class="flex-fill col-1">{{'vinculaciones.periodo_semestral' | translate}}</div>
      <div class="flex-fill col-2">{{'vinculaciones.campus_solicitado' | translate}}</div>
      <div class="flex-fill col-1">{{'vinculaciones.estatus' | translate}}</div>
      <div class="flex-fill col-1">{{'vinculaciones.acciones' | translate}}</div>
    </div>

    <div class="roundedsquare cont_fl d-flex" *ngFor="let el of vinculacionesList">

      <div class="flex-fill col-1">
        {{el.fechaSolicitud | date: 'dd/MMM/yy'}}
      </div>

      <div class="flex-fill col-3">
        <a [routerLink]="['/socio/pagina-retos']" [queryParams]="{idRetoMaestro: el.idRetoMaestro}">{{el.reto}}</a>
        <div>{{ el.escuela }} </div>
      </div>

      <div class="flex-fill col-1">
        {{el.duracion}}
      </div>

      <div class="flex-fill col-1">
        {{el.periodoSemanal }}
      </div>

      <div class="flex-fill col-1">
        {{el.periodoSemestral}}
      </div>

      <div class="flex-fill col-2">
        <img alt="alcance" src="assets/img/ico_alcance.png"> {{el.campusCoordinador}}
      </div>

      <div class="flex-fill col-1 d-flex justify-content-start" *ngIf="'registrado'== el.claveEstatusSolicitud">
        <img alt="estatus" class="tam" src="assets/svg/ev_{{el.claveEstatusSolicitud}}.svg">
      </div>
      <div class="flex-fill col-1 d-flex justify-content-start" *ngIf="'registrado'!= el.claveEstatusSolicitud">
        <img alt="estatus" class="tam" src="assets/img/status_{{el.claveEstatusSolicitud}}.png">
      </div>

      <div class="flex-fill col-1">
        <app-button (callbackAction)="verDetalle(el.idRetoMaestro)">Ver</app-button>
      </div>

    </div>

    <div *ngIf="vinculacionesList.length === 0">
      <app-sin-datos-table></app-sin-datos-table>
    </div>

  </div>

  <nav id="pagination" class="d-none d-md-block mt-4" *ngIf="numeroPaginas > 0">
    <ul class="pagination justify-content-center">
      <li class="page-item" [class.disabled]="paginaActual==1">
        <a class="page-link" (click)="obtenerRegistrosActuales(paginaActual-1)">{{ "paginador.anterior" | translate }}
          <img alt="Anterior" class="next_previous" src="assets/img/icon pag_ant.svg"></a>
      </li>
      <li class="page-item" *ngFor="let num of pages" [class.active]="paginaActual==num">
        <a class="page-link" (click)="obtenerRegistrosActuales(num)">{{num}}</a>
      </li>
      <li class="page-item" [class.disabled]="paginaActual>=numeroPaginas">
        <a class="page-link" (click)="obtenerRegistrosActuales(paginaActual+1)"><img class="next_previous"
            alt="siguiente" src="assets/img/icon pag_sig.svg"> {{ "paginador.siguiente" | translate }}</a>
      </li>
    </ul>
    <div class="text-center">{{ "paginador.total_paginador" | translate: {paginaActual: paginaActual, totalPaginas:
      numeroPaginas } }}</div>
  </nav>

  <div class="d-block d-md-none">
    <div class="accordion" id="ac_1">
      <div class="accordion-item" *ngFor="let el of elementsTo">
        <h2 class="accordion-header">
          <button class="accordion-button" type="button" data-bs-toggle="collapse"
            [attr.data-bs-target]="'#flush-collapse'+el.idSolicitud" aria-expanded="true">
            {{el.reto}}
          </button>
        </h2>
        <div id="flush-collapse{{el.idSolicitud}}" class="accordion-collapse collapse show" data-bs-parent="#ac_1">
          <div class="accordion-body">
            <strong [innerHTML]="'vinculaciones.FECHA' | translate "></strong><br>{{el.fechaSolicitud}}<br><strong
              [innerHTML]="'vinculaciones.alcance' | translate "></strong><br>{{el.alcance}}<br><strong
              [innerHTML]="'vinculaciones.estatus' | translate "><br /></strong>
            <div class="flex-fill d-flex justify-content-start">
              <img class="tam" alt="Revisión" src="assets/img/status_revision.png">
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-2 d-flex justify-content-center" *ngIf="paginaActualMasResultados < numeroPaginas">
      <button class="mb_mas" (click)="obtenerMasResultados()"><img class="tam-icono-mas" alt="Más resultados"
          src="assets/img/icon_mas_bullet.svg"> {{ 'vinculaciones.mas_resultados' | translate }}</button>
    </div>

    <div class="mt-2 d-flex justify-content-center"
      *ngIf="paginaActualMasResultados == numeroPaginas && elementsTo.length <= numberPerPage && vinculacionesListTotal.length > numberPerPage">
      <button class="mb_mas" (click)="obtenerMasResultados()"><img class="tam-icono-mas" alt="Más resultados"
          src="assets/img/icon_mas_bullet.svg"> {{ 'paginador.resultados_anteriores' | translate }} </button>
    </div>

    <div *ngIf="elementsTo.length === 0">
      <app-sin-datos-table></app-sin-datos-table>
    </div>

  </div>
</div>