<div class="row" *ngIf="status!=='formalizacion' || clavePerfil === 'socio'">

    <div class="subtitlecard textblue">SOBRE EL SOCIO FORMADOR </div>

    <div class="col-1 left mt-4 ms-4">
        <img src="{{ lbl_imgSource }}" alt="Reto" class="imgsocio">
    </div>

    <div class="col-10 left my-3 ms-3">
        <div class="title-option">Razón Social y RFC:</div>
        <div class="txt-option textblue cursor-pointer"><u><span
                    (click)="mostrarDetalleSocioFormador(lbl_Descripcion)">{{
                    lbl_razonSocial }}</span></u></div>
        <div class="txt-option ">{{ lbl_rfc }}</div>
    </div>

    <div class="ms-3 subtitlecard textblue">Contacto(s): </div>

    <div class="ms-3 mt-0 pt-0 pb-0">
        <div class="row">
            <div class="col-4" *ngFor="let contacto of lstContactosReto">
                <div class="title-option"><b>Nombre:</b></div>
                <div class="txt-option">{{contacto.nombre}}</div>
                <div class="title-option"><b>Apellidos:</b></div>
                <div class="txt-option">{{contacto.apellidos ?? '-'}}</div>
                <div class="title-option"><b>Correo electrónico:</b></div>
                <div class="txt-option">{{contacto.email}}</div>
                <div class="title-option"><b>Puesto:</b></div>
                <div class="txt-option">{{contacto.puesto}}</div>
            </div>
        </div>
    </div>


</div>

<div class="section-reto row" *ngIf="status==='formalizacion'  && ['cvdp','cvdp_admin','academia'].includes(clavePerfil)">

    <div class="subtitlecard textblue">PASO 2: SOBRE EL SOCIO FORMADOR </div>
    <div class="section">
        <div class="txtcard text-secondary"> Selecciona el Socio Formador con el que estás trabajando este reto. Además,
            indica a
            la(s) personas de la organización involucradas.
            En caso de que no estén en el listado, selecciona la opción "Otro" para que puedas registrarlo. Recuerda
            que las personas que registres serán a las que se les hará llegar la encuesta de evaluación una vez que
            termine el reto. </div>
        <div class="mt-4 ">
            <button (click)="mostrarSocioFormador()" class="yell_button">Asignar el Socio Formador</button>
        </div>
        <div *ngIf="documentarSocio" class="row">
            <form [formGroup]="sobresfForm">
                <div class="row mt-4">

                    <!-- 1 -->
                    <div class="col-1 "><button _ngcontent-cen-c213="" class="step-button-active text-center"> 1
                        </button>
                    </div>
                    <div class="col-11 ">

                        <span class="question-text title-option">¿Ya habías realizado a través de esta plataforma alguna solicitud de vinculación con un Socio Formador para este reto? Si seleccionas Sí, en el siguiente paso sólo podrás seleccionar a los Socios Formadores con los que has solicitado vinculación. Si seleccionas la opción No, podrás seleccionar a cualquiera de los Socios Formadores disponibles en el banco de Socios Formadores.</span>
                        <br /><br />
                        <label class="rcontainer" (click)="obtenerSFBuscados()">
                            <input formControlName="busqueda" type="radio" value="Si" >
                            <span class="checkmark"> Sí</span>

                        </label>
                        <label class="rcontainer section" (click)="getSociosFormadores()">
                            <input formControlName="busqueda" type="radio" value="No" >
                            <span class="checkmark"> No</span>
                        </label>
                        <br>
                    </div>

                    <!-- 2 -->
                    <div class="col-1 mt-2"><button _ngcontent-cen-c213="" class="step-button-active text-center"> 2
                        </button>
                    </div>
                    <div class="col-11 mt-2">
                        <span class="title-option">Selecciona al Socio Formador</span>
                        <div class="select-dropdown">
                            <ng-select formControlName="sf" (change)="getContactos($event)" [items]="sociosData" #ngSFSocio
                                bindLabel="nombreSocioFormador" bindValue="idSocioFormador">
                            </ng-select>
                        </div><br>
                    </div>

                    <div class="col-1 left mt-4 ms-4">
                        <img src="{{ lbl_imgSource }}" alt="Reto" class="imgsocio">

                    </div>
                    <div class="col-10 left mt-4 ms-3">
                        <p class="title-option">Razón Social y RFC:</p>
                        <p class="titlecard textblue notspace cursor-pointer"><u><span
                                    (click)="mostrarDetalleSocioFormador(lbl_Descripcion)">{{ lbl_razonSocial
                                    }}</span></u></p>
                        <p class="txt-option">{{ lbl_rfc }}</p>
                    </div>
                    <br>
                    <div class="col-1"></div>

                    <!-- 3 -->
                    <div class="col-1 mt-4"><button _ngcontent-cen-c213="" class="step-button-active text-center"> 3
                        </button>
                    </div>
                    <div class="col-11 mt-4">
                        <span class="title-option">Selecciona la(s) persona(s) de contacto del SF:</span>
                        <div class="select-dropdown">
                            <ng-select [items]="contactosData" bindLabel="nombre" bindValue="idContacto" #ngSFContacto
                                formControlName="idContacto" (change)="agregarNuevoContacto($event)"></ng-select>
                        </div><br>
                    </div>

                    <div class="col-1">&nbsp;</div>
                    <div class="bold col-11" *ngIf="showAddContactToMatch">
                        <button class="add_button mt-4 mb-4" type="button" (click)="addContacto()"> Agregar
                            contacto</button>
                    </div>

                    <div class="col-7"></div>
                    <div class="mt-1 pt-0 mb- pb-0">
                        <div class="row my-4">
                            <div class="col-4" *ngFor="let contacto of lstContactosReto">
                                <button type="button" class="col-1 lst_bt_brr" (click)="eliminarContacto(contacto.idRetoContacto)"></button>
                                <div class="title-option">Nombre: </div>
                                <div class="txt-option">{{contacto.nombre}}</div>
                                <div class="title-option">Apellidos:</div>
                                <div class="txt-option">{{contacto.apellidos ?? "-"}}</div>
                                <div class="title-option">Correo electrónico:</div>
                                <div class="txt-option">{{contacto.email ?? "-"}}</div>
                                <div class="title-option">Puesto:</div>
                                <div class="txt-option">{{contacto.puesto ?? "-"}}</div>

                            </div>
                            
                        </div>
                    </div>

                </div>
            </form>
        </div>
    </div>

</div>

<!-- nuevo Contacto -->
<ng-template #nuevoContacto let-c="dismiss">
    <div class="modal-body">
        <!-- Registro de Contacto -->
    <form [formGroup]="contactoNuevoForm">
        <!-- Datos de Contacto -->
        <div class="col-12" >

            <!-- Título Datos de Contacto -->
            <div class="frm_titulo_seccion mt-3">
                Datos de contacto
            </div>

            <div class="row">

                <div class="col-12 col-sm-6">
                    <div class="pl-60px">

                        <!-- Nombre -->
                        <div>
                            <div class="d-flex1 justify-content-center1">
                                <div class="form-group">

                                    <label for="nombre_contacto" class="form-label">Nombre <span
                                            class="requerido">*</span></label>
                                    <input type="text" class="form-control" id="nombre_contacto" name="nombre_contacto"
                                        formControlName="nombre_contacto" placeholder="Escribe el nombre aquí">
                                    <div *ngIf="contactoNuevoForm.get('nombre_contacto')?.touched && contactoNuevoForm.get('nombre_contacto')?.errors?.['required']"
                                        class="error">
                                        El campo es requerido
                                    </div>
                                    <div *ngIf="contactoNuevoForm.get('nombre_contacto')?.touched && contactoNuevoForm.get('nombre_contacto')?.errors?.['minlength']"
                                        class="error">
                                        Mínimo 3 letras
                                    </div>
                                </div>
                            </div>
                        </div>


                        <!-- Apellidos -->
                        <div>
                            <div class="d-flex1 justify-content-center1">
                                <div class="form-group">
                                    <label for="apellidos_contacto" class="form-label">Apellidos <span
                                            class="requerido">*</span></label>
                                    <input type="text" class="form-control" id="apellidos_contacto"
                                        name="apellidos_contacto" formControlName="apellidos_contacto"
                                        placeholder="Escribe los apellidos aquí">
                                    <div *ngIf="contactoNuevoForm.get('apellidos_contacto')?.touched && contactoNuevoForm.get('apellidos_contacto')?.errors?.['required']"
                                        class="error">
                                        El campo es requerido
                                    </div>
                                    <div *ngIf="contactoNuevoForm.get('apellidos_contacto')?.touched && contactoNuevoForm.get('apellidos_contacto')?.errors?.['minlength']"
                                        class="error">
                                        Mínimo 3 letras
                                    </div>
                                </div>
                            </div>
                        </div>



                        <!-- Area que pertenece -->
                        <div>
                            <div class="d-flex1 justify-content-center1 d-none">
                                <div class="form-group">
                                    <label for="area_departamento_contacto" class="form-label">Área a la que perteneces
                                        <span class="requerido">*</span></label>

                                    <div class="select-dropdown">
                                        <ng-select class=" colorGray" [items]="Areas" bindLabel="area"
                                            bindValue="idArea" placeholder="Selecciona una opción"
                                            formControlName="area_departamento_contacto"></ng-select>
                                    </div>

                                    <div *ngIf="contactoNuevoForm.get('area_departamento_contacto')?.touched && contactoNuevoForm.get('area_departamento_contacto')?.errors?.['required']"
                                        class="error">
                                        El campo es requerido
                                    </div>
                                    <div *ngIf="contactoNuevoForm.get('area_departamento_contacto')?.touched && contactoNuevoForm.get('area_departamento_contacto')?.errors?.['minlength']"
                                        class="error">
                                        Minimo 3 letras
                                    </div>
                                </div>
                            </div>
                        </div>


                        <!-- Puesto -->
                        <div>
                            <div class="d-flex1 justify-content-center1">
                                <div class="form-group">
                                    <label for="puesto_contacto" class="form-label">Puesto <span
                                            class="requerido"></span></label>
                                    <input type="text" class="form-control" id="puesto_contacto" name="puesto_contacto"
                                        formControlName="puesto_contacto" placeholder="Escribe el puesto aquí">
                                    <div *ngIf="contactoNuevoForm.get('puesto_contacto')?.touched && contactoNuevoForm.get('puesto_contacto')?.errors?.['required']"
                                        class="error">
                                        El campo es requerido
                                    </div>
                                    <div *ngIf="contactoNuevoForm.get('puesto_contacto')?.touched && contactoNuevoForm.get('puesto_contacto')?.errors?.['minlength']"
                                        class="error">
                                        Mínimo 3 letras
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="col-12 col-sm-6">

                    <!-- Correo electrónico -->
                    <div>
                        <div class="d-flex1 justify-content-center1">
                            <div class="form-group">
                                <label for="email_contacto" class="form-label">Correo electrónico <span
                                        class="requerido">*</span></label>
                                <input type="text" class="form-control" id="email_contacto" name="email_contacto"
                                    formControlName="email_contacto" placeholder="Escribe el correo electrónico aquí">
                                <div *ngIf="contactoNuevoForm.get('email_contacto')?.touched && contactoNuevoForm.get('email_contacto')?.errors?.['required']"
                                    class="error">
                                    El campo es requerido
                                </div>
                                <div *ngIf="contactoNuevoForm.get('email_contacto')?.touched && contactoNuevoForm.get('email_contacto')?.errors?.['email']"
                                    class="error">
                                    Correo no válido
                                </div>
                            </div>
                        </div>
                    </div>



                    <!-- Telefono Oficina -->
                    <div>
                        <div class="d-flex1 justify-content-center1">
                            <div class="form-group">
                                <label for="telefono_oficina_contacto" class="form-label">Teléfono de oficina</label>
                                <input type="text" class="form-control" id="telefono_oficina_contacto"
                                    name="telefono_oficina_contacto" formControlName="telefono_oficina_contacto">
                                <div *ngIf="contactoNuevoForm.get('telefono_oficina_contacto')?.errors?.['pattern']"
                                    class="error">
                                    Deben ser sólo números
                                </div>
                            </div>
                        </div>
                    </div>


                    <!-- Telefono de Celular -->
                    <div>
                        <div class="d-flex1 justify-content-center1 ">
                            <div class="form-group">
                                <label for="celular_contacto" class="form-label">Teléfono celular</label>
                                <input type="text" class="form-control" id="celular_contacto" name="celular_contacto"
                                    formControlName="celular_contacto">
                                <div *ngIf="contactoNuevoForm.get('celular_contacto')?.errors?.['pattern']" class="error">
                                    Deben ser sólo números
                                </div>
                            </div>
                        </div>
                    </div>


                    <!-- Eres EXATEC -->
                    <div class="row ">
                        <div class="d-flex1 justify-content-center1 d-none">
                            <div class="form-group">
                                <label for="exatec_contacto" class="form-label">¿Eres EXATEC? <span
                                        class="requerido">*</span></label>
                                <div class="">
                                    <select class="form-control" formControlName="exatec_contacto">
                                        <option value="" selected disabled>Selecciona una opción</option>
                                        <option value="true">Si</option>
                                        <option value="false">No</option>
                                    </select>
                                    <div *ngIf="contactoNuevoForm.get('exatec_contacto')?.touched && contactoNuevoForm.get('exatec_contacto')?.errors?.['required']"
                                        class="error">
                                        El campo es requerido
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>


                    <!-- Cómo te enteraste -->
                    <div class="row">
                        <div class="d-flex1 justify-content-center1 d-none">
                            <div class="form-group">
                                <label for="enteraste_contacto" class="form-label">¿Cómo te enteraste de esta
                                    plataforma? <span class="requerido">*</span></label>

                                <div class="select-dropdown">
                                    <ng-select class=" colorGray" [items]="MediosDeContacto" bindLabel="medioContacto"
                                        bindValue="idMedioContacto" placeholder="Selecciona una opción"
                                        formControlName="enteraste_contacto"></ng-select>
                                </div>



                                <div *ngIf="contactoNuevoForm.get('enteraste_contacto')?.touched && contactoNuevoForm.get('enteraste_contacto')?.errors?.['required']"
                                    class="error">
                                    El campo es requerido
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>        
    </form>

    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-brown" (click)="cancelNewContact()">Cerrar</button>
        <button type="button" class="btn btn-amarillo" (click)="guardarNuevoContacto()">Guardar</button>
    </div>
</ng-template>