import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { COLS_SOCIOS_FORMADORES_VINCULAR } from "@pages/dashboard/academia-dashboard/academia-dashboard.columns";
import {
  IEscuelaDepartamento,
  IPeriodoSemanal,
  IPeriodoSemestral,
} from "@shared/Interface/ICatalogos.interface";
import { IUser } from "@shared/Interface/user";
import { CatalogosService } from "@shared/service/Catalogos/catalogos.service";
import { DashboardService } from "@shared/service/Dashboard/dashboard.service";
import { InicioService } from "@shared/service/Inicio/inicio.service";
import { UsuariosService } from "@shared/service/Usuarios/usuarios.service";
import { lastValueFrom, tap } from "rxjs";

@Component({
  selector: "app-solicitudes-vinculacion-retos",
  templateUrl: "./solicitudes-vinculacion-retos.component.html",
  styleUrls: ["./solicitudes-vinculacion-retos.component.css"],
})
export class SolicitudesVinculacionRetosComponent implements OnInit {
  sidebarExpanded = false;
  lang: string = "es";

  titleSociosFormadores = {
    title: "Socios Formadores que se quieren vincular a mis retos",
  };

  backgroundTable = "#F6F6F6";
  // ** Socios Formadores que se quieren vincular a retos
  sociosFormadoresColumns = COLS_SOCIOS_FORMADORES_VINCULAR;

  sociosFormadoresData: any[] = [];
  sociosFormadoresRechazadoaData: any[] = [];
  sociosFormadoresAceptadasData: any[] = [];
  sociosFormadoresDataPage: any[] = [];
  sociosFormadoresRechazadoaDataPage: any[] = [];
  sociosFormadoresAceptadasDataPage: any[] = [];

  periodoSemestralData: IPeriodoSemestral[] = [];
  periodoSemanalData: IPeriodoSemanal[] = [];
  departamentoData: IEscuelaDepartamento[] = [];

  filter: any = {
    query: "",
    idPeriodoSemestral: 0,
    idPeriodoSemanal: 0,
    departamento: 0,
  };

  pagina: number = 0;
  hits: number = 0;
  localdb: any = [];

  constructor(
    private dashboardService: DashboardService,
    private usuarioService: UsuariosService,
    private catalogosService: CatalogosService,
    private changeDetector: ChangeDetectorRef,
    private inicioService: InicioService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.lang = localStorage.getItem("lang") ?? "es";
    this.loadCatalogos();
    this.loadData();
  }

  changeLang(lang: string) {
    this.lang = lang;
    localStorage.setItem("lang", lang);
    window.location.reload();
  }

  // Se agrega función compatible con el buscador MainLayoutBuscador
  buscadorPress(keyWord: any) {
    // Aquí iría lo que el padre tenga que hacer

    localStorage.removeItem("localdb");
    localStorage.removeItem("localdbFilters");

    if (
      keyWord !== null &&
      keyWord !== undefined &&
      keyWord.toString().trim() !== ""
    ) {
      this.filter.query = keyWord;
    } else {
      this.filter.query = "";
    }
    this.loadData();
  }

  getValueCatalogo(obj: any, index: string, id: number, indexValue: string) {
    let obje = obj.filter((x: any) => x[index] === id).pop();
    if (obje) {
      return obje[indexValue];
    } else {
      return null;
    }
  }

  async addFilter(type: string, id: number) {
    localStorage.removeItem("localdb");
    localStorage.removeItem("localdbFilters");
    this.filter[type] = id;
    this.loadData();
  }

  setFilter(type: string, id: number) {
    this.filter[type] = id;
  }

  async cleanFilters() {
    localStorage.removeItem("localdbFilters");
    localStorage.removeItem("localdb");

    this.filter = {
      query: "",
      idPeriodoSemestral: 0,
      idPeriodoSemanal: 0,
      departamento: 0,
    };
    this.loadData();
  }

  loadCatalogos() {
    this.catalogosService
      .getPeriodoSemestral()
      .pipe(
        tap(
          (resultado: IPeriodoSemestral[]) =>
            (this.periodoSemestralData = resultado)
        )
      )
      .subscribe();

    this.catalogosService
      .getPeriodoSemanal()
      .pipe(
        tap(
          (resultado: IPeriodoSemanal[]) =>
            (this.periodoSemanalData = resultado)
        )
      )
      .subscribe();

    this.catalogosService
      .getDepartamentos()
      .pipe(
        tap(
          (resultado: IEscuelaDepartamento[]) =>
            (this.departamentoData = resultado.filter(
              (item) => item.departamento != "No Aplica" && item.idEscuela == this.usuarioService.getCurrentUserData().idEscuela
            ))
        )
      )
      .subscribe();
  }

  async loadData() {
    let usr: IUser = this.usuarioService.getCurrentUserData();

    this.sociosFormadoresData = [];
    this.sociosFormadoresRechazadoaData = [];
    this.sociosFormadoresAceptadasData = [];
    this.sociosFormadoresDataPage = [];
    this.sociosFormadoresRechazadoaDataPage = [];
    this.sociosFormadoresAceptadasDataPage = [];

    this.chekfilters();
    let tmp = localStorage.getItem("localdb");
    //if (tmp) {
      this.localdb = tmp ? JSON.parse(tmp) : '';
      if (this.localdb.aprobar && this.localdb.aceptado && this.localdb.rechazado) {
        this.sociosFormadoresData = this.localdb.aprobar;
        this.sociosFormadoresAceptadasData = this.localdb.aceptado;
        this.sociosFormadoresRechazadoaData = this.localdb.rechazado;
      //}
    } else {
      // Socios Formadores que se quieren vincular a retos
      let response = await lastValueFrom(
        this.dashboardService.getAcademiaVinculaciones(
          usr.idUsuario,
          "aprobado",
          this.filter.idPeriodoSemestral,
          this.filter.idPeriodoSemanal,
          this.filter.departamento,
          this.filter.query
        )
      );
      this.sociosFormadoresData = this.getSociosDashboard(response);

      response = await lastValueFrom(
        this.dashboardService.getAcademiaVinculaciones(
          usr.idUsuario,
          "aceptado",
          this.filter.idPeriodoSemestral,
          this.filter.idPeriodoSemanal,
          this.filter.departamento,
          this.filter.query
        )
      );
      this.sociosFormadoresAceptadasData = this.getSociosDashboard(response);

      response = await lastValueFrom(
        this.dashboardService.getAcademiaVinculaciones(
          usr.idUsuario,
          "rechazado",
          this.filter.idPeriodoSemestral,
          this.filter.idPeriodoSemanal,
          this.filter.departamento,
          this.filter.query
        )
      );
      this.sociosFormadoresRechazadoaData = this.getSociosDashboard(response);

      this.saveFilters();
      let vinculacionesList = {
        aprobar: this.sociosFormadoresData,
        aceptado: this.sociosFormadoresRechazadoaData,
        rechazado: this.sociosFormadoresAceptadasData

      };

      localStorage.setItem("localdb", JSON.stringify(vinculacionesList));
    }


  }

  saveFilters(){
    let filters = {
        query:              this.filter.query,
        idPeriodoSemestral: this.filter.idPeriodoSemestral,
        idPeriodoSemanal:   this.filter.idPeriodoSemanal,
        departamento:       this.filter.departamento
    };
    localStorage.setItem('localdbFilters',JSON.stringify(filters));
    this.inicioService.changeFilterQuery(this.filter.query);
  }

  chekfilters(){
    let tmp1 = localStorage.getItem('localdbFilters');
    let ret = false;
    if(tmp1){
      let tmp = JSON.parse(tmp1);

        this.filter.query = tmp.query;
        this.filter.idPeriodoSemestral = tmp.idPeriodoSemestral;
        if (this.filter.idPeriodoSemestral > 0)
           this.setFilter('idPeriodoSemestral', this.filter.idPeriodoSemestral);
        this.filter.idPeriodoSemanal = tmp.idPeriodoSemanal;
        if (this.filter.idPeriodoSemanal > 0)
           this.setFilter('idPeriodoSemanal', this.filter.idPeriodoSemanal);
        this.filter.departamento = tmp.departamento;
        if (this.filter.departamento > 0)
           this.setFilter('departamento', this.filter.departamento);

        ret = true;
    }
    return ret;
  }


  getSociosDashboard(resultado: any) {
    return resultado.map((socioaVincular: any) => ({
      campus: socioaVincular.campus,
      duracionSemanas: socioaVincular.duracion ?? "-",
      periodoSemanal: socioaVincular.periodoSemanal ?? "-",
      periodoSemestral: socioaVincular.periodoSemestral ?? "-",
      fechaSolicitud: socioaVincular.fechaRegistro,
      imageTitle: {
        title: socioaVincular.nombreSocioFormador,
        link: `solicitud-vinculacion-a-reto/${socioaVincular.idSocioFormador}/${socioaVincular.idRetoMaestro}/${socioaVincular.idSolicitud}`,
        subtitle: socioaVincular.nombreContacto,
        image: socioaVincular.urlFotoContacto,
      },
      reto: {
        title: socioaVincular.reto,
        subtitle: socioaVincular.escuela,
      },
      nombreSocioFormador: {
        title: socioaVincular.nombreSocioFormador,
        subtitle: socioaVincular.rfc,
      },
      claveEstatusSolicitud: {
        textCol: "Ver",
        statusType: "primary",
        link: `solicitud-vinculacion-a-reto/${socioaVincular.idSocioFormador}/${socioaVincular.idRetoMaestro}/${socioaVincular.idSolicitud}`,
      },
    }));
  }

  showPaginationDataAprobar(objData: any) {
    if (objData.length) {
      this.sociosFormadoresDataPage = objData;
      this.changeDetector.detectChanges();
    }
  }

  showPaginationDataAceptada(objData: any) {
    if (objData.length) {
      this.sociosFormadoresAceptadasDataPage = objData;
      this.changeDetector.detectChanges();
    }
  }

  showPaginationDataRechazada(objData: any) {
    if (objData.length) {
      this.sociosFormadoresRechazadoaDataPage = objData;
      this.changeDetector.detectChanges();
    }
  }
}
