import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-socio-search',
  templateUrl: './socio-search.component.html',
  styleUrls: ['./socio-search.component.css'],
})
export class SocioSearchComponent  {
  retosInfo: any;
  companiesData: any = [  ];
  constructor(private router: Router) {}


}
