<app-header-buscador lbl_placeholder="Buscar un Usuario" (fatherNameFunction)="buscadorPress($event)"></app-header-buscador>

<app-typeform idForm="rooix9jw"></app-typeform>

<div>
	<div >
		<div class="sec_ad">
			<app-title-show-more [showMoreObject]="{title: 'gestion_usuarios.titulo' | translate} "></app-title-show-more>

			<div id="sec_tab" class="nav nav-pills d-flex flex-md-nowrap flex-wrap" role="presentation">

				<div class="active" data-bs-toggle="pill" data-bs-target="#tab-socios-formadores" aria-controls="tab-socios-formadores" prm="re_ac" role="tab" type="button" aria-selected="true" [innerHTML]="'gestion_usuarios.socios_formadores' | translate " [ngClass]="{active: activeTab === 'socios'}" (click)="changetab('socios')">
					asd
				</div>
				<div prm="re_hs" data-bs-toggle="pill" data-bs-target="#tab-academia" aria-controls="tab-academia" class="" role="tab" type="button" aria-selected="false" [innerHTML]="'gestion_usuarios.academica' | translate" [ngClass]="{active: activeTab === 'academia'}" (click)="changetab('academia')">
				</div>
				<div prm="re_hs" data-bs-toggle="pill" data-bs-target="#tab-cvdp" aria-controls="tab-cvdp" class="" role="tab" type="button" aria-selected="false" [innerHTML]="'gestion_usuarios.cvdp' | translate" [ngClass]="{active: activeTab === 'cvdp'}" (click)="changetab('cvdp')">
				</div>
			</div>

			<div class="tab-content" id="pills-tabContent">
				<div class="tab-pane fade " id="tab-socios-formadores" role="tabpanel" aria-labelledby="tab-socios-formadores-tab" [ngClass]="{'active show': activeTab === 'socios'}">
					<app-socios-formadores></app-socios-formadores>
				</div>
				<div class="tab-pane fade" id="tab-academia" role="tabpanel" aria-labelledby="tab-academia-tab" [ngClass]="{'active show': activeTab === 'academia'}">
					<app-academia></app-academia>
				</div>
				<div class="tab-pane fade" id="tab-cvdp" role="tabpanel" aria-labelledby="tab-cvdp-tab" [ngClass]="{'active show': activeTab === 'cvdp'}">
					<app-cvdp></app-cvdp>
				</div>
			</div>
		</div>
	</div>
</div>

