<app-header-buscador [showSearchBar]="false"></app-header-buscador>

<div class="flex-fill" *ngIf="!newContactCreate">
    <app-btn-regresar></app-btn-regresar>

    <div class="sec_ad">
        <app-title-show-more [showMoreObject]="{title: 'gestion_contactos.titulo' | translate} "></app-title-show-more>
    </div>

    <!-- Sección para buscar el socio formador -->
    <div class="bkgatomic p-4">

        <div class="col-12">
            <span>Nombre del Socio Formador:&nbsp;
            </span>
            <div class="row">
                <div class="col-6">

                    <ng-select aria-label="Default example" (change)="getDatosSocioformador($event.idSocioFormador)"
                        [items]="sociosFormadoresData" bindLabel="nombreSocioFormador" bindValue="idSocioFormador"
                        [ngModel]="socioFormadorSeleccionado">
                    </ng-select>
                </div>
                <div class="col-6 d-none">
                    <app-button (callbackAction)="getContactos()">Ver Contactos</app-button>
                </div>
            </div>
        </div>

        <div class="col bkgatomic mt-3 p-4" *ngIf="socioFormadorSeleccionado">
            <div class="row mb-3">
                <div class="col">
                    <img class="imgpchip_med" alt="Información del Socio Formador"
                        src="assets/svg/icon_info.svg" />&nbsp;
                    <span class="titatomic">
                        <strong>Información del Socio Formador seleccionado</strong>
                    </span>
                </div>
            </div>

            <div><strong>{{ SF_razon }}</strong></div>

            <div class="col-12 mb-2">
                <span class="mb-2">{{ SF_empresa }}</span>
            </div>
            <div class="d-none">
                <div class="col-6">
                    <strong>Sector:</strong>
                </div>
                <div class="col-6 mb-2">
                    <span> {{ SF_sector }} </span>
                </div>
                <div class="col-6">
                    <strong>Tamaño de Empresa:</strong>
                </div>
                <div class="col-6 mb-2">
                    <span>{{ SF_tamano }} </span>
                </div>
                <div class="col-6">
                    <strong>Industria:</strong>
                </div>
                <div class="col-6 mb-2">
                    <span>{{ SF_industria }}</span>
                </div>
                <div class="col-6">
                    <strong>Presencia:</strong>
                </div>
                <div class="col-6 mb-2">
                    <span>{{ SF_presencia }}</span>
                </div>
            </div>

        </div>

    </div>
    <div class="mt-3" *ngIf="showAddContactButton">
        <app-button (callbackAction)="agregar()">Agregar</app-button>
    </div>
    <!-- Sección para mostrar los contactos -->
    <div class="mt-3" *ngIf="contactosData.length > 0">


        <div id="c_sf" class="sec_cn tipo-10 mt-4">
            <div class="">
                <div class="cont_tit d-flex">
                    <div class="flex-fill col-1">Fecha Registro</div>
                    <div class="flex-fill col-3">Nombre <br /> Puesto / Area</div>
                    <div class="flex-fill col-3">Socio Formador <br /> RFC <br /> Campus</div>
                    <div class="flex-fill col-2">Correo</div>
                    <div class="flex-fill col-2">Teléfono</div>
                    <div class="flex-fill col-1">Acciones</div>
                </div>
                <div class="roundedsquare cont_fl d-flex align-items-center" *ngFor="let el of contactosData">
                    <div class="flex-fill col-1"> {{ el.fechaRegistro | date: 'dd/MMM/yyyy'}}</div>
                    <div class="flex-fill col-3 d-flex align-items-center">
                        <div>
                            <div>
                                <div>{{el.nombre }} {{ el.apellidos }}<br /> {{el.puesto }} / {{ obtenerArea(el.area)
                                    }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="flex-fill col-3">
                        {{el.nombreSocioFormador || 'Socio Formador'}}
                        <br> {{ el.rfcSocioFormador || 'XXXX000000'}}
                        <br> {{ el.campusConvenio || 'CampusConvenio'}}
                    </div>
                    <div class="flex-fill col-2">{{el.email}}</div>
                    <div class="flex-fill col-2">Oficina: {{el.telefonoOficina}} <br /> Móvil: {{ el.telefonoMovil }}
                    </div>
                    <div class="flex-fill col-1">
                        <span><button class="lst_bt_edt" (click)="editar(el.idContacto)"></button></span>
                        <span><button class="lst_bt_brr" (click)="eliminar(el.idUsuario)"></button></span>
                    </div>
                </div>
            </div>

            <div *ngIf="contactosData.length === 0">
                <app-sin-datos-table></app-sin-datos-table>
            </div>

        </div>

    </div>
</div>

<div class="" *ngIf="newContactCreate">

    <div class="col bkgatomic mt-3 p-4 mb-5" >
        <div class="row mb-3">
            <div class="col">
                <img class="imgpchip_med" alt="Información del Socio Formador" src="assets/svg/icon_info.svg" />&nbsp;
                <span class="titatomic">
                    <strong>Información del Socio Formador seleccionado</strong>
                </span>
            </div>
        </div>

        <div>
            <div>Nombre:</div>
            <div class="ms-3">
                <strong>{{ socioFormadorSeleccionado.nombreSocioFormador }}</strong>
            </div>

            <div>Razón Social:</div>
            <div class="ms-3">
                <strong>{{ SF_razon }}</strong>
            </div>

        </div>

        <div class="col-12 mb-2">
            <div>Descripción:</div>
            <span class="mb-2 ms-3">{{ SF_empresa }}</span>
        </div>
    </div>
    <app-perfil-contact *ngIf="newContactCreate" [mainContactObj]="editContactData" [newContact]="newContactCreate"
        (callbackShowContacts)="cancelAndshowContacts()"></app-perfil-contact>

</div>
