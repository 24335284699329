<div class="section">
  <div *ngIf="status!='formalizacion' || clavePerfil === 'socio'">
    <div class="title-option mb-3">Entregables</div>

    <div class="row " *ngFor="let entregable of entregablesList; let i=index">
      <div class="col-12">
        <span class="title-option-small mb-3">Entregable {{i+1}} <br /></span>

        <label class="title-option-small" for="descripcion{{i}}"><u>a. Descripción:</u>&nbsp;</label>
        <label class="txt-option-small">{{entregable.descripcion}}</label><br>

        <label class="title-option-small" for="momento{{i}}"><u>b. Momento de la entrega:</u>&nbsp;</label>
        <label class="txt-option-small">{{entregable.temporalidad}}</label> <br>
      </div>
    </div>

    <div *ngIf="entregablesList.length == 0">
      <span class="txt-option"> No hay entregables registrados.</span>
    </div>

  </div>

  <div class="row" *ngIf="status === 'formalizacion' && ['cvdp','cvdp_admin','academia'].includes(clavePerfil)">
    <div class="col-1"><button class="step-button-active text-center"> 6 </button>
    </div>
    <div class="col-11">
      <span class="title-option mb-2">Define uno a uno los entregables que harás al Socio Formador, incluyendo la
        temporalidad. <span class="text-danger">*</span></span>


      <div class="row row-item my-3" *ngFor="let entregable of entregablesList; let i=index">
        <div class="col-11">
          <span class="title-option-small">Entregable {{i+1}} <br /></span>

          <label class="title-option-small mt-1" for="descripcion{{i}}">Descripción:&nbsp;</label>
          <label class="txt-option-small">{{entregable.descripcion}}</label><br>

          <label class="title-option-small" for="momento{{i}}">Momento:&nbsp;</label>
          <label class="txt-option-small">{{entregable.temporalidad}}</label> <br>
        </div>
        <div class="col-1 d-flex align-items-center">
          <button type="button" class="lst_bt_brr" (click)="eliminar(entregable.idRetoEntregable)"></button>
        </div>
      </div>

      <!-- Button trigger modal -->
      <button type="button" class="add_button mt-4 mb-4" data-bs-toggle="modal" data-bs-target="#entregableFormModal">
        Agregar entregable
      </button>

      <!-- Modal -->
      <div class="modal fade" id="entregableFormModal" data-bs-backdrop="static" tabindex="-1"
        aria-labelledby="entregableFormModalLabel" aria-hidden="true" data-bs-keyboard="false" tabindex="-1">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="entregableFormModalLabel">Agregar un entregable</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" #closebutton></button>
            </div>
            <div class="modal-body">
              <form [formGroup]="entregablesForm" class="my-3">

                <span class="# "> <u> Descripción: </u> <span class="required">*</span></span>
                <textarea class="form-control" id="descripcion" rows="3" type="text" formControlName="descripcion"
                  placeholder="Escribe aquí la descripción del entregable por parte del Socio Formador"></textarea>
                <div class="required"
                  *ngIf="entregablesForm.get('descripcion')?.touched && entregablesForm.get('descripcion')?.errors?.['required']">
                  Este campo es requerido</div>
                <br>


                <span class="# "> <u> Especifica en qué momento compartirás el entregable (temporalidad)
                  </u> <span class="required">*</span></span>
                <input class="form-control" id="temporalidad" type="text" formControlName="temporalidad"
                  placeholder="Escribe aquí en qué momento requieres el entregable">
                <div class="required"
                  *ngIf="entregablesForm.get('temporalidad')?.touched && entregablesForm.get('temporalidad')?.errors?.['required']">
                  Este campo es requerido</div>

              </form>
            </div>
            <div class="modal-footer">
              <button type="button" class="add_form_button mt-4 mb-4" (click)="addEntregable()">Guardar</button>
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="col-1"></div>
  </div>
</div>
