<app-header-buscador [showSearchBar]="false"></app-header-buscador>

<div class="sec_ad">

  <app-title-show-more [showMoreObject]="{ title: 'solicitudes_sin_reto.titulo' | translate }" *featureFlag="'F2_5_HU58'"></app-title-show-more>
  <app-title-show-more [showMoreObject]="{ title: 'Problemáticas que no encontraron un reto'  }" *featureFlag="'F2_4_HU58'" ></app-title-show-more>

  <div class="row">

    <!-- Filtro de Campus -->
    <div class="col-md">
      <div class="btn-group order-by">
        <button class="btn dropdown-toggle" type="button" id="listado" data-bs-toggle="dropdown"
          data-bs-auto-close="true" aria-expanded="false" data-bs-offset="0,0"
          title="{{ filter.campus ? getValueCatalogo(campusData, 'idCampus', filter.campus, 'campus') : 'Campus' }}">
          {{ filter.campus ? getValueCatalogo(campusData, 'idCampus', filter.campus, 'campus') : 'Campus' }}
        </button>
        <ul class="dropdown-menu dropdown-menu-start" aria-labelledby="listado">
          <li *ngIf="campusData.length == 0" class="dropdown-item">Selecciona una escuela</li>
          <li *ngFor="let campus of campusData; let i = index">
            <a class="dropdown-item {{ i === 0 ? 'active' : '' }}" (click)="addFilter('campus', campus.idCampus)">{{
              campus.campus }}</a>
          </li>
        </ul>
      </div>
    </div>


    <!-- Filtro de escuela -->
    <div class="col-md">
      <div class="btn-group order-by">
        <button class="btn dropdown-toggle" type="button" id="listado" data-bs-toggle="dropdown"
          data-bs-auto-close="true" aria-expanded="false" data-bs-offset="0,0"
          title="{{ filter.escuela ? getValueCatalogo(escuelaData, 'idEscuela', filter.escuela, 'escuela') : 'Escuela' }}">
          {{ filter.escuela ? getValueCatalogo(escuelaData, 'idEscuela', filter.escuela, 'escuela') : 'Escuela' }}
        </button>
        <ul class="dropdown-menu dropdown-menu-start" aria-labelledby="listado">
          <li *ngFor="let escuela of escuelaData; let i = index">
            <a class="dropdown-item {{ i === 0 ? 'active' : '' }}" (click)="addFilter('escuela', escuela.idEscuela)">{{
              escuela.escuela }}</a>
          </li>
        </ul>
      </div>
    </div>

    <!-- Filtro de Periodo -->
    <div class="col-md" *ngIf="false">
      <div class="btn-group order-by">
        <button class="btn dropdown-toggle" type="button" id="listado" data-bs-toggle="dropdown"
          data-bs-auto-close="true" aria-expanded="false" data-bs-offset="0,0"
          title="{{ filter.periodo ? getValueCatalogo(periodoData, 'idPeriodo', filter.periodo, 'periodo') : 'Periodo' }}"
          >
          {{ filter.periodo ? getValueCatalogo(periodoData, 'idPeriodo', filter.periodo, 'periodo') : 'Periodo' }}
        </button>
        <ul class="dropdown-menu dropdown-menu-start" aria-labelledby="listado">
          <li *ngFor="let periodo of periodoData; let i = index">
            <a class="dropdown-item {{ i === 0 ? 'active' : '' }}" (click)="addFilter('periodo', periodo.idPeriodo)">{{
              periodo.periodo }}</a>
          </li>
        </ul>
      </div>
    </div>


    <!-- Reestablecer Filtros -->
    <div class="col-md d-flex justify-content-end">
      <app-button (callbackAction)="cleanFilters()"> Restablecer </app-button>
    </div>
  </div>


  <div id="sec_tab" class="nav nav-pills d-flex flex-md-nowrap flex-wrap" role="presentation" *featureFlag="'F2_5_HU58'">
      <div class="active" data-bs-toggle="pill" data-bs-target="#tab-reto-solicitudes-aprobar" aria-controls="tab-reto-solicitudes-aprobar" prm="re_ac" role="tab" type="button" aria-selected="true">
        {{ "solicitudes_sin_reto.solicitudes_registradas" | translate }}
      </div>
      <div prm="re_hs" data-bs-toggle="pill" data-bs-target="#tab-reto-solicitudes-rechazadas" aria-controls="tab-reto-solicitudes-rechazadas" class="" role="tab" type="button" aria-selected="false">
        {{ "solicitudes_sin_reto.solicitudes_revision" | translate }}
      </div>
      <div prm="re_hs" data-bs-toggle="pill" data-bs-target="#tab-reto-solicitudes-aprobadas" aria-controls="tab-reto-solicitudes-aprobadas" class="" role="tab" type="button" aria-selected="false">
        {{ "solicitudes_sin_reto.solicitudes_atendidas" | translate }}
    </div>
  </div>

  <div id="sec_tab" class="nav nav-pills d-flex flex-md-nowrap flex-wrap" role="presentation" *featureFlag="'F2_4_HU58'">
    <div class="active" data-bs-toggle="pill" data-bs-target="#tab-reto-solicitudes-aprobar" aria-controls="tab-reto-solicitudes-aprobar" prm="re_ac" role="tab" type="button" aria-selected="true">
      Solicitudes por aprobar
    </div>
    <div prm="re_hs" data-bs-toggle="pill" data-bs-target="#tab-reto-solicitudes-rechazadas" aria-controls="tab-reto-solicitudes-rechazadas" class="" role="tab" type="button" aria-selected="false">
      Solicitudes rechazadas
    </div>
    <div prm="re_hs" data-bs-toggle="pill" data-bs-target="#tab-reto-solicitudes-aprobadas" aria-controls="tab-reto-solicitudes-aprobadas" class="" role="tab" type="button" aria-selected="false">
      Solicitudes aprobadas
    </div>
  </div>

  <div class="tab-content" id="pills-tabContent">
      <div class="tab-pane fade show active" id="tab-reto-solicitudes-aprobar" role="tabpanel" aria-labelledby="tab-reto-solicitudes-aprobar-tab">

        <app-table [backgroundColor]="backgroundTable" [columns]="sinRetoColumns" [data]="solicitudesSinRetosDataPage"></app-table>

          <app-pagination *ngIf="solicitudesSinRetosData.length" [objPagination]="solicitudesSinRetosData"
        (paginationDataChange)="showPaginationDataRevision($event)"></app-pagination>

      </div>
      <div class="tab-pane fade" id="tab-reto-solicitudes-rechazadas" role="tabpanel" aria-labelledby="tab-reto-solicitudes-rechazadas-tab">

        <app-table [backgroundColor]="backgroundTable" [columns]="sinRetoColumns" [data]="solicitudesRechazadasSinRetosDataPage"></app-table>

        <app-pagination *ngIf="solicitudesRechazadasSinRetosData.length" [objPagination]="solicitudesRechazadasSinRetosData"
        (paginationDataChange)="showPaginationDataRechazado($event)"></app-pagination>

      </div>
      <div class="tab-pane fade" id="tab-reto-solicitudes-aprobadas" role="tabpanel" aria-labelledby="tab-reto-solicitudes-aprobadas-tab">

        <app-table [backgroundColor]="backgroundTable" [columns]="sinRetosTodosColumns" [data]="solicitudesAprobadasSinRetosDataPage"></app-table>

        <app-pagination *ngIf="solicitudesAprobadasSinRetosData.length" [objPagination]="solicitudesAprobadasSinRetosData"
        (paginationDataChange)="showPaginationDataAprobado($event)"></app-pagination>

    </div>
  </div>


  <ng-template #comentarios let-c="dismiss">
    <div class="modal-body">
        <p> Comentarios realizados: </p>
        <p *ngIf="comentariosData.length == 0">
          No hay comentarios.
        </p>
        <div *ngIf="comentariosData">
          <ul>
              <li *ngFor="let com of comentariosData">
                  [{{ com.fechaRegistro }} - {{ com.nombreUsuario }}]
                  {{ com.comentarios }}
              </li>
          </ul>
      </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-brown" (click)="c('dismiss')">Cerrar</button>
    </div>
</ng-template>

