import { Component } from '@angular/core';

@Component({
  selector: 'app-registro-sf',
  templateUrl: './registro-sf.component.html',
  styleUrls: ['./registro-sf.component.css'],
})
export class RegistroSFComponent  {
  
}
