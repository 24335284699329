<div class="sec_ad">

    <!-- Solicitudes Socios Formadores Potenciales -->
    <app-title-show-more [showMoreObject]="titleSociosPotenciales"></app-title-show-more>
    <div id="sec_tab" class="nav nav-pills d-flex flex-md-nowrap flex-wrap" role="presentation">
        <div class="active" data-bs-toggle="pill" data-bs-target="#tab-solicitudes-recibida"
            aria-controls="tab-solicitudes-recibida" prm="re_ac" role="tab" type="button" aria-selected="true">
            Recibidas
        </div>
        <div data-bs-toggle="pill" data-bs-target="#tab-solicitudes-revisar" aria-controls="tab-solicitudes-revisar"
            prm="re_ac" role="tab" type="button" aria-selected="true">
            En revisión
        </div>
        <div data-bs-toggle="pill" data-bs-target="#tab-solicitudes-aprobar" aria-controls="tab-solicitudes-aprobar"
            prm="re_ac" role="tab" type="button" aria-selected="true">
            Aprobadas
        </div>
        <div prm="re_hs" data-bs-toggle="pill" data-bs-target="#tab-solicitudes-rechazadas"
            aria-controls="tab-solicitudes-rechazadas" class="" role="tab" type="button" aria-selected="false">
            Rechazadas
        </div>
    </div>
    <div class="tab-content" id="pills-tabContent">
        <div class="tab-pane fade show active" id="tab-solicitudes-recibida" role="tabpanel"
            aria-labelledby="tab-solicitudes-revisar-tab">
            <app-table [backgroundColor]="backgroundTable" [columns]="solicitudesColumns"
                [data]="solicitudesRecibidasData"></app-table>
        </div>
        <div class="tab-pane fade " id="tab-solicitudes-revisar" role="tabpanel"
            aria-labelledby="tab-solicitudes-revisar-tab">
            <app-table [backgroundColor]="backgroundTable" [columns]="solicitudesColumns"
                [data]="solicitudesRevisionData"></app-table>
        </div>
        <div class="tab-pane fade " id="tab-solicitudes-aprobar" role="tabpanel"
            aria-labelledby="tab-solicitudes-revisar-tab">
            <app-table [backgroundColor]="backgroundTable" [columns]="solicitudesColumns"
                [data]="solicitudesAprobadasData"></app-table>
        </div>
        <div class="tab-pane fade" id="tab-solicitudes-rechazadas" role="tabpanel"
            aria-labelledby="tab-solicitudes-rechazadas-tab">
            <app-table [backgroundColor]="backgroundTable" [columns]="solicitudesColumns"
                [data]="solicitudesRechazadasData"></app-table>
        </div>
    </div>

    <!-- Académico que busca un Socio Formador -->
    <div *featureFlag="'F2_5_HU69'">
        <app-title-show-more [showMoreObject]="titleAcademicoSocioFormador"></app-title-show-more>
        <div id="sec_tab" class="nav nav-pills d-flex flex-md-nowrap flex-wrap" role="presentation">
            <div class="active" data-bs-toggle="pill" data-bs-target="#tab-academico-recibidas"
                aria-controls="tab-academico-recibidas" prm="re_ac" role="tab" type="button" aria-selected="true">
                Recibidas
            </div>
            <div data-bs-toggle="pill" data-bs-target="#tab-academico-aprobar" aria-controls="tab-academico-aprobar"
                prm="re_ac" role="tab" type="button" aria-selected="true">
                En Revisión
            </div>
            <div data-bs-toggle="pill" data-bs-target="#tab-academico-aprobado" aria-controls="tab-academico-aprobado"
                class="" role="tab" type="button" aria-selected="false">
                Aceptadas
            </div>
            <div data-bs-toggle="pill" data-bs-target="#tab-academico-rechazadas"
                aria-controls="tab-academico-rechazadas" class="" role="tab" type="button" aria-selected="false">
                Rechazadas
            </div>

        </div>
        <div class="tab-content" id="pills-tabContent">
            <div class="tab-pane fade show active" id="tab-academico-recibidas" role="tabpanel"
                aria-labelledby="tab-academico-recibidas-tab">
                <app-table [backgroundColor]="backgroundTable" [columns]="academicoColumns"
                    [data]="solicitudesAcademicosRecibidasData"></app-table>
            </div>
            <div class="tab-pane fade" id="tab-academico-aprobar" role="tabpanel"
                aria-labelledby="tab-academico-aprobar-tab">
                <app-table [backgroundColor]="backgroundTable" [columns]="academicoColumns"
                    [data]="solicitudesAcademicosData"></app-table>
            </div>
            <div class="tab-pane fade" id="tab-academico-aprobado" role="tabpanel"
                aria-labelledby="tab-academico-aprobado-tab">
                <app-table [backgroundColor]="backgroundTable" [columns]="academicoColumns"
                    [data]="solicitudesAprobadaAcademicosData"></app-table>
            </div>
            <div class="tab-pane fade" id="tab-academico-rechazadas" role="tabpanel"
                aria-labelledby="tab-academico-rechazadas-tab">
                <app-table [backgroundColor]="backgroundTable" [columns]="academicoColumns"
                    [data]="solicitudesRechazadasAcademicosData"></app-table>
            </div>
        </div>
    </div>

    <div *featureFlag="'F2_4_HU69'">
        <app-title-show-more [showMoreObject]="titleAcademicoSocioFormador"></app-title-show-more>
        <div id="sec_tab" class="nav nav-pills d-flex flex-md-nowrap flex-wrap" role="presentation">
            <div class="active" data-bs-toggle="pill" data-bs-target="#tab-academico-aprobar"
                aria-controls="tab-academico-aprobar" prm="re_ac" role="tab" type="button" aria-selected="true">
                Solicitudes por aprobar
            </div>
            <div data-bs-toggle="pill" data-bs-target="#tab-academico-aprobado" aria-controls="tab-academico-aprobado"
                class="" role="tab" type="button" aria-selected="false">
                Solicitudes Aprobadas
            </div>
            <div data-bs-toggle="pill" data-bs-target="#tab-academico-rechazadas"
                aria-controls="tab-academico-rechazadas" class="" role="tab" type="button" aria-selected="false">
                Solicitudes Rechazadas
            </div>

        </div>
        <div class="tab-content" id="pills-tabContent">
            <div class="tab-pane fade show active" id="tab-academico-aprobar" role="tabpanel"
                aria-labelledby="tab-academico-aprobar-tab">
                <app-table [backgroundColor]="backgroundTable" [columns]="academicoColumns"
                    [data]="solicitudesAcademicosData"></app-table>
            </div>
            <div class="tab-pane fade" id="tab-academico-aprobado" role="tabpanel"
                aria-labelledby="tab-academico-aprobado-tab">
                <app-table [backgroundColor]="backgroundTable" [columns]="academicoColumns"
                    [data]="solicitudesAprobadaAcademicosData"></app-table>
            </div>
            <div class="tab-pane fade" id="tab-academico-rechazadas" role="tabpanel"
                aria-labelledby="tab-academico-rechazadas-tab">
                <app-table [backgroundColor]="backgroundTable" [columns]="academicoColumns"
                    [data]="solicitudesRechazadasAcademicosData"></app-table>
            </div>
        </div>
    </div>

    <!-- Socios Formadores Solicitando un reto -->
    <app-title-show-more [showMoreObject]="titleSociosFormadores"></app-title-show-more>
    <div id="sec_tab" class="nav nav-pills d-flex flex-md-nowrap flex-wrap" role="presentation">
        <div class="active" data-bs-toggle="pill" data-bs-target="#tab-retos-recibidas"
            aria-controls="tab-retos-recibidas" prm="re_ac" role="tab" type="button" aria-selected="true">
            Recibidas
        </div>
        <div class="" data-bs-toggle="pill" data-bs-target="#tab-retos-aprobar" aria-controls="tab-retos-aprobar"
            prm="re_ac" role="tab" type="button" aria-selected="true">
            En revisión
        </div>
        <div prm="re_hs" data-bs-toggle="pill" data-bs-target="#tab-retos-aprobadas" aria-controls="tab-retos-aprobadas"
            class="" role="tab" type="button" aria-selected="false">
            Aceptadas
        </div>
        <div prm="re_hs" data-bs-toggle="pill" data-bs-target="#tab-retos-rechazadas"
            aria-controls="tab-retos-rechazadas" class="" role="tab" type="button" aria-selected="false">
            Rechazados
        </div>

    </div>
    <div class="tab-content" id="pills-tabContent">
        <div class="tab-pane fade show active" id="tab-retos-recibidas" role="tabpanel"
            aria-labelledby="tab-retos-recibidas-tab">
            <app-table [backgroundColor]="backgroundTable" [columns]="retoColumns"
                [data]="solicitudesRetosRegistradasData"></app-table>
        </div>
        <div class="tab-pane fade" id="tab-retos-aprobar" role="tabpanel" aria-labelledby="tab-retos-aprobar-tab">
            <app-table [backgroundColor]="backgroundTable" [columns]="retoColumns"
                [data]="solicitudesRetosData"></app-table>
        </div>
        <div class="tab-pane fade" id="tab-retos-aprobadas" role="tabpanel" aria-labelledby="tab-retos-aprobadas-tab">
            <app-table [backgroundColor]="backgroundTable" [columns]="retoColumns"
                [data]="solicitudesAprobadasRetosData"></app-table>
        </div>
        <div class="tab-pane fade" id="tab-retos-rechazadas" role="tabpanel" aria-labelledby="tab-retos-rechazadas-tab">
            <app-table [backgroundColor]="backgroundTable" [columns]="retoColumns"
                [data]="solicitudesRechazadasRetosData"></app-table>
        </div>
    </div>


    <!-- Problemáticas que no encontraron reto -->
    <div *featureFlag="'F2_5_HU58'">
        <app-title-show-more [showMoreObject]="titleSolicitudesReto"></app-title-show-more>
        <div id="sec_tab" class="nav nav-pills d-flex flex-md-nowrap flex-wrap" role="presentation">
            <div class="active" data-bs-toggle="pill" data-bs-target="#tab-reto-solicitudes-aprobar"
                aria-controls="tab-reto-solicitudes-aprobar" prm="re_ac" role="tab" type="button" aria-selected="true">
                Recibidas
            </div>
            <div prm="re_hs" data-bs-toggle="pill" data-bs-target="#tab-reto-solicitudes-rechazadas"
                aria-controls="tab-reto-solicitudes-rechazadas" class="" role="tab" type="button" aria-selected="false">
                En Revisión
            </div>
            <div prm="re_hs" data-bs-toggle="pill" data-bs-target="#tab-reto-solicitudes-aprobadas"
                aria-controls="tab-reto-solicitudes-aprobadas" class="" role="tab" type="button" aria-selected="false">
                Atendidas
            </div>
        </div>
        <div class="tab-content" id="pills-tabContent">
            <div class="tab-pane fade show active" id="tab-reto-solicitudes-aprobar" role="tabpanel"
                aria-labelledby="tab-reto-solicitudes-aprobar-tab">
                <app-table [backgroundColor]="backgroundTable" [columns]="sinRetoColumns"
                    [data]="solicitudesSinRetosData"></app-table>
            </div>
            <div class="tab-pane fade" id="tab-reto-solicitudes-rechazadas" role="tabpanel"
                aria-labelledby="tab-reto-solicitudes-rechazadas-tab">
                <app-table [backgroundColor]="backgroundTable" [columns]="sinRetoColumns"
                    [data]="solicitudesRechazadasSinRetosData"></app-table>
            </div>
            <div class="tab-pane fade" id="tab-reto-solicitudes-aprobadas" role="tabpanel"
                aria-labelledby="tab-reto-solicitudes-aprobadas-tab">
                <app-table [backgroundColor]="backgroundTable" [columns]="sinRetoCommentsColumns"
                    [data]="solicitudesAprobadasSinRetosData"></app-table>
            </div>
        </div>
    </div>

    <div *featureFlag="'F2_4_HU58'">
        <app-title-show-more [showMoreObject]="titleSolicitudesRetoF2_4_HU58"></app-title-show-more>
        <div id="sec_tab" class="nav nav-pills d-flex flex-md-nowrap flex-wrap" role="presentation">
            <div class="active" data-bs-toggle="pill" data-bs-target="#tab-reto-solicitudes-aprobar"
                aria-controls="tab-reto-solicitudes-aprobar" prm="re_ac" role="tab" type="button" aria-selected="true">
                Solicitudes por aprobar
            </div>
            <div prm="re_hs" data-bs-toggle="pill" data-bs-target="#tab-reto-solicitudes-rechazadas"
                aria-controls="tab-reto-solicitudes-rechazadas" class="" role="tab" type="button" aria-selected="false">
                Solicitudes Rechazadas
            </div>
            <div prm="re_hs" data-bs-toggle="pill" data-bs-target="#tab-reto-solicitudes-aprobadas"
                aria-controls="tab-reto-solicitudes-aprobadas" class="" role="tab" type="button" aria-selected="false">
                Solicitudes Aprobadas
            </div>
        </div>
        <div class="tab-content" id="pills-tabContent">
            <div class="tab-pane fade show active" id="tab-reto-solicitudes-aprobar" role="tabpanel"
                aria-labelledby="tab-reto-solicitudes-aprobar-tab">
                <app-table [backgroundColor]="backgroundTable" [columns]="sinRetoColumns"
                    [data]="solicitudesSinRetosData"></app-table>
            </div>
            <div class="tab-pane fade" id="tab-reto-solicitudes-rechazadas" role="tabpanel"
                aria-labelledby="tab-reto-solicitudes-rechazadas-tab">
                <app-table [backgroundColor]="backgroundTable" [columns]="sinRetoColumns"
                    [data]="solicitudesRechazadasSinRetosData"></app-table>
            </div>
            <div class="tab-pane fade" id="tab-reto-solicitudes-aprobadas" role="tabpanel"
                aria-labelledby="tab-reto-solicitudes-aprobadas-tab">
                <app-table [backgroundColor]="backgroundTable" [columns]="sinRetoCommentsColumns"
                    [data]="solicitudesAprobadasSinRetosData"></app-table>
            </div>
        </div>
    </div>

    <!-- Retos -->
    <div *featureFlag="'F2_4_HU67'">
        <app-title-show-more [showMoreObject]="titleRetos"></app-title-show-more>
        <div id="sec_tab" class="nav nav-pills d-flex flex-md-nowrap flex-wrap" role="presentation">
            <div class="active" data-bs-toggle="pill" data-bs-target="#tab-reto-activos"
                aria-controls="tab-reto-activos" prm="re_ac" role="tab" type="button" aria-selected="true">
                Retos Activos
            </div>
            <div prm="re_hs" data-bs-toggle="pill" data-bs-target="#tab-reto-historial"
                aria-controls="tab-reto-historial" class="" role="tab" type="button" aria-selected="false">
                Historial de Retos
            </div>

        </div>
        <div class="tab-content" id="pills-tabContent">
            <div class="tab-pane fade show active" id="tab-reto-activos" role="tabpanel"
                aria-labelledby="tab-reto-activos-tab">
                <app-table [backgroundColor]="backgroundTable" [columns]="listadoRetosColumns"
                    [data]="listadoRetosActivosData"></app-table>
            </div>
            <div class="tab-pane fade" id="tab-reto-historial" role="tabpanel" aria-labelledby="tab-reto-historial-tab">
                <app-table [backgroundColor]="backgroundTable" [columns]="listadoRetosColumns"
                    [data]="listadoRetosTodosData"></app-table>
            </div>

        </div>
    </div>

    <!-- Retos -->
    <!-- Sección de estatus -->
    <div *featureFlag="'F2_5_HU67'">
        <app-title-show-more [showMoreObject]="titleRetos"></app-title-show-more>

        <div class="mt-2 pt-0 mb-0 pb-0">
            <ng-template matStepLabel>En formalización</ng-template>
            <div class="mt-5">
                <div class="nav nav-tabs d-inline" id="accordionExample">
                    <div class="steps">

                        <div class="step-item active" data-bs-toggle="tab" data-bs-target="#tab-formalizacion"
                            (click)="changeViewStatus('formalizacion')">
                            <progress [ngClass]="status != 'formalizacion'  ? 'progress-active' : 'progress-inactive'"
                                id="progress-active" [value]="status != 'formalizacion'  ? 20 : 0" max=20></progress>
                            <div class="titlestepActive">
                                En formalización
                            </div>
                            <button class="step-button-active text-center">
                                1
                            </button>

                        </div>
                        <div class="step-item" data-bs-toggle="tab" data-bs-target="#tab-curso"
                            (click)="changeViewStatus('curso')">
                            <progress
                                [ngClass]="status == 'evaluacion' || status == 'concluido' ? 'progress-active' : 'progress-inactive'"
                                id="progress-active" [value]="status == 'evaluacion' || status == 'concluido'  ? 20 : 0"
                                max=20></progress>
                            <div [ngClass]="status != 'formalizacion'  ? 'titlestepActive' : 'titlestepInactive'">
                                En curso
                            </div>
                            <button [ngClass]="status != 'formalizacion'  ? 'step-button-active' : 'step-button'"
                                class="text-center">
                                2
                            </button>

                        </div>

                        <div class="step-item" data-bs-toggle="tab" data-bs-target="#tab-evaluacion"
                            (click)="changeViewStatus('evaluacion')">
                            <progress [ngClass]="status == 'concluido' ? 'progress-active' : 'progress-inactive'"
                                id="progress-active" [value]="status == 'concluido'  ? 20 : 0" max=20></progress>
                            <div
                                [ngClass]="status == 'evaluacion' || status == 'concluido' ? 'titlestepActive' : 'titlestepInactive'">
                                En evaluación
                            </div>
                            <button
                                [ngClass]="status == 'evaluacion' || status == 'concluido'  ? 'step-button-active' : 'step-button'"
                                class="text-center">
                                3
                            </button>

                        </div>
                        <div class="step-item" data-bs-toggle="tab" data-bs-target="#tab-concluido"
                            (click)="changeViewStatus('concluido')">
                            <div [ngClass]="status == 'concluido'  ? 'titlestepActive' : 'titlestepInactive'">
                                Concluidos
                            </div>
                            <button [ngClass]="status == 'concluido'  ? 'step-button-active' : 'step-button'"
                                class="text-center">
                                4
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="tab-content" id="pills-tabContent">
            <div class="tab-pane fade show active" id="tab-formalizacion" role="tabpanel"
                aria-labelledby="tab-reto-activos-tab">
                <app-table [backgroundColor]="backgroundTable" [columns]="listadoRetosFormalizacionColumns"
                    [data]="listadoRetosFormalizacionData"></app-table>
            </div>
            <div class="tab-pane fade" id="tab-curso" role="tabpanel" aria-labelledby="tab-reto-historial-tab">
                <app-table [backgroundColor]="backgroundTable" [columns]="listadoRetosCursoColumns"
                    [data]="listadoRetosCursoData"></app-table>
            </div>
            <div class="tab-pane fade" id="tab-evaluacion" role="tabpanel" aria-labelledby="tab-reto-historial-tab">
                <app-table [backgroundColor]="backgroundTable" [columns]="listadoRetosEvaluacionColumns"
                    [data]="listadoRetosEvaluacionData"></app-table>
            </div>
            <div class="tab-pane fade" id="tab-concluido" role="tabpanel" aria-labelledby="tab-reto-historial-tab">
                <app-table [backgroundColor]="backgroundTable" [columns]="listdaoRetosConcluidoColumns"
                    [data]="listadoRetosConcluidoData"></app-table>
            </div>

        </div>
    </div>





</div>





<ng-template #comentarios let-c="dismiss">
    <div class="modal-body">
        <p> Comentarios realizados: </p>
        <p *ngIf="comentariosData.length == 0">
            No hay comentarios.
        </p>
        <div *ngIf="comentariosData">
            <ul>
                <li *ngFor="let com of comentariosData">
                    [{{ com.fechaRegistro }} - {{ com.nombreUsuario }}]
                    {{ com.comentarios }}
                </li>
            </ul>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-brown" (click)="c('dismiss')">Cerrar</button>
    </div>
</ng-template>