import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule, DatePipe, registerLocaleData } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { NgSelectModule } from '@ng-select/ng-select';
import localeEsMX from '@angular/common/locales/es-MX';
registerLocaleData(localeEsMX);

import { HeaderBuscadorComponent } from './shared/components/header-buscador/header-buscador.component';
import { SidebarComponent } from './shared/components/sidebar/sidebar.component';

import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from '@shared/components/header/header.component';

import { HomesfComponent } from '@pages/homesf/homesf.component';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { MisRetosComponent } from '@pages/mis-retos/mis-retos.component';
import { MisRetosCvdpComponent } from '@pages/mis-retos-cvdp/mis-retos-cvdp.component';
import { MisRetosActivosComponent } from '@pages/mis-retos/retos-activos/retos-activos.component';
import { PaginaRetosComponent } from '@pages/pagina-retos/pagina-retos.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SFSolicitandoRetosComponent } from '@pages/CVDP/SF-solicitando-reto/SF-solicitando-reto.component';

import { FooterLargeComponent } from './shared/components/footer-large/footer-large.component';
import { MainLayoutBuscadorComponent } from './shared/components/main-layout-buscador/main-layout-buscador.component';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ProgressBarColor } from './progress-bar-color';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatExpansionModule} from '@angular/material/expansion';
import { MatStepperModule} from '@angular/material/stepper';


import { VinculacionesComponent } from '@pages/vinculaciones/vinculaciones.component';
import { SolicitudesAComponent } from '@pages/vinculaciones/solicitudes-abiertas/solicitudes-abiertas.component';
import { ProblematicasComponent } from '@pages/problematicas/problematicas.component';
import { RevisionComponent } from '@pages/problematicas/revision/revision.component';

import { AcademicoBuscaSFComponent } from '@pages/academico-busca-sf/academico-busca-sf.component';

import { SolicitudesSinRetoComponent } from '@pages/solicitudes-sin-reto/solicitudes-sin-reto.component';

import { GestionUsuarioComponent } from '@pages/gestion-usuarios/gestion-usuarios.component';
import { GestionUsuarioSFComponent } from '@pages/gestion-usuarios/socios-formadores/socios-formadores.component';
import { GestionUsuarioACComponent } from '@pages/gestion-usuarios/academia/academia.component';
import { GestionUsuarioCVDPomponent } from '@pages/gestion-usuarios/CVDP/cvdp.component';

import { SolicitudesVinculacionRetosComponent } from '@pages/academia/solicitudes-vinculacion-retos/solicitudes-vinculacion-retos.component';
import { SolicitudesVinculacionRetosSAComponent } from '@pages/academia/solicitudes-vinculacion-retos/solicitudes-aprobadas/solicitudes-aprobadas.component';


import { RegistroSFComponent } from '@pages/registro-sf/registro-sf.component';
import { FooterComponent } from '@shared/components/footer/footer.component';
import { FormularioComponent } from '@pages/registro-sf/formulario/formulario.component';
import { LoginComponent } from '@pages/login/login.component';
import { DashboardComponent } from '@pages/dashboard/dashboard.component';

import { UserHeaderComponent } from './shared/components/user-header/user-header.component';
import { IconUserComponent } from './shared/components/icon-user/icon-user.component';
import { IndicatorUserComponent } from './shared/components/indicator-user/indicator-user.component';
import { ButtonUserComponent } from './shared/components/button-user/button-user.component';

import { TableComponent } from '@shared/components/table/table.component';

import { RetosComponent } from '@pages/retos/retos.component';
import { NotificacionesComponent } from '@pages/notificaciones/notificaciones.component';
import { SolicitudesComponent } from '@pages/solicitud-socios-formadores/solicitudes.component';

import { ItemTableComponent } from './shared/components/table/item-table/item-table.component';
import { LinkTypeComponent } from './shared/components/table/item-table/link-type/link-type.component';
import { StatusTypeComponent } from './shared/components/table/item-table/status-type/status-type.component';
import { ProgressTypeComponent } from './shared/components/table/item-table/progress-type/progress-type.component';
import { HeaderTypeComponent } from './shared/components/table/item-table/header-type/header-type.component';
import { BodyTableComponent } from './shared/components/table/body-table/body-table.component';
import { HeaderTableComponent } from './shared/components/table/header-table/header-table.component';

import { AcademicoBancoSFComponent } from '@pages/academia-banco-socio-formador/academia-banco-socio-formador.component';
import { RetosBusquedaSFComponent } from '@pages/retos-busqueda-sf/retos-busqueda-sf.component';

import { MisRetosACActivosComponent } from '@pages/academia/retos-activos/retos-activos/retos-activos.component';

import {
  InteractionType,
  IPublicClientApplication,
  LogLevel,
  PublicClientApplication,
} from '@azure/msal-browser';
import {
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalRedirectComponent,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
} from '@azure/msal-angular';

import { AcademiaDashboardComponent } from './pages/dashboard/academia-dashboard/academia-dashboard.component';
import { CvdpDashboardComponent } from './pages/dashboard/cvdp-dashboard/cvdp-dashboard.component';
import { PerfilDashboardComponent } from './pages/dashboard/perfil-dashboard/perfil-dashboard.component';
import { ButtonComponent } from './shared/components/button/button.component';
import { TitleShowMoreComponent } from './shared/components/title-show-more/title-show-more.component';
import { VinculacionComponent } from './shared/components/vinculacion/vinculacion.component';

import { CardAccordionComponent } from './shared/components/card-accordion/card-accordion.component';
import { CardContactComponent } from './shared/components/card-contact/card-contact.component';
import { CardRetosComponent } from './shared/components/card-retos/card-retos.component';
import { CardSearchComponent } from './shared/components/card-search/card-search.component';
import { CheckboxComponent } from './shared/components/checkbox/checkbox.component';
import { ChipItemComponent } from './shared/components/chip-item/chip-item.component';
import { IconBorderComponent } from './shared/components/icon-border/icon-border.component';
import { NotSearchComponent } from './shared/components/not-search/not-search.component';
import { PaginationComponent } from './shared/components/pagination/pagination.component';
import { TabComponent } from './shared/components/tab/tab.component';
import { TextInputComponent } from './shared/components/text-input/text-input.component';
import { BancoRetosComponent } from './pages/banco-retos/banco-retos.component';
import { GestionUsuariosSfComponent } from './pages/gestion-usuarios-sf/gestion-usuarios-sf.component';
import { SociosTabComponent } from './pages/gestion-usuarios-sf/socios-tab/socios-tab.component';
import { ButtonsComponent } from './shared/components/table/item-table/buttons/buttons.component';
import { ImageTitleComponent } from './shared/components/table/item-table/image-title/image-title.component';
import { RegistroPerfilComponent } from '@pages/registro-perfil/registro-perfil.component';
import { ContactosTabComponent } from '@pages/registro-perfil/contactos-tab/contactos-tab.component';
import { PerfilContactComponent } from '@pages/registro-perfil/perfil-contact/perfil-contact.component';
import { PerfilTabComponent } from '@pages/registro-perfil/perfil-tab/perfil-tab.component';
import { RegistroProblematicaComponent } from './pages/registro-problematica/registro-problematica.component';
import { RetoConfirmacionComponent } from './pages/reto-confirmacion/reto-confirmacion.component';
import { SolicitudAcademicoComponent } from './pages/solicitud-academico/solicitud-academico.component';
import { VinculaRetoComponent } from './pages/vincula-reto/vincula-reto.component';
import { VinculaRetosBusquedaComponent } from './pages/vincula-retos-busqueda/vincula-retos-busqueda.component';
import { VinculaRetosSearchComponent } from './pages/vincula-retos-search/vincula-retos-search.component';
import { SfNoEncontreSfComponent } from './pages/sf-no-encontre-sf/sf-no-encontre-sf.component';

import { CookieService } from 'ngx-cookie-service';
import { SinDatosTableComponent } from '@shared/components/sin-datos-table/sin-datos-table.component';
import { ValidaSesionComponent } from '@pages/valida-sesion/valida-sesion.component';
import { SolicitudComponent } from './pages/solicitud/solicitud.component';
import { ProblematicaRegistradaComponent } from './pages/problematica-registrada/problematica-registrada.component';
import { RegistraRetoComponent } from './pages/registra-reto/registra-reto.component';
import { MatchesSociosComponent } from './pages/matches-socios/matches-socios.component';
import { CardAccordionSocioComponent } from './shared/components/card-accordion-socio/card-accordion-socio.component';
import { RetoResumenComponent } from './pages/reto-resumen/reto-resumen.component';
import { SocioSearchComponent } from './pages/socio-search/socio-search.component';
import { CardSocioSearchComponent } from './shared/components/card-socio-search/card-socio-search.component';
import { AcademicoPerfilComponent } from './pages/registro-perfil/academico-perfil/academico-perfil.component';
import { SocioFormadorComponent } from '@pages/academia/socio-formador/socio-formador.component';
import { SolicitudProblematicaComponent } from './pages/solicitud-problematica/solicitud-problematica.component';
import { DateTypeComponent } from '@shared/components/table/item-table/date-type/date-type.component';
import { ConfirmacionVinculacionComponent } from '@pages/academia/confirmacion-vinculacion/confirmacion-vinculacion.component';
import { SolicitudVinculacionARetoComponent } from '@pages/academia/solicitud-vinculacion-a-reto/solicitud-vinculacion-a-reto.component';
import { DatosEmpresaTabComponent } from './pages/registro-perfil/datos-empresa-tab/datos-empresa-tab.component';
import {
  NgxGoogleAnalyticsModule,
  NgxGoogleAnalyticsRouterModule,
} from 'ngx-google-analytics';
import { RetoRegistradoComponent } from '@pages/academia/reto-registrado/reto-registrado.component';
import { RecursosVinculacionSfComponent } from '@pages/recursos-vinculacion-sf/recursos-vinculacion-sf.component';
import { environment } from 'environments/environment';
import { HttpSFInterceptor } from './interceptors/httpsf.interceptor';
import { SpinnerComponent } from './shared/components/spinner/spinner.component';
import { SpinnerInterceptor } from './interceptors/spinner.interceptor';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { AvisoPrivacidadComponent } from './pages/aviso-privacidad/aviso-privacidad.component';
import { PreguntasfrecuentesComponent } from './pages/preguntasfrecuentes/preguntasfrecuentes.component';
import { SolicitudesSinRetoSPAComponent } from '@pages/solicitudes-sin-reto/solicitudes-por-aprobar/solicitudes-por-aprobar.component';
import { UsuariosAcademiaComponent } from './pages/usuarios-academia/usuarios-academia.component';
import { AcademicoPerfilRegistroComponent } from './pages/usuarios-academia/academico-perfil-registro/academico-perfil-registro.component';

import { ConveniosComponent } from './pages/CVDP/convenios/convenios.component';
import { ConveniosSFComponent } from '@pages/registro-perfil/convenios-sf/convenios-sf.component';
import { RegistraRetoSemillaComponent } from '@pages/registra-reto-semilla/registra-reto-semilla.component';
import { VinculaUsuarios2Component } from '@pages/vincula-usuarios2/vincula-usuarios2.component';
import { RegistraRetoSemilla2Component } from '@pages/registra-reto-semilla2/registra-reto-semilla2.component';
import { VinculaUsuarios3Component } from '@pages/vincula-usuarios3/vincula-usuarios3.component';
import { CasosDeExitoComponent } from './pages/casos-de-exito/casos-de-exito.component';
import { RecursosDeVinculacionComponent } from './pages/recursos-de-vinculacion/recursos-de-vinculacion.component';
import { MenuComponent } from './shared/components/menu/menu.component';
import { BtnRegresarComponent } from './shared/components/btn-regresar/btn-regresar.component';
import { FileTypeComponent } from './shared/components/table/item-table/file-type/file-type.component';
import { ContactosSecundariosComponent } from './pages/contactos-secundarios/contactos-secundarios.component';
import { RegistroRecursosVinculacionComponent } from './pages/recursos-vinculacion-sf/registro-recursos-vinculacion/registro-recursos-vinculacion.component';
import { ReportesComponent } from './reportes/reportes.component';
import { RegistroCasosExitoComponent } from './pages/casos-de-exito/registro-casos-exito/registro-casos-exito.component';
import { DetalleComponent } from './reportes/detalle/detalle.component';
// import { UsuariosPendientesComponent } from './pages/usuarios-academia-lista/usuarios-pendientes/usuarios-pendientes.component';
// import { UsuariosRechazadosComponent } from './pages/usuarios-academia-lista/usuarios-rechazados/usuarios-rechazados.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { GestionSocioformadorComponent } from './pages/gestion-socioformador/gestion-socioformador.component';

import { ClipboardModule} from '@angular/cdk/clipboard';
import { GestionNotificacionesComponent } from './pages/gestion-notificaciones/gestion-notificaciones.component';
import { RetoTerminadoComponent } from './pages/reto-terminado/reto-terminado.component';
import { RetosExitososComponent } from './shared/components/retos-exitosos/retos-exitosos.component';
import { SociosExitososComponent } from './shared/components/socios-exitosos/socios-exitosos.component';
import { GestionContactosComponent } from './pages/gestion-contactos/gestion-contactos.component';

import { SolicitudesSFPotencialRechazoComponent } from '@pages/CVDP/solicitud-sf-potencial-rechazo/solicitud-sf-potencial-rechazo.component';
import { MessageTypeComponent } from './shared/components/table/item-table/message-type/message-type.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { ConvenioViewerComponent } from './shared/components/convenio-viewer/convenio-viewer.component';
import { CargaDocumentosComponent } from './pages/carga-documentos/carga-documentos.component';

import { SobreProfesoresComponent } from './pages/pagina-retos/sobre-profesores/sobre-profesores.component';
import { CompromisosSocioComponent } from './pages/pagina-retos/compromisos-socio/compromisos-socio.component';
import { CasosExitoComponent } from './pages/pagina-retos/casos-exito/casos-exito.component';
import { RetoImplementarComponent } from './pages/pagina-retos/reto-implementar/reto-implementar.component';
import { SobreSfComponent } from './pages/pagina-retos/sobre-sf/sobre-sf.component';
import { RetoEntregablesComponent } from './pages/pagina-retos/reto-entregables/reto-entregables.component';
import { RetoCompromisosComponent } from './pages/pagina-retos/reto-compromisos/reto-compromisos.component';
import { RetoRecursosComponent } from './pages/pagina-retos/reto-recursos/reto-recursos.component';
import { PostularCasoComponent } from './pages/pagina-retos/postular-caso/postular-caso.component';
import { DocumentacionRetoComponent } from './pages/documentacion-reto/documentacion-reto.component';
import { RoutesComponent } from './pages/dashboard/routes/routes.component';
import { FeatureFlagDirective } from './featureModule/feature-flag.directive';
import { FeatureFlagService } from './featureModule/feature-flag.service';
import { CartaColaboracionComponent } from './pages/pagina-retos/carta-colaboracion/carta-colaboracion.component';
import { VinculacionesConSocioComponent } from './pages/vinculaciones-con-socio/vinculaciones-con-socio.component';
import { EvaluacionComponent } from './shared/components/evaluacion/evaluacion.component';
import { StarRatingModule } from 'angular-star-rating';
import { TypeformComponent } from '@shared/components/typeform/typeform.component';



const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1; // Remove this line to use Angular Universal

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: environment.B2C,
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: isIE,
    },
  });
}
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(
    'https://graph.microsoft.com/v1.0/users',
    environment.B2C_Protected
  );

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: environment.B2C_scopes,
    },
    loginFailedRoute: '/login-failed',
  };
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

const featureFactory = (featureFlagService: FeatureFlagService) =>()=> featureFlagService.loadConfig();

@NgModule({
  declarations: [
    AcademiaDashboardComponent,
    AcademicoBancoSFComponent,
    AcademicoBuscaSFComponent,
    AcademicoPerfilRegistroComponent,
    AppComponent,
    BancoRetosComponent,
    BodyTableComponent,
    ButtonComponent,
    ButtonsComponent,
    ButtonUserComponent,
    CardAccordionComponent,
    CardContactComponent,
    CardRetosComponent,
    CardSearchComponent,
    CheckboxComponent,
    ChipItemComponent,
    ContactosTabComponent,
    ConfirmacionVinculacionComponent,
    CvdpDashboardComponent,
    DashboardComponent,
    DateTypeComponent,
    MessageTypeComponent,
    FooterComponent,
    FooterLargeComponent,
    FormularioComponent,
    GestionUsuarioACComponent,
    GestionUsuarioComponent,
    GestionUsuarioCVDPomponent,
    GestionUsuarioSFComponent,
    GestionUsuariosSfComponent,
    HeaderBuscadorComponent,
    HeaderComponent,
    HeaderTableComponent,
    HeaderTypeComponent,
    HomesfComponent,
    IconBorderComponent,
    IconUserComponent,
    ImageTitleComponent,
    IndicatorUserComponent,
    ItemTableComponent,
    LinkTypeComponent,
    LoginComponent,
    MainLayoutBuscadorComponent,
    MisRetosACActivosComponent,
    MisRetosActivosComponent,
    MisRetosComponent,
    MisRetosCvdpComponent,
    NotificacionesComponent,
    NotSearchComponent,
    PaginaRetosComponent,
    PaginationComponent,
    PerfilContactComponent,
    PerfilDashboardComponent,
    PerfilTabComponent,
    ProblematicasComponent,
    ProgressBarColor,
    ProgressTypeComponent,
    RegistroPerfilComponent,
    RegistroProblematicaComponent,
    RegistroSFComponent,
    RetoConfirmacionComponent,
    RetosBusquedaSFComponent,
    RetosComponent,
    RetoRegistradoComponent,
    RevisionComponent,
    RecursosVinculacionSfComponent,
    SfNoEncontreSfComponent,
    SFSolicitandoRetosComponent,
    SidebarComponent,
    SinDatosTableComponent,
    SociosTabComponent,
    SolicitudAcademicoComponent,
    SolicitudComponent,
    SolicitudesAComponent,
    SolicitudesComponent,
    SolicitudesSFPotencialRechazoComponent,
    SolicitudesSinRetoComponent,
    SolicitudesSinRetoSPAComponent,
    SolicitudesVinculacionRetosComponent,
    SolicitudVinculacionARetoComponent,
    SolicitudesVinculacionRetosSAComponent,
    StatusTypeComponent,
    TabComponent,
    TableComponent,
    TextInputComponent,
    TitleShowMoreComponent,
    UserHeaderComponent,
    VinculacionComponent,
    VinculacionesComponent,
    VinculaRetoComponent,
    VinculaRetosBusquedaComponent,
    VinculaRetosSearchComponent,
    ValidaSesionComponent,
    ProblematicaRegistradaComponent,
    RegistraRetoComponent,
    MatchesSociosComponent,
    CardAccordionSocioComponent,
    RetoResumenComponent,
    SocioSearchComponent,
    CardSocioSearchComponent,
    AcademicoPerfilComponent,
    SocioFormadorComponent,
    SolicitudProblematicaComponent,
    DatosEmpresaTabComponent,
    SpinnerComponent,
    AvisoPrivacidadComponent,
    PreguntasfrecuentesComponent,
    UsuariosAcademiaComponent,
    ConveniosComponent,
    ConveniosSFComponent,
    RegistraRetoSemillaComponent,
    VinculaUsuarios2Component,
    VinculaUsuarios3Component,
    RegistraRetoSemilla2Component,
    CasosDeExitoComponent,
    RecursosDeVinculacionComponent,
    MenuComponent,
    BtnRegresarComponent,
    FileTypeComponent,
    ContactosSecundariosComponent,
    RegistroRecursosVinculacionComponent,
    ReportesComponent,
    RegistroCasosExitoComponent,
    DetalleComponent,
    GestionSocioformadorComponent,
    GestionNotificacionesComponent,
    RetoTerminadoComponent,
    RetosExitososComponent,
    SociosExitososComponent,
    GestionContactosComponent,
    ConvenioViewerComponent,
    CargaDocumentosComponent,
    SobreProfesoresComponent,
    CompromisosSocioComponent,
    CasosExitoComponent,
    RetoImplementarComponent,
    SobreSfComponent,
    RetoEntregablesComponent,
    RetoCompromisosComponent,
    RetoRecursosComponent,
    PostularCasoComponent,
    DocumentacionRetoComponent,
    RoutesComponent,
    FeatureFlagDirective,
    CartaColaboracionComponent,
    VinculacionesConSocioComponent,
    EvaluacionComponent,
    TypeformComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    NgSelectModule,
    ReactiveFormsModule,
    NgbModule,
    MatTabsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgxGoogleAnalyticsModule.forRoot(environment.GoogleAnalitycs),
    NgxGoogleAnalyticsRouterModule,
    SlickCarouselModule,
    MatProgressBarModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatCheckboxModule,
    MatChipsModule,
    MatExpansionModule,
    MatStepperModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    MsalModule,
    ClipboardModule,
    NgxExtendedPdfViewerModule,
    StarRatingModule.forRoot()
  ],
  providers: [
    { provide:APP_INITIALIZER,
      useFactory: featureFactory,
      deps:[FeatureFlagService],
      multi: true
  },
    { provide: LOCALE_ID, useValue: 'es-MX' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpSFInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SpinnerInterceptor,
      multi: true,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    CookieService,
    DatePipe,
   ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
