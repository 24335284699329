import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { RetosService } from "@shared/service/Retos/retos.service";
import { SociosFormadoresService } from "@shared/service/Socios_Formadores/socios-formadores.service";
import { NgbModal, NgbModalConfig } from "@ng-bootstrap/ng-bootstrap";
import { CatalogosService } from "@shared/service/Catalogos/catalogos.service";
import { lastValueFrom } from "rxjs";

@Component({
  selector: "app-evaluacion",
  templateUrl: "./evaluacion.component.html",
  styleUrls: ["./evaluacion.component.css"],
})
export class EvaluacionComponent implements OnInit {
  @Input() ShowCommentsButton: boolean = false;
  @Input() typeEvaluation: "RETO" | "SOCIO";
  @Input() evaluacion: number;
  @Input() evaluadores: number;
  @Input() Id: number;
  @Input() clavePerfil: string = 'socio';

  showEvaluations: boolean = false;
  evaluationTitle: string="";
  listaEvaluciones: any[]=[];

  @ViewChild("contentEvaluaciones") content: ElementRef;
  content_modal;

  constructor(
    private sociosService: SociosFormadoresService,
    private retosService: RetosService,
    private config: NgbModalConfig,
    private modalService: NgbModal,
    private catalogoService: CatalogosService
  ) {
    config.backdrop = "static";
    config.keyboard = false;
  }

  ngOnInit(): void {
      if(this.clavePerfil != 'socio')
      {
        this.showEvaluations = true;
      }
  }

  async mostrarEvaluaciones() {
    await this.getEvaluaciones();
    this.evaluationTitle = this.getTitle();
    this.showPopup();

  }

  getTitle() {
    if (this.typeEvaluation == "RETO") {
      return "Evaluación global de la Unidad de Formación";
    }
    if (this.typeEvaluation == "SOCIO") {
      return "Calificación del Socio Formador";
    }
    return "";
  }

  async getEvaluaciones() {

    try {
      // Socio
      if(this.typeEvaluation === "SOCIO"){

        let response:any = await lastValueFrom(this.sociosService.getEvaluaciones(this.Id));

        this.listaEvaluciones = response.map(x => { return {
          nombre_RS: x.nombreAcademico,
          unidadFormativa: x.unidadFormativa,
          evaluacion: x.evaluacionAprendizaje,
          periodo: x.periodo,
          periodoSemanal: x.periodoSemanal,
          fecha: x.fechaComentario
        }});
    }

    // reto
    if(this.typeEvaluation === "RETO"){

      let response:any = await lastValueFrom(this.retosService.getEvaluaciones(this.Id));

      this.listaEvaluciones = response.map(x => { return {
        nombre_RS: x.nombreSocioFormador,
        unidadFormativa: null,
        evaluacion: x.evaluacionAprendizaje,
        periodo: x.periodo,
        fecha: x.fechaComentario
      }});
    }

    } catch (err) {
      console.log(err);
    }

  }

  close() {
    this.content_modal.close();
  }

  showPopup(){
    this.content_modal = this.modalService.open(this.content, { size: "xl" });
  }

}
