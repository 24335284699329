<div class="flex-fill">
    <div class="d-flex justify-content-end">
        <div class="d-none d-md-block add-user">
            <button class="blue_button float-end" (click)="agregarUsuario()">
                {{'gestion_usuarios.añadir_cvdp' | translate }}</button>
        </div>

        <div class="d-none d-md-block add-user">
            <button class="blue_button" (click)="restablecer()">{{
                'academia_banco_socio_formador.btn_restablecer' | translate }}</button>
        </div>

        <div class="btn-group order-by">
            <button class="btn dropdown-toggle" type="button" id="listado" data-bs-toggle="dropdown"
                data-bs-auto-close="true" aria-expanded="false" data-bs-offset="0,0">
                {{textOrdenarPor | translate }}
            </button>
            <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="listado">
                <!-- ajustar a parametros que recibe la api orden -> "nombre/campus"   -->
                <li><a class="dropdown-item" [class.active]="ordenamientoBy=='nombre'"
                        (click)="orderBy('nombre')">{{'gestion_usuarios.nombre_az' | translate }}</a></li>
                <li><a class="dropdown-item" [class.active]="ordenamientoBy=='campus'"
                        (click)="orderBy('campus')">{{'gestion_usuarios.campus' | translate }}</a></li>
            </ul>
        </div>
    </div>
</div>
<div id="c_sf" class="sec_cnt tipo-10">
    <div class="d-none d-md-block">
        <div class="cont_tit d-flex">
            <div class="col-3">{{'gestion_usuarios.nombre_correo' | translate }}</div>
            <div class="col-2">{{'gestion_usuarios.campus' | translate }}</div>
            <div class="col-1">{{'gestion_usuarios.region' | translate }}</div>
            <div class="col-1">{{'gestion_usuarios.nivel_gestion' | translate }}</div>
            <div class="col-1">{{'gestion_usuarios.estatus' | translate }}</div>
            <div class="col-3">{{'gestion_usuarios.acciones' | translate }}</div>
        </div>
        <div *ngFor="let el of usuariosList" class="roundedsquare cont_fl d-flex align-items-center">
            <div class="col-3 d-flex align-items-center"><img class="img_usr" alt="Foto Usuario"
                    src="{{ el.urlFotoUsuario }}">
                <div>
                    <div>
                        <span><a class="pointer"
                                (click)="verDatosUsuario(el.idUsuario, el)">{{el.nombreUsuario}}</a></span>
                    </div>
                    <span>{{el.correo}}</span>
                </div>
            </div>
            <div class="col-2">{{el.campus}}<br/>{{ el.campusPrincipal }}</div>
            <div class="col-1">{{el.region}}</div>
            <div class="col-1">{{el.nivelGestion}}</div>
            <div class="col-1">{{el.lblEstatus}}</div>

            <div class="col-3 justify-content-start">
                <button class="lst_bt_edt" title="Editar" (click)="editarUsuario(el.idUsuario)">&nbsp;</button>
                <button class="lst_bt_brr" title="Eliminar" (click)="eliminarUsuario(el.idUsuario)">&nbsp;</button>
                <button class="lst_bt_cpr" title="Convertir en Principal" (click)="validaEstablecerPrincipal(el)">&nbsp;</button>
                <button class="lst_bt_not" title="Notificaciones" (click)="abrirNotificaciones(el)">&nbsp;</button>
            </div>
        </div>
        <div *ngIf="usuariosList.length === 0">
            <app-sin-datos-table></app-sin-datos-table>
        </div>

    </div>

    <app-pagination *ngIf="usuariosListTotal.length" [objPagination]="usuariosListTotal"
        (paginationDataChange)="showPaginationData($event)"></app-pagination>

    <div class="d-block d-md-none">
        <div class="accordion" id="ac_1">
            <div *ngFor="let el of usuariosList" class="accordion-item">
                <h2 class="accordion-header" id="flush-heading{{el.idUsuario}}">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse"
                        [attr.data-bs-target]="'#flush-collapse'+el.idUsuario" aria-expanded="true">
                        {{el.campus}}
                    </button>
                </h2>
                <div id="flush-collapse{{el.idUsuario}}" class="accordion-collapse collapse show"
                    data-bs-parent="#ac_1">
                    <div class="accordion-body">
                        <div><img alt="Logo" class="img_usr" src="assets/img/avatar_sin.jpg"></div>
                        <div><a class="pointer"
                                (click)="verDatosUsuario(el.idUsuario, el)">{{el.nombreUsuario}}</a><br /><span>{{el.correo}}</span><br />{{el.region}}
                        </div>
                        <div class="d-flex align-items-center justify-content-center">
                            <button class="mb_btn_edt" (click)="editarUsuario(el.idUsuario)"><img alt="Editar"
                                    class="tamgu" src="assets/img/icon_editar.svg"> {{'gestion_usuarios.editar_usuario'
                                | translate }}</button>
                            <button class="mb_btn_brr" (click)="eliminarUsuario(el.idUsuario)"><img alt="Eliminar"
                                    class="tamgu" src="assets/img/icon_eliminar.svg">
                                {{'gestion_usuarios.eliminar_usuario' | translate }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-center" *ngIf="paginaActualMasResultados < numeroPaginasTo">
            <button class="mb_mas" (click)="obtenerMasResultados()"><img alt="Más resultados" class="tamgu"
                    src="assets/img/icon_mas_bullet.svg"> {{'gestion_usuarios.cargar_mas_resultados' | translate
                }}</button>
        </div>
        <div class="d-block d-md-none mt-3">
            <button class="blue_button float-end"><img alt="Añadir" class="tamgu" src="assets/img/icon_mas.svg">
                {{'gestion_usuarios.añadir_usuario' | translate }}</button>
        </div>

        <div *ngIf="elementsTo.length === 0">
            <app-sin-datos-table></app-sin-datos-table>
        </div>

    </div>
</div>
