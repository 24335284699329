import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-status-type',
  templateUrl: './status-type.component.html',
  styleUrls: ['./status-type.component.css'],
})
export class StatusTypeComponent  {
  @Input()
  elementStatus: any;

  constructor(private router: Router) {}



  handleClick() {
    if (this.elementStatus.link) {
      this.router.navigateByUrl(this.elementStatus.link);
    }
  }
}
