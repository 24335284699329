<div class="container">
        <app-header></app-header>
        <div class="row fondo">
                <div *ngIf="showReturnButton">
                        <button class="dashboard_btn d-md-block" (click)="regresa()">
                                <svg width="12" height="24" viewBox="0 0 12 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.98157 24L0.968321 14.48C0.348159 13.8228 0 12.9333 0 12.006C0 11.0787 0.348159 10.1892 0.968321 9.532L9.992 0L12 2.121L2.97632 11.652C2.88746 11.7458 2.83753 11.8729 2.83753 12.0055C2.83753 12.1381 2.88746 12.2652 2.97632 12.359L11.9886 21.879L9.98157 24Z"
                                                fill="#4A6BB6" />
                                </svg>
                                <span>&nbsp;Regresar</span>
                        </button>
                </div>

                <p
                        style="margin-top:15.0pt;margin-bottom:7.5pt;text-align:center;line-height:normal;">
                        <strong><span style='color:black;'>AVISO DE
                                        PRIVACIDA</span></strong><strong><span>D DE
                                        LA
                                        PLATAFORMA<span style="color:black;">&nbsp;</span>MPS TEC</span></strong>
                </p>
                <p
                        style="margin-top:15.0pt;margin-bottom:7.5pt;line-height:normal;">
                        <strong><span style='color:black;'>IDENTIDAD Y
                                        DOMICILIO DEL
                                        RESPONSABLE</span></strong>
                </p>
                <p style="margin-bottom:7.5pt;line-height:normal;"><span
                                style='color:black;'>El Responsable de los
                                datos
                                personales
                                que usted proporciona es el Instituto Tecnol&oacute;gico y de Estudios Superiores de
                                Monterrey (en lo
                                sucesivo &ldquo;ITESM&rdquo;)&nbsp;con domicilio en Av. Eugenio Garza Sada No. 2501 Sur,
                                Col.
                                Tecnol&oacute;gico, Monterrey, Nuevo Le&oacute;n, C.P.64849.</span></p>
                <p
                        style="margin-top:15.0pt;margin-bottom:7.5pt;line-height:normal;">
                        <strong><span style='color:black;'>DATOS
                                        PERSONALES Y DATOS
                                        PERSONALES SENSIBLES TRATADOS POR ITESM</span></strong>
                </p>
                <p style="margin-bottom:7.5pt;line-height:normal;"><span
                                style='color:black;'>Para cumplir con las
                                finalidades del
                                tratamiento se&ntilde;aladas en el presente Aviso de Privacidad, es necesario que el
                                ITESM trate las
                                siguientes categor&iacute;as de datos personales:</span></p>
                <ul>
                        <li><span style='color:black;'>Datos
                                        de
                                        identificaci&oacute;n (incluyendo su imagen personal)</span></li>
                        <li><span style='color:black;'>Datos
                                        de
                                        contacto&nbsp;</span></li>
                        <li><span style='color:black;'>Datos
                                        de
                                        autenticaci&oacute;n</span></li>
                        <li><span style='color:black;'>Datos
                                        laborales</span>
                        </li>
                        <li><span style='color:black;'>Datos
                                        de sus
                                        antecedentes acad&eacute;micos y profesionales</span></li>
                        <li><span style='color:black;'>Datos
                                        relacionados
                                        con
                                        sus intereses y aptitudes tanto&nbsp;
                                        &nbsp;que usted proporcione de forma directa o a trav&eacute;s de la
                                        realizaci&oacute;n de
                                        actividades
                                        por ITESM y/o que se puedan inferir a partir de la informaci&oacute;n en
                                        posesi&oacute;n de ITESM
                                        mediante la aplicaci&oacute;n de diversas t&eacute;cnicas de an&aacute;lisis de
                                        datos;</span></li>
                </ul>
                <p style="margin-bottom:7.5pt;line-height:normal;"><span>Asimismo<span
                                        style="color:black;">, le
                                        informamos
                                        que para cumplir con las finalidades se&ntilde;aladas en el presente Aviso de
                                        Privacidad, ITESM no
                                        recabar&aacute; ni tratar&aacute; datos personales
                                        sensibles.</span></span>&nbsp; &nbsp; &nbsp;</p>
                <p style="margin-bottom:7.5pt;line-height:normal;"><span
                                style='color:black;'>Por otro lado, le
                                informamos que el
                                ITESM
                                podr&aacute; dar tratamiento a datos personales de identificaci&oacute;n, contacto y
                                laborales de
                                terceros
                                que mantengan alguna relaci&oacute;n laboral y/o profesional con usted
                                (&ldquo;Terceros&rdquo;) con el
                                prop&oacute;sito de cumplir con las finalidades informadas en el presente Aviso de
                                Privacidad. De este
                                modo,
                                al proporcionar los datos personales relacionados con Terceros usted reconoce tener el
                                consentimiento de
                                estos para que el ITESM trate sus datos personales en t&eacute;rminos del presente Aviso
                                de
                                Privacidad.</span></p>
                <p style="margin-bottom:7.5pt;line-height:normal;"><span
                                style='color:black;'>&nbsp;</span></p>
                <p style="margin-bottom:7.5pt;line-height:normal;"><strong><span
                                        style='color:black;'>FINALIDADES
                                        PRIMARIAS</span></strong>
                </p>
                <p style="margin-bottom:7.5pt;line-height:normal;"><span
                                style='color:black;'>El ITESM dar&aacute;
                                tratamiento a
                                sus
                                datos personales para las siguientes finalidades primarias y necesarias:</span></p>
                <ul>
                        <li><span style='color:black;'>Identificarlo
                                        como
                                        prospecto de ITESM y verificar que cuente con las habilidades, aptitudes y
                                        dem&aacute;s requisitos
                                        necesarios para&nbsp;</span><span
                                        >la
                                        plataforma
                                        de MPS Tec.&nbsp;</span></li>
                        <li><span style='color:black;'>Para
                                        gestionar
                                        la(s)
                                        solicitud(es) que usted realice en relaci&oacute;n&nbsp;</span><span
                                        >con<span
                                                style="color:black;">&nbsp;su</span></span> <span
                                        >participaci&oacute;n como Socio Formador
                                        del ITESM.</span>
                        </li>
                        <li><span style='color:black;'>Para
                                        validar la
                                        veracidad y calidad de la informaci&oacute;n proporcionada por usted o aquella
                                        que pueda inferirse
                                        de
                                        usted u obtenerse de otras fuentes l&iacute;citas a las que el ITESM pueda tener
                                        acceso, incluyendo
                                        la
                                        verificaci&oacute;n de sus datos ante instancias gubernamentales o terceros con
                                        los que tengamos
                                        celebrados acuerdos de colaboraci&oacute;n.</span></li>
                        <li><span style='color:black;'>Realizar
                                        actividades de
                                        an&aacute;lisis de la informaci&oacute;n y datos relacionados con su persona
                                        mediante la
                                        aplicaci&oacute;n de diversas t&eacute;cnicas y tecnolog&iacute;as de
                                        an&aacute;lisis de datos que
                                        nos
                                        permitir&aacute;n conocer mejor su perfil, aptitudes e intereses.</span></li>
                        <li><span style='color:black;'>Para
                                        dar
                                        cumplimiento y
                                        seguimiento a los procedimientos y actividades institucionales previstos en
                                        gu&iacute;as,
                                        procedimientos, reglamentos, normas, c&oacute;digos o pol&iacute;ticas de
                                        car&aacute;cter interno
                                        que
                                        resulten aplicables.</span></li>
                        <li><span style='color:black;'>Para
                                        invitarle y,
                                        en su
                                        caso, gestionar su acceso y participaci&oacute;n en la(s) actividad(es) y
                                        eventos relacionados
                                        a</span><span >&nbsp;la plataforma MPS
                                        Tec.</span></li>
                        <li><span style='color:black;'>Para
                                        dar
                                        cumplimiento y
                                        seguimiento a las bases, requisitos y procedimientos para el registro,
                                        admisi&oacute;n y desarrollo
                                        de</span><span >&nbsp;la plataforma MPS
                                        Tec<span style="color:black;">.</span></span></li>
                        <li><span style='color:black;'>Para
                                        atender,
                                        registrar
                                        y dar seguimiento a las quejas que en su caso realice.</span></li>
                        <li><span style='color:black;'>Para
                                        elaborar,
                                        cuando
                                        corresponda, el documento de acceso a las instalaciones.</span></li>
                        <li><span style='color:black;'>Para
                                        utilizar su
                                        imagen
                                        personal (ya sea en formato de fotograf&iacute;a, video, o cualquier otro medio
                                        similar) para hacer
                                        publicaciones internas y externas sobre su participaci&oacute;n en eventos o
                                        actividades, as&iacute;
                                        como para promocionar al ITESM.</span></li>
                        <li><span style='color:black;'>Enviarle
                                        comunicaciones, notificaciones y avisos personales a trav&eacute;s de distintos
                                        canales y mecanismos
                                        tecnol&oacute;gicos de comunicaci&oacute;n que el ITESM considere pertinentes a
                                        fin de darle a
                                        conocer
                                        informaci&oacute;n relevante sobre el estado de su proceso de admisi&oacute;n o
                                        cualquier asunto que
                                        resulte de su inter&eacute;s; as&iacute; como para conocer su opini&oacute;n
                                        respecto a la
                                        atenci&oacute;n recibida y los servicios que ofrecemos.</span></li>
                        <li><span style='color:black;'>Para
                                        realizar
                                        estad&iacute;sticas y reportes para control interno.</span></li>
                        <li><span style='color:black;'>Para
                                        cumplir con
                                        las
                                        obligaciones previstas en la normatividad aplicable.</span></li>
                </ul>
                <p style="line-height:normal;"><span >&nbsp;</span></p>
                <p
                        style="margin-top:3.75pt;margin-bottom:3.75pt;line-height:normal;">
                        <strong><span style='color:black;'>FINALIDADES
                                        SECUNDARIAS</span></strong>
                </p>
                <p style="margin-bottom:7.5pt;line-height:normal;"><span
                                style='color:black;'>De manera adicional,
                                en caso de que
                                no se
                                oponga, el ITESM utilizar&aacute; su informaci&oacute;n personal para las siguientes
                                finalidades que no
                                son
                                necesarias para el servicio que nos solicita, pero que nos permiten y facilitan
                                brindarle una mejor
                                atenci&oacute;n:</span></p>
                <ul>
                        <li><span style='color:black;'>Para
                                        contactos
                                        posteriores.</span></li>
                        <li><span style='color:black;'>Para
                                        enviarle
                                        informaci&oacute;n que consideramos podr&iacute;a ser de su inter&eacute;s y que
                                        pudiera estar
                                        relacionada con los productos y servicios que ofrecemos.</span></li>
                        <li><span style='color:black;'>Para
                                        enviarle
                                        invitaciones a eventos, actividades, programas y proyectos.</span></li>
                        <li><span style='color:black;'>Para
                                        promocionar la
                                        vida acad&eacute;mica, de investigaci&oacute;n, de extensi&oacute;n, eventos
                                        deportivos culturales,
                                        recreativos y sociales.</span></li>
                        <li><span style='color:black;'>Proceso
                                        de
                                        disociaci&oacute;n para darles tratamiento de forma an&oacute;nima.</span></li>
                        <li><span style='color:black;'>Para
                                        enviarle
                                        publicidad y comunicaciones con fines de mercadotecnia o tele-marketing
                                        o&nbsp;</span><span
                                        style='font-size:10.5pt;'>campa&ntilde;as<span
                                                style="color:black;">&nbsp;financieras.</span></span></li>
                </ul>
                <p style="margin-bottom:7.5pt;line-height:normal;"><span
                                style='color:black;'>Si usted no desea que
                                el ITESM trate
                                sus
                                datos personales para alguna de las finalidades descritas en este apartado, por favor
                                env&iacute;e un
                                correo
                                electr&oacute;nico a&nbsp;</span><a href="mailto:datospersonales@itesm.mx"><strong><span
                                                style='color:black;'>datospersonales@itesm.mx</span></strong></a><span
                                style='color:black;'>. Usted podr&aacute;
                                cambiar de
                                opci&oacute;n en cualquier momento, siguiendo el procedimiento, requisitos y plazos para
                                el ejercicio de
                                sus
                                Derechos ARCO y/o revocaci&oacute;n del consentimiento en nuestra p&aacute;gina de
                                internet&nbsp;</span><a
                                href="https://tec.mx/es/derechos-arco-yo-revocacion-del-consentimiento"><strong><span
                                                style='color:black;'>https://tec.mx/es/derechos-arco-yo-revocacion-del-consentimiento</span></strong></a><span
                                style='color:black;'>.</span></p>
                <p style="margin-bottom:7.5pt;line-height:normal;"><span
                                style='color:black;'>Es importante que
                                tenga presente que,
                                en
                                caso de otorgar su consentimiento, este seguir&aacute; siendo v&aacute;lido incluso
                                cuando hubiere
                                terminado
                                la relaci&oacute;n jur&iacute;dica entre usted y el ITESM, lo que significa que el ITESM
                                podr&aacute;
                                seguir
                                tratando sus datos personales necesarios para cumplir con las presentes finalidades
                                secundarias.</span>
                </p>
                <p style="margin-bottom:7.5pt;line-height:normal;"><span
                                style='color:black;'>&nbsp;</span></p>
                <p style="margin-bottom:7.5pt;line-height:normal;"><strong><span
                                        style='color:black;'>TRANSFERENCIAS</span></strong>
                </p>
                <p style="margin-bottom:7.5pt;line-height:normal;"><span
                                style='color:black;'>Con base en lo
                                establecido en el
                                art&iacute;culo 37 de la&nbsp;</span><span
                                >Ley
                                Federal de Protecci&oacute;n de Datos Personales en Posesi&oacute;n de los
                                Particulares<span style="color:black;">, el ITESM podr&aacute; transferir sus datos
                                        personales sin requerir de su
                                        consentimiento en los siguientes supuestos:<br>&nbsp;</span></span></p>
                <ul>
                        <li><span style='color:black;'>A
                                        sociedades
                                        controladoras, subsidiarias o afiliadas bajo el control com&uacute;n de ITESM, o
                                        a una sociedad
                                        matriz o
                                        cualquier sociedad del mismo grupo del responsable que opere bajos mismo
                                        procesos y pol&iacute;ticas
                                        internas.</span></li>
                        <li><span style='color:black;'>Autoridades
                                        competentes
                                        en los casos previstos por la normatividad aplicable, en el caso que
                                        recibi&eacute;ramos un
                                        requerimiento de obligado cumplimiento.</span></li>
                        <li><span style='color:black;'>Cuando
                                        sea precisa
                                        para
                                        el mantenimiento o cumplimiento de la relaci&oacute;n jur&iacute;dica entre
                                        Usted y el ITESM, como
                                        es el
                                        caso de personas f&iacute;sicas o morales, nacionales o internacionales con las
                                        que tengamos
                                        celebrados
                                        acuerdos jur&iacute;dicos vinculantes.</span></li>
                </ul>
                <p
                        style="margin-top:15.0pt;margin-bottom:7.5pt;line-height:normal;">
                        <strong><span style='color:black;'>DERECHOS ARCO
                                        Y/O
                                        REVOCACI&Oacute;N DEL CONSENTIMIENTO</span></strong>
                </p>
                <p
                        style="margin-top:15.0pt;margin-bottom:7.5pt;line-height:normal;">
                        <span style='color:black;'>Usted o su
                                representante legal
                                podr&aacute; ejercer cualquiera de los derechos de acceso, rectificaci&oacute;n,
                                cancelaci&oacute;n u
                                oposici&oacute;n (en lo sucesivo &ldquo;</span>&nbsp; &nbsp; &nbsp;<span
                                style='color:black;'>Derechos&nbsp;</span>&nbsp;
                        &nbsp;
                        &nbsp;<span style='color:black;'>ARCO&rdquo;),
                                as&iacute; como
                                revocar su consentimiento para el tratamiento de sus datos personales enviando un correo
                                electr&oacute;nico
                                a:&nbsp;</span><a href="mailto:datospersonales@itesm.mx"><strong><span
                                                style='color:black;'>datospersonales@itesm.mx</span></strong></a><span
                                style='color:black;'>&nbsp;y siguiendo el
                                procedimiento,
                                requisitos y plazos para el ejercicio de sus Derechos ARCO y/o revocaci&oacute;n del
                                consentimiento en
                                nuestra p&aacute;gina de internet&nbsp;</span><a
                                href="https://tec.mx/es/derechos-arco-yo-revocacion-del-consentimiento"><strong><span
                                                style='color:black;'>https://tec.mx/es/derechos-arco-yo-revocacion-del-consentimiento</span></strong></a><span
                                style='color:black;'>.</span>
                </p>
                <p
                        style="margin-top:15.0pt;margin-bottom:7.5pt;line-height:normal;">
                        <span style='color:black;'>Es importante que tenga
                                en cuenta
                                que
                                no en todos los casos podremos resolver favorablemente a su solicitud o concluir el uso
                                de forma
                                inmediata,
                                ya que es posible que por alguna obligaci&oacute;n legal requiramos seguir tratando sus
                                datos
                                personales.</span>
                </p>
                <p
                        style="margin-top:15.0pt;margin-bottom:7.5pt;line-height:normal;">
                        <strong><span style='color:black;'>LIMITACI&Oacute;N
                                        Y/O
                                        DIVULGACI&Oacute;N DE SUS DATOS</span></strong>
                </p>
                <p style="margin-bottom:7.5pt;line-height:normal;"><span
                                style='color:black;'>Usted podr&aacute;
                                limitar el uso o
                                divulgaci&oacute;n de sus datos personales enviando su solicitud a el ITESM por conducto
                                de un correo
                                electr&oacute;nico dirigido a la direcci&oacute;n&nbsp;</span><a
                                href="mailto:datospersonales@itesm.mx"><strong><span
                                                style='color:black;'>datospersonales@itesm.mx</span></strong></a><span
                                style='color:black;'>. En caso de que
                                su&nbsp;</span><span
                                >solicitud<span
                                        style="color:black;">&nbsp;sea
                                        procedente se le registrar&aacute; en el listado de exclusi&oacute;n propio del
                                        ITESM.</span></span>
                </p>
                <p style="margin-bottom:7.5pt;line-height:normal;"><span
                                style='color:black;'>&nbsp;</span></p>
                <p style="margin-bottom:7.5pt;line-height:normal;"><strong><span
                                        style='color:black;'>CAMBIOS AL
                                        AVISO DE
                                        PRIVACIDAD</span></strong></p>
                <p style="margin-bottom:7.5pt;line-height:normal;"><span
                                style='color:black;'>El ITESM le
                                notificar&aacute; de
                                cualquier cambio a su aviso de privacidad a trav&eacute;s de&nbsp;</span><a
                                href="https://tec.mx/es/avisos-de-privacidad-especiales"><span
                                        style='color:#1155CC;'>Avisos de
                                        Privacidad Especiales
                                        |
                                        Tecnol&oacute;gico de Monterrey</span></a><span
                                style='color:black;'>.&nbsp;</span>&nbsp;
                        &nbsp; &nbsp;
                </p>
                <p style="margin-bottom:7.5pt;line-height:normal;">&nbsp; &nbsp; &nbsp;</p>
                <p style="margin-bottom:7.5pt;line-height:normal;"><u><span
                                        style='color:black;'><span
                                                style="text-decoration: none;">&nbsp;</span></span></u></p>
                <p style="margin-bottom:7.5pt;text-align:right;line-height:normal;"><span
                                style='color:black;'>&Uacute;ltima
                                actualizaci&oacute;n:&nbsp;</span>&nbsp; &nbsp; &nbsp;<span
                                style='color:black;background:yellow;'>Agosto&nbsp;</span>&nbsp;
                        &nbsp; &nbsp;<span
                                style='color:black;background:yellow;'>2022</span>&nbsp;
                        &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</p>
        </div>
        <app-footer [ShowImage]="false"></app-footer>
</div>