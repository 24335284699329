import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import Utils from "@shared/helpers/utils";
import { IAlcance, IModalidad } from "@shared/Interface/ICatalogos.interface";
import { RetosResponse } from "@shared/Interface/IRetosResponse";
import { CasosDeExitoService } from "@shared/service/Casos_de_Exito/casos-de-exito.service";
import { CatalogosService } from "@shared/service/Catalogos/catalogos.service";
import { UsuariosService } from "@shared/service/Usuarios/usuarios.service";
import { lastValueFrom } from "rxjs/internal/lastValueFrom";

@Component({
  selector: "app-reto-implementar",
  templateUrl: "./reto-implementar.component.html",
  styleUrls: ["./reto-implementar.component.css"],
})
export class RetoImplementarComponent implements OnInit {
  @Input() idReto: number;
  @Input() status: string;
  @Input() oReto: RetosResponse;
  @Output() retoImplementar: EventEmitter<number> = new EventEmitter<number>();
  @Output() documentarR: EventEmitter<boolean> = new EventEmitter<boolean>();

  documentarReto: boolean = false;
  enviarForm: FormGroup;

  alcancesData: IAlcance[] = [];
  modalidadData: IModalidad[] = [];

  retoForm: FormGroup;

  lbl_nombre_reto: string ="";
  lbl_descripcion_reto: string="";
  lbl_objetivo_reto: string="";

  clavePerfil: string="";

  mostrarCE: boolean = true;
  postularCaso: boolean = false;
  RecienPostulado: boolean = false;

  constructor(
    public formBuilder: FormBuilder,
    private fb: FormBuilder,
    private catalogosService: CatalogosService,
    private usuarioService: UsuariosService,
    private casosDeExitoService: CasosDeExitoService
  ) {

  }

  ngOnInit(): void {

    this.clavePerfil = this.usuarioService.getCurrentUserData().clavePerfil;

    this.initForm();
    this.getCatalogos();
    this.lbl_nombre_reto = this.oReto.reto;
    this.lbl_descripcion_reto = this.oReto.descripcion;
    this.lbl_objetivo_reto = this.oReto.objetivo;

    if(this.status !== 'formalizacion'){
      this.documentarReto = true;
    }

    this.getCasoDeExito();
  }

  async getCatalogos() {
    try {
      this.alcancesData = await lastValueFrom(this.catalogosService.getAlcance());
      this.modalidadData = await lastValueFrom(this.catalogosService.getModalidad());
    } catch (error) {
      console.log(error);
    }
  }

  initForm() {

    this.retoForm = this.fb.group({
      alcance_reto: [this.oReto.idAlcance , Validators.required],
      nombre_reto: [this.oReto.reto, Validators.required],
      modalidad_reto: [this.oReto.idModalidad, Validators.required],
      descripcion_reto: [this.oReto.descripcion, Validators.required],
      objetivo_aprendizaje: [this.oReto.objetivo, Validators.required],
      validacion: [],
    });

    // this.retoForm.controls["descripcion_reto"].setValue(
    //   this.retoData.descripcion
    // );
    // this.retoForm.controls["objetivo_aprendizaje"].setValue(
    //   this.retoData.objetivo
    // );
  }


  documentar() {
    this.documentarReto = !this.documentarReto;
    this.documentarR.emit(true);
    this.retoForm.markAllAsTouched();
  }
  onSubmit() {

    this.retoForm.patchValue({ validacion: this.retoForm.valid });
    this.setReto();
  }

  setReto() {
    this.retoForm.patchValue({ validacion: this.retoForm.valid });
    this.retoImplementar.emit(this.retoForm.value);
  }

  entregables(event: any) {
    console.log(event);
  }
  compromisos(event: any) {
    console.log(event);
  }
  recursos(event: any) {
    console.log(event);
  }

  postular() {
    this.postularCaso = !this.postularCaso;
    this.RecienPostulado = !this.RecienPostulado;
  }

  async getCasoDeExito(){
    try{
      this.mostrarCE = true;
      let res = await lastValueFrom(this.casosDeExitoService.getCasoExitoPoridReto(this.oReto.idReto));
      if(res){
        this.mostrarCE = false;
      }
    }catch(err:any){
      Utils.processErrorResponse(err);
    }
  }

  retoData: any = {
    descripcion:
      "El objetivo principal es continuar con los elementos del bloque anterior construyendo en ello. Lo que realizará es un análisis de oportunidades de alto impacto en una empresa pequeña con presencia local (15-100 empleados). A partir de este análisis, se generán propuestas que sean económicamente rentables, ambientalmente sostenibles, escalables o innovadoras generando un impacto social y creando valor para el socio formador. Este proceso se lleva a cabo en un ambiente profundo de investigación y experimentación.",
    objetivo:
      "-Evalúa las oportunidades de proyectos emprendedores a través de las metodologías especializadas - Desarrolla una solución de negocio para un mercado objetivo. - Valida la solución y modelo de negocios en ambientes controlados.- Construye una estrategia personal para enfrentar las adversidades del emprendedor.",
    entregable: {
      descripcion:
        "Proyecto que contenga  el desarrollo de la solucioón mediante metodologías de ideación La propuesta de solución debe ser deseable (mercado), viable (financiera) y factible (técnica) al Consejo Directivo de la Empresa, Conclusiones y Referencias.",
      momento: "Una vez concluido el reto.",
    },
    compromiso: {
      descripcion:
        "Escribe aquí el primer compromiso por parte del Socio Formador.",
      momento: "Escribe aquí en qué momento requires la actividad",
    },
    recurso: {
      recurso: "Humano",
      descripcion: "Escribe a que se refiere el recurso",
      momento: "Escribe aquí en que momento requieres el recurso",
    },
  };
  retoDataResponse: any = {
    nombre_reto: "Análisis de oportunidades de alto impacto con la Casita",
    descripcion:
      "El objetivo principal es continuar con los elementos del bloque anterior construyendo en ello. Lo que realizará es un análisis de oportunidades de alto impacto en una empresa pequeña con presencia local (15-100 empleados). A partir de este análisis, se generán propuestas que sean económicamente rentables, ambientalmente sostenibles, escalables o innovadoras generando un impacto social y creando valor para el socio formador. Este proceso se lleva a cabo en un ambiente profundo de investigación y experimentación.",
    objetivo:
      "-Evalúa las oportunidades de proyectos emprendedores a través de las metodologías especializadas - Desarrolla una solución de negocio para un mercado objetivo. - Valida la solución y modelo de negocios en ambientes controlados.- Construye una estrategia personal para enfrentar las adversidades del emprendedor.",
    entregable: [
      {
        descripcion:
          "Proyecto que contenga  el desarrollo de la solucioón mediante metodologías de ideación La propuesta de solución debe ser deseable (mercado), viable (financiera) y factible (técnica) al Consejo Directivo de la Empresa, Conclusiones y Referencias.",
        momento: "Una vez concluido el reto.",
      },
      {
        descripcion:
          "Proyecto que contenga  el desarrollo de la solucioón mediante metodologías de ideación La propuesta de solución debe ser deseable (mercado), viable (financiera) y factible (técnica) al Consejo Directivo de la Empresa, Conclusiones y Referencias.",
        momento: "Una vez concluido el reto.",
      },
    ],
  };

}
