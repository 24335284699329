<app-header-buscador [showSearchBar]="false"></app-header-buscador>

<div class="sec_ad">
  <div>
    <img class="img_hdr" alt="Imagen de Reto" src="assets/img/img_reto_fullsize.jpg" />
  </div>
  <div id="sec_tit">
    <div class="d-flex align-items-center">
      <h2 class="tituloSubPagina w-100">{{ titleVinculacion.title }}</h2>
      <app-button class="flex-shrink-1" (callbackAction)="redirectBancoRetos()"> Ver banco de retos </app-button>
    </div>


  </div>

  <div class="div-text-btn">
    <div class="question-div">
      ¿Cuál es el problema o tema de interés con el que te gustaría vincularte a un reto
      académico?
    </div>
  </div>
  <form [formGroup]="vinculacionForm" (submit)="searchRetos()" autocomplete="off">
    <div class="row questions-container open-text">
      <div class="col-md-9 col-sm-12">
        <div class="row">
          <div class="col-md-12 espacio">
            <span class="question-title-text">Acerca de la Problemática</span>
          </div>
          <div class="col-md-12 espacio">
            <span class="question-text">¿Qué áreas de tu empresa/organización están involucradas? <span
                class="text-danger">*</span></span>
          </div>
          <div class="col-md-12 ">
            <span class="answer-title-text">Selecciona una o más opciones</span>
            <div class="row">
              <div class="col-md-12 answer-text">
                <div class="row">
                  <div class="col col-6" *ngFor="let area of areaData">
                    <input type="checkbox" class="btn-check" id="{{ area.area }}" autocomplete="off"
                      formArrayName="areas" value="{{ area.idArea }}" (change)="onCheckboxChange($event, 'areas')" />
                    <label class="btn btn-outline-primary" for="{{ area.area }}"></label>
                    <span>{{ area.area }}</span>
                    &nbsp;<input type="text" *ngIf="area.area=='Otra'" formControlName="otraArea">
                  </div>
                </div>
                <div class="row">
                  <div class="col col-md-6">

                  </div>
                </div>
              </div>
              <div class="text-danger" *ngIf="vinculacionForm.get('areas')?.invalid && vinculacionForm.get('areas')?.touched">
                  El campo es requerido. Áreas involucradas
              </div>
            </div>

            <div class="row">
              <div class="col espacio">
                <label for="">Alcance de la problemática o tema de interés<span class="text-danger">*</span></label>
              </div>
            </div>
            <div class="row">
              <div class="col icons-checkbox" *ngFor="let alcance of alcanceData">
                <div class="icon-div">
                  <img src="assets/svg/icon_{{ alcance.alcance | lowercase }}.svg" alt="icon-local" />
                </div>
                <p>{{ alcance.alcance }}</p>
                <input type="radio" class="btn-check" id="{{ alcance.alcance }}" name="idAlcance"
                  value="{{ alcance.idAlcance }}" autocomplete="off" formControlName="idAlcance" />
                <label class="btn btn-outline-primary" for="{{ alcance.alcance }}"></label>
              </div>
              <div class="text-danger" *ngIf="vinculacionForm.get('idAlcance')?.invalid && vinculacionForm.get('idAlcance')?.touched">
                El campo es requerido. Alcance de la problemática
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-md-12 espacio">
                <label for="proyecto">Nombre de la problemática <span class="text-danger">*</span></label>
                <app-text-input [formControl]="$any(vinculacionForm.controls['proyecto'])" [label]="'Problemática'"
                  [placeholder]="'Escribe el nombre aquí'"></app-text-input>
              </div>
              <div class="col-md-12 espacio">
                <label for="problematica">Contexto de la problemática o tema de interés <span
                    class="text-danger">*</span></label>
                <textarea class="forma_area form-control" id="problematica" placeholder="¿De qué trata tu problemática?"
                  [formControl]="$any(vinculacionForm.controls['problematica'])"
                  [ngClass]="{'is-invalid':vinculacionForm.get('problematica')?.invalid && vinculacionForm.get('problematica')?.touched}"></textarea>
                <div class="text-danger" *ngIf="vinculacionForm.get('problematica')?.invalid && vinculacionForm.get('problematica')?.touched">
                  El campo es requerido. Contexto de la Problemática
                </div>

              </div>

              <div class="col-md-12 espacio">
                <label for="objetivos">¿Qué objetivos buscas lograr con el acompañamiento académico? <span
                    class="text-danger">*</span>
                </label>
                <textarea class="forma_area form-control" id="objetivos" placeholder="Cuéntanos tus objetivos"
                  [formControl]="$any(vinculacionForm.controls['objetivos'])"
                  [ngClass]="{'is-invalid':vinculacionForm.get('objetivos')?.invalid && vinculacionForm.get('objetivos')?.touched}"></textarea>
                <div class="text-danger" *ngIf="vinculacionForm.get('objetivos')?.invalid && vinculacionForm.get('objetivos')?.touched">
                  El campo es requerido. Objetivos
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-3 col-6">
          <app-button ngForm buttonType="yell" extraClass="btn-full" [isDisabled]="buttonSave.isLoading">
            {{ buttonSave.title }}</app-button>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="row" *ngIf="validationErrors.length > 0">
            <ul class="text-danger">
              <li *ngFor="let error of validationErrors">
                {{ error }}
              </li>
            </ul>
          </div>
        </div>
      </div>


    </div>
  </form>
</div>
