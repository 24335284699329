<div class="row" *ngIf="existeContacto">
  <div class='col-4'>
    <img class="img-card-{{ size }}" alt="Foto Usuario" src="{{ urlFotoUsuario }}" />
  </div>
  <div class="col-8 mt-3">
    <div class="row">
      <div class="col">
        <span class="text-card-name-{{ size }}">{{ nombre }} {{ apellidos }}</span>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col">
        <span class="text-card-carrier-{{ size }}">{{ area }}</span>
      </div>
    </div>
    <div class="row">
      <div class="col" *ngIf="showMoreDetailsButton">
        <a (click)="listerActionDetails(contactObj)" class="text-action-show-{{ size }}">Editar información del contacto</a>
      </div>
    </div>
    <div class="row" *ngIf="mostrarEliminar">
      <div class="col">
        <a (click)="listerActionDelete(contactObj)" class="text-action-delete-{{ size }}">Eliminar contacto</a>
      </div>
    </div>
  </div>
</div>