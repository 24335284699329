import { Component, EventEmitter, Input,  OnInit,  Output } from '@angular/core';
import { Router } from '@angular/router';
import { IArea } from '@shared/Interface/ICatalogos.interface';
import { IContacto } from '@shared/Interface/IContacto.interface';
import { CatalogosService } from '@shared/service/Catalogos/catalogos.service';
import { UsuariosService } from '@shared/service/Usuarios/usuarios.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-card-contact',
  templateUrl: './card-contact.component.html',
  styleUrls: ['./card-contact.component.css'],
})
export class CardContactComponent implements OnInit {
  @Input() size = 'sm';
  @Input() contactObj: IContacto;
  @Input() showMoreDetailsButton: boolean;
  @Input() showDeleteContactButton: boolean;
  @Output() callbackDetails: EventEmitter<any> = new EventEmitter<IContacto>();
  @Output() callbackDelete: EventEmitter<any> = new EventEmitter<IContacto>();


  marginTop = this.size === 'lg' ? '' : 'mt-5';

  urlFotoUsuario: string = 'https://mvretosstorage.blob.core.windows.net/mvpretos-user-images/reto_4.jpg';
  nombre: string ='';
  apellidos: string ='';
  area: string = '';
 // contactoPrincipal: boolean = false;
  mostrarEliminar: boolean = false;

  existeContacto: boolean = false;

  AreasData: IArea[]=[];

  constructor(
    private router: Router,
    private usuarioService: UsuariosService,
    private catalogosService: CatalogosService
    ) {

  }



  async ngOnInit(){
    this.AreasData = await lastValueFrom(this.catalogosService.getAreas());
    if(this.contactObj){
      this.urlFotoUsuario = this.contactObj.urlFotoUsuario||this.urlFotoUsuario;
      this.nombre = this.contactObj.nombre;
      this.apellidos = this.contactObj.apellidos;
      this.area = this.contactObj.area;

      this.area = this.AreasData.find(x=>x.idArea === Number(this.contactObj.area))?.area ?? this.contactObj.area;

      if (this.contactObj.idUsuario == this.usuarioService.getCurrentUserData().idUsuario && this.usuarioService.getCurrentUserData().contactoPrincipal){
        this.mostrarEliminar = false;
      }
      else{
        this.mostrarEliminar = this.showDeleteContactButton;
      }

      this.existeContacto = true;
    }
  }
  redirectPage(contactObj: IContacto) {
    if (contactObj.contactoPrincipal) {
      this.router.navigate(['/mi-perfil']);
    }
  }

  listerActionDetails(contactObj: IContacto) {
    this.callbackDetails.emit(contactObj);
  }

  listerActionDelete(contactObj: IContacto) {
    this.callbackDelete.emit(contactObj);
  }


}
