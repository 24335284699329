<app-header-buscador lbl_placeholder="Buscar ... " (fatherNameFunction)="buscadorPress($event)"></app-header-buscador>

<div>
  <div class="sec_ad">
    <app-title-show-more [showMoreObject]="title"></app-title-show-more>

    <!-- Filters -->
    <div class="row">

      <!-- Filtro de Periodo Semestral -->
      <div class="col-md">
        <div class="btn-group order-by">
          <button class="btn dropdown-toggle" type="button" id="listado" data-bs-toggle="dropdown"
            data-bs-auto-close="true" aria-expanded="false" data-bs-offset="0,0"
            title="{{ filter.idPeriodoSemestral ? getValueCatalogo(periodoSemestralData, 'idPeriodoSemestral', filter.idPeriodoSemestral, 'periodoSemestral') : 'Periodo Semestral' }}">
            {{ filter.idPeriodoSemestral ? getValueCatalogo(periodoSemestralData, 'idPeriodoSemestral', filter.idPeriodoSemestral, 'periodoSemestral') : 'Periodo Semestral' }}
          </button>
          <ul class="dropdown-menu dropdown-menu-start" aria-labelledby="listado">
            <li *ngFor="let periodo of periodoSemestralData; let i = index">
              <a class="dropdown-item {{ i === 0 ? 'active' : '' }}" (click)="addFilter('idPeriodoSemestral', periodo.idPeriodoSemestral)">{{
                periodo.periodoSemestral }}</a>
            </li>
          </ul>
        </div>
      </div>

      <!-- Filtro de Periodo Semanal -->
      <div class="col-md">
        <div class="btn-group order-by">
          <button class="btn dropdown-toggle" type="button" id="listado" data-bs-toggle="dropdown"
            data-bs-auto-close="true" aria-expanded="false" data-bs-offset="0,0"
            title="{{ filter.idPeriodoSemanal ? getValueCatalogo(periodoSemanalData, 'idPeriodoSemanal', filter.idPeriodoSemanal, 'periodoSemanal') : 'Periodo Semanal' }}">
            {{ filter.idPeriodoSemanal ? getValueCatalogo(periodoSemanalData, 'idPeriodoSemanal', filter.idPeriodoSemanal, 'periodoSemanal') : 'Periodo Semanal' }}
          </button>
          <ul class="dropdown-menu dropdown-menu-start" aria-labelledby="listado">
            <li *ngFor="let periodo of periodoSemanalData; let i = index">
              <a class="dropdown-item {{ i === 0 ? 'active' : '' }}" (click)="addFilter('idPeriodoSemanal', periodo.idPeriodoSemanal)">{{
                periodo.periodoSemanal }}</a>
            </li>
          </ul>
        </div>
      </div>

      <!-- Filtro de Departamento -->
      <div class="col-md">
        <div class="btn-group order-by">
          <button class="btn dropdown-toggle" type="button" id="listado" data-bs-toggle="dropdown"
            data-bs-auto-close="true" aria-expanded="false" data-bs-offset="0,0"
            title="{{ filter.departamento ? getValueCatalogo(departamentoData, 'idDepartamento', filter.departamento, 'departamento') : 'Departamento' }}">
            {{ filter.departamento ? getValueCatalogo(departamentoData, 'idDepartamento', filter.departamento, 'departamento') : 'Departamento' }}
          </button>
          <ul class="dropdown-menu dropdown-menu-start" aria-labelledby="listado">
            <li *ngFor="let periodo of departamentoData; let i = index">
              <a class="dropdown-item {{ i === 0 ? 'active' : '' }}" (click)="addFilter('departamento', periodo.idDepartamento)">{{
                periodo.departamento }}</a>
            </li>
          </ul>
        </div>
      </div>


      <!-- Reestablecer Filtros -->
      <div class="col-md d-flex justify-content-end">
        <app-button (callbackAction)="cleanFilters()"> Restablecer </app-button>
      </div>
    </div>


    <!-- Table results -->
    <div class="tab-pane fade show active" id="tab-academico-aprobar" role="tabpanel"
    aria-labelledby="tab-academico-aprobar-tab">

      <app-table
        [backgroundColor]="backgroundTable"
        [columns]="retosSocioColumns"
        [data]="solicitudAcadBuscaSocioFormadorDataPage">
      </app-table>

      <app-pagination
        *ngIf="solicitudAcadBuscaSocioFormadorData.length"
        [objPagination]="solicitudAcadBuscaSocioFormadorData"
        (paginationDataChange)="showPaginationDataRevision($event)">
      </app-pagination>

    </div>

  </div>


</div>
