import { ICampus } from "./ICatalogos.interface";

export interface ISolicitudGetResponse {
    idSolicitud: number,
    idTipoSolicitud: number,
    tipoSolicitud: string,
    descripcionSolicitud: string,
    idProblematica: number,
    idRetoMaestro: number,
    idSocioFormador: number,
    fechaRegistro: Date,
    idUsuarioCVDP: number,
    fechaCVDP: Date,
    idUsuarioAcademia: number,
    fechaAcademia: Date,
    claveEstatus: string,
    estatus: string,
    fechaResolucion: string,
    format: Date,
    areaCanalizacion: string,
    motivoRechazo: string,
    idReto: number,
    idPeriodo: number,
    actualizadoPor: string,
    idCampus: number,
    fechaActualizacion: string,
    modalidad: string
    idModalidad: number
    campus: ICampus[],
    idLogBusquedaReto: number
    idLogBusquedaSocio: number
    parametrosBusquedaSocio: string

  }

  export class cSolicitudGetResponse implements ISolicitudGetResponse{
    idSolicitud: number;
    idTipoSolicitud: number;
    tipoSolicitud: string;
    descripcionSolicitud: string;
    idProblematica: number;
    idRetoMaestro: number;
    idSocioFormador: number;
    fechaRegistro: Date;
    idUsuarioCVDP: number;
    fechaCVDP: Date;
    idUsuarioAcademia: number;
    fechaAcademia: Date;
    claveEstatus: string;
    estatus: string;
    fechaResolucion: string;
    format: Date;
    areaCanalizacion: string;
    motivoRechazo: string;
    idReto: number;
    idPeriodo: number;
    actualizadoPor: string;
    idCampus: number;
    fechaActualizacion: string;
    modalidad :string;
    idModalidad: number;
    campus: ICampus[];
    idLogBusquedaReto: number;
    idLogBusquedaSocio: number;
    parametrosBusquedaSocio: string;

    constructor(){
      this.idSolicitud = 0;
      this.idTipoSolicitud = 0;
      this.tipoSolicitud = '';
      this.descripcionSolicitud='';
      this.idProblematica = 0;
      this.idRetoMaestro = 0;
      this.idSocioFormador = 0;
      // this.fechaRegistro: Date;
      this.idUsuarioCVDP = 0;
      // this.fechaCVDP: Date;
      this.idUsuarioAcademia = 0;
      // this.fechaAcademia: Date;
      this.claveEstatus = '';
      this.estatus = '';
      this.fechaResolucion = '';
      // this.format: Date;
      this.areaCanalizacion = '';
      this.motivoRechazo = '';
      this.idReto = 0;
      this.idPeriodo = 0;
      this.actualizadoPor = '';
      this.idCampus  = 0;
      this.modalidad ="";
      this.idModalidad = 0;
      this.campus = [];
      this.idLogBusquedaReto = 0;
      this.idLogBusquedaSocio = 0;
      this.parametrosBusquedaSocio = "";
    }

  }

