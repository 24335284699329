import { Component } from '@angular/core';

@Component({
  selector: 'app-solicitud-academico',
  templateUrl: './solicitud-academico.component.html',
  styleUrls: ['./solicitud-academico.component.css'],
})
export class SolicitudAcademicoComponent  {
  solicitudObj = {
    decripcion:
      'Reporte de análisis de la estructura de financiamiento y de los órganos de gobierno corporartivo de la empresa.',
    entrgable: `Determinar costos de cada una de las fuentes de financiamiento del
      Socio Formador y posteriormente el costo de capital promedio
      ponderado. El reto se trabaja con los estados financieros y si es
      factible, con entrevistas con directivos del Socio Formador bajo
      la guía del profesor.`,
    reto: 'Empresa que cotiza en la Bolsa.',
    escuela: 'Escuela de Negocios',
    fechaInicio: '28 de Agosto de 2022',
    duracion: '5 semanas',
    departamento: 'Nombre del Departamento',
    fechaTermino: '2 de Octubre de 2022',
    coordinador: 'Agustina Lara Sánchez',
  };
  titleSolicitud = {
    title: 'Académico que busca Socio Formador',
  };
  contactRequest = {
    nombre: 'Beatriz',
    apellidos: 'Pinzón Solano',
    area: 'Adquisición de Talento y Recursos Humanos',
    img: 'avatar_1',
    isContact: true,
    removedButtons: true,
  };
  generalInfoIcons = [
    {
      icon: 'icon_local',
      title: 'Local',
    },
    {
      icon: 'icon_nacional',
      title: 'Nacional',
    },
    {
      icon: 'icon_language',
      title: 'Espanol',
    },
    {
      icon: 'icon_semestre',
      title: '4 Semestre',
    },
    {
      icon: 'icon_season',
      title: 'Agosto-Noviembre',
    },
  ];
  
}
