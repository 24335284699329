import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { IfilesRetosResponse } from "@shared/Interface/IArchivos";
import { ArchivosService } from "@shared/service/Archivos/archivos.service";
import { AzureBlobStorageService } from "@shared/service/AzureBlobStorage.Service";
import { UsuariosService } from "@shared/service/Usuarios/usuarios.service";
import { environment } from "environments/environment";
import { ToastrService } from "ngx-toastr";
import { lastValueFrom } from "rxjs";
import Swal from "sweetalert2";

@Component({
  selector: "app-carga-documentos",
  templateUrl: "./carga-documentos.component.html",
  styleUrls: ["./carga-documentos.component.css"],
})
export class CargaDocumentosComponent implements OnInit {
  [x: string]: any;
  titleCargaDocumentos = {
    title: "Carga de archivos",
  };

  @ViewChild("modal_prev") modal_prev: ElementRef;

  archivosRetosData: IfilesRetosResponse[] = [];
  archivosVinculatecACData: any = [];
  archivosVinculatecSFData: any = [];
  resumenData: any =[];

  constructor(
    private archivosService: ArchivosService,
    private usuariosService: UsuariosService,
    private toastr: ToastrService,
    private azureBlobStorage: AzureBlobStorageService,
    private modalService: NgbModal,
  ) {}

  ngOnInit(): void {
    this.cargarArchivos();
  }

  async cargarArchivos() {
    try {
      let res = await lastValueFrom(this.archivosService.getListadoArchivos());
      this.archivosRetosData = res.slice(0, 6);
    } catch (error) {}
  }

  async selImagen(event: any) {
    let selFile = (event.target as HTMLInputElement).files?.[0];
    if (!selFile) return;
    let fileName = this.getFileName(selFile);
    await this.uploadSocioImage(selFile, fileName);
  }

  async uploadSocioImage(selFile, fileName) {
    await this.azureBlobStorage
      .uploadFile(
        {
          containerName: environment.AzureBlob_containerNameCarga,
          file: selFile,
          filename: fileName,
        },
        environment.AzureBlob_ACCOUNT_NAME,
        environment.AzureBlob_cargaSAS
      )
      .then(async (res) => {
        this.toastr.success("Archivo cargadocon éxito");
        let fileUrlLogo = `${environment.AzureBlob_cargaURL}${fileName}`;
        let idUsuario = this.usuariosService.getCurrentUserData().idUsuario;
        await lastValueFrom(
          this.archivosService.postArchivo(idUsuario, fileUrlLogo, "retos")
        );
        this.cargarArchivos();
      });
  }

  getFileName(file) {
    let id = this.makeid(5);
    return `carga-masiva-retos-${id}.${file.name.split(".")[1]}`.toLowerCase();
  }

  makeid(length) {
    let result = Date.now().toString();
    return result;
  }

  async verResumen(idArchivoProcesar:number){
    try {
      this.resumenData = [];
      let res = await lastValueFrom(this.archivosService.getArchivoLogs(idArchivoProcesar));
      res.forEach(element => {
        this.resumenData.push({
          descripcion: 'Validación',
          valor: element.observaciones
        });
      });

      let res2 = await lastValueFrom(this.archivosService.getArchivoResumen(idArchivoProcesar));
      res2["#result-set-1"].forEach(element => {
        this.resumenData.push({descripcion: element.Descripcion, valor: element.Valor});
       });

    } catch (error) {
      console.log(error);
    }
    this.modalService.open(this.modal_prev, {
      centered: true,
    });
  }

  guardarArchivo(idArchivoProcesar:number){
    try {
      Swal.fire({
        text:'¿Desea procesar el archivo?',
      }).then(async (result) => {
        if(result.isConfirmed){
        await lastValueFrom(this.archivosService.postArchivoProcesar(idArchivoProcesar));
        }
      });
    } catch (error) {
      this.toastr.error("ocurrió un error, intentar más tarde.");
      console.log(error);
    }
  }
}

