<div class="row fondocard_h">
  <div class="row">

    <div class="col-md-4">
      <img class="imgcarrousel_izq imgcarrousel_izq" alt="imagenReto" src="{{ imgReto }}" />
    </div>

    <div class="col-md-8">

      <div class="row h-85">
        <div class="col-12 colmatch">
          <div class="row">

            <div class="col-11">
              <span class="titsans">{{ objReto.retoMaestro }}</span><br />
              <span class="subtit1col">{{ objReto.escuela }}</span>
              
              <!-- Widget de evaluacion -->
              <div class="row">
                <div class="col-3 mt-1">
                  <app-evaluacion [typeEvaluation]="'RETO'" [evaluacion]="objReto.evaluacion" [clavePerfil]="clavePerfil"></app-evaluacion>
                </div>
                <div class="col-10"></div>
              </div>
              
            </div>

            <div class="col-1 mt-3 d-flex justify-content-center align-items-center">
              <img alt="Sentido Humano" *ngIf="objReto.sentidoHumano" class="sentidoHumano"
                src="\assets\img\sentidohumano.svg" width="50px" heigth="50">

              <!-- Btn de compartir url -->
              <button class="btn-blue-share ms-3" (click)="generarShareUrl(objReto.idRetoMaestro)"
                (cdkCopyToClipboardCopied)="copiado()">
                <svg ngbTooltip="Compartir" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                  fill="currentColor" class="bi bi-share" viewBox="0 0 16 16">
                  <path
                    d="M13.5 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5zm-8.5 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm11 5.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3z" />
                </svg>
              </button>
            </div>

          </div>


          <hr style="color: #BEDCFE">
          <div class="col-12 align-top">
            <span title="{{ objReto.descripcion }}">{{ lbl_descripcion }}</span>
          </div>
        </div>

        <div class="row mt-2">

          <div class="col-12">
            <app-chip-item *ngIf="lbl_semestres" [icon]="'icon_chip_carrera'" [title]="lbl_semestres">
            </app-chip-item>
          </div>

          <div class="col-12">
            <app-chip-item *ngIf="lbl_semana" [icon]="'icon_chip_semestre'" [title]="lbl_semana"></app-chip-item>
          </div>

        </div>

      </div>

      <div class="row h-10">

        <div class="col-4">&nbsp;</div>

        <div class="col-4">
          <app-button *ngIf="MostrarBotonEditar" (callbackAction)="editarReto(objReto.idRetoMaestro)"
            buttonType="yell">Editar</app-button>
        </div>

        <div class="col-4">
          <app-button buttonType="yell" (callbackAction)="verDetalleReto(objReto.idRetoMaestro)">Más
            información</app-button>
        </div>

      </div>
    </div>

  </div>
</div>