<app-header-buscador [showSearchBar]="false"></app-header-buscador>

<div>
  <section class="container-fluid">
    <p class="title">Notificaciones</p>
    <br />

    <div class="row" *ngIf="this.notifications === undefined" >
        <div class="col text-center">
          <app-sin-datos-table></app-sin-datos-table>
        </div>
      </div>

    <div *ngFor="let notification of notifications">
      <section class="row mt-4 mb-4">
        <div
          class="col-md-1 txt_title_date_noti"
          [innerHTML]="formatDate(notification.date)"
        ></div>
        <div class="col-md-6"><hr style="height: 2px;" /></div>
      </section>

      <section class="row" *ngFor="let msj of notification.messages">
        <div class="col-sm-2 col-md-2 col-lg-2 col-xl-1">
          <img
            *ngIf="(msj.tipoSolicitud.toLowerCase().includes('vinculo') || msj.tipoSolicitud.toLowerCase().includes('vinculacion') || msj.tipoSolicitud.toLowerCase().includes('vinculaci�n')); else elseBlock"
            class="img_noti"
            src="/assets/img/ico_notificaciones.png"
            alt=""
          />
          <ng-template #elseBlock>
            <img
              #elseBlock
              class="img_noti"
              src="/assets/img/ico_misretos.png"
              alt=""
            />
          </ng-template>
        </div>
        <div class="col-sm-10 col-md-6">
          <p class="m-0 txt_title_noti">
            <span [innerHTML]="msj.tipoSolicitud"></span>
            &nbsp;<span class="txt_title_bold_noti" [innerHTML]="msj.reto"></span>
          </p>
          <p class="txt_date_noti mb-5" [innerHTML]="formatTime(msj.fechaSolicitud) + ' hrs'" ></p>
        </div>
      </section>
    </div>
  </section>
</div>
