import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Paginador } from '@shared/Interface/IPaginador.interface';
import { IUsuariosAcademiaResponse } from '@shared/Interface/IUsuariosAcademiaResponse';
import { PaginadorLibsComponent } from '@shared/libs/paginador.component';
import { UsuariosService } from '@shared/service/Usuarios/usuarios.service';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { tap, lastValueFrom } from 'rxjs';
import Swal from 'sweetalert2';

export interface IescuelaObj {
  escuela: string;
  id: number;
  usuarios: IUsuariosAcademiaResponse[];
}

@Component({
  selector: 'app-academia',
  templateUrl: './academia.component.html',
  styleUrls: ['./academia.component.css'],
})
export class GestionUsuarioACComponent implements OnInit {
  sidebarExpanded = false;
  lang: string = 'es';
  numberPerPage = environment.RegistrosPagina;
  pages: number[] = [];
  paginaActual = 1;
  numeroPaginas = 0;
  numeroPaginasTo = 0;
  usuariosList: IUsuariosAcademiaResponse[] = [];
  usuariosListTotal: [] = [];
  elementsTo: IescuelaObj[] = [];
  elementsTotal: IescuelaObj[] = [];
  paginaActualMasResultados: number = 0;
  ordenamientoBy: string = 'nombre'; //ajustar al parametro que recibe la api "nombre/campus"
  textOrdenarPor: string = 'gestion_usuarios.ordenar_por';

  queryFilter:string = '';

  constructor(
    private _usuariosService: UsuariosService,
    private paginadorLibs: PaginadorLibsComponent,
    private toastr: ToastrService,
    private router: Router,
    private changeDetector: ChangeDetectorRef
  ) {}

  async ngOnInit(): Promise<void> {
    this.lang = localStorage.getItem('lang') ?? 'es';
    await this.getUsuarios(this.ordenamientoBy);

    this._usuariosService.queryFilter$.subscribe( async text => {
      this.queryFilter = text;
      await this.getUsuarios(this.ordenamientoBy);
    });
  }

  async getUsuarios(ordenamiento: string) {
    try {
      this.usuariosListTotal = [];
      this.usuariosList=[];
      let response:any = [];
      let response1 = <any>await lastValueFrom(this._usuariosService.getUsuariosAcademiaByFiltro(ordenamiento,'',0,0,0));
      let response2 = <any>await lastValueFrom(this._usuariosService.getUsuariosAcademiaByFiltro(ordenamiento,'',0,0,1));
      let responsetmp = [...response1, ...response2];

      response = responsetmp.map((user:IUsuariosAcademiaResponse)=>({
        ...user,
        lblEstatus: (user.estatus?.trim()=='activo' && user.avisoPrivacidad == 1 && user.fechaUltimoLogin ?'Activo':'Inactivo'),
        nivelGestion: user.nivelGestion ?? 'Usuario',
        region: user.region ?? '-'
      }));

      if (response != null && response != undefined && response.length > 0) {
        if(this.queryFilter!=''){
          this.usuariosListTotal = response.filter((user :IUsuariosAcademiaResponse) => {
            let filter = this.queryFilter.toUpperCase();
            return user.nombreUsuario.toUpperCase().includes(filter)
                || (user.campus || '').toUpperCase().includes(filter)
                || (user.escuela || '').toUpperCase().includes(filter)
                || (user.lblEstatus || '').toUpperCase() == filter
                || (user.nivelGestion || '').toUpperCase().includes(filter)
                || (user.region || '').toUpperCase().includes(filter)
                || (user.correo || '').toUpperCase().includes(filter);
                
          });
        }
        else{
          this.usuariosListTotal = response;
        }
       this.showPaginationData(this.usuariosListTotal.slice(0,this.numberPerPage));
      }
    } catch (error) {
      console.error('Error al realizar la petición', error);
    }
  }

  obtenerMasResultados() {
    this.paginaActualMasResultados += 1;
    this.elementsTo = this.paginadorLibs.obtenerMasResultados(
      this.paginaActualMasResultados,
      this.numberPerPage,
      this.elementsTotal
    );
  }

  async agrugarBySF() {
    this.elementsTotal = [];
    let index = 1;
    this.usuariosListTotal.forEach((element: IUsuariosAcademiaResponse) => {
      let sf: IescuelaObj = {
        escuela: element.escuela,
        id: index,
        usuarios: [],
      };
      sf.usuarios.push(element);

      let buscar = this.elementsTotal.filter(
        (el) => el.escuela == element.escuela
      );
      if (buscar.length > 0) {
        this.elementsTotal.forEach((elementTo) => {
          if (elementTo.escuela == element.escuela) {
            let buscarUser = elementTo.usuarios.filter(
              (el) => el.idUsuario == element.idUsuario
            );
            if (buscarUser.length == 0) {
              elementTo.usuarios.push(element);
            }
          }
        });
      } else {
        this.elementsTotal.push(sf);
      }
      index++;
    });

    this.obtenerMasResultados();
    let paginador: Paginador = this.paginadorLibs.getPaginacion(
      this.elementsTotal.length,
      this.numberPerPage
    );
    this.numeroPaginasTo = paginador.totalPaginas;
  }

  async orderBy(ordenamiento: string) {
    switch (ordenamiento) {
      case 'nombre':
        this.textOrdenarPor = 'gestion_usuarios.nombre_az';
        break;

      case 'campus':
        this.textOrdenarPor = 'gestion_usuarios.campus';
        break;
    }

    this.ordenamientoBy = ordenamiento;
    await this.getUsuarios(ordenamiento);

  }

  changeLang(lang: string) {
    this.lang = lang;
    localStorage.setItem('lang', lang);
    window.location.reload();
  }

  editarUsuario(idUsuario: number) {
    this.router.navigate([`edit-user/academia/${idUsuario}`]).catch((error)=> console.log(error));
  }

  async eliminarUsuario(idUsuario: number) {
    await Swal.fire({
      title: '¿Desea eliminar el usuario?',
      text: '',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Borrar',
    }).then((result) => {
      if (result.isConfirmed) {

        this._usuariosService
      .delteUser(idUsuario)
      .pipe(
        tap((resultado: any) => {
          if (resultado) {
            Swal.fire('Gestión de Usuarios.', 'Usuario Eliminado.', 'success').catch((error)=> console.log(error));

            this.ngOnInit();
          } else {
            this.toastr.error('Algo Salió mal!');
          }
        })
      )
      .subscribe();

      }
    });


  }

  agregarUsuario() {
    localStorage.setItem('AU-Ctx-nU', 'true');
    localStorage.setItem('AU-Ctx-nU-type', 'academia');
    this.router.navigate(['new-user/academia']).catch((error)=> console.log(error));
  }


  showPaginationData(objData: any) {
    if (objData.length) {

      this.usuariosList = objData;

      this.changeDetector.detectChanges();
    }
  }

  restablecer(){

    window.location.reload();
  }

  verDatosUsuario(idUsuario, el){
    this._usuariosService.getUsuarioById(idUsuario).subscribe({
      next: (resOK)=>{

        let mensaje = '<div class="row text-start w-100">' +
      '<div class="col-sm-6 "><h5>Nombre:</h5></div>' +
      '<div class="col-sm-6"><h6>&nbsp;&nbsp;' + resOK.nombreUsuario + '</h6></div>' +
      '<div class="col-sm-6"><h5>Apellidos:</h5></div>' +
      '<div class="col-sm-6"><h6>&nbsp;&nbsp;' + resOK.apellidosUsuario + '</h6></div>' +
      '<div class="col-sm-6"><h5>Correo Electrónico:</h5></div>' +
      '<div class="col-sm-6"><h6>&nbsp;&nbsp;' + resOK.usuario + '</h6></div>' +
      '<div class="col-sm-6"><h5>Nómina:</h5></div>' +
      '<div class="col-sm-6"><h6>&nbsp;&nbsp;' + resOK.nomina + '</h6></div>' +
      '<div class="col-sm-6"><h5>Campus:</h5></div>' +
      '<div class="col-sm-6"><h6>&nbsp;&nbsp;' + resOK.campus + '</h6></div>' +
      '<div class="col-sm-6"><h5>Rol Académico:</h5></div>' +
      '<div class="col-sm-6"><h6>&nbsp;&nbsp;' + resOK.rolAcademico + '</h6></div>'+
      '<div class="col-sm-6"><h5>Escuela:</h5></div>' +
      '<div class="col-sm-6"><h6>&nbsp;&nbsp;' + resOK.escuela + '</h6></div>'+
      '<div class="col-sm-6"><h5>Estatus:</h5></div>' +
      '<div class="col-sm-6"><h6>&nbsp;&nbsp;' + el.lblEstatus + '</h6></div>'+
	  '</div>';

        Swal.fire({
          title:'Datos del Académico',
          width: 600,
          html:mensaje
        });
      }
      ,error:(error)=>{
        console.log(error);
      }
    });

  }
  abrirNotificaciones(el){
    sessionStorage.setItem('usrNot',JSON.stringify(el));
    sessionStorage.setItem('usrPerfNot',"academia");
    this.router.navigate(['/admin/notificaciones']).catch((error)=> console.log(error));
  }

}
