import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService } from '@shared/service/Account/account.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})

export class HeaderComponent  {

  @Input() MostrarBotonInicio: boolean;

  constructor(private routes : Router, private accountService: AccountService) {
    this.MostrarBotonInicio = true;
   }

  

  async login(){
    try{
      await this.routes.navigateByUrl('/validasesion');
    }catch(error)
    {
      console.log('HDRError',error);
    }
      
  }
}
