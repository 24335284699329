import { Component } from "@angular/core";

@Component({
  selector: "app-reportes",
  templateUrl: "./reportes.component.html",
  styleUrls: ["./reportes.component.css"],
})
export class ReportesComponent  {

  abrirReporte(IdReporte: number) {
    localStorage.setItem("reporte", "" + IdReporte);
    window.location.href="/admin/reporte/detalle";
  }
}
