<app-header></app-header>

<div class="container">
  <div class="hero-1 row d-flex">
    <img class="hero-der col-6 px-0 order-md-last " alt="" src="/assets/img/foto-1_2.png">
    <div class="hero-izq col-6 px-0 order-md-first ">
      <div class="hero-texto1"> Innovación, colaboración y aprendizaje basado en retos.</div>
      <div class="hero-texto2"> MPS Tec es la plataforma que vincula a la academia y a las organizaciones para resolver
        retos académicos que contribuyan a la formación de nuestros estudiantes.</div>

        <div class="d-block d-lg-none">

        <div class="col-12 vstack gap-3 mx-auto ">

          <span class="hero-subtitle d-flex justify-content-center">REGISTRO:</span>

          <a class="hero-cta d-flex justify-content-center" href="/sf/registro-sf"><button class="btn-amarillo">
              Organización</button></a>

          <a class="hero-cta d-flex justify-content-center" href="/acad/registro"><button class="btn-hard-blue">
              Academia</button></a>


        </div>
      </div>

    </div>

  </div>

  <div class="counters-container row offset-sm-1 col-sm-10">

    <div class="counters row row-cols-3 justify-content-evenly">
      <div class="counter col-md-2 col-12">
        <div class="row">
          <div class="col-4 col-md-12">
            <img class="" alt="" src="/assets/img/icono_retos.png">
          </div>
          <div class="col-8 col-md-12">
            <div class=" counter-numero">+3k</div>
            <div class=" counter-texto">Retos realizados</div>
          </div>
        </div>
      </div>
      <div class="counter col-md-2 col-12">
        <div class="row">
          <div class="col-4 col-md-12">
            <img alt="" src="/assets/img/icono_profesores.png">
          </div>
          <div class="col-8 col-md-12">
            <div class="counter-numero">+1.5k</div>
            <div class="counter-texto">Socios Formadores</div>
          </div>
        </div>
      </div>
      <div class="counter col-md-2 col-12">
        <div class="row">
          <div class="col-4 col-md-12">
            <img alt="" src="/assets/img/icono_alumnos.png">
          </div>
          <div class="col-8 col-md-12">
            <div class="counter-numero">+45k</div>
            <div class="counter-texto">Alumnos Impactados</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="info row">

    <div class="card">
      <img class="card-img" alt="" src="/assets/img/foto-home-socio-formador_1.png">
      <div class="card-texto">¿Qué es un Socio Formador?</div>
      <div class="card-descripcion"> Son organizaciones: agentes o entidades que pueden pertenecer al sector productivo,
        la sociedad civil, oficinas gubernamentales, instituciones o grupos comunitarios, con quienes el Tec de
        Monterrey establece una relación en donde sus estudiantes, de manera sistemática y apoyados por sus profesores,
        llevan a cabo el abordaje de retos de aprendizaje que les permiten el desarrollo de competencias en ambientes
        reales.
        <a class="d-flex justify-content-end d-none d-sm-inline-block" href="https://tec.mx/es/socios-formadores"
        referrerpolicy="noopener noreferrer" target="_blank">
        <button class="conoce-mas btn btn-link">Conoce más</button>
      </a>

      <a class="d-inline-block d-sm-none" href="https://tec.mx/es/socios-formadores">
        <button class="conoce-mas btn btn-link">Conoce más</button>
      </a>
    </div>

    </div>

    <div class="card">
      <img class="card-img" alt="" src="/assets/img/foto-home-reto-academico_1.png">
      <div class="card-texto">¿Qué es un Reto Académico?</div>
      <div class="card-descripcion">Un reto es una experiencia diseñada para exponer al alumno a una situación
        desafiante del entorno para
        lograr objetivos específicos de aprendizaje. Los retos contribuyen al desarrollo de competencias
        disciplinares y transversales de los alumnos, ya que en ellos aplican, de manera individual y colaborativa,
        sus conocimientos, habilidades, actitudes y valores.

        <a class="align-self-sm-end d-none d-sm-inline-block" href="https://tec.mx/es/modelo-tec21"
        referrerpolicy="noopener noreferrer" target="_blank">
        <button class="conoce-mas btn btn-link">Conoce Tec21</button>
      </a>

      <a class="d-inline-block d-sm-none" href="https://tec.mx/es/modelo-tec21">
        <button class="conoce-mas btn btn-link">Conoce Tec21</button>
      </a>
    </div>

    </div>


    <div class="card">
      <img class="card-img" alt="" src="/assets/img/foto-home-programas-academicos_1.png">
      <div class="card-texto">Programas Académicos Tec</div>
      <div class="card-descripcion">Conoce la oferta académica que cuenta en el Tec de Monterrey, basada en su modelo
        Tec21 enfocado en la resolución de retos y en los cuales como Socio Formador podrás participar.


        <a class="align-self-sm-end d-none d-sm-inline-block" href="https://tec.mx/es/oferta-educativa-profesional"
        referrerpolicy="noopener noreferrer" target="_blank">
        <button class="conoce-mas btn btn-link">Ver programas</button>
      </a>

      <a class="d-inline-block d-sm-none" href="https://tec.mx/es/oferta-educativa-profesional">
        <button class="conoce-mas btn btn-link">Ver programas</button>
      </a>

    </div>

    </div>

  </div>


  <div class="row m-ce-escuelas d-block d-sm-none">
    <div class="select-dropdown">
      <ng-select [items]="escuelasData" bindLabel="escuela" [(ngModel)]="ngEscuelaDefault" bindValue="idEscuela"
        (change)="mostrar($event)"
        placeholder="Selecciona una opción" ></ng-select>
    </div>
  </div>

  <div class="casos-exito row" app-casos-exito>
    <img class="ce-img-patron-8 d-none d-lg-block" alt="" src="/assets/img/Figura_8.png">
    <div class="ce-titulo">Retos realizados</div>
    <div class="ce-subtitulo d-none d-sm-block">Conoce alguno de los retos que han resuelto nuestros alumnos con las organizaciones</div>
    <div class="ce-categorias">

      <div class="col-sm-5 offset-sm-4 ce-escuelas d-none d-sm-block mb-5">
        <div class="select-dropdown"> Buscar por escuela:
          <ng-select [items]="escuelasData" bindLabel="escuela" bindValue="idEscuela" class="ce-selEscuela" [(ngModel)]="ngEscuelaDefault"
          (change)="mostrar($event)"
            placeholder="Selecciona una opción" ></ng-select>
        </div>
      </div>

      <div class="tab-content">
        <!--Inicia Tab -->
        <div class="tab-pane fade show active " id="tab_arquitectutra" role="tab_panel" aria-labelledby="eaad" >
          <!-- Carousel -->
          <div id="demo_eaad" class="carousel carousel-dark slide" data-bs-ride="carousel">



            <!-- The slideshow/carousel Cambiar a Dinamico-->
            <div class="carousel-inner">

              <div class="carousel-item" [ngClass]="{'active' : j == 0 }" *ngFor="let esc of escuelaSeleccionada.regs3; let j = index">
                <div class="card-wrapper">
                  <div class="ce-card" *ngFor="let el of esc">
                    <img class="ce-card-img" alt="" src={{el.urlImagenCasoExito}}>
                    <div class="ce-card-texto">{{el.nombreCasoExito}}</div>
                    <div class="ce-card-descripcion">@Campus{{el.campus}} - {{el.anoCasoExito}}<br>
                      {{el.escuela}}</div>
                    <a class="a-btn-amarillo"><button class="btn-amarillo" data-bs-toggle="modal"
                        data-bs-target="#modal" (click)="openModal(el)">Ver reto</button></a>
                  </div>
                </div>
              </div>

            </div>

            <!-- Left and right controls/icons -->
            <button class="carousel-control-prev" type="button" data-bs-target="#demo_eaad" data-bs-slide="prev">
              <span class="carousel-control-prev-icon"></span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#demo_eaad" data-bs-slide="next">
              <span class="carousel-control-next-icon"></span>
            </button>
          </div>

        </div>

        <!--Termina Tab Arquitectura-->

        <div *ngIf="showModalBox" class="modal" id="modal" tabindex="-1">
          <div class="modal-dialog modal-xl">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">{{detalle.nombreCasoExito}} | {{detalle.escuela}}</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <br /><br />
                <span>{{detalle.descripcionCasoExito}}</span>
                <br /><br />
                <p *ngIf="detalle.urlConectaCasoExito!=''">
                  Nota en Conecta:
                  <br />
                  <a href={{detalle.urlConectaCasoExito}} target="_blank" rel="noopener noreferrer">
                    {{detalle.urlConectaCasoExito}}
                  </a>

                </p>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>

  </div>

  <div id="carouselLogo" class="carousel slide" data-bs-ride="carousel">
    <div class="carousel-inner">
      <div class="carousel-item " *ngFor="let slide of slides">
        <img src="{{ slide.logoHome}}" class="d-block w-25" alt="...">
      </div>
    </div>
  </div>
  <div class="sociosFormadores row pb-5">
    <div class="sf-titulo mb-4">Algunos de nuestros Socios Formadores</div>

    <ngx-slick-carousel class="carousel pt-4" #slickModal="slick-carousel" [config]="slideConfig">
      <div ngxSlickItem *ngFor="let slide of slides" class="slide">
        <img src="{{ slide.logoHome }}" alt="" width="100%" class="mx-4">
      </div>
    </ngx-slick-carousel>

  </div>

  <app-footer></app-footer>


</div>
