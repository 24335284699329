import { Clipboard } from '@angular/cdk/clipboard';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import Utils from '@shared/helpers/utils';
import { UsuariosService } from '@shared/service/Usuarios/usuarios.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-card-retos',
  templateUrl: './card-retos.component.html',
  styleUrls: ['./card-retos.component.css'],
})
export class CardRetosComponent implements OnInit {
  @Input()
  objReto: any;

  campus: string="";
  clavePerfil: string;
  imgReto: string = "assets/img/img_reto_fullsize.jpg";
  MostrarBotonEditar: boolean = false;

  lbl_descripcion: string = '';
  lbl_semestres: string ='Semestres: ';
  lbl_semana: string='Duración: ';

  constructor(
    private _usuarioService: UsuariosService,
    private _router: Router,
    private toastr: ToastrService,
    private clipboard: Clipboard
  ) {}

  ngOnInit(): void {
      this.lbl_descripcion = Utils.cutLongTextByXChars(this.objReto.descripcion??'',180);
      
      this.imgReto = this.objReto.urlFotoReto||"assets/img/img_reto_fullsize.jpg";
      
      this.MostrarBotonEditar = this._usuarioService.getCurrentUserData().claveRoles.includes('disenador');

      this.lbl_semestres +=  this.objReto.semestres?(this.objReto.semestres.join(', ')):'';
      this.lbl_semana += this.objReto.semanas??'' + " ";

      this.clavePerfil = this._usuarioService.getCurrentUserData().clavePerfil;

  }

  verDetalleReto(idRetoMaestro: number){
    sessionStorage.setItem('idRetoMaestro',idRetoMaestro.toString());
    localStorage.setItem("contextoOrigen", "bancoRetos");
    this._router.navigateByUrl('/socio/pagina-retos');
  }

  editarReto(idRetoMaestro: number){
    sessionStorage.setItem('idRetoMaestro',idRetoMaestro.toString());
    this._router.navigateByUrl('/gestion-reto');
  }


  generarShareUrl(idReto) {
    Swal.fire({
      text: "¿Deseas copiar el link del Reto?",
      confirmButtonText: "Si!, Copiar link",
      cancelButtonText: "No, Cancelar",
    }).then((result) => {
      if (result.isConfirmed) { 
        let url = `${location.origin}/banco-retos/${btoa(
          idReto
        )}`;
        this.clipboard.copy(url);
        const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
            confirmButton: "btn btn-success",
            cancelButton: "btn btn-danger",
          },
          buttonsStyling: false,
        });

        swalWithBootstrapButtons.fire({
          title: "¡Link copiado!",
          timer: 1500,
          confirmButtonText: "¡Link copiado!",
        });
      }
    });
  }

  copiado() {
    this.toastr.success("Link copiado!");
  }


}
