import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UsuariosService } from '@shared/service/Usuarios/usuarios.service';
import { environment } from 'environments/environment';

import {CookieService} from 'ngx-cookie-service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm!: UntypedFormGroup;

  lang : string = 'es';

  constructor(
    private readonly fb: UntypedFormBuilder,
    private _router : Router,
    private usuarioService: UsuariosService,
    private cookieService : CookieService
  ) { }

  ngOnInit(): void {
    this.lang = localStorage.getItem('lang') ?? 'es';
    this.loginForm = this.initForm();
  }

  changeLang(lang:string){
    this.lang  = lang;
    localStorage.setItem('lang',lang);
    window.location.reload();
  }

  validaLogin(): void {
        // Validar con B2C
    if(environment.HabilitarB2C){
      this._router.navigate(["dashboard"]);
    }
    else{
      this._router.navigate(["dashboard1"]);
    }

  }

  initForm(): UntypedFormGroup{
    return this.fb.group({
      user: ['',[Validators.required, Validators.email]],
      pwd: ['',[Validators.required, Validators.minLength(3)]]
    });
  }
}
