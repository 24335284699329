<app-header-buscador [showSearchBar]="false"></app-header-buscador>

<app-btn-regresar></app-btn-regresar>
<div>
    <div>
        <div class="sec_ad">
            <app-title-show-more [showMoreObject]="{title: 'Gestión de Notificaciones'} "></app-title-show-more>
            <div class="notificacionesContainer">

                <div class="" *ngIf="lblPerfilUsuario">

                    <div class="my-4">
                        <div class="row">
                            <div class="title-option col-sm-2">Usuario:&nbsp;</div>
                            <div class="txt-option col-sm-10"> {{ lblnombreUsuario }}</div>
                        </div>
                        <div class="row">
                            <div class="title-option col-sm-2">Perfil:&nbsp;</div>
                            <div class="txt-option col-sm-10"> {{ lblPerfilUsuario }}</div>
                        </div>
                        <div class="row">
                            <div class="title-option col-xs-12 col-sm-2">Correo:&nbsp;</div>
                            <div class="txt-option col-xs-12 col-sm-10">{{ lblCorreo }}</div>
                        </div>
                        <div class="row">
                            <div class="title-option col-12 col-sm-2">{{ lblExtraTitulo }}&nbsp;</div>
                            <div class="txt-option col-12 col-sm-10">{{ lblExtra }}</div>
                        </div>
                    </div>
                    <div class="txt-option mb-5">
                        Estas son las notificaciones que tiene este usuario de acuerdo a su perfil:
                    </div>

                    <div class="row my-3">
                        <div class="row">

                            <div class="col-6 offset-sm-1 title-option">Tipo Notificación</div>
                            <div class="col-2 title-option">Estatus</div>
                            <div class="col-3 title-option">Acción</div>
                        </div>
                        <div class="row" *ngFor="let item of listadoNotificacionesUsuario">
                            <div class="col-6 offset-sm-1">{{ item.tipoNotificacion }}</div>
                            <div class="col-2">{{ item.estatus }}</div>
                            <div class="col-1">
                                <div class="# mb-3" *ngIf="item.claveEstatus.trim() =='activo'">
                                    <svg ngbTooltip="Inactivar"
                                        (click)="inactivarNotificacion(item.idUsuario, item.idTipoNotificacion)"
                                        xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#A8DF9D"
                                        class="bi bi-toggle-on" viewBox="0 0 16 16">
                                        <path
                                            d="M5 3a5 5 0 0 0 0 10h6a5 5 0 0 0 0-10H5zm6 9a4 4 0 1 1 0-8 4 4 0 0 1 0 8z" />
                                    </svg>

                                </div>
                                <!-- Activar -->
                                <div class="# mb-3" *ngIf="item.claveEstatus.trim() =='inactivo'">
                                    <svg ngbTooltip="Activar"
                                        (click)="activarNotificacion(item.idUsuario, item.idTipoNotificacion)"
                                        xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#F06067"
                                        class="bi bi-toggle-off" viewBox="0 0 16 16">
                                        <path
                                            d="M11 4a4 4 0 0 1 0 8H8a4.992 4.992 0 0 0 2-4 4.992 4.992 0 0 0-2-4h3zm-6 8a4 4 0 1 1 0-8 4 4 0 0 1 0 8zM0 8a5 5 0 0 0 5 5h6a5 5 0 0 0 0-10H5a5 5 0 0 0-5 5z" />
                                    </svg>

                                </div>
                            </div>

                        </div>

                    </div>



                </div>


            </div>
        </div>
    </div>
</div>
