<div class="row">

  <div *ngIf="idSocioFormador == 0" class="col text-container-contacts">Para administrar contactos, selecciona o registra un Socio Formador.</div>

  <div class="col" *ngIf="idSocioFormador>0">
    <div class="row">
      <div class="col-md-10">
        <app-card-contact
          [contactObj]="userData"
          [size]="'lg'"
          [showMoreDetailsButton]="mostrarBtnNuevo()"
          [showDeleteContactButton]="mostrarBtnNuevo()"
          (callbackDetails)="showMoreDetails($event)"
          (callbackDelete)="deleteContact($event)"
        ></app-card-contact>
      </div>
    </div>
    <div class="row">
      <div *ngIf="!contactsData.length" class="col text-container-contacts"> No hay Contactos Registrados</div>

      <div *ngIf="contactsData.length" class="col text-container-contacts">{{ contactsData.length }} Contactos de Empresa</div>
    </div>
    <div class="row">
      <div class="col-md-6 mb-5" *ngFor="let contact of contactsData">
        <app-card-contact
            [showMoreDetailsButton]="mostrarBtnNuevo()"
            [showDeleteContactButton]="mostrarBtnNuevo()"
            (callbackDelete)="deleteContact($event)"
            (callbackDetails)="showMoreDetails($event)"
            [contactObj]="contact"></app-card-contact>
      </div>
    </div>
    <div class="row" *ngIf="mostrarBtnNuevo()">
      <div class="col-md-6">
        <app-button (callbackAction)="newContact()">
          <svg
            width="13"
            height="13"
            viewBox="0 0 13 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13 7.3125H7.3125V13H5.6875V7.3125H0V5.6875H5.6875V0H7.3125V5.6875H13V7.3125Z"
              fill="#0C2B3E"
            />
          </svg>
          &nbsp;&nbsp;Añadir usuario
        </app-button>
      </div>
    </div>
  </div>
</div>
