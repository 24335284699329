import { Component } from '@angular/core';

@Component({
  selector: 'app-gestion-usuarios-sf',
  templateUrl: './gestion-usuarios-sf.component.html',
  styleUrls: ['./gestion-usuarios-sf.component.css'],
})
export class GestionUsuariosSfComponent  {
  titleGestion = {
    title: 'Gestión de Usuarios',
    button: 'Añadir usuario',
  };

  
}
