<app-header-buscador [showSearchBar]="false"></app-header-buscador>

<app-btn-regresar></app-btn-regresar>

<h1 class="mt-5">Reto: {{ nombreReto }}</h1>

<h3>Reto terminado, guarda su resultado.</h3>

<div class="row mt-5">
    <div class="col-12">
        <form [formGroup]="resultadoRetoForm" autocomplete="false">
            <div class="row">

                <div class="col-3 text-center">
                    <div>
                        <img class="img_avt_ds" alt="logotipo" src="{{ imgUrl }}" />
                    </div>
                    <div><br />Imagen .JPG o .PNG</div>
                    <small>Tamaño Máx: 1024x1024 < 3Mb</small><span class="text-danger">*</span>
                            <div class="editFoto fileUploadButton" (click)="file.click()"><br />Editar Foto</div>
                            <input class="hidden" type="file" (change)="selImagen($event)" accept="image/*" #file />

                </div>

                <div class="col-7">
                    <div class="col mt-2">
                        <label for="">Resultado del reto: <span class="text-danger">*</span></label>
                    </div>
                    <div class="text-danger"
                        *ngIf="resultadoRetoForm.get('resultado')?.touched && resultadoRetoForm.get('resultado')?.errors?.['required']">
                        Este campo es requerido
                    </div>
                    <div class="col">
                        <textarea formControlName="resultado" class="form-control" style="width:100%" rows="5"
                            placeholder="Escribe el resultado del reto obtenido"></textarea>
                    </div>

                    <div class="col mt-3">
                        <label for="">Liga de video, noticia o información de interés: <span
                                class="text-danger">*</span></label>
                        <div class="text-danger"
                            *ngIf="resultadoRetoForm.get('urlInformacionResultado')?.touched && resultadoRetoForm.get('urlInformacionResultado')?.errors?.['required']">
                            Este campo es requerido
                        </div>
                        <input type="text" formControlName="urlInformacionResultado" class="form-control" style="width:100%"
                            placeholder="https://www.noticia.com">
                    </div>

                    <div class="col mt-3 ">
                        <label for="">Marcar como caso de éxito</label>
                        <br />
                        <div class="text-danger"
                            *ngIf="resultadoRetoForm.get('reto_exitoso')?.touched && resultadoRetoForm.get('reto_exitoso')?.errors?.['required']">
                            Este campo es requerido
                        </div>
                        <div class="col-4">
                            <select class="form-control " formControlName="reto_exitoso">
                                <option value=true [selected]="resultadoRetoForm.get('reto_exitoso')?.value == true">Si</option>
                                <option value=false [selected]="resultadoRetoForm.get('reto_exitoso')?.value == false">No</option>
                            </select>
                        </div>
                        <div class="fs-6 fw-ligther fst-italic d-none">Si se selecciona como caso de éxito, ya no se podrá editar la información, se tiene que administrar dede la Gestión de Casos de éxito.</div>
                    </div>


                    <div class="col-3 mt-5">
                        <app-button buttonType="yell" (callbackAction)="guardarCasoExistoso()">Guardar</app-button>
                    </div>

                </div>

            </div>
        </form>
    </div>
</div>
