import { Component, Input, OnInit } from "@angular/core";
import { IPeriodoSemanal } from "@shared/Interface/ICatalogos.interface";
import { CatalogosService } from "@shared/service/Catalogos/catalogos.service";
import { RetosService } from "@shared/service/Retos/retos.service";
import { UsuariosService } from "@shared/service/Usuarios/usuarios.service";
import { ToastrService } from "ngx-toastr";
import { lastValueFrom } from "rxjs";
import Swal from "sweetalert2";

@Component({
  selector: "app-sobre-profesores",
  templateUrl: "./sobre-profesores.component.html",
  styleUrls: ["./sobre-profesores.component.css"],
})
export class SobreProfesoresComponent implements OnInit {
  @Input() lstprofe: any;
  @Input() retoDetalle: any;
  @Input() status: string;

  mostrarSeccionProfesores: boolean = false;
  periodosSemanalesData: IPeriodoSemanal[] = [];

  constructor(
    private retoService: RetosService,
    private toastr: ToastrService,
    private usuarioService: UsuariosService,
    private catalogosService: CatalogosService
  ) {}

  profesorCordinador: any;
  profesorImplementador: any;
  clavePerfil: string ="";

  ngOnInit(): void {
    this.clavePerfil = this.usuarioService.getCurrentUserData().clavePerfil;
    this.separarProfesores();
    this.getPeriodosSemanales();
    if(this.status !== 'formalizacion' || this.clavePerfil == 'socio'){
      this.mostrarSeccionProfesores = true;
    }
  }

  separarProfesores(){
    let profeEmpty = {
      idRetoProfesor: 0,
      idUsuario: 0,
      nombreUsuario: "-",
      apellidoUsuario: "-",
      correoUsuario: "-",
      rolAcademico: "-",
      idPeriodo: 0,
      esCoordinador: 0,
      periodoSemanal: "-",
      idReto: 0,
    };

    this.profesorCordinador =
      this.lstprofe.find((x) => x.esCoordinador) ?? profeEmpty;
    this.profesorImplementador =
      this.lstprofe.filter((x) => !x.esCoordinador) ?? profeEmpty;
  }

  async convertirCoordinador(profesor: any) {
    try {
      Swal.fire({
        text: "¿Deseas convertir este profesor en coordinador?",
        showCancelButton: true,
        cancelButtonText: "No",
        confirmButtonText: "Si",
      }).then(async (data) => {
        if (data.isConfirmed) {
          // Quitamos el coordinador actual
          let profC = this.profesorCordinador;
          if (profC.idUsuario != 0) {
            let request = {
              idRetoProfesor: profC.idRetoProfesor,
              idReto: profC.idReto,
              idUsuario: profC.idUsuario,
              esCoordinador: 0,
              idPeriodo: profC.idPeriodo
            };
            let rest = await lastValueFrom(
              this.retoService.putRetoProfesor(request)
            );
          }
          let request = {
            idRetoProfesor: profesor.idRetoProfesor,
            idReto: profesor.idReto,
            idUsuario: profesor.idUsuario,
            esCoordinador: 1,
            idPeriodo: profesor.idPeriodo
          };
          let rest = await lastValueFrom(
            this.retoService.putRetoProfesor(request)
          );

          this.lstprofe = await lastValueFrom(
            this.retoService.getAllRetoProfesor(this.retoDetalle.idReto)
          );

          this.separarProfesores();

          this.toastr.success("Guardado con éxito");
        }
      });
    } catch (err) {
      console.log(err);
    }
  }

  mostrarProfesores(){
    this.mostrarSeccionProfesores = !this.mostrarSeccionProfesores;
  }

  async getPeriodosSemanales(){
    try{
      this.periodosSemanalesData = await lastValueFrom(this.catalogosService.getPeriodoSemanal());

    }catch(err){ console.log(err);}
  }

  getPeriodoSemanalPorId(idPeriodo: number){
    return this.periodosSemanalesData.find(x => x.idPeriodoSemanal == idPeriodo)?.periodoSemanal ?? '-';
  }
}
