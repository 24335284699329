<app-header-buscador [showSearchBar]="false"></app-header-buscador>

<div class="sec_ad">
  <div>
    <img alt="Vinculación" class="img_hdr" src="assets/img/img_vinculacion_fullsize.jpg" />
  </div>
  <app-title-show-more [showMoreObject]="titleRegistroReto"></app-title-show-more>
  <div class="div-text-btn">
    <div class="question-div">
      Une a los Socios Formadores a nuevos desafíos para mostrar el potencial de
      las Unidades Formativas.
    </div>
  </div>

  <form [formGroup]="retoForm" (submit)="saveReto()" autocomplete="off">
    <div class="row questions-container open-text">
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-12 mb-5">
            <span class="question-title-text">Acerca del Reto</span>
          </div>

          <div class="col-md-12">
            <span class="question-text">¿Es este reto un reto Semilla?</span>
          </div>

          <div class="col col-md-12">
            <span>Si, es un reto semilla</span>
            &nbsp;
            <input type="checkbox" class="btn-check" id="semilla-si" formControlName="semilla"
              [checked]="this.retoForm.value.semilla" (change)="updateTitle()" />
            <label class="btn btn-outline-primary" for="semilla-si"></label>
          </div>

          <div class="col-md-12" *ngIf="!this.retoForm.value.semilla">
            <span class="question-text">Selecciona tu Reto Semilla <span class="requerido">*</span> </span>

            <div class="requerido" *ngIf="mostrarObligatorios && retoForm.get('idRetoMaestro')?.errors?.['required']">
              Este campo es requerido
            </div>

            <div class="select-dropdown">
              <ng-select id="idRetoMaestro" formControlName="idRetoMaestro" (change)="getRetoDetail($event)">
                <ng-option *ngFor="let reto of retosMaestrosData" [value]="reto.idRetoMaestro">
                  {{ reto.reto }}
                </ng-option>
              </ng-select>
            </div>
          </div>

          <div class="col-md-12">
            <span class="question-text">Selecciona tu Escuela <span class="requerido">*</span></span>

             <div class="requerido" *ngIf="mostrarObligatorios && retoForm.get('idEscuela')?.errors?.['required']">
              Este campo es requerido
            </div>

            <div class="select-dropdown">
              <select class="form-select" id="idEscuela" aria-label="Default select example" formControlName="idEscuela"
              (change)="updateProgramasAcademicos($event)">
                <option selected>Selecciona</option>
                <option *ngFor="let escuela of escuelaData" value="{{ escuela.idEscuela }}" selected="{{
                    verifySelected(escuela.idEscuela, 'idEscuela')
                  }}">
                  {{ escuela.escuela }}
                </option>
              </select>

            </div>
          </div>
          <div class="col-md-12">
            <span class="question-text">Selecciona tu Programa académico <span class="requerido">*</span> </span>

            <div class="requerido" *ngIf="mostrarObligatorios && retoForm.get('idProgramaAcademico')?.errors?.['required']">
              Este campo es requerido
            </div>


              <div class="select-dropdown">
                <select class="form-select" aria-label="Default select example" formControlName="idProgramaAcademico"
                >
                  <option selected="">Selecciona una opción</option>
                  <option *ngFor="let programa of programaAcademicoData" value="{{ programa.idProgramaAcademico }}"
                    selected="{{
                      verifySelected(
                        programa.idProgramaAcademico,
                        'idProgramaAcademico'
                      )
                    }}">
                    {{ programa.programaAcademico }}
                  </option>
                </select>
              </div>

          </div>
          <div class="col-md-12">
            <span class="question-text">Selecciona tu Unidad Formativa <span class="requerido">*</span> </span>

            <div class="requerido" *ngIf="mostrarObligatorios && retoForm.get('idUnidadFormativa')?.errors?.['required']">
              Este campo es requerido
            </div>

              <div class="select-dropdown">
                <select class="form-select" aria-label="Default select example" formControlName="idUnidadFormativa">
                  <option selected="">Selecciona una opción</option>
                  <option *ngFor="let unidad of unidadFormativaData" value="{{ unidad.idUnidadFormativa }}" selected="{{
                      verifySelected(unidad.idUnidadFormativa, 'campus')
                    }}">
                    {{ unidad.clave+'-'+unidad.unidadFormativa }}
                  </option>
                </select>
              </div>

          </div>
          <div class="col-md-12">
          <div class="row">
            <div class="col-md-12" *ngIf="!this.retoForm.value.semilla">
              <span class="question-text">Periodo Académico: <span class="requerido">*</span> </span>

              <div class="requerido" *ngIf="mostrarObligatorios && retoForm.get('idPeriodo')?.errors?.['required']">
                Este campo es requerido
              </div>

                <div class="select-dropdown">
                  <select class="form-select" aria-label="Default select example" formControlName="idPeriodo">
                    <option selected="">Selecciona una opción</option>
                    <option *ngFor="let periodo of periodoAcademicoData" value="{{ periodo.idPeriodo }}"
                    selected="{{ verifySelected(periodo.idPeriodo, 'idPeriodo')  }}">
                      {{ periodo.periodo }}    [{{ periodo.fechaInicio }} / {{periodo.fechaFin }}]
                    </option>
                  </select>
                </div>

            </div>
            <div class="col-md-6" *ngIf="this.retoForm.value.semilla">
              <span class="question-text">Duración del reto: <span class="requerido">*</span> </span>

              <div class="requerido" *ngIf="mostrarObligatorios && retoForm.get('idSemanas')?.errors?.['required']">
                Este campo es requerido
              </div>

                <div class="select-dropdown">
                  <select class="form-select" aria-label="Default select example" formControlName="idSemanas">
                    <option selected="">Selecciona una opción</option>
                    <option *ngFor="let semana of semanasData" value="{{ semana.idSemanas }}" selected="{{
                        verifySelected(semana.idSemanas, 'idSemanas')
                      }}">
                      {{ semana.semanas }}
                    </option>
                  </select>
                </div>

            </div>
          </div>
          <div class="row" *ngIf="!this.retoForm.value.semilla">
            <div class="col-md-6">
              <span class="question-text">Fecha de Inicio: <span class="requerido">*</span> </span>

              <div class="requerido" *ngIf="mostrarObligatorios && retoForm.get('fechaInicio')?.errors?.['required']">
                Este campo es requerido
              </div>

              <div class="input-group">
                <input class="form-control" placeholder="dd-mm-yyyy" name="fechaInicio" ngbDatepicker #d="ngbDatepicker"
                  (click)="d.toggle()" formControlName="fechaInicio" />
              </div>
            </div>
            <div class="col-md-6">
              <span class="question-text">Fecha de Término: <span class="requerido">*</span> </span>

              <div class="requerido" *ngIf="mostrarObligatorios && retoForm.get('fechaFin')?.errors?.['required']">
                Este campo es requerido
              </div>

              <div class="input-group">
                <input class="form-control" placeholder="dd-mm-yyyy" name="fechaFin" ngbDatepicker #d2="ngbDatepicker"
                  (click)="d2.toggle()" formControlName="fechaFin" />
              </div>
            </div>
          </div>
        </div>
          <div class="col-md-12">
            <span class="question-text">Idioma en que se imparte: <span class="requerido">*</span> </span>

            <div class="requerido" *ngIf="mostrarObligatorios && retoForm.get('idIdioma')?.errors?.['required']">
              Este campo es requerido
            </div>

              <div class="select-dropdown">
                <select class="form-select" aria-label="Default select example" formControlName="idIdioma">
                  <option selected="">Selecciona una opción</option>
                  <option *ngFor="let idioma of idiomasData" value="{{ idioma.idIdioma }}"
                    selected="{{ verifySelected(idioma.idIdioma, 'idIdioma') }}">
                    {{ idioma.idioma }}
                  </option>
                </select>
              </div>

          </div>
          <div class="col-md-12 mb-3">
            <span class="question-text">Semestre en el que se imparte el reto: <span class="requerido">*</span> </span>

            <div class="requerido" *ngIf="mostrarObligatorios && retoForm.get('semestre')?.errors?.['required']">
              Este campo es requerido
            </div>

            <div class="row">
              <div class="col icons-checkbox" *ngFor="let semestre of semestreData">
                <div class="icon-div"></div>
                <p>{{ semestre }}</p>
                <input type="radio" class="btn-check" id="semestre-{{ semestre }}" name="semestre"
                  value="{{ semestre }}" autocomplete="off" formControlName="semestre"
                  [checked]="verifyChecked(semestre, 'semestre')" />
                <label class="btn btn-outline-primary" for="semestre-{{ semestre }}"></label>
              </div>
            </div>
          </div>
          <div class="col-md-12 mt-4 mb-4">
            <span class="question-text">Alcance: <span class="requerido">*</span> </span>
          </div>

          <div class="requerido" *ngIf="mostrarObligatorios && retoForm.get('idAlcance')?.errors?.['required']">
            Este campo es requerido
          </div>

          <div class="col icons-checkbox" *ngFor="let alcance of alcanceData">
            <div class="icon-div">
              <img src="assets/svg/icon_{{ alcance.alcance | lowercase }}.svg" alt="icon-local" />
            </div>
            <p>{{ alcance.alcance }}</p>
            <input type="radio" class="btn-check" id="{{ alcance.alcance }}" name="idAlcance"
              value="{{ alcance.idAlcance }}" formControlName="idAlcance"
              [checked]="verifyChecked(alcance.idAlcance, 'idAlcance')" />
            <label class="btn btn-outline-primary" for="{{ alcance.alcance }}"></label>
          </div>

          <div class="col-md-12" *ngIf="!this.retoForm.value.semilla">
            <span class="question-text">Región: <span class="requerido">*</span> </span>

            <div class="requerido" *ngIf="mostrarObligatorios && retoForm.get('idRegion')?.errors?.['required']">
              Este campo es requerido
            </div>

              <div class="select-dropdown">
                <select class="form-select" aria-label="Default select example" formControlName="idRegion"
                  (change)="actualizarCampus($event)">
                  <option  value="" selected="">Selecciona una opción</option>
                  <option *ngFor="let region of regionesData" value="{{ region.idRegion }}"
                    selected="{{ verifySelected(region.idRegion, 'idRegion') }}">
                    {{ region.region }}
                  </option>
                </select>
              </div>

          </div>

          <div class="col-md-12" *ngIf="!this.retoForm.value.semilla">
            <span class="question-text">Campus donde se llevará el reto: <span class="requerido">*</span> </span>
          </div>

          <div class="requerido" *ngIf="mostrarObligatorios && !this.retoForm.value.semilla && retoForm.get('campus')?.errors?.['required']">
            Este campo es requerido
          </div>

          <div class="col-md-12" *ngIf="!this.retoForm.value.semilla">
            <span class="answer-title-text">Selecciona una o más opciones</span>
            <div class="row">
              <div class="col-md-12 answer-text">
                <div class="row">
                  <div class="col col-md-6" *ngFor="let ubicacion of campusData">
                    <input type="checkbox" class="btn-check" id="{{ ubicacion.idCampus }}-{{ ubicacion.campus }}"
                      autocomplete="off" formArrayName="campus" value="{{ ubicacion.idCampus }}"
                      (change)="onCheckboxChange($event, 'campus')"
                      [checked]="verifyChecked(ubicacion.idCampus, 'campus')" />
                    <label class="btn btn-outline-primary"
                      for="{{ ubicacion.idCampus }}-{{ ubicacion.campus }}"></label>
                    <span>{{ ubicacion.campus }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>


      <div class="col-md-6">
        <div class="row mb-5">
          <div class="col-md-12">
            <span class="question-title-text">Acerca de la Vinculación: </span>
          </div>
        </div>
        <div class="row">

          <div class="col-md-12">
            <span class="question-text">Nombre del reto: <span class="requerido">*</span> </span>

            <div class="requerido" *ngIf="mostrarObligatorios && retoForm.get('reto')?.errors?.['required']">
              Este campo es requerido
            </div>

            <app-text-input [formControl]="$any(retoForm.controls['reto'])" [label]="'reto'"
              [placeholder]="'Escribe el nombre de tu reto'"></app-text-input>
          </div>

        </div>
        <div class="row">

          <div class="col-md-12">
            <span class="question-text">Descripción del reto: <span class="requerido">*</span> </span>

            <div class="requerido" *ngIf="mostrarObligatorios && retoForm.get('descripcion')?.errors?.['required']">
              Este campo es requerido
            </div>

            <textarea class="forma_area" [formControl]="$any(retoForm.controls['descripcion'])"
              [placeholder]="'Una frase que describa el objetivo de tu reto'"></textarea>
          </div>

        </div>
        <div class="row">

          <div class="col-md-12">
            <span class="question-text">Entregable: <span class="requerido">*</span> </span>

            <div class="requerido" *ngIf="mostrarObligatorios && retoForm.get('entregable')?.errors?.['required']">
              Este campo es requerido
            </div>

            <textarea class="forma_area" id="entregable" placeholder="Describe los resultados a lograr en el reto"
              formControlName="entregable"></textarea>
          </div>

        </div>
        <div class="row mb-5">

          <div class="col-md-12">
            <span class="question-text">Sector: <span class="requerido">*</span> </span>
          </div>

          <div class="requerido" *ngIf="mostrarObligatorios && retoForm.get('idSector')?.errors?.['required']">
            Este campo es requerido
          </div>

          <div class="col icons-checkbox" *ngFor="let sector of sectoresData">
            <div class="icon-div mb-5">
              <img class="sector-image" src="assets/svg/ico_{{ cleanText(sector.sector) }}_ngo.svg"
                alt="icon-{{ sector.sector }}" />
            </div>
            <input type="radio" class="btn-check" id="{{ sector.sector }}" name="idSector" value="{{ sector.idSector }}"
              autocomplete="off" formControlName="idSector" [checked]="verifyChecked(sector.idSector, 'idSector')" />
            <label class="btn btn-outline-primary" for="{{ sector.sector }}"></label>
          </div>

        </div>

        <div class="row mb-3">
          <div class="col-md-12">
            <span class="question-text">Industria: <span class="requerido">*</span> </span>

            <div class="requerido" *ngIf="mostrarObligatorios && retoForm.get('idIndustria')?.errors?.['required']">
              Este campo es requerido
            </div>

              <div class="select-dropdown">
                <select class="form-select" aria-label="Default select example" formControlName="idIndustria">
                  <option selected="">Selecciona una opción</option>
                  <option *ngFor="let industria of industriasData" value="{{ industria.idIndustria }}" selected="{{
                      verifySelected(industria.idIndustria, 'idIndustria')
                    }}">
                    {{ industria.industria }}
                  </option>
                </select>
              </div>

          </div>
        </div>
        <div class="row mb-5">
          <div class="col-md-12">
            <span class="question-text">Tamaño de empresa: <span class="requerido">*</span> </span>

            <div class="requerido" *ngIf="mostrarObligatorios && retoForm.get('idTamano')?.errors?.['required']">
              Este campo es requerido
            </div>

              <div class="select-dropdown">
                <select class="form-select" aria-label="Default select example" formControlName="idTamano">
                  <option selected="">Selecciona una opción</option>
                  <option *ngFor="let tamano of tamanioEmpresasData" value="{{ tamano.idTamanoEmpresa }}" selected="{{
                      verifySelected(tamano.idTamanoEmpresa, 'idTamano')
                    }}">
                    {{ tamano.tamanoEmpresa }}
                  </option>
                </select>
              </div>

          </div>
        </div>
        <div class="row">

          <div class="col-md-12">
            <span class="question-text">¿Es una empresa familiar?</span>
          </div>
          <div class="col col-md-12">
            <span>Si</span>
            &nbsp;
            <input type="checkbox" class="btn-check" id="familiar-si" value="false" formControlName="empresaFamiliar" />
            <label class="btn btn-outline-primary" for="familiar-si"></label>
          </div>

        </div>
        <div class="row">

          <div class="col-md-12">
            <span class="question-text">Giro de la Empresa: <span class="requerido">*</span> </span>
          </div>

          <div class="requerido" *ngIf="mostrarObligatorios && retoForm.get('giros')?.errors?.['required']">
            Este campo es requerido
          </div>

          <div class="col-md-12 mb-3">
            <span class="answer-title-text">Selecciona una o más opciones</span>
            <div class="row">
              <div class="col-md-12 answer-text">
                <div class="row">
                  <div class="col col-md-6" *ngFor="let giro of giroData">
                    <input type="checkbox" class="btn-check" id="{{ giro.idGiro }}-{{ giro.giro }}" autocomplete="off"
                      formArrayName="giros" value="{{ giro.idGiro }}" (change)="onCheckboxChange($event, 'giros')"
                      [checked]="verifyChecked(giro.idGiro, 'giros')" />
                    <label class="btn btn-outline-primary" for="{{ giro.idGiro }}-{{ giro.giro }}"></label>
                    <span>{{ giro.giro }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div class="row mt-4">
          <div class="col">
            <app-button ngForm buttonType="yell" extraClass="btn-full" [isDisabled]="buttonSave.isLoading">
              {{ buttonSave.title }}</app-button>
          </div>
        </div>

      </div>
    </div>
  </form>
</div>
