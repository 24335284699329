<div class="form-group">
    <input type="{{type}}"
        [class.is-invalid]='ngControl.touched && ngControl.invalid'
        [formControl]="$any(ngControl.control)"
        class="form-control forma_linea" placeholder="{{placeholder}}"
      >
      <div *ngIf="ngControl.control?.errors?.required" class="invalid-feedback">{{ 'text_input.campo_requerido' | translate:{ campo: label} }}  </div>
        <div *ngIf="ngControl.control?.errors?.minlength" class="invalid-feedback">{{label}}  must be at least {{ngControl.control?.errors?.minlength['requiredLength']}} </div>
        <div *ngIf="ngControl.control?.errors?.maxlength" class="invalid-feedback">{{label}}  must be at most {{ngControl.control?.errors?.maxlength['requiredLength']}} </div>
      <div *ngIf="ngControl.control?.errors?.isMatching" class="invalid-feedback">Passwords do not match</div>
  
  </div>