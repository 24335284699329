import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { UsuariosService } from "@shared/service/Usuarios/usuarios.service";

@Component({
  selector: "app-routes",
  templateUrl: "./routes.component.html",
  styleUrls: ["./routes.component.css"],
})
export class RoutesComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private usuarioService: UsuariosService
  ) {}

  ngOnInit(): void {
    let cvePerfil = this.usuarioService.getCurrentUserData().clavePerfil;
    let urlPath = this.router.url ?? '';
    if (cvePerfil == "cvdp" || cvePerfil == "cvdp_admin") {
      this.router.navigate([`cvdp/${urlPath}`]);
    }
    if (cvePerfil == "academia") {
      this.router.navigate([`acad/${urlPath}`]);
    }
    if (cvePerfil == "socio") {
      this.router.navigate([`sf/${urlPath}`]);
    }
  }
}
