<p class="{{ class }}" *ngIf="!icon" title="{{ title }}">{{title_40}}</p>
<div *ngIf="icon" class="flex-fill" title="{{ title }}">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11"
    height="24"
    viewBox="0 0 11 24"
    fill="none"
    class="iCn replaced-svg"
  >
    <path
      d="M11 5.49073C11 2.46317 8.53303 0 5.50069 0C2.46789 0 0 2.46317 0 5.49073C0 8.31359 2.14547 10.6432 4.89422 10.9459V23.3946C4.89422 23.7287 5.16594 24 5.50069 24C5.83543 24 6.10715 23.7287 6.10715 23.3946V10.9459C8.85453 10.6436 11 8.31359 11 5.49073ZM7.05019 4.97147C6.2743 4.97147 5.64363 4.34189 5.64363 3.56779C5.64363 2.79369 6.2743 2.16365 7.05019 2.16365C7.82563 2.16365 8.4563 2.79369 8.4563 3.56779C8.4563 4.34189 7.82518 4.97147 7.05019 4.97147Z"
      fill="#93BCFD"
    ></path>
  </svg>
  {{ title_40 }}
</div>