import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ContactosService } from '@shared/service/Contactos/contactos.service';
import { UsuariosService } from '@shared/service/Usuarios/usuarios.service';
import { tap } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { InicioService } from '@shared/service/Inicio/inicio.service';
import { AzureBlobStorageService } from '@shared/service/AzureBlobStorage.Service';
import { SociosFormadoresService } from '@shared/service/Socios_Formadores/socios-formadores.service';

@Component({
  selector: 'app-contactos-tab',
  templateUrl: './contactos-tab.component.html',
  styleUrls: ['./contactos-tab.component.css'],
})
export class ContactosTabComponent  {
  @Input() contactsData: any;
  @Input() userData: any;
  @Input() idSocioFormador: number;
  @Output() callbackContacts: EventEmitter<any> = new EventEmitter<any>();
  @Output() callbackNewContact: EventEmitter<any> = new EventEmitter<any>();
  @Output() callbackDeletedContact: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private router: Router,
    private contactosService: ContactosService,
    private usuariosService: UsuariosService,
    private toastr: ToastrService,
    private inicio: InicioService,
    private azureBlobStorage: AzureBlobStorageService,
    private sociosFormadores: SociosFormadoresService
  ) {}


  showMoreDetails(objContact: any) {
    if (objContact.contactoPrincipal && this.usuariosService.getCurrentUserData().contactoPrincipal) {
      this.inicio.changeSubMenuItem('miperfil');
    } else {
      this.callbackContacts.emit(objContact);
    }
  }

  deleteContact(objContact: any) {
    Swal.fire({
      title: '¿Desea eliminar el contacto?',
      text: '',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Borrar',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        this.contactosService
        .deleteContact(this.idSocioFormador, objContact.idUsuario)
        .pipe(
          tap((resultado: any) => {
            if (resultado) {
              Swal.fire('Contacto.', resultado['#result-set-1'][0].status + ' - ' + resultado['#result-set-1'][0].mensaje, 'success');
              this.callbackDeletedContact.emit();
            } else {
              this.toastr.error('Algo Salió mal!');
            }
          })
        )
        .subscribe();


      }
    });

  }

  newContact() {
    this.callbackNewContact.emit();
  }

  mostrarBtnNuevo(){
    const idSF_academico = Number(sessionStorage.getItem("idSocioFormador_academico"));
    const cvePerfil = this.usuariosService.getCurrentUserData().clavePerfil;

    if(cvePerfil){
      if(cvePerfil != 'academia'){
        return true;
      }

      if( cvePerfil == 'academia' && this.idSocioFormador == idSF_academico){
        return true;
      }
      else {
        return false;
      }
    }
    else{
      return false;
    }
  }

}
