<app-header-buscador [showSearchBar]="false"></app-header-buscador>

<div id="sec_tit">
    <div class="d-flex align-items-center">
        <h3>Problemática Registrada</h3>
    </div>
    <div><button class="dashboard_btn d-md-block" (click)="regresar()"><svg xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px"
                viewBox="0 0 13.6 13.6" style="enable-background:new 0 0 13.6 13.6;" xml:space="preserve"
                class="iCn replaced-svg">
                <style type="text/css">
                    .st0 {
                        fill: #4A6BB6;
                    }
                </style>
                <path class="st0"
                    d="M5.8,13.1L1,8C0.7,7.7,0.5,7.2,0.5,6.7c0-0.5,0.2-1,0.5-1.3l4.8-5.1l1.1,1.1L2.1,6.5C2,6.6,2,6.6,2,6.7  s0,0.1,0.1,0.2L6.9,12L5.8,13.1z">
                </path>
            </svg><span>Regresar</span></button></div>
    <div class="d-flex align-items-center">
        <span class="titsans">Socio Formador que Solicita:&nbsp;</span>
    </div>
    <div class="row">
        <div class="col row_spacer">
            <div class="row">
                <div class="col-20p atomic_spc">
                    <img alt="Foto" class="img_avt_mn" src="{{dataUser.urlFotoUsuario}}">
                </div>
                <div class="col-80p atomic_spc">
                    <span class="titsans_contact_b"><strong>{{dataUser.nombreUsuario}}</strong></span><br>
                    <span class="subtgris">BBVA</span>

                </div>

            </div>
        </div>
        <div class="col">
        </div>
    </div>


    <div class="row" *ngIf="problematica.claveEstatus==='registrado'">
        <div class="col bkgatomic_alert_a">
            <img alt="Revisión" class="imgpchip_med" src="assets/img/icon_alert_porterminar.svg">&nbsp;<span class="titatomic_r"><strong>Tu
                    solicitud se encuentra en revisión</strong></span>
        </div>
    </div>

    <div class="row" *ngIf="problematica.claveEstatus==='revision'">
        <div class="col bkgatomic_alert_a">
            <img alt="Revisión" class="imgpchip_med" src="assets/img/icon_alert_porterminar.svg">&nbsp;<span class="titatomic_r"><strong>Tu
                    solicitud se encuentra en revisión</strong></span>
        </div>
    </div>

    <div class="row" *ngIf="problematica.claveEstatus==='cancelado'">
        <div class="col bkgatomic_alert_r">
            <img alt="Cancelada" class="imgpchip_med" src="assets/img/icon_alert_porterminar.svg">&nbsp;<span class="titatomic_r"><strong>Tu
                    solicitud se encuentra cancelada</strong></span>
        </div>
    </div>

    <div class="row" *ngIf="problematica.claveEstatus==='rechazado'">
        <div class="col bkgatomic_alert_r">
            <img alt="Rechazada" class="imgpchip_med" src="assets/img/icon_alert_porterminar.svg">&nbsp;<span class="titatomic_r"><strong>Tu
                    solicitud se encuentra rechazada</strong></span>
        </div>
    </div>

    <div class="row">
        <div class="col bkgatomic_alert_w">
            <div class="row">
                <div class="col data_spacer">
                    <strong>Descripción del tipo de problemática que busca:</strong><br><br>
                {{problematicaDesc}}

                </div>
            </div>
            <div class="row">
                <div class="col data_spacer">
                    <br>
                    <strong>Áreas de la Empresa/Organización involucradas:</strong><br><br>
                    {{areas}}
                </div>
            </div>
            <div class="row">
                <div class="col data_spacer">
                    <br>
                    <strong>Problemática que le interesa resolver:</strong><br><br>
                    {{objetivos}}
                    <br>

                </div>
            </div>
            <div class="row">
                <div class="col data_spacer">
                    <br>
                    <strong>Resultados que busca obtener:</strong><br><br>

                    {{entregables}}



                </div>
            </div>

            <div class="row" *ngIf="mostrarBotonCancelarSolicitud()">


                <div class="col-md-3 align-self-end">
                    <span class="#"><button class="pink_button" (click)="cancelarSolicitud()"><svg xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px"
                                viewBox="0 0 32 32" style="enable-background:new 0 0 32 32;" xml:space="preserve"
                                class="iCn replaced-svg">
                                <style type="text/css">
                                    .st0 {
                                        fill: #0C2B3E;
                                    }
                                </style>
                                <polygon class="st0"
                                    points="32,2.8 29.2,0 16,13.2 2.8,0 0,2.8 13.2,16 0,29.2 2.8,32 16,18.8 29.2,32 32,29.2 18.8,16 ">
                                </polygon>
                            </svg>Cancelar Solicitud</button></span><br>
                </div>

            </div>

        </div>



    </div>




</div>
