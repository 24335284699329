<input
  type="{{ type }}"
  class="btn-check"
  id="{{ label }}-{{ value }}"
  autocomplete="off"
  formArrayName="{{ formArrayName }}"
  value="{{ value }}"
  (change)="onInputChange($event)"
  [formControl]="$any(ngControl.control)"
/>
<label class="btn btn-outline-primary" for="{{ label }}"></label>
<span>{{ label }}</span>
