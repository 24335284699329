
<app-sidebar></app-sidebar>


<div id="cuerpo" class="d-flex">
  <div class="content">
    <div class="sec_ad">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

<app-footer-large></app-footer-large>
