<app-title-show-more  *ngIf="false"
[showMoreObject]="title"
></app-title-show-more>

<div class="flex-fill" *ngIf="false">
    <div class="d-flex justify-content-end">
        <div class="btn-group order-by">
            <button class="btn dropdown-toggle" type="button" id="listado" data-bs-toggle="dropdown"
                data-bs-auto-close="true" aria-expanded="false" data-bs-offset="0,0">
                {{textOrdenarPor | translate }}
            </button>
            <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="listado">
                <!-- ajustar a parametros que recibe la api orden -> "nombre/campus"   -->
                <li><a class="dropdown-item" [class.active]="ordenamientoBy=='nombre'"
                        (click)="orderBy('nombre')">{{'gestion_usuarios.nombre_az' | translate }}</a></li>
            </ul>
        </div>
    </div>
</div>

<div id="c_sf" class="sec_cn tipo-10 mt-4"> 
        <div class="">
            <div class="cont_tit d-flex">
                <div class="flex-fill col-1">Fecha Registro</div>
                <div class="flex-fill col-3">Nombre <br/> Puesto / Area</div>
                <div class="flex-fill col-3">Socio Formador <br/> RFC <br/> Campus</div>
                <div class="flex-fill col-2">Correo</div>
                <div class="flex-fill col-2">Teléfono</div>
                <div class="flex-fill col-1">Acciones</div>
            </div>
            <div  class="roundedsquare cont_fl d-flex align-items-center" *ngFor="let el of usuariosList" >
                <div class="flex-fill col-1"> {{ el.fechaRegistro | date: 'dd/MMM/yyyy'}}</div>
                <div class="flex-fill col-3 d-flex align-items-center">
                    <div><div><div>{{el.nombre }} {{ el.apellidos }}<br/> {{el.puesto }} / {{ obtenerArea(el.area) }}</div></div></div>
                </div>
                <div class="flex-fill col-3">
                    {{el.nombreSocioFormador || 'Socio Formador'}} 
                    <br> {{ el.rfcSocioFormador || 'XXXX000000'}}
                    <br> {{ el.campusConvenio || 'CampusConvenio'}}
                </div>
                <div class="flex-fill col-2">{{el.email}}</div>
                <div class="flex-fill col-2">Oficina: {{el.telefonoOficina}} <br/> Móvil: {{ el.telefonoMovil }}</div>
                <div class="flex-fill col-1">
                    <span><button class="lst_bt_edt" (click)="AprobraContacto(el.idUsuario)"></button></span>
                    <span><button class="lst_bt_brr" (click)="eliminarUsuario(el.idUsuario)"></button></span>
                </div>
            </div>
        

        <div *ngIf="usuariosList.length === 0">
            <app-sin-datos-table></app-sin-datos-table>
        </div>

    </div>

    <app-pagination *ngIf="usuarios.length" [objPagination]="usuarios" (paginationDataChange)="showPaginationData($event)"></app-pagination>



    <div class="d-block d-md-none">
        <div class="accordion" id="ac_1">
            <div *ngFor="let el of elementsTo" class="accordion-item">
                <h2 class="accordion-header" id="flush-heading{{el.id}}">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse"
                        [attr.data-bs-target]="'#flush-collapse'+el.id" aria-expanded="true">
                        Socio Formador
                    </button>
                </h2>
                <div id="flush-collapse{{el.id}}" class="accordion-collapse collapse show" data-bs-parent="#ac_1">
                    <div *ngFor="let obj of el.usuarios" class="accordion-body">
                        <div><img class="img_usr" alt="Logotipo" src="assets/img/avatar_sin.jpg"></div>
                        <div><a href="">{{obj.nombre}} {{obj.apellidos}}</a><br /></div>
                        <div class="d-flex align-items-center justify-content-center">
                            <button class="mb_btn_edt"><img class="tamgu" alt="Editar" src="assets/img/icon_editar.svg">
                                {{'gestion_usuarios.editar_usuario' | translate }}</button>
                            <button class="mb_btn_brr"><img class="tamgu d-none" alt="Eliminar" src="assets/img/icon_eliminar.svg">
                                {{'gestion_usuarios.eliminar_usuario' | translate }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-center" *ngIf="paginaActualMasResultados < numeroPaginasTo">
            <button class="mb_mas" (click)="obtenerMasResultados()"><img alt="Más resultados" class="tamgu"
                    src="assets/img/icon_mas_bullet.svg"> {{'gestion_usuarios.cargar_mas_resultados' | translate
                }}</button>
        </div>
        <div class="d-block d-md-none mt-3">
            <button class="blue_button float-end"><img class="tamgu" alt="Más resultados" src="assets/img/icon_mas.svg">
                {{'gestion_usuarios.añadir_usuario' | translate }}</button>
        </div>

        <div *ngIf="elementsTo.length === 0">
            <app-sin-datos-table></app-sin-datos-table>
        </div>

    </div>
</div>