import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-message-type',
  templateUrl: './message-type.component.html',
  styleUrls: ['./message-type.component.css']
})
export class MessageTypeComponent {

  @Input() title: string = '';
  @Input() icon: boolean = false;

  @Input() button: any;
  @Output() callbackAction: EventEmitter<any> = new EventEmitter<any>();

  handleClick(){
    console.log(this.button);
    this.button.callbackAction(this.button.objParams);
  }

}
