import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { IArea } from '@shared/Interface/ICatalogos.interface';
import { IContacto } from '@shared/Interface/IContacto.interface';
import { ISocioFormadorResponse } from '@shared/Interface/ISocioFormadorResponse';
import { Column, optionsTextCol } from '@shared/components/table/table.component';
import { CatalogosService } from '@shared/service/Catalogos/catalogos.service';
import { ContactosService } from '@shared/service/Contactos/contactos.service';
import { SociosFormadoresService } from '@shared/service/Socios_Formadores/socios-formadores.service';
import { ToastrService } from 'ngx-toastr';
import { lastValueFrom, tap } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-gestion-contactos',
  templateUrl: './gestion-contactos.component.html',
  styleUrls: ['./gestion-contactos.component.css']
})
export class GestionContactosComponent implements OnInit {

  socioFormador = new FormControl('');
  contactosData: IContacto[]=[];
  catalogoAreas: IArea[] = [];
  sociosFormadoresData: ISocioFormadorResponse[]=[];
  socioFormadorSeleccionado: ISocioFormadorResponse;
  SF_sector = "";
  SF_industria = "";
  SF_tamano = "";
  SF_presencia = "";
  SF_empresa = "";
  SF_razon = "";
  showAddContactButton = false;

  constructor(
    private sociosFormadoresService: SociosFormadoresService,
    private contactosService: ContactosService,
    private catalogosService: CatalogosService,
    private toastr: ToastrService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.loadData();
  }

  async loadData(){
    try{
      let keySearch: string = "";
      let sectorID: number = 0;
      let industriaId: number = 0;
      let claveEstado: string = "";
      let tamanoId: number = 0;
      let status:string = '';
      let campus:number = 0;
      let conConvenio:boolean = false;

      this.sociosFormadoresData = await lastValueFrom(
        this.sociosFormadoresService.getAllSociosFormadores(
          keySearch,
          sectorID,
          industriaId,
          claveEstado,
          tamanoId,
          status,
          campus,
          conConvenio
        ));
        this.sociosFormadoresData.sort((a,b)=>
                (a.nombreSocioFormador < b.nombreSocioFormador)
                ? -1
                : 0
              );

        this.catalogoAreas = await lastValueFrom(this.catalogosService.getAreas());

          let sfs = sessionStorage.getItem('gu-c-sfseleccionado');
          if(sfs){
            this.socioFormadorSeleccionado = JSON.parse(sfs??'');
            this.socioFormador.patchValue(this.socioFormadorSeleccionado.idSocioFormador.toString());
            this.getDatosSocioformador(this.socioFormadorSeleccionado.idSocioFormador);
            this.getContactos();
            sessionStorage.removeItem('gu-c-sfseleccionado')
          }



      }
      catch(err){
        console.log(err);
      }
  }

  obtenerArea(idArea){
    let nArea = this.catalogoAreas.filter(x => x.idArea == idArea).pop();
    if(!nArea){
      return idArea;
    }
    return nArea.area;
  }

  getDatosSocioformador(event) {
    this.contactosData=[];
    if (this.sociosFormadoresData) {
      this.socioFormadorSeleccionado = this.sociosFormadoresData.filter(
        (x) => x.idSocioFormador == event
      )[0];
      this.SF_razon = this.socioFormadorSeleccionado.razonSocial;
      this.SF_empresa = this.socioFormadorSeleccionado.empresa;
      this.SF_industria = this.socioFormadorSeleccionado.industria ?? "Sin Información";
      this.SF_presencia =
        this.socioFormadorSeleccionado.presenciaC ?? "Sin Información";
      this.SF_sector = this.socioFormadorSeleccionado.sector ?? "Sin Información";
      this.SF_tamano = this.socioFormadorSeleccionado.tamano ?? "Sin Información";
    }

    this.getContactos();
  }

  async getContactos(){
    try{
      this.contactosData = [];
      if(!this.socioFormadorSeleccionado){
        this.toastr.error("Seleccione un Socio Formador");
        return;
      }
      let soloPrincipal: boolean = false;
      this.contactosData = await lastValueFrom(this.contactosService.getContactos(this.socioFormadorSeleccionado.idSocioFormador,soloPrincipal));
      this.showAddContactButton = true;
    }catch(err){
      this.showAddContactButton = true;
      console.log(err);
    }
  }


  editar(idContacto){
    let idSocio = this.socioFormadorSeleccionado.idSocioFormador;
    sessionStorage.setItem('gu-c-sfseleccionado',JSON.stringify(this.socioFormadorSeleccionado));
    this.router.navigate([`edit-user/socio/${idSocio}/${idContacto}`]);

  }

  eliminar(idUsuario){
    Swal.fire({
      title:'Eliminar Contacto',
      text:' ¿Desea eliminar el contacto? ',
      icon: 'warning',
      showCancelButton: true
    }).then(async (res) => {
      if(res.isConfirmed){
        try{
          await lastValueFrom(this.contactosService.deleteContact(this.socioFormadorSeleccionado.idSocioFormador, idUsuario));
          this.toastr.success("Eliminado Correctamente!");
          this.getContactos();

        }catch(err)
        {
          console.log(err);
          this.toastr.error("Error, por favor intente más tarde");
        }
      }
    });
  }
  editContact = false;
  newContactCreate = false;
  editContactData: any;

  agregar(){
    sessionStorage.setItem("idSocioFormador_banco",this.socioFormadorSeleccionado.idSocioFormador.toString());
      this.editContact = false;
      this.newContactCreate = true;
  }
  cancelAndshowContacts(){
    this.newContactCreate = false;
    this.getContactos();
  }
}
