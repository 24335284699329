import { Injectable } from "@angular/core";
import { Paginador } from "@shared/Interface/IPaginador.interface";

@Injectable({
  providedIn: 'root'
})

export class PaginadorLibsComponent {

  

  /**
   * Método que calcula el número de paginas a mostrar en una tabla
   * @param longitudLista tipo de dato number que identiica el tamaño de la lista
   * @param registrosPagina tipo de dato number que identifica el número de registros por vista
   * @returns Objeto tipo Paginador (total de paginas y el número de las paginas para crear paginador)
   */
  getPaginacion(longitudLista: number, registrosPagina:number): Paginador{
    let pages: number [] = [];
    let totalPaginas = Math.ceil(longitudLista / registrosPagina);
    if (totalPaginas > 0) {
      for (let index = 1; index <= totalPaginas; index++) {
        pages.push(index);
      }
    }
    let paginador: Paginador = {
      paginas: pages,
      totalPaginas: totalPaginas
    };

    return paginador;
  }

  /**
   * Método que obtiene los registros actuales de una lista para mostrar en tabla paginada
   * @param numPagina tipo de dato number que identifica el número de la pagina actual
   * @param registrosPagina tipo de dato number que identifica el número de registros por pagina
   * @param lista Tipo de dato any[] que identifica a la lista a la que se le aplicara la paginación
   * @returns objeto any[] con la lista filtrada por paginación
   */
  obtenerRegistrosActuales(numPagina:number, registrosPagina:number, lista:any[]): any[] {
    let retosActualesSFList: any[] = [];
    let offset = (numPagina - 1) * registrosPagina;
    retosActualesSFList = lista.slice(offset, offset + registrosPagina);
    return retosActualesSFList;
  }


  /**
   * Método que obtiene más resultados de una lista para mostrar en tabla responsivas moviles paginada
   * @param numPagina tipo de dato number que identifica el número de la pagina actual
   * @param registrosPagina tipo de dato number que identifica el número de registros por pagina
   * @param lista Tipo de dato any[] que identifica a la lista a la que se le aplicara la paginación
   * @returns objeto any[] con la lista filtrada por paginación
   */
  obtenerMasResultados(numPagina:number, registrosPagina:number, lista:any[]){
    let retosActualesSFList: any[] = [];
    let offset = (numPagina - 1) * registrosPagina;
    retosActualesSFList = lista.slice(0, offset + registrosPagina);
    return retosActualesSFList;
  }

}
