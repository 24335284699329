import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import Utils from "@shared/helpers/utils";
import { ICartaConvenio } from "@shared/Interface/ICartaConvenio";
import { ICartaColaboracion } from "@shared/Interface/IReto.interface";
import { SociosFormadoresService } from "@shared/service/Socios_Formadores/socios-formadores.service";
import { lastValueFrom } from "rxjs";

@Component({
  selector: "app-convenio-viewer",
  templateUrl: "./convenio-viewer.component.html",
  styleUrls: ["./convenio-viewer.component.css"],
})
export class ConvenioViewerComponent implements OnInit {
  @Input()
  IdSocioFormador: number;

  @Input()
  showFilePDF: string;

  constructor(
    private sociosFormadoresService: SociosFormadoresService
  ) {}

  @Output()
  filePdfEmitter = new EventEmitter<ICartaConvenio >();
  filePdfEmitterCol = new EventEmitter<ICartaColaboracion>();
  filepdfraw: string;
  ngOnInit(): void {
    if (this.showFilePDF) {
      this.filepdfraw = this.showFilePDF;
    }
    if (this.IdSocioFormador != 0) {
      this.obtenerUltimoConvenio();
    }
  }

  ngOnChanges(){
    this.filepdfraw = this.showFilePDF;
  }

  async obtenerUltimoConvenio() {
    try {
      let res: ICartaConvenio[] = await lastValueFrom(
        this.sociosFormadoresService.getCartaConvenio(this.IdSocioFormador)
      );
      if (res) {
        res = res.filter((x) => (x.paso1Firma == false || x.paso2Firma == false));
        let convenio = res.pop();
        if (convenio != undefined) {
          this.filepdfraw = convenio.urlCartaConvenio;
          this.filePdfEmitter.emit(convenio);
        }
      }
    } catch (error:any) {
      Utils.processErrorResponse(error);
    }
  }
}
