<div class="row body_color mb-3 roundedsquare" >
  <div class="col-md-offset-1 col-md-2">
    <div class="user-header-img" *ngIf="userData">
      <app-icon-user [userimg]="userData.urlFotoUsuario"></app-icon-user>
    </div>

    &nbsp;<app-button-user
      *ngFor="let button of buttons; let i = index"
      (callbackAction)="onClickButton(button.url)"
      [buttons]="button"
    ></app-button-user>
  </div>
  <div class="col-md-10">
    <div class="container-fluid">
      <div class="row">
        <div class="col-100p">
          <h2 class="dbd_title">¡Hola, {{ userData.nombreUsuario }}!</h2>
        </div>
      </div>
      <div class="row">
        <app-indicator-user [indicators]="indicators"></app-indicator-user>
      </div>
    </div>
  </div>
</div>
