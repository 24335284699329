import { Component } from '@angular/core';
import {
  Column,
  Data,
  optionsTextCol,
} from '@shared/components/table/table.component';

@Component({
  selector: 'app-socios-tab',
  templateUrl: './socios-tab.component.html',
  styleUrls: ['./socios-tab.component.css'],
})
export class SociosTabComponent  {
  backgroundTable = '#F6F6F6';

  solicitudesColumns: Column[] = [
    {
      title: 'Socio Formador',
      sizeCol: '4',
      classType: 'dashbd_rows_img justify-content-md-center',
      typeColumn: optionsTextCol.link,
      key: 'link',
    },
    {
      title: 'Contacto y rol',
      sizeCol: '4',
      classType: 'dashbd_rows_img justify-content-md-center',
      typeColumn: optionsTextCol.imageTitle,
      icon: true,
      key: 'imageTitle',
    },
    {
      title: 'Campus de Atencion',
      sizeCol: '2',
      classType: 'dashbd_rows_img justify-content-md-center',
      typeColumn: optionsTextCol.text,
      key: 'campus',
    },
    {
      title: 'Acciones',
      sizeCol: '',
      classType: 'dashbd_rows_img justify-content-md-center',
      typeColumn: optionsTextCol.buttons,
      key: 'buttons',
    },
  ];

  solicitudesData: Data[] = [
    {
      id: 0,
      link: {
        title: 'Corporativo Grupo Torres Corzo',
      },
      imageTitle: {
        title: 'Marcela Valencia Mendoza',
        link: '#a',
        subtitle: 'Persona de enlace',
        image: 'avatar_5.jpg',
      },
      campus: 'Santa Fe',
      buttons: [
        {
          class: 'lst_bt_edt',
        },
      ],
    },
    {
      id: 1,
      link: {
        title: 'Embotelladora AGA del Centro',
      },
      imageTitle: {
        title: 'Patricia Fernández Miraflores',
        link: '#a',
        subtitle: 'Persona de enlace',
        image: 'avatar_4.jpg',
      },
      campus: 'Monterrey',
      buttons: [
        {
          class: 'lst_bt_edt',
        },
      ],
    },
    {
      id: 2,
      link: {
        title: 'Fábrica de Chocolate La Frontera',
      },
      imageTitle: {
        title: 'Hugo Lombardi Mayagoitia',
        link: '#a',
        subtitle: 'Persona de enlace',
        image: 'avatar_2.jpg',
      },
      campus: 'Santa Fe',
      buttons: [
        {
          class: 'lst_bt_edt',
        },
      ],
    },
  ];

  
}
