<app-header-buscador lbl_placeholder="Buscar un Reto" (fatherNameFunction)="buscadorPress($event)"></app-header-buscador>
<div class="sec_ad">

  <app-title-show-more [showMoreObject]="titleBancoRetos" ></app-title-show-more>

  <div class="row">

    <!-- Filtro de escuela -->
    <div class="col-md">
      <div class="btn-group order-by">
        <button
          class="btn dropdown-toggle"
          type="button"
          id="listado"
          data-bs-toggle="dropdown"
          data-bs-auto-close="true"
          aria-expanded="false"
          data-bs-offset="0,0"
          title="{{ filter.escuela ? getValueCatalogo(escuelasData, 'idEscuela', filter.escuela, 'escuela') : 'Escuela' }}"
        >
        {{ filter.escuela ? getValueCatalogo(escuelasData, 'idEscuela', filter.escuela, 'escuela') : 'Escuela' }}
        </button>
        <ul class="dropdown-menu dropdown-menu-start" aria-labelledby="listado">
          <li *ngFor="let escuela of escuelasData; let i = index">
            <a
              class="dropdown-item {{ i === 0 ? 'active' : '' }}"
              (click)="addFilter('escuela', escuela.idEscuela)"
              >{{ escuela.escuela }}</a
            >
          </li>
        </ul>
      </div>
    </div>

    <!-- Filtro de Departamento escuela -->
    <div class="col-md" >
      <div class="btn-group order-by">
        <button
          class="btn dropdown-toggle"
          type="button"
          id="listado"
          data-bs-toggle="dropdown"
          data-bs-auto-close="true"
          aria-expanded="false"
          data-bs-offset="0,0"
          title="{{ filter.departamento ? getValueCatalogo(departamentosEscuelaData, 'idDepartamento', filter.departamento, 'departamento') : 'Departamento' }}"
        >
        {{ filter.departamento ? getValueCatalogo(departamentosEscuelaData, 'idDepartamento', filter.departamento, 'departamento') : 'Departamento' }}
        </button>
        <ul
          class="dropdown-menu dropdown-menu-start"
          aria-labelledby="listado"
        >
          <li *ngIf="programasAcademicosData.length == 0" class="dropdown-item">Selecciona una escuela</li>
          <li *ngFor="let departamento of departamentosEscuelaData; let i = index">
            <a
              class="dropdown-item {{ i === 0 ? 'active' : '' }}"
              (click)="addFilter('departamento', departamento.idDepartamento)"
              >{{ departamento.departamento }}</a
            >
          </li>
        </ul>
      </div>
    </div>

    <!-- Filtro de Programa Academico -->
    <div class="col-md d-none" >
      <div class="btn-group order-by">
        <button
          class="btn dropdown-toggle"
          type="button"
          id="listado"
          data-bs-toggle="dropdown"
          data-bs-auto-close="true"
          aria-expanded="false"
          data-bs-offset="0,0"
          title="{{ filter.programa ? getValueCatalogo(programasAcademicosData, 'idProgramaAcademico', filter.programa, 'programaAcademico') : 'Programa' }}"
        >
        {{ filter.programa ? getValueCatalogo(programasAcademicosData, 'idProgramaAcademico', filter.programa, 'programaAcademico') : 'Programa' }}
        </button>
        <ul
          class="dropdown-menu dropdown-menu-start"
          aria-labelledby="listado"
        >
          <li *ngIf="programasAcademicosData.length == 0" class="dropdown-item">Selecciona una escuela</li>
          <li *ngFor="let programa of programasAcademicosData; let i = index">
            <a
              class="dropdown-item {{ i === 0 ? 'active' : '' }}"
              (click)="addFilter('programa', programa.idProgramaAcademico)"
              >{{ programa.programaAcademico }}</a
            >
          </li>
        </ul>
      </div>
    </div>

    <!-- Filtro de Periodo Semestral -->
    <div class="col-md">
      <div class="btn-group order-by">
        <button
          class="btn dropdown-toggle"
          type="button"
          id="listado"
          data-bs-toggle="dropdown"
          data-bs-auto-close="true"
          aria-expanded="false"
          data-bs-offset="0,0"
        >
        {{ filter.periodoSemestral ? getValueCatalogo(periodoSemestralData, 'idPeriodoSemestral', filter.periodoSemestral, 'periodoSemestral') : 'Periodo Semestral' }}
        </button>
        <ul class="dropdown-menu dropdown-menu-start" aria-labelledby="listado">
          <li *ngFor="let periodo of periodoSemestralData; let i = index">
            <a
              class="dropdown-item {{ i === 0 ? 'active' : '' }}"
              (click)="addFilter('periodoSemestral', periodo.idPeriodoSemestral)"
              >{{ periodo.periodoSemestral }}</a
            >
          </li>
        </ul>
      </div>
    </div>

    <!-- Filtro de Semestre -->
    <div class="col-md d-none">
      <div class="btn-group order-by">
        <button
          class="btn dropdown-toggle"
          type="button"
          id="listado"
          data-bs-toggle="dropdown"
          data-bs-auto-close="true"
          aria-expanded="false"
          data-bs-offset="0,0"
        >
        {{ filter.semestre ? getValueCatalogo(semestreData, 'idSemestre', filter.semestre, 'semestre') : 'Semestre' }}
        </button>
        <ul class="dropdown-menu dropdown-menu-start" aria-labelledby="listado">
          <li *ngFor="let semestre of semestreData; let i = index">
            <a
              class="dropdown-item {{ i === 0 ? 'active' : '' }}"
              (click)="addFilter('semestre', semestre.idSemestre)"
              >{{ semestre.semestre }}</a
            >
          </li>
        </ul>
      </div>
    </div>

    <!-- Filtro de Periodo Semanal -->
    <div class="col-md">
      <div class="btn-group order-by">
        <button
          class="btn dropdown-toggle"
          type="button"
          id="listado"
          data-bs-toggle="dropdown"
          data-bs-auto-close="true"
          aria-expanded="false"
          data-bs-offset="0,0"
        >
        {{ filter.periodoSemanal ? getValueCatalogo(periodoSemanalData, 'idPeriodoSemanal', filter.periodoSemanal, 'periodoSemanal') : 'Periodo' }}
        </button>
        <ul class="dropdown-menu dropdown-menu-start" aria-labelledby="listado">
          <li *ngFor="let periodo of periodoSemanalData; let i = index">
            <a
              class="dropdown-item {{ i === 0 ? 'active' : '' }}"
              (click)="addFilter('periodoSemanal', periodo.idPeriodoSemanal)"
              >{{ periodo.periodoSemanal }}</a
            >
          </li>
        </ul>
      </div>
    </div>

    <!-- Filtro de Semanas -->
    <div class="col-md">
      <div class="btn-group order-by">
        <button
          class="btn dropdown-toggle"
          type="button"
          id="listado"
          data-bs-toggle="dropdown"
          data-bs-auto-close="true"
          aria-expanded="false"
          data-bs-offset="0,0"
        >
        {{ filter.semanas ? getValueCatalogo(semanasData, 'idSemanas', filter.semanas, 'semanas') : 'Semanas' }}
        </button>
        <ul
          class="dropdown-menu dropdown-menu-start"
          aria-labelledby="listado"
        >
          <li *ngFor="let semanas of semanasData; let i = index">
            <a
              class="dropdown-item {{ i === 0 ? 'active' : '' }}"
              (click)="addFilter('semanas', semanas.idSemanas)"
              >{{ semanas.semanas }}</a
            >
          </li>
        </ul>
      </div>
    </div>

    <!-- Reestablecer Filtros -->
    <div class="col-md d-flex justify-content-end">
      <app-button (callbackAction)="cleanFilters()"> Restablecer </app-button>
    </div>
  </div>

  <button class="btn-blue mt-3" *ngIf="clavePerfil === 'academia'" (click)="quieroVincularme()" >Vincular Socio Formador</button>

  <div class="row">
    <div class="col" *ngIf="paginationObj.length">
      <div class="row" *ngFor="let reto of retosObj">
        <div class="col">
          <app-card-retos [objReto]="reto"></app-card-retos>
        </div>
      </div>
    </div>
    <div class="col d-flex justify-content-center" *ngIf="!paginationObj.length">
      <span class="questioncard">No hay retos disponibles</span>
    </div>
  </div>
  <app-pagination *ngIf="paginationObj.length" [objPagination]="paginationObj" (paginationDataChange)="showPaginationData($event)"></app-pagination>

  <div class="row" *ngIf="clavePerfil==='socio' ">
    <div class="col-md-12 d-flex justify-content-center">
      <span class="questioncard">{{ textDataBotton.title }}</span>
    </div>
    <div class="col-md-12 d-flex justify-content-center">
      <span class="subtitlequstcard">{{ textDataBotton.subtitle }}</span>
    </div>
    <br><br>
  </div>

  <div class="row" *ngIf="clavePerfil==='socio' ">
    <div class="col d-flex justify-content-center">
      <app-button buttonType="yell" extraClass="float-end" (callbackAction)="quieroVincularme()">
        Vinculación de Retos
      </app-button>
    </div>
  </div>

</div>
