import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Paginador } from '@shared/Interface/IPaginador.interface';
import { IVinculacionesResponse } from '@shared/Interface/IsocioVinculacion';
import { PaginadorLibsComponent } from '@shared/libs/paginador.component';
import { DashboardService } from '@shared/service/Dashboard/dashboard.service';
import { UsuariosService } from '@shared/service/Usuarios/usuarios.service';
import { environment } from 'environments/environment';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-solicitudes-abiertas',
  templateUrl: './solicitudes-abiertas.component.html',
  styleUrls: ['./solicitudes-abiertas.component.css']
})
export class SolicitudesAComponent implements OnInit {

  @Input() estatusType: string;

  idSocioFormador: number ;
  sidebarExpanded = false;
  lang : string = 'es';
  numberPerPage = environment.RegistrosPagina;
  pages : number[] = [];
  paginaActual = 1;
  numeroPaginas=0;
  vinculacionesList : IVinculacionesResponse[] = [];
  vinculacionesListTotal : IVinculacionesResponse[] = [];
  elementsTo : IVinculacionesResponse[] = [];
  paginaActualMasResultados: number = 1;

  constructor(
      private _dashboardService : DashboardService,
      private paginadorLibs: PaginadorLibsComponent,
      private usuarioService:UsuariosService,
      private router: Router
    ) { }

  async ngOnInit(): Promise<void> {
    this.lang = localStorage.getItem('lang') ?? 'es';
    await this.getVinculaciones();
    this.getPaginacion();
     this.obtenerRegistrosActuales(this.paginaActual);
  }

  async getVinculaciones() {
    try {
      this.idSocioFormador = this.usuarioService.getCurrentUserData().idSocioFormador;
      let type = this.estatusType || 'revision';
      let response;
      if(type == 'aprobado'){
        let responseAprobado = await lastValueFrom(this._dashboardService.getVinculacionesSocio(this.idSocioFormador, type));
        let responseAceptado = await lastValueFrom(this._dashboardService.getVinculacionesSocio(this.idSocioFormador, 'aceptado'));

        let responseAcept =  responseAceptado.map(x=> {return {
          ...x,
          claveEstatusSolicitud: 'aprobado'
        }});
        response = [...responseAprobado, ...responseAcept];
        response.sort((x,y)=>{
          return y.idSolicitud - x.idSolicitud ;
        });

      }
      else{
        response = await lastValueFrom(this._dashboardService.getVinculacionesSocio(this.idSocioFormador, type));
      }

      if (response != null && response != undefined && response.length > 0) {
        this.vinculacionesListTotal = response;
      }
    } catch (error) {
      console.log("Error al realizar la petición", error);
    }
  }

  getPaginacion(){
    let paginador: Paginador = this.paginadorLibs.getPaginacion(this.vinculacionesListTotal.length, this.numberPerPage);
    this.numeroPaginas = paginador.totalPaginas;
    this.pages = paginador.paginas;
  }

  obtenerRegistrosActuales(numPagina:number) {
    this.vinculacionesList = this.paginadorLibs.obtenerRegistrosActuales(numPagina, this.numberPerPage, this.vinculacionesListTotal);
    this.paginaActual = numPagina;
    this.elementsTo = this.vinculacionesList;
    this.paginaActualMasResultados = numPagina;
  }

  obtenerMasResultados(){
    this.paginaActualMasResultados += 1;
    this.elementsTo = this.paginadorLibs.obtenerMasResultados(this.paginaActualMasResultados, this.numberPerPage, this.vinculacionesListTotal);
  }

  changeLang(lang:string){
    this.lang  = lang;
    localStorage.setItem('lang',lang);
    window.location.reload();
  }
  verDetalle(idRetoMaestro){
    window.location.href=`/socio/pagina-retos?idRetoMaestro=${idRetoMaestro}`;
  }
}
