import { DatePipe, formatDate } from "@angular/common";
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FormControl, FormControlName } from "@angular/forms";
import Utils from "@shared/helpers/utils";
import { IContacto } from "@shared/Interface/IContacto.interface";
import { ICartaColaboracion } from "@shared/Interface/IReto.interface";
import { RetosResponse } from "@shared/Interface/IRetosResponse";
import { AzureBlobStorageService } from "@shared/service/AzureBlobStorage.Service";
import { ContactosService } from "@shared/service/Contactos/contactos.service";
import { RetosService } from "@shared/service/Retos/retos.service";
import { SociosFormadoresService } from "@shared/service/Socios_Formadores/socios-formadores.service";
import { SolicitudesService } from "@shared/service/Solicitudes/solicitudes.service";
import { UsuariosService } from "@shared/service/Usuarios/usuarios.service";
import { environment } from "environments/environment";
import { ToastrService } from "ngx-toastr";
import { lastValueFrom } from "rxjs";
import Swal from "sweetalert2";

@Component({
  selector: "app-carta-colaboracion",
  templateUrl: "./carta-colaboracion.component.html",
  styleUrls: ["./carta-colaboracion.component.css"],
})
export class CartaColaboracionComponent implements OnInit {
  @Input() idReto: number;
  @Input() status: string;
  @Input() oReto: RetosResponse;
  @Input() idSocioFormador: number;
  @Input() lstContactos: IContacto[] = [];
  @Input() borrarCarta:boolean;

  @Output() sobreSFCV: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output() firmarCartaCV: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output() setContactoEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() mostrarFormCartaColaboracion: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  cartaColaboracion: ICartaColaboracion[];
  firmarCartaSF: boolean = false;
  estatusFile: string = "";
  idContactoFirma: number = 0;
  clavePerfil: string = "";
  contactoFirmante: any;
  mostrarSeccionCartaColaboracion: boolean = false;

  constructor(
    private sociosFormadoresService: SociosFormadoresService,
    private contactoService: ContactosService,
    private usuarioService: UsuariosService,
    private toastr: ToastrService,
    private _azureBlobStorage: AzureBlobStorageService,
    private retoService: RetosService,
    private solicitudesService: SolicitudesService
  ) {}

  ngOnInit(): void {
    this.clavePerfil = this.usuarioService.getCurrentUserData().clavePerfil;

    this.getCartaColaboracion();

    if (this.status != "formalizacion" || "socio" == this.clavePerfil) {
      this.mostrarSeccionCartaColaboracion = true;
    }
  }

  async ngOnChanges(changes: SimpleChanges) {

    this.getContactosdelSocioFormador();

    if (
      (changes.borrarCarta?.currentValue ?? false)
    ) {
        if (this.cartaColaboracion.length > 0) {
          try {
            let idColaboracion = this.cartaColaboracion[0].idColaboracion;
            let idUsuario = this.usuarioService.getCurrentUserData().idUsuario;
            await lastValueFrom(
              this.sociosFormadoresService.deleteCartaColaboracion(
                idColaboracion,
                idUsuario
              )
            );
            this.firmarCartaSF = true;
            this.getCartaColaboracion();
          } catch (err) {
            console.log(err);
          }
      }
    }
  }

  firmar() {
    this.firmarCartaSF = true;
    this.firmarCartaCV.emit();
  }

  async eliminarCartaColaboracion(idColaboracion) {
    Swal.fire({
      text: "¿Desea eliminar la carta colaboración?",
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then(async (res) => {
      if (res.isConfirmed) {
        try {
          let idUsuario = this.usuarioService.getCurrentUserData().idUsuario;
          await lastValueFrom(
            this.sociosFormadoresService.deleteCartaColaboracion(
              idColaboracion,
              idUsuario
            )
          );
          this.toastr.success("Carta de colaboración eliminada correctamente.");
          this.firmarCartaSF = true;
          this.getCartaColaboracion();
          // Notificar que se borró la carta
          this.sobreSFCV.emit();
        } catch (err) {
          console.log(err);
        }
      }
    });
  }

  async getCartaColaboracion() {
    try {
      this.datosCartaColaboracion.tieneCarta = false;
      this.datosCartaColaboracion.tieneFecha = false;
      this.datosCartaColaboracion.tieneContacto = false;
      this.setContactoEvent.emit(this.datosCartaColaboracion);
      this.cartaColaboracion = [];
      let results = await lastValueFrom(
        this.sociosFormadoresService.getCartaColaboracion(this.idSocioFormador ?? this.oReto.idSocioFormador)
      );
      if (results.length > 0) {
        let result = results.find((x) => x.idReto == this.oReto.idReto);
        if (result) {
          let contacto = "";
          if (this.contactoFirmante) {
            contacto = `${this.contactoFirmante.nombre} ${this.contactoFirmante.apellidos}`;
          } else {
            contacto = await this.getNombreContacto(result[0]);
          }
          this.cartaColaboracion.push({
            ...result,
            nombreFirmante: contacto,
          });
          this.datosCartaColaboracion.tieneCarta = true;
          this.datosCartaColaboracion.tieneFecha = true;
          this.datosCartaColaboracion.tieneContacto = true;
          this.setContactoEvent.emit(this.datosCartaColaboracion);
        }
      }
    } catch (error:any) {
      Utils.processErrorResponse(error);
    }
  }

  fechaFirmaCartaClaboracion: string = "";
  setFechaFirmaCartaColaboracion(event) {
    this.fechaFirmaCartaClaboracion = "";
    this.fechaFirmaCartaClaboracion = event.target.value;
  }

  async selImagen(event: any) {
    if (!this.idContactoFirma) {
      event.target.value = "";
      console.error("Debe seleccionar y guardar un Reto");
      this.toastr.error("Selecciona el contacto que firma");
      return;
    }
    // Valida idSocioFormador
    if (!this.oReto) {
      event.target.value = "";
      console.error("Debe seleccionar y guardar un Reto");
      this.toastr.error("Selecciona y guarda un Reto");
      return;
    }

    // Valida la fecha
    if (this.fechaFirmaCartaClaboracion.length == 0) {
      console.error("Debe seleccionar y guardar un Reto");
      this.toastr.error("Selecciona la fecha de firma");
      return;
    }

    let idUsr = this.idSocioFormador ?? this.oReto.idSocioFormador;
    let idReto = this.oReto.idReto;
    let selFile = (event.target as HTMLInputElement).files?.[0];

    // Valida idSocioFormador
    if (!idUsr) {
      event.target.value = "";
      console.error("Debe seleccionar y guardar el Socio Formador");
      this.toastr.error("Selecciona y guarda un Socio Formador");
      return;
    }
    // Valida existencia
    if (!selFile) return;
    // Valida tamaño
    // if (selFile.size > 2e6) {
    //   console.error("Tamaño máximo de 2MB");
    //   this._toastr.error("Tamaño máximo de 2MB");
    //   return;
    // }
    // Obtiene nombre
    let fileName = this.getFileName(idUsr, idReto, selFile);
    // guarda en bd
    this.estatusFile = "Subiendo archivo ...";
    await this.uploadSocioImage(idUsr, selFile, fileName, idReto);
  }

  async uploadSocioImage(idUsr, selFile, fileName, idReto) {
    await this._azureBlobStorage
      .uploadFile(
        {
          containerName: environment.AzureBlob_containerNameSocio,
          file: selFile,
          filename: fileName,
        },
        environment.AzureBlob_ACCOUNT_NAME,
        environment.AzureBlob_socioSAS
      )
      .then(async (res) => {
        let fileUrlLogo = `${environment.AzureBlob_socioURL}${fileName}`;
        let currentDate = this.fechaFirmaCartaClaboracion;
        try {
          let res: any = await lastValueFrom(
            this.sociosFormadoresService.postCartaColaboracion({
              idSocioFormador: idUsr,
              idReto: idReto,
              urlCartaColaboracion: fileUrlLogo,
              idAcademico: this.usuarioService.getCurrentUserData().idUsuario,
              fechaFirma: formatDate(
                currentDate,
                "yyyy-MM-dd HH:mm:ss",
                "es-MX"
              ),
              idCategoria: 6,
            })
          );

          // Obtener el idCartaColaboracion actual
          let resCC = await lastValueFrom(
            this.sociosFormadoresService.getCartaColaboracion(idUsr)
          );

          let cartacolActual = resCC.find(
            (x) => x.urlCartaColaboracion == fileUrlLogo
          );

          if (!cartacolActual) {
            this.toastr.error("Error, Intenta de nuevo.");
            return;
          }

          this.estatusFile = "";

          // Obtener el idUsuario

          let idUsuarioFirma = (
            await lastValueFrom(
              this.contactoService.getContactoxId(idUsr, this.idContactoFirma)
            )
          ).idUsuario;

          this.firmarColaboracion(
            cartacolActual.idColaboracion,
            idUsuarioFirma
          );

          this.toastr.success("Carta de colaboración guardada correctamente.");
          this.getCartaColaboracion();
        } catch (err) {
          this.estatusFile = "Error al subir el archivo, intentar nuevamente.";
          console.log(err);
        }
      });
  }

  async firmarColaboracion(idColaboracion: number, idUsuarioFirmante: number) {
    try {
      await lastValueFrom(
        this.sociosFormadoresService.postFlujofirmas({
          tipo: "Colaboracion",
          pasoFirma: 1,
          id: idColaboracion,
          idUsuarioFirmante: idUsuarioFirmante,
        })
      );
      await lastValueFrom(
        this.sociosFormadoresService.postFlujofirmas({
          tipo: "Colaboracion",
          pasoFirma: 2,
          id: idColaboracion,
          idUsuarioFirmante: idUsuarioFirmante,
        })
      );
    } catch (e) {
      console.log(e);
    }
  }

  getFileName(idsf, idReto, file) {
    return `socio_carta_colaboracion_${idsf}_${idReto}.${file.name
      .split(".")
      .pop()}`.toLowerCase();
  }

  datosCartaColaboracion: any = {
    tieneContacto: false,
    tieneFecha: false,
    tieneCarta: false,
  };
  setContacto(event) {
    this.contactoFirmante = event;
    this.idContactoFirma = event.idContacto;
    this.datosCartaColaboracion.tieneContacto = true;
    this.setContactoEvent.emit(this.datosCartaColaboracion);
  }

  async sfTieneCartaConvenioFirmada(){
    let valorRegreso:boolean = false;
    try{
      let res = await lastValueFrom(this.sociosFormadoresService.getCartaConvenio(this.idSocioFormador));

      let vigente = false
      res.forEach(item => {
        let fecHoy = new Date();
        let fecFin = new Date(item.fechaFin);

        // Fecha inicio menor a fecha fin
      if (fecHoy < fecFin && !vigente )
          vigente = true;

      });

      valorRegreso = vigente;

    }
    catch(error)
    {
      console.log(error);

    }
    if(!valorRegreso){
      Swal.fire({text:'La carta de colaboración no puede ser generada hasta que la solicitud del Socio Formador sea aprobada por el CVDP.'});
    }
    return valorRegreso;
  }


  generarColaboracion() {
    // Se quita a petición de Lourdes, Daniel y Mariano
    // 20240424
    // if(!this.sfTieneCartaConvenioFirmada()){
    //   return;
    // }
    let idSocioFormador = this.idSocioFormador ?? this.oReto.idSocioFormador;
    if(idSocioFormador == 0){
      Swal.fire({text:'Para generar la carta de Colaboración, Selecciona al Socio Formador y su contacto.'});
      return;
    }

    Swal.fire({
      text: "Se generará la carta colaboracion",
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {

          let idUsuario = this.usuarioService.getCurrentUserData().idUsuario;
          let idReto = this.idReto;

          let tipoCarta;

          // idAlcance Nacional = 1  => categoria => Nacional 2
          if (this.oReto.idAlcance == 1) tipoCarta = 2;
          // idAlcance Regional = 2  => categoria => Regional 3
          if (this.oReto.idAlcance == 2) tipoCarta = 3;
          // idAlcance Local = 3  => categoria => Local 1
          if (this.oReto.idAlcance == 3) tipoCarta = 1;

          let request = {
            listaIds: [
              {
                campo: "idSocioFormador",
                id: idSocioFormador,
              },
              {
                campo: "idUsuario",
                id: idUsuario,
              },
              {
                campo: "idColaboracion",
                id: 0,
              },
              {
                campo: "idReto",
                id: idReto,
              },
            ],
            idCategoria: tipoCarta,
          };
          let result = await lastValueFrom(
            this.solicitudesService.postGeneraCartaPDF(request)
          );

          this.getCartaColaboracion();

        } catch (err:any) {
          this.toastr.error(
            "Error al generar la carta colaboración, intenta más tarde."
          );
          Utils.processErrorResponse(err);
        }
      }
    });
  }

  mostrarCartacolaboracion() {
    let idSocio = this.idSocioFormador ?? this.oReto.idSocioFormador;
    if (
      !idSocio &&
      this.mostrarSeccionCartaColaboracion == false
    ) {
      Swal.fire({
        text: "Debes seleccionar un Socio Formador",
      });
    } else if (this.lstContactos.length == 0) {
        this.getContactosdelSocioFormador();
      }

    this.mostrarSeccionCartaColaboracion = !this
      .mostrarSeccionCartaColaboracion;

    this.mostrarFormCartaColaboracion.emit(this.mostrarSeccionCartaColaboracion);
  }

  async getContactosdelSocioFormador() {
    this.lstContactos = [];
    try {
      this.lstContactos = await lastValueFrom(
        this.contactoService.getContactos(this.idSocioFormador ?? this.oReto.idSocioFormador)
      );
    } catch (error:any) {
      Utils.processErrorResponse(error);
    }
  }

  async getNombreContacto(contacto) {
    let nombre = "";

    if(!contacto)
      return nombre;

    if(!contacto.idUsuarioPaso2Firma)
      return nombre;

    try {
      let res: any = await lastValueFrom(
        this.usuarioService.getUsuarioById(contacto.idUsuarioPaso2Firma)
      );
      nombre = `${res.nombreUsuario} ${res.apellidosUsuario}`;
    } catch (error: any) {
      Utils.processErrorResponse(error);
    }
    return nombre;
  }
}
