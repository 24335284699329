import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { PaginationComponent } from "@shared/components/pagination/pagination.component";
import {
  optionsTextCol,
} from "@shared/components/table/table.component";
import {
  ICampus,
  IEscuela,
  IPeriodo,
} from "@shared/Interface/ICatalogos.interface";
import { CatalogosService } from "@shared/service/Catalogos/catalogos.service";
import { ReportesService } from "@shared/service/Reportes/reportes.service";
import { TableUtil } from "@shared/service/Reportes/tableUtils";
import { UsuariosService } from "@shared/service/Usuarios/usuarios.service";
import { Observable, lastValueFrom } from "rxjs";

@Component({
  selector: "app-detalle",
  templateUrl: "./detalle.component.html",
  styleUrls: ["./detalle.component.css"],
})
export class DetalleComponent implements OnInit {
  private pagina: PaginationComponent;

  backgroundTable = "#F6F6F6";
  idReporteMostrar = 0;

  DataReporte = [];
  DataPantalla = [];
  DataPantallaActual = [];

  // Observables
  periodoData$: Observable<IPeriodo[]>;
  escuelaData$: Observable<IEscuela[]>;
  campusData$: Observable<ICampus[]>;
  rolesData: any[];
  registrosPorPagina:number = 20;

  // Controles:
  ctrlPeriodoSemestral = new FormControl("");
  ctrlEscuela = new FormControl("");
  ctrlEscuela2 = new FormControl("");
  ctrlCampus = new FormControl("");
  ctrlRoles = new FormControl("");

  constructor(
    private _reportesService: ReportesService,
    private catalogosService: CatalogosService,
    private _usuarioService: UsuariosService,
    private changeDetector: ChangeDetectorRef
  ) {
    this.pagina = new PaginationComponent();
    this.pagina.recordsPerPage = this.registrosPorPagina;

    this.periodoData$ = this.catalogosService.getPeriodo();
    this.escuelaData$ = this.catalogosService.getEscuelas();
    this.campusData$ = this.catalogosService.getCampus();
    this.rolesData = [
      { cvePerfil: "academia", perfil: "Academia" },
      { cvePerfil: "cvdp", perfil: "CVDP" },
      { cvePerfil: "socio", perfil: "Socio" },
    ];
  }

  ngOnInit(): void {
    if(!this._usuarioService.getCurrentUserData()){
      window.location.href="/";
      return;
    }
    let idReporte = Number(localStorage.getItem("reporte") || 0);
    this.obtenerReporte(idReporte);
  }

  async obtenerReporte(idReporte) {
    try {
      this.DataPantallaActual = [];
      this.idReporteMostrar = idReporte;
      let allData;
      if(idReporte == 1){
        let parametros = `${this.ctrlPeriodoSemestral.value||0}|${this.ctrlEscuela.value||0}|${this.ctrlCampus.value||0}`;
        allData = await lastValueFrom(this._reportesService
          .postGenerarReporte(idReporte, 1, parametros))
      }

      if(idReporte == 2){
        let parametros = `${this.ctrlEscuela2.value||0}`;
        allData = await lastValueFrom(this._reportesService
          .postGenerarReporte(idReporte, 1, parametros));

      }

      if(idReporte == 3){
        let parametros = `${this.ctrlRoles.value}`;
        allData = await lastValueFrom(this._reportesService
          .postGenerarReporte(idReporte, 1, parametros));
      }

      this.DataReporte = allData["#result-set-1"]||[];
      this.DataPantalla = this.DataReporte;
      this.DataPantallaActual = this.DataReporte.slice(0,this.registrosPorPagina);
      this.obtenerColumnas();

      this.pagina.objPagination = this.DataPantalla;
      this.pagina.updateCurrentPage(1);

    } catch (err) {
      console.log(err);
    }
  }

  showPaginationData(objData: any) {
    if (objData.length) {
      this.DataPantallaActual = objData;
    }
    else{
      this.DataPantallaActual = [];
    }
    this.changeDetector.detectChanges();
  }

  columnas: any;
  obtenerColumnas() {
    if (this.DataReporte && this.DataReporte.length>0) {
      let objTmp = this.DataReporte[0];
      let listaColumnas = Object.keys(objTmp);

      this.columnas = listaColumnas.map((x) => {
        return {
          title: x,
          sizeCol: "2",
          classType: "dashbd_rows",
          typeColumn: optionsTextCol.text,
          key: x,
        };
      });
    }
  }

  async exportData() {
    try {
      let allData;
      if(this.idReporteMostrar == 1){
        let parametros = `${this.ctrlPeriodoSemestral.value||0}|${this.ctrlEscuela.value||0}|${this.ctrlCampus.value||0}`;
        allData = await lastValueFrom(this._reportesService
          .postGenerarReporte(this.idReporteMostrar, 0, parametros));
      }

      if(this.idReporteMostrar == 2){
        let parametros = `${this.ctrlEscuela2.value||0}`;
        allData = await lastValueFrom(this._reportesService
          .postGenerarReporte(this.idReporteMostrar, 0, parametros));
      }

      if(this.idReporteMostrar == 3){
        let parametros = `${this.ctrlRoles.value}`;
        allData = await lastValueFrom(this._reportesService
          .postGenerarReporte(this.idReporteMostrar, 0, parametros));
      }

      TableUtil.exportArrayToExcel(allData["#result-set-1"], "Reporte");

    } catch (err) {
      console.log(err);
    }
  }
}
