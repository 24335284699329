import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { InicioService } from '@shared/service/Inicio/inicio.service';

@Component({
  selector: 'app-reto-registrado',
  templateUrl: './reto-registrado.component.html',
  styleUrls: ['./reto-registrado.component.css']
})
export class RetoRegistradoComponent implements OnInit {
  titleConfirmReto = {
    title: '',
  };
  imgUrlSocio: string = 'assets/img/reto-5-1.png';

  constructor(private router: Router, private inicio: InicioService) { }

  ngOnInit(): void {

    this.imgUrlSocio = '';
  }
  irAmisRetos() {
    localStorage.removeItem('idReto');
    this.router.navigate(['retos-activos']);
  }
  irAVincularSocios(){
    // guardar reto en localstorage
    this.inicio.changeMenuItem('vinculacionsociosformadores');
    this.router.navigate(['vincula-socios-formadores']);
  }
}
