<div class="sec_ad mt-4">
    <div id="c_sl_pa" class="sec_cnt tipo-10">
        <div class="d-none d-md-block">
            <div class="cont_tit d-flex">
                <div class="flex-fill col-1">{{ 'solicitud-vinculacion-retos.fecha' | translate }}</div>
                <div class="flex-fill col-3">{{ 'solicitud-vinculacion-retos.socio_formador_contacto' | translate }}</div>
                <div class="flex-fill col-3">{{ 'solicitud-vinculacion-retos.reto_vincular' | translate }}</div>
                <div class="flex-fill col-1">{{ 'solicitud-vinculacion-retos.duracion' | translate }}</div>
                <div class="flex-fill col-1">{{ 'solicitud-vinculacion-retos.periodo_semanal' | translate }}</div>
                <div class="flex-fill col-1">{{ 'solicitud-vinculacion-retos.periodo_semestral' | translate }}</div>
                <div class="flex-fill col-1">{{ 'solicitud-vinculacion-retos.campus' | translate }}</div>
                <div class="flex-fill col-1">{{ 'solicitud-vinculacion-retos.acciones' | translate }}</div>
            </div>

            <div *ngFor="let vinculacionReto of vinculacionRetosActualesList"
                class="roundedsquare cont_fl d-flex align-items-center">

                <div class="flex-fill col-1">{{vinculacionReto.fechaRegistro | date: 'dd/MMM/yy'}}</div>

                <div class="flex-fill col-3 d-flex align-items-center">
                    <img alt="Logo" class="img_usr d-none" src="assets/img/avatar_sin.jpg">
                    <div>
                        <div>
                            <a [routerLink]="[]" (click)="verReto(vinculacionReto)">
                                {{vinculacionReto.nombreSocioFormador}}</a>
                        </div><span>{{vinculacionReto.rfc}}</span>
                    </div>
                </div>

                <div class="flex-fill col-3" title="{{vinculacionReto.reto}}">
                    {{vinculacionReto.reto.substring(0,maxChar)}}
                    ...<br />
                    <span title="{{ vinculacionReto.unidadFormativa }}">{{
                        vinculacionReto.unidadFormativa.substring(0,maxChar) }}</span>
                </div>

                <div class="flex-fill d-flex align-items-center col-1">
                    <div>
                        <div>{{vinculacionReto.duracion }}</div>
                    </div>
                </div>

                <div class="flex-fill d-flex align-items-center col-1">
                    <div>
                        <div>{{vinculacionReto.periodoSemanal }}
                        </div>
                    </div>
                </div>

                <div class="flex-fill d-flex align-items-center col-1">
                    <div>
                        <div>{{vinculacionReto.periodoSemestral }}
                        </div>
                    </div>
                </div>

                <div class="flex-fill d-flex align-items-center col-1">
                    <div>
                        <div>{{vinculacionReto.campus}}</div>
                    </div>
                </div>

                <div class="flex-fill col-1">
                    <button (click)="verReto(vinculacionReto)" class="blue_button_nrml">{{
                        'solicitud-vinculacion-retos.ver' | translate }}</button>
                </div>
            </div>

            <div *ngIf="vinculacionRetosActualesList.length === 0">
                <app-sin-datos-table></app-sin-datos-table>
            </div>

        </div>
        <div class="d-block d-md-none">
            <div class="accordion" id="ac_1">
                <div *ngFor="let vinculacionReto of vinculacionRetosActualesMovilList" class="accordion-item">
                    <h2 class="accordion-header" id="flush-heading{{vinculacionReto.idSolicitud}}">
                        <button class="accordion-button" type="button" data-bs-toggle="collapse"
                            [attr.data-bs-target]="'#flush-collapse'+vinculacionReto.idSolicitud" aria-expanded="true">
                            <strong>{{vinculacionReto.nombreSocioFormador}}</strong>&nbsp;{{vinculacionReto.nombreContacto}}
                        </button>
                    </h2>
                    <div id="flush-collapse{{vinculacionReto.idSolicitud}}" class="accordion-collapse collapse show"
                        data-bs-parent="#ac_1">
                        <div class="accordion-body">
                            <div><strong>{{ 'solicitud-vinculacion-retos.fecha' | translate
                                    }}</strong><br />{{vinculacionReto.fechaRegistro | date: 'dd/MMM/yy'}}
                            </div>
                            <div><strong>{{ 'solicitud-vinculacion-retos.reto_vincular' | translate
                                    }}</strong><br />{{vinculacionReto.reto}}</div>
                            <div><strong>{{ 'solicitud-vinculacion-retos.campus' | translate
                                    }}</strong><br />{{vinculacionReto.campus}}<br /></div>
                            <div><button class="blue_button_nrml">{{ 'solicitud-vinculacion-retos.ver' |
                                    translate
                                    }}</button></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-center" *ngIf="paginaActualMasResultados < totalPaginas">
                <button [disabled]="paginaActualMasResultados >= totalPaginas" class="mb_mas"
                    (click)="obtenerMasResultados()"><img alt="Más resultados" class="tam"
                        src="assets/img/icon_mas_bullet.svg"> {{
                    'solicitud-vinculacion-retos.cargar_resultados' |
                    translate }}</button>
            </div>

            <div class="mt-2 d-flex justify-content-center"
                *ngIf="paginaActualMasResultados == totalPaginas && vinculacionRetosActualesMovilList.length <= registrosPagina && vinculacionesRetosAllList.length > registrosPagina">
                <button class="mb_mas" (click)="obtenerMasResultados()"><img alt="Anterior" class="tam"
                        src="assets/img/icon_mas_bullet.svg"> {{ 'paginador.resultados_anteriores' |
                    translate }}
                </button>
            </div>

            <div *ngIf="vinculacionRetosActualesMovilList.length === 0">
                <app-sin-datos-table></app-sin-datos-table>
            </div>

        </div>
    </div>
</div>

<nav id="pagination" class="d-none d-md-block mt-4" *ngIf="totalPaginas > 0">
    <ul class="pagination justify-content-center">
        <li class="page-item" [class.disabled]="paginaActual==1">
            <a class="page-link" (click)="obtenerRegistrosActuales(paginaActual - 1)">{{
                "paginador.anterior" |
                translate }} &nbsp;&nbsp; <img alt="Anterior" class="iCn" src="assets/img/icon pag_ant.svg"></a>
        </li>
        <li class="page-item" *ngFor="let num of pages" [class.active]="paginaActual==num">
            <a class="page-link" (click)="obtenerRegistrosActuales(num)">{{num}}</a>
        </li>
        <li class="page-item" [class.disabled]="paginaActual >=totalPaginas">
            <a class="page-link" (click)="obtenerRegistrosActuales(paginaActual + 1)"><img alt="Siguiente" class="iCn"
                    src="assets/img/icon pag_sig.svg">&nbsp;&nbsp; {{ "paginador.siguiente" |
                translate }}</a>
        </li>
    </ul>
    <div class="text-center">{{ "paginador.total_paginador" | translate: {paginaActual: paginaActual,
        totalPaginas:
        totalPaginas } }}</div>
</nav>