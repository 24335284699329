import { HttpClient, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { AccountService } from '../Account/account.service';
import { IUser } from '@shared/Interface/user';
import { CookieService } from 'ngx-cookie-service';

import { baseUrl } from '../routesServices';
import { IRecursosVinculacion } from '@shared/Interface/IRecursosVinculacion';


@Injectable({
  providedIn: 'root',
})
export class RecursosService {
  //** Esta clase maneja todo lo relacionado con el usuario en SociosFormadores */
  baseUrl = environment.apiUrl;
  currentUser!: IUser;

  queryFilter$ = new EventEmitter();

  constructor(
    private http: HttpClient,
    private accountService: AccountService,
    private cookieService: CookieService
  ) {}

  /** Api que obtiene los recursos de vinculacion por estatus
   *
   * @param estatus Estatus de lo recursos de viculacion si no se pasa trae todos los estatus
   * @returns lista de objetos tipo 
   */
  getRecursosEstatus(estatus: any): Observable<any[]> {
    return this.http.get<any[]>(
      this.baseUrl + 'administracion/recursoVinculacion?estatus=' + estatus
    );
  }
  getVinculacionById(id:number): Observable<IRecursosVinculacion> {
    return this.http.get<IRecursosVinculacion>(
      this.baseUrl + 'administracion/recursoVinculacion/' + id
    );
  }
  putVinculacion(body: any): Observable<any> {
    return this.http.put<any>(this.baseUrl + 'administracion/recursoVinculacion', body);
  }
  postVinculacion(body: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'administracion/recursoVinculacion', body);
  }

  deleteRecurso(id:number){
    return this.http.delete(`${this.baseUrl}administracion/recursoVinculacion/${id}`);
  }
  deleteItem(item: any): Observable<any> {
    let params = new HttpParams();
    params = params.append('itemId', item.itemId);
    return this.http.delete<any>(`url`, { params });
}

postContactos(idSocio, body: any): Observable<any> {
    return this.http.post<any>(
      `${baseUrl}/${idSocio}`,
      body
    );
  }



}
