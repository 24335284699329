// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  name:'sps',
  /*apiUrl: 'https://api.mpstec.mx:8443/v1/', */
   apiUrl: "https://mpsapi.spsneurika.net:8443/v1/",
  RegistrosPagina: 10,
  RegistrosDashboard: 3,
  HabilitarB2C: true,
  imageSFDefault: 'mvpretos-app-assets/logo_sf_sin.jpg',
  GoogleAnalitycs: 'G-F6410CCV39',
  B2C: {
    clientId: '4981f22c-580d-4af7-9239-dba5e2976e76', // Application Id of Application registered in B2C
    redirectUri: 'https://mps.spsneurika.net/validasesion',
    knownAuthorities: ['https://mvpretos1.b2clogin.com/'],
    authority: 'https://mvpretos1.b2clogin.com/mvpretos1.onmicrosoft.com/B2C_1_Retos2',
    postLogoutRedirectUri: 'https://mps.spsneurika.net/logout',
  },
  B2C_Protected: ['https://mvpretos1.b2clogin.com/User.ReadWrite.All'],
  B2C_scopes: ['https://mvpretos1.b2clogin.com/User.ReadWrite.All'],
  regexEmailAccepted: "^(?!([A|a])[0-9])+[-!#$%&'+\/0-9=?A-Z^_a-z{|}](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}])*(@tec.mx|@gmail.com)$",
  AzureBlob_ACCOUNT_NAME:'mvretosstorage',
  AzureBlob_socioSAS:'?sp=rawdl&st=2022-09-22T00:44:16Z&se=2025-09-22T08:44:16Z&spr=https&sv=2021-06-08&sr=c&sig=ysau9yJ88xq5UQsLx9N3LymISX6UgX4LUKx23sPk1z8%3D',
  AzureBlob_retosSAS:'?sp=racwdl&st=2022-09-25T21:41:38Z&se=2025-09-26T05:41:38Z&spr=https&sv=2021-06-08&sr=c&sig=sXtSHDFEVpKL2Md5Vq5rjFe4iI%2BSXfBaghpSAkSwr7E%3D',
  AzureBlob_usuarSAS:'?sp=racwdl&st=2022-09-25T21:43:15Z&se=2025-09-26T05:43:15Z&spr=https&sv=2021-06-08&sr=c&sig=7%2BJwPwjfCSbFKRjIpg2otJITfW4IbTTarA2yoc3%2FVc8%3D',
  AzureBlob_cargaSAS:'?sp=racwdl&st=2023-10-03T18:50:19Z&se=2026-03-11T02:50:19Z&spr=https&sv=2022-11-02&sr=c&sig=8wDYLMzAVVG4eEbCyVa2IGUFsT4cYUq8DKzdka08nRQ%3D',
  AzureBlob_containerNameSocio: 'mvpretos-socios-logos',
  AzureBlob_containerNameUsuario: 'mvpretos-user-images',
  AzureBlob_containerNameRetos: 'mvpretos-retos-images',
  AzureBlob_containerNameVinculacion: 'mvpretos-vinculacion-images',
  AzureBlob_containerNameCarga: 'mvpretos-carga-masiva',
  AzureBlob_socioURL: 'https://mvretosstorage.blob.core.windows.net/mvpretos-socios-logos/',
  AzureBlob_retosURL: 'https://mvretosstorage.blob.core.windows.net/mvpretos-retos-images/',
  AzureBlob_usuarURL: 'https://mvretosstorage.blob.core.windows.net/mvpretos-user-images/',
  AzureBlob_cargaURL: 'https://mvretosstorage.blob.core.windows.net/mvpretos-carga-masiva/',
  feature:{
    "F2_5_HU69":true, // ACademico buscando SF
    "F2_4_HU69":false, // ACademico buscando SF

    "F2_5_HU58":true,
    "F2_4_HU58":false,

    "F2_5_HU67":true,
    "F2_4_HU67":false,

    "F2_5_HU54":true,
    "F2_5_HU50":true
},
RawCaseIdSocioFormador: 3883
};

/*
SASsociotoken: sp=rawdl&st=2022-09-22T00:44:16Z&se=2025-09-22T08:44:16Z&spr=https&sv=2021-06-08&sr=c&sig=ysau9yJ88xq5UQsLx9N3LymISX6UgX4LUKx23sPk1z8%3D
SASUrl:   https://mvretosstorage.blob.core.windows.net/mvpretos-socios-logos?sp=rawdl&st=2022-09-22T00:44:16Z&se=2025-09-22T08:44:16Z&spr=https&sv=2021-06-08&sr=c&sig=ysau9yJ88xq5UQsLx9N3LymISX6UgX4LUKx23sPk1z8%3D


SASRetos: sp=racwdl&st=2022-09-25T21:41:38Z&se=2025-09-26T05:41:38Z&spr=https&sv=2021-06-08&sr=c&sig=sXtSHDFEVpKL2Md5Vq5rjFe4iI%2BSXfBaghpSAkSwr7E%3D
SASURL: https://mvretosstorage.blob.core.windows.net/mvpretos-retos-images?sp=racwdl&st=2022-09-25T21:41:38Z&se=2025-09-26T05:41:38Z&spr=https&sv=2021-06-08&sr=c&sig=sXtSHDFEVpKL2Md5Vq5rjFe4iI%2BSXfBaghpSAkSwr7E%3D

SASusuario:  sp=racwdl&st=2022-09-25T21:43:15Z&se=2025-09-26T05:43:15Z&spr=https&sv=2021-06-08&sr=c&sig=7%2BJwPwjfCSbFKRjIpg2otJITfW4IbTTarA2yoc3%2FVc8%3D
SASURL:  https://mvretosstorage.blob.core.windows.net/mvpretos-user-images?sp=racwdl&st=2022-09-25T21:43:15Z&se=2025-09-26T05:43:15Z&spr=https&sv=2021-06-08&sr=c&sig=7%2BJwPwjfCSbFKRjIpg2otJITfW4IbTTarA2yoc3%2FVc8%3D

    clientId: '4981f22c-580d-4af7-9239-dba5e2976e76', // Application Id of Application registered in B2C
    redirectUri: 'https://sociosformadores.spsneurika.net/validasesion',
    knownAuthorities: ['https://mvpretos1.b2clogin.com/'],
    authority: 'https://mvpretos1.b2clogin.com/mvpretos1.onmicrosoft.com/B2C_1_Retos2',
    postLogoutRedirectUri: 'https://sociosformadores.spsneurika.net/logout',
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
