import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-perfil-contact',
  templateUrl: './perfil-contact.component.html',
  styleUrls: ['./perfil-contact.component.css'],
})
export class PerfilContactComponent  {
  @Input() mainContactObj: any;
  @Input() newContact: boolean;
  @Input() updateSocio = false;
  @Output() callbackShowContacts: EventEmitter<any> = new EventEmitter<any>();

  listenerCallbackShow(event) {
    this.callbackShowContacts.emit(event);
  }
}
