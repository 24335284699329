import { Component, Input,  Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
  selector: 'app-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.css'],
})
export class TextInputComponent implements ControlValueAccessor {
  @Input() label: string;
  @Input() placeholder: string;
  @Input() type = 'text';


  constructor(@Self() public ngControl: NgControl) {
    this.ngControl.valueAccessor = this;
  }

  writeValue(obj: any): void {
    // Implementar si es necesario
    //console.log("write");
  }
  registerOnChange(fn: any): void {
    //console.log("OnChange")
  }
  registerOnTouched(fn: any): void {
    //console.log("OnTouched")
  }

  
}
