<div>
    <div class="subtitlecard textblue" *ngIf="status != 'formalizacion'">SOBRE EL RETO A IMPLEMENTAR </div>
    <div class="subtitlecard textblue"
        *ngIf="status == 'formalizacion' && ['cvdp','cvdp_admin','academia'].includes(clavePerfil)">PASO 3: SOBRE EL
        RETO A IMPLEMENTAR </div>
    <div class="section">
        <div class="text-secondary"
            *ngIf="status == 'formalizacion' && ['cvdp','cvdp_admin','academia'].includes(clavePerfil)"> Registra el
            reto implementado. Hemos cargado el reto
            semilla para que te sirva como base.
            Recuerda
            que esta información es la que visualizará el Socio Formador y la que sirve de insumo para construir la
            carta de
            colaboración.
        </div>

        <div class="mt-4 bold"
            *ngIf="status == 'formalizacion' && ['cvdp','cvdp_admin','academia'].includes(clavePerfil)">
            <button (click)="documentar()" class="yell_button">Documentar mi reto </button>
        </div>

        <form [formGroup]="retoForm"
            *ngIf="documentarReto && status == 'formalizacion' && ['cvdp','cvdp_admin','academia'].includes(clavePerfil)">
            <div class="row mt-4">
                <!-- 1 -->
                <div class="col-1"><button _ngcontent-cen-c213="" class="step-button-active text-center"> 1 </button>
                </div>
                <div class="col-11">
                    <span class="title-option">Seleciona el alcance del reto <span class="text-danger">*</span></span>
                    <div class="text-danger" *ngIf="retoForm.get('alcance_reto')?.errors?.['required']">Campo requerido
                    </div>
                    <div class="select-dropdown">
                        <ng-select [items]="alcancesData" bindLabel="alcance" bindValue="idAlcance"
                            formControlName="alcance_reto" (change)="setReto()"
                            [placeholder]="'Selecciona una opción'"></ng-select>
                    </div>
                    <br />
                </div>

                <!-- 2 -->
                <div class="col-1"><button _ngcontent-cen-c213="" class="step-button-active text-center"> 2 </button>
                </div>
                <div class="col-11">
                    <span class="title-option">Define un nombre para tu reto <span class="text-danger">*</span></span>
                    <div class="text-danger" *ngIf="retoForm.get('nombre_reto')?.errors?.['required']" >Campo requerido
                    </div>
                    <input class="form-control" type="text" formControlName="nombre_reto"
                        [placeholder]="'Selecciona una opción'" (change)="setReto()" required>
                    <br />
                </div>

                <!-- 3 -->
                <div class="col-1"><button _ngcontent-cen-c213="" class="step-button-active text-center"> 3 </button>
                </div>
                <div class="col-11">
                    <span class="title-option">Modalidad de reto <span class="text-danger">*</span></span>
                    <ng-select [items]="modalidadData" bindLabel="modalidad" bindValue="idModalidad"
                        (change)="setReto()" [placeholder]="'Selecciona una opción'"
                        formControlName="modalidad_reto"></ng-select>
                    <br />
                </div>

                <!-- 4 -->
                <div class="col-1"><button _ngcontent-cen-c213="" class="step-button-active text-center"> 4 </button>
                </div>
                <div class="col-11">
                    <span class="title-option">Ajusta la descripción de reto <span class="text-danger">*</span></span>
                    <textarea class="form-control textArea text-secondary" type="text" id="descripcion_reto" rows="8"
                        formControlName="descripcion_reto" (change)="setReto()" required> </textarea>
                    <br />
                </div>

                <!-- 5 -->
                <div class="col-1"><button _ngcontent-cen-c213="" class="step-button-active text-center"> 5 </button>
                </div>
                <div class="col-11">
                    <span class="title-option">Ajusta el objetivo de aprendizaje del estudiante <span
                            class="text-danger">*</span></span>
                    <textarea class="form-control text-secondary" type="text" id="objetivo_aprendizaje" rows="5"
                        formControlName="objetivo_aprendizaje" (change)="setReto()" required> </textarea>
                    <br />
                </div>

            </div>


        </form>

        <div *ngIf="status != 'formalizacion' || clavePerfil == 'socio'">
            <div class="title-option mt-3">Nombre del reto:</div>
            <div class="txt-option">{{ lbl_nombre_reto }}</div>
            <br />
            <div class="title-option">Descripción del reto:</div>
            <div class="txt-option">{{ lbl_descripcion_reto }}</div>
            <br />
            <div class="title-option">Objetivo de Aprendizaje del estudiante:</div>
            <div class="txt-option">{{ lbl_objetivo_reto }}</div>
            <br />

        </div>
    </div>
    <!-- Entregables -->
    <app-reto-entregables *ngIf="documentarReto || clavePerfil === 'socio'" [idReto]="idReto" [status]='status'
        (entregables)="entregables($event)"></app-reto-entregables>

    <!-- Casos de exito -->
    <div class="col-12 my-3" *ngIf="(status=='evaluacion' || status=='concluido') && mostrarCE">
        <button (click)="postular()" class="yell_button">Postular como caso de éxito</button>
        <app-postular-caso *ngIf="postularCaso" [idReto]="idReto" [oReto]="oReto" [status]='status'></app-postular-caso>
        <app-typeform idForm="xog3Xwoi" *ngIf="RecienPostulado"></app-typeform>
    </div>

    <!-- Compromisos -->
    <app-reto-compromisos [idReto]="idReto" [status]='status' *ngIf="documentarReto || clavePerfil === 'socio'"
        (compromisos)="compromisos($event)"></app-reto-compromisos>

    <!-- Recursos -->
    <app-reto-recursos [idReto]="idReto" [status]='status' *ngIf="documentarReto || clavePerfil === 'socio'"
        (recursos)="recursos($event)"></app-reto-recursos>

    <div class="d-none">
        <div class="subtitlecard textblue">SOBRE EL RETO IMPLEMENTADO </div>
        <div class="section">
            <div class="text-secondary"> Registra el reto implementado. Hemos cargado el reto semilla para que te sirva
                como
                base.
                Recuerda
                que esta información es la que visualizará el Socio Formador y la que sirve de insumo para construir la
                carta de
                colaboración.
            </div>
        </div>
    </div>
