<div class="flex-fill d-flex align-items-center">
  <img alt="Usuario"
    *ngIf="elementsImageTitle.image"
    class="img_usr"
    src="{{ elementsImageTitle.image || imagenPerfil }}"
  />
  <div>
    <div class="lmt_text">
      <a *ngIf="elementsImageTitle.link" href="{{ elementsImageTitle.link }}" title="{{ elementsImageTitle.title }}">
        {{ title_40 }}
      </a>
      <span *ngIf="!elementsImageTitle.link" title="{{ elementsImageTitle.title}}">{{ title_40 }}</span>
    </div>
    <div class="subtitle" title="{{ elementsImageTitle.subtitle }}">{{ subtitle_40 }}</div>
    <div class="subtitle" title="{{ elementsImageTitle.description }}">{{ elementsImageTitle.description }}</div>
  </div>
</div>
