<app-header-buscador [showSearchBar]="false"></app-header-buscador>

<div class="sec_ad" *featureFlag="'F2_5_HU58'">

  <app-btn-regresar></app-btn-regresar>
  <app-title-show-more [showMoreObject]="title"></app-title-show-more>


  <div id="sec_tab" class="nav nav-pills d-flex flex-md-nowrap flex-wrap" role="presentation">
    <div class="active" data-bs-toggle="pill" data-bs-target="#tab-socios-formadores-registrado"
      aria-controls="tab-socios-formadores-aprobar" prm="re_ac" role="tab" type="button" aria-selected="true"
      [innerHTML]="'problematicas.enviadas' | translate ">

    </div>
    <div data-bs-toggle="pill" data-bs-target="#tab-socios-formadores-revision"
      aria-controls="tab-socios-formadores-aprobar" prm="re_ac" role="tab" type="button" aria-selected="true"
      [innerHTML]="'problematicas.revision' | translate ">

    </div>
    <div data-bs-toggle="pill" data-bs-target="#tab-socios-formadores-aprobar"
      aria-controls="tab-socios-formadores-aprobar" prm="re_ac" role="tab" type="button" aria-selected="true"
      [innerHTML]="'problematicas.rechazadas' | translate">

    </div>
    <div prm="re_hs" data-bs-toggle="pill" data-bs-target="#tab-socios-formadores-rechazadas"
      aria-controls="tab-socios-formadores-rechazadas" class="" role="tab" type="button" aria-selected="false"
      [innerHTML]="'problematicas.solicitudes_aprobadas' | translate">
    </div>


  </div>

  <div class="tab-content" id="pills-tabContent">
    <div class="tab-pane fade show active" id="tab-socios-formadores-registrado" role="tabpanel"
      aria-labelledby="tab-socios-formadores-aprobar-tab">
      <app-revision [estatusType]="'registrado'"></app-revision>
    </div>
    <div class="tab-pane fade" id="tab-socios-formadores-revision" role="tabpanel"
      aria-labelledby="tab-socios-formadores-aprobar-tab">
      <app-revision [estatusType]="'revision'"></app-revision>
    </div>
    <div class="tab-pane fade" id="tab-socios-formadores-aprobar" role="tabpanel"
      aria-labelledby="tab-socios-formadores-aprobar-tab">
      <app-revision [estatusType]="'rechazado'"></app-revision>
    </div>
    <div class="tab-pane fade" id="tab-socios-formadores-rechazadas" role="tabpanel"
      aria-labelledby="tab-socios-formadores-rechazadas-tab">
      <app-revision [estatusType]="'aprobado'"></app-revision>
    </div>
  </div>

</div>
