import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { AccountService } from '../Account/account.service';
import { IUser } from '@shared/Interface/user';
import { CookieService } from 'ngx-cookie-service';

import { ICasosExito, IResultadoReto, IRetoExitosoResponse } from '@shared/Interface/ICasosExito';
import { IRetoResponse } from '@shared/Interface/IReto.interface';


@Injectable({
  providedIn: 'root'
})
export class CasosDeExitoService {
  //** Esta clase maneja todo lo relacionado con el usuario en SociosFormadores */
  baseUrl = environment.apiUrl;
  currentUser!: IUser;

  queryFilter$ = new EventEmitter();

  constructor(
    private http: HttpClient,
    private accountService: AccountService,
    private cookieService: CookieService
  ) {}

  /** Api que obtiene los casos de éxito por estatus
   *
   * @param estatus Estatus de los casos de éxito si no se pasa trae todos los estatus
   * @returns lista de objetos tipo 
   */
  getCasosEstatus(estatus: any): Observable<any[]> {
    return this.http.get<any[]>(
      this.baseUrl + 'administracion/casosExito?estatus=' + estatus
    );
  }
  getCasosById(id:number): Observable<ICasosExito> {
    return this.http.get<ICasosExito>(
      this.baseUrl + 'administracion/casosExito/' + id
    );
  }
  putCasos(body: any): Observable<any> {
    return this.http.put<any>(this.baseUrl + 'administracion/casosExito', body);
  }
  postCasos(body: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'administracion/casosExito', body);
  }

  deleteCasos(id:number){
    return this.http.delete(`${this.baseUrl}administracion/casosExito/${id}`);
  }

  postResultadoReto(body){
    return this.http.post<any>(`${this.baseUrl}retos/resultados`,body);
  }

  putResultadoReto(body){
    return this.http.put<any>(`${this.baseUrl}retos/resultados`,body);
  }

  getResultadoReto(idReto: number): Observable<IResultadoReto>{
    return  this.http.get<IResultadoReto>(`${this.baseUrl}retos/${idReto}/resultados`);
  }
  
  getnRetosExitososxSocio(idSocioFormador: number, hits?: number ):Observable<IRetoExitosoResponse[]>{
    hits = hits ?? 5;
    return this.http.get<IRetoExitosoResponse[]>(`${this.baseUrl}socios/${idSocioFormador}/retosExitosos?hits=${hits}`);
  }

  getnRetosExitososxReto(idRetoMaestro: number, hits?: number ):Observable<IRetoExitosoResponse[]>{
    hits = hits ?? 5;
    return this.http.get<IRetoExitosoResponse[]>(`${this.baseUrl}retos/retosMaestros/${idRetoMaestro}/exitos?hits=${hits}&verUnidadFormacion=true`);
  }

  getnSociosExitosos(idSocioFormador: number, hits?: number ):Observable<IRetoExitosoResponse[]>{
    throw new Error('No implementado');
    // hits = hits ?? 5;
    // return this.http.get<IRetoExitosoResponse[]>(`${this.baseUrl}socios/${idSocioFormador}/retosExitosos?hits=${hits}`);
  }
  getLogosHome(): Observable<ICasosExito> {
    return this.http.get<ICasosExito>(
      this.baseUrl + 'socios/verLogoHome'
    );
  }

  getCasoExitoPoridReto(idReto: number):Observable<ICasosExito>{
    return this.http.get<ICasosExito>(`${this.baseUrl}administracion/casosExito/retos/${idReto}`);
  }

}
