import { Component, Input, OnInit } from '@angular/core';
import { AccountService } from '@shared/service/Account/account.service';



@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  @Input()
  ShowImage: boolean=true;

  isLogged:boolean=false;
  constructor(private accountService: AccountService) { }

  ngOnInit(): void {
    this.isLogged = this.accountService.isLoggeed();
    
  }

}
