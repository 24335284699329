import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import {
  ICampus,
  IEscuela,
  IPeriodo,
} from "@shared/Interface/ICatalogos.interface";
import { IRetosCVDPResponse } from "@shared/Interface/IRetosCVDPResponse";
import { DashboardService } from "@shared/service/Dashboard/dashboard.service";
import { UsuariosService } from "@shared/service/Usuarios/usuarios.service";
import { CatalogosService } from "@shared/service/Catalogos/catalogos.service";
import { tap, lastValueFrom } from "rxjs";
import {
  COLS_LISTADO_RETOS,
  COLS_LISTADO_RETOS_CONC,
  COLS_LISTADO_RETOS_CURS,
  COLS_LISTADO_RETOS_FORM,
} from "@pages/dashboard/cvdp-dashboard/cvdp-dashboard.columns";
import { InicioService } from "@shared/service/Inicio/inicio.service";
import Utils from "@shared/helpers/utils";

@Component({
  selector: "app-retos-cvdp",
  templateUrl: "./mis-retos-cvdp.component.html",
  styleUrls: ["./mis-retos-cvdp.component.css"],
})
export class MisRetosCvdpComponent implements OnInit {
  buscadorText: string = "";
  sidebarExpanded = false;
  lang: string = "es";

  title = {
    title: "Mis Retos",
  };

  //* Documentacion de retos
  listadoRetosFormalizacionColumns = COLS_LISTADO_RETOS_FORM;
  listadoRetosFormalizacionData: any[] = [];
  listadoRetosFormalizacionDataPage: any[] = [];

  listadoRetosCursoColumns = COLS_LISTADO_RETOS_CURS;
  listadoRetosCursoData: any[] = [];
  listadoRetosCursoDataPage: any[] = [];

  listadoRetosEvaluacionColumns = COLS_LISTADO_RETOS_CURS;
  listadoRetosEvaluacionData: any[] = [];
  listadoRetosEvaluacionDataPage: any[] = [];

  listadoRetosConcluidoColumns = COLS_LISTADO_RETOS_CONC;
  listadoRetosConcluidoData: any[] = [];
  listadoRetosConcluidoDataPage: any[] = [];

  mostrarRetos: boolean = false;
  status: string = "formalizacion";

  backgroundTable = "#F6F6F6";

  // ** Listado de Retos  (Activos / Mi historial de retos)
  listadoRetosColumns = COLS_LISTADO_RETOS;

  listadoRetosActivosDataPage: any = [];
  listadoRetosTodosDataPage: any = [];

  campusData: ICampus[] = [];
  escuelaData: IEscuela[] = [];
  periodoData: IPeriodo[] = [];

  filter: any = {
    query: "",
    campus: 0,
    escuela: 0,
    periodo: 0,
  };

  pagina: number = 0;
  hits: number = 0;

  localdb: any = [];

  filterChanged: boolean = false;

  ngOnInit(): void {
    this.lang = localStorage.getItem("lang") ?? "es";
    this.loadCatalogos();
    this.loadData();
    if (
      ["cvdp", "cvdp_admin"].includes(
        this.usuarioService.getCurrentUserData().clavePerfil
      )
    ) {
      this.title.title = "Retos";
    }
  }

  // Se agrega función compatible con el buscador MainLayoutBuscador
  async buscadorPress(keyWord: any) {
    // Aquí iría lo que el padre tenga que hacer

    localStorage.removeItem("localdb");
    localStorage.removeItem("localdbFilters");

    if (
      keyWord !== null &&
      keyWord !== undefined &&
      keyWord.toString().trim() !== ""
    ) {
      this.filter.query = keyWord;
    } else {
      this.filter.query = "";
    }
    this.loadData();
  }

  changeLang(lang: string) {
    this.lang = lang;
    localStorage.setItem("lang", lang);
    window.location.reload();
  }

  constructor(
    private dashboardService: DashboardService,
    private changeDetector: ChangeDetectorRef,
    private usuarioService: UsuariosService,
    private catalogosService: CatalogosService,
    private inicioService: InicioService
  ) {
    //this.cleanFilters();
  }

  loadCatalogos() {
    this.catalogosService
      .getCampus()
      .pipe(tap((resultado: ICampus[]) => (this.campusData = resultado)))
      .subscribe();

    this.catalogosService
      .getEscuelas()
      .pipe(tap((resultado: IEscuela[]) => (this.escuelaData = resultado)))
      .subscribe();

    this.catalogosService
      .getPeriodo(false)
      .pipe(tap((resultado: IPeriodo[]) => (this.periodoData = resultado)))
      .subscribe();
  }

  getValueCatalogo(obj: any, index: string, id: number, indexValue: string) {
    let obje = obj.filter((x: any) => x[index] === id).pop();
    if (obje) {
      return obje[indexValue];
    } else {
      return null;
    }
  }

  addFilter(type: string, id: number) {
    localStorage.removeItem('localdb');
    localStorage.removeItem('localdbFilters');
    this.setFilter(type, id);
    this.loadData();
  }

  setFilter(type: string, id: number) {
    this.filter[type] = id;
    this.filterChanged = true;
  }

  cleanFilters() {
    localStorage.removeItem('localdb');
    localStorage.removeItem('localdbFilters');
    this.filterChanged = false;

    this.filter = {
      query: "",
      campus: 0,
      escuela: 0,
      periodo: 0,
      idPeriodoSemanal: 0,
      idPeriodoSemestral: 0
    };
    this.loadData();
  }

  async loadData() {
    let userStore = this.usuarioService.getCurrentUserData();

    this.listadoRetosFormalizacionDataPage = [];
    this.listadoRetosCursoDataPage = [];
    this.listadoRetosEvaluacionDataPage = [];
    this.listadoRetosConcluidoDataPage = [];

    this.listadoRetosFormalizacionData = [];
    this.listadoRetosCursoData = [];
    this.listadoRetosEvaluacionData = [];
    this.listadoRetosConcluidoData = [];

    let idUsuario =
      userStore.clavePerfil === "cvdp_admin" ? 0 : userStore.idUsuario;

    let responseRetos;

    this.chekfilters();
    let tmp = localStorage.getItem("localdb");
    if (tmp) {
      this.localdb = JSON.parse(tmp);
      if (this.localdb) {
        this.listadoRetosFormalizacionData = this.localdb.formalizacion;
        this.listadoRetosCursoData = this.localdb["en-curso"];
        this.listadoRetosEvaluacionData = this.localdb.evaluacion;
        this.listadoRetosConcluidoData = this.localdb.concluido;

      }
    } else {
      // Documentacion
      try {
        responseRetos = await lastValueFrom(
          this.dashboardService.getListadoRetosByCDVP(
            idUsuario,
            "formalizacion",
            this.filter.campus,
            this.filter.escuela,
            this.filter.periodo,
            this.pagina,
            this.hits,
            this.filter.query,
            this.filter.idPeriodoSemanal,
            this.filter.idPeriodoSemestral
          )
        );
        this.listadoRetosFormalizacionData = this.getListadoRetosFormalizacion(
          responseRetos
        );

      } catch (err) {
        console.log(err);
      }

      try {
        responseRetos = [];
        responseRetos = await lastValueFrom(
          this.dashboardService.getListadoRetosByCDVP(
            idUsuario,
            "en-curso",
            this.filter.campus,
            this.filter.escuela,
            this.filter.periodo,
            this.pagina,
            this.hits,
            this.filter.query,
            this.filter.idPeriodoSemanal,
            this.filter.idPeriodoSemestral
          )
        );
        this.listadoRetosCursoData = this.getListadoRetosCursoyEval(
          responseRetos, "en-curso"
        );
      } catch (err) {
        console.log(err);
      }

      try {
        responseRetos = [];
        responseRetos = await lastValueFrom(
          this.dashboardService.getListadoRetosByCDVP(
            idUsuario,
            "evaluacion",
            this.filter.campus,
            this.filter.escuela,
            this.filter.periodo,
            this.pagina,
            this.hits,
            this.filter.query,
            this.filter.idPeriodoSemanal,
            this.filter.idPeriodoSemestral
          )
        );
        this.listadoRetosEvaluacionData = this.getListadoRetosCursoyEval(
          responseRetos, "evaluacion"
        );
      } catch (err) {
        console.log(err);
      }

      try {
        responseRetos = [];
        responseRetos = await lastValueFrom(
          this.dashboardService.getListadoRetosByCDVP(
            idUsuario,
            "concluido",
            this.filter.campus,
            this.filter.escuela,
            this.filter.periodo,
            this.pagina,
            this.hits,
            this.filter.query,
            this.filter.idPeriodoSemanal,
            this.filter.idPeriodoSemestral
          )
        );
        this.listadoRetosConcluidoData = this.getListadoRetosConcluido(
          responseRetos
        );
      } catch (err) {
        console.log(err);
      }

      this.saveFilters();
      if ( this.filterChanged){
        let socioFormadorList = {
          "formalizacion" : this.listadoRetosFormalizacionData,
          "en-curso" : this.listadoRetosCursoData,
          "evaluacion" : this.listadoRetosEvaluacionData,
          "concluido" : this.listadoRetosConcluidoData
        };
        localStorage.setItem('localdb',JSON.stringify(socioFormadorList));
      }
    }

    this.showPaginationDataFormalizacion(
      this.listadoRetosFormalizacionData.slice(0, 10)
    );

  }

  getListadoRetosFormalizacion(resultado) {
    let perfil = this.getPerfil();
    return resultado.map((solicitud: IRetosCVDPResponse) => ({
      idReto: solicitud.idReto,
      fechaRegistro: solicitud.fechaInicio,
      reto: {
        title: solicitud.reto,
        subtitle: solicitud.unidadFormativa,
        description: solicitud.escuela,
        link: `/${perfil}/formalizacion?reto=${solicitud.idReto}`,
      },
      nombreSocioFormador: {
        title: solicitud.nombreSocioFormador,
        subtitle: solicitud.rfc,
      },
      duracion: solicitud.semanas ?? "-",
      periodoSemanal: solicitud.periodoSemanal,
      periodoSemestral: solicitud.periodoSemestral,
      imageTitle: {
        title: solicitud.campus ?? "",
        subtitle: solicitud.escuela,
        description: solicitud.crn,
      },
      proyecto: solicitud.alcance,
      claveEstatusSolicitud: {
        textCol: "Ver",
        statusType: "primary",
        link: `/${perfil}/formalizacion?reto=${solicitud.idReto}`,
      },
    }));
  }

  getListadoRetosCursoyEval(resultado, estatus) {
    let perfil = this.getPerfil();
    return resultado.map((solicitud: IRetosCVDPResponse) => ({
      idReto: solicitud.idReto,
      fechaRegistro: solicitud.fechaInicio,
      reto: {
        title: solicitud.reto,
        subtitle: solicitud.unidadFormativa,
        description: solicitud.escuela,
        link: `/${perfil}/${estatus}?reto=${solicitud.idReto}`,
      },
      duracion: solicitud.semanas ?? "-",
      periodoSemanal: solicitud.periodoSemanal,
      periodoSemestral: solicitud.periodoSemestral,
      imageTitle: {
        title: solicitud.campus ?? "",
        subtitle: solicitud.escuela,
        description: solicitud.crn,
      },
      nombreSocioFormador: {
        title: solicitud.academico,
        subtitle: solicitud.correoAcademico,
      },
      claveEstatusSolicitud: {
        textCol: "Ver",
        statusType: "primary",
        link: `/${perfil}/${estatus}?reto=${solicitud.idReto}`,
      },
    }));
  }

  getListadoRetosConcluido(resultado) {
    let perfil = this.getPerfil();
    return resultado.map((solicitud: IRetosCVDPResponse) => ({
      idReto: solicitud.idReto,
      fechaRegistro: solicitud.fechaInicio,
      reto: {
        title: solicitud.reto,
        subtitle: solicitud.unidadFormativa,
        description: solicitud.escuela,
        link: `/${perfil}/concluido?reto=${solicitud.idReto}`,
      },
      duracion: solicitud.semanas ?? "-",
      periodoSemanal: solicitud.periodoSemanal,
      periodoSemestral: solicitud.periodoSemestral,
      imageTitle: {
        title: solicitud.campus ?? "",
        subtitle: solicitud.escuela,
        description: solicitud.crn,
      },
      nombreSocioFormador: {
        title: solicitud.nombreSocioFormador,
        subtitle: solicitud.rfc,
      },
      inscritos: solicitud.inscritos,
      claveEstatusSolicitud: {
        textCol: "Ver",
        statusType: "primary",
        link: `/${perfil}/concluido?reto=${solicitud.idReto}`,
      },
    }));
  }

  getListadoRetos(resultado) {
    return resultado.map((solicitud: IRetosCVDPResponse) => ({
      idReto: solicitud.idReto,
      fechaRegistro: solicitud.fechaInicio,
      reto: {
        title: solicitud.reto,
        subtitle: solicitud.unidadFormativa,
        description: solicitud.escuela,
        link: `/socio/pagina-retos-resumen?reto=${solicitud.idReto}`,
      },
      nombreSocioFormador: {
        title: solicitud.nombreSocioFormador,
        subtitle: solicitud.rfc,
      },
      contactPerson: {
        title: solicitud.nombreContacto ?? "-",
        subtitle: solicitud.correoContacto ?? "-",
      },
      imageTitle: {
        title: solicitud.academico,
        subtitle: solicitud.correoAcademico,
      },
      periodoSemestral: solicitud.periodoSemestral,
      periodoSemanal: solicitud.periodoSemanal,
      claveEstatusSolicitud: {
        textCol: "Ver",
        statusType: "primary",
        link: `/socio/pagina-retos-resumen?reto=${solicitud.idReto}`,
      },
    }));
  }

  showPaginationData(objData: any) {
    if (objData.length) {
      this.listadoRetosActivosDataPage = objData;
    }
  }

  showPaginationDataHistorial(objData: any) {
    if (objData.length) {
      this.listadoRetosTodosDataPage = objData;
    }
  }

  changeViewStatus(status) {
    this.status = status;
  }

  showPaginationDataFormalizacion(objData: any) {
    if (objData.length) {
      this.listadoRetosFormalizacionDataPage = objData;
    }
  }

  showPaginationDataCurso(objData: any) {
    if (objData.length) {
      this.listadoRetosCursoDataPage = objData;
    }
  }

  showPaginationDataEvaluacion(objData: any) {
    if (objData.length) {
      this.listadoRetosEvaluacionDataPage = objData;
    }
  }

  showPaginationDataConcluido(objData: any) {
    if (objData.length) {
      this.listadoRetosConcluidoDataPage = objData;
    }
  }


  saveFilters(){
    let filters = {
        query:this.filter.query,
        campus:this.filter.campus,
        escuela:this.filter.escuela,
        periodo:this.filter.periodo,
        idPeriodoSemanal:this.filter.idPeriodoSemanal,
        idPeriodoSemestral:this.filter.idPeriodoSemestral,

    };
    localStorage.setItem('localdbFilters',JSON.stringify(filters));
    this.inicioService.changeFilterQuery(this.filter.query);
  }

  chekfilters(){
    let tmp1 = localStorage.getItem('localdbFilters');
    let ret=false;
    if(tmp1){
      let tmp = JSON.parse(tmp1);

        this.filter.query = tmp.query;
        this.filter.campus = tmp.campus;
        if (this.filter.campus > 0)
           this.setFilter('campus', this.filter.campus);
        this.filter.escuela = tmp.escuela;
        if (this.filter.escuela > 0)
          this.setFilter('escuela', this.filter.escuela);
        this.filter.periodo = tmp.periodo;
        if (this.filter.periodo > 0 )
            this.setFilter('periodo', this.filter.periodo);
        this.filter.idPeriodoSemanal = tmp.idPeriodoSemanal;
        if (this.filter.idPeriodoSemanal > 0 )
           this.setFilter('periodoSemanal', this.filter.idPeriodoSemanal);
        this.filter.idPeriodoSemestral = tmp.idPeriodoSemestral;
        if (this.filter.idPeriodoSemestral > 0 )
             this.setFilter('periodoSemestral', this.filter.idPeriodoSemestral);


        ret = true;
    }
    return ret;
  }

  getPerfil(){

    return Utils.getRouteFromProfile(this.usuarioService.getCurrentUserData().clavePerfil);
  }
}
