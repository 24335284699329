import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-convenios-sf',
  templateUrl: './convenios-sf.component.html',
  styleUrls: ['./convenios-sf.component.css']
})
export class ConveniosSFComponent implements OnInit {


  formularioForm!: UntypedFormGroup;
  
  constructor(
    private readonly fb: UntypedFormBuilder,
  ) { }

  ngOnInit(): void {

    this.formularioForm = this.initForm();


  }

  initForm():UntypedFormGroup{
    return this.fb.group({
      fechaInicio: ["", [Validators.required, Date]],
      fechaFin: ["", [Validators.required, Date]],
    });

  }


  onSubmit(): void {
    console.log('Enviado');
  }
}
