import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { IRecursosVinculacion } from '@shared/Interface/IRecursosVinculacion';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { UsuariosService } from '@shared/service/Usuarios/usuarios.service';
import { environment } from 'environments/environment';
import { PaginadorLibsComponent } from '@shared/libs/paginador.component';
import { ContactosService } from '@shared/service/Contactos/contactos.service';
import { lastValueFrom } from 'rxjs';
import { RecursosService } from '@shared/service/Recursos_de_vinculacion/recursos.service';

@Component({
  selector: 'app-recursos-de-vinculacion',
  templateUrl: './recursos-de-vinculacion.component.html',
  styleUrls: ['./recursos-de-vinculacion.component.css']
})
export class RecursosDeVinculacionComponent implements OnInit {
  data: IRecursosVinculacion[]
  title: string = 'Recursos de Viculacion Administraciòn'
  sidebarExpanded = false;
  lang: string = "es";
  numberPerPage = environment.RegistrosPagina;
  pages: number[] = [];
  paginaActual = 1;
  numeroPaginas = 0;
  numeroPaginasTo = 0;
  vinculacionList: IRecursosVinculacion[] = [];
  vinculacionListTotal: [] | any = [];
  elementsTo: IRecursosVinculacion[] = [];
  elementsTotal: IRecursosVinculacion[] = [];
  paginaActualMasResultados: number = 0;
  ordenamientoBy: string = 'nombre'; //ajustar al parametro que recibe la api "nombre/socio"
  textOrdenarPor: string = 'gestion_usuarios.ordenar_por';

  queryFilter: string = '';
  constructor(
    private toaster: ToastrService,
    private router: Router,
    private usuarioService: UsuariosService,
    private _usuariosService: UsuariosService,
    private _recursosService: RecursosService,
    private paginadorLibs: PaginadorLibsComponent,
    private contactosService: ContactosService,
    private changeDetector: ChangeDetectorRef
  ) { }

  async ngOnInit(): Promise<void> {
    this.lang = localStorage.getItem("lang") || "es";
    await this.getVinculacion(this.ordenamientoBy);
    this._usuariosService.queryFilter$.subscribe(async text => {
      this.queryFilter = text;
      await this.getVinculacion(this.ordenamientoBy);
    });

  }

  changeLang(lang: string) {
    this.lang = lang;
    localStorage.setItem("lang", lang);
    window.location.reload();
  }

  filter = { query: "" };



  async buscadorPress(keyWord: any) {
    if (
      keyWord !== null &&
      keyWord !== undefined &&
      keyWord.toString().trim() !== ""
    ) {

      const filterByValue = (arr = [], query = '') => {
        const reg = new RegExp(query,'i');
        return arr.filter((item)=>{
           let flag = false;
           for(const prop in item){
              if(reg.test(item[prop])){
                 flag = true;
              }
           };
           return flag;
        });

     };

     this.vinculacionList=filterByValue(this.vinculacionListTotal, keyWord)


    } else {
      this.vinculacionList=this.vinculacionListTotal
    }

  }

  async getVinculacion(estatusO: string) {
    try {
      this.vinculacionListTotal = [];
      this.vinculacionList = [];
      let estatus = 'activo'

      let response = <any>await lastValueFrom(this._recursosService
        .getRecursosEstatus(estatus));

      if (response != null && response != undefined && response.length > 0) {

        if (this.queryFilter != '') {
          let vinculaOrdena: IRecursosVinculacion[] = response

          vinculaOrdena.sort((a, b) => a.tituloVinculacion.toLowerCase().localeCompare(b.tituloVinculacion.toLowerCase()))
          this.vinculacionListTotal = vinculaOrdena;
        }
        else {
          this.vinculacionListTotal = response;
        }
        this.showPaginationData(this.vinculacionListTotal);
      }
    } catch (error) {
      console.log('Error al realizar la peticion', error);
    }
  }

  obtenerMasResultados() {
    this.paginaActualMasResultados += 1;
    this.elementsTo = this.paginadorLibs.obtenerMasResultados(
      this.paginaActualMasResultados,
      this.numberPerPage,
      this.elementsTotal
    );
  }

  async orderBy(ordenamiento: string) {
    let vinculaOrdena: IRecursosVinculacion[] = this.vinculacionListTotal

    vinculaOrdena.sort((a, b) => a.tituloVinculacion.toLowerCase().localeCompare(b.tituloVinculacion.toLowerCase()))
    this.queryFilter = 'Ordenar A-Z'

    this.ordenamientoBy = ordenamiento;
    await this.getVinculacion(ordenamiento);
  }
  crear() {
    const accion = 'crearVinculacion'
    this.router.navigate([`/registrar-recursos-de-vinculacion/${accion}`]);
  }



  editarVinculacion(id: number, ) {
    const accion = 'editVinculacion'
    this.router.navigate([`/registrar-recursos-de-vinculacion/${accion}/${id}`]);
  }

  eliminarVinculacion(id: number, ) {
    this._recursosService.deleteRecurso(id)
      .subscribe({
        next:(resOK) => {
        this.toaster.success('Eliminación exitosa!');
          this.ngOnInit();

      },
      error:(error) => {
        console.log(error)
        this.toaster.success('Ocurrió un error!');
          this.ngOnInit();
      }
    });
  }


  showPaginationData(objData: any) {
    if (objData.length) {

      this.vinculacionList = objData;

      this.changeDetector.detectChanges();
    }
  }

  restablecer() {
    window.location.reload();
  }

}
