import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import {
  FormArray,
  FormControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import {
  IIndustrias,
  ITamanoEmpresa,
} from "@shared/Interface/ICatalogo.interface";
import { tap } from "rxjs/operators";
import { CatalogosService } from "@shared/service/Catalogos/catalogos.service";
import {
  ICampus,
  ICodigoPostalResponse,
  IColonia,
  IPais,
} from "@shared/Interface/ICatalogos.interface";
import { IRelacionesTecResponse } from "@shared/Interface/IRelacionesTecResponse";
import { SociosFormadoresService } from "@shared/service/Socios_Formadores/socios-formadores.service";
import Swal from "sweetalert2";
import { IUser } from "@shared/Interface/user";
import { UsuariosService } from "@shared/service/Usuarios/usuarios.service";
import { ISocioFormadorResponse } from "@shared/Interface/ISocioFormadorResponse";
import { AzureBlobStorageService } from "@shared/service/AzureBlobStorage.Service";
import { environment } from "environments/environment";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { IEstadosResponse } from "@shared/Interface/IEstadosResponse";
import { lastValueFrom } from "rxjs";
import Utils from "@shared/helpers/utils";

@Component({
  selector: "app-datos-empresa-tab",
  templateUrl: "./datos-empresa-tab.component.html",
  styleUrls: ["./datos-empresa-tab.component.css"],
})
export class DatosEmpresaTabComponent implements OnInit {
  @Output() socioSeleccionado: EventEmitter<number> = new EventEmitter<
    number
  >();
  @Output() socioCreado: EventEmitter<number> = new EventEmitter<number>();

  Industrias!: IIndustrias[];
  TamanoEmpresa!: ITamanoEmpresa[];
  Campus!: ICampus[];
  RelacionesTec!: IRelacionesTecResponse[];
  RelacionesTecLoaded: IRelacionesTecResponse[];
  Estados: IEstadosResponse[];
  Paises!: IPais[];
  Colonias!: IColonia[];

  formularioForm!: UntypedFormGroup;
  sectorEmpresa!: number;
  presenciaEmpresa!: number;

  userStore: IUser;

  img: string =
    "https://swagger-tec-socios-formadores.s3.amazonaws.com/logo_sf_sin.jpg";

  buttons = [
    {
      url: "",
      text: "Editar foto",
    },
  ];

  userData: any;

  idSocioFormador: number;

  cuentaActiva: boolean = true;

  datosEmpresaData: ISocioFormadorResponse;

  procesandoBusqueda: boolean = false;

  esNuevoSocioFormador: boolean = false;

  cpMexico: boolean = true;

  lblbtnAccion: string = "Actualizar";
  lblbtnCancelar: string = "";

  fieldSetEnable = true;
  clavePerfil: string;
  updated: boolean = false;

  constructor(
    private readonly fb: UntypedFormBuilder,
    private catalogosSvc: CatalogosService,
    private socioFormadorService: SociosFormadoresService,
    private usuarioService: UsuariosService,
    private router: Router,
    private azureBlobStorage: AzureBlobStorageService,
    private sociosFormadores: SociosFormadoresService,
    private usuariosService: UsuariosService,
    private toastr: ToastrService
  ) {}

  cargarCatalogos() {
    /** Catalogos
     * Industrias
     * Tamaño de empresa
     * Campus
     * Relaciones Tec
     */
    // INdustrias
    this.catalogosSvc
      .getIndustrias()
      .pipe(
        tap(
          (resultado: IIndustrias[]) =>
            (this.Industrias = resultado.filter((x) => !["Todos","Todas"].includes(x.industria) ))
        )
      )
      .subscribe();

    // Tamano Empresa
    this.catalogosSvc
      .getTamanoEmpresa()
      .pipe(
        tap((resultado: ITamanoEmpresa[]) =>
              (this.TamanoEmpresa = resultado.filter((x)=> !["Todas","Todos"].includes(x.tamanoEmpresa))))
      )
      .subscribe();

    //Campus
    this.catalogosSvc
      .getCampus()
      .pipe(tap((resultado: ICampus[]) => (this.Campus = resultado)))
      .subscribe();

    // Relaciones Tec
    this.catalogosSvc
      .getRelacionesTec()
      .pipe(
        tap(
          (resultado: IRelacionesTecResponse[]) =>
            (this.RelacionesTec = resultado)
        )
      )
      .subscribe();

    // Estados
    this.catalogosSvc
      .getEstados()
      .pipe(tap((resultado: IEstadosResponse[]) => (this.Estados = resultado)))
      .subscribe();

    //Paises
    this.catalogosSvc
      .getPaises()
      .pipe(tap((resultado: IPais[]) => (this.Paises = resultado)))
      .subscribe();
  }

  async ngOnInit() {
    this.formularioForm = this.initForm();
    // * Obtener el id del socio formador *

    // si viene del banco, evaluamos nuevo o editar, sino tomamos sesion
    if (sessionStorage.getItem("idSocioFormador_banco_b") === "true") {
      this.idSocioFormador = Number(
        sessionStorage.getItem("idSocioFormador_banco")
      );
      this.socioSeleccionado.emit(this.idSocioFormador);
    } else {
      this.userStore = this.usuarioService.getCurrentUserData();
      this.idSocioFormador = this.userStore.idSocioFormador;
    }

    if (this.idSocioFormador != 0) {
      await this.getInfoSocioFormador();
    } else {
      this.lblbtnAccion = "Crear";
      this.lblbtnCancelar = "Cancelar";
    }

    this.cargarCatalogos();

    this.clavePerfil = this.usuarioService.getCurrentUserData().clavePerfil;
    if(this.clavePerfil != 'socio'){
      this.formularioForm.get('acercamiento_empresa')?.disable();
    }

  }

  patchFormValues(resOK) {
    this.formularioForm.patchValue({
      rfc_empresa: resOK.rfc,
      nombre_empresa: resOK.nombreSocioFormador,
      descripcion_empresa: resOK.empresa,
      grupo_empresa: resOK.grupoComercial,
      razonsocial_empresa: resOK.razonSocial,
      calle_empresa: resOK.calle,
      ciudad_empresa: resOK.ciudad,
      cp_empresa: resOK.cp,
      estado_empresa: resOK.claveEstado,
      pais_empresa: resOK.pais == "México" ? "MEX" : resOK.pais,
      sector_empresa: resOK.idSector,
      industria_empresa: resOK.idIndustria == 0 ? "" : resOK.idIndustria,
      tamanio_empresa: resOK.idTamano == 0 ? "" : resOK.idTamano,
      presencia_empresa: [resOK.idPresencia],
      web_empresa: resOK.paginaWeb,
      linkedin_empresa: resOK.linkedIn,
      twitter_empresa: resOK.twitter,
      facebook_empresa: resOK.facebook,
      instagram_empresa: resOK.instagram,
      campus_empresa: resOK.idCampus == 0 ? "" : resOK.idCampus,
      relaciones: resOK.relacionesTec,
      acercamiento_empresa: resOK.conoceRolSF,
    });
  }

  async getInfoSocioFormador() {
    try {
      let resOK = await lastValueFrom(
        this.socioFormadorService.getSociosFormadoresById(this.idSocioFormador)
      );

      this.datosEmpresaData = resOK;
      this.patchFormValues(resOK);

      this.datosEmpresaData.relacionesTec.forEach((x) =>
        this.setCheckboxItems(x, "relaciones")
      );

      this.cuentaActiva = this.datosEmpresaData.claveEstatus == "activo";

      if ((resOK.urlLogo ?? "") != "") {
        this.img = resOK.urlLogo + "?time=" + new Date().getMilliseconds();
      }

      // Obtener las colonias
      let resultado: ICodigoPostalResponse = await lastValueFrom(
        this.catalogosSvc.getInfoCp(resOK.cp)
      );
      this.Colonias = resultado.colonias;
      this.formularioForm.get("colonia_empresa")?.patchValue(resOK.colonia);

      this.lblbtnAccion = "Actualizar";
      this.lblbtnCancelar = "";
    } catch (error:any) {
      Utils.processErrorResponse(error);
    }
  }

  verifyChecked(value, tipoLista) {
    let ret = false;
    let item;
    if (!this.datosEmpresaData) return false;
    switch (tipoLista) {
      case "relacionesTec":
        item = this.datosEmpresaData.relacionesTec.find((x) => {
          return x == value;
        });
        if (item) {
          ret = true;
        }
        break;
      case "sector":
        ret = this.datosEmpresaData.idSector === value;
        break;
      case "presencia":
        ret = this.datosEmpresaData.idPresencia === value;
        break;
    }
    return ret;
  }

  onSubmit(): void {
    this.formularioForm.markAllAsTouched();
    if (this.formularioForm.valid) {
      let socioFormadorModel: any = {
        socioFormador: {
          idSocioFormador: this.idSocioFormador,
          nombreSocioFormador: this.formularioForm.get("nombre_empresa")?.value,
          razonSocial: this.formularioForm.get("razonsocial_empresa")?.value, //
          empresa: this.formularioForm.get("descripcion_empresa")?.value,
          grupoComercial: this.formularioForm.get("grupo_empresa")?.value,
          rfc: this.formularioForm.get("rfc_empresa")?.value,
          calle: this.formularioForm.get("calle_empresa")?.value,
          cp: this.formularioForm.get("cp_empresa")?.value, //
          claveEstado: this.formularioForm.get("estado_empresa")?.value, //
          ciudad: this.formularioForm.get("ciudad_empresa")?.value, //
          pais: this.formularioForm.get("pais_empresa")?.value, //
          idSector: this.sectorEmpresa,
          idTamano: parseInt(this.formularioForm.get("tamanio_empresa")?.value),
          paginaWeb: this.formularioForm.get("web_empresa")?.value,
          twitter: this.formularioForm.get("twitter_empresa")?.value, //
          linkedIn: this.formularioForm.get("linkedin_empresa")?.value, //
          facebook: this.formularioForm.get("facebook_empresa")?.value, //
          instagram: this.formularioForm.get("instagram_empresa")?.value, //
          idCampus: parseInt(this.formularioForm.get("campus_empresa")?.value),
          idTipoSocioFormador: 0, //
          empresaFamiliar: 0, //
          idIndustria: parseInt(
            this.formularioForm.get("industria_empresa")?.value
          ),
          idPresencia: this.presenciaEmpresa,
          relacionesTec: Utils.removeDuplicates(this.formularioForm.get("relaciones")?.value),
          colonia: this.formularioForm.get("colonia_empresa")?.value,
          idUsuarioModifica: this.usuarioService.getCurrentUserData().idUsuario,
          idUsuarioRegistro: this.usuarioService.getCurrentUserData().idUsuario,
          cvePerfilRegistro: this.usuarioService.getCurrentUserData()
            .clavePerfil,
          conoceRolSF: this.formularioForm.get("acercamiento_empresa")?.value,
        },
      };
      if (this.idSocioFormador == 0) {

        sessionStorage.setItem("sf_json", JSON.stringify(socioFormadorModel.socioFormador));
        Swal.fire({
          text: "Da de alta un Contacto principal.",
        });
        this.socioCreado.emit(1);

        // this.socioFormadorService
        //   .postSoloSocioFormador(socioFormadorModel.socioFormador)
        //   .subscribe({
        //     next: (resOK) => {
        //       Swal.fire({
        //         icon: "success",
        //         text: "Guardado con éxito.",
        //       });
        //       sessionStorage.setItem(
        //         "idSocioFormador_banco",
        //         JSON.stringify(resOK.idSocioFormador)
        //       );
        //       sessionStorage.setItem("idSocioFormador_banco_b", "true");
        //       sessionStorage.setItem(
        //         "idSocioFormador_academico",
        //         JSON.stringify(resOK.idSocioFormador)
        //       );
        //       this.socioCreado.emit(resOK.idSocioFormador);
        //     },
        //     error(err) {
        //       Swal.fire({
        //         title: "<strong>Error en tu solicitud</strong>",
        //         icon: "error",
        //         html: err.error.mensaje,
        //         showCloseButton: true,
        //         showConfirmButton: false,
        //       });
        //     },
        //   });

      } else {
        try {
          this.socioFormadorService
            .putSocios(socioFormadorModel.socioFormador)
            .subscribe({
              next: (resOK) => {
                Swal.fire({
                  icon: "success",
                  text: "Actualizado con éxito.",
                });
                this.updated= true;
                localStorage.removeItem('localdb');
              },
              error: (err) => {
                Swal.fire({
                  title: "<strong>Error en tu solicitud</strong>",
                  icon: "error",
                  html: err.error.mensaje,
                  showCloseButton: true,
                  showConfirmButton: false,
                });
                console.log("Error Solcitud: ", err);
              },
            });
        } catch (e) {
          console.log(e);
        }
      }
    } else {
      this.toastr.error("Llena los campos obligatorios");
    }
  }

  setCheckboxItems(value, typeData) {
    const selectedArray = this.formularioForm.controls[typeData] as FormArray;
    selectedArray.push(new FormControl(parseInt(value)));

  }

  onCheckboxChange(event: any, typeData: string) {
    const selectedArray = this.formularioForm.controls[typeData] as FormArray;
    const valueArray = selectedArray.controls.map((x) => x.value);
    if (event.target.checked) {
      const index = selectedArray.controls.findIndex(
        (x) => x.value === parseInt(event.target.value)
      );
      if(index<0){
        selectedArray.push(new FormControl(parseInt(event.target.value)));
      }
    } else {
      const index = selectedArray.controls.findIndex(
        (x) => x.value === parseInt(event.target.value)
      );
      selectedArray.removeAt(index);
    }
  }
  setSectorEmpresa(valor: number) {
    this.formularioForm.get("sector_empresa")?.patchValue(valor);
    this.sectorEmpresa = valor;
  }
  setPresenciaEmpresa(valor: number) {
    this.formularioForm.get("presencia_empresa")?.patchValue(valor);
    this.presenciaEmpresa = valor;
  }

  initForm(): UntypedFormGroup {
    return this.fb.group({
      rfc_empresa: ["", [Validators.required,Validators.pattern('^[a-zA-Z0-9&]+$')]],
      nombre_empresa: ["", [Validators.required, Validators.minLength(3)]],
      descripcion_empresa: ["", [Validators.required]],
      grupo_empresa: [""],
      razonsocial_empresa: ["", [Validators.required]],
      calle_empresa: ["", [Validators.required]],
      ciudad_empresa: ["", [Validators.required]],
      cp_empresa: ["", [Validators.required]],
      estado_empresa: ["", [Validators.required]],
      pais_empresa: ["", [Validators.required]],
      colonia_empresa: ["", Validators.required],
      sector_empresa: ["", Validators.required],
      industria_empresa: ["", [Validators.required]],
      tamanio_empresa: ["", [Validators.required]],
      presencia_empresa: ["", [Validators.required]],
      web_empresa: ["", [Validators.required]],
      linkedin_empresa: [""],
      twitter_empresa: [""],
      facebook_empresa: [""],
      instagram_empresa: [""],
      campus_empresa: ["", [Validators.required]],
      relaciones: this.fb.array([],[Validators.required]),
      acercamiento_empresa: ["", Validators.required],
    });
  }

  showRazonSocial() {
    if (this.usuarioService.getCurrentUserData().clavePerfil == "academia") {
      this.formularioForm.get("razonsocial_empresa")?.disable();
      return false;
    } else {
      this.formularioForm.get("razonsocial_empresa")?.enable();
      return true;
    }
  }

  async selImagen(event: any) {
    let idUsr = this.idSocioFormador;
    let selFile = (event.target as HTMLInputElement).files?.[0];
    if (!selFile) return;
    if (!selFile.type.includes("image/")) {
      this.toastr.error("Únicamente imágenes");
      return;
    }
    if (selFile.size > 3e6) {
      this.toastr.error("Tamaño máximo de 3MB");
      return;
    }
    let fileName = this.getFileName(idUsr, selFile);

    await this.azureBlobStorage
      .uploadFile(
        {
          containerName: environment.AzureBlob_containerNameSocio,
          file: selFile,
          filename: fileName,
        },
        environment.AzureBlob_ACCOUNT_NAME,
        environment.AzureBlob_socioSAS
      )
      .then((res) => {
        let fileUrlLogo = `${environment.AzureBlob_socioURL}${fileName}`;
        this.sociosFormadores
          .putLogo({ idSocioFormador: idUsr, urlLogo: fileUrlLogo })
          .subscribe({
            next: (resOK) => {
              this.img = fileUrlLogo+"?time=" + new Date().getMilliseconds();
              localStorage.removeItem("localdb");
            },
            error: (error) => {
              console.log(error);
            },
          });
      });
  }

  getFileName(id, file) {
    return `socio_${id}.${file.name.split(".").pop()}`;
  }

  getUserData() {
    this.userData = this.usuariosService.getCurrentUserData();
    if (!this.userData) this.router.navigate(["/"]);

    return this.userData;
  }

  InactivarCuenta() {
    Swal.fire({
      title: "¿Desea INACTIVAR la cuenta de Socio Formador?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Inactivar",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          "Gestión de Socio Formador.",
          "Socio Formador Inactivo.",
          "success"
        );

        this.cuentaActiva = false;

        this.socioFormadorService
          .cambiarEstatus({
            idSocioFormador: this.idSocioFormador,
            estatus: "inactivo",
          })
          .pipe(
            tap((resultado: any) => {
              if (resultado) {
                Swal.fire(
                  "Gestión de Usuarios.",
                  "Socio Formador Inactivo.",
                  "success"
                );
                localStorage.removeItem("localdb");
                this.ngOnInit();
              } else {
                this.toastr.error("Algo Salió mal!");
              }
            })
          )
          .subscribe();
      }
    });
  }

  ActivarCuenta() {
    Swal.fire({
      title: "¿Desea ACTIVAR la cuenta de Socio Formador?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Activar",
    }).then((result) => {
      if (result.isConfirmed) {
        this.cuentaActiva = true;
        this.socioFormadorService
          .cambiarEstatus({
            idSocioFormador: this.idSocioFormador,
            estatus: "activo",
          })
          .pipe(
            tap((resultado: any) => {
              if (resultado) {
                Swal.fire(
                  "Gestión de Socio Formador.",
                  "Socio Formador Activo.",
                  "success"
                );
                localStorage.removeItem("localdb");
                this.ngOnInit();
              } else {
                this.toastr.error("Algo Salió mal!");
              }
            })
          )
          .subscribe();
      }
    });
  }

  buscarRFC() {
    this.procesandoBusqueda = true;
    this.formularioForm.get("rfc_empresa")?.updateValueAndValidity();

    let rfc = this.formularioForm.get("rfc_empresa")?.value.trim() || "";

    //Validate rfc, check if has special characters
    if (rfc.match(/[^A-Z0-9&]/gi)) {
      this.toastr.warning("RFC Inválido, Escribe únicamente letras o números, sin espacios.");
      this.procesandoBusqueda = false;
      return;
    }

    this.socioFormadorService.buscarSocioPorRFC(rfc).subscribe({
      next: (resOK) => {
        let socioEncontrador = <ISocioFormadorResponse>resOK;
        this.procesandoBusqueda = false;
        Swal.fire({
          title: "Socio Formador Existente",
          html: `El Socio Formador ${socioEncontrador.nombreSocioFormador} ya existe, ¿deseas visualizar la información que tenemos registrada?`,
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonText: "Ver Información",
        }).then((result) => {
          if (result.isConfirmed) {
            this.formularioForm.reset();
            this.idSocioFormador = socioEncontrador.idSocioFormador;
            this.getInfoSocioFormador();

            sessionStorage.setItem(
              "idSocioFormador_banco",
              JSON.stringify(this.idSocioFormador)
            );
            sessionStorage.setItem("idSocioFormador_banco_b", "true");
            this.socioSeleccionado.emit(this.idSocioFormador);
            this.fieldSetEnable = true;
            if(this.esAcademico()){
              this.fieldSetEnable = false;
            }
          } else {
            this.formularioForm.reset();
          }
        });
      },
      error: (err) => {
        this.procesandoBusqueda = false;
        console.log(err);
      },
    });
  }

  getEstados() {
    this.catalogosSvc
      .getEstados()
      .pipe(tap((resultado: IEstadosResponse[]) => (this.Estados = resultado)))
      .subscribe();
  }

  async buscarCP() {
    try {
      this.cpMexico = true;
      // Verificamos que el CP sea valido
      if (!this.formularioForm.get("cp_empresa")?.valid) {
        this.toastr.error("Escribe un Código Postal válido");
        return;
      }

      this.formularioForm.get("ciudad_empresa")?.patchValue(null);
      this.formularioForm.get("estado_empresa")?.patchValue(null);
      this.formularioForm.get("colonia_empresa")?.patchValue(null);
      this.formularioForm.get("estado2_empresa")?.patchValue(null);
      this.formularioForm.get("colonia2_empresa")?.patchValue(null);

      // Habilitamos los controles al estado default => México, CP
      this.formularioForm.get("estado2_empresa")?.disable();
      this.formularioForm.get("colonia2_empresa")?.disable();

      this.formularioForm.get("estado_empresa")?.enable();
      this.formularioForm.get("ciudad_empresa")?.enable();
      this.formularioForm.get("colonia_empresa")?.enable();

      if (this.formularioForm.get("cp_empresa")?.value.toUpperCase() == "EXT") {
        // Es Extranjero
        this.cpMexico = false;
        this.Estados = [];
        this.formularioForm.get("estado2_empresa")?.enable();
        this.formularioForm.get("colonia2_empresa")?.enable();
        this.formularioForm.get("estado_empresa")?.disable();
        this.formularioForm.get("colonia_empresa")?.disable();
      } else {
        // Estados
        this.getEstados();
      }

      let resultado: ICodigoPostalResponse = await lastValueFrom(
        this.catalogosSvc.getInfoCp(
          this.formularioForm.get("cp_empresa")?.value
        )
      );

      if (resultado) {
        this.formularioForm
          .get("ciudad_empresa")
          ?.patchValue(resultado.ciudad ?? resultado.estado);

        this.formularioForm
          .get("estado_empresa")
          ?.patchValue(resultado.claveEstado);

        this.Colonias = resultado.colonias;

        // Deshabilitamos estado y ciudad
        this.formularioForm.get("estado_empresa")?.disable();
        this.formularioForm.get("ciudad_empresa")?.disable();
      } else {
        this.toastr.error(
          "No hay informaicón pra el CP: " +
            this.formularioForm.get("cp_empresa")?.value
        );
      }
    } catch (err: any) {
      Utils.processErrorResponse(err);
    }
  }

  btnRegresar() {
    window.history.back();
    //this.router.navigate(["/banco-socio-formador"]);
  }

  esAcademico() {
    if (this.usuarioService.getCurrentUserData().clavePerfil == "academia") {
      return true;
    }
    return false;
  }
  mostrarBotonera() {
    if (this.usuarioService.getCurrentUserData().clavePerfil == "academia") {
      if (this.idSocioFormador > 0) {
        return false;
      }
    }
    return true;
  }

  validarPais(event) {
    let idPais = event.target.value;
    this.formularioForm.get("cp_empresa")?.patchValue(null);
    this.formularioForm.get("estado2_empresa")?.patchValue(null);
    if (idPais != "MEX") {
      this.formularioForm.get("cp_empresa")?.patchValue("EXT");
    }
    this.buscarCP();
  }
}
