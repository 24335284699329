<app-header-buscador [showSearchBar]="false"></app-header-buscador>

<div class="sec_ad" *featureFlag="'F2_4_HU69'">

  <app-title-show-more [showMoreObject]="{ title:'Académico que busca un Socio Formador'  }"></app-title-show-more>

  <div class="row">

    <!-- Filtro de Campus -->
    <div class="col-md">
      <div class="btn-group order-by">
        <button class="btn dropdown-toggle" type="button" id="listado" data-bs-toggle="dropdown"
          data-bs-auto-close="true" aria-expanded="false" data-bs-offset="0,0"
          title="{{ filter.campus ? getValueCatalogo(campusData, 'idCampus', filter.campus, 'campus') : 'Campus' }}">
          {{ filter.campus ? getValueCatalogo(campusData, 'idCampus', filter.campus, 'campus') : 'Campus' }}
        </button>
        <ul class="dropdown-menu dropdown-menu-start" aria-labelledby="listado">
          <li *ngIf="campusData.length == 0" class="dropdown-item">Selecciona una escuela</li>
          <li *ngFor="let campus of campusData; let i = index">
            <a class="dropdown-item {{ i === 0 ? 'active' : '' }}" (click)="addFilter('campus', campus.idCampus)">{{
              campus.campus }}</a>
          </li>
        </ul>
      </div>
    </div>


    <!-- Filtro de escuela -->
    <div class="col-md">
      <div class="btn-group order-by">
        <button class="btn dropdown-toggle" type="button" id="listado" data-bs-toggle="dropdown"
          data-bs-auto-close="true" aria-expanded="false" data-bs-offset="0,0"
          title="{{ filter.escuela ? getValueCatalogo(escuelaData, 'idEscuela', filter.escuela, 'escuela') : 'Escuela' }}">
          {{ filter.escuela ? getValueCatalogo(escuelaData, 'idEscuela', filter.escuela, 'escuela') : 'Escuela' }}
        </button>
        <ul class="dropdown-menu dropdown-menu-start" aria-labelledby="listado">
          <li *ngFor="let escuela of escuelaData; let i = index">
            <a class="dropdown-item {{ i === 0 ? 'active' : '' }}" (click)="addFilter('escuela', escuela.idEscuela)">{{
              escuela.escuela }}</a>
          </li>
        </ul>
      </div>
    </div>

    <!-- Filtro de Periodo -->
    <div class="col-md">
      <div class="btn-group order-by">
        <button class="btn dropdown-toggle" type="button" id="listado" data-bs-toggle="dropdown"
          data-bs-auto-close="true" aria-expanded="false" data-bs-offset="0,0"
          title="{{ filter.periodo ? getValueCatalogo(periodoData, 'idPeriodo', filter.periodo, 'periodo') : 'Periodo' }}">
          {{ filter.periodo ? getValueCatalogo(periodoData, 'idPeriodo', filter.periodo, 'periodo') : 'Periodo' }}
        </button>
        <ul class="dropdown-menu dropdown-menu-start" aria-labelledby="listado">
          <li *ngFor="let periodo of periodoData; let i = index">
            <a class="dropdown-item {{ i === 0 ? 'active' : '' }}" (click)="addFilter('periodo', periodo.idPeriodo)">{{
              periodo.periodo }}</a>
          </li>
        </ul>
      </div>
    </div>


    <!-- Reestablecer Filtros -->
    <div class="col-md d-flex justify-content-end">
      <app-button (callbackAction)="cleanFilters()"> Restablecer </app-button>
    </div>
  </div>


  <div id="sec_tab" class="nav nav-pills d-flex flex-md-nowrap flex-wrap" role="presentation">
    <div class="active" data-bs-toggle="pill" data-bs-target="#tab-academico-aprobar"
      aria-controls="tab-academico-aprobar" role="tab" type="button" aria-selected="false">
      Solicitudes por aprobar
    </div>
    <div data-bs-toggle="pill" data-bs-target="#tab-academico-aprobado" aria-controls="tab-academico-aprobado" class=""
      role="tab" type="button" aria-selected="false">
      Solicitudes aprobadas
    </div>
    <div data-bs-toggle="pill" data-bs-target="#tab-academico-rechazadas" aria-controls="tab-academico-rechazadas"
      class="" role="tab" type="button" aria-selected="false">
      {{ "academico_busca_sf.solicitudes_rechazadas" | translate }}
    </div>

  </div>

  <div class="tab-content" id="pills-tabContent">

    <div class="tab-pane fade show active" id="tab-academico-aprobar" role="tabpanel"
      aria-labelledby="tab-academico-aprobar-tab">

      <app-table [backgroundColor]="backgroundTable" [columns]="academicoColumns"
        [data]="solicitudesAcademicosDataPage"></app-table>

      <app-pagination *ngIf="solicitudesAcademicosData.length" [objPagination]="solicitudesAcademicosData"
        (paginationDataChange)="showPaginationDataRevision($event)"></app-pagination>
    </div>

    <div class="tab-pane fade" id="tab-academico-aprobado" role="tabpanel" aria-labelledby="tab-academico-aprobado-tab">

      <app-table [backgroundColor]="backgroundTable" [columns]="academicoColumns"
        [data]="solicitudesAprobadaAcademicosDataPage"></app-table>

      <app-pagination *ngIf="solicitudesAprobadaAcademicosData.length"
        [objPagination]="solicitudesAprobadaAcademicosData"
        (paginationDataChange)="showPaginationDataAprobado($event)"></app-pagination>

    </div>

    <div class="tab-pane fade" id="tab-academico-rechazadas" role="tabpanel"
      aria-labelledby="tab-academico-rechazadas-tab">

      <app-table [backgroundColor]="backgroundTable" [columns]="academicoColumns"
        [data]="solicitudesRechazadasAcademicosDataPage"></app-table>

      <app-pagination *ngIf="solicitudesRechazadasAcademicosData.length"
        [objPagination]="solicitudesRechazadasAcademicosData"
        (paginationDataChange)="showPaginationDataRechazado($event)"></app-pagination>

    </div>
  </div>
</div>

  <div class="sec_ad" *featureFlag="'F2_5_HU69'">

    <app-title-show-more [showMoreObject]="{ title: 'academico_busca_sf.titulo' | translate }"></app-title-show-more>

    <div class="row">

      <!-- Filtro de Campus -->
      <div class="col-md">
        <div class="btn-group order-by">
          <button class="btn dropdown-toggle" type="button" id="listado" data-bs-toggle="dropdown"
            data-bs-auto-close="true" aria-expanded="false" data-bs-offset="0,0"
            title="{{ filter.campus ? getValueCatalogo(campusData, 'idCampus', filter.campus, 'campus') : 'Campus' }}">
            {{ filter.campus ? getValueCatalogo(campusData, 'idCampus', filter.campus, 'campus') : 'Campus' }}
          </button>
          <ul class="dropdown-menu dropdown-menu-start" aria-labelledby="listado">
            <li *ngIf="campusData.length == 0" class="dropdown-item">Selecciona una escuela</li>
            <li *ngFor="let campus of campusData; let i = index">
              <a class="dropdown-item {{ i === 0 ? 'active' : '' }}" (click)="addFilter('campus', campus.idCampus)">{{
                campus.campus }}</a>
            </li>
          </ul>
        </div>
      </div>


      <!-- Filtro de escuela -->
      <div class="col-md">
        <div class="btn-group order-by">
          <button class="btn dropdown-toggle" type="button" id="listado" data-bs-toggle="dropdown"
            data-bs-auto-close="true" aria-expanded="false" data-bs-offset="0,0"
            title="{{ filter.escuela ? getValueCatalogo(escuelaData, 'idEscuela', filter.escuela, 'escuela') : 'Escuela' }}">
            {{ filter.escuela ? getValueCatalogo(escuelaData, 'idEscuela', filter.escuela, 'escuela') : 'Escuela' }}
          </button>
          <ul class="dropdown-menu dropdown-menu-start" aria-labelledby="listado">
            <li *ngFor="let escuela of escuelaData; let i = index">
              <a class="dropdown-item {{ i === 0 ? 'active' : '' }}"
                (click)="addFilter('escuela', escuela.idEscuela)">{{
                escuela.escuela }}</a>
            </li>
          </ul>
        </div>
      </div>

      <!-- Filtro de Periodo -->
      <div class="col-md">
        <div class="btn-group order-by">
          <button class="btn dropdown-toggle" type="button" id="listado" data-bs-toggle="dropdown"
            data-bs-auto-close="true" aria-expanded="false" data-bs-offset="0,0"
            title="{{ filter.periodo ? getValueCatalogo(periodoData, 'idPeriodo', filter.periodo, 'periodo') : 'Periodo' }}">
            {{ filter.periodo ? getValueCatalogo(periodoData, 'idPeriodo', filter.periodo, 'periodo') : 'Periodo' }}
          </button>
          <ul class="dropdown-menu dropdown-menu-start" aria-labelledby="listado">
            <li *ngFor="let periodo of periodoData; let i = index">
              <a class="dropdown-item {{ i === 0 ? 'active' : '' }}"
                (click)="addFilter('periodo', periodo.idPeriodo)">{{
                periodo.periodo }}</a>
            </li>
          </ul>
        </div>
      </div>


      <!-- Reestablecer Filtros -->
      <div class="col-md d-flex justify-content-end">
        <app-button (callbackAction)="cleanFilters()"> Restablecer </app-button>
      </div>
    </div>


    <div id="sec_tab" class="nav nav-pills d-flex flex-md-nowrap flex-wrap" role="presentation">
      <div class="active" data-bs-toggle="pill" data-bs-target="#tab-academico-registradas"
        aria-controls="tab-academico-regsitrados" role="tab" type="button" aria-selected="true">
        Recibidos
      </div>
      <div data-bs-toggle="pill" data-bs-target="#tab-academico-aprobar" aria-controls="tab-academico-aprobar"
        role="tab" type="button" aria-selected="false">
        En Revisión
      </div>
      <div data-bs-toggle="pill" data-bs-target="#tab-academico-aprobado" aria-controls="tab-academico-aprobado"
        class="" role="tab" type="button" aria-selected="false">
        Aceptadas
      </div>
      <div data-bs-toggle="pill" data-bs-target="#tab-academico-rechazadas" aria-controls="tab-academico-rechazadas"
        class="" role="tab" type="button" aria-selected="false">
        {{ "academico_busca_sf.solicitudes_rechazadas" | translate }}
      </div>

    </div>

    <div class="tab-content" id="pills-tabContent">
      <div class="tab-pane fade show active" id="tab-academico-registradas" role="tabpanel"
        aria-labelledby="tab-academico-registradas-tab">

        <app-table [backgroundColor]="backgroundTable" [columns]="academicoColumns"
          [data]="solicitudesAcademicosRegistradosDataPage"></app-table>

        <app-pagination *ngIf="solicitudesAcademicosRegistradosData.length"
          [objPagination]="solicitudesAcademicosRegistradosData"
          (paginationDataChange)="showPaginationDataRegistrados($event)"></app-pagination>
      </div>

      <div class="tab-pane fade" id="tab-academico-aprobar" role="tabpanel" aria-labelledby="tab-academico-aprobar-tab">

        <app-table [backgroundColor]="backgroundTable" [columns]="academicoColumns"
          [data]="solicitudesAcademicosDataPage"></app-table>

        <app-pagination *ngIf="solicitudesAcademicosData.length" [objPagination]="solicitudesAcademicosData"
          (paginationDataChange)="showPaginationDataRevision($event)"></app-pagination>
      </div>

      <div class="tab-pane fade" id="tab-academico-aprobado" role="tabpanel"
        aria-labelledby="tab-academico-aprobado-tab">

        <app-table [backgroundColor]="backgroundTable" [columns]="academicoColumns"
          [data]="solicitudesAprobadaAcademicosDataPage"></app-table>

        <app-pagination *ngIf="solicitudesAprobadaAcademicosData.length"
          [objPagination]="solicitudesAprobadaAcademicosData"
          (paginationDataChange)="showPaginationDataAprobado($event)"></app-pagination>

      </div>

      <div class="tab-pane fade" id="tab-academico-rechazadas" role="tabpanel"
        aria-labelledby="tab-academico-rechazadas-tab">

        <app-table [backgroundColor]="backgroundTable" [columns]="academicoColumns"
          [data]="solicitudesRechazadasAcademicosDataPage"></app-table>

        <app-pagination *ngIf="solicitudesRechazadasAcademicosData.length"
          [objPagination]="solicitudesRechazadasAcademicosData"
          (paginationDataChange)="showPaginationDataRechazado($event)"></app-pagination>

      </div>
    </div>

  </div>
