import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { ActivatedRoute, Router, UrlTree } from "@angular/router";
import { IAreaCanalizacionResponse } from "@shared/Interface/IAreaCanalizacionResponse";
import { ICartaConvenio } from "@shared/Interface/ICartaConvenio";
import { IArea, ICampus } from "@shared/Interface/ICatalogos.interface";
import { IComentarioSocioFormadorRequest } from "@shared/Interface/IComentarioSocioFormadorRequest";
import { IComentarioSocioFormadorResponse } from "@shared/Interface/IComentarioSocioFormadorResponse";
import { IContacto } from "@shared/Interface/IContacto.interface";
import { IEstadosResponse } from "@shared/Interface/IEstadosResponse";
import { ISocioFormadorContactoResponse } from "@shared/Interface/ISocioFormadorContactoResponse";
import { ISocioFormadorResponse } from "@shared/Interface/ISocioFormadorResponse";
import { ISolicitudGetResponse } from "@shared/Interface/ISolicitudGetResponse";
import {
  ISociosSolicitudComentarios,
  ISolicitudSocioComentarios,
} from "@shared/Interface/ISolicitudSocioComentarios";
import { IUser } from "@shared/Interface/user";
import Utils from "@shared/helpers/utils";
import { AzureBlobStorageService } from "@shared/service/AzureBlobStorage.Service";
import { CatalogosService } from "@shared/service/Catalogos/catalogos.service";
import { ContactosService } from "@shared/service/Contactos/contactos.service";
import { InicioService } from "@shared/service/Inicio/inicio.service";
import { SociosFormadoresService } from "@shared/service/Socios_Formadores/socios-formadores.service";
import { SolicitudesService } from "@shared/service/Solicitudes/solicitudes.service";
import { UsuariosService } from "@shared/service/Usuarios/usuarios.service";
import { environment } from "environments/environment";
import {
  NgxExtendedPdfViewerService,
  pdfDefaultOptions,
} from "ngx-extended-pdf-viewer";
import { ToastrService } from "ngx-toastr";
import { lastValueFrom, tap } from "rxjs";
import Swal from "sweetalert2";

@Component({
  selector: "app-solicitud-sf-potencial-rechazo",
  templateUrl: "./solicitud-sf-potencial-rechazo.component.html",
  styleUrls: ["./solicitud-sf-potencial-rechazo.component.css"],
})
export class SolicitudesSFPotencialRechazoComponent implements OnInit {

  URLCARTA_PF_PDF='https://mpstecstorage.blob.core.windows.net/mvpretos-app-assets/Carta_Solicitud_Persona_Fisica1.0.pdf';
  URLCARTA_PM_PDF = 'https://mpstecstorage.blob.core.windows.net/mvpretos-app-assets/Carta_Solicitud_Persona_Moral.pdf';
  URLCARTA_PF_DOC='https://mpstecstorage.blob.core.windows.net/mvpretos-app-assets/Carta_Solicitud_Persona_Fisica1.0.docx';
  URLCARTA_PM_DOC = 'https://mpstecstorage.blob.core.windows.net/mvpretos-app-assets/Carta_Solicitud_Persona_Moral.docx';

  sidebarExpanded = false;
  lang: string = "es";
  urlTree: UrlTree;

  retosId: number = 0;
  idSocioFormador: number = 0;
  idCVDP: number = 0;
  idSolicitud: number = 0;
  socioFormador!: ISocioFormadorResponse;
  contactoSocioFormador!: ISocioFormadorContactoResponse;
  solicitud!: ISolicitudGetResponse;
  estados: IEstadosResponse[] = [];

  relacionTec: string = "";
  areasData: IArea[] = [];
  areasCanalizacionList: IAreaCanalizacionResponse[] = [];
  idsCanalizacion: number[] = [];

  showFormularioCancelacion: boolean = false;
  showFormularioReasignacion: boolean = false;
  disableBotons: boolean = false;
  areaCanalizacionRequerido: string = "";

  motivosRequeridos: string = "";
  motivosRechazo: string = "";
  motivoRechazo: string = "";

  campusSeleccionado: number;

  showDetalleCancelacion: boolean = false;
  areaCanalizacionText: string = "";
  comentarioSocioFormador: string = "";
  documentacionCompleta: boolean = false;
  giroConcat: string = "";
  panelOpenState = false;
  convenioForm!: FormGroup;
  convenioForm2!: FormGroup;
  fileNameSelected: any = "";
  img: string;
  listCartaConvenio: any;
  mostrarDivConvenio = false;
  imgSubirConvenio: string = "assets/img/icon_abajo.svg";
  userStore: IUser;
  imgQuieroVincularlo: string = "assets/img/icon_abajo.svg";

  clavePerfil: string;
  showFormularioContinuar: boolean = false;
  campusData: ICampus[];

  statusSolicitudForm!: FormGroup;
  urlPDFGenerado: SafeResourceUrl;
  urlPDFGenerado2: string;
  contactos: IContacto[];

  mostrarArchivoRequerido: boolean = false;
  mostrarPersonaFirmaRequerido: boolean = false;

  lbl_medioContacto: string = "-";

  estatusData: any = [];
  cartaGenerada: boolean = false;

  constructor(
    private socioFormadorService: SociosFormadoresService,
    private catalogo: CatalogosService,
    private solicitudesService: SolicitudesService,
    private router: Router,
    private usuarioService: UsuariosService,
    private toastr: ToastrService,
    private contactoService: ContactosService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private azureBlobStorage: AzureBlobStorageService,
    private domSanitizer: DomSanitizer,
    private pdfService: NgxExtendedPdfViewerService,
    private sociosFormadoresService: SociosFormadoresService,
    private inicio: InicioService
  ) {
    this.userStore = this.usuarioService.getCurrentUserData();
    this.urlTree = this.router.parseUrl(this.router.url);
    this.idSolicitud = Number(this.urlTree.queryParams["solicitud"]);
    this.idCVDP = Number(this.urlTree.queryParams["CVDP"]);

    this.initForm();
    this.initFormStatus();

    pdfDefaultOptions.doubleTapZoomFactor = "150%";
    pdfDefaultOptions.maxCanvasPixels = 4096 * 4096 * 5;
  }

  async ngOnInit(): Promise<void> {
    this.lang = localStorage.getItem("lang") ?? "es";

    await this.getMediosContacto();

    await this.getAreas();
    await this.getEstatus();
    await this.getEstados();
    await this.getAreasCanalizacion();
    await this.getSolicitudById();
    await this.getSocioFormador();
    await this.getSocioFormadorContacto();
    this.getcampusData();

    this.getComentariosSocios();

    this.checkCartaConvenio();
  }

  initFormStatus() {
    this.statusSolicitudForm = this.fb.group({
      claveEstatus: ["primer-contacto", Validators.required],
      comentarios: [null, Validators.required],
    });
  }
  initForm() {
    this.convenioForm = this.fb.group({
      fechaInicio: ["", [Validators.required]],
      fechaFin: ["", [Validators.required]],
      personaFirma: [""],
      urlCartaConvenio: [""],
    });
    this.convenioForm2 = this.fb.group({
      tipoPersona: ["", [Validators.required]],
      fechaInicio: ["", [Validators.required]],
      fechaFin: ["", [Validators.required]],
    });
  }

  changeLang(lang: string) {
    this.lang = lang;
    localStorage.setItem("lang", lang);
    window.location.reload();
  }

  async getSocioFormador() {
    try {
      let resultado = <any>(
        await lastValueFrom(
          this.socioFormadorService.getSociosFormadoresById(
            this.idSocioFormador
          )
        )
      );
      if (resultado != null && resultado != undefined) {
        this.socioFormador = resultado;
        //Actualizamos el idsocioformador y el idusuario del formulario de registro
        if (
          resultado.claveEstatusSeguimiento &&
          resultado.claveEstatusSeguimiento !== "primer-contacto"
        ) {
          this.statusSolicitudForm.patchValue({
            claveEstatus: resultado.claveEstatusSeguimiento,
          });
        }

        this.catalogo.getRelacionesTec().subscribe((resOK) => {
          let relaciones = resOK.filter((x) =>
            resultado.relacionesTec.includes(x.idRelacionTec)
          );
          this.relacionTec = relaciones.map((x) => x.relacionTec).join(", ");
        });

        let statusContacto = "inactivo";
        if (["aprobado"].includes(this.solicitud.claveEstatus.trim())) {
          statusContacto = "activo";
        }

        if (resultado.claveEstatusSeguimiento === "rechazado") {
          this.showFormularioContinuar = true;
        }

        this.contactoService.getContactosPorEstatus(statusContacto).subscribe({
          next: (resOK) => {
            let result = resOK.filter(
              (x) => x.idSocioFormador == resultado.idSocioFormador
            );
            this.contactos = result.map((x) => {
              return {
                  ...x,
                  nombreContacto: `${x.nombre} ${x.apellidos}`
                };
            });
            if (this.contactos.length == 0) {
              this.contactoService
                .getContactos(this.idSocioFormador)
                .subscribe({
                  next: (resOK) => {
                    this.contactos = resOK.map((x) => {
                      return {
                        ...x,
                        nombreContacto: `${x.nombre} ${x.apellidos}`
                      };
                    });
                  },
                  error: (err) => {
                    console.log(err);
                  },
                });
            }
          },
          error: (err) => {
            console.log(err);
          },
        });
      }
    } catch (error) {
      console.error(
        "Error al obtener el detalle de un Socio Formador por su Id ",
        error
      );
    }
  }

  medioContactoData: any;
  async getMediosContacto() {
    try {
      this.medioContactoData = await lastValueFrom(
        this.catalogo.getMediosContacto()
      );
    } catch (error) {
      console.log(error);
    }
  }

  obtenerMedioContacto(idMedioContacto: number, otroMedioContacto: string) {
    let medio = "";
    if (idMedioContacto > 0) {
      medio = this.medioContactoData.find(
        (x) => x.idMedioContacto == idMedioContacto
      ).medioContacto;
    }


    return `${medio} ${otroMedioContacto ?? ""}`;
  }

  async getSocioFormadorContacto() {
    try {
      let resultado = <any>(
        await lastValueFrom(
          this.socioFormadorService.getSociosFormadoresContacto(
            this.idSocioFormador,
            true
          )
        )
      );
      if (resultado != null && resultado != undefined && resultado.length > 0) {
        this.contactoSocioFormador = resultado[0];
      }
    } catch (error) {
      console.error(
        "Error al obtener el contacto por el Id del Socio Formador ",
        error
      );
    }
  }

  async getSolicitudById() {
    try {
      let resultado = <any>(
        await lastValueFrom(
          this.solicitudesService.getSolicitudById(this.idSolicitud)
        )
      );
      if (resultado != null && resultado != undefined) {
        this.solicitud = resultado;
        this.retosId = this.solicitud.idRetoMaestro;
        this.idSocioFormador = this.solicitud.idSocioFormador;
      }
    } catch (error) {
      console.error(
        "Error al obtener el detalle de la solicitud por su Id ",
        error
      );
    }
  }

  async getEstados() {
    try {
      let resultado = <any>await lastValueFrom(this.catalogo.getEstados());
      if (resultado != null && resultado != undefined && resultado.length > 0) {
        this.estados = resultado;
      }
    } catch (error) {
      console.error("Error al obtener todos los estados ", error);
    }
  }

  getEstadosByClave(claveEstado: string) {
    let nombreEsatdo = "";
    try {
      let estadosFiltrados = this.estados.filter(
        (es) => es.claveEstado.trim() == claveEstado.trim()
      );
      if (estadosFiltrados.length > 0) {
        nombreEsatdo = estadosFiltrados[0].estado;
      }
    } catch (error) {
      console.error("Error al obtener el estado por su Clave ", error);
    }
    return nombreEsatdo;
  }

  async getAreas(){
    try{
      this.areasData = await lastValueFrom(this.catalogo.getAreas());

    }catch(error){
      console.error("Error al obtener las areas", error);
    }
  }

  async getAreasCanalizacion() {
    try {
      let resultado = <any>(
        await lastValueFrom(this.catalogo.getAreasCanalizacion())
      );
      if (resultado != null && resultado != undefined && resultado.length > 0) {
        this.areasCanalizacionList = resultado;
      }
    } catch (error) {
      console.error("Error al obtener todas las areas de canalización ", error);
    }
  }

  seleccionarCheck(idCanalizacion: number) {
    try {
      let checkExiste = this.idsCanalizacion.includes(idCanalizacion);

      if (!checkExiste) {
        this.idsCanalizacion.push(idCanalizacion);
      } else {
        let index = this.idsCanalizacion.findIndex((c) => c === idCanalizacion);
        if (index >= 0) {
          this.idsCanalizacion.splice(index, 1);
        }
      }
    } catch (error) {
      console.error("Error al seleccionar un check ", error);
    }
  }

  async actualizarComentarios(): Promise<
    IComentarioSocioFormadorRequest | undefined
  > {
    try {
      let comentarioRequest: IComentarioSocioFormadorRequest = {
        comentarios: this.comentarioSocioFormador,
        documentacionCompleta: this.documentacionCompleta ? 1 : 0,
        idSocioFormador: this.idSocioFormador,
      };

      let resultado = <any>(
        await lastValueFrom(
          this.socioFormadorService.putComentarioSocioFormador(
            comentarioRequest
          )
        )
      );
      if (resultado != null && resultado != undefined) {
        let comentarioResponse: IComentarioSocioFormadorResponse = resultado;
        return comentarioResponse;
      }

      return undefined;
    } catch (error) {
      console.error("Error al actualizar los comentarios ", error);
      return undefined;
    }
  }
  mostrarComentarioRequerido: boolean = false
  async actualizarSolicitud(
    claveEstatus: string,
    prefirmado: boolean,
    tipoOpcion: string
  ) {
    let storeUser = this.usuarioService.getCurrentUserData();
    this.mostrarComentarioRequerido = false;
    try {
      if (claveEstatus === "rechazado") {
        if(this.motivosRechazo.trim() === ""){
          this.toastr.error("Debes especificar un motivo de rechazo.");
          this.mostrarComentarioRequerido = true;
          return;
        }

        this.solicitudesService
          .putSolicitudEstatus({
            idUsuario: storeUser.idUsuario,
            idSolicitud: this.idSolicitud,
            claveEstatus: "rechazado",
            idAcademia: 0,
            motivoEstatus: this.motivosRechazo,
            idCampus: this.solicitud.idCampus,
          })
          .subscribe({
            next: (resultOK) => {
              this.toastr.success("Solicitud rechazada con éxito.");
              this.router.navigateByUrl("/dashboard");
            },
            error: (error) => {
              this.toastr.error("Hubo un error, intenta más tarde.");
              console.log("rechazado", error);
            },
          });
      }
      if (claveEstatus === "aprobado") {
        let resultGuardaConvenio = "OK";
        if (tipoOpcion === "A") {
          resultGuardaConvenio = await this.guardarConvenio();
        }
        if (resultGuardaConvenio != "OK") {
          this.toastr.warning("Revisa la información para subir la Carta Convenio.");
          return;
        }
        let estatusFirma = prefirmado ? "firmado" : "pendiente-firma";
        if (this.socioFormador.claveEstatusSeguimiento === "rechazado") {
          // borrar convenios anteriores
          this.borrarConveniosAnteriores();

          // actualizar el estatus a pendiente de firma y su comentario
          let comentario = `Convenio ${prefirmado ? "cargado" : "creado"} por ${
            this.usuarioService.getCurrentUserData().nombreUsuario
          } el ${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()}`;

          await this.cambiarEstatusSeguimiento(
            "aprobado",
            estatusFirma,
            comentario
          );

          await this.getComentariosSocios();

          await this.getSocioFormador();

          this.toastr.success("Convenio generado correctamente");

          this.showFormularioContinuar = false;
        } else {
          this.solicitudesService
            .putSolicitud({
              idUsuario: storeUser.idUsuario,
              idSolicitud: this.idSolicitud,
              claveEstatus: "aprobado",
              idAcademia: 0,
              idSocioFormador: this.idSocioFormador,
            })
            .subscribe({
              next: async (resultOK) => {
                let comentario = `Convenio ${
                  prefirmado ? "cargado" : "creado"
                } por ${
                  this.usuarioService.getCurrentUserData().nombreUsuario
                } el ${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()}`;
                await this.cambiarEstatusSeguimiento(
                  "aprobado",
                  estatusFirma,
                  comentario
                );
                this.toastr.success("Solicitud aprobada con éxito.");
                this.router.navigateByUrl("/dashboard");
              },
              error: (error) => {
                this.toastr.error("Hubo un error, intenta más tarde.");
                console.log("aprobado", error);
              },
            });
        }
      }
    } catch (error) {
      console.error("Error al actualizar una solicitud ", error);
    }
  }

  revertirCancelacion() {
    this.showFormularioCancelacion = false;
    this.disableBotons = false;
    this.areaCanalizacionRequerido = "";
    this.motivosRequeridos = "";
  }

  validarForm(): boolean {
    let isValid: boolean = true;
    this.areaCanalizacionRequerido = "";
    this.motivosRequeridos = "";

    if (this.idsCanalizacion.length === 0) {
      this.areaCanalizacionRequerido =
        "Seleccione al menos una área de canalización";
      isValid = false;
    }
    if (this.motivosRechazo === "") {
      this.motivosRequeridos = "Escriba un motivo para la cancelación";
      isValid = false;
    }
    return isValid;
  }

  entregarDocumentosCheck() {
    this.documentacionCompleta = !this.documentacionCompleta;
  }

  async guardarEstatus() {
    try {
      this.statusSolicitudForm.markAllAsTouched();

      if (this.statusSolicitudForm.valid) {
        let idSocioFormador = this.idSocioFormador;
        let cveEstatus = "revision";
        let cveEstatusSeguimiento = this.statusSolicitudForm.controls[
          "claveEstatus"
        ].value;
        let comentarios = this.statusSolicitudForm.controls["comentarios"]
          .value;
        let idUsuario = this.userStore.idUsuario;

        let request: ISolicitudSocioComentarios = {
          idSocioFormador: idSocioFormador,
          claveEstatus: cveEstatus,
          claveEstatusSeguimiento: cveEstatusSeguimiento,
          comentarios: comentarios,
          idUsuarioRegistro: idUsuario,
        };

        await lastValueFrom(
          this.solicitudesService.postSolicitudSFP_comentarios(request)
        );
        this.socioFormador.claveEstatusSeguimiento = cveEstatusSeguimiento;
        this.socioFormador.estatusSeguimiento = this.obtenerEstatusSeguimiento(cveEstatusSeguimiento);
        this.statusSolicitudForm.reset();
        this.statusSolicitudForm.controls["claveEstatus"].patchValue(cveEstatusSeguimiento);

        this.getComentariosSocios();

        this.toastr.success("Actualización de estatus exitosa");
      } else {
        this.toastr.warning("Escribe un comentario");
      }
    } catch (err) {
      console.log(err);
      this.toastr.warning("Ocurrió un error, por favor intente más tarde.");
    }
  }
  editarSocioFormador(idSocioFormador: number) {
    sessionStorage.setItem("idSocioFormador_banco_b", "true");
    sessionStorage.setItem("idSocioFormador_banco", idSocioFormador.toString());
    this.inicio.changeSubMenuItem('datosempresa');
    let rutaClave = Utils.getRouteFromProfile(this.usuarioService.getCurrentUserData().clavePerfil);
    this.router.navigate([`/${rutaClave}/registro-sf`], {
      relativeTo: this.route,
    });
  }

  getFileName(id, fechaInicio, fechaFin) {
    fechaInicio = fechaInicio.replace("/");
    fechaFin = fechaFin.replace("/");
    return `cartaconvenio_${id}_${fechaInicio}_${fechaFin}.pdf`;
  }

  async selImagen(event: any) {
    let idUsr = this.idSocioFormador;
    let selFile = (event.target as HTMLInputElement).files?.[0];
    this.fileNameSelected = selFile?.name;
    if (!selFile) return;
    let fileName = this.getFileName(
      idUsr,
      this.convenioForm.get("fechaInicio")?.value,
      this.convenioForm.get("fechaFin")?.value
    );

    if (selFile.type != "application/pdf") {
      this.fileNameSelected = "Solo archivos PDF.";
    } else {
      await this.azureBlobStorage
        .uploadFile(
          {
            containerName: environment.AzureBlob_containerNameSocio,
            file: selFile,
            filename: fileName,
          },
          environment.AzureBlob_ACCOUNT_NAME,
          environment.AzureBlob_socioSAS
        )
        .then((res) => {
          let fileUrlLogo = `${environment.AzureBlob_socioURL}${fileName}`;
          this.img = fileUrlLogo;
        });
    }
  }

  async guardarConvenio() {
    try {
      let idUsuario = this.usuarioService.getCurrentUserData().idUsuario;
      let nombrePersonaFirma: any = this.contactos
        .filter(
          (x) => x.idUsuario == this.convenioForm.get("personaFirma")?.value
        )
        .pop();
      let requestConvenio = {
        idSocioFormador: this.idSocioFormador,
        fechaInicio: this.convenioForm.get("fechaInicio")?.value,
        fechaFin: this.convenioForm.get("fechaFin")?.value,
        personaFirma: nombrePersonaFirma.nombreContacto ?? "",
        urlCartaConvenio: this.img,
        idUsuario: idUsuario,
        idCategoria: 6,
      };

      await lastValueFrom(
        this.socioFormadorService.postCartaConvenio(requestConvenio)
      );

      // Borrar los convenios ateriores no firmados
      this.borrarConveniosAnteriores();

      //Actualizar el flujo de firmas
      let result = await lastValueFrom(
        this.socioFormadorService.getCartaConvenio(this.idSocioFormador)
      );

      result.sort((a, b) => b.idConvenio - a.idConvenio);

      // Firmar el ultimo registro de carta conveio
      if (result.length > 0) {
        let convenio = result[0];
        this.firmarConvenio(
          convenio.idConvenio,
          this.convenioForm.get("personaFirma")?.value
        );
        this.convenioForm.reset();
      }
      return "OK";
    } catch (error) {
      this.toastr.error("Ocurrió un error, por favor intente más tarde.");
      console.log(error);
      return "BAD";
    }
  }

  async firmarConvenio(idConvenio: number, idUsuarioFirmante: number) {
    try {
      await lastValueFrom(
        this.sociosFormadoresService.postFlujofirmas({
          tipo: "Convenio",
          pasoFirma: 1,
          id: idConvenio,
          idUsuarioFirmante: idUsuarioFirmante,
        })
      );
      await lastValueFrom(
        this.sociosFormadoresService.postFlujofirmas({
          tipo: "Convenio",
          pasoFirma: 2,
          id: idConvenio,
          idUsuarioFirmante: idUsuarioFirmante,
        })
      );
    } catch (e) {
      console.log(e);
    }
  }

  getAllCartaConvenios() {
    this.listCartaConvenio = [];
    this.socioFormadorService.getCartaConvenio(this.idSocioFormador).subscribe({
      next: (res: ICartaConvenio[]) => {
        let listado;
        if (
          ["academia"].includes(
            this.usuarioService.getCurrentUserData().clavePerfil
          )
        ) {
          listado = res.pop() ?? {};
          this.listCartaConvenio.push({
            fechaInicio: listado.fechaInicio,
            fechaTermino: listado.fechaFin,
            personaFirma: listado.personaFirma,
            link: {
              link: listado.urlCartaConvenio,
              title: `Convenio ${listado.fechaInicio} ${listado.fechaFin}`,
              openNewTab: true,
            },
          });
        } else {
          listado = res;
          this.listCartaConvenio = listado.map((x) => ({
            fechaInicio: x.fechaInicio,
            fechaTermino: x.fechaFin,
            personaFirma: x.personaFirma,
            link: {
              link: x.urlCartaConvenio,
              title: `Convenio ${x.fechaInicio} ${x.fechaFin}`,
              openNewTab: true,
            },
            buttons: [
              {
                className: "delete",
                objParams: x.idConvenio,
                callbackAction: (idConvenio) => {
                  this.eliminarCartaConvenio(idConvenio);
                },
              },
            ],
          }));
        }
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  eliminarCartaConvenio(idConvenio: number) {
    let idUsuario = this.usuarioService.getCurrentUserData().idUsuario;
    Swal.fire({
      title: "Convenios",
      text: "¿Desea eliminar el convenio?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Eliminar",
      buttonsStyling: false,
      customClass: {
        confirmButton: "btn-amarillo order-2 mx-1",
        cancelButton: "btn-brown order-1 mx-1",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        this.socioFormadorService
          .deleteCartaConvenio(idConvenio, idUsuario)
          .pipe(
            tap((resultado: any) => {
              if (resultado) {
                Swal.fire({
                  title: "Socio Formador - Convenio",
                  text: "Convenio eliminado.",
                  icon: "success",
                  buttonsStyling: false,
                  customClass: {
                    confirmButton: "btn-amarillo order-2 mx-1",
                  },
                });
                this.ngOnInit();
                this.convenioForm.reset();
                this.fileNameSelected = "";
              } else {
                this.toastr.error("Algo Salió mal!");
              }
            })
          )
          .subscribe();
      }
    });
  }

  continuarCarta() {
    this.convenioForm.markAllAsTouched();
    this.mostrarArchivoRequerido = false;
    this.mostrarArchivoRequerido = false;
    if (!this.img) {
      this.mostrarArchivoRequerido = true;
      return;
    }
    let personaFirma = this.convenioForm.get("personaFirma")?.value;
    let fechaIni = this.convenioForm.get("fechaInicio")?.value;
    let fechaFin = this.convenioForm.get("fechaFin")?.value;

    if (!personaFirma) {
      this.toastr.error(
        "Debe seleccionar o escribir el nombre de la persona que firma"
      );
      return;
    }
    if (fechaIni >= fechaFin) {
      this.toastr.error(
        "La fecha de inicio no puede ser mayor o igual que la fecha fin"
      );
      return;
    }
    let yearIni = fechaIni.toString().split("-")[0];
    let yearFin = fechaFin.toString().split("-")[0];

    if (yearFin - yearIni <= 0) {
      this.toastr.error("Debe tener al menos 1 año de diferencia");
      return;
    }

    if (this.convenioForm.valid) {
      this.actualizarSolicitud("aprobado", true, "A");
    }
  }

  async continuarCarta2() {
    this.chkDocumentosEntregados.markAsTouched();
    // Se comenta a petición de Lulú F2.5 - Incidencia 285
    // if (!this.chkDocumentosEntregados.valid) {
    //   this.toastr.error("Debe seleccionar si los documentos fueron entregados");
    //   return;
    // }

    // Revisamos si tiene un registro de carta
    let result;
    try{
      if (this.idSocioFormador > 0) {
        result = await lastValueFrom(this.socioFormadorService.getCartaConvenio(this.idSocioFormador));
      }
    }catch(error)
    {
        if(result === undefined || result.length == 0){
          this.toastr.warning("Debe generar la Carta Convenio antes de continuar.");
          return;
          }
      }

    this.actualizarSolicitud("aprobado", false, "B");
  }
  chkDocumentosEntregados = new FormControl("", [Validators.required]);

  generarContrato() {
    this.convenioForm2.markAllAsTouched();
    if (this.convenioForm2.valid) {
      let ctrTipoPersona = this.convenioForm2.get("tipoPersona");
      let ctrFechaInicio = this.convenioForm2.get("fechaInicio");
      let ctrFechaFin = this.convenioForm2.get("fechaFin");
      let idUsuario = this.usuarioService.getCurrentUserData().idUsuario;

      if (ctrFechaInicio?.value >= ctrFechaFin?.value) {
        this.toastr.error(
          "La fecha de inicio no puede ser mayor o igual que la fecha fin"
        );
        return;
      }
      let yearIni = ctrFechaInicio?.value.toString().split("-")[0];
      let yearFin = ctrFechaFin?.value.toString().split("-")[0];

      if (yearFin - yearIni <= 0) {
        this.toastr.error("Debe tener al menos 1 año de diferencia");
        return;
      }

      let sPersona = ctrTipoPersona?.value == "fisica" ? "Física" : "Moral";
      let iCategoria = ctrTipoPersona?.value == "fisica" ? 4 : 5;
      Swal.fire({
        title: "Confirmar generación de carta solicitud",
        text: "¿Desea generar la carta de solicitud como Persona " + sPersona + "?",
        confirmButtonText: "Si",
        cancelButtonText: "No",
        showCancelButton: true,
        buttonsStyling: false,
        customClass: {
          confirmButton: "btn-amarillo order-2 mx-1",
          cancelButton: "btn-brown order-1 mx-1",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          let results: any = await lastValueFrom(
            this.solicitudesService.postGeneraCartaPDF({
              listaIds: [
                {
                  campo: "idSocioFormador",
                  id: this.idSocioFormador,
                },
                {
                  campo: "fechaIni",
                  id: Number(ctrFechaInicio?.value.replaceAll("-", "")),
                },
                {
                  campo: "fechaFin",
                  id: Number(ctrFechaFin?.value.replaceAll("-", "")),
                },
                {
                  campo: "idUsuario",
                  id: idUsuario,
                },
                {
                  campo: "idConvenio",
                  id: 0,
                },
              ],
              idCategoria: iCategoria,
            })
          );
          this.urlPDFGenerado2 = results.payload.urlFile;
          // borrar convenios anteriores
          this.borrarConveniosAnteriores();

          this.cartaGenerada = true;
        }
      });
    }
  }

  seleccionarCampus($event) {
    this.campusSeleccionado = $event?.target?.value ?? $event.idCampus;
  }


  reasignarSolicitud() {
    let storeUser = this.usuarioService.getCurrentUserData();
    Swal.fire({
      input: "textarea",
      inputLabel: "Motivo de reasignación",
      inputPlaceholder: "Escribe el motivo de reasignación",
      inputAttributes: {
        "aria-label": "Escribe el motivo de reasignación",
      },
      showCancelButton: true,
      buttonsStyling: false,
      customClass: {
        confirmButton: "btn-amarillo order-2 mx-1",
        cancelButton: "btn-brown order-1 mx-1",
      },
    }).then((response) => {
      if (response.isConfirmed) {
        this.motivoRechazo = response.value;

        this.solicitudesService
          .putSolicitudEstatus({
            idUsuario: storeUser.idUsuario,
            idSolicitud: this.idSolicitud,
            claveEstatus: "reasignado",
            idAcademia: 0,
            idCampus: this.campusSeleccionado,
            motivoEstatus: this.motivoRechazo,
          })
          .subscribe({
            next: (resultOK) => {
              this.toastr.success("Solicitud reasignada con éxito.");
              this.router.navigateByUrl("/dashboard");
            },
            error: (error) => {
              this.toastr.error("Hubo un error, intenta más tarde.");
              console.log("reasignado", error);
            },
          });
      }
    });
  }

  handleAceptar() {
    this.showFormularioCancelacion = false;
    this.showFormularioReasignacion = false;
    this.showFormularioContinuar = true;
  }

  handleRechazar() {
    this.showFormularioContinuar = false;
    this.showFormularioReasignacion = false;
    this.showFormularioCancelacion = true;
  }

  handleReasignar() {
    this.showFormularioContinuar = false;
    this.showFormularioCancelacion = false;
    this.showFormularioReasignacion = true;
  }

  async getcampusData() {
    let response1 = <any>await lastValueFrom(this.catalogo.getCampus());
    this.campusData = response1;
  }


  mostrarOpcionesDeCarta() {
    Swal.fire({
      title: " Selecciona la carta:",
      html: `<p class="">Selecciona la carta que deseas descargar.<br/></p>
      <div class="offset-2 col-7">
      <ul>
        <li>Carta Persona Física  <a href="${this.URLCARTA_PF_PDF}" target="_blank" referrerpolicy="noopener noreferrer">PDF</a></li>
        <li>Carta Persona Moral   <a href="${this.URLCARTA_PM_PDF}" target="_blank" referrerpolicy="noopener noreferrer">PDF</a></li>
        <li>Carta Persona Física <a href="${this.URLCARTA_PF_DOC}" target="_blank" referrerpolicy="noopener noreferrer">DOC</a></li>
        <li>Carta Persona Moral  <a href="${this.URLCARTA_PM_DOC}" target="_blank" referrerpolicy="noopener noreferrer">DOC</a></li>
      </ul>
      </div>
        `,
    });
    return false;
  }

  comentariosData: ISociosSolicitudComentarios[] = [];
  async getComentariosSocios() {
    try {
      this.comentariosData = [];
      this.comentariosData = await lastValueFrom(
        this.solicitudesService.getsolicitudSFP_comentarios(
          this.idSocioFormador
        )
      );
    } catch (error) {
      console.log(error);
    }
  }

  async cambiarEstatusSeguimiento(
    cveEstatus: string,
    cveEstatusSeguimiento: string,
    comentarios: string
  ) {
    let idSocioFormador = this.idSocioFormador;
    let idUsuario = this.usuarioService.getCurrentUserData().idUsuario;

    let request: ISolicitudSocioComentarios = {
      idSocioFormador: idSocioFormador,
      claveEstatus: cveEstatus,
      claveEstatusSeguimiento: cveEstatusSeguimiento,
      comentarios: comentarios,
      idUsuarioRegistro: idUsuario,
    };

    await lastValueFrom(
      this.solicitudesService.postSolicitudSFP_comentarios(request)
    );
  }

  async borrarConveniosAnteriores() {
    try {
      if (this.idSocioFormador > 0) {
        let result = await lastValueFrom(
          this.socioFormadorService.getCartaConvenio(this.idSocioFormador)
        );
        let lstConvenio = result.sort((x, y) => y.idConvenio - x.idConvenio);
        let idUsuario = this.usuarioService.getCurrentUserData().idUsuario;

        for (let index = 1; index < lstConvenio.length; index++) {
          await lastValueFrom(
            this.socioFormadorService.deleteCartaConvenio(
              lstConvenio[index].idConvenio,
              idUsuario
            )
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  async getEstatus() {
    try {
      this.estatusData = await lastValueFrom(this.catalogo.getEstatus());
    } catch (error) {
      console.log(error);
    }
  }

  obtenerEstatusSeguimiento(estatus: string) {
    return this.estatusData.find((x) => x.claveEstatus == estatus).estatus;
  }

  getAreaDescription(area: string) {
    return this.areasData.find(x=>x.idArea.toString() == area.toString())?.area;
  }

  async checkCartaConvenio(){
    let result;
    try{
      if (this.idSocioFormador > 0) {
        result = await lastValueFrom(this.socioFormadorService.getCartaConvenio(this.idSocioFormador));
      }
    }catch(error)
    {
      if(this.solicitud.claveEstatus === 'aprobado'){
        if(result === undefined || result.length == 0){
          this.toastr.warning("Debe generar la Carta Convenio antes de continuar.");
          this.showFormularioContinuar = true;
          }
        }
      }

    }

}
