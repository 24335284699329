import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-header-table',
  templateUrl: './header-table.component.html',
  styleUrls: ['./header-table.component.css'],
})
export class HeaderTableComponent  {
  @Input()
  columns: any;

  
}
