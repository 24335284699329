<div class="row" *ngIf="status !== 'formalizacion' || clavePerfil === 'socio'">
  <div class="section my-4">
    <span class="title-option ">RECURSOS</span>

    <div class="row" *ngFor="let recurso of recursosList; let i=index">
      <div class="col-12">
        <span class="title-option-small mt-2">Recurso {{i+1}} <br /></span>

        <label class="title-option-small mt-1" for="descripcion{{i}}"><u>a. Tipo de recurso:</u>&nbsp;</label>
        <label class="txt-option-small"> {{recurso.tipoRecurso}} </label><br>
        <label class="title-option-small" for="descripcion{{i}}"><u>Descripción:</u>&nbsp;</label>
        <label class="txt-option-small">{{recurso.descripcion}}</label><br>

        <label class="title-option-small" for="momento{{i}}"><u>b. Momento de la entrega:</u>&nbsp;</label>
        <label class="txt-option">{{recurso.temporalidad }}</label><br>
      </div>

    </div>

    <div *ngIf="recursosList.length == 0">
      <span class="txt-option"> No hay recursos registrados.</span>
    </div>

  </div>
</div>

<div class="section">
  <div class="row " *ngIf="status === 'formalizacion'  && ['cvdp','cvdp_admin','academia'].includes(clavePerfil)">
    <div class="col-1"><button class="step-button-active text-center"> 8 </button>
    </div>
    <div class="col-11">
      <span class="title-option">Especifica los recursos acordados (humanos, logísticos, económicos o de
        estructura). <span class="text-danger">*</span></span>

      <div class="row row-item my-2" *ngFor="let recurso of recursosList; let i=index">
        <div class="col-11">
          <span class="title-option-small mt-2">Recurso {{i+1}}</span>
          <br />
          <label class="title-option-small mt-1" for="descripcion{{i}}">Tipo de recurso:&nbsp;</label>
          <label class="txt-option-small"> {{recurso.tipoRecurso}} </label><br>
          <label class="title-option-small" for="descripcion{{i}}">Descripción de la actividad:&nbsp;</label>
          <label class="txt-option-small">{{recurso.descripcion}}</label><br>

          <label class="title-option-small" for="momento{{i}}">Momento:&nbsp;</label>
          <label class="txt-option-small">{{recurso.temporalidad }}</label><br>
        </div>
        <div class="col-1 d-flex align-items-center">
          <button type="button" class="lst_bt_brr" (click)="eliminar(recurso.idRetoRecurso)"></button>
        </div>
      </div>


      <!-- Button trigger modal -->
      <button type="button" class="add_button mt-4 mb-4" data-bs-toggle="modal" data-bs-target="#recursosFormModal">
        Agregar recursos
      </button>

      <!-- Modal -->
      <div class="modal fade" id="recursosFormModal" data-bs-backdrop="static" tabindex="-1"
        aria-labelledby="recursosFormModalLabel" aria-hidden="true" data-bs-keyboard="false" tabindex="-1">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="recursosFormModalLabel">Agregar un recursos</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" #closebuttonRecursos></button>
            </div>
            <div class="modal-body">
              <form [formGroup]="recursosForm" class="my-3">

                <span class=""><u>Especifica el tipo de recurso</u> <span class="text-danger">*</span></span>
                <div class="select-dropdown">
                  <ng-select [items]="tipoRecurso" bindLabel="tipoRecurso" bindValue="idTipoRecurso"
                    [placeholder]="'Selecciona una opción'" formControlName="idTipoRecurso">
                  </ng-select>
                </div>
                <div class="required"
                  *ngIf="recursosForm.get('idTipoRecurso')?.touched && recursosForm.get('idTipoRecurso')?.errors?.['required']">
                  Este campo es requerido</div>
                <br>
                <span class=""> <u> Descripción: </u></span>
                <input class="form-control" id="descripcion" type="text" formControlName="descripcion"
                  placeholder="Escribe aquí la descripción del recurso">
                <div class="required"
                  *ngIf="recursosForm.get('descripcion')?.touched && recursosForm.get('descripcion')?.errors?.['required']">
                  Este campo es requerido</div>
                <br>

                <span class=""> <u> Especifica en qué momento requieres el recurso (temporalidad)
                  </u></span>
                <input class="form-control text-secondary" id="temporalidad" type="text" formControlName="temporalidad"
                  placeholder="Escribe aquí en qué momento requieres el recurso">
                <div class="required"
                  *ngIf="recursosForm.get('temporalidad')?.touched && recursosForm.get('temporalidad')?.errors?.['required']">
                  Este campo es requerido</div>
                <br>

              </form>
            </div>
            <div class="modal-footer">
              <button type="button" class="add_form_button mt-4 mb-4" (click)="addRecurso()">Guardar</button>
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
            </div>
          </div>
        </div>
      </div>




    </div>
  </div>
</div>
