<div class="text-secondary" *ngIf="status == 'formalizacion' && ['cvdp','cvdp_admin','academia'].includes(clavePerfil)">Documenta paso a paso la siguiente información</div>
<div class="subtitlecard textblue" *ngIf="status != 'formalizacion'"> SOBRE LOS PROFESORES</div>
<div class="subtitlecard textblue" *ngIf="status == 'formalizacion'">PASO 1: SOBRE LOS PROFESORES</div>
<div class="section">

    <div class="text-secondary" *ngIf="status == 'formalizacion' && ['cvdp','cvdp_admin','academia'].includes(clavePerfil)">Selecciona al(a) profesor(a) coordinador(a) del reto.</div>

    <button type="button" class="yell_button mt-2" 
    *ngIf="status == 'formalizacion' && ['cvdp','cvdp_admin','academia'].includes(clavePerfil)"
     (click)="mostrarProfesores()">Asignar el Profesor
        coordinador</button>

    <div class="section-reto" *ngIf="mostrarSeccionProfesores">

        <div class="subtitlecard textblue ml-2">Profesor coordinador del reto </div>
        <div class="title-option">Nombre: </div>
        <div class="txt-option"> {{ profesorCordinador.nombreUsuario ?? "-" }} </div>
        <div class="title-option">Apellidos: </div>
        <div class="txt-option"> {{ profesorCordinador.apellidoUsuario ?? "-" }} </div>
        <div class="title-option">Correo electrónico: </div>
        <div class="txt-option"> {{ profesorCordinador.correoUsuario ?? "-" }} </div>
        <div class="title-option">Rol académico: </div>
        <div class="txt-option"> {{ profesorCordinador.rolAcademico ?? "-" }} </div>

        <div class="mt-2 pt-0 mb- pb-0">
            <div class="subtitlecard textblue">Profesores implementadores </div>
        </div>

        <div class="">
            <div class="row">
                <div class="col-4 my-3" *ngFor="let profe of profesorImplementador">
                    <button type="button" class="col-8 blue_button my-3"
                        *ngIf="['cvdp','cvdp_admin','academia'].includes(clavePerfil) && status == 'formalizacion'"
                        (click)="convertirCoordinador(profe)">Seleccionar como coordinador</button>
                    <div class="title-option">Nombre:</div>
                    <div class="txt-option"> {{ profe.nombreUsuario ?? "-"}}</div>
                    <div class="title-option">Apellidos:</div>
                    <div class="txt-option">{{ profe.apellidoUsuario ?? "-" }}</div>
                    <div class="title-option">Correo electrónico:</div>
                    <div class="txt-option">{{ profe.correoUsuario ?? "-"}}</div>
                    <div class="title-option">Rol académico:</div>
                    <div class="txt-option">{{ profe.rolAcademico ?? "-"}}</div>
                    <div class="title-option">Periodo(s) que imparte:</div>
                    <div class="txt-option">{{ getPeriodoSemanalPorId(profe.idPeriodo) }}</div>
                </div>

                <div class="" *ngIf="profesorImplementador.length == 0">
                    <span class="txt-option"> No hay profesores implementadores. </span>
                </div>

            </div>
        </div>

    </div>
</div>