import { Component, OnInit, Input } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ICasosExito, IResultadoReto } from "@shared/Interface/ICasosExito";
import { IRetoResponse } from "@shared/Interface/IReto.interface";
import { AzureBlobStorageService } from "@shared/service/AzureBlobStorage.Service";
import { CasosDeExitoService } from "@shared/service/Casos_de_Exito/casos-de-exito.service";
import { RetosService } from "@shared/service/Retos/retos.service";
import { environment } from "environments/environment";
import { ToastrService } from "ngx-toastr";
import { lastValueFrom } from "rxjs";

@Component({
  selector: "app-postular-caso",
  templateUrl: "./postular-caso.component.html",
  styleUrls: ["./postular-caso.component.css"],
})
export class PostularCasoComponent implements OnInit {
  postularForm: FormGroup;
  @Input() idReto: number;
  @Input() status: string;
  @Input() oReto: any;

  lbl_url_img: string = "";
  lbl_url_evidencia: string = "";
  lbl_url_img_fn: string = "";
  lbl_url_evidencia_fn: string = "";
  casoExitoActual: any;

  constructor(
    private fb: FormBuilder,
    private retosService: RetosService,
    private toastr: ToastrService,
    private casoExitoService: CasosDeExitoService,
    private azureBlobStorage: AzureBlobStorageService
  ) {}

  ngOnInit(): void {
    this.verificarCasoExito();
  }

  async verificarCasoExito() {
    let ce: any;
    this.postularForm = this.fb.group({
      idReto: [this.idReto],
      resultados: ["", Validators.required],
      link: ["", Validators.required],
      imagen: ["", Validators.required],
      evidencia: ["", Validators.required],
    });
    this.casoExitoActual = null;
    try {
      ce = await lastValueFrom(
        this.casoExitoService.getCasoExitoPoridReto(this.idReto)
      );
      this.casoExitoActual = ce;
      this.lbl_url_evidencia = ce.urlEvidencia;
      this.lbl_url_img = ce.urlImagenCasoExito;

      this.postularForm.patchValue({
        resultados: ce.descripcionCasoExito,
        link: ce.urlConectaCasoExito,
        imagen: ce.urlImagenCasoExito,
        evidencia: ce.urlEvidencia,
      });
    } catch (err) {
      console.log(err);
    }
  }

  async guardarCasoExito() {
    let anio = new Date().getFullYear();

    let request = {
      idCasoExito: this.casoExitoActual?.idCasoExito ?? 0,
      idReto: this.idReto,
      nombreCasoExito: this.oReto.reto,
      descripcionCasoExito: this.postularForm.get("resultados")?.value,
      anoCasoExito: anio,
      idEscuela: this.oReto.idEscuela,
      idCampus: this.oReto.idcampus,
      urlImagenCasoExito: this.lbl_url_img,
      urlConectaCasoExito: this.postularForm.get("link")?.value,
      urlEvidencia: this.lbl_url_evidencia,
      estatusCasoExito: "activo",
    };

    if ((this.casoExitoActual?.idCasoExito ?? 0) == 0)   {
    try {
      let resultado = await lastValueFrom(
        this.casoExitoService.postResultadoReto({
          idReto: this.idReto,
          resultado: this.postularForm.get("resultados")?.value,
          urlInformacionResultado: this.postularForm.get("link")?.value,
          urlFotoResultado: this.lbl_url_img,
        })
      );
    } catch (err) {
      console.log(err);
    }
  }

    try {
      if ((this.casoExitoActual?.idCasoExito ?? 0) == 0) {
        let res = await lastValueFrom(this.casoExitoService.postCasos(request));
        this.casoExitoActual = res;
      } else {
        await lastValueFrom(this.casoExitoService.putCasos(request));
      }

      this.toastr.success("Se ha guardado el caso exitosamente");
    } catch (er) {
      this.toastr.error("Ocurrió un error, intente más tarde");
      console.log(er);
    }
  }

  async selImagen(event: any, fileType: string) {
    "imagen" == fileType
      ? (this.lbl_url_img_fn = "Subiendo Archivo...")
      : (this.lbl_url_evidencia_fn = "Subiendo Archivo...");

    let selFile = (event.target as HTMLInputElement).files?.[0];
    if (!selFile) return;

    // Validate images only
    if (selFile.type != "image/jpeg" && selFile.type != "image/png") {
      this.toastr.error("Por favor selecciona únicamente imágenes (*.jpeg, or .png) ");
      "imagen" == fileType
      ? (this.lbl_url_img_fn = "")
      : (this.lbl_url_evidencia_fn = "");
      return;
    }
    let fileName = this.getFileName(selFile, fileType);
    await this.uploadRetoImage(selFile, fileName, fileType);
  }

  async uploadRetoImage(selFile, fileName, fileType) {
    this.azureBlobStorage
      .uploadFile(
        {
          containerName: environment.AzureBlob_containerNameRetos,
          file: selFile,
          filename: fileName,
        },
        environment.AzureBlob_ACCOUNT_NAME,
        environment.AzureBlob_retosSAS
      )
      .then((res) => {
        let fileUrlLogo = `${environment.AzureBlob_retosURL}${fileName}`;

        if (fileType == "imagen") {
          this.lbl_url_img = fileUrlLogo;
          this.lbl_url_img_fn = "";
        } else {
          this.lbl_url_evidencia = fileUrlLogo;
          this.lbl_url_evidencia_fn = "";
        }
      });
  }

  getFileName(file, fileType) {
    let id = Date.now().toString();
    return `caso_exito_${id}_${fileType}.${
      file.name.split(".")[1]
    }`.toLowerCase();
  }
}
