<div>
    <div class="subtitlecard textblue" *ngIf="status != 'formalizacion' || 'socio' == clavePerfil">CARTA DE COLABORACIÓN </div>
    <div class="subtitlecard textblue" *ngIf="status == 'formalizacion' && ['cvdp','cvdp_admin','academia'].includes(clavePerfil)">PASO 4: CARTA DE COLABORACIÓN </div>

    <div class="section">

        <div class="text-secondary"
            *ngIf="status == 'formalizacion' && ['cvdp','cvdp_admin','academia'].includes(clavePerfil)"> En esta sección
            podrás cargar una carta de colaboración en caso de que ya tengas el documento en pdf firmado, ó generar una carta de colaboración digital para que sea firmada por este medio.
        </div>

        <div class="mt-4 bold"
            *ngIf="status == 'formalizacion' && ['cvdp','cvdp_admin','academia'].includes(clavePerfil)">
            <button (click)="mostrarCartacolaboracion()" class="yell_button">Cargar o generar carta de colaboración </button>
        </div>

        <div *ngIf="mostrarSeccionCartaColaboracion">

            <!-- Existe una carta de colaboración -->
            <div class="row my-4 bg-white rounded-3 p-2 col-xs-12 col-md-6 align-items-center"
                *ngFor="let carta of cartaColaboracion">
                <div class="col-6">
                    <a href="{{ carta.urlCartaColaboracion }}" target="_blank" rel="noopener noreferrer">Carta
                        Colaboración</a>
                </div>
                <div class="col-5">
                    {{ carta.nombreFirmante }}
                </div>
                <div class="col-1 d-flex align-items-center" *ngIf="['cvdp','cvdp_admin','academia'].includes(clavePerfil)">
                    <button type="button" class="lst_bt_brr"
                        (click)="eliminarCartaColaboracion(carta.idColaboracion)"></button>
                </div>
            </div>

            <!-- No existe una carta de colaboración -->
            <div *ngIf="cartaColaboracion.length == 0 && ['cvdp','cvdp_admin','academia'].includes(clavePerfil)">

                <div class="my-4 title-option d-none">
                    <button (click)="firmar()" class="yell_button">Firmar y enviar carta de
                        colaboración</button>
                </div>

                <div >

                    <div class="col-12 mt-3">
                        <span class="title-option"> a. Si ya cuentas con tu carta de colaboración en pdf cárgala aquí</span>
                    </div>

                    <!-- Seleccion de Firmante -->
                    <div class="mt-3 col-4 ">
                        <label class="txt-option">Selecciona el contacto que firma la carta: <span
                                class="required">*</span></label>
                        <ng-select bindLabel="nombre" [items]="lstContactos" bindValue="idUsuario"
                            (change)="setContacto($event)"></ng-select>
                    </div>
                    <div class="col-8">&nbsp;</div>

                    <!-- Selección de fecha -->
                    <div class="mb-1 col-4 ">
                        <label class="txt-option">Selecciona la fecha de firma de la carta: <span
                                class="required">*</span></label>
                        <input class="form-control" type="date" (change)="setFechaFirmaCartaColaboracion($event)" required placeholder="dd/mm/yyyy" value="" min="2000-01-01" max="2100-12-31">
                        
                    </div>
                    <div class="col-8">&nbsp;</div>

                    <!-- Cargar carta existente -->
                    <div class="col-12 mt-2 ms-3">

                        <button type="button" class="blue_button" (click)="file.click()">Cargar archivo</button>

                        <br>
                        <div>{{ estatusFile }}</div>
                        <br>

                        <input class="d-none" type="file" (change)="selImagen($event)" id="fileConvenioUpload"
                            accept="application/pdf" #file />

                    </div>

                    <div class="col-12">
                        <span class="title-option"> b. Si requieres generarla</span>
                    </div>
                    <!-- Generar una carta -->
                    <div class="col-12 mt-2 ms-3">
                        <button type="button" class="blue_button"
                            (click)="generarColaboracion()">Generar carta de colaboración</button>
                    </div>

                </div>
            </div>

            <!-- No existe carta colaboracion -->
            <div *ngIf="cartaColaboracion.length == 0 && ['socio'].includes(clavePerfil)">
                <span class="txt-option">No hay carta colaboracion registrada</span>
            </div>
        </div>
    </div>
</div>