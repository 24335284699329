import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { COLS_SOLICITUDES_SF_POTENCIAL } from "@pages/dashboard/cvdp-dashboard/cvdp-dashboard.columns";
import {
  ICampus,
  IEscuela,
  IPeriodo,
} from "@shared/Interface/ICatalogos.interface";
import { CatalogosService } from "@shared/service/Catalogos/catalogos.service";
import { DashboardService } from "@shared/service/Dashboard/dashboard.service";
import { UsuariosService } from "@shared/service/Usuarios/usuarios.service";
import { lastValueFrom } from "rxjs";
import { tap } from "rxjs/internal/operators/tap";

@Component({
  selector: "app-solicitudes-socios",
  templateUrl: "./solicitudes.component.html",
  styleUrls: ["./solicitudes.component.css"],
})
export class SolicitudesComponent implements OnInit {
  lang: string = "es";
  title = {
    title: "Solicitudes de Socios Formadores potenciales",
  };

  constructor(
    private catalogosService: CatalogosService,
    private dashboardService: DashboardService,
    private usuarioService: UsuariosService,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.lang = localStorage.getItem("lang") ?? "es";
    this.loadCatalogos();
    this.loadData();
  }

  changeLang(lang: string) {
    this.lang = lang;
    localStorage.setItem("lang", lang);
    window.location.reload();
  }

  campusData: ICampus[] = [];
  escuelaData: IEscuela[] = [];
  periodoData: IPeriodo[] = [];


  getCatEstatusSeguimiento(claveEstatus:string, estatus: string, clave: string){
    if (claveEstatus === "revision") {
      let catEstatusSeguimiento = {
        "conversacion": "Conversación Inicial",
        "primer-contacto": "Primer Contacto",
        "validacion-doc": "Validación de documentos"
      };
      return catEstatusSeguimiento[clave] ?? 'Revisión';
    } else if (claveEstatus === "aprobado") {
      let catEstatusSeguimiento = {
        "pendiente-firma": "Pendiente de Firma",
        rechazado: "Rechazada por el SF",
        firmado: "Firmado",
      };
      return catEstatusSeguimiento[clave] ?? "Pendiente de Firma";
    } else {
      return estatus;
    }
  }

  filter: any = {
    query: "",
    campus: 0,
    escuela: 0,
    periodo: 0,
  };

  loadCatalogos() {
    this.catalogosService
      .getCampus()
      .pipe(tap((resultado: ICampus[]) => (this.campusData = resultado)))
      .subscribe();

    this.catalogosService
      .getEscuelas()
      .pipe(tap((resultado: IEscuela[]) => (this.escuelaData = resultado)))
      .subscribe();

    this.catalogosService
      .getPeriodo(false)
      .pipe(tap((resultado: IPeriodo[]) => (this.periodoData = resultado)))
      .subscribe();
  }

  getValueCatalogo(obj: any, index: string, id: number, indexValue: string) {
    let obje = obj.filter((x: any) => x[index] === id).pop();
    if (obje) {
      return obje[indexValue];
    } else {
      return null;
    }
  }

  addFilter(type: string, id: number) {
    this.filter[type] = id;
    this.loadData();
  }

  cleanFilters() {
    this.filter = {
      query: "",
      campus: 0,
      escuela: 0,
      periodo: 0,
    };
    this.loadData();
  }

  backgroundTable = "#F6F6F6";
  solicitudesColumns = COLS_SOLICITUDES_SF_POTENCIAL;

  solicitudesRegistradasData: any;
  solicitudesRevisionData: any;
  solicitudesAprobadasData: any;
  solicitudesRechazadasData: any;


  solicitudesRegistradas: any[] = [];
  solicitudesRecibidas: any[] = [];
  solicitudesAprobadas: any[] = [];

  solicitudesRegistradasPaginaActual: any[] = [];
  solicitudesRevisionPaginaActual: any[] = [];
  solicitudesAprobadasPaginaActual: any[] = [];
  solicitudesRechazadasPaginaActual: any[] = [];

  solicitudesPaginaActual: any[] = [];

  pagina: number = 0;
  hits: number = 0;

  async loadData() {
    this.solicitudesRegistradasData = [];
    this.solicitudesRevisionData = [];
    this.solicitudesAprobadasData = [];
    this.solicitudesRechazadasData = [];

    this.solicitudesRegistradasPaginaActual =[];
    this.solicitudesRevisionPaginaActual = [];
    this.solicitudesRecibidas = [];
    this.solicitudesRechazadasPaginaActual = [];

    let userStore = this.usuarioService.getCurrentUserData();
    let idUsuario =
      userStore.clavePerfil === "cvdp_admin" ? 0 : userStore.idUsuario;

    let response = await lastValueFrom(
      this.dashboardService.getSolicitudesSocio(
        idUsuario,
        "registrado",
        this.filter.campus,
        this.filter.escuela,
        this.filter.periodo,
        this.pagina,
        this.hits
      )
    );
    this.solicitudesRegistradasData = this.getSolicitudesSFPotenciales(response);
    this.showPaginationRegistradoData(this.solicitudesRegistradasData.slice(0, 10));

    response = await lastValueFrom(
      this.dashboardService.getSolicitudesSocio(
        idUsuario,
        "revision",
        this.filter.campus,
        this.filter.escuela,
        this.filter.periodo,
        this.pagina,
        this.hits
      )
    );
    this.solicitudesRevisionData = this.getSolicitudesSFPotenciales(response);
    this.showPaginationRevisionData(this.solicitudesRevisionData.slice(0, 10));

    response = await lastValueFrom(
      this.dashboardService.getSolicitudesSocio(
        idUsuario,
        "aprobado",
        this.filter.campus,
        this.filter.escuela,
        this.filter.periodo,
        this.pagina,
        this.hits
      )
    );
    this.solicitudesAprobadasData = this.getSolicitudesSFPotenciales(response);
    this.showPaginationAprobadasData(
      this.solicitudesAprobadasData.slice(0, 10)
    );

    response = await lastValueFrom(
      this.dashboardService.getSolicitudesSocio(
        idUsuario,
        "rechazado",
        this.filter.campus,
        this.filter.escuela,
        this.filter.periodo,
        this.pagina,
        this.hits
      )
    );
    this.solicitudesRechazadasData = this.getSolicitudesSFPotenciales(response);
    this.showPaginationDataRechazado(
      this.solicitudesRechazadasData.slice(0, 10)
    );
  }

  getSolicitudesSFPotenciales(resultado) {
    return resultado.map((solicitudes: any) => ({
      fechaRegistro: solicitudes.fechaRegistro,
      link: {
        title: `${solicitudes.nombreSocioFormador}`,
        subtitle: solicitudes.rfc,
        description:
          solicitudes.idUsuarioRegistro > 0
            ? ` Académico: ${solicitudes.nombreUsuarioRegistro} - ${solicitudes.correoUsuarioRegistro}`
            : "",
        link: `/cvdp/solicitud-sf-potencial?solicitud=${solicitudes.idSolicitud}&CVDP=${solicitudes.idSocioFormador}`,
      },
      imageTitle: {
        title: solicitudes.nombreContacto ?? "-",
        subtitle: solicitudes.correoContacto ?? "-",
      },
      campus: solicitudes.campus,
      estatus:  this.getCatEstatusSeguimiento( solicitudes.claveEstatus, solicitudes.estatus, solicitudes.claveEstatusSeguimiento) ,
      claveEstatusSolicitud: {
        textCol: "Ver",
        statusType: "primary",
        link: `/cvdp/solicitud-sf-potencial?solicitud=${solicitudes.idSolicitud}&CVDP=${solicitudes.idSocioFormador}`,
      },
    }));
  }

  showPaginationRegistradoData(objData: any) {
    if (objData.length) {
      this.solicitudesRegistradasPaginaActual = objData;
      this.changeDetector.detectChanges();
    }
  }

  showPaginationRevisionData(objData: any) {
    if (objData.length) {
      this.solicitudesRevisionPaginaActual = objData;
      this.changeDetector.detectChanges();
    }
  }

  showPaginationAprobadasData(objData: any) {
    if (objData.length) {
      this.solicitudesAprobadasPaginaActual = objData;
      this.changeDetector.detectChanges();
    }
  }

  showPaginationDataRechazado(objData: any) {
    if (objData.length) {
      this.solicitudesRechazadasPaginaActual = objData;
      this.changeDetector.detectChanges();
    }
  }
}
