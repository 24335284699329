<app-header-buscador [showSearchBar]="false"></app-header-buscador>

<form [formGroup]="vinculacionForm" (submit)="edit_crear()">
  <div class="row form-perfil">
    <div class="col-md-3 text-center">
      <div>
        <img class="img_avt_ds" alt="logotipo" src="{{ imgUrl }}" />
      </div>
      <div><br />Imagen .JPG o .PNG</div>
      <small>Tamaño Máx: 1024x1024 < 3Mb</small><span class="text-danger">*</span>
      <div class="editFoto fileUploadButton" (click)="file.click()"><br />Editar Foto</div>
            <input class="hidden" type="file" (change)="selImagen($event)" accept="image/*" #file/>
      <app-button-user
        *ngFor="let button of buttons; let i = index"
        (callbackAction)="onClickButton(button.url)"
        [buttons]="button"
      ></app-button-user>
    </div>
    <div class="col-md-9 text-form">
      <div class="row">
        <div class="col" id="sec_tit">
          <h2>{{ titleProfile }}</h2>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <label for="tituloVinculacion"
            >Titulo <span class="text-danger">*</span></label
          >
          <app-text-input
            [formControl]="$any(vinculacionForm.controls['tituloVinculacion'])"
            [label]="'titulo'"
            [placeholder]="'ej. Bolsa de Trabajo'"
          ></app-text-input>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <label for="tituloVinculacion"
            >Tipo de Recurso <span class="text-danger">*</span></label
          >
          <div class="select-dropdown">
            <select formControlName="idTipoRecursoVinculacion" class="form-select">
                <option *ngFor="let el of ListaTipoRecursoVinculacion" [value]="el.idTipoRecursoVinculacion">{{el.tipoRecursoVinculacion}}</option>

            </select></div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <label for="descripcionVinculacion">Descripcion<span class="text-danger">*</span></label>
           <app-text-input
            [formControl]="$any(vinculacionForm.controls['descripcionVinculacion'])"
            [label]="'descripcionVinculacion'"
            [placeholder]="
              'ej. ¿Te interesa reclutar talento Tec? Ingresa...'
            "
          ></app-text-input>
        </div>
      </div>

      <div class="row" >
        <div class="col">
          <label for="linkVinculacion"
            >link </label
          >
          <app-text-input
            [formControl]="$any(vinculacionForm.controls['linkVinculacion'])"
            [label]="'linkVinculacion'"
            [placeholder]="'link externo: http://www.google.com    link interno: /bolsa_trabajo'"
          ></app-text-input>
        </div>
      </div>

      <div class="row">

        <div class="col-6 d-flex j1ustify-content-center">
          <app-button [buttonType]="'yell'"> {{ btnText }} </app-button>
        </div>
      </div>
    </div>
  </div>
</form>


<div
  class="modal fade"
  id="idModalCorreoExistente"
  tabindex="-1"
  aria-labelledby="ModalCorreoExistente"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">

        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <h3>{{ this.mensajeModal }}</h3>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" data-bs-dismiss="modal">
          Cerrar
        </button>
      </div>
    </div>
  </div>
</div>
