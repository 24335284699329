<app-header-buscador [showSearchBar]="false"></app-header-buscador>

<div class="sec_ad">
    <div id="sec_tit">
        <div class="d-flex align-items-center">
            <h2>{{ 'no_encontre_sf.titulo' | translate }}</h2>
        </div>
        <div class="flex-fill" [innerHTML]="'no_encontre_sf.subtitulo' | translate "></div>
    </div>

    <div class="sec_ad cont_tit">


        <form [formGroup]="enviarForm">

            <div class="row">
                <div class="col-md">
                    <div class="row">
                        <div class="row">
                            <div class="col">
                                <div class="col-md">

                                    <span class="#">{{ 'no_encontre_sf.p0' | translate }}</span>
                                    <div class="select-dropdown">
                                        <select formControlName="idRetoMaestro" class="form-select" required>
                                            <option *ngFor="let reto of retos" [value]="reto.idRetoMaestro">
                                                {{reto.reto}}
                                            </option>

                                        </select>
                                    </div><br>
                                    <label for="centregable">{{ 'no_encontre_sf.p1' | translate }}<span
                                            class="requerido">*</span></label>
                                    <textarea class="forma_area" id="centregable" formControlName="caracteristicas_sf"
                                        placeholder="{{ 'no_encontre_sf.p1_place' | translate }}"
                                        required></textarea><br>
                                    <div class="requerido" *ngIf="m['caracteristicas_sf'].touched && m['caracteristicas_sf'].errors?.['required']">Campo obligatorio</div>

                                </div>

                            </div>
                        </div>
                    </div><br>

                </div>

            </div>
        </form>
        <div class="row">
            <div class="d-flex justify-content-start"><button (click)="guardar()" class="yell_button">{{
                    'no_encontre_sf.enviar' | translate }}</button></div>

        </div>


    </div>
</div>
