import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reto-confirmacion',
  templateUrl: './reto-confirmacion.component.html',
  styleUrls: ['./reto-confirmacion.component.css'],
})
export class RetoConfirmacionComponent implements OnInit {
  titleConfirmReto = {
    title: 'Información del Reto',
  };
  carrer = '';

  constructor(private router: Router) {}

  ngOnInit(): void {
    const retoSelected: any = localStorage.getItem('retoSelected');
    const reto: any = JSON.parse(retoSelected);
    if (reto) {
      this.titleConfirmReto = {
        title: reto.reto,
      };
      this.carrer = reto.unidadFormativa;
    }
  }

  sendBancoRetos() {
    localStorage.removeItem('retoSelected');
    localStorage.removeItem('retosMatch');
    this.router.navigateByUrl('/dashboard');
  }
}
