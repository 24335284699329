import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { COLS_BUSQUEDA_SOCIOS_FORMADORES } from "@pages/dashboard/academia-dashboard/academia-dashboard.columns";
import Utils from "@shared/helpers/utils";
import { IAcademiaSolicitudesSFResponse } from "@shared/Interface/IAcademiaSolicitudesSFResponse";
import {
  IEscuelaDepartamento,
  IPeriodoSemanal,
  IPeriodoSemestral,
} from "@shared/Interface/ICatalogos.interface";
import { CatalogosService } from "@shared/service/Catalogos/catalogos.service";
import { DashboardService } from "@shared/service/Dashboard/dashboard.service";
import { InicioService } from "@shared/service/Inicio/inicio.service";
import { UsuariosService } from "@shared/service/Usuarios/usuarios.service";
import { environment } from "environments/environment";
import { lastValueFrom, map, tap } from "rxjs";

@Component({
  selector: "app-retos-busqueda-sf",
  templateUrl: "./retos-busqueda-sf.component.html",
  styleUrls: ["./retos-busqueda-sf.component.css"],
})
export class RetosBusquedaSFComponent implements OnInit {
  title = {
    title: "Mis búsquedas de Socios Formadores",
  };

  lang: string = "es";

  solicitudAcadBuscaSocioFormadorData: IAcademiaSolicitudesSFResponse[] = [];
  solicitudAcadBuscaSocioFormadorDataPage: IAcademiaSolicitudesSFResponse[] = [];

  idAcademico: number = 0;
  estatus: string = "todas";
  registrosPagina: number = environment.RegistrosPagina;

  backgroundTable = "#F6F6F6";

  // ** Mis busquedas de Socios Formadores
  retosSocioColumns = COLS_BUSQUEDA_SOCIOS_FORMADORES;
  retosSocioData: any;

  periodoSemestralData: IPeriodoSemestral[] = [];
  periodoSemanalData: IPeriodoSemanal[] = [];
  departamentoData: IEscuelaDepartamento[] = [];

  filter: any = {
    query: "",
    idPeriodoSemestral: 0,
    idPeriodoSemanal: 0,
    departamento: 0,
  };

  pagina: number = 0;
  hits: number = 0;
  localdb: any = [];

  constructor(
    private dashboardService: DashboardService,
    private usuarioService: UsuariosService,
    private router: Router,
    private catalogosService: CatalogosService,
    private changeDetector: ChangeDetectorRef,
    private inicioService: InicioService
  ) {}

  ngOnInit() {
    this.lang = localStorage.getItem("lang") ?? "es";
    this.loadCatalogos();
    this.idAcademico = this.usuarioService.getCurrentUserData().idUsuario;
    this.getListasolicitudesSFActivos();
  }

  changeLang(lang: string) {
    this.lang = lang;
    localStorage.setItem("lang", lang);
    window.location.reload();
  }

  // Se agrega función compatible con el buscador MainLayoutBuscador
  buscadorPress(keyWord: any) {
    // Aquí iría lo que el padre tenga que hacer
    localStorage.removeItem("localdb");
    localStorage.removeItem("localdbFilters");

    if (
      keyWord !== null &&
      keyWord !== undefined &&
      keyWord.toString().trim() !== ""
    ) {
      this.filter.query = keyWord;
    } else {
      this.filter.query = "";
    }
    this.getListasolicitudesSFActivos();
  }

  async getListasolicitudesSFActivos() {
    try {
      this.solicitudAcadBuscaSocioFormadorDataPage = [];
      this.solicitudAcadBuscaSocioFormadorData = [];

      this.chekfilters();
      let tmp = localStorage.getItem("localdb");
      //if (tmp) {
        this.localdb = tmp ? JSON.parse(tmp) : '';
        if (this.localdb.solicitudes) {
          this.solicitudAcadBuscaSocioFormadorData = this.localdb.solicitudes;
        //}
      } else {
        this.solicitudAcadBuscaSocioFormadorData = await lastValueFrom(
          this.dashboardService
            .getAcademiaSolicitudesSF(
              this.idAcademico,
              this.estatus,
              this.filter.idPeriodoSemestral,
              this.filter.idPeriodoSemanal,
              this.filter.departamento,
              this.filter.query
            )
            .pipe(map((resultado) => this.getBusquedaSocioFormador(resultado)))
        );

        this.saveFilters();
        let vinculacionesList = {
          solicitudes: this.solicitudAcadBuscaSocioFormadorData
        };

        localStorage.setItem("localdb", JSON.stringify(vinculacionesList));
      }
    } catch (error) {
      Utils.processErrorResponse(
        error,
        "Error al obtener los académicos buscando un SF"
      );
    }
  }

  showPaginationDataRevision(objData: any) {
    if (objData.length) {
      this.solicitudAcadBuscaSocioFormadorDataPage = objData;
      this.changeDetector.detectChanges();
    }
  }

  saveFilters(){
    let filters = {
        query:              this.filter.query,
        idPeriodoSemestral: this.filter.idPeriodoSemestral,
        idPeriodoSemanal:   this.filter.idPeriodoSemanal,
        departamento:       this.filter.departamento
    };
    localStorage.setItem('localdbFilters',JSON.stringify(filters));
    this.inicioService.changeFilterQuery(this.filter.query);
  }

  chekfilters(){
    let tmp1 = localStorage.getItem('localdbFilters');
    let ret = false;
    if(tmp1){
      let tmp = JSON.parse(tmp1);

        this.filter.query = tmp.query;
        this.filter.idPeriodoSemestral = tmp.idPeriodoSemestral;
        if (this.filter.idPeriodoSemestral > 0)
           this.setFilter('idPeriodoSemestral', this.filter.idPeriodoSemestral);
        this.filter.idPeriodoSemanal = tmp.idPeriodoSemanal;
        if (this.filter.idPeriodoSemanal > 0)
           this.setFilter('idPeriodoSemanal', this.filter.idPeriodoSemanal);
        this.filter.departamento = tmp.departamento;
        if (this.filter.departamento > 0)
           this.setFilter('departamento', this.filter.departamento);

        ret = true;
    }
    return ret;
  }


  getBusquedaSocioFormador(resultado: any) {
    return resultado.map((solicitudes: any) => ({
      fechaSolicitud: solicitudes.fechaRegistro,
      periodo: solicitudes.periodoSemestral ?? "-",
      periodoSemanal: solicitudes.periodoSemanal ?? "-",
      reto: {
        title: solicitudes.reto,
        subtitle: `${solicitudes.escuela} `,
        link: `/solicitud/academico/${solicitudes.idAcademico}/${solicitudes.idRetoMaestro}/${solicitudes.idSolicitud}`,
      },
      link: {
        title:
          solicitudes.idSocioFormador == 0
            ? "No encontró un Socio Formador"
            : solicitudes.nombreSocioFormador,
        subtitle: solicitudes.rfc,
      },
      contactPerson: {
        title: solicitudes.nombreContacto,
        image: solicitudes.urlFotoContacto,
      },
      claveEstatusSolicitud: {
        textCol: "Ver",
        statusType: "primary",
        link: `/solicitud/academico/${solicitudes.idAcademico}/${solicitudes.idRetoMaestro}/${solicitudes.idSolicitud}`,
      },
      estatus: {
        textCol: "En revision",
        statusType: "ev_revision",
      },
    }));
  }

  loadCatalogos() {
    this.catalogosService
      .getPeriodoSemestral()
      .pipe(
        tap(
          (resultado: IPeriodoSemestral[]) =>
            (this.periodoSemestralData = resultado)
        )
      )
      .subscribe();

    this.catalogosService
      .getPeriodoSemanal()
      .pipe(
        tap(
          (resultado: IPeriodoSemanal[]) =>
            (this.periodoSemanalData = resultado)
        )
      )
      .subscribe();

    this.catalogosService
      .getDepartamentos()
      .pipe(
        tap(
          (resultado: IEscuelaDepartamento[]) =>
            (this.departamentoData = resultado.filter(
              (item) => item.departamento != "No Aplica" && item.idEscuela == this.usuarioService.getCurrentUserData().idEscuela
            ))
        )
      )
      .subscribe();
  }

  getValueCatalogo(obj: any, index: string, id: number, indexValue: string) {
    let obje = obj.filter((x: any) => x[index] === id).pop();
    if (obje) {
      return obje[indexValue];
    } else {
      return null;
    }
  }

  async addFilter(type: string, id: number) {
    localStorage.removeItem("localdb");
    localStorage.removeItem("localdbFilters");

    this.filter[type] = id;
    await this.getListasolicitudesSFActivos();
  }

  setFilter(type: string, id: number) {
    this.filter[type] = id;
  }

  async cleanFilters() {
    localStorage.removeItem("localdb");
    localStorage.removeItem("localdbFilters");

    this.filter = {
      query: "",
      idPeriodoSemestral: 0,
      idPeriodoSemanal: 0,
      departamento: 0,
    };
    await this.getListasolicitudesSFActivos();
  }

  verSolicitud(idReto: number, idAcademico: number, idSolicitud: number) {
    let url = `/solicitud/academico/${idAcademico}/${idReto}/${idSolicitud}`;
    this.router.navigateByUrl(url);
  }
}
