<app-header></app-header>
<div class="container " style="display: none;">
    <button class="btn btn-light" (click)="changeLang('es')">Español</button>
    <button class="btn btn-light" (click)="changeLang('en')">Inglés</button>
</div>
<div class="hero-login row">
    <img class="hero-der col-md-6 order-md-last" alt="" src="/assets/img/foto-3_1.png">
    <div class="hero-izq col-md-6 col-xs-12 order-md-first">
        <form (ngSubmit)="validaLogin()" class="frm_registro_sf" [formGroup]="loginForm">
            <div class="hero-texto1" [innerHTML]="'acceder_cuenta' | translate "></div>
            <div class="lblUsuario" [innerHTML]="'usuario' | translate"></div>
            <input class="input-text" type="text" name="user" id="user" formControlName="user">
            <div *ngIf="loginForm.get('user')?.touched && loginForm.get('user')?.errors?.['required']" class="error">
                El campo es requerido
            </div>

            <div class="lblPWd" [innerHTML]="'contrasena' | translate"></div>
            <input class="input-text" type="password" name="pwd" id="pwd" formControlName="pwd">
            <div *ngIf="loginForm.get('pwd')?.touched && loginForm.get('pwd')?.errors?.['required']" class="error">
                El campo es requerido
            </div>
            <div class="form-check login-remember">
                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                <label class="form-check-label" for="flexCheckDefault">
                    Recordar mi Usuario
                </label>
            </div>
            <br>
            <a href="#">
                <button class="btn-amarillo" (click)="validaLogin">Iniciar sesión</button>
            </a>
        </form>
        <a href="#">
            <div class="login-forgot">Olvidé mi contraseña</div>
        </a>
    </div>

</div>
<app-footer></app-footer>