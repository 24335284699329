import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { environment } from "environments/environment";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { UsuariosService } from "@shared/service/Usuarios/usuarios.service";
import { PaginadorLibsComponent } from "@shared/libs/paginador.component";
import { ICasosExito } from "@shared/Interface/ICasosExito";
import { CasosDeExitoService } from "@shared/service/Casos_de_Exito/casos-de-exito.service";
import { lastValueFrom } from "rxjs";
import Utils from "@shared/helpers/utils";

@Component({
  selector: "app-casos-de-exito",
  templateUrl: "./casos-de-exito.component.html",
  styleUrls: ["./casos-de-exito.component.css"],
})
export class CasosDeExitoComponent implements OnInit {
  data: any[];
  title: string = "Recursos de Viculacion Administraciòn";
  sidebarExpanded = false;
  lang: string = "es";
  numberPerPage = environment.RegistrosPagina;
  pages: number[] = [];
  paginaActual = 1;
  numeroPaginas = 0;
  numeroPaginasTo = 0;
  casosList: ICasosExito[] = [];
  casosListTotal: [] | any = [];
  elementsTo: ICasosExito[] = [];
  elementsTotal: ICasosExito[] = [];
  paginaActualMasResultados: number = 0;
  ordenamientoBy: string = "nombre"; //ajustar al parametro que recibe la api "nombre/socio"
  textOrdenarPor: string = "gestion_usuarios.ordenar_por";

  queryFilter: string = "";
  constructor(
    private toaster: ToastrService,
    private router: Router,
    private usuarioService: UsuariosService,
    private _usuariosService: UsuariosService,
    private _casosService: CasosDeExitoService,
    private paginadorLibs: PaginadorLibsComponent,
    private changeDetector: ChangeDetectorRef
  ) {}

  async ngOnInit(): Promise<void> {
    this.lang = localStorage.getItem("lang") ?? "es";

    await this.getCasos(this.ordenamientoBy);

    this._usuariosService.queryFilter$.subscribe((text) => {
      this.queryFilter = text;
      this.getCasos(this.ordenamientoBy);
    });
  }

  changeLang(lang: string) {
    this.lang = lang;
    localStorage.setItem("lang", lang);
    window.location.reload();
  }

  filter = { query: "" };

  async buscadorPress(keyWord: any) {
    if (
      keyWord !== null &&
      keyWord !== undefined &&
      keyWord.toString().trim() !== ""
    ) {
      const filterByValue = (arr = [], query = "") => {
        const reg = new RegExp(query, "i");
        return arr.filter((item) => {
          let flag = false;
          for (const prop in item) {
            if (reg.test(item[prop])) {
              flag = true;
            }
          }
          return flag;
        });
      };

      this.casosList = filterByValue(this.casosListTotal, keyWord);
    } else {
      this.casosList = this.casosListTotal;
    }
  }

  async getCasos(estatusO: string) {
    try {
      this.casosListTotal = [];
      this.casosList = [];
      let estatus = "activo";

      let response = <any>(
        await lastValueFrom(this._casosService.getCasosEstatus(estatus))
      );

      if (response != null && response != undefined && response.length > 0) {
        if (this.queryFilter != "") {
          let vinculaOrdena: ICasosExito[] = response;

          vinculaOrdena.sort((a, b) =>
            a.nombreCasoExito
              .toLowerCase()
              .localeCompare(b.nombreCasoExito.toLowerCase())
          );
          this.casosListTotal = vinculaOrdena;
        } else {
          this.casosListTotal = response;
        }
        this.showPaginationData(
          this.casosListTotal.slice(0, this.numberPerPage)
        );
      }
    } catch (error) {
      console.log("Error al realizar la peticion", error);
    }
  }

  obtenerMasResultados() {
    this.paginaActualMasResultados += 1;
    this.elementsTo = this.paginadorLibs.obtenerMasResultados(
      this.paginaActualMasResultados,
      this.numberPerPage,
      this.elementsTotal
    );
  }

  async orderBy(ordenamiento: string) {
    let vinculaOrdena: ICasosExito[] = this.casosListTotal;

    vinculaOrdena.sort((a, b) =>
      a.nombreCasoExito
        .toLowerCase()
        .localeCompare(b.nombreCasoExito.toLowerCase())
    );
    this.queryFilter = "Ordenar A-Z";

    this.ordenamientoBy = ordenamiento;
    await this.getCasos(ordenamiento);
  }
  crear() {
    const accion = "crearCaso";
    this.router.navigate([`registrar-caso-exito/${accion}`]);
  }

  editarVinculacion(id: number) {
    const accion = "editCaso";
    this.router.navigate([`registrar-caso-exito/${accion}/${id}`]);
  }

  eliminarVinculacion(id: number) {
    this._casosService.deleteCasos(id).subscribe({
      next: (resOK) => {
        this.toaster.success("Eliminación exitosa!");
        this.ngOnInit();
      },
      error: (error) => {
        console.log(error);
        this.toaster.success("Eliminación exitosa!");
        this.ngOnInit();
      },
    });
  }

  showPaginationData(objData: any) {
    if (objData.length) {
      this.casosList = objData;
      this.changeDetector.detectChanges();
    }
  }

  restablecer() {
    window.location.reload();
  }

  cutTextLong(text: string) {
    return Utils.cutLongTextByXChars(text, 150);
  }

  async guardarCambioHome(idCasoExito: number, valorActual: boolean) {
    try {
      let casoExito = this.casosListTotal.find(
        (x) => x.idCasoExito == idCasoExito
      );
      casoExito.publicadoHome = !valorActual;
      await lastValueFrom(this._casosService.putCasos(casoExito));

      this.toaster.success("Guardaro exitosamente");
    } catch (err) {
      console.log(err);
      this.toaster.error("Intente más tarde");
    }
  }

  async guardarCambioUF(idCasoExito: number, valorActual) {
    try {
      let casoExito = this.casosListTotal.find(
        (x) => x.idCasoExito == idCasoExito
      );
      casoExito.publicadoUnidadFormacion = !valorActual;
      await lastValueFrom(this._casosService.putCasos(casoExito));

      this.toaster.success("Guardaro exitosamente");
    } catch (err) {
      console.log(err);
      this.toaster.error("Intente más tarde");
    }
  }
}
