import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IfilesArchivoLogsResponse, IfilesProcesamientResumenResponse, IfilesRetosResponse } from '@shared/Interface/IArchivos';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ArchivosService {
  private baseURL = environment.apiUrl;

  constructor(private http: HttpClient) {}

  getListadoArchivos():Observable<IfilesRetosResponse[]>{
    return this.http.get<IfilesRetosResponse[]>(this.baseURL+'archivos/cargas');
  }
  
  postArchivo(idUsuario: number, urlArchivo: string, tipoArchivo:string){
    return this.http.post(this.baseURL+'archivos/cargas',{
      idUsuario: idUsuario,
      urlArchivo: urlArchivo,
      tipoArchivo: tipoArchivo
    });
  }
  
  getArchivoLogs(idArchivoProceso: number){
    return this.http.get<IfilesArchivoLogsResponse[]>(`${this.baseURL}archivos/cargas/${idArchivoProceso}/logs`);
  }

  getArchivoResumen(idArchivoProceso: number){
    return this.http.get<IfilesProcesamientResumenResponse>(`${this.baseURL}archivos/cargas/${idArchivoProceso}//procesamiento/resumen`);
  }

  postArchivoProcesar(idArchivoProcesar: number){
    return this.http.post(`${this.baseURL}archivos/cargas/procesamiento`, {idARchivoProcesamiento: idArchivoProcesar});
  }
}
