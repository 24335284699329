import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { UsuariosService } from "../../../shared/service/Usuarios/usuarios.service";
import { Router, ActivatedRoute } from "@angular/router";
import { Observable, lastValueFrom, tap } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { CatalogosService } from "@shared/service/Catalogos/catalogos.service";
import {
  ICampus,
  IEscuela,
  IRegion,
  IRolAcademico,
} from "@shared/Interface/ICatalogos.interface";
import Swal from "sweetalert2";
import { AzureBlobStorageService } from "@shared/service/AzureBlobStorage.Service";
import { environment } from "environments/environment";
import { SociosFormadoresService } from "@shared/service/Socios_Formadores/socios-formadores.service";
import { IRolesUsuarioResponse } from "@shared/Interface/IRolesUsuarioResponse";
import { INivelGestion } from "@shared/Interface/ICatalogo.interface";
import Utils from "@shared/helpers/utils";

declare const window: any;

@Component({
  selector: "app-academico-perfil",
  templateUrl: "./academico-perfil.component.html",
  styleUrls: ["./academico-perfil.component.css"],
})
export class AcademicoPerfilComponent implements OnInit {
  contactForm: FormGroup;
  campusData: ICampus[];
  campusDataRegion: ICampus[];
  regionData: any;
  escuelasData: any;
  departamentosData: any[];
  userData: any;
  idUser: any;
  userType: any;
  clavePerfil: string;

  img = "avatar_sin";
  imgUrl = "assets/img/avatar_sin.jpg";
  titleProfile = "Mi Perfil";
  buttons = [
    {
      url: "",
      text: "Editar foto",
    },
  ];

  formModal: any;
  mensajeModal: string = "";
  btn_lblAccion: string="Actualizar datos";
  mostrarbtnCancelar:boolean= false;

  rolesData$: Observable<IRolesUsuarioResponse[]>;
  rolAcademicoData$ : Observable<IRolAcademico[]>;

  nivelGestionData$:Observable<INivelGestion[]>;

  mostrarRegion: boolean = false;
  mostrarCampus: boolean = false;
  mostrarNivelGestion: boolean = false;

  constructor(
    private fb: FormBuilder,
    private usuariosService: UsuariosService,
    private router: Router,
    private toastr: ToastrService,
    private catalogosService: CatalogosService,
    private route: ActivatedRoute,
    private azureBlobStorage: AzureBlobStorageService,
    private sociosFormadores: SociosFormadoresService,
    private _toaster: ToastrService
  ) {
    this.rolAcademicoData$ = this.catalogosService.getRolesAcademicos();
    this.nivelGestionData$ = this.catalogosService.getNivelesGestion();
  }

  getUserData() {
    this.userData = this.usuariosService.getCurrentUserData();
    if (!this.userData) this.router.navigate(["/"]);
    this.imgUrl = this.userData.urlFotoUsuario;

    return this.userData;
  }

  getCatalogues(){
    this.catalogosService
    .getCampus()
    .pipe(tap((resultado: ICampus[]) => (this.campusData = resultado)))
    .subscribe();

  this.catalogosService
    .getEscuelas()
    .pipe(tap((resultado: IEscuela[]) => (this.escuelasData = resultado)))
    .subscribe();

  this.catalogosService
    .getRegiones()
    .pipe(tap((resultado: IRegion[]) => (this.regionData = resultado)))
    .subscribe();
  }

  ngOnInit(): void {
    this.formModal = new window.bootstrap.Modal(
      document.getElementById("idModalCorreoExistente")
    );

    this.initializeForm(null);
    this.getCatalogues();

    // Verificar si es nuevo usuario
    let localStorageNU = localStorage.getItem("AU-Ctx-nU") ?? 'false';
    let localStorageuserType = localStorage.getItem("AU-Ctx-nU-type") ?? '';
    let NewUser   = Boolean(JSON.parse(localStorageNU)) ;

    let editUser  = ! NewUser;

    this.userType =
        this.route.snapshot.paramMap.get("userType") ??
        this.usuariosService.getCurrentUserData().clavePerfil;

    this.idUser =
        this.route.snapshot.paramMap.get("idUsuario") ??
        this.usuariosService.getCurrentUserData().idUsuario;


    //if (this.userType && this.idUser) {
    if ( editUser ){
      // Editar usuario
      this.mostrarbtnCancelar = true;
      this.clavePerfil = this.userType;
      this.getUserToEdit(this.idUser);
      this.btn_lblAccion = 'Actualizar datos';
      this.rolesData$ = this.catalogosService.getRolesUsuario(this.userType === 'cvdp_admin' ? 'cvdp': this.userType);
      return;
    }

    //if (this.userType && !this.idUser) {
    if( NewUser ) {
      // Nuevo usuario
      this.clavePerfil = this.userType;
      this.titleProfile = `Nuevo Usuario ${this.userType.toUpperCase()}`;
      this.btn_lblAccion = 'Crear usuario';
      this.rolesData$ = this.catalogosService.getRolesUsuario(this.userType === 'cvdp_admin' ? 'cvdp': this.userType);
      //this.initializeForm(null);
      return;
    }
    // this.userType = this.usuariosService.getCurrentUserData().clavePerfil=='cvdp_admin'?'cvdp':this.usuariosService.getCurrentUserData().clavePerfil;
    // this.rolesData$ = this.catalogosService.getRolesUsuario(this.userType);
    // this.getUserToEdit(this.getUserData().idUsuario);
  }

  getUserToEdit(userId: string) {
    this.usuariosService
      .getUsuarioById(userId)
      .pipe(
        tap((response) => {
          this.userData = response;
          this.imgUrl = this.userData.urlFotoUsuario;
          this.cargarDepartamentoxEscuela(response.idEscuela);
          return this.initializeForm({
            ...response,
          });
        })
      )
      .subscribe();
  }

  initializeForm(contacto: any | null) {
    let idRolAcademico = contacto?.idRolAcademico == 0? null : contacto?.idRolAcademico;
    // Obtener los campus correspondientes

    if(contacto != null)
    {
      this.campusDataRegion = this.campusData.filter(x=> x.idRegion == contacto?.idRegion);
    }


    this.contactForm = this.fb.group({
      usuario: [ contacto?.usuario ?? "", [Validators.required, Validators.email], ],
      nombreUsuario: [contacto?.nombreUsuario ?? "", Validators.required],
      apellidosUsuario: [contacto?.apellidosUsuario ?? "", Validators.required],
      nomina: [contacto?.nomina ?? "", [Validators.required, Validators.pattern(/^L0(\d){7}$/)]],
      domicilio: [contacto?.domicilio ?? ""],
      idCampus: [contacto?.idCampus ?? "",[Validators.required]],
      idEscuela: [contacto?.idEscuela ?? null,[Validators.required]],
      puesto: [contacto?.puesto ?? ""],
      departamento: [contacto?.departamento ?? ""],
      claveRoles: [contacto?.claveRoles],
      idRolAcademico: [idRolAcademico],
      escuelaDepartamento: [contacto?.idEscuelaDepartamento == 0 ? "" : contacto?.idEscuelaDepartamento , [Validators.min(1)]],
      listaCampusAdicional:[contacto?.listaCampusAdicional.map(x=> x.idCampus), ],
      idRegion:[contacto?.idRegion, ],
      idNivelGestion: [contacto?.idNivelGestion, ]
    });
    if (this.idUser) {
      this.titleProfile = `Editar: ${contacto.nombreUsuario} ${contacto.apellidosUsuario}`;
    }
    // Se valida si es edicion se bloquea el email
    this.contactForm.controls["usuario"].disable();
    if (this.userType && !this.idUser) {
      this.contactForm.controls["usuario"].enable();
    }

    if(['cvdp','cvdp_admin'].includes(this.userType)){
      this.contactForm.controls['idEscuela'].disable();
      this.contactForm.controls['escuelaDepartamento'].disable();
    }
    else{
      this.contactForm.controls['escuelaDepartamento'].enable();
    }
    // Si es cvdp_admin no debe llevar campus
    if(this.usuariosService.getCurrentUserData().clavePerfil == 'cvdp_admin'){
      this.contactForm.controls['idCampus'].disable();
    }

    // Se valida si es CVDP admin
    this.contactForm.controls['listaCampusAdicional'].disable();
      this.contactForm.controls['idRegion'].disable();
      this.contactForm.controls['idNivelGestion'].disable();
      this.mostrarNivelGestion = false;
    if(['cvdp','cvdp_admin'].includes(this.usuariosService.getCurrentUserData().clavePerfil)){
      this.contactForm.controls['listaCampusAdicional'].enable();
      this.contactForm.controls['idRegion'].enable();
      this.contactForm.controls['idNivelGestion'].enable();
      this.mostrarNivelGestion = true;
    }

    if(contacto?.idNivelGestion == this.NG_LOCAL){
      this.mostrarCampus = true;
      this.mostrarRegion = true;
    }
    if(contacto?.idNivelGestion == this.NG_REGIONAL){
      this.mostrarCampus = false;
      this.mostrarRegion = true;
    }

    if(contacto?.idNivelGestion == this.NG_NACIONAL){
      this.mostrarCampus = false;
      this.mostrarRegion = false;
    }


  }

  getFullName() {
    return `${this.contactForm.value?.nombreUsuario ?? ""} ${
      this.contactForm.value?.apellidosUsuario ?? ""
    }`;
  }

  administrarAcademia() {
    if (!this.idUser) {
      // nuevo registro
      this.crearAcademia();
    } else {
      // edicion
      this.editarAcademia();
    }
  }

  administrarCVDP() {
    if (!this.idUser) {
      // nuevo registro
      this.crearCvdp();
    } else {
      // edicion
      this.editarCvdp();
    }
  }

  crearCvdp() {

    let listaCampus = this.getCampusSeleccionados();
    let regionSelecionada = this.getRegionSeleccionada();

    let postUsuario = {...this.contactForm.value,
      clavePerfil: this.userType,
      urlFotoUsuario: this.imgUrl,
      idCampus: Number(this.contactForm.get("idCampus")?.value),
      idregion: regionSelecionada,
      idRolAcademico: 0,
      campusAdicional: listaCampus};

    delete postUsuario.listaCampusAdicional;

    this.usuariosService
      .postUsuario(
        postUsuario)
      .subscribe({
        next: (resultado: any) => {
          if (resultado) {
            this.toastr.success("Actualizado Correctamente!");
            let cvePerfilRoute = Utils.getRouteFromProfile(this.userData.clavePerfil);
            this.router.navigateByUrl(`/${cvePerfilRoute}/dashboard`);
          } else {
            this.toastr.error("Algo Salio mal!");
          }
        },
        error: (error) => {
          console.log(error);
          if(error.message){
            this.toastr.warning(error.message);
          }
          else{
          Swal.fire({
            icon: "error",
            text: "Error en la solicitud, intentar más tarde",
            title: "Error",
          });
        }
        },
      });
  }

  async editarCvdp() {
    let listaCampus = this.getCampusSeleccionados();
    let regionSelecionada = this.getRegionSeleccionada();

    let resultado = await lastValueFrom(this.usuariosService
      .putUsuarios({
        ...this.contactForm.value,
        idRegion: regionSelecionada,
        clavePerfil: this.userData.clavePerfil,
        idUsuario: this.idUser,
        idCampus: Number(this.contactForm.get("idCampus")?.value),
        campusAdicional: listaCampus,
      }));

      if (resultado) {
        await this.usuariosService.updateCurrentUserData(
          this.userData.usuario
        );
        this.toastr.success("Actualizado Correctamente!");
        let cvePerfilRoute = Utils.getRouteFromProfile(this.userData.clavePerfil);
        this.router.navigateByUrl(`/${cvePerfilRoute}/dashboard`);
      } else {
        this.toastr.error("Algo Salió mal!");
      }
      
  }


  getCampusSeleccionados(){
    let lCampus:number[]=[];
    if(this.contactForm.get('idNivelGestion')?.value == this.NG_NACIONAL)
    {
      lCampus = this.campusData.map(x => x.idCampus);
    }

    if(this.contactForm.get('idNivelGestion')?.value == this.NG_REGIONAL){
      let idRegion = this.contactForm.get('idRegion')?.value;
      lCampus = this.campusData.filter(x => x.idRegion == idRegion).map( x => x.idCampus);
    }

    if(this.contactForm.get('idNivelGestion')?.value == this.NG_LOCAL){
      lCampus = this.contactForm.get('listaCampusAdicional')?.value;
    }
    return lCampus;
  }

  getRegionSeleccionada(){
    if(this.contactForm.get('idNivelGestion')?.value == this.NG_NACIONAL){
      let idCampusCoordinador = this.contactForm.get('idCampus')?.value;
      let idRegion = null; //this.campusData.find(x=> x.idCampus == idCampusCoordinador)?.idRegion;
      return idRegion;
    }

    if(this.contactForm.get('idNivelGestion')?.value == this.NG_REGIONAL){
      return this.contactForm.get('idRegion')?.value;
    }

    if(this.contactForm.get('idNivelGestion')?.value == this.NG_LOCAL){
      return this.contactForm.get('idRegion')?.value;
    }
  }

  crearAcademia() {

    let listaCampus = this.getCampusSeleccionados();
    let regionSelecionada = this.getRegionSeleccionada();

    let postUsuarioAcad = {
      ...this.contactForm.value,
      clavePerfil: this.userType,
      urlFotoUsuario: this.imgUrl,
      idCampus: Number(this.contactForm.get("idCampus")?.value),
      idRegion: regionSelecionada,
      idRolAcademico:
        this.contactForm.get("idRolAcademico")?.value == 0
          ? null
          : this.contactForm.get("idRolAcademico")?.value,
          idEscuelaDepartamento: this.contactForm.get("escuelaDepartamento")?.value,
          campusAdicional: listaCampus,
    };

    delete postUsuarioAcad.listaCampusAdicional;

    this.usuariosService
      .postUsuario(postUsuarioAcad)
      .subscribe({
        next: (resultado: any) => {
          if (resultado) {
            this.toastr.success("Actualizado Correctamente!");
            window.history.back();
          } else {
            this.toastr.error("Algo Salio mal!");
          }
        },
        error: (error) => {
          console.log(error);
          if(error.message){
            this.toastr.warning(error.message);
          }
          else{
          Swal.fire({
            icon: "error",
            text: "Error el la solicitud, intentar más tarde",
            title: "Error",
          });
        }
        },
      });
  }

  async editarAcademia() {

    let listaCampus = this.getCampusSeleccionados();
    let regionSelecionada = this.getRegionSeleccionada();

    let resultado = await lastValueFrom(this.usuariosService
      .putUsuarios({
        ...this.contactForm.value,
        clavePerfil: this.userData.clavePerfil,
        idUsuario: this.idUser,
        idCampus: Number(this.contactForm.get("idCampus")?.value),
        idRegion: regionSelecionada,
        idRolAcademico:
          this.contactForm.get("idRolAcademico")?.value == 0
            ? null
            : this.contactForm.get("idRolAcademico")?.value,
        idEscuelaDepartamento: this.contactForm.get("escuelaDepartamento")?.value,
        campusAdicional: listaCampus,
      }));
      
      if (resultado) {
        await this.usuariosService.updateCurrentUserData(
          this.userData.usuario
        );
        this.toastr.success("Actualizado Correctamente!");
        let cvePerfilRoute = Utils.getRouteFromProfile(this.userData.clavePerfil);
        this.router.navigateByUrl(`/${cvePerfilRoute}/dashboard`);
      } else {
        this.toastr.error("Algo Salió mal!");
      }
        
  }

  updateContact() {
    try {

      if(this.contactForm.get("escuelaDepartamento")?.value== ""){
        this.contactForm.get("escuelaDepartamento")?.setValue(0);
      }

      this.contactForm.markAllAsTouched();

      if (this.contactForm.valid) {
        // Validamos si es CVDP o Academico
        if (this.userType == "cvdp" || this.userType == "cvdp_admin") {
          this.administrarCVDP();
        }
        if (this.userType == "academia") {
          // varificamos si es nuevo registro o edicion
          this.administrarAcademia();
        }
      }
      else{
        this.toastr.error("Revisar poner todos los datos");
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        text: "Error en la solicitud, intenta más tarde",
        title: "Error",
      });
    }
  }


  actualizarCampus(event: any) {
    this.campusData = [];
    if (event.target.value !== "") {
      this.catalogosService
        .getCampusPorRegion(event.target.value)
        .subscribe((resOK) => {
          this.campusData = resOK;
        });
    }
  }

  verifySelected(id: string | number, inputForm: string) {
    return this.contactForm.get(inputForm)?.value === id ? true : null;
  }

  onClickButton(url: string) {
    console.log("edit image");
  }

  async selImagen(event: any) {
    let idUsr = this.idUser;
    let selFile = (event.target as HTMLInputElement).files?.[0];
    if (!selFile) return;
    if (!selFile.type.includes("image/")) {
      this._toaster.error("Únicamente imágenes");
      return;
    }
    if (selFile.size > 3e6) {
      this._toaster.error("Tamaño máximo de 3MB");
      return;
    }
    let fileName = this.getFileName(idUsr, selFile);

    await this.uploadUserImage(idUsr, selFile, fileName);
  }

  async uploadSocioImage(idUsr, selFile, fileName) {
    await this.azureBlobStorage
      .uploadFile(
        {
          containerName: environment.AzureBlob_containerNameSocio,
          file: selFile,
          filename: fileName,
        },
        environment.AzureBlob_ACCOUNT_NAME,
        environment.AzureBlob_socioSAS
      )
      .then((res) => {
        let fileUrlLogo = `${environment.AzureBlob_socioURL}${fileName}`;
        this.sociosFormadores
          .putLogo({ idSocioFormador: idUsr, urlLogo: fileUrlLogo })
          .subscribe((resOK) => {
            this.usuariosService.updateCurrentObjectUserData(
              "urlFotoUsuario",
              fileUrlLogo
            );
            window.location.reload();
          });
      });
  }

  async uploadUserImage(idUsr, selFile, fileName) {
    this.azureBlobStorage
      .uploadFile(
        {
          containerName: environment.AzureBlob_containerNameUsuario,
          file: selFile,
          filename: fileName,
        },
        environment.AzureBlob_ACCOUNT_NAME,
        environment.AzureBlob_usuarSAS
      )
      .then((res) => {
        let fileUrlLogo = `${environment.AzureBlob_usuarURL}${fileName}`;
        this.usuariosService
          .putFotos({ idUsuario: idUsr, urlFoto: fileUrlLogo })
          .subscribe((resOK) => {
            this.usuariosService.updateCurrentObjectUserData(
              "urlFotoUsuario",
              fileUrlLogo
            );
            this.imgUrl = fileUrlLogo;
            if (
              this.idUser == this.usuariosService.getCurrentUserData().idUsuario
            ) {
              window.location.reload();
            }
          });
      });
  }

  getFileName(id, file) {
    if (this.clavePerfil === "socio") {
      return `socio_${id}.${file.name.split(".").pop()}.`.toLowerCase();
    } else {
      return `user_${id}.${file.name.split(".").pop()}`.toLowerCase();
    }
  }

  canShowRoles(){
    return (this.usuariosService.hasRole('cvdp') || this.usuariosService.hasRole('cvdp_admin'));
  }

  regresar(){
    Swal.fire({
      title:'¿Desea Cancelar?',
      text: 'Se perderán sus cambios.',
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: 'No'
    }).then((result)=>{
      if(result.isConfirmed){
        //this.getUserToEdit(this.idUser);
        window.history.back();
      }
    })

  }

  async cargarDepartamentoxEscuela(idEscuela){
    this.departamentosData = [];
    if(idEscuela>0){
      let resultado = await lastValueFrom(this.catalogosService
          .getDepartamentosPorEscuela(idEscuela));
      this.departamentosData = resultado;
    }
  }

  obtenerDepartamentos(event){
    let idEscuela = event.target.value;
     this.cargarDepartamentoxEscuela(idEscuela);
  }

  NG_LOCAL:number=1;
  NG_NACIONAL:number=2;
  NG_REGIONAL:number=3;

  showRegion(event){
    if(event === undefined){
      this.mostrarRegion = false;
      this.mostrarCampus = false;
    }
    if(event.idNivelGestion == this.NG_NACIONAL){
      this.mostrarRegion = false;
      this.mostrarCampus = false;
    }
    if(event.idNivelGestion == this.NG_REGIONAL){
      this.mostrarRegion = true;
      this.mostrarCampus = false;
    }
    if(event.idNivelGestion == this.NG_LOCAL){
      this.mostrarRegion = true;
      this.mostrarCampus = true;
    }
  }

  showCampus(event){
    this.campusDataRegion = this.campusData.filter(x => x.idRegion == event.idRegion);
    this.contactForm.get('listaCampusAdicional')?.reset();

  }

  async eliminarUsuario() {
    let idUsuario = this.idUser;
    await Swal.fire({
      title: '¿Desea inactivar el usuario?',
      text: '',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Borrar',
    }).then((result) => {
      if (result.isConfirmed) {

        this.usuariosService
      .delteUser(idUsuario)
      .pipe(
        tap((resultado: any) => {
          if (resultado) {
            Swal.fire('Gestión de Usuarios.', 'Usuario inactivo, no podrá acceder a la plataforma.', 'success')
            .then(()=>{window.history.back();})
            .catch((error)=> console.log(error));


          } else {
            this.toastr.error('Algo Salió mal!');
          }
        })
      )
      .subscribe();
      }
    });
  }
}
