import { Component, OnInit } from "@angular/core";
import {
  IGestionNotificacionPerfilResponse,
  IGestionNotificacionUsuarioResponse,
} from "@shared/Interface/INotification";
import { ContactosService } from "@shared/service/Contactos/contactos.service";
import { NotificacionesService } from "@shared/service/Notificaciones/notificaciones.service";
import { UsuariosService } from "@shared/service/Usuarios/usuarios.service";
import { ToastrService } from "ngx-toastr";
import { lastValueFrom } from "rxjs/internal/lastValueFrom";

@Component({
  selector: "app-gestion-notificaciones",
  templateUrl: "./gestion-notificaciones.component.html",
  styleUrls: ["./gestion-notificaciones.component.css"],
})
export class GestionNotificacionesComponent implements OnInit {
  constructor(
    private notificacionesService: NotificacionesService,
    private usuarioService: UsuariosService,
    private contactoService: ContactosService,
    private toastr: ToastrService
  ) {}

  lblnombreUsuario:string ='';
  lblPerfilUsuario: string = "";
  lblCorreo: string = "";
  lblExtra: string = "";
  lblExtraTitulo: string = "";

  listadoNotificaciones: IGestionNotificacionUsuarioResponse[];
  listadoNotificacionesUsuario: IGestionNotificacionUsuarioResponse[];
  listadoNotificacionesPerfil: IGestionNotificacionPerfilResponse[];

  Usuario: any;

  ngOnInit(): void {
    let objUsuario = JSON.parse(sessionStorage.getItem('usrNot')||'');
    let objUsuarioPerf = sessionStorage.getItem('usrPerfNot')||'';
    this.cargarUsuarios(objUsuario, objUsuarioPerf);
  }

  async cargarUsuarios(objUsuario, objUsuarioPerf) {
    try {
      if(objUsuarioPerf == 'cvdp' || objUsuarioPerf == 'cvdp_admin'){

        this.Usuario = {
          nombreUsuario: objUsuario.nombreUsuario,
          correo: objUsuario.correo,
          clavePerfil: "cvdp",
          idUsuario: objUsuario.idUsuario,
          lblExtra: "Campus:",
          extra: objUsuario.campus,
        };
      }
      if(objUsuarioPerf == 'academia'){

        this.Usuario = {
          nombreUsuario: objUsuario.nombreUsuario,
          correo: objUsuario.correo,
          clavePerfil: "academia",
          idUsuario: objUsuario.idUsuario,
          lblExtra: "Escuela:",
          extra: objUsuario.escuela,
        };
      }
      if (objUsuarioPerf == 'socio'){
         let UserSocio = await lastValueFrom(
          this.contactoService.getContactoxId(objUsuario.idSocioFormador,objUsuario.idContacto)
        );
        this.Usuario = {
              nombreUsuario: objUsuario.nombreContacto,
              correo: objUsuario.correo,
              clavePerfil: "socio",
              idUsuario: UserSocio.idUsuario,
              lblExtra: "Socio Formador:",
              extra: objUsuario.nombreSocioFormador,
        };
      }
      await this.buscarUsuario();

    } catch (error) {
      console.log(error);
      this.toastr.error("No hay Notificaciones Configuradas");
    }
  }

  async buscarUsuario() {
    try {
      this.listadoNotificacionesPerfil = [];
      this.listadoNotificacionesUsuario = [];
      this.lblnombreUsuario = "";
      this.lblPerfilUsuario = "";
      this.lblCorreo = "";
      this.lblExtraTitulo = "";
      this.lblExtra = "";

      this.lblnombreUsuario = this.Usuario.nombreUsuario;
      this.lblPerfilUsuario = this.Usuario.clavePerfil;
      this.lblCorreo = this.Usuario.correo;
      this.lblExtraTitulo = this.Usuario.lblExtra;
      this.lblExtra = this.Usuario.extra;


      this.listadoNotificacionesUsuario = await lastValueFrom(
        this.notificacionesService.getByIdUsuario(this.Usuario.idUsuario)
      );
    } catch (error) {
      console.log(error);
      this.toastr.error('No hay Notificaciones configuradas');
    }
  }

  async inactivarNotificacion(idUsuario: number, idTipoNotificacion: number) {
    try {
      let request = {
        idUsuario: idUsuario,
        idTipoNotificacion: idTipoNotificacion,
        claveEstatus: "inactivo",
      };
      await lastValueFrom(
        this.notificacionesService.postRegistraExcepcion(request)
      );

      this.listadoNotificacionesUsuario = await lastValueFrom(
        this.notificacionesService.getByIdUsuario(idUsuario)
      );

      this.toastr.success('Guardado con éxito');
    } catch (error) {
      console.log(error);
      this.toastr.error("Ocurrió un error, intenta más tarde.");
    }
  }

  async activarNotificacion(idUsuario: number, idTipoNotificacion: number) {
    try {
      let request = {
        idUsuario: idUsuario,
        idTipoNotificacion: idTipoNotificacion,
      };
      await lastValueFrom(
        this.notificacionesService.deleteRegistraExcepcion(request)
      );

      this.listadoNotificacionesUsuario = await lastValueFrom(
        this.notificacionesService.getByIdUsuario(idUsuario)
      );

      this.toastr.success('Guardado con éxito');

    } catch (error) {
      console.log(error);
      this.toastr.error("Ocurrió un error, intenta más tarde.");
    }
  }
}
