import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { IRetosCVDPResponse } from "@shared/Interface/IRetosCVDPResponse";
import { IVinculacionesResponse } from "@shared/Interface/IsocioVinculacion";
import { IUser } from "@shared/Interface/user";
import { DashboardService } from "@shared/service/Dashboard/dashboard.service";
import { UsuariosService } from "@shared/service/Usuarios/usuarios.service";
import { lastValueFrom } from "rxjs";
import {
  COLS_ACADEMICO_BUSCA_SF,
  COLS_LISTADO_RETOS,
  COLS_LISTADO_RETOS_CONC,
  COLS_LISTADO_RETOS_CURS,
  COLS_LISTADO_RETOS_FORM,
  COLS_PROBLEMATICAS,
  COLS_PROBLEMATICAS_COMMENTS,
  COLS_SF_SOLICITANDO_RETO,
  COLS_SOLICITUDES_SF_POTENCIAL,
} from "./cvdp-dashboard.columns";
import { NgbModalConfig, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FeatureFlagService } from "app/featureModule/feature-flag.service";
import { SolicitudesService } from "@shared/service/Solicitudes/solicitudes.service";
import { RegistroCasosExitoComponent } from "@pages/casos-de-exito/registro-casos-exito/registro-casos-exito.component";

@Component({
  selector: "app-cvdp-dashboard",
  templateUrl: "./cvdp-dashboard.component.html",
  styleUrls: ["./cvdp-dashboard.component.css"],
  providers: [NgbModalConfig, NgbModal],
})
export class CvdpDashboardComponent implements OnInit {
  @ViewChild("comentarios") comentarios: ElementRef;

  F2_5_HU69:boolean;
  F2_4_HU69:boolean;

  F2_5_HU58:boolean;
  F2_4_HU58:boolean;

  F2_5_HU67:boolean;
  F2_4_HU67:boolean;

  [x: string]: any;
  backgroundTable = "#F6F6F6";
  titleSociosPotenciales = {
    title: "Solicitudes de Socios Formadores Potenciales",
    link: "/cvdp/solicitudes-socios-potenciales",
  };
  titleSociosFormadores = {
    title: "Socios Formadores solicitando un reto",
    link: "/cvdp/solicitudes-socios-retos",
  };
  titleAcademicoSocioFormador = {
    title: "Académico que busca un Socio Formador",
    link: "/cvdp/academico-busca-socio",
  };
  titleSolicitudesReto = {
    title: "Socio Formador que no encontró reto (problemáticas)",
    link: "/cvdp/solicitudes-no-encontraron-reto",
  };
  titleSolicitudesRetoF2_4_HU58 = {
    title: "Problemáticas que no encontraron un reto",
    link: "/cvdp/solicitudes-no-encontraron-reto",
  };
  titleRetos = {
    title: "Retos",
    link: "/retos",
  };

  solicitudesColumns = COLS_SOLICITUDES_SF_POTENCIAL;

  retoColumns = COLS_SF_SOLICITANDO_RETO;

  academicoColumns = COLS_ACADEMICO_BUSCA_SF;

  //* Problematicas */
  sinRetoColumns = COLS_PROBLEMATICAS;
  sinRetoCommentsColumns = COLS_PROBLEMATICAS_COMMENTS;

  // ** Listado de Retos  (Activos / Mi historial de retos)
  listadoRetosColumns = COLS_LISTADO_RETOS;

  retoData: IVinculacionesResponse[] = [];
  solicitudesRecibidasData: any;
  solicitudesRevisionData: any;
  solicitudesAprobadasData: any;
  solicitudesRechazadasData: any;

  solicitudesRetosData: any;
  solicitudesAprobadasRetosData: any;
  solicitudesRechazadasRetosData: any;

  solicitudesAcademicosData: any;
  solicitudesAprobadaAcademicosData: any;
  solicitudesRechazadasAcademicosData: any;

  solicitudesSinRetosData: any;
  solicitudesAprobadasSinRetosData: any;
  solicitudesRechazadasSinRetosData: any;

  listadoRetosActivosData: any;
  listadoRetosTodosData: any;

  userStore!: IUser;

  idRequestEndpoint: number;

  //* Documentacion de retos
  listadoRetosFormalizacionColumns = COLS_LISTADO_RETOS_FORM;
  listadoRetosFormalizacionData: any;

  listadoRetosCursoColumns = COLS_LISTADO_RETOS_CURS;
  listadoRetosCursoData: any;

  listadoRetosEvaluacionColumns = COLS_LISTADO_RETOS_CURS;
  listadoRetosEvaluacionData: any;

  listdaoRetosConcluidoColumns = COLS_LISTADO_RETOS_CONC;
  listadoRetosConcluidoData: any;


  status: string = "formalizacion";
  constructor(
    private dashboardService: DashboardService,
    private usuarioService: UsuariosService,
    private config: NgbModalConfig,
    private modalService: NgbModal,
    private featureFlagService:FeatureFlagService,
    private solicitudesService: SolicitudesService
  ) {}

  getSolicitudesSFPotenciales(resultado) {
    return resultado.map((solicitudes: any) => ({
      fechaRegistro: solicitudes.fechaRegistro,
      link: {
        title: `${solicitudes.nombreSocioFormador}`,
        subtitle: solicitudes.rfc,
        description:
          solicitudes.idUsuarioRegistro > 0
            ? ` Académico: ${solicitudes.nombreUsuarioRegistro} - ${solicitudes.correoUsuarioRegistro}`
            : "",
        link: `/cvdp/solicitud-sf-potencial?solicitud=${solicitudes.idSolicitud}&CVDP=${solicitudes.idSocioFormador}`,
      },
      imageTitle: {
        title: solicitudes.nombreContacto ?? "-",
        subtitle: solicitudes.correoContacto ?? "-",
      },
      campus: solicitudes.campus,
      estatus: this.getCatEstatusSeguimiento(
        solicitudes.claveEstatus,
        solicitudes.estatus,
        solicitudes.claveEstatusSeguimiento
      ),
      claveEstatusSolicitud: {
        textCol: "Ver",
        statusType: "primary",
        link: `/cvdp/solicitud-sf-potencial?solicitud=${solicitudes.idSolicitud}&CVDP=${solicitudes.idSocioFormador}`,
      },
    }));
  }

  getSociosSolicitandoReto(resultado) {
    return resultado.map((solicitudes: IVinculacionesResponse) => ({
      fechaSolicitud: solicitudes.fechaRegistro,
      campus: solicitudes.campus ?? "-",
      alcance: solicitudes.campusVincular ?? "-",
      duracion: solicitudes.duracion ?? "-",
      periodo: solicitudes.periodoSemestral ?? "-",
      link: {
        title: solicitudes.nombreSocioFormador,
        subtitle: solicitudes.rfc,
        link: `/solicitud-vinculacion-a-reto/${solicitudes.idSocioFormador}/${solicitudes.idRetoMaestro}/${solicitudes.idSolicitud}`,
      },
      contactPerson: {
        title: solicitudes.nombreContacto,
        subtitle: solicitudes.correoContacto,
      },
      retoVincular: {
        title: solicitudes.reto,
        subtitle: solicitudes.escuela,
      },
      claveEstatusSolicitud: {
        textCol: "Ver",
        statusType: "primary",
        link: `/solicitud-vinculacion-a-reto/${solicitudes.idSocioFormador}/${solicitudes.idRetoMaestro}/${solicitudes.idSolicitud}`,
      },
    }));
  }

  getAcademicoBuscaSF(resultado) {
    return resultado.map((solicitudes: any) => ({
      fechaRegistro: solicitudes.fechaRegistro,
      periodoSemestral: solicitudes.periodoSemestral ?? "-",
      periodoSemanal: solicitudes.periodoSemanal ?? "-",
      contactPerson: {
        title: solicitudes.nombreAcademico,
        subtitle: solicitudes.correoUsuario,
        description: solicitudes.campus,
        link: `/solicitud/Academico/${solicitudes.idAcademico}/${solicitudes.idRetoMaestro}/${solicitudes.idSolicitud}`,
        image: solicitudes.urlFotoUsuario,
      },
      imageTitle: {
        title:
          solicitudes.idSocioFormador == 0
            ? "No se encontró un Socio Formador"
            : solicitudes.detalleSolicitud,
        subtitle: solicitudes.rfc ?? "-",
      },
      unidadFormativa: {
        title: solicitudes.reto,
        subtitle: solicitudes.escuela,
      },
      claveEstatusSolicitud: {
        textCol: "Ver",
        statusType: "primary",
        link: `/solicitud/Academico/${solicitudes.idAcademico}/${solicitudes.idRetoMaestro}/${solicitudes.idSolicitud}`,
      },
    }));
  }

  getProblematicasSinReto(resultado) {
    return resultado.map((solicitudes: any) => ({
      fechaSolicitud: solicitudes.fechaRegistro,
      campusCoordinador: solicitudes.campus ?? "-",
      campus: solicitudes.campusSolicitado ?? "-",
      alcance: solicitudes.escuela ?? "-",
      link: {
        title: solicitudes.proyecto,
      },
      imageTitle: {
        title: solicitudes.nombreSocioFormador,
        subtitle: solicitudes.rfc,
        link: `/solicitud-problematica/${solicitudes.idProblematica}/${solicitudes.idSocioFormador}/${solicitudes.idSolicitud}`,
      },
      contactPerson: {
        title: solicitudes.nombreContacto,
        subtitle: solicitudes.correoContacto,
      },
      unidadFormativa: {
        title: solicitudes.industria,
      },
      claveEstatusSolicitud: {
        textCol: "Ver",
        statusType: "primary",
        link: `/solicitud-problematica/${solicitudes.idProblematica}/${solicitudes.idSocioFormador}/${solicitudes.idSolicitud}`,
      },
    }));
  }

  getProblematicasSinRetoComments(resultado) {
    return resultado.map((solicitudes: any) => ({
      fechaSolicitud: solicitudes.fechaRegistro,
      campusCoordinador: solicitudes.campus ?? "-",
      campus: solicitudes.campusSolicitado ?? "-",
      alcance: solicitudes.escuela ?? "-",
      link: {
        title: solicitudes.proyecto,
      },
      imageTitle: {
        title: solicitudes.nombreSocioFormador,
        subtitle: solicitudes.rfc,
        link: `/solicitud-problematica/${solicitudes.idProblematica}/${solicitudes.idSocioFormador}/${solicitudes.idSolicitud}`,
      },
      contactPerson: {
        title: solicitudes.nombreContacto,
        subtitle: solicitudes.correoContacto,
      },
      unidadFormativa: {
        title: solicitudes.industria,
      },
      claveEstatusSolicitud: {
        textCol: "Ver",
        statusType: "primary",
        link: `/solicitud-problematica/${solicitudes.idProblematica}/${solicitudes.idSocioFormador}/${solicitudes.idSolicitud}`,
      },
      message: {
        objParams: {
          idSolicitud: solicitudes.idSolicitud,
          idSocioformador: solicitudes.idSocioFormador,
        },
        callbackAction: (solicitudData) => {
          this.getComentarios(
            solicitudData.idSolicitud
          );
        },
      },
    }));
  }

  getListadoRetos(resultado) {
    return resultado.map((solicitud: IRetosCVDPResponse) => ({
      idReto: solicitud.idReto,
      fechaRegistro: solicitud.fechaRegistro,
      reto: {
        title: solicitud.reto,
        subtitle: solicitud.unidadFormativa,
        description: solicitud.escuela,
        link: `/socio/pagina-retos-resumen?reto=${solicitud.idReto}`,
      },
      nombreSocioFormador: {
        title: solicitud.nombreSocioFormador,
        subtitle: solicitud.rfc,
      },
      contactPerson: {
        title: solicitud.nombreContacto ?? "-",
        subtitle: solicitud.correoContacto ?? "-",
      },
      imageTitle: {
        title: solicitud.academico,
        subtitle: solicitud.correoAcademico,
      },
      periodoSemestral: solicitud.periodoSemestral,
      periodoSemanal: solicitud.periodoSemanal,
      claveEstatusSolicitud: {
        textCol: "Ver",
        statusType: "primary",
        link: `/socio/pagina-retos-resumen?reto=${solicitud.idReto}`,
      },
    }));
  }

  getListadoRetosFormalizacion(resultado) {
    return resultado.map((solicitud: IRetosCVDPResponse) => ({
      idReto: solicitud.idReto,
      fechaRegistro: solicitud.fechaInicio ,
      reto: {
        title: solicitud.reto,
        subtitle: solicitud.unidadFormativa,
        description: solicitud.escuela,
        link: `/cvdp/formalizacion?reto=${solicitud.idReto}`,
      },
      nombreSocioFormador: {
        title: solicitud.nombreSocioFormador,
        subtitle: solicitud.rfc,
      },
      duracion: solicitud.semanas ?? "-",
      periodoSemanal: solicitud.periodoSemanal,
      periodoSemestral: solicitud.periodoSemestral,
      imageTitle: {
        title: solicitud.campus ?? "",
        subtitle: solicitud.escuela,
        description: solicitud.crn,
      },
      proyecto: solicitud.alcance,
      claveEstatusSolicitud: {
        textCol: "Ver",
        statusType: "primary",
        link: `/cvdp/formalizacion?reto=${solicitud.idReto}`,
      },
    }));
  }

  getListadoRetosCursoyEval(resultado, estatus) {
    return resultado.map((solicitud: IRetosCVDPResponse) => ({
      idReto: solicitud.idReto,
      fechaRegistro: solicitud.fechaInicio,
      reto: {
        title: solicitud.reto,
        subtitle: solicitud.unidadFormativa,
        description: solicitud.escuela,
        link: `/cvdp/${estatus}?reto=${solicitud.idReto}`,
      },
      duracion: solicitud.semanas ?? "-",
      periodoSemanal: solicitud.periodoSemanal,
      periodoSemestral: solicitud.periodoSemestral,
      imageTitle: {
        title: solicitud.campus ?? "",
        subtitle: solicitud.escuela,
        description: solicitud.crn,
      },
      nombreSocioFormador: {
        title: solicitud.academico,
        subtitle: solicitud.correoAcademico,
      },
      claveEstatusSolicitud: {
        textCol: "Ver",
        statusType: "primary",
        link: `/cvdp/${estatus}?reto=${solicitud.idReto}`,
      },
    }));
  }

  getListadoRetosConcluido(resultado) {
    return resultado.map((solicitud: IRetosCVDPResponse) => ({
      idReto: solicitud.idReto,
      fechaRegistro: solicitud.fechaInicio,
      reto: {
        title: solicitud.reto,
        subtitle: solicitud.unidadFormativa,
        description: solicitud.escuela,
        link: `/cvdp/concluido?reto=${solicitud.idReto}`,
      },
      duracion: solicitud.semanas ?? "-",
      periodoSemanal: solicitud.periodoSemanal,
      periodoSemestral: solicitud.periodoSemestral,
      imageTitle: {
        title: solicitud.campus ?? "",
        subtitle: solicitud.escuela,
        description: solicitud.crn,
      },
      nombreSocioFormador: {
        title: solicitud.nombreSocioFormador,
        subtitle: solicitud.rfc,
      },
      inscritos: solicitud.inscritos,
      claveEstatusSolicitud: {
        textCol: "Ver",
        statusType: "primary",
        link: `/cvdp/concluido?reto=${solicitud.idReto}`,
      },
    }));
  }

  async ngOnInit() {
    this.F2_4_HU69 = this.featureFlagService.isFeatureEnabled('F2_4_HU69');
    this.F2_5_HU69 = this.featureFlagService.isFeatureEnabled('F2_5_HU69');

    this.F2_4_HU58 = this.featureFlagService.isFeatureEnabled('F2_4_HU58');
    this.F2_5_HU58 = this.featureFlagService.isFeatureEnabled('F2_5_HU58');

    this.F2_4_HU67 = this.featureFlagService.isFeatureEnabled('F2_4_HU67');
    this.F2_5_HU67 = this.featureFlagService.isFeatureEnabled('F2_5_HU67');

    this.userStore = this.usuarioService.getCurrentUserData();

    this.idRequestEndpoint =
      this.userStore.clavePerfil === "cvdp_admin"
        ? 0
        : this.userStore.idUsuario;

    let idCampus = 0;
    let idEscuela = 0;
    let idPeriodo = 0;
    let pagina = 1;
    let hits = 3;

    // Solicitudes de Socios Formadores Potenciales
    let response = await lastValueFrom(
      this.dashboardService.getSolicitudesSocio(
        this.idRequestEndpoint,
        "registrado",
        idCampus,
        idEscuela,
        idPeriodo,
        pagina,
        hits
      )
    );
    this.solicitudesRecibidasData = this.getSolicitudesSFPotenciales(response);

    response = await lastValueFrom(
      this.dashboardService.getSolicitudesSocio(
        this.idRequestEndpoint,
        "revision",
        idCampus,
        idEscuela,
        idPeriodo,
        pagina,
        hits
      )
    );
    this.solicitudesRevisionData = this.getSolicitudesSFPotenciales(response);

    response = await lastValueFrom(
      this.dashboardService.getSolicitudesSocio(
        this.idRequestEndpoint,
        "aprobado",
        idCampus,
        idEscuela,
        idPeriodo,
        pagina,
        hits
      )
    );
    this.solicitudesAprobadasData = this.getSolicitudesSFPotenciales(response);

    response = await lastValueFrom(
      this.dashboardService.getSolicitudesSocio(
        this.idRequestEndpoint,
        "rechazado",
        idCampus,
        idEscuela,
        idPeriodo,
        pagina,
        hits
      )
    );
    this.solicitudesRechazadasData = this.getSolicitudesSFPotenciales(response);

    // Socios Formadores solicitando un reto

    let responseReg = await lastValueFrom(
      this.dashboardService.getSolicitudesRetoSocio(
        this.idRequestEndpoint,
        "registrado",
        idCampus,
        idEscuela,
        idPeriodo,
        pagina,
        hits
      )
    );

    this.solicitudesRetosRegistradasData = this.getSociosSolicitandoReto(responseReg);

    response = await lastValueFrom(
      this.dashboardService.getSolicitudesRetoSocio(
        this.idRequestEndpoint,
        "revision",
        idCampus,
        idEscuela,
        idPeriodo,
        pagina,
        hits
      )
    );

    this.solicitudesRetosData = this.getSociosSolicitandoReto(response);

    response = await lastValueFrom(
      this.dashboardService.getSolicitudesRetoSocio(
        this.idRequestEndpoint,
        "aprobado",
        idCampus,
        idEscuela,
        idPeriodo,
        pagina,
        hits
      )
    );
    this.solicitudesAprobadasRetosData = this.getSociosSolicitandoReto(
      response
    );

    response = await lastValueFrom(
      this.dashboardService.getSolicitudesRetoSocio(
        this.idRequestEndpoint,
        "rechazado",
        idCampus,
        idEscuela,
        idPeriodo,
        pagina,
        hits
      )
    );
    this.solicitudesRechazadasRetosData = this.getSociosSolicitandoReto(
      response
    );

    // Académico que bucsa un Socio Formador
    let responseRegistrado = await lastValueFrom(
      this.dashboardService.getSolicitudesAcademicos(
        this.idRequestEndpoint,
        "registrado",
        idCampus,
        idEscuela,
        idPeriodo,
        pagina,
        hits
      )
    );
    this.solicitudesAcademicosRecibidasData = this.getAcademicoBuscaSF(
      responseRegistrado
    );

    response = await lastValueFrom(
      this.dashboardService.getSolicitudesAcademicos(
        this.idRequestEndpoint,
        "revision",
        idCampus,
        idEscuela,
        idPeriodo,
        pagina,
        hits
      )
    );

    let responsePropuesta = await lastValueFrom(
      this.dashboardService.getSolicitudesAcademicos(
        this.idRequestEndpoint,
        "propuesta",
        idCampus,
        idEscuela,
        idPeriodo,
        pagina,
        hits
      )
    );
    let listado = [...responsePropuesta, ...response].slice(0, 3);
    listado = listado.sort((a, b) => b.fechaRegistro.localeCompare(a.fechaRegistro));
    this.solicitudesAcademicosData = this.getAcademicoBuscaSF(listado);

    response = await lastValueFrom(
      this.dashboardService.getSolicitudesAcademicos(
        this.idRequestEndpoint,
        "aprobado",
        idCampus,
        idEscuela,
        idPeriodo,
        pagina,
        hits
      )
    );
    this.solicitudesAprobadaAcademicosData = this.getAcademicoBuscaSF(response);

    response = await lastValueFrom(
      this.dashboardService.getSolicitudesAcademicos(
        this.idRequestEndpoint,
        "rechazado",
        idCampus,
        idEscuela,
        idPeriodo,
        pagina,
        hits
      )
    );
    this.solicitudesRechazadasAcademicosData = this.getAcademicoBuscaSF(
      response
    );

    // Socio Formador que no encontró reto (Problemáticas)
    if (this.F2_5_HU58) {
      this.load_problematicas();
    }
    if (this.F2_4_HU58) {
      this.load_problematicas2_4();
    }

    // Retos
    if(this.F2_5_HU67)
    {
      this.load_retos();
    }

    // Documentacion
    if(this.F2_4_HU67){
      this.load_retos2_4();
    }




  }

  async load_retos(){
    let idCampus = 0;
    let idEscuela = 0;
    let idPeriodo = 0;
    let pagina = 1;
    let hits = 3;

    let responseRetos = await lastValueFrom(
      this.dashboardService.getListadoRetosByCDVP(
        this.idRequestEndpoint,
        "formalizacion",
        idCampus,
        idEscuela,
        idPeriodo,
        pagina,
        hits
      )
    );
    this.listadoRetosFormalizacionData = this.getListadoRetosFormalizacion(
      responseRetos
    );

    responseRetos = await lastValueFrom(
      this.dashboardService.getListadoRetosByCDVP(
        this.idRequestEndpoint,
        "en-curso",
        idCampus,
        idEscuela,
        idPeriodo,
        pagina,
        hits
      )
    );
    this.listadoRetosCursoData = this.getListadoRetosCursoyEval(responseRetos, "en-curso");

    responseRetos = await lastValueFrom(
      this.dashboardService.getListadoRetosByCDVP(
        this.idRequestEndpoint,
        "evaluacion",
        idCampus,
        idEscuela,
        idPeriodo,
        pagina,
        hits
      )
    );
    this.listadoRetosEvaluacionData = this.getListadoRetosCursoyEval(
      responseRetos, "evaluacion"
    );

    responseRetos = await lastValueFrom(
      this.dashboardService.getListadoRetosByCDVP(
        this.idRequestEndpoint,
        "concluido",
        idCampus,
        idEscuela,
        idPeriodo,
        pagina,
        hits
      )
    );

    this.listadoRetosConcluidoData = this.getListadoRetosConcluido(
      responseRetos
    );

  }

  async load_retos2_4(){

    let idCampus = 0;
    let idEscuela = 0;
    let idPeriodo = 0;
    let pagina = 1;
    let hits = 3;

    let responseRetos = await lastValueFrom(
      this.dashboardService.getListadoRetosByCDVP(
        this.idRequestEndpoint,
        "activo",
        idCampus,
        idEscuela,
        idPeriodo,
        pagina,
        hits
      )
    );
    this.listadoRetosActivosData = this.getListadoRetos(responseRetos);

    responseRetos = await lastValueFrom(
      this.dashboardService.getListadoRetosByCDVP(
        this.idRequestEndpoint,
        "todos",
        idCampus,
        idEscuela,
        idPeriodo,
        pagina,
        hits
      )
    );
    this.listadoRetosTodosData = this.getListadoRetos(responseRetos);
  }

  async load_problematicas() {

    let idCampus = 0;
    let idEscuela = 0;
    let idPeriodo = 0;
    let pagina = 1;
    let hits = 3;

    let response = await lastValueFrom(
      this.dashboardService.getSolicitudesSinRetos(
        this.idRequestEndpoint,
        "registrado",
        idCampus,
        idEscuela,
        idPeriodo,
        pagina,
        hits
      )
    );
    this.solicitudesSinRetosData = this.getProblematicasSinReto(response);

    response = await lastValueFrom(
      this.dashboardService.getSolicitudesSinRetos(
        this.idRequestEndpoint,
        "revision",
        idCampus,
        idEscuela,
        idPeriodo,
        pagina,
        hits
      )
    );
    this.solicitudesRechazadasSinRetosData = this.getProblematicasSinReto(
      response
    );

    response = await lastValueFrom(
      this.dashboardService.getSolicitudesSinRetos(
        this.idRequestEndpoint,
        "todas",
        idCampus,
        idEscuela,
        idPeriodo,
        pagina,
        hits
      )
    );
    this.solicitudesAprobadasSinRetosData = this.getProblematicasSinRetoComments(
      response
    );
  }

  async load_problematicas2_4() {

    let idCampus = 0;
    let idEscuela = 0;
    let idPeriodo = 0;
    let pagina = 1;
    let hits = 3;

    let response = await lastValueFrom(
      this.dashboardService.getSolicitudesSinRetos(
        this.idRequestEndpoint,
        "registrado",
        idCampus,
        idEscuela,
        idPeriodo,
        pagina,
        hits
      )
    );

    let responseRevision = await lastValueFrom(
      this.dashboardService.getSolicitudesSinRetos(
        this.idRequestEndpoint,
        "revision",
        idCampus,
        idEscuela,
        idPeriodo,
        pagina,
        hits
      )
    );
    let lista = [... response, ...responseRevision].slice(0,3);

    this.solicitudesSinRetosData = this.getProblematicasSinReto(lista);

    response = await lastValueFrom(
      this.dashboardService.getSolicitudesSinRetos(
        this.idRequestEndpoint,
        "rechazado",
        idCampus,
        idEscuela,
        idPeriodo,
        pagina,
        hits
      )
    );

    this.solicitudesRechazadasSinRetosData = this.getProblematicasSinReto(
      response
    );

    response = await lastValueFrom(
      this.dashboardService.getSolicitudesSinRetos(
        this.idRequestEndpoint,
        "aprobado",
        idCampus,
        idEscuela,
        idPeriodo,
        pagina,
        hits
      )
    );
    this.solicitudesAprobadasSinRetosData = this.getProblematicasSinRetoComments(
      response
    );

  }

  getCatEstatusSeguimiento(cveEstatus: string, estatus: string, clave: string) {
    if (cveEstatus === "revision") {
      let catEstatusSeguimiento = {
        conversacion: "Conversación Inicial",
        "primer-contacto": "Primer Contacto",
        "validacion-doc": "Validación de documentos",
      };
      return catEstatusSeguimiento[clave] ?? "Revisión";
    } else if (cveEstatus === "aprobado") {
      let catEstatusSeguimiento = {
        "pendiente-firma": "Pendiente de Firma",
        rechazado: "Rechazada por el SF",
        firmado: "Firmado",
      };
      return catEstatusSeguimiento[clave] ?? "Pendiente de Firma";
    } else {
      return estatus;
    }
  }

  comentariosData: any = [];

  async getComentarios(idSolicitud) {
    this.comentariosData = [];
    try {
      let result = await lastValueFrom(
        this.solicitudesService.getSolicitudComentarios(idSolicitud)
      );
      this.comentariosData = result;
    } catch (err) {
      console.log(err);
    }
    this.modalService.open(this.comentarios, { size: "xl" });
  }

  changeViewStatus(status) {
    this.status = status;
  }
}
