<app-header-buscador [showSearchBar]="false"></app-header-buscador>

<div class="sec_ad">

  <app-title-show-more [showMoreObject]="title"></app-title-show-more>

  <div class="row">

        <!-- Filtro de Campus -->
        <div class="col-md" >
          <div class="btn-group order-by">
            <button
              class="btn dropdown-toggle"
              type="button"
              id="listado"
              data-bs-toggle="dropdown"
              data-bs-auto-close="true"
              aria-expanded="false"
              data-bs-offset="0,0"
              title="{{ filter.campus ? getValueCatalogo(campusData, 'idCampus', filter.campus, 'campus') : 'Campus' }}"
            >
            {{ filter.campus ? getValueCatalogo(campusData, 'idCampus', filter.campus, 'campus') : 'Campus' }}
            </button>
            <ul
              class="dropdown-menu dropdown-menu-start"
              aria-labelledby="listado"
            >
              <li *ngIf="campusData.length == 0" class="dropdown-item">Selecciona una escuela</li>
              <li *ngFor="let campus of campusData; let i = index">
                <a
                  class="dropdown-item {{ i === 0 ? 'active' : '' }}"
                  (click)="addFilter('campus', campus.idCampus)"
                  >{{ campus.campus }}</a
                >
              </li>
            </ul>
          </div>
        </div>


    <!-- Filtro de escuela -->
    <div class="col-md" *ngIf="false">
      <div class="btn-group order-by">
        <button
          class="btn dropdown-toggle"
          type="button"
          id="listado"
          data-bs-toggle="dropdown"
          data-bs-auto-close="true"
          aria-expanded="false"
          data-bs-offset="0,0"
          title="{{ filter.escuela ? getValueCatalogo(escuelaData, 'idEscuela', filter.escuela, 'escuela') : 'Escuela' }}"
        >
        {{ filter.escuela ? getValueCatalogo(escuelaData, 'idEscuela', filter.escuela, 'escuela') : 'Escuela' }}
        </button>
        <ul class="dropdown-menu dropdown-menu-start" aria-labelledby="listado">
          <li *ngFor="let escuela of escuelaData; let i = index">
            <a
              class="dropdown-item {{ i === 0 ? 'active' : '' }}"
              (click)="addFilter('escuela', escuela.idEscuela)"
              >{{ escuela.escuela }}</a
            >
          </li>
        </ul>
      </div>
    </div>

    <!-- Filtro de Periodo -->
    <div class="col-md" *ngIf="false">
      <div class="btn-group order-by">
        <button
          class="btn dropdown-toggle"
          type="button"
          id="listado"
          data-bs-toggle="dropdown"
          data-bs-auto-close="true"
          aria-expanded="false"
          data-bs-offset="0,0"
          title="{{ filter.periodo ? getValueCatalogo(periodoData, 'idPeriodo', filter.periodo, 'periodo') : 'Periodo' }}"
        >
        {{ filter.periodo ? getValueCatalogo(periodoData, 'idPeriodo', filter.periodo, 'periodo') : 'Periodo' }}
        </button>
        <ul class="dropdown-menu dropdown-menu-start" aria-labelledby="listado">
          <li *ngFor="let periodo of periodoData; let i = index">
            <a
              class="dropdown-item {{ i === 0 ? 'active' : '' }}"
              (click)="addFilter('periodo', periodo.idPeriodo)"
              >{{ periodo.periodo }}</a
            >
          </li>
        </ul>
      </div>
    </div>


    <!-- Reestablecer Filtros -->
    <div class="col-md d-flex justify-content-end">
      <app-button (callbackAction)="cleanFilters()"> Restablecer </app-button>
    </div>
  </div>

  <div id="sec_tab" class="nav nav-pills d-flex flex-md-nowrap flex-wrap" role="presentation">
    <div class="active" prm="re_hs" data-bs-toggle="pill" data-bs-target="#tab-solicitudes-recibidas" aria-controls="tab-solicitudes-recibidas" prm="" role="tab" type="button" aria-selected="true">
      {{ 'solicitudes_socios.tab_recibidas' | translate }}
    </div>
    <div  data-bs-toggle="pill" data-bs-target="#tab-solicitudes-revisar" aria-controls="tab-solicitudes-revisar" prm="re_ac" role="tab" type="button" aria-selected="false">
      {{ 'solicitudes_socios.tab_revision' | translate }}
    </div>
    <div prm="re_hs" data-bs-toggle="pill" data-bs-target="#tab-solicitudes-aprobadas" aria-controls="tab-solicitudes-aprobadas" prm="" role="tab" type="button" aria-selected="false">
      {{ 'solicitudes_socios.tab_aprobadas' | translate }}
    </div>
    <div prm="re_hs" data-bs-toggle="pill" data-bs-target="#tab-solicitudes-rechazadas" aria-controls="tab-solicitudes-rechazadas" class="" role="tab" type="button" aria-selected="false">
        {{ 'solicitudes_socios.tab_rechazadas' | translate }}
    </div>
</div>

<div class="tab-content" id="pills-tabContent">
  <div class="tab-pane fade show active" id="tab-solicitudes-recibidas" role="tabpanel" aria-labelledby="tab-solicitudes-recibidas-tab">

    <app-table [backgroundColor]="backgroundTable" [columns]="solicitudesColumns" [data]="solicitudesRegistradasPaginaActual"></app-table>

      <app-pagination *ngIf="solicitudesRegistradasData.length" [objPagination]="solicitudesRegistradasData"
        (paginationDataChange)="showPaginationRegistradoData($event)" ></app-pagination>

</div>
<div class="tab-pane fade " id="tab-solicitudes-revisar" role="tabpanel" aria-labelledby="tab-solicitudes-revisar-tab">

  <app-table [backgroundColor]="backgroundTable" [columns]="solicitudesColumns" [data]="solicitudesRevisionPaginaActual" ></app-table>

    <app-pagination *ngIf="solicitudesRevisionData.length" [objPagination]="solicitudesRevisionData"
      (paginationDataChange)="showPaginationRevisionData($event)" ></app-pagination>

</div>
    <div class="tab-pane fade" id="tab-solicitudes-aprobadas" role="tabpanel" aria-labelledby="tab-solicitudes-aprobadas-tab">

        <app-table [backgroundColor]="backgroundTable" [columns]="solicitudesColumns" [data]="solicitudesAprobadasPaginaActual" ></app-table>

          <app-pagination *ngIf="solicitudesAprobadasData.length" [objPagination]="solicitudesAprobadasData"
            (paginationDataChange)="showPaginationAprobadasData($event)" ></app-pagination>

    </div>
    <div class="tab-pane fade" id="tab-solicitudes-rechazadas" role="tabpanel" aria-labelledby="tab-solicitudes-rechazadas-tab">

        <app-table [backgroundColor]="backgroundTable" [columns]="solicitudesColumns" [data]="solicitudesRechazadasPaginaActual"></app-table>

        <app-pagination *ngIf="solicitudesRechazadasData.length" [objPagination]="solicitudesRechazadasData"
            (paginationDataChange)="showPaginationDataRechazado($event)" ></app-pagination>

    </div>
</div>



</div>
