import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-title-show-more',
  templateUrl: './title-show-more.component.html',
  styleUrls: ['./title-show-more.component.css'],
})
export class TitleShowMoreComponent  {
  @Input()
  showMoreObject: any;


  callbackClick() {
    this.showMoreObject?.callbackClick();
  }
}
