<div class="row top-40px">
    <div *ngFor=" let indicator of indicators; let i = index" class="col-6">
        <div class="row">
            <div class="col-2 mt-3">
                <h2 class="dbd_title">
                    <img alt="Perfil" class="imgperfil" src="assets/svg/{{ indicator.icon }}.svg">
                </h2>
            </div>
            <div class="col-10">
                <div>
                    <h2 class="dbd_title numdash">{{indicator.value}}</h2>
                    <p class="dbd_data">{{indicator.title}}</p>
                    <br>
                </div>
            </div>
        </div>
    </div>
</div>