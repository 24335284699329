<div class="subtitlecard textblue">COMPROMISOS Y RECURSOS DEL SOCIO FORMADOR</div>
<div class="section-reto">
    <div class="subtitlecard">COMPROMISOS </div>
    <div class="subtitlecard">Compromiso 1 </div>
    <div class="txtcard"><u>a.Descripcion:</u> Proyecto que contenga el desarrollo de la solución mediante metodologías
        de ideación. La propuesta de solución debe ser deseable (mercado), viable (financiera), y factible (técnica) al
        Consejo Directivo de la Empresa. Conclusiones y Referencias. </div>
    <div class="txtcard"><u>b. Momento de la entrega:</u> Primer semana una vez que arranque el semestre.</div>

    <div class="subtitlecard mt-4">Compromiso 2 </div>
    <div class="txtcard"><u>a.Descripcion:</u> Proyecto que contenga el desarrollo de la solución mediante metodologías
        de ideación. La propuesta de solución debe ser deseable (mercado), viable (financiera), y factible (técnica) al
        Consejo Directivo de la Empresa. Conclusiones y Referencias. </div>
    <div class="txtcard"><u>b. Momento de la entrega:</u> Primer semana una vez que arranque el semestre.</div>

    <div class="subtitlecard">RECURSOS </div>
    <div class="subtitlecard">Recurso 1 </div>
    <div class="txtcard"><u>a.Tipo de recurso:</u> Económico </div>
    <div class="txtcard"><u> Descripcion:</u> Proyecto que contenga el desarrollo de la solución mediante metodologías
        de ideación. La propuesta de solución debe ser deseable (mercado), viable (financiera), y factible (técnica) al
        Consejo Directivo de la Empresa. Conclusiones y Referencias. </div>
    <div class="txtcard"><u>b. Momento de la entrega:</u> Primer semana una vez que arranque el semestre.</div>


    <div class="subtitlecard">Recurso 2 </div>
    <div class="txtcard"><u>a.Tipo de recurso:</u> Económico </div>
    <div class="txtcard"><u> Descripcion:</u> Proyecto que contenga el desarrollo de la solución mediante metodologías
        de ideación. La propuesta de solución debe ser deseable (mercado), viable (financiera), y factible (técnica) al
        Consejo Directivo de la Empresa. Conclusiones y Referencias. </div>
    <div class="txtcard"><u>b. Momento de la entrega:</u> Primer semana una vez que arranque el semestre.</div>


</div>