import { Component, Input } from "@angular/core";
import { AzureBlobStorageService } from "@shared/service/AzureBlobStorage.Service";
import { SociosFormadoresService } from "@shared/service/Socios_Formadores/socios-formadores.service";
import { UsuariosService } from "@shared/service/Usuarios/usuarios.service";
import { environment } from "environments/environment";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-file-type",
  templateUrl: "./file-type.component.html",
  styleUrls: ["./file-type.component.css"],
})
export class FileTypeComponent  {
  @Input()
  objFileWithOwner: any;

  constructor(
    private _usuariosService: UsuariosService,
    private _azureBlobStorage: AzureBlobStorageService,
    private _sociosFormadores: SociosFormadoresService,
    private _toastr: ToastrService
  ) {}



  async selImagen(event: any) {
    let idUsr = this.objFileWithOwner.id;
    let idReto = this.objFileWithOwner.idReto;
    let selFile = (event.target as HTMLInputElement).files?.[0];
    
      // Valida existencia
    if (!selFile) return;
    // Valida tamaño
    if (selFile.size > 2e6) {
      console.error("Error: Tamaño máximo de 2MB");
      this._toastr.error("Error: Tamaño máximo de 2MB");
      return;
    }
    // Obtiene nombre
    let fileName = this.getFileName(idUsr, idReto, selFile);
    // guarda en bd
    await this.uploadSocioImage(idUsr, selFile, fileName, idReto);
  }

  async uploadSocioImage(idUsr, selFile, fileName, idReto) {
    await this._azureBlobStorage
      .uploadFile(
        {
          containerName: environment.AzureBlob_containerNameSocio,
          file: selFile,
          filename: fileName,
        },
        environment.AzureBlob_ACCOUNT_NAME,
        environment.AzureBlob_socioSAS
      )
      .then((res) => {
        let fileUrlLogo = `${environment.AzureBlob_socioURL}${fileName}`;
        this._sociosFormadores
          .postCartaColaboracion({
            idSocioFormador: idUsr,
            idReto: idReto,
            urlCartaColaboracion: fileUrlLogo,
          })
          .subscribe((resOK) => {
            this.objFileWithOwner.url = fileUrlLogo;
          });
      });
  }  

  getFileName(idsf, idReto, file) {
    return `socio_carta_colaboracion_${idsf}_${idReto}.${
      file.name.split(".").pop()
    }`.toLowerCase();
  }
}
