import { HttpErrorResponse } from "@angular/common/http";
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { FormGroup, FormBuilder, Validators, UntypedFormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectComponent } from "@ng-select/ng-select";
import { IArea, IMedioContacto } from "@shared/Interface/ICatalogos.interface";
import { RetosResponse } from "@shared/Interface/IRetosResponse";
import { ISocioFormadorResponse } from "@shared/Interface/ISocioFormadorResponse";
import Utils from "@shared/helpers/utils";
import { CatalogosService } from "@shared/service/Catalogos/catalogos.service";
import { ContactosService } from "@shared/service/Contactos/contactos.service";
import { DashboardService } from "@shared/service/Dashboard/dashboard.service";
import { InicioService } from "@shared/service/Inicio/inicio.service";
import { RetosService } from "@shared/service/Retos/retos.service";
import { SociosFormadoresService } from "@shared/service/Socios_Formadores/socios-formadores.service";
import { UsuariosService } from "@shared/service/Usuarios/usuarios.service";
import { ToastrService } from "ngx-toastr";
import { lastValueFrom } from "rxjs";
import Swal from "sweetalert2";

@Component({
  selector: "app-sobre-sf",
  templateUrl: "./sobre-sf.component.html",
  styleUrls: ["./sobre-sf.component.css"],
})
export class SobreSfComponent implements OnInit {
  @ViewChild("nuevoContacto") nuevoContacto: ElementRef;
  @ViewChild("ngSFContacto") ngSFContacto: NgSelectComponent;
  @ViewChild("ngSFSocio") ngSFSocio : NgSelectComponent;
  showAddContactToMatch = false;

  lstprofe: any[] = [];
  contactosData: any[] | null = [];
  sobresfForm: FormGroup;
  contactoNuevoForm: FormGroup;

  sociosData: ISocioFormadorResponse[] = [];
  sociosDataTmp: ISocioFormadorResponse[] = [];

  lstContactosReto: any = [];

  idSocioFormador: number;
  lbl_razonSocial: string = "-";
  lbl_rfc: string = "-";
  lbl_imgSource: string =
    "https://mvretosstorage.blob.core.windows.net/mvpretos-app-assets/logo_sf_sin.jpg";
  lbl_Descripcion: string = "";

  mostrarAltaSocioFormador: boolean = false;
  mostrarAltaContacto: boolean = false;

  clavePerfil: string = "";

  Areas!: IArea[];
  MediosDeContacto!: IMedioContacto[];

  @Input() idReto: number;
  @Input() status: string;
  @Input() oReto: RetosResponse;

  @Output() sobreSF: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output() firmarCarta: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output() sobreSFeliminado: EventEmitter<any> = new EventEmitter<any>();
  @Output() mostrarFormulario: EventEmitter<any> = new EventEmitter<any>();



  public documentarSocio: boolean = false;
  constructor(
    private fb: FormBuilder,
    private sociosFormadoresService: SociosFormadoresService,
    private contactoService: ContactosService,
    private dashboardService: DashboardService,
    private usuarioService: UsuariosService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private retoService: RetosService,
    private toastr: ToastrService,
    private catalogosSvc: CatalogosService,
    private inicio: InicioService
  ) {
    this.sobresfForm = this.fb.group({
      busqueda: [""],
      sf: [""],
      contactos: [],
      carta: [],
      idContacto: [],
    });
  }

  initFormC(): UntypedFormGroup {
    return this.fb.group({
      nombre_contacto: ["", [Validators.required, Validators.minLength(3)]],
      apellidos_contacto: ["", [Validators.required, Validators.minLength(3)]],
      area_departamento_contacto: [null, ],
      puesto_contacto: ["", [Validators.minLength(3)]],
      email_contacto: ["", [Validators.required, Validators.email]],
      telefono_oficina_contacto: ["", [Validators.pattern(/\d/)]],
      celular_contacto: ["", [Validators.pattern(/\d/)]],
      exatec_contacto: [null,],
      enteraste_contacto: [null, ],
      terminos_condiciones: [null, ],
    });
  }

  async ngOnInit() {
    this.contactoNuevoForm = this.initFormC();
    this.clavePerfil = this.usuarioService.getCurrentUserData().clavePerfil;

    this.getSocio();
    this.obtenerContactosReto();

    let regresaNuevoSocio = sessionStorage.getItem("documentarRetoNuevoSocio") ?? 0;
    if( regresaNuevoSocio == 1 ){
      this.getSociosFormadores();
    }

    // Areas
    this.Areas = await lastValueFrom(this.catalogosSvc.getAreas());

    // Medios de contacto
    this.MediosDeContacto = await lastValueFrom(this.catalogosSvc.getMediosContacto());
  }

  mostrarSocioFormador() {
    this.documentarSocio = !this.documentarSocio;
    this.mostrarFormulario.emit(this.documentarSocio);
  }

  async getSocio() {
    try {
      let retoTmp: any = this.oReto;
      this.idSocioFormador = this.oReto.idSocioFormador;
      if (this.oReto.idSocioFormador != 0) {
        await this.getSociosFormadores();
        await this.getContactos({
          idSocioFormador: this.oReto.idSocioFormador,
        });
      }

      let socio = await lastValueFrom(
        this.sociosFormadoresService.getSociosFormadoresById(
          retoTmp.idSocioFormador
        )
      );
      this.lbl_razonSocial = socio.razonSocial;
      this.lbl_Descripcion = socio.empresa;
      this.lbl_rfc = socio.rfc;
      this.lbl_imgSource = socio.urlLogo;

      let info: any = {
        idSocioFormador: retoTmp.idSocioFormador,
      };
      this.sobreSF.emit(info);
      this.sobresfForm.patchValue({
        sf: retoTmp.idSocioFormador,
      });
      if (this.oReto.idSolicitud == 0) {
        this.sobresfForm.patchValue({ busqueda: "No" });
      } else {
        this.sobresfForm.patchValue({ busqueda: "Si" });
      }
    } catch (err:any) {
      Utils.processErrorResponse(err);
    }
  }

  async addContacto() {
    let idContacto = this.sobresfForm.get("idContacto")?.value ?? 0;
    if (idContacto === -1) {
      return;
    }

    let result: any[] = [];
    try {
      result = await lastValueFrom(
        this.retoService.getAllRetoContacto(this.oReto.idReto)
      );
    } catch (err:any) {
      Utils.processErrorResponse(err);
    }

    if (!result.find((x) => x.idContacto === idContacto)) {
      this.retoService
        .postRetoContacto({
          idReto: this.idReto,
          idContacto: idContacto,
        })
        .subscribe({
          next: (data) => {
            this.obtenerContactosReto();
          },
        });
    }

    this.toastr.success("Contacto agregado correctamente.");
  }

  // firmar() {
  //   this.firmarCartaSF = true;
  //   this.firmarCarta.emit(this.sobresfForm.value);
  // }

  async getSociosFormadores() {
    try {
      this.limpiarSF();
      this.sociosData = await lastValueFrom(
        this.sociosFormadoresService.getAllSociosFormadores(
          "",
          0,
          0,
          "",
          0,
          "todos",
          0,
          false,
          true
        )
      );
      let sfOtro: ISocioFormadorResponse = {
        idSocioFormador: -1,
        nombreSocioFormador: "Otro",
        razonSocial: "",
        ciudad: "",
        empresa: "",
        empresaShort: "",
        estado: "",
        grupoComercial: "",
        numeroEmpleados: 0,
        rfc: "",
        domicilioFiscal: "",
        calle: "",
        ciudadFiscal: "",
        cp: "",
        claveEstado: "",
        pais: "",
        telefonoEmpresa: "",
        alcance: "",
        area: "",
        claveEstatus: "",
        estatus: "",
        idSector: 0,
        sector: "",
        idTamano: 0,
        tamano: "",
        idPresencia: 0,
        presencia: "",
        presenciaC: "",
        paginaWeb: "",
        twitter: "",
        linkedIn: "",
        facebook: "",
        instagram: "",
        idCampus: 0,
        campus: "",
        idTipoSocioFormador: 0,
        tipoSocioFormador: "",
        fechaRegistro: "",
        idIndustria: 0,
        industria: "",
        relacionesTec: [],
        urlLogo: "",
        urlLogoSocioFormador: "",
        idCVDPResponsable: 0,
        nombreCVDPResponsable: "",
        colonia: "",
        idUsuarioRegistro: 0,
        conoceRolSF: false,
        claveEstatusSeguimiento: "",
        estatusSeguimiento: "",
        verLogoHome: false,
        evaluacion: 0,
        evaluadores: 0
      };

      this.sociosData.splice(0, 0, sfOtro);
    } catch (error:any) {
      Utils.processErrorResponse(error);
    }
  }

  limpiarSF() {
    this.lbl_razonSocial = "-";
    this.lbl_rfc = "-";
    this.lbl_imgSource =
      "https://mvretosstorage.blob.core.windows.net/mvpretos-app-assets/logo_sf_sin.jpg";
  }

  async getContactos(event) {
    this.contactosData = [];
    this.limpiarSF();
    this.sobresfForm.patchValue({sf: 0});
    //this.idSocioFormador = 0;
    if(!event){
      event = {idSocioFormador : 0};
      let info: any = {
        idSocioFormador: 0,
      };
      this.sobreSF.emit(info);
    }

    if (event.idSocioFormador === -1) {
      this.agregarSocioFormador();
      return;
    }
    console.log(this.idSocioFormador);
    if(this.idSocioFormador == 0 || this.idSocioFormador == event.idSocioFormador){
      this.llenarlistaContactos(event);
      return;
    }

    if (this.idSocioFormador != event.idSocioFormador) {
      Swal.fire({
        title: "¿Desea cambiar el Socio Formador?",
        text: "Se perderán los datos de contactos.",
        showCancelButton: true,
        cancelButtonText: "No",
        confirmButtonText: "Si",
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.showAddContactToMatch= false;
          this.eliminarContactos(this.idSocioFormador);
          this.llenarlistaContactos(event);
          this.sobreSFeliminado.emit();
        }
        if(result.isDismissed){
          this.sobresfForm.patchValue({sf: this.idSocioFormador});
        }
      });
    }

  }

  async llenarlistaContactos(event){

    this.idSocioFormador = event.idSocioFormador;

    if(!event){
      return;
    }

    if(!event.idSocioFormador){
      return;
    }

    if(event.idSocioFormador == 0){
      return;
    }

    let info: any = {
      idSocioFormador: event.idSocioFormador,
    };
    this.sobreSF.emit(info);

    this.lbl_razonSocial = event.razonSocial;
    this.lbl_rfc = event.rfc;
    this.lbl_imgSource = event.urlLogo;
    this.lbl_Descripcion = event.empresa;

    this.contactosData = null;
    let otroContacto = {
      nombre: "Otro",
      idUsuario: -1,
      idContacto: -1,
    };
    try {
      if(event.idSocioFormador > 0){
      let resultado = await lastValueFrom(
        this.contactoService.getContactos(event.idSocioFormador)
      );
      this.contactosData = resultado.map((x) => {
        return {
          nombre: `${x.nombre} ${x.apellidos}`,
          idUsuario: x.idUsuario,
          email: x.email,
          puesto: x.puesto,
          idContacto: x.idContacto,
        };
      });
    }
    } catch (error:any) {
      Utils.processErrorResponse(error,"Error al obtener el detalle de contactos por su ID ");
    }

    if(this.contactosData){
      this.contactosData.splice(0, 0, otroContacto);
    }else{
      this.contactosData = [otroContacto];
    }
  }

  agregarSocioFormador() {
    Swal.fire({
      text: "¿Desea agregar un nuevo Socio Formador?",
      confirmButtonText: "Si",
      cancelButtonText: "No",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        this.mostrarAltaSocioFormador = true;
        this.irAgregarSocioFormador();
        this.showAddContactToMatch= false;
      }
      else{
        try{
          this.ngSFSocio.handleClearClick();
        }catch(error: any){
          console.log(error);
        }
      }
    });
  }

  irAgregarSocioFormador() {
    sessionStorage.setItem("idSocioFormador_banco_b", "false");
    sessionStorage.setItem("idSocioFormador_banco", "0");
    sessionStorage.setItem("documentarRetoNuevoSocio", "1");
    this.inicio.changeSubMenuItem('datosempresa');
    let rutaClave = Utils.getRouteFromProfile(this.usuarioService.getCurrentUserData().clavePerfil);
    this.router.navigate([`/${rutaClave}/registro-sf`], {
      relativeTo: this.route,
    });
  }

  agregarNuevoContacto(event) {
    if (event.idUsuario > -1){
      // Se autoagrega
      //this.showAddContactToMatch = true;
      this.addContacto();
      return;
    }

    this.showAddContactToMatch= false;
    Swal.fire({
      text: "¿Desea agregar un nuevo Contacto?",
      confirmButtonText: "Si",
      cancelButtonText: "No",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        this.mostrarAltaContacto = true;
        this.modalService.open(this.nuevoContacto, { centered: true, size: 'xl' });
      }
      else{
        try{
          this.ngSFContacto.handleClearClick();
        }catch(e){
          console.log(e)
        }
      }
    });
  }

  cancelNewContact(){
    try{
      this.mostrarAltaContacto = false;
      this.ngSFContacto.handleClearClick();
      this.modalService.dismissAll();
    }catch(e){
      console.log(e)
    }
  }

  async obtenerSFBuscados() {
    this.limpiarSF();
    this.sociosData = [];

    // Obtener las solicitudes de este usuario
    let idAcademico = this.usuarioService.getCurrentUserData().idUsuario;
    let cvePerfil = this.usuarioService.getCurrentUserData().clavePerfil;

    let idRequestEndpoint = cvePerfil === "cvdp_admin" ? 0 : idAcademico;

    let solAcademicoRev = await lastValueFrom(
      this.dashboardService.getSolicitudesAcademicosxSocio(
        idRequestEndpoint,
        "revision",
        0,
        0,
        0,
        0,
        0
      )
    );
    let solAcademicoReg = await lastValueFrom(
      this.dashboardService.getSolicitudesAcademicosxSocio(
        idRequestEndpoint,
        "registrado",
        0,
        0,
        0,
        0,
        0
      )
    );

    let lista = [...solAcademicoReg, ...solAcademicoRev];

    let listaSocios = lista.filter((x) =>
      x.reto.split("_")[0].includes(this.oReto.reto.split("_")[0])
    );

    if (this.sociosDataTmp.length == 0) {
      this.sociosDataTmp = await lastValueFrom(
        this.sociosFormadoresService.getAllSociosFormadores(
          "",
          0,
          0,
          "",
          0,
          "activo",
          0,
          false
        )
      );
    }

    let sociosIDs = listaSocios.map((x) => x.idSocioFormador);
    this.sociosData = this.sociosDataTmp.filter((x) =>
      sociosIDs.includes(x.idSocioFormador)
    );
  }

  mostrarDetalleSocioFormador(detalle) {
    Swal.fire({ text: detalle });
  }

  async guardarNuevoContacto() {
    try{

      this.contactoNuevoForm.markAllAsTouched();
      if (this.contactoNuevoForm.valid) {

        await this.saveNewContact();

      }
    }catch(e){
      console.log(e);
    }
  }

  async obtenerContactosReto() {
    this.lstContactosReto = [];
    try {
      let result = await lastValueFrom(
        this.retoService.getAllRetoContacto(this.idReto)
      );
      this.lstContactosReto = result.map((x) => {
        return {
          idRetoContacto: x.idRetoContacto,
          idContacto: x.idContacto,
          nombre: x.nombreContacto,
          apellidos: x.apellidosContacto,
          email: x.correoContacto,
          puesto: x.puestoContacto,
        };
      });
    } catch (error:any) {
      Utils.processErrorResponse(error);
    }
  }

  async eliminarContacto(idRetoContacto) {
    try {
      await lastValueFrom(
        this.retoService.deleteRetoContacto(idRetoContacto)
      );
      this.toastr.success("Contacto eliminado correctamente.");
      this.obtenerContactosReto();
    } catch (err:any) {
      Utils.processErrorResponse(err);
    }
  }

  infoSF: any;
  sobreSFCV(event: any) {
    this.infoSF = event;
  }

  firmarCartaCV(event: any) {
    console.log(event);
  }

  async eliminarContactos(idSocioFormadorActual) {
    try {
      this.lstContactosReto = [];
      this.sobresfForm.patchValue({idContacto: ''})
      let res = await lastValueFrom(
        this.retoService.getAllRetoContacto(this.oReto.idReto)
      );
      for(let item of res){
        await lastValueFrom(
          this.retoService.deleteRetoContacto(
            item.idRetoContacto
          )
        );
      }
    } catch (error:any) {
      Utils.processErrorResponse(error);
    }
  }

  async saveNewContact() {
    //console.log(this.idSocioFormador);

    let idSocioFormador = this.sobresfForm.get("sf")?.value;
    if(!idSocioFormador || idSocioFormador <= 0){
      idSocioFormador = this.idSocioFormador;
    }

    this.contactoNuevoForm.markAllAsTouched();

    let contactP = false;

    if (this.contactoNuevoForm.valid) {
      let request = {
        email: this.contactoNuevoForm.value.email_contacto,
        nombre: this.contactoNuevoForm.value.nombre_contacto,
        apellidos: this.contactoNuevoForm.value.apellidos_contacto,
        area: this.contactoNuevoForm.value.area_departamento_contacto,
        puesto: this.contactoNuevoForm.value.puesto_contacto,
        telefonoOficina: this.contactoNuevoForm.value.telefono_oficina_contacto,
        telefonoMovil: this.contactoNuevoForm.value.celular_contacto,
        idMedioContacto: this.contactoNuevoForm.value.enteraste_contacto,
        exatec: this.contactoNuevoForm.value.exatec_contacto,
        contactoPrincipal: contactP,
        idUsuarioRegistro: this.usuarioService.getCurrentUserData().idUsuario
      };
      try{
        let resultado = await lastValueFrom(this.contactoService
          .postContactos(idSocioFormador, request));

            if (resultado) {
              this.toastr.success("Actualizado Correctamente!");

              await lastValueFrom(this.contactoService.putContactos(idSocioFormador,{...resultado, ...request}));

              await lastValueFrom(this.usuarioService.putFotos({
                  idUsuario: resultado.idUsuario
                  ,urlfotos:'https://mpstecstorage.blob.core.windows.net/mvpretos-user-images/reto_4.jpg'}));

              // Si viene del alta académico, de la documentacion de retos, se auto aprueba el contacto
              // Incidencia2.5/327 => se autoaprueba
              try{
                await lastValueFrom(this.usuarioService.putUsuarioActivacion(resultado.idUsuario, 'activo'));
              }
              catch(e){
                console.log(e);
              }

              // Guardamos la relacion del contacto con le reto
              await lastValueFrom(this.retoService.postRetoContacto({
                idReto: this.idReto,
                idContacto: resultado.idContacto,
              }));

              //Lo asignamos a la lista
              this.lstContactosReto.push({
                idContacto: resultado.idContacto,
                nombre: this.contactoNuevoForm.get("nombre_contacto")?.value,
                apellidos: this.contactoNuevoForm.get("apellidos_contacto")?.value,
                email: this.contactoNuevoForm.get("email_contacto")?.value,
                puesto: this.contactoNuevoForm.get("puesto_contacto")?.value,
              });

              // Actualizamos lista de contactos
              this.llenarlistaContactos({idSocioFormador: idSocioFormador });

              this.contactoNuevoForm.reset();

              this.modalService.dismissAll();

            } else {
              this.toastr.error("Algo Salió mal!");
            }
          }
        catch(error){
          console.log(error);
          this.toastr.error((error as HttpErrorResponse).message);
        };
    }
  }
}
