<app-header-buscador [showSearchBar]="false"></app-header-buscador>

<div class="sec_ad">
  <div>
    <img alt="Reto" class="img_hdr" src="assets/img/img_reto_fullsize.jpg" />
  </div>
  <app-title-show-more
    [showMoreObject]="titleVinculacion"
  ></app-title-show-more>
  <div class="row">
    <div class="col-md-12">
      <div class="flex-fill">
        Une a los Socios Formadores a nuevos desafíos para mostrar el potencial
        de las Unidades Formativas.
      </div>
    </div>
  </div>
  <div class="row text">
    <div class="col-md-6">
      <div class="row" *ngIf="retoData.escuela">
        <div class="col-md-12 mb-3 mt-4">
          <strong>Escuela:</strong>
        </div>
        <div class="col-md-12">
          <span>{{ retoData.escuela }}</span>
        </div>
      </div>
      <div class="row" *ngIf="retoData.unidadFormativa">
        <div class="col-md-12 mb-3 mt-4">
          <strong>Unidad Formativa:</strong>
        </div>
        <div class="col-md-12">
          <span>{{ retoData.unidadFormativa }}</span>
        </div>
      </div>
      <div class="row" *ngIf="retoData.semestre">
        <div class="col-md-12 mb-3 mt-4">
          <strong>Semestre:</strong>
        </div>
        <div class="col-md-12">
          <span>{{ retoData.semestre }}° Semestre</span>
        </div>
      </div>

      <div class="row" *ngIf="retoData.alcance">
        <div class="col-md-12 mb-3 mt-4">
          <strong>Presencia:</strong>
        </div>
        <div class="col-md-12">
          <span>{{ retoData.alcance }}</span>
        </div>
      </div>

      <div class="row" *ngIf="retoData.region">
        <div class="col-md-12 mb-3 mt-4">
          <strong>Región:</strong>
        </div>
        <div class="col-md-12">
          <span>{{ retoData.region }}</span>
        </div>
      </div>

      <div class="row" *ngIf="retoData.campus">
        <div class="col-md-12 mb-3 mt-4">
          <strong>Campus:</strong>
        </div>
        <div class="col-md-12">
          <span
            >{{ getJoinArray(retoData.campus, 'campus') }}</span
          >
        </div>
      </div>

      <div class="row" *ngIf="retoData.periodo">
        <div class="col-md-12 mb-3 mt-4">
          <strong>Periodo Académico:</strong>
        </div>
        <div class="col-md-12">
          <span>{{ retoData.periodo }}</span>
        </div>
      </div>

      <div class="row" *ngIf="retoData.semanas">
        <div class="col-md-12 mb-3 mt-4">
          <strong>Duración:</strong>
        </div>
        <div class="col-md-12">
          <span>{{ retoData.semanas }}</span>
        </div>
      </div>

      <div class="row" *ngIf="retoData.idioma">
        <div class="col-md-12 mb-3 mt-4">
          <strong>Idioma:</strong>
        </div>
        <div class="col-md-12">
          <span>{{ retoData.idioma }}</span>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="row" *ngIf="retoData.reto">
        <div class="col-md-12 mb-3 mt-4">
          <strong>Nombre del Reto:</strong>
        </div>
        <div class="col-md-12">
          <span>{{ retoData.reto }}</span>
        </div>
      </div>

      <div class="row" *ngIf="retoData.descripcion">
        <div class="col-md-12 mb-3 mt-4">
          <strong>Descripción breve del reto:</strong>
        </div>
        <div class="col-md-12">
          <span>{{ retoData.descripcion }}</span>
        </div>
      </div>

      <div class="row" *ngIf="retoData.empresaFamiliar">
        <div class="col-md-12 mb-3 mt-4">
          <strong>Tipo de Empresa:</strong>
        </div>
        <div class="col-md-12">
          <span> {{ retoData.empresaFamiliar ? 'Familiar' : 'No Familiar' }}</span>
        </div>
      </div>

      <div class="row" *ngIf="retoData.fechaInicio">
        <div class="col-md-12 mb-3 mt-4">
          <strong>Fecha de inicio:</strong>
        </div>
        <div class="col-md-12">
          <span>{{ retoData.fechaInicio }}</span>
        </div>
      </div>

      <div class="row" *ngIf="retoData.fechaFin">
        <div class="col-md-12 mb-3 mt-4">
          <strong>Fecha de término:</strong>
        </div>
        <div class="col-md-12">
          <span>{{ retoData.fechaFin }}</span>
        </div>
      </div>

      <div class="row" *ngIf="retoData.sector">
        <div class="col-md-12 mb-3 mt-4">
          <strong>Sector:</strong>
        </div>
        <div class="col-md-12">
          <span>{{ retoData.sector }}</span>
        </div>
      </div>

      <div class="row" *ngIf="retoData.industria">
        <div class="col-md-12 mb-3 mt-4">
          <strong>Industria:</strong>
        </div>
        <div class="col-md-12">
          <span>{{ retoData.industria }}</span>
        </div>
      </div>


      <div class="row mt-5">
        <div class="col-md-6">
          <app-button (callbackAction)="updateReto()">Modificar</app-button>
        </div>
        <div class="col-md-6">
          <app-button buttonType="yell" (callbackAction)="createReto()">Publicar este Reto</app-button>
        </div>
      </div>
    </div>
  </div>
</div>
