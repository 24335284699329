import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, FormGroupName, Validators } from "@angular/forms";
import { UsuariosService } from "@shared/service/Usuarios/usuarios.service";
import { lastValueFrom, Observable } from "rxjs";
import { ToastrService } from "ngx-toastr";

import Swal from "sweetalert2";

import {
  ICampus,
  IEscuela,
  IEscuelaDepartamento,
  IRegion,
  IRolAcademico,
} from "@shared/Interface/ICatalogos.interface";
import { environment } from "environments/environment";
import { CatalogosService } from "@shared/service/Catalogos/catalogos.service";
import { passwordMatch } from "./passwordMatch.validator";

declare const window: any;

@Component({
  selector: "app-academico-perfil-registro",
  templateUrl: "./academico-perfil-registro.component.html",
  styleUrls: ["./academico-perfil-registro.component.css"],
})
export class AcademicoPerfilRegistroComponent implements OnInit {
  contactForm: FormGroup;
  regionData: IRegion[];
  escuelaDepartamentosData: IEscuelaDepartamento[];
  campusData$: Observable<ICampus[]>;
  escuelasData$: Observable<IEscuela[]>;
  rolesAcademicosData$: Observable<IRolAcademico[]>;

  userData: any;
  idUser: any;
  userType: any;
  clavePerfil: string;

  imgUrl = "assets/img/avatar_sin.jpg";

  frmEmail: FormGroup;
  frmCreaPwd: FormGroup;
  frmCreaNvoPwd: FormGroup;

  email_usuario_recupera = new FormControl("",[Validators.required,Validators.email,
    Validators.pattern(environment.regexEmailAccepted)]);
  email_usuario_enviac = new FormControl("",[Validators.required,Validators.email,
    Validators.pattern(environment.regexEmailAccepted)]);

  txt_codigo = new FormControl("",[Validators.required,Validators.pattern(/^\d{6}$/)]);

  formModal: any;
  mensajeModal: string = "";

  vDatosDocente: boolean = true;
  vRecuperaPwd: boolean = false;
  vEnviaCodigo: boolean = false;
  vCreaNvoPwd: boolean = false;
  vRegistroDocente: boolean = false;
  vCreaPwd: boolean = false;
  vConfirma: boolean = false;
  vConfirmaPwd: boolean = false;

  existeEmail: boolean = false;

  constructor(
    private fb: FormBuilder,
    private usuariosService: UsuariosService,
    private toastr: ToastrService,
    private catalogosService: CatalogosService,
    private _usuarioService: UsuariosService
  ) {
    this.escuelasData$ = this.catalogosService.getEscuelas();
    this.campusData$ = this.catalogosService.getCampus();
    this.rolesAcademicosData$ = this.catalogosService.getRolesAcademicos();
  }

  ngOnInit(): void {

    this.formModal = new window.bootstrap.Modal(
      document.getElementById("idModalCorreoExistente")
    );

    this.initializeForm();

    this.clavePerfil = "academico";
  }

  initializeForm() {
    const strRegExEmail = environment.regexEmailAccepted || "";

    this.contactForm = this.fb.group({
      nombreUsuario: [null, Validators.required],
      apellidosUsuario: [null, Validators.required],
      nomina: [null, [Validators.required, Validators.pattern(/^L0(\d){7}$/)]],
      idCampus: [null, Validators.required],
      idEscuela: [null, Validators.required],
      puesto: [null],
      departamento: [null],
      idRolAcademico: [null, Validators.required],
      idEscuelaDepartamento: [null, Validators.required],
    });

    this.frmEmail = this.fb.group({
      usuario: [
        "",
        [
          Validators.required,
          Validators.email,
          Validators.pattern(strRegExEmail),
        ],
      ],
    });

    this.frmCreaPwd = this.fb.group({
      clave1Usuario:[null,[Validators.required, Validators.minLength(8)]],
      clave2Usuario:[null,[Validators.required, Validators.minLength(8)]],
      terminos_condiciones:[null, [Validators.requiredTrue]]
    }, {validator:passwordMatch("clave1Usuario", "clave2Usuario")});

    this.frmCreaNvoPwd = this.fb.group({
      clave1Usuario:[null,[Validators.required, Validators.minLength(8)]],
      clave2Usuario:[null,[Validators.required, Validators.minLength(8)]]
    }, {validator:passwordMatch("clave1Usuario", "clave2Usuario")});
  }

  updateContact() {
    this.frmCreaPwd.markAllAsTouched();
    try {
      if (this.frmCreaPwd.valid) {
        this.usuariosService
          .postUsuarioAutoRegistro({
            ...this.contactForm.value,
            clavePerfil: "academia",
            claveRoles: ["profesor"],
            usuario: this.frmEmail.get("usuario")?.value,
            urlFotoUsuario: this.imgUrl,
          })
          .subscribe({
            next: (resultado: any) => {
              if (resultado) {
                // Cuando todo esta OK, se Auto-autoriza
                let clave = this.frmCreaPwd.get('clave1Usuario')?.value;
                this._usuarioService
                  .putUsuarioActivacion(resultado.idUsuario, "activo", clave)
                  .subscribe({
                    next: (resultado: any) => {
                      if (resultado) {
                        this.ocultarTodos();
                        this.vConfirma = true;
                      } else {
                        this.toastr.error("Algo Salió mal!");
                      }
                    },
                    error: (err) => {
                      this.toastr.error("Por favor intenta más tarde.");
                      console.log(err);
                    },
                  });
              } else {
                this.toastr.error("Algo Salio mal!");
              }
            },
            error: (error) => {
              this.mensajeModal = error.message;
              Swal.fire({
                icon: "error",
                text: this.mensajeModal,
                title: "Error",
              });
            },
          });
        return;
      } else {
        this.toastr.error("Se deben rellenar los campos requeridos!");
      }
    } catch (err) {
      console.log(err);
      this.formModal.show();
    }
  }


  regresar() {

    window.location.href = "/";
  }

  async obtenerDepartamentos($event) {
    this.escuelaDepartamentosData = [];
    let idEscuela = $event.idEscuela;
    this.escuelaDepartamentosData = await lastValueFrom(
      this.catalogosService.getDepartamentosPorEscuela(idEscuela)
    );
  }

  ocultarTodos() {
    this.vDatosDocente = false;
    this.vRecuperaPwd = false;
    this.vEnviaCodigo = false;
    this.vCreaNvoPwd = false;
    this.vRegistroDocente = false;
    this.vCreaPwd = false;
    this.vConfirma = false;
    this.vConfirmaPwd = false;
  }

  async validaEmail() {
    this.frmEmail.markAllAsTouched();
    if (!this.frmEmail.get("usuario")?.valid) {
      this.toastr.error("Escribe un correo válido");
      return;
    }
    let existe = await lastValueFrom(
      this.usuariosService.searchEmail(
        this.frmEmail.get("usuario")?.value || ""
      )
    );
    if (existe) {
      this.existeEmail = true;
    } else {
      this.ocultarTodos();
      this.vRegistroDocente = true;
    }
  }

  aceptar() {
    window.location.href = "/validasesion";
  }

  contactPwd() {
    this.contactForm.markAllAsTouched();
    try {
      if (this.contactForm.valid) {
        this.ocultarTodos();
        this.vCreaPwd = true;
      }
    } catch (err) {
      console.log(err);
      this.formModal.show();
    }
  }

  recuperaContrasena(){
    this.ocultarTodos();
    this.vRecuperaPwd =true;

    let email = this.frmEmail.get('usuario')?.value;
    this.email_usuario_recupera.patchValue(email);

  }

  regresarInicio(){
    this.ocultarTodos();
    this.vDatosDocente = true;
    this.existeEmail=false;
    this.frmEmail.get('usuario')?.patchValue("");
  }

  enviarCodigo(){
    this.email_usuario_enviac.patchValue(this.email_usuario_recupera.value);
    this.email_usuario_enviac.disable();
    this.email_usuario_enviac.markAsTouched();

    if(this.email_usuario_recupera.valid){

      let email_docente =  this.email_usuario_recupera.value;
      this._usuarioService.enviarCodigoVerificacion({email: email_docente}).
      subscribe({
        next:(resOK)=>{
            this.ocultarTodos();
            this.vEnviaCodigo =true;
        }
        ,error:(error)=>{
          console.log(error);
          Swal.fire({
            text: error.message
          });
        }
      });

    }
  }



  validaCodigo(){

    this.email_usuario_enviac.disable();
    this.txt_codigo.markAsTouched();
    if(this.txt_codigo.valid){

      let request = {
        email: this.email_usuario_recupera.value,
        claveVerificacion: this.txt_codigo.value
      };
      this._usuarioService.validaCodigo(request).subscribe({
        next:(resOK)=>{
          this.ocultarTodos();
          this.vCreaNvoPwd=true;
        },
        error:(error)=>{
          console.log(error);
          Swal.fire({
            text: error.message
          });
        }
      });


    }
  }



  cambiaClave(){
    this.frmCreaNvoPwd.markAllAsTouched();
    if(this.frmCreaNvoPwd.valid){

      let request = {
        email: this.frmEmail.get('usuario')?.value,
        contrasena: this.frmCreaNvoPwd.get('clave1Usuario')?.value
      };
      this._usuarioService.cambioClave(request)
      .subscribe({
        next:(resOK)=>{
          this.ocultarTodos();
          this.vConfirmaPwd = true;
        }
        ,error:(error)=>{
          console.log(error);
          Swal.fire({
            text: error.message
          })
        }
      });



    }

  }


}
