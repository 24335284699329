import { HttpClient } from '@angular/common/http';
import type { ISolicitudes } from '@shared/Interface/ISolicitudes.interface';
import { Injectable } from '@angular/core';
import { ISolicitudGetResponse } from '@shared/Interface/ISolicitudGetResponse';
import { ISolicitudRequest } from '@shared/Interface/ISolicitudRequest';
import { ISolicitudResponse } from '@shared/Interface/ISolicitudResponse';
import { environment } from 'environments/environment';
import { map, Observable } from 'rxjs';

import { moduleService } from '../routesServices';
import { ISociosSolicitudComentarios, ISolicitudComentarioRequest, ISolicitudComentarios, ISolicitudSocioComentarios } from '@shared/Interface/ISolicitudSocioComentarios';

const { SOLICITUDES } = moduleService;


@Injectable({
  providedIn: 'root'
})
export class SolicitudesService {

  baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) {
   }

  /**
   * Api que actualiza una solicitud
   * @param request objeto tipo ISolicitudRequest
   * @returns objeto tipo ISolicitudResponse
   */
  putSolicitud(request: ISolicitudRequest):Observable<ISolicitudResponse>{
    return this.http.put<ISolicitudResponse>(this.baseUrl + "solicitudes", request);
  }
  
  postSolicitud(body: any): Observable<ISolicitudes> {
    return this.http.post<ISolicitudes>(`${this.baseUrl}${SOLICITUDES}`, body);
  }

  /**
   * Api que obtiene la información de una solicitud
   * @param idSolicitud tipo de dato number que identifica a la solicitud
   * @returns objeto tipo ISolicitudGetResponse
   */
  getSolicitudById(idSolicitud: number):Observable<ISolicitudGetResponse> {
    return this.http.get<ISolicitudGetResponse>(this.baseUrl + "solicitudes/"+idSolicitud)
    .pipe(map(response =>{
        response.descripcionSolicitud = response.descripcionSolicitud ?? "";

      return response;
    }));
  }

putSolicitudEstatus(request: any):Observable<ISolicitudResponse>{
    return this.http.put<ISolicitudResponse>(this.baseUrl + "solicitudes/estatus", request);
  }


  // postRetoDesdeRetoMaestro(request:any):Observable<ISolicitudResponse>{
  //   return this.http.post<ISolicitudResponse>(`${this.baseUrl}solicitudes`, request);
  // }

  postSolicitudSFP_comentarios(request: ISolicitudSocioComentarios){
    return this.http.post(`${this.getUrlBase}socios/solicitudes/comentarios`, request);
  }

  getsolicitudSFP_comentarios(idSocioFormador: number){
    return this.http.get<ISociosSolicitudComentarios[]>(`${this.getUrlBase}socios/${idSocioFormador}/solicitudes/comentarios`);
  }

  postPropuestaSF(request: any){
    return this.http.post(`${this.getUrlBase}solicitudes/propuestas/socios`, request);
  }
  getPropuestaSF_comentarios(idSolicitud:number){
    return this.http.get<ISociosSolicitudComentarios[]>(`${this.getUrlBase}solicitudes/${idSolicitud}/propuestas/socios`);
  }

  get getUrlBase(){
    return environment.apiUrl; 
    // if(environment.name == 'local'){
    //   return 'http://localhost:3000/v1/';
    // }else{
    //   return environment.apiUrl;
    // }
  }

  postGeneraCartaPDF(request: any){
    return this.http.post(`${this.baseUrl}administracion/cartas/generaPDF`, request);
  }

  postSolicitudComentario(request: ISolicitudComentarioRequest){
    return this.http.post(`${this.baseUrl}solicitudes/comentarios`, request);  
  }

  getSolicitudComentarios(idSolicitud: number){ 
    return this.http.get<ISolicitudComentarios[]>(`${this.baseUrl}solicitudes/${idSolicitud}/comentarios`);  
  }

}
