<div class="d-flex flex-row-reverse align-items-center">
    <div id="ms_menu" (click)="collapseToggle()" class="p-2"><img alt="Menu" src="{{ imgMenuFlecha }}"></div>
    <div class="p-2">
        <a id="pic"><img alt="Perfil" class="img_avt_mn" src="{{ imagenPerfil }}"></a>
    </div>
    <div class="p-lg-5 p-3">
        <a class="position-relative" routerLink='/notificaciones' [queryParams]="{socio:this.idSocioFormador}">
            <img alt="Mensaje" src="assets/img/union.svg">
            <span
                class="position-absolute top-0 start-100 translate-middle badge border border-light rounded-pill bg-notif p-2">
                <div>{{ NumeroMensajes }}</div>
                <span class="visually-hidden">unread messages</span>
            </span>
        </a>
    </div>
</div>
<div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
    <div id="perfil" class="position-relative text-start">
        <div id="perfil0" class="position-absolute top-0 end-0">
            <span>{{ nombreUsuario }}</span>
            <div id="menu_perfil">
                <a class="dropdown-item active" (click)="ir('miperfil')">{{ "header.mi_perfil" | translate}}</a>
                <a class="dropdown-item" (click)="ir('miscontactos')"
                    *ngIf="clavePerfil == 'socio' && contactoPrincipal">{{
                    "header.mis_contactos" | translate }}</a>
                <a class="dropdown-item" (click)="ir('datosempresa')"
                    *ngIf="clavePerfil == 'socio'  && contactoPrincipal">{{
                    "header.datos_empresa" | translate }}</a>
                <a class="dropdown-item" (click)="ir('convenios')"
                    *ngIf="clavePerfil == 'socio'  && contactoPrincipal">{{
                    "header.convenio" | translate }}</a>
                <a class="dropdown-item" (click)="logout()">{{ "header.cerrar_sesion" | translate }}</a>
            </div>
        </div>
    </div>
</div>