import { Component, OnInit } from '@angular/core';
import { ISociosNotificacionesResponse } from '@shared/Interface/ISociosNotificacionesResponse';
import { SociosFormadoresService } from '@shared/service/Socios_Formadores/socios-formadores.service';
import { UsuariosService } from '@shared/service/Usuarios/usuarios.service';
import { lastValueFrom } from 'rxjs';
import * as moment from 'moment';
import 'moment/locale/es'; // without this line it didn't work
moment.locale('es');

@Component({
  selector: 'app-notificaciones',
  templateUrl: './notificaciones.component.html',
  styleUrls: ['./notificaciones.component.css'],
})
export class NotificacionesComponent implements OnInit {
  lang: string = 'es';
  idSocio: number;
  idUsuario: number;
  notifications!: ISociosNotificacionesResponse[];
  clavePerfil: string;
  idCampus: number;

  constructor(
    private sociosFormadoresService: SociosFormadoresService,
    private usuarioService: UsuariosService
  ) {}

  async ngOnInit(): Promise<void> {
    this.lang = localStorage.getItem('lang') ?? 'es';
    this.idSocio = this.usuarioService.getCurrentUserData().idSocioFormador;
    this.idUsuario = this.usuarioService.getCurrentUserData().idUsuario;
    this.clavePerfil = this.usuarioService.getCurrentUserData().clavePerfil;
    this.idCampus = this.usuarioService.getCurrentUserData().idCampus;

    if (this.clavePerfil == 'socio') {
      await this.getNotificaciones();
    }
    if (this.clavePerfil == 'academia') {
      await this.getNotificacionesUsuarioAcademia();
    }
    if (this.clavePerfil == 'cvdp' || this.clavePerfil == 'cvdp_admin') {
      await this.getNotificacionesUsuarioCVDP();
    }
    let miDate = new Date();
      let hoy = miDate.getFullYear()+''+miDate.getMonth()+''+miDate.getDay();
      localStorage.setItem('NotiFec',hoy);
  }

  changeLang(lang: string) {
    this.lang = lang;
    localStorage.setItem('lang', lang);
    window.location.reload();
  }

  async getNotificacionesUsuarioAcademia() {
    try {
      let resultado = <any>(
        await lastValueFrom(this.usuarioService
          .getNotificacionesByIdAcacemia(this.idUsuario))
      );
      if (
        resultado !== undefined &&
        resultado !== null &&
        resultado.length > 0
      ) {
        const groups = resultado.slice().reduce((groups: any, data: any) => {
          const date = data.fechaSolicitud.substring(0,10);
          if (!groups[date]) {
            groups[date] = [];
          }
          groups[date].push(data);
          return groups;
        }, {});

        const groupArrays = Object.keys(groups).map((date) => {
          return {
            date: date,
            messages: groups[date],
          };
        });
        this.notifications = groupArrays;
      }
    } catch (error) {
      console.error(
        'Error al obtener las notificaciones por id del Socio Formador ',
        error
      );
    }
  }
  async getNotificacionesUsuarioCVDP() {
    try {
      let resultado = <any>(
        await lastValueFrom(this.usuarioService
          .getNotificacionesByIdCVDP(this.idCampus))
      );
      if (
        resultado !== undefined &&
        resultado !== null &&
        resultado.length > 0
      ) {
        const groups = resultado.slice().reduce((groups: any, data: any) => {
          const date = data.fechaSolicitud.substring(0,10);
          if (!groups[date]) {
            groups[date] = [];
          }
          groups[date].push(data);
          return groups;
        }, {});

        const groupArrays = Object.keys(groups).map((date) => {
          return {
            date: date,
            messages: groups[date],
          };
        });
        this.notifications = groupArrays;
      }
    } catch (error) {
      console.error(
        'Error al obtener las notificaciones por id del Socio Formador ',
        error
      );
    }
  }

  async getNotificaciones() {
    try {
      let resultado = <any>(
        await lastValueFrom(this.sociosFormadoresService
          .getNotificacionesByIdSocio(this.idSocio))
      );
      if (
        resultado !== undefined &&
        resultado !== null &&
        resultado.length > 0
      ) {
        const groups = resultado.slice().reduce((groups: any, data: any) => {
          const date = data.fechaSolicitud.substring(0,10);
          if (!groups[date]) {
            groups[date] = [];
          }
          groups[date].push(data);
          return groups;
        }, {});

        const groupArrays = Object.keys(groups).map((date) => {
          return {
            date: date,
            messages: groups[date],
          };
        });
        this.notifications = groupArrays;
      }
    } catch (error) {
      console.error(
        'Error al obtener las notificaciones por id del Socio Formador ',
        error
      );
    }
  }

  formatDate(date: string) {
    let textDate = moment(date,"YYYY-MM-DD").calendar({
      lastDay: '[Ayer]',
      sameDay: '[Hoy]',
      nextDay: '[Mañana]',
      nextWeek: 'dddd DD/MMMM/YY',
      lastWeek: 'dddd DD/MMMM/YY',
      sameElse: 'dddd DD/MMMM/YY',
    });

    return textDate.charAt(0).toUpperCase() + textDate.slice(1);
  }

  formatTime(date: string) {
    return moment(date,'YYYY-MM-DD hh:mm:ss').format('LT');
  }
}
