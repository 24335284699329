<div class="sec_ad">

  <!-- Mis busquedas de Socios Formadores -->
  <app-title-show-more [showMoreObject]="retosSociosFormadores"></app-title-show-more>

  <div class="d-none d-md-block flex-fill">
    <div class="d-flex justify-content-end">
      <app-button [routerLink]="['/vincula-socios-formadores']">
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none"
          class="iCn replaced-svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M0.5 6.45834V2.125C0.5 1.69402 0.671205 1.2807 0.975952 0.975952C1.2807 0.671205 1.69402 0.5 2.125 0.5H6.45834V6.45834H0.5ZM1.58333 5.375H5.375V1.58333H2.125C1.98134 1.58333 1.84357 1.6404 1.74198 1.74198C1.6404 1.84357 1.58333 1.98134 1.58333 2.125V5.375ZM0.975952 13.0241C0.671205 12.7193 0.5 12.306 0.5 11.875V7.54167H6.45834V13.5H2.125C1.69402 13.5 1.2807 13.3288 0.975952 13.0241ZM5.375 8.625H1.58333V11.875C1.58333 12.0187 1.6404 12.1564 1.74198 12.258C1.84357 12.3596 1.98134 12.4167 2.125 12.4167H5.375V8.625ZM7.54167 7.54167V13.5H11.875C12.306 13.5 12.7193 13.3288 13.0241 13.0241C13.3288 12.7193 13.5 12.306 13.5 11.875V7.54167H7.54167ZM12.4167 11.875C12.4167 12.0187 12.3596 12.1564 12.258 12.258C12.1564 12.3596 12.0187 12.4167 11.875 12.4167H8.625V8.625H12.4167V11.875ZM10.7917 6.45834H9.70833V4.29167H7.54167V3.20834H9.70833V1.04167H10.7917V3.20834H12.9583V4.29167H10.7917V6.45834Z"
            fill="#0C2B3E"></path>
        </svg>
        &nbsp;Buscar un Socio Formador
      </app-button>
    </div>
  </div>

  <app-table [backgroundColor]="backgroundTable" [columns]="retosSocioColumns" [data]="retosSocioData">
  </app-table>

  <!-- Mis retos -->
  <app-title-show-more [showMoreObject]="titleRetos"></app-title-show-more>

  <div id="sec_tab" *featureFlag="'F2_4_HU58'" class="nav nav-pills d-flex flex-md-nowrap flex-wrap" role="presentation">

    <div class="active" data-bs-toggle="pill" data-bs-target="#retos-activos" aria-controls="retos-activos" prm="re_ac"
      role="tab" type="button" aria-selected="true">
      Mis Retos activos
    </div>
    <div prm="re_hs" data-bs-toggle="pill" data-bs-target="#historial-retos" aria-controls="historial-retos" class=""
      role="tab" type="button" aria-selected="false">
      Mi Historial de Retos
    </div>

  </div>

  <div class="tab-content" id="pills-tabContent" *featureFlag="'F2_4_HU58'">

    <div class="tab-pane fade show active" id="retos-activos" role="tabpanel" aria-labelledby="retos-activos-tab">
      <app-table [backgroundColor]="backgroundTable" [columns]="retosColumns" [data]="retosData"></app-table>
    </div>
    <div class="tab-pane fade" id="historial-retos" role="tabpanel" aria-labelledby="historial-retos-tab">
      <app-table [backgroundColor]="backgroundTable" [columns]="retosColumns" [data]="retosAllData"></app-table>
    </div>

  </div>

  <!-- Estatus -->
  <div class="mt-2 pt-0 mb-0 pb-0" *featureFlag="'F2_5_HU58'">
    <ng-template matStepLabel>En formalización</ng-template>
    <div class="mt-5">
        <div class="nav nav-tabs d-inline" id="accordionExample">
            <div class="steps">

                <div class="step-item active" data-bs-toggle="tab" data-bs-target="#tab-formalizacion"
                    (click)="changeViewStatus('formalizacion')">
                    <progress [ngClass]="status != 'formalizacion'  ? 'progress-active' : 'progress-inactive'"
                        id="progress-active" [value]="status != 'formalizacion'  ? 20 : 0" max=20></progress>
                    <div class="titlestepActive">
                        En formalización
                    </div>
                    <button class="step-button-active text-center">
                        1
                    </button>

                </div>
                <div class="step-item" data-bs-toggle="tab" data-bs-target="#tab-curso"
                    (click)="changeViewStatus('curso')">
                    <progress
                        [ngClass]="status == 'evaluacion' || status == 'concluido' ? 'progress-active' : 'progress-inactive'"
                        id="progress-active" [value]="status == 'evaluacion' || status == 'concluido'  ? 20 : 0"
                        max=20></progress>
                    <div [ngClass]="status != 'formalizacion'  ? 'titlestepActive' : 'titlestepInactive'">
                        En curso
                    </div>
                    <button [ngClass]="status != 'formalizacion'  ? 'step-button-active' : 'step-button'"
                        class="text-center">
                        2
                    </button>

                </div>

                <div class="step-item" data-bs-toggle="tab" data-bs-target="#tab-evaluacion"
                    (click)="changeViewStatus('evaluacion')">
                    <progress [ngClass]="status == 'concluido' ? 'progress-active' : 'progress-inactive'"
                        id="progress-active" [value]="status == 'concluido'  ? 20 : 0" max=20></progress>
                    <div
                        [ngClass]="status == 'evaluacion' || status == 'concluido' ? 'titlestepActive' : 'titlestepInactive'">
                        En evaluación
                    </div>
                    <button
                        [ngClass]="status == 'evaluacion' || status == 'concluido'  ? 'step-button-active' : 'step-button'"
                        class="text-center">
                        3
                    </button>

                </div>
                <div class="step-item" data-bs-toggle="tab" data-bs-target="#tab-concluido"
                    (click)="changeViewStatus('concluido')">
                    <div [ngClass]="status == 'concluido'  ? 'titlestepActive' : 'titlestepInactive'">
                        Concluidos
                    </div>
                    <button [ngClass]="status == 'concluido'  ? 'step-button-active' : 'step-button'"
                        class="text-center">
                        4
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="tab-content" id="pills-tabContent" *featureFlag="'F2_5_HU58'">
    <div class="tab-pane fade show active" id="tab-formalizacion" role="tabpanel"
        aria-labelledby="tab-reto-activos-tab">
        <app-table [backgroundColor]="backgroundTable" [columns]="listadoRetosFormalizacionColumns"
            [data]="listadoRetosFormalizacionData"></app-table>
    </div>
    <div class="tab-pane fade" id="tab-curso" role="tabpanel" aria-labelledby="tab-reto-historial-tab">
        <app-table [backgroundColor]="backgroundTable" [columns]="listadoRetosCursoColumns"
            [data]="listadoRetosCursoData"></app-table>
    </div>
    <div class="tab-pane fade" id="tab-evaluacion" role="tabpanel" aria-labelledby="tab-reto-historial-tab">
        <app-table [backgroundColor]="backgroundTable" [columns]="listadoRetosEvaluacionColumns"
            [data]="listadoRetosEvaluacionData"></app-table>
    </div>
    <div class="tab-pane fade" id="tab-concluido" role="tabpanel" aria-labelledby="tab-reto-historial-tab">
        <app-table [backgroundColor]="backgroundTable" [columns]="listdaoRetosConcluidoColumns"
            [data]="listadoRetosConcluidoData"></app-table>
    </div>

</div>



  <!-- Socios Formadores que se quieren vincular a retos -->
  <app-title-show-more [showMoreObject]="titleSociosFormadores"></app-title-show-more>

  <div id="sec_tab" class="nav nav-pills d-flex flex-md-nowrap flex-wrap" role="presentation">

    <div class="active" data-bs-toggle="pill" data-bs-target="#tab-socios-formadores-aprobar"
      aria-controls="tab-socios-formadores-aprobar" prm="re_ac" role="tab" type="button" aria-selected="true">
      Solicitudes por aprobar
    </div>
    <div prm="re_hs" data-bs-toggle="pill" data-bs-target="#tab-socios-formadores-aceptadas"
      aria-controls="tab-socios-formadores-aceptadas" class="" role="tab" type="button" aria-selected="false">
      Solicitudes aceptadas
    </div>
    <div prm="re_hs" data-bs-toggle="pill" data-bs-target="#tab-socios-formadores-rechazadas"
      aria-controls="tab-socios-formadores-rechazadas" class="" role="tab" type="button" aria-selected="false">
      Solicitudes rechazadas
    </div>

  </div>

  <div class="tab-content" id="pills-tabContent">

    <div class="tab-pane fade show active" id="tab-socios-formadores-aprobar" role="tabpanel"
      aria-labelledby="tab-socios-formadores-aprobar-tab">
      <app-table [backgroundColor]="backgroundTable" [columns]="sociosFormadoresColumns"
        [data]="sociosFormadoresData"></app-table>
    </div>
    <div class="tab-pane fade" id="tab-socios-formadores-aceptadas" role="tabpanel"
      aria-labelledby="tab-socios-formadores-aceptadas-tab">
      <app-table [backgroundColor]="backgroundTable" [columns]="sociosFormadoresColumns"
        [data]="sociosFormadoresAceptadasData"></app-table>
    </div>
    <div class="tab-pane fade" id="tab-socios-formadores-rechazadas" role="tabpanel"
      aria-labelledby="tab-socios-formadores-rechazadas-tab">
      <app-table [backgroundColor]="backgroundTable" [columns]="sociosFormadoresColumns"
        [data]="sociosFormadoresRechazadoaData"></app-table>
    </div>

  </div>


</div>