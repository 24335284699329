import { Component } from '@angular/core';

@Component({
  selector: 'app-vincula-retos-busqueda',
  templateUrl: './vincula-retos-busqueda.component.html',
  styleUrls: ['./vincula-retos-busqueda.component.css'],
})
export class VinculaRetosBusquedaComponent  {
  retosData = [
    {
      reto: 'Análisis de financiamiento',
      carrera: 'Cultura Financiera',
      img: 'img_reto_fullsize',
      match: 90,
      chipData: [
        {
          title: 'Lic. en Finanzas',
          icon: 'icon_chip_carrera',
        },
        {
          title: '4to. Semestre',
          icon: 'icon_chip_semestre',
        },
      ],
    },
    {
      reto: 'Análisis de financiamiento2',
      carrera: 'Cultura Financiera2',
      img: 'img_reto_fullsize',
      match: 80,
      chipData: [
        {
          title: 'Marketing',
          icon: 'icon_chip_carrera',
        },
        {
          title: '4to. Semestre',
          icon: 'icon_chip_semestre',
        },
      ],
    },
    {
      reto: 'Análisis de financiamiento3',
      carrera: 'Cultura Financiera2',
      img: 'img_reto_fullsize',
      match: 80,
      chipData: [
        {
          title: 'Marketing',
          icon: 'icon_chip_carrera',
        },
        {
          title: '4to. Semestre',
          icon: 'icon_chip_semestre',
        },
      ],
    },
    {
      reto: 'Análisis de financiamiento4',
      carrera: 'Cultura Financiera2',
      img: 'img_reto_fullsize',
      match: 80,
      chipData: [
        {
          title: 'Marketing',
          icon: 'icon_chip_carrera',
        },
        {
          title: '4to. Semestre',
          icon: 'icon_chip_semestre',
        },
      ],
    },
  ];
  
}
