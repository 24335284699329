<app-header-buscador [showSearchBar]="false"></app-header-buscador>

<div class="sec_ad">

  <app-btn-regresar></app-btn-regresar>
  <app-title-show-more [showMoreObject]="title"></app-title-show-more>

  <div id="sec_tab" class="nav nav-pills d-flex flex-md-nowrap flex-wrap" role="presentation">
    <div class="active" data-bs-toggle="pill" data-bs-target="#tab-socios-formadores-enviadas"
      aria-controls="tab-socios-formadores-enviadas" prm="re_ac" role="tab" type="button" aria-selected="true">
      Solicitudes Enviadas
    </div>
    <div class="" data-bs-toggle="pill" data-bs-target="#tab-socios-formadores-abiertas"
      aria-controls="tab-socios-formadores-abiertas" prm="re_ac" role="tab" type="button" aria-selected="true"
      [innerHTML]="'vinculaciones.solicitudes_abiertas' | translate ">

    </div>
    <div prm="re_hs" data-bs-toggle="pill" data-bs-target="#tab-socios-formadores-rechazadas"
      aria-controls="tab-socios-formadores-rechazadas" class="" role="tab" type="button" aria-selected="false"
      [innerHTML]="'vinculaciones.solicitudes_rechazadas' | translate">

    </div>

    <div prm="re_hs" data-bs-toggle="pill" data-bs-target="#tab-socios-formadores-aprobadas"
      aria-controls="tab-socios-formadores-aprobadas" class="" role="tab" type="button" aria-selected="false"
      [innerHTML]="'vinculaciones.solicitudes_aprobadas' | translate">

    </div>

    <div class="d-none d-md-block flex-fill">
      <div class="d-flex justify-content-end">
        <app-button [routerLink]="['/vinculacion-reto']">
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none"
            class="iCn replaced-svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M0.5 6.45834V2.125C0.5 1.69402 0.671205 1.2807 0.975952 0.975952C1.2807 0.671205 1.69402 0.5 2.125 0.5H6.45834V6.45834H0.5ZM1.58333 5.375H5.375V1.58333H2.125C1.98134 1.58333 1.84357 1.6404 1.74198 1.74198C1.6404 1.84357 1.58333 1.98134 1.58333 2.125V5.375ZM0.975952 13.0241C0.671205 12.7193 0.5 12.306 0.5 11.875V7.54167H6.45834V13.5H2.125C1.69402 13.5 1.2807 13.3288 0.975952 13.0241ZM5.375 8.625H1.58333V11.875C1.58333 12.0187 1.6404 12.1564 1.74198 12.258C1.84357 12.3596 1.98134 12.4167 2.125 12.4167H5.375V8.625ZM7.54167 7.54167V13.5H11.875C12.306 13.5 12.7193 13.3288 13.0241 13.0241C13.3288 12.7193 13.5 12.306 13.5 11.875V7.54167H7.54167ZM12.4167 11.875C12.4167 12.0187 12.3596 12.1564 12.258 12.258C12.1564 12.3596 12.0187 12.4167 11.875 12.4167H8.625V8.625H12.4167V11.875ZM10.7917 6.45834H9.70833V4.29167H7.54167V3.20834H9.70833V1.04167H10.7917V3.20834H12.9583V4.29167H10.7917V6.45834Z"
              fill="#0C2B3E"></path>
          </svg>
          &nbsp;{{ 'vinculaciones.nueva_vinculacion' | translate }}
        </app-button>
      </div>
    </div>

  </div>

  <div class="tab-content" id="pills-tabContent">
    <div class="tab-pane fade show active" id="tab-socios-formadores-enviadas" role="tabpanel"
      aria-labelledby="tab-socios-formadores-aprobar-tab">
      <app-solicitudes-abiertas [estatusType]="'registrado'"></app-solicitudes-abiertas>
    </div>
    <div class="tab-pane fade" id="tab-socios-formadores-abiertas" role="tabpanel"
      aria-labelledby="tab-socios-formadores-aprobar-tab">
      <app-solicitudes-abiertas [estatusType]="'revision'"></app-solicitudes-abiertas>
    </div>
    <div class="tab-pane fade" id="tab-socios-formadores-rechazadas" role="tabpanel"
      aria-labelledby="tab-socios-formadores-rechazadas-tab">
      <app-solicitudes-abiertas [estatusType]="'rechazado'"></app-solicitudes-abiertas>
    </div>
    <div class="tab-pane fade" id="tab-socios-formadores-aprobadas" role="tabpanel"
      aria-labelledby="tab-socios-formadores-rechazadas-tab">
      <app-solicitudes-abiertas [estatusType]="'aprobado'"></app-solicitudes-abiertas>
    </div>
  </div>

</div>
