import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Paginador } from '@shared/Interface/IPaginador.interface';
import { IRetosAcademicosResponse } from '@shared/Interface/IRetosAcademicosResponse';
import { PaginadorLibsComponent } from '@shared/libs/paginador.component';
import { DashboardService } from '@shared/service/Dashboard/dashboard.service';
import { UsuariosService } from '@shared/service/Usuarios/usuarios.service';
import { environment } from 'environments/environment';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-retos-activos-ac',
  templateUrl: './retos-activos.component.html',
  styleUrls: ['./retos-activos.component.css']
})
export class MisRetosACActivosComponent implements OnInit {

  lang: string = 'es';
  paginaActual: number = 1;
  idAcademico: number = 1;
  retosAcadList: IRetosAcademicosResponse[] = [];
  retosActualesAcadList: IRetosAcademicosResponse[] = [];
  retosActualesMovilAcadList: IRetosAcademicosResponse[] = [];
  @Input()  statusType:string ;
  registrosPagina: number = environment.RegistrosPagina;
  paginaActualMasResultados: number = 1;
  totalPaginas:number = 0;
  pages: number[] = [];

  constructor(
      private dashboardService: DashboardService,
      private paginadorLibs: PaginadorLibsComponent,
      private usuarioService: UsuariosService,
      private router : Router
      ) { }

  async ngOnInit(): Promise<void> {
    this.idAcademico = this.usuarioService.getCurrentUserData().idUsuario;
    this.lang = localStorage.getItem('lang') ?? 'es';
    await this.getListaRetosActivos();
    this.getPaginacion();
    this.obtenerRegistrosActuales(1);
  }

  changeLang(lang: string) {
    this.lang = lang;

    localStorage.setItem('lang', lang);
    window.location.reload();
  }

  async getListaRetosActivos(){
    try {
      let type= this.statusType ?? 'activo'
      let idCampus = 0;
      let idEscuela = 0;
      let idPeriodo = 0;
      let pagina = 1;
      let hits = this.registrosPagina;

      let resultado = <any>await lastValueFrom(this.dashboardService.getRetosByAcademicoAndEstatus(this.idAcademico, type, idCampus, idEscuela, idPeriodo, pagina, hits));
      if (resultado !== undefined && resultado !== null && resultado.length > 0) {
        this.retosAcadList = resultado;
      }
    } catch (error) {
      console.error("Error al obtener los retos de Socios Formadores por ");
    }
  }

  getPaginacion(){
    let paginador: Paginador = this.paginadorLibs.getPaginacion(this.retosAcadList.length, this.registrosPagina);
    this.totalPaginas = paginador.totalPaginas;
    this.pages = paginador.paginas;
  }

  obtenerRegistrosActuales(numPagina:number) {
    this.retosActualesAcadList = this.paginadorLibs.obtenerRegistrosActuales(numPagina, this.registrosPagina, this.retosAcadList);
    this.retosActualesMovilAcadList = this.retosActualesAcadList;
    this.paginaActual = numPagina;
    this.paginaActualMasResultados = numPagina;
  }


  obtenerMasResultados(){
    this.paginaActualMasResultados += 1;
    this.retosActualesMovilAcadList = this.paginadorLibs.obtenerMasResultados(this.paginaActualMasResultados, this.registrosPagina, this.retosAcadList);
  }

  VerReto(idReto){
    window.location.href=`/programacion-reto?id=${idReto}`;
  }
}
