import { Component, Input, OnInit } from '@angular/core';
import Utils from '@shared/helpers/utils';

@Component({
  selector: 'app-header-type',
  templateUrl: './header-type.component.html',
  styleUrls: ['./header-type.component.css'],
})
export class HeaderTypeComponent implements OnInit {
  @Input() title: string = '';
  @Input() icon: boolean = false;
  @Input() class: string = 'align-bottom';

  title_40: string;


  ngOnInit(): void {
    this.title_40 = Utils.cutLongTextByXChars(this.title,50);
   
  }
}
