<app-header-buscador [showSearchBar]="false"></app-header-buscador>
<div class="sec_ad">
    <div id="sec_tit">
      <div class="d-flex justify-content-between align-items-start">
        <span class="titleform_sm_sans">Resultados de “Finanzas”</span>
      </div>
    </div>
    <div id="sec_tab" class="d-flex flex-md-nowrap flex-wrap tabs-3">
      <div class="flex-fill">
        <div class="d-flex justify-content-end">
          <div class="btn-group order-by">
            <button
              class="btn dropdown-toggle"
              type="button"
              id="listado"
              data-bs-toggle="dropdown"
              data-bs-auto-close="true"
              aria-expanded="false"
              data-bs-offset="0,0"
            >
              Ordenar por
            </button>
            <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="listado">
              <li><a class="dropdown-item" href="#">Nombre A-Z</a></li>
              <li><a class="dropdown-item" href="#">Programa</a></li>
              <li><a class="dropdown-item" href="#">Semestre</a></li>
              <li><a class="dropdown-item" href="#">Campus</a></li>
              <li><a class="dropdown-item" href="#">Región</a></li>
              <li class="visually-hidden">
                <a class="dropdown-item" href="#">Campus</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div *ngFor="let retos of retosData">
      <app-card-search [retosObj]="retos"></app-card-search>
    </div>

    <app-pagination></app-pagination>

    <app-not-search></app-not-search>

</div>
