<app-header-buscador [showSearchBar]="false"></app-header-buscador>

<app-btn-regresar></app-btn-regresar>
<div class="sec_ad">
  <div class="
      align-items-md-stretch
      flex-wrap flex-md-nowrap
      sec_cnt
    ">

    <!-- Sección para el contacto principal de un Socio Formador -->
    <div class=" col-md-12" *ngIf="this.userData.contactoPrincipal === true">

      <app-title-show-more [showMoreObject]="titlePerfil"></app-title-show-more>

      <div id="sec_tab" class="nav nav-pills d-flex flex-md-nowrap flex-wrap" role="presentation">

        <div prm="re_hs" id="miperfil" [ngClass]="{active: activeTab === 'miperfil'}" (click)="changetab('miperfil')"
          data-bs-toggle="pill" aria-controls="mis-datos" prm="re_ac" role="tab" type="button" aria-selected="true">
          Mis Datos
        </div>

        <div prm="re_hs" id="miscontactos" [ngClass]="{active: activeTab == 'miscontactos'}"
          (click)="changetab('miscontactos')" data-bs-toggle="pill" aria-controls="datos-contacto" class="" role="tab"
          type="button" aria-selected="false">
          Mis Contactos
        </div>

        <div prm="re_hs" id="datosempresa" [ngClass]="{active: activeTab === 'datosempresa'}"
          (click)="changetab('datosempresa')" data-bs-toggle="pill" aria-controls="datos-empresa" class="" role="tab"
          type="button" aria-selected="false">
          Datos de la Organización
        </div>

        <div prm="re_hs" id="convenios" [ngClass]="{active: activeTab === 'convenios'}" (click)="changetab('convenios')"
          data-bs-toggle="pill" aria-controls="datos-empresa-convenios" class="" role="tab" type="button"
          aria-selected="false">
          Mis documentos
        </div>

      </div>

      <div class="tab-content" id="pills-tabContent">
        <!-- Sección de Mi perfil -->
        <div id="mis-datos" class="tab-pane fade show " [ngClass]="{active: activeTab === 'miperfil'}" role="tabpanel"
          aria-labelledby="mis-datos-tab">

          <div class="col text-container-contacts" *ngIf="mensajeContactos.length>0">{{ mensajeContactos }}</div>

          <app-perfil-tab *ngIf="mainContactData" [mainContactObj]="mainContactData"></app-perfil-tab>

        </div>

        <!-- Seccion de datos de contacto-->
        <div id="datos-contacto" class="tab-pane fade show" [ngClass]="{active: activeTab == 'miscontactos'}"
          role="tabpanel" aria-labelledby="datos-contacto-tab">

          <div class="col text-container-contacts" *ngIf="!contactsData && mensajeContactos.length>0">{{
            mensajeContactos }}</div>

          <div *ngIf="contactsData">

            <app-contactos-tab (callbackNewContact)="newContact()" (callbackContacts)="editContactSelected($event)"
              (callbackDeletedContact)="showContacts($event)" [contactsData]="contactsData" [userData]="mainContactData"
              [idSocioFormador]="idSocio" *ngIf="!editContact"></app-contactos-tab>

          </div>

          <app-perfil-contact *ngIf="editContact" [mainContactObj]="editContactData" [newContact]="newContactCreate"
            (callbackShowContacts)="showContacts($event)"></app-perfil-contact>

        </div>

        <!-- Sección de datos de empresa -->
        <div id="datos-empresa" class="tab-pane fade show " [ngClass]="{active: activeTab === 'datosempresa'}"
          role="tabpanel" aria-labelledby="datos-empresa-tab">

          <app-datos-empresa-tab></app-datos-empresa-tab>

        </div>
        <!-- Sección de convenio -->
        <div id="datos-empresa-convenios" class="tab-pane fade show " [ngClass]="{active: activeTab === 'convenios'}"
          role="tabpanel" aria-labelledby="datos-empresa-tab">

          <div class="row" *ngIf="false">
            <div class="col-12">
              <button type="button" class="btn-amarillo" (click)="toogleDivCargaConvenio()"> Agregar un convenio <img
                  id="imgQuieroVincularlo" alt="Convenio" width="20px" src="{{ imgQuieroVincularlo }}"></button>
            </div>
          </div>

          <div class="row" *ngIf="mostrarDivSolVinculacion">
            <div class="col-3">&nbsp;</div>
            <div class="col-12">
              <form (ngSubmit)="onSubmit()" class="frm_registro_sf" [formGroup]="convenioForm">
                <span>El periodo del convenio no debe exceder 5 años.</span>
                <div class="col-6">
                  <label for="fechaInicio">Fecha Inicial:</label>
                </div>
                <div class="col-4">
                  <input class="form-control" (change)="validaFecha($event)" type="date" id="fechaInicio"
                    formControlName="fechaInicio">
                </div>


                <div class="col-4">
                  <label for="fechaFin">Fecha Final:</label>
                  <input class="form-control" type="date" (change)="validaFechaFin($event)" id="fechaFin"
                    formControlName="fechaFin">
                </div>
                <div class="col-6">
                  <label for="fechaInicio">Convenio:</label><br>

                  <button type="button" class="btn-amarillo" (click)="file.click()">Subir</button>
                  <br><br>
                  <small>Archivo PDF < a 3Mb</small>
                      <input class="d-none" type="file" id="fileConvenioUpload" (change)="selImagen($event)"
                        accept="application/pdf" #file />
                      <div>{{ fileNameSelected }}</div>

                </div>
                <br><br>

                <button type="button" class="btn-amarillo" (click)="guardarConvenio()">Guardar Convenio</button>
              </form>
            </div>
          </div>

          <div class="col-5">&nbsp;</div>

          <div class="row">

            <!-- cartas convenio y colaboracion -->
            <div class="row">
              <div class="col-md-12">

                <div>

                  <!-- Listado de Convenio -->
                  <span class="txtcard">

                    <label class="subtitlecard">{{ "pagina_socio_formador_detalles.carta_solicitud_convenio" | translate
                      }}</label>

                    <app-table [backgroundColor]="backgroundTable" [columns]="convenioColumns"
                      [data]="listCartaConvenio"></app-table>
                  </span>

                </div>

                <!-- Listado de Cartas Colaboracion -->
                <div>
                  <label class="subtitlecard">{{ "pagina_socio_formador_detalles.colaboracion" | translate
                    }}</label>

                  <span class="txtcard">

                    <app-table [backgroundColor]="backgroundTable" [columns]="colaboracionColumns"
                      [data]="listCartaColaboracion"></app-table>

                  </span>

                </div>


              </div>
            </div>

          </div>

        </div>


      </div>
    </div>

    <!-- Sección para el socio formador contacto -->
    <div *ngIf="this.userData.contactoPrincipal === false">
      <div class="offset-1 col-md-10 uno">
        <app-perfil-contact *ngIf="updateSocio" [mainContactObj]="mainContactData"
          [updateSocio]="updateSocio"></app-perfil-contact>
      </div>
    </div>


    <!-- Sección para editar perfiles que no son el contacto principal -->
    <div class="offset-1 col-md-10 mt-5" *ngIf="!updateSocio && this.userData.contactoPrincipal === false">

      <app-academico-perfil class="dos"
        *ngIf="!updateSocio && ['academia', 'cvdp_admin', 'cvdp'].includes(this.userData.clavePerfil)">
      </app-academico-perfil>

      <app-perfil-contact class="tres" *ngIf="updateSocio" [mainContactObj]="mainContactData"
        [updateSocio]="updateSocio"></app-perfil-contact>

    </div>

  </div>
</div>

<!-- Modal de la carta solicitud -->
<ng-template #content>
  <div class="modal-header">

    <button type="button" class="btn-close" aria-label="Close"
    (click)="closeConvenio()"
    ></button>
  </div>
  <div class="modal-body">
    <app-convenio-viewer (idSocioFormador)="0" [showFilePDF]="filePdfFirmado"
    ></app-convenio-viewer>

  </div>
  <div class="modal-footer">
    <button type="button" 
      class="btn btn-amarillo" 
      (click)="downloadPDF()">Descargar PDF</button>
  </div>


</ng-template>  
