import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ContactosService } from "@shared/service/Contactos/contactos.service";
import { IContacto } from "@shared/Interface/IContacto.interface";
import { lastValueFrom, tap } from "rxjs";
import { UsuariosService } from "@shared/service/Usuarios/usuarios.service";
import { InicioService } from "@shared/service/Inicio/inicio.service";
import Swal from "sweetalert2";
import { IUser } from "@shared/Interface/user";
import { SociosFormadoresService } from "@shared/service/Socios_Formadores/socios-formadores.service";
import { AzureBlobStorageService } from "@shared/service/AzureBlobStorage.Service";
import { environment } from "environments/environment";
import { ToastrService } from "ngx-toastr";
import {
  Column,
  optionsTextCol,
} from "@shared/components/table/table.component";
import { ICartaConvenio } from "@shared/Interface/ICartaConvenio";

import { saveAs } from "file-saver";
import { NgbModal, NgbModalConfig } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-registro-perfil",
  templateUrl: "./registro-perfil.component.html",
  styleUrls: ["./registro-perfil.component.css"],
  providers: [NgbModalConfig, NgbModal],
})
export class RegistroPerfilComponent implements OnInit {

  @ViewChild("content") content: ElementRef;

  content_modal;

  userData: any;
  idUser: any;
  titlePerfil = {
    title: "Mi Perfil",
  };

  // menuSel: string = "misperfil"
  activeTab: string = "miperfil";
  contactsData: any;
  mainContactData: any;
  editContact = false;
  newContactCreate = false;
  editContactData: any;
  updateSocio = false;
  idSocio: any;

  mensajeContactos: string = "";
  img: string = "";

  convenioForm!: UntypedFormGroup;
  userStore: IUser;

  listCartaConvenio: any;
  listCartaColaboracion: any;

  userType: string;

  backgroundTable = "#F6F6F6";

  constructor(
    private contactosService: ContactosService,
    private router: Router,
    private route: ActivatedRoute,
    private usuariosService: UsuariosService,
    private inicio: InicioService,
    private socioFormadorService: SociosFormadoresService,
    private readonly fb: UntypedFormBuilder,
    private azureBlobStorage: AzureBlobStorageService,
    private toastr: ToastrService,
    private config: NgbModalConfig,
    private modalService: NgbModal,
  ) {
    config.backdrop = "static";
    config.keyboard = false;
  }

  async ngOnInit() {
    this.idUser = this.route.snapshot.paramMap.get("idUsuario");
    this.idSocio = this.route.snapshot.paramMap.get("idSocio");
    this.userType = this.route.snapshot.paramMap.get("userType") ?? "";
    this.convenioForm = this.initForm();
    this.getUserData();

    if (this.userType === "socio") {
      await this.getUserDataRequest(this.idSocio, false, true);
      this.updateSocio = true;
      return;
    }

    if (this.getUserData().clavePerfil === "socio") {
      let regresarTodosContactos = false;
      this.idSocio = this.getUserData().idSocioFormador;

      this.getUserDataRequest(this.idSocio, regresarTodosContactos, false);
    }

    this.inicio.currentSubMenuOption.subscribe(
      (active) => (this.activeTab = active)
    );

    if (
      ["cvdp", "cvdp_admin"].includes(this.getUserData().clavePerfil)
    ) {
      this.convenioColumns[this.convenioColumns.length-1].hide = false;
      this.colaboracionColumns[this.colaboracionColumns.length-1].hide = false;
    }

    this.getAllCartaConvenios();
    this.getAllCartaColaboracion();
  }

  getUserData() {
    this.userData = this.usuariosService.getCurrentUserData();
    if (!this.userData) this.router.navigate(["/"]);
    return this.userData;
  }

  getMainContact(contacts: IContacto[]) {
    return contacts
      .filter((contact) => contact.contactoPrincipal)
      .map((contact) => ({
        ...contact,
        img: "avatar_sin",
        isContact: contact.contactoPrincipal,
      }));
  }

  getContacts(contacts: IContacto[]) {
    return contacts
      .filter((contact) => !contact.contactoPrincipal)
      .map((contact) => ({
        ...contact,
        img: "avatar_sin",
        nombre: `${contact.nombre}`,
        isContact: contact.contactoPrincipal,
      }));
  }

  async getUserDataRequest(idSocio, isMainUser = false, urlParams = false) {
    if (this.userData.contactoPrincipal) {
      try {
        let resultado = await lastValueFrom(
          this.contactosService.getContactos(idSocio, isMainUser)
        );
        this.mainContactData = this.getMainContact(resultado)?.[0] ?? null;
        if (!this.mainContactData) {
          this.mensajeContactos =
            "No puede ver esta información, porque no tiene asignado un contacto principal.";
          return;
        }

        this.contactsData = this.getContacts(resultado);
      } catch (err) {
        this.mensajeContactos =
          "No puede ver esta información, porque no tiene asignado un contacto principal.";

        Swal.fire({
          icon: "error",
          text:
            "No cuenta con Contacto Principal, favor de consultar al Administrador y corroborar el contacto principal.",
        });

        console.error("Sin contacto principal asociado. id: " + idSocio, err);
      }
    } else {
      try {
        let idContacto = this.userData.idContacto;
        if (urlParams) {
          // Viene de la gestion de usurarios, traer el usuario del parametro
          idContacto = this.idUser;
        }
        this.mainContactData = await lastValueFrom(
          this.contactosService.getContactoxId(idSocio, idContacto)
        );

        this.updateSocio = true;
      } catch (err) {
        console.log(err);
      }
    }
  }

  editContactSelected(contactObj: any) {
    this.editContact = true;
    this.newContactCreate = false;
    this.editContactData = contactObj;
  }

  async showContacts(event) {
    await this.getUserDataRequest(
      this.usuariosService.getCurrentUserData().idSocioFormador,
      false,
      true
    );
    this.editContact = false;
  }

  newContact() {
    this.editContact = true;
    this.newContactCreate = true;
  }

  changetab(tab: string) {
    this.editContact = false;
    this.inicio.changeSubMenuItem(tab);
  }

  onSubmit(): void {
    if (this.convenioForm.valid) {
      let convenioModel: any = {
        idSocioformador: this.userStore.idSocioFormador,
        fechaInicio: this.convenioForm.get("fechaInicio")?.value,
        fechaFin: this.convenioForm.get("fechaFin")?.value, //
        urlCartaConvenio: this.convenioForm.get("urlCartaConvenio")?.value,
      };

      try {
        this.socioFormadorService.postCartaConvenio(convenioModel).subscribe({
          next: (resOK) => {
            Swal.fire({
              title: "<strong>Carta Convenio</strong>",
              icon: "success",
              html: "Convenio cargado correctamente",
              showCloseButton: true,
              showConfirmButton: false,
            });
            this.convenioForm.reset();
            this.fileNameSelected = "";
          },
          error: (err) => {
            Swal.fire({
              title: "<strong>Error en tu solicitud</strong>",
              icon: "error",
              html: err.error.mensaje,
              showCloseButton: true,
              showConfirmButton: false,
            });
            console.log("Error Solcitud: ", err);
          },
        });
      } catch (e) {
        console.log(e);
      }
    }
  }

  initForm(): UntypedFormGroup {
    return this.fb.group({
      fechaInicio: ["", [Validators.required]],
      fechaFin: ["", [Validators.required]],
      urlCartaConvenio: ["", [Validators.required]],
    });
  }

  async selImagen(event: any) {
    this.fileNameSelected = "";
    let fecIni = this.convenioForm.get("fechaInicio")?.value;
    let fecFin = this.convenioForm.get("fechaFin")?.value;
    if (!this.validaPeriodoConvenio(fecIni, fecFin)) {
      this.toastr.error("Favor de revisar las fechas");
      event.target.value = "";
      return;
    }
    let idUsr = this.usuariosService.getCurrentUserData().idSocioFormador;
    let selFile = (event.target as HTMLInputElement).files?.[0];
    this.fileNameSelected = selFile?.name;
    if (!selFile) return;
    if (selFile.type != "application/pdf") {
      this.fileNameSelected = "Sólo archivos PDF.";
      this.toastr.error("Sólo archivos PDF.");
      return;
    }
    if (selFile.size > 3e6) {
      this.fileNameSelected = "Tamaño máximo de 3MB";
      this.toastr.error("Tamaño máximo de 3MB");
      return;
    }
    let fileName = this.getFileName(
      idUsr,
      this.convenioForm.get("fechaInicio")?.value,
      this.convenioForm.get("fechaFin")?.value
    );

    await this.azureBlobStorage
      .uploadFile(
        {
          containerName: environment.AzureBlob_containerNameSocio,
          file: selFile,
          filename: fileName,
        },
        environment.AzureBlob_ACCOUNT_NAME,
        environment.AzureBlob_socioSAS
      )
      .then((res) => {
        let fileUrlLogo = `${environment.AzureBlob_socioURL}${fileName}`;
        this.img = fileUrlLogo;
      });
  }

  guardarConvenio() {
    let fecIni = this.convenioForm.get("fechaInicio")?.value;
    let fecFin = this.convenioForm.get("fechaFin")?.value;
    if (!this.validaPeriodoConvenio(fecIni, fecFin)) {
      this.toastr.error("Favor de revisar las fechas");
      return;
    }

    this.socioFormadorService
      .postCartaConvenio({
        idSocioFormador: this.getUserData().idSocioFormador,
        fechaInicio: this.convenioForm.get("fechaInicio")?.value,
        fechaFin: this.convenioForm.get("fechaFin")?.value,
        urlCartaConvenio: this.img,
      })
      .subscribe((resOK) => {
        Swal.fire({
          icon: "success",
          text: "Convenio cargado con éxito.",
        });
        this.convenioForm.reset();
        this.fileNameSelected = "";
        this.getAllCartaConvenios();
      });

    this.toogleDivCargaConvenio();
  }

  getEstatus(status: string, fechaFin: string){

     if(status)
     {
      return status;
     }

      let fecHoy = new Date();
      let fecFin = new Date(fechaFin);


    // Fecha inicio menor a fecha fin
      if (fecHoy > fecFin) return 'Vencido';
    return 'Vigente';
  }
  getAllCartaConvenios() {
    let userStore = this.usuariosService.getCurrentUserData();
    this.listCartaConvenio = [];
    if(this.idSocio){
      this.socioFormadorService.getCartaConvenio(this.idSocio).subscribe({
        next: (res: ICartaConvenio[]) => {
          let listado;
          if (["academia"].includes(userStore.clavePerfil)) {
            listado = res.pop() ?? {};
            this.listCartaConvenio.push({
              fechaInicio: listado.fechaInicio,
              fechaTermino: listado.fechaFin,
              personaFirma: {
                title: listado.personaFirma,
                subtitle: listado.correo ?? "",
              },
              estatus: this.getEstatus(listado.estatus,listado.fechaFin),
              link: {
                link: listado.urlCartaConvenio,
                title: `Convenio ${listado.fechaInicio} ${listado.fechaFin}`,
                openNewTab: false,
                objParams:{
                  urlCarta: listado.urlCartaConvenio
                },
                callbackAction:(objParams) => {
                  this.verDocumento(objParams.urlCarta);
                }
              },
            });
          } else {
            listado = res;
            this.listCartaConvenio = listado.map((x) => ({
              fechaInicio: x.fechaInicio,
              fechaTermino: x.fechaFin,
              personaFirma: {
                title: x.personaFirma,
                subtitle: x.correo ?? "",
              },
              estatus: this.getEstatus(x.estatus, x.fechaFin),
              link: {
                link: x.urlCartaConvenio,
                title: `Convenio ${x.fechaInicio} ${x.fechaFin}`,
                openNewTab: false,
                objParams:{
                  urlCarta: x.urlCartaConvenio
                },
                callbackAction:(objParams) => {
                  this.verDocumento(objParams.urlCarta);
                }
              },
              buttons: [
                {
                  className: "delete",
                  objParams: x.idConvenio,
                  callbackAction: (idConvenio) => {
                    this.eliminarCartaConvenio(idConvenio);
                  },
                },
              ],
            }));
          }
        },
        error: (err) => {
          console.log(err);
        },
      });
    }
  }

  filePdfFirmado: string;

  downloadPDF() {
    //let nombre = this.filePdfFirmado.split("/")[4];
    saveAs(this.filePdfFirmado, "Carta.pdf");
  }

  verDocumento(doc){

    this.filePdfFirmado = doc;
    this.content_modal = this.modalService.open(this.content, { size: "xl" });
  }

  closeConvenio() {
    this.content_modal.close();
  }

  getAllCartaColaboracion() {
    let userStore = this.usuariosService.getCurrentUserData();
    this.listCartaColaboracion = [];
    if(this.idSocio){
      this.socioFormadorService.getCartaColaboracion(this.idSocio).subscribe({
        next: (resOk) => {
          let listado;

          if (["academia"].includes(userStore.clavePerfil)) {
            listado = resOk.pop() ?? {};
            this.listCartaColaboracion.push({
              fechaInicio: listado.fechaFirma,
              contactPerson:{
                title: listado.nombreContacto ?? '',
                subtitle: listado.correoContacto ?? '',
              },
              personaFirma: {
                title: listado.nombreAcademico,
                subtitle: listado.correo ?? "",
              },
              reto: listado.reto,
              link: {
                title: `Carta Colaboración ${listado.fechaFirma.substring(
                  0,
                  10
                )}`,
                link: listado.urlCartaColaboracion,
                openNewTab: false,
                objParams:{
                  urlCarta: listado.urlCartaColaboracion
                },
                callbackAction:(objParams) => {
                  this.verDocumento(objParams.urlCarta);
                }
              },
            });
          } else {
            listado = resOk;
            this.listCartaColaboracion = listado.map((x) => ({
              fechaInicio: x.fechaFirma,
              contactPerson:{
                title: listado.nombreContacto ?? '',
                subtitle: listado.correoContacto ?? '',
              },
              personaFirma: {
                title: x.nombreAcademico,
                subtitle: x.correo ?? "",
              },
              reto: x.reto,
              link: {
                title: `Carta Colaboración ${x.fechaFirma.substring(0, 10)}`,
                link: x.urlCartaColaboracion,
                openNewTab: false,
                objParams:{
                  urlCarta: x.urlCartaColaboracion
                },
                callbackAction:(objParams) => {
                  this.verDocumento(objParams.urlCarta);
                }
              },
              buttons: [
                {
                  className: "delete",
                  objParams: x.idColaboracion,
                  callbackAction: (idCartaColaboracion) => {
                    this.eliminarCartaColaboracion(idCartaColaboracion);
                  },
                },
              ],
            }));
          }
        },
        error: (err) => {
          console.log(err);
        },
      });
    }
  }

  getFileName(id, fechaInicio, fechaFin) {
    fechaInicio = fechaInicio.replace("/");
    fechaFin = fechaFin.replace("/");
    return `cartaconvenio_${id}_${fechaInicio}_${fechaFin}.pdf`;
  }

  eliminarCartaConvenio(idConvenio: number) {
    let idUsuario = this.usuariosService.getCurrentUserData().idUsuario;
    Swal.fire({
      title: "Convenios",
      text: "¿Desea eliminar el convenio?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Eliminar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        this.socioFormadorService
          .deleteCartaConvenio(idConvenio, idUsuario)
          .pipe(
            tap((resultado: any) => {
              if (resultado) {
                Swal.fire(
                  "Socio Formador - Convenio",
                  "Convenio eliminado.",
                  "success"
                );
                this.getAllCartaConvenios();
                this.convenioForm.reset();
                this.fileNameSelected = "";
              } else {
                this.toastr.error("Algo Salió mal!");
              }
            })
          )
          .subscribe();
      }
    });
  }

  eliminarCartaColaboracion(idCartacolaboracion: number) {
    let idUsuario = this.usuariosService.getCurrentUserData().idUsuario;
    Swal.fire({
      title: "Carta Colaboración",
      text: "¿Desea eliminar la carta de colaboración?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Eliminar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        this.socioFormadorService
          .deleteCartaColaboracion(idCartacolaboracion, idUsuario)
          .pipe(
            tap((resultado: any) => {
              if (resultado) {
                Swal.fire(
                  "Socio Formador - colaboración",
                  "Carta colaboración eliminada.",
                  "success"
                );
                this.getAllCartaColaboracion();
                this.convenioForm.reset();
              } else {
                this.toastr.error("Algo Salió mal!");
              }
            })
          )
          .subscribe();
      }
    });
  }

  fileNameSelected;
  mostrarDivSolVinculacion = false;
  imgQuieroVincularlo: string = "assets/img/icon_abajo.svg";
  toogleDivCargaConvenio() {
    this.mostrarDivSolVinculacion = !this.mostrarDivSolVinculacion;

    this.imgQuieroVincularlo = this.mostrarDivSolVinculacion
      ? "assets/img/icon_arriba.svg"
      : "assets/img/icon_abajo.svg";
  }

  validaFecha($event) {
    let fechaFin = this.sumarAniosFecha($event.target.value, 5);
    this.convenioForm.get("fechaFin")?.patchValue(fechaFin);
  }

  sumarAniosFecha(pFecha, anios) {
    let fecha = pFecha.split("-");
    let anio = fecha[0];
    let anio5 = Number(anio) + Number(anios);
    let fechaFin = `${anio5}-${fecha[1]}-${fecha[2]}`;
    return fechaFin;
  }

  validaFechaFin($event) {
    let fechaInicio = this.convenioForm.get("fechaInicio")?.value;
    this.validaPeriodoConvenio(fechaInicio, $event.target.value);
  }

  secondsDiff(d1, d2) {
    let secDiff = Math.floor((d2 - d1) / 1000);
    return secDiff;
  }

  minutesDiff(d1, d2) {
    let seconds = this.secondsDiff(d1, d2);
    let minutesDiff = Math.floor(seconds / 60);
    return minutesDiff;
  }

  hoursDiff(d1, d2) {
    let minutes = this.minutesDiff(d1, d2);
    let hoursDiff = Math.floor(minutes / 60);
    return hoursDiff;
  }
  daysDiff(d1, d2) {
    let hours = this.hoursDiff(d1, d2);
    let daysDiff = Math.floor(hours / 24);
    return daysDiff;
  }

  validaPeriodoConvenio(fechaInicio, fechaFin) {
    let fecIni = fechaInicio.replaceAll("-", "");
    let fecFin = fechaFin.replaceAll("-", "");

    // Que haya fechaInicio
    if (!fechaInicio && !fechaFin) return false;

    // Fecha inicio menor a fecha fin
    if (fecIni > fecFin) return false;

    // periodo no mayor a 5 años entre fecha inicio y fecha fin
    let tmpFecFin: any = this.sumarAniosFecha(fechaInicio, 5);
    tmpFecFin = tmpFecFin.replaceAll("-", "");
    if (fecFin > tmpFecFin) return false;

    return true;
  }

  convenioColumns: Column[] = [
    {
      title: "Fecha de inicio",
      sizeCol: "2",
      classType: "dashbd_rows_thin justify-content-md-center",
      typeColumn: optionsTextCol.date,
      key: "fechaInicio",
      headerClassName: "dashbd_header",
    },
    {
      title: "Fecha de Término",
      sizeCol: "2",
      classType: "dashbd_rows_thin justify-content-md-center",
      typeColumn: optionsTextCol.date,
      key: "fechaTermino",
      headerClassName: "dashbd_header",
    },
    {
      title: "Persona que firma",
      sizeCol: "2",
      classType: "dashbd_rows_thin justify-content-md-center",
      typeColumn: optionsTextCol.imageTitle,
      key: "personaFirma",
      headerClassName: "dashbd_header",
    },
    {
      title: "Documento",
      sizeCol: "3",
      classType: "dashbd_rows_thin justify-content-md-center",
      typeColumn: optionsTextCol.link,
      key: "link",
      headerClassName: "dashbd_header"
    },
    {
      title: "Estatus",
      sizeCol: "2",
      classType: "dashbd_rows_thin justify-content-md-center",
      typeColumn: optionsTextCol.text,
      key: "estatus",
      headerClassName: "dashbd_header",
    },
    {
      title: "Acciones",
      sizeCol: "1",
      classType: "dashbd_rows_thin justify-content-md-center",
      typeColumn: optionsTextCol.buttons,
      key: "buttons",
      headerClassName: "dashbd_header",
      hide: true,
    },
  ];

  colaboracionColumns: Column[] = [
    {
      title: "Fecha de firma",
      sizeCol: "2",
      classType: "dashbd_rows_thin justify-content-md-center",
      typeColumn: optionsTextCol.date,
      key: "fechaInicio",
      headerClassName: "dashbd_header",
    },
    {
      title: "Mi contacto que firma",
      sizeCol: "2",
      classType: "dashbd_rows_thin justify-content-md-center",
      typeColumn: optionsTextCol.imageTitle,
      key: "contactPerson",
      headerClassName: "dashbd_header",
    },
    {
      title: "Académico que firma",
      sizeCol: "2",
      classType: "dashbd_rows_thin justify-content-md-center",
      typeColumn: optionsTextCol.imageTitle,
      key: "personaFirma",
      headerClassName: "dashbd_header",
    },
    {
      title: "Reto",
      sizeCol: "2",
      classType: "dashbd_rows_thin justify-content-md-center",
      typeColumn: optionsTextCol.text,
      key: "reto",
      headerClassName: "dashbd_header",
    },
    {
      title: "Documento",
      sizeCol: "3",
      classType: "dashbd_rows_thin justify-content-md-center",
      typeColumn: optionsTextCol.link,
      key: "link",
      headerClassName: "dashbd_header",
    },
    {
      title: "Acciones",
      sizeCol: "1",
      classType: "dashbd_rows_thin justify-content-md-center",
      typeColumn: optionsTextCol.buttons,
      key: "buttons",
      headerClassName: "dashbd_header",
      hide: true,
    },
  ];
}
