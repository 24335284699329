<app-header-buscador [showSearchBar]="false"></app-header-buscador>

<div class="sec_ad">
  <div>
    <img class="img_hdr" alt="Vinculacion" src="assets/img/img_vinculacion_fullsize.jpg" />
  </div>
  <app-title-show-more [showMoreObject]="titleVinculacion"></app-title-show-more>

  <div class="div-text-btn">
    <div class="question-div">
      Característica del reto programado:.
      <br /><br />
    </div>
  </div>


  <div class="sec_ad">

    <div id="sec_tab" class="nav nav-pills d-flex flex-md-nowrap flex-wrap" role="presentation">
      <div class="active" data-bs-toggle="pill" data-bs-target="#reto" aria-controls="reto" prm="re_ac" role="tab"
        type="button" aria-selected="true">
        Reto y coordinador
      </div>
      <div prm="re_hs" data-bs-toggle="pill" data-bs-target="#socio" aria-controls="socio" class="" role="tab"
        type="button" aria-selected="false">
        Socio Formador
      </div>
      <div prm="re_hs" data-bs-toggle="pill" data-bs-target="#vinculacion" aria-controls="vinculacion" class=""
        role="tab" type="button" aria-selected="false">
        Vinculación
      </div>
      <div prm="re_hs" data-bs-toggle="pill" data-bs-target="#reto-particular" aria-controls="reto-particular" class=""
        role="tab" type="button" aria-selected="false">
        Información del reto particular
      </div>

    </div>

    <div class="tab-content" id="pills-tabContent">


      <!-- * Informacion del reto * -->
      <div class="tab-pane fade show active" id="reto" role="tabpanel" aria-labelledby="retos-activos-tab">
        <form [formGroup]="retoProfesorForm" (submit)="updateRetoProfesorCoordinador()" autocomplete="off" class="">
          <div class="row questions-container open-text">
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-12">
                  <span class="question-title-text">Acerca del reto</span>
                </div>


                <div class="col-md-12">
                  <span class="question-text">Selecciona tu Escuela <span class="requerido">*</span></span>

                  <div class="requerido" *ngIf="retoProfesorForm.get('idEscuela')?.errors?.['required']">
                    Este campo es requerido
                  </div>

                  <div class="select-dropdown">
                    <select class="form-select" id="idEscuela" aria-label="Default select example"
                      formControlName="idEscuela" (change)="getUnidadFormativa($event)">
                      <option selected>Selecciona</option>
                      <option *ngFor="let escuela of escuelaData" value="{{ escuela.idEscuela }}">
                        {{ escuela.escuela }}
                      </option>
                    </select>

                  </div>
                </div>

                <div class="col-md-12">
                  <span class="question-text">Selecciona la Unidad Formativa <span class="requerido">*</span></span>

                  <div class="requerido" *ngIf="retoProfesorForm.get('idUnidadFormativa')?.errors?.['required']">
                    Este campo es requerido.
                  </div>

                  <ng-select aria-label="Default " (change)="getRetoDetail($event)" formControlName="idUnidadFormativa">
                    <ng-option *ngFor="let reto of unidadFormativaData" [value]="reto.idUnidadFormativa">
                      {{ reto.clave }} - {{ reto.unidadFormativa}}
                    </ng-option>
                  </ng-select>
                </div>

                <div class="col bkgatomic" *ngIf="!retoInfo">
                  <span class="titatomic">Selecciona un reto para ver su información
                  </span>
                </div>
                <div class="col bkgatomic" *ngIf="retoInfo">
                  <div class="row mb-5">
                    <div class="col">
                      <img class="imgpchip_med" alt="Información del reto" src="assets/svg/icon_info.svg" />&nbsp;
                      <span class="titatomic">
                        <strong>Información del reto asociado a la UF</strong>
                      </span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 mb-4">
                      <span class="titsans">{{ retoInfo.reto }}</span>
                    </div>
                    <div class="col-12">
                      <strong>Descripción:</strong>
                    </div>
                    <div class="col-12 mb-2">
                      <p>{{ retoInfo.descripcion }}</p>
                    </div>
                    <div class="col-12">
                      <strong>Entregable:</strong>
                    </div>
                    <div class="col-12 mb-2">
                      <p>{{ retoInfo.entregable }}</p>
                    </div>
                    <div class="col-12">
                      <strong>Objetivo del reto:</strong>
                    </div>
                  </div>
                  <div class="col-12 mb-2">
                    <p>{{ retoInfo.objetivo }}</p>
                  </div>
                  <div class="col-12">
                    <strong>Alcance:</strong>
                  </div>
                  <div class="col-12 mb-2">
                    <p>{{ retoInfo.alcances }}</p>
                  </div>

                  <div class="col-12">
                    <strong>Idiomas: </strong>
                  </div>
                  <div class="col-12 mb-2">
                    <span>{{ retoInfo.idiomas }}</span>
                  </div>
                  <div class="col-12">
                    <strong>Semestres: </strong>
                  </div>
                  <div class="col-12 mb-2">
                    <span>{{ retoInfo.semestres }}</span>
                  </div>
                  <div class="col-12">
                    <strong>Duración: </strong>
                  </div>
                  <div class="col-12 mb-2">
                    <span>{{ retoInfo.semanas }}</span>
                  </div>
                  <div class="row">
                    <div class="col-3 container-icons mb-3" *ngFor="let infoIcon of retoInfo.generalInfoIcons">
                      <app-icon-border [icon]="infoIcon.icon" [title]="infoIcon.title"></app-icon-border>
                    </div>
                  </div>

                  <div class="col-12">
                    <strong>Programa Académico</strong><br>
                    <div class="" *ngFor="let programa of retoInfo.programasAcademicos">
                      <div class="mt-0 mb-0">{{ programa.clave}} {{programa.programaAcademico }}</div>
                    </div>
                  </div>
                  <div class="row" *ngIf="false">
                    <div class="col d-flex justify-content-center">
                      <app-button extraClass="">Crear nuevo reto a partir de existente</app-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Profesor Coordinador -->
            <div class="col-md-6">

              <div class="row">
                <div class="col-md-12">
                  <span class="question-title-text">Profesor coordinador del reto:</span>
                  <br>
                  <span>Contacto del Tec con quien se estará trabajando el reto</span>
                  <br><br>
                </div>
              </div>

              <div class="row mb-3">
                <div class="col-md-12">
                  <span class="question-text">Nombre: </span>

                  <input type="text" style="width: 100%;" formControlName="nombreCoordinador">

                </div>
              </div>

              <div class="row mb-3">
                <div class="col-md-12">
                  <span class="question-text">Correo: </span>

                  <input type="text" style="width: 100%;" formControlName="correoCoordinador">

                </div>
              </div>

              <div class="row mb-3">
                <div class="col-md-12">
                  <span class="question-text">Puesto: </span>

                  <input type="text" style="width: 100%;" formControlName="puestoCoordinador">

                </div>
              </div>

              <div class="row mb-3">
                <div class="col-md-12">
                  <span class="question-text">Teléfono: </span>

                  <input type="text" style="width: 100%;" formControlName="telefonoCoordinador">

                </div>
              </div>

              <div class="row">
                <div class="col-3">
                  <app-button ngForm buttonType="yell" extraClass="btn-full" [isDisabled]="buttonSave.isLoading">
                    Guardar </app-button>
                </div>
              </div>

            </div>

          </div>
        </form>
      </div>

      <!-- *  Información del Socio Formador *-->
      <div class="tab-pane fade" id="socio" role="tabpanel" aria-labelledby="historial-retos-tab">
        <form [formGroup]="socioForm" (submit)="updateSocio()" autocomplete="off">
          <div class="row questions-container open-text">
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-12">
                  <span class="question-title-text">Acerca del Socio Formador</span>
                </div>
              </div>

              <div class="col-12">
                <span>Nombre del Socio Formador:&nbsp;<span class="requerido">*</span></span>
                <ng-select aria-label="Default example" formControlName="idSocioFormador"
                  (change)="getDatosSocioformador($event)">
                  <ng-option *ngFor="let socio of socioFormadorList" [value]="socio.idSocioFormador">
                    {{ socio.nombreSocioFormador }}
                  </ng-option>
                </ng-select>
              </div>

              <div class="col bkgatomic">
                <div class="row mb-5">
                  <div class="col">
                    <img class="imgpchip_med" alt="Informacion del Socio Formador"
                      src="assets/svg/icon_info.svg" />&nbsp;
                    <span class="titatomic">
                      <strong>Información del Socio Formador seleccionado</strong>
                    </span>
                  </div>
                </div>

                <div><strong>{{ SF_razon }}</strong></div>

                <div class="col-12 mb-2">
                  <span class="mb-2">{{ SF_empresa }}</span>
                </div>

                <div class="col-6">
                  <strong>Sector:</strong>
                </div>
                <div class="col-6 mb-2">
                  <span> {{ SF_sector }} </span>
                </div>
                <div class="col-6">
                  <strong>Tamaño de Empresa:</strong>
                </div>
                <div class="col-6 mb-2">
                  <span>{{ SF_tamano }} </span>
                </div>
                <div class="col-6">
                  <strong>Industria:</strong>
                </div>
                <div class="col-6 mb-2">
                  <span>{{ SF_industria }}</span>
                </div>
                <div class="col-6">
                  <strong>Presencia:</strong>
                </div>
                <div class="col-6 mb-2">
                  <span>{{ SF_presencia }}</span>
                </div>


                <div class="col-6" *ngIf="socioFormadorContactos">
                  <strong>Contactos</strong>
                </div>
                <div class="col-12 mb-5">
                  <div *ngFor="let contacto of socioFormadorContactosPrincipal">
                    Nombre: {{ contacto.nombre }} {{ contacto.nombre }}<br>
                    Email: {{ contacto.email }}<br>
                    Telefono: {{ contacto.telefonoOficina }}<br>
                    Móvil: {{ contacto.telefonoMovil }}
                  </div>
                </div>
              </div>
            </div>



            <div class="col-md-6">
              <div class="row">
                <div class="col-md-12">
                  <span class="question-title-text">Contacto de la empresa:</span>
                  <br>
                  <span>Contacto de la empresa con quien estarán trabajando el reto</span>
                  <br><br>
                </div>
              </div>

              <div class="row mb-3">
                <div class="col-md-12">
                  <span class="question-text">Nombre: </span>

                  <input type="text" style="width: 100%;" formControlName="nombreContacto">

                </div>
              </div>

              <div class="row mb-3">
                <div class="col-md-12">
                  <span class="question-text">Correo: </span>

                  <input type="text" style="width: 100%;" formControlName="correoContacto">

                </div>
              </div>

              <div class="row mb-3">
                <div class="col-md-12">
                  <span class="question-text">Puesto: </span>

                  <input type="text" style="width: 100%;" formControlName="puestoContacto">

                </div>
              </div>

              <div class="row mb-3">
                <div class="col-md-12">
                  <span class="question-text">Teléfono: </span>

                  <input type="text" style="width: 100%;" formControlName="telefonoContacto">

                </div>
              </div>

              <div class="row">
                <div class="col">
                  <app-button ngForm buttonType="yell" extraClass="btn-full" [isDisabled]="buttonSave.isLoading">
                    Guardar</app-button>
                </div>
              </div>
            </div>

          </div>
        </form>
      </div>

      <!-- * Información de Vinculación * -->
      <div class="tab-pane fade" id="vinculacion" role="tabpanel" aria-labelledby="historial-retos-tab">

        <div class="row questions-container open-text">
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-12">
                <span class="question-title-text">Acerca de la Vinculación</span>
                <br><br>
              </div>

              <div class="col-12">
                <span class="question-text">Carta colaboración: &nbsp;</span>
                <br>

                <div class="lmt_text">
                  <a *ngIf="urlCartaColaboracion!=''" href="{{ urlCartaColaboracion }}" target="_blank" rel="noopener noreferrer">
                    <button class="btn btn-amarillo">Ver</button></a>
                  <button *ngIf="!urlCartaColaboracion" (click)="file.click()" class="btn btn-amarillo">Cargar</button>
                  <input class="d-none" type="file" (change)="selImagen($event)" accept="Application/pdf" #file />
                </div>

              </div>

              <div class="col-12">&nbsp;</div>
              <form [formGroup]="infoVinculacionForm" (submit)="updateVinculacion()" autocomplete="off">
                <div class="col-12">
                  <span class="question-text">Periodo Semestral:&nbsp; </span>
                  <div class="select-dropdown">

                    <select class="form-select" aria-label="Default select example"
                      formControlName="idPeriodoSemestral">
                      <option value="{{semestre.idPeriodo}}" *ngFor="let semestre of periodos">{{ semestre.periodo}}
                      </option>

                    </select>

                  </div>

                </div>

                <div class="col-12">&nbsp;</div>

                <div class="col-12">
                  <span class="question-text">Periodo semanal:&nbsp;</span>

                  <ng-select [multiple]="true" formControlName="periodosSemanales" (change)="onChangeValue($event)" [items]="periodosSemanales" bindLabel="periodoSemanal"
                   bindValue="idPeriodoSemanal">
                  </ng-select>

                </div>

                <div class="col-12">&nbsp;</div>

                <div class="col-12">
                  <span class="question-text">CRN Principal:</span>
                  <br>
                  <input style="width:100%" type="text" formControlName="CRNPrincipal">
                </div>

                <div class="col-12">&nbsp;</div>

                <div class="col-12">
                  <span class="question-text">CRN Vinculados:</span>
                  <br>
                  <ng-select [addTag]="true" [multiple]="true" [minTermLength]="4"  formControlName="CRNVinculados"></ng-select>
                </div>

                <div class="col-12">&nbsp;</div>

                <div class="col-12">
                  <span class="question-text">Campus donde se lleva el reto: </span>
                  <ng-select [multiple]="true" formControlName="listadoCampus" (change)="onChangeValue($event)" [items]="campusData" bindLabel="campus" bindValue="idCampus">
                  </ng-select>
                </div>

                <div class="col-12">&nbsp;</div>

                <div class="col-12">
                  <span class="question-text">Campus que coordina el reto: </span>
                  <select class="form-select" aria-label="Default select example" formControlName="campusCoordina">
                    <option *ngFor="let campus of selectedCampus" value="{{campus.idCampus}}">{{ campus.campus }}</option>
                  </select>
                </div>

                <div class="row">
                  <div class="col-3 mt-3">
                    <app-button ngForm buttonType="yell" extraClass="btn-full" [isDisabled]="buttonSave.isLoading">
                      Guardar</app-button>
                  </div>
                </div>

              </form>

            </div>

          </div>

          <div class="row">&nbsp;</div>

        </div>

      </div>

      <!-- * Informacion del Reto Particular * -->
      <div class="tab-pane fade" id="reto-particular" role="tabpanel" aria-labelledby="historial-retos-tab">
        <form [formGroup]="retoParticularForm" (submit)="updateRetoParticular()" autocomplete="off">
          <div class="row questions-container open-text">

            <div class="col-md-12">

              <div class="row">
                <div class="col-md-12">
                  <span class="question-title-text">Acerca del reto particular</span>
                </div>
              </div>


              <div class="row mb-3">
                <div class="col-md-12">
                  <span class="question-text">Nombre del reto: <span class="requerido">*</span></span>

                  <input type="text" style="width: 100%;" id="reto-particular-reto" formControlName="nombreReto">

                </div>
              </div>

              <div class="row mb-3">
                <div class="col-md-12">
                  <span class="question-text">Descripción del reto: <span class="requerido">*</span></span>

                  <textarea type="text" style="width: 100%;" formControlName="descripcionReto"></textarea>

                </div>
              </div>

              <div class="row mb-3">
                <div class="col-md-12">
                  <span class="question-text">Entregable: <span class="requerido">*</span></span>

                  <textarea type="text" style="width: 100%;" formControlName="entregable"></textarea>

                </div>
              </div>



              <div class="row">
                <div class="col-3">
                  <app-button ngForm buttonType="yell" extraClass="btn-full" [isDisabled]="buttonSave.isLoading">
                    Guardar</app-button>
                </div>
                <div class="col-3" *ngIf="['academia','cvdp', 'cvdp_admin'].includes(clavePerfil)">
                  <app-button *ngIf="retoGuardado" type="button" buttonType="yell" (callbackAction)="marcarTerminado()" >Terminado</app-button>
                </div>
              </div>
            </div>

          </div>
        </form>
      </div>



    </div>

  </div>

</div>
