import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import {
  COLS_SF_SOLICITANDO_RETO,
  COLS_SOLICITUDES_SF_POTENCIAL,
} from "@pages/dashboard/cvdp-dashboard/cvdp-dashboard.columns";
import {
  ICampus,
  IEscuela,
  IPeriodo,
} from "@shared/Interface/ICatalogos.interface";
import { IVinculacionesResponse } from "@shared/Interface/IsocioVinculacion";
import { CatalogosService } from "@shared/service/Catalogos/catalogos.service";
import { DashboardService } from "@shared/service/Dashboard/dashboard.service";
import { UsuariosService } from "@shared/service/Usuarios/usuarios.service";
import { lastValueFrom, tap } from "rxjs";

@Component({
  selector: "app-sf-solicitando-reto",
  templateUrl: "./SF-solicitando-reto.component.html",
  styleUrls: ["./SF-solicitando-reto.component.css"],
})
export class SFSolicitandoRetosComponent implements OnInit {
  lang: string = "es";
  backgroundTable = "#F6F6F6";

  constructor(
    private usuarioService: UsuariosService,
    private dashboardService: DashboardService,
    private catalogosService: CatalogosService,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.lang = localStorage.getItem("lang") ?? "es";
    this.loadCatalogos();
    this.loadData();
  }

  changeLang(lang: string) {
    this.lang = lang;
    localStorage.setItem("lang", lang);
    window.location.reload();
  }

  campusData: ICampus[] = [];
  escuelaData: IEscuela[] = [];
  periodoData: IPeriodo[] = [];

  filter: any = {
    query: "",
    campus: 0,
    escuela: 0,
    periodo: 0,
  };

  pagina: number = 0;
  hits: number = 0;

  loadCatalogos() {
    this.catalogosService
      .getCampus()
      .pipe(tap((resultado: ICampus[]) => (this.campusData = resultado)))
      .subscribe();

    this.catalogosService
      .getEscuelas()
      .pipe(tap((resultado: IEscuela[]) => (this.escuelaData = resultado)))
      .subscribe();

    this.catalogosService
      .getPeriodo(false)
      .pipe(tap((resultado: IPeriodo[]) => (this.periodoData = resultado)))
      .subscribe();
  }

  getValueCatalogo(obj: any, index: string, id: number, indexValue: string) {
    let obje = obj.filter((x: any) => x[index] === id).pop();
    if (obje) {
      return obje[indexValue];
    } else {
      return null;
    }
  }

  addFilter(type: string, id: number) {
    this.filter[type] = id;
    this.loadData();
  }

  cleanFilters() {
    this.filter = {
      query: "",
      campus: 0,
      escuela: 0,
      periodo: 0,
    };
    this.loadData();
  }

  solicitudesColumns = COLS_SOLICITUDES_SF_POTENCIAL;

  retoColumns = COLS_SF_SOLICITANDO_RETO;
  solicitudesRetosData: any[] =[];
  solicitudesAprobadasRetosData: any[] =[];
  solicitudesRechazadasRetosData: any[] =[];
  solicitudesRetosDataPage: any[] = [];
  solicitudesAprobadasRetosDataPage: any[] = [];
  solicitudesRechazadasRetosDataPage: any[] = [];

  solicitudesRetosRegistradosData: any[] = [];
  solicitudesRetosRegistradosDataPage: any[] = [];

  async loadData() {
    let userStore = this.usuarioService.getCurrentUserData();

  this.solicitudesRetosData =[];
  this.solicitudesAprobadasRetosData =[];
  this.solicitudesRechazadasRetosData =[];
  this.solicitudesRetosDataPage = [];
  this.solicitudesAprobadasRetosDataPage = [];
  this.solicitudesRechazadasRetosDataPage = [];
  this.solicitudesRetosRegistradosData = [];
  this.solicitudesRetosRegistradosDataPage = [];

    let idUsuario =
      userStore.clavePerfil === "cvdp_admin" ? 0 : userStore.idUsuario;

      let response;

      let responseRegistrado = await lastValueFrom(
        this.dashboardService.getSolicitudesRetoSocio(
          idUsuario,
          "registrado",
          this.filter.campus,
          this.filter.escuela,
          this.filter.periodo,
          this.pagina,
          this.hits
        )
      );

          this.solicitudesRetosRegistradosData = this.getSociosSolicitandoReto(responseRegistrado);
      this.showPaginationDataRegistrado(this.solicitudesRetosRegistradosData.slice(0,10));

    let responseRevision = await lastValueFrom(
      this.dashboardService.getSolicitudesRetoSocio(
        idUsuario,
        "revision",
        this.filter.campus,
        this.filter.escuela,
        this.filter.periodo,
        this.pagina,
        this.hits
      )
    );

    this.solicitudesRetosData = this.getSociosSolicitandoReto(responseRevision);
    this.showPaginationDataRevision(this.solicitudesRetosData.slice(0,10));

    response = await lastValueFrom(
      this.dashboardService.getSolicitudesRetoSocio(
        idUsuario,
        "aprobado",
        this.filter.campus,
        this.filter.escuela,
        this.filter.periodo,
        this.pagina,
        this.hits
      )
    );
    this.solicitudesAprobadasRetosData = this.getSociosSolicitandoReto(
      response
    );
    this.showPaginationDataAprobado(this.solicitudesAprobadasRetosDataPage.slice(0,10));

    response = await lastValueFrom(
      this.dashboardService.getSolicitudesRetoSocio(
        idUsuario,
        "rechazado",
        this.filter.campus,
        this.filter.escuela,
        this.filter.periodo,
        this.pagina,
        this.hits
      )
    );
    this.solicitudesRechazadasRetosData = this.getSociosSolicitandoReto(
      response
    );
    this.showPaginationDataRechazado(this.solicitudesRechazadasRetosData.slice(0,10));
  }

  getSociosSolicitandoReto(resultado) {
    return resultado.map((solicitudes: IVinculacionesResponse) => ({
      fechaSolicitud: solicitudes.fechaRegistro,
      campus: solicitudes.campus ?? "-",
      alcance: solicitudes.campusVincular ?? "-",
      duracion: solicitudes.duracion ?? "-",
      periodo: solicitudes.periodoSemestral ?? "-",
      link: {
        title: solicitudes.nombreSocioFormador,
        subtitle: solicitudes.rfc,
        link: `/solicitud-vinculacion-a-reto/${solicitudes.idSocioFormador}/${solicitudes.idRetoMaestro}/${solicitudes.idSolicitud}`,
      },
      contactPerson: {
        title: solicitudes.nombreContacto,
        subtitle: solicitudes.correoContacto,
      },
      retoVincular: {
        title: solicitudes.reto,
        subtitle: solicitudes.escuela,
      },
      claveEstatusSolicitud: {
        textCol: "Ver",
        statusType: "primary",
        link: `/solicitud-vinculacion-a-reto/${solicitudes.idSocioFormador}/${solicitudes.idRetoMaestro}/${solicitudes.idSolicitud}`,
      },
    }));
  }

  showPaginationDataRegistrado(objData: any) {
    if (objData.length) {
      this.solicitudesRetosRegistradosDataPage = objData;
      this.changeDetector.detectChanges();
    }
  }

  showPaginationDataRevision(objData: any) {
    if (objData.length) {
      this.solicitudesRetosDataPage = objData;
      this.changeDetector.detectChanges();
    }
  }

  showPaginationDataAprobado(objData: any) {
    if (objData.length) {
      this.solicitudesAprobadasRetosDataPage = objData;
      this.changeDetector.detectChanges();
    }
  }

  showPaginationDataRechazado(objData: any) {
    if (objData.length) {
      this.solicitudesRechazadasRetosDataPage = objData;
      this.changeDetector.detectChanges();
    }
  }
}
