
<div id="div" class="d-block d-md-none bg-white">
  <div id="bar-mb" class="d-flex">
    <div id="logo-mb" class="col-6 d-flex align-items-center">
      <img alt="MPS" src="assets/img/sello_n.svg">
      <span>Market<br />Place</span>
    </div>
    <div id="btn-md" class="d-flex align-items-center justify-content-end">
      <div class="p-3">
        <a id="mb_alert" href="" class="position-relative"  [routerLink]="['/notificaciones/',{ queryParams: {socio: this.idSocioFormador}}]">
          <img alt="Mensajes" src="assets/img/union.svg">
          <span
            class="position-absolute top-0 start-100 translate-middle badge border border-light rounded-pill bg-notif">
            <div>{{ NumeroMensajes }}</div>
            <span class="visually-hidden">unread messages</span>
          </span>
        </a>
      </div>
      <div *ngIf="showSearchBar" id="mb_search" class="p-3"><img alt="Búsqueda" src="assets/img/icon_search.svg"></div>
      <div id="mb_menu" class="p-3"><img alt="Menu" src="assets/img/icon_menu_hamb.svg"></div>
    </div>
  </div>
  <div *ngIf="showSearchBar" id="mn_search">
    <form id="search_mb" class="form-inline my-2 my-md-0">
      <div id="inp_srch" class="input-group input-group-lg">
        <span class="input-group-text" id="basic-addon1"><i class="fa fa-search"></i></span>
        <input class="form-control mr-sm-2" type="search" placeholder="{{ lbl_placeholder }}" [(ngModel)]="valorBuscador" name="buscadorMovil" (change)="childFunction()"
          aria-label="Search">
      </div>
    </form>
  </div>
</div>

<div id="cuerpo" class="d-flex">
  <div class="content mb-0 p-0">

    <div id="bar-wb" class="d-none d-md-block" style="margin-top: 1.11vw;">
      <div class="row w-100">
        <div class="col-5 offset-3">
          <div *ngIf="showSearchBar" id="mn_search" class="d-flex align-items-center">
            <form id="search_wb" class="form-inline my-2 my-md-0">
              <div id="inp_srch" class="input-group input-group-lg">
                <span class="input-group-text" id="basic-addon1"><i class="fa fa-search"></i></span>
                <input class="form-control mr-sm-2" type="search" placeholder="{{ lbl_placeholder }}" [(ngModel)]="valorBuscador" name="buscadorWeb" (change)="childFunction()"
                  aria-label="Search">
              </div>
            </form>
          </div>
        </div>


        <div id="btn-mn" class="col-3 text-end" style="margin-left: 60px;">

          <app-menu></app-menu>

        </div>


      </div>
    </div>

  </div>
</div>
