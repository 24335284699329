import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SolicitudesService } from '@shared/service/Solicitudes/solicitudes.service';

interface Retos {
  campus: string;
  descripcion: string;
  idReto: number;
  matchScore: number;
  periodo: string;
  programaAcademico: string;
  reto: string;
  semestre: number;
  unidadFormativa: string;
}
interface RetosData extends Retos {
  groupsData?: Retos[];
}
@Component({
  selector: 'app-vincula-retos-search',
  templateUrl: './vincula-retos-search.component.html',
  styleUrls: ['./vincula-retos-search.component.css'],
})
export class VinculaRetosSearchComponent implements OnInit {
  titleVinculaReto = {
    title:
      'De acuerdo con la información proporcionada, los retos con los que podrías vincularte son:',
  };
  retosData: any;
  retosInfo: any;
  objBotton: any = {
    title: '¿Tu problemática no se acopla a los retos mostrados?',
    subtitle: 'Dinos más detalles sobre tu problemática y qué apoyos buscas.',
    buttons: [
      {
        title: 'Registra tu problemática',
        type: 'blue',
        action: () =>
          this.navigateProblematica(this.getMatchData().idProblematica),
      },
    ],
  };
  constructor(
    private router: Router,
    private solicitudesService: SolicitudesService
  ) {}

  getMatchData() {
    const retos: any = localStorage.getItem('retosMatch');
    if (!retos) this.router.navigate(['vinculacion-reto']);
    this.retosInfo = JSON.parse(retos);
    return this.retosInfo;
  }

  ngOnInit(): void {
    
    this.retosData = this.generateGroupsOf(this.getMatchData().retosMatch);
  }

  generateGroupsOf(data: any) {
    const groups: any = [];
    let group: any = [];
    let index = 1;
    data.forEach((record: any, i: number) => {
      if (index % 3 === 0) {
        group.push(record);
        groups.push(group);
        group = [];
      } else {
        group.push(record);
      }
      if (data.length === index && group.length) {
        groups.push(group);
        group = [];
      }
      index++;
    });
    return groups;
  }

  joinReto(retoObj: any) {

    localStorage.setItem('contextoOrigen','matchReto');
    localStorage.setItem('retoSelected', JSON.stringify(retoObj));
    localStorage.setItem('problematica',this.getMatchData().idProblematica);
    // Set idRetoMaestro
    sessionStorage.setItem('idRetoMaestro',retoObj.idReto);
    


    this.router.navigateByUrl(`/socio/pagina-retos`);

  }

  navigateProblematica(idProblematica: string) {
    this.router.navigate([`problematica/${idProblematica}`]);
  }
}
