<div id="sec_tit">
  <div class="d-flex align-items-center">
    <h2>{{ showMoreObject.title }}</h2>
  
    <div *ngIf="showMoreObject.link" class="d-none d-md-block">
      <a [routerLink]="showMoreObject.link" class="a_btn_verm">
        <button class="btn_verm">
          Ver más
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1"
            x="0px" y="0px" viewBox="0 0 25.5 22.1" style="enable-background: new 0 0 25.5 22.1" xml:space="preserve"
            class="iCn replaced-svg">
            <style type="text/css">
              .st0 {
                fill: #4a6bb6;
              }
            </style>
            <path class="st0"
              d="M25.1,10l-9.5-9.5c-0.6-0.6-1.5-0.6-2.1,0c-0.6,0.6-0.6,1.5,0,2.1l7,7H0v3h20.4l-7,7c-0.6,0.6-0.6,1.5,0,2.1  c0.6,0.6,1.5,0.6,2.1,0l9.5-9.5C25.6,11.5,25.6,10.6,25.1,10z">
            </path>
          </svg>
        </button>
      </a>
    </div>
  
    <div *ngIf="showMoreObject.button" class="d-md-block flex-fill">
      <app-button (callbackAction)="callbackClick()" extraClass="blue_button float-end">
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none"
          class="iCn replaced-svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M13,7.3H7.3V13H5.7V7.3H0V5.7h5.7V0h1.6v5.7H13V7.3z"
            fill="#0C2B3E"></path>
        </svg>
        &nbsp;{{ showMoreObject.button }}
      </app-button>
    </div>

    <div *ngIf="showMoreObject.plain_button" class="d-md-block flex-fill em-2">
      <app-button (callbackAction)="callbackClick()" extraClass="blue_button float-end">
        &nbsp;{{ showMoreObject.plain_button }}
      </app-button>
    </div>
  </div>
</div>