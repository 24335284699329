import { Component, Input, OnInit } from "@angular/core";
import { Paginador } from "@shared/Interface/IPaginador.interface";
import { IProblematicasResponse } from "@shared/Interface/IProblematicasResponse";
import { PaginadorLibsComponent } from "@shared/libs/paginador.component";
import { DashboardService } from "@shared/service/Dashboard/dashboard.service";
import { UsuariosService } from "@shared/service/Usuarios/usuarios.service";
import { FeatureFlagService } from "app/featureModule/feature-flag.service";
import { environment } from "environments/environment";
import { lastValueFrom } from "rxjs";

@Component({
  selector: "app-revision",
  templateUrl: "./revision.component.html",
  styleUrls: ["./revision.component.css"],
})
export class RevisionComponent implements OnInit {
  @Input() estatusType: string;

  idSocioFormador: number;
  sidebarExpanded = false;
  lang: string = "es";
  numberPerPage = environment.RegistrosPagina;
  pages: number[] = [];
  paginaActual = 1;
  numeroPaginas = 0;
  problematicasList: IProblematicasResponse[] = [];
  problematicasListTotal: IProblematicasResponse[] = [];
  elementsTo: IProblematicasResponse[] = [];
  paginaActualMasResultados: number = 1;

  constructor(
    private _dashboardService: DashboardService,
    private paginadorLibs: PaginadorLibsComponent,
    private usuarioService: UsuariosService,
    private featureFlagService: FeatureFlagService
  ) {}

  async ngOnInit(): Promise<void> {

    this.lang = localStorage.getItem("lang") || "es";
    this.idSocioFormador = this.usuarioService.getCurrentUserData().idSocioFormador;

    await this.getProblematicas();

    this.getPaginacion();
    this.obtenerRegistrosActuales(this.paginaActual);
  }

  async getProblematicas() {
    try {
      let type = this.estatusType || "revision";
      let response = await lastValueFrom(
        this._dashboardService.getProblematicasSocio(
          this.idSocioFormador,
          type
        )
      );
      if (response != null && response != undefined && response.length > 0) {
        this.problematicasListTotal = response;
      }
    } catch (error) {
      console.log("Error al realizar la peticion", error);
    }
  }

  getPaginacion() {
    let paginador: Paginador = this.paginadorLibs.getPaginacion(
      this.problematicasListTotal.length,
      this.numberPerPage
    );
    this.numeroPaginas = paginador.totalPaginas;
    this.pages = paginador.paginas;
  }

  obtenerRegistrosActuales(numPagina: number) {
    this.problematicasList = this.paginadorLibs.obtenerRegistrosActuales(
      numPagina,
      this.numberPerPage,
      this.problematicasListTotal
    );
    this.paginaActual = numPagina;
    this.elementsTo = this.problematicasList;
    this.paginaActualMasResultados = numPagina;
  }

  obtenerMasResultados() {
    this.paginaActualMasResultados += 1;
    this.elementsTo = this.paginadorLibs.obtenerMasResultados(
      this.paginaActualMasResultados,
      this.numberPerPage,
      this.problematicasListTotal
    );
  }

  changeLang(lang: string) {
    this.lang = lang;
    localStorage.setItem("lang", lang);
    window.location.reload();
  }
  verDetalle(idSocio, idProblematica) {
    window.location.href = `/problematica-registrada?socio=${idSocio}&problematica=${idProblematica}`;
  }
}
