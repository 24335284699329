<div class="sec_ad">

    <app-title-show-more 
    [showMoreObject]="title"
  ></app-title-show-more>
  
     <div
      id="sec_tab"
      class="nav nav-pills d-flex flex-md-nowrap flex-wrap"
      role="presentation"
    >

    <div id="c_sl_pa" class="sec_cnt tipo-1 mt-4">
        <div class="d-none d-md-block">
      
          <div class="cont_tit d-flex">
            <div class="flex-fill">{{ "mis_retos.nombre_reto" | translate }}</div>
          </div>
      
          <div class="roundedsquare cont_fl d-flex" *ngFor="let el of conveniosData">
            <div class="flex-fill">
              
            </div>
            
          </div>
      
          <div *ngIf="conveniosData.length === 0">
            <app-sin-datos-table></app-sin-datos-table>
          </div>
      
        </div>
    </div>
      

  </div>
  
  
  
  