import { HttpClient, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, lastValueFrom, map } from 'rxjs';
import { IUsuariosSFResponse } from '@shared/Interface/IUsuariosSFResponse';
import { IUsuariosCVDPResponse } from '@shared/Interface/IUsuariosCVDPResponse';
import { IUsuariosAcademiaResponse } from '@shared/Interface/IUsuariosAcademiaResponse';
import { AccountService } from '../Account/account.service';
import { IUser, IUserAvisoPrivacidadResponse } from '@shared/Interface/user';
import { CookieService } from 'ngx-cookie-service';
import { IUsuarioGetResponse, UsuarioCVDPResponsableGetResponse, UsuarioGetResponse } from '@shared/Interface/IUsuarioGetResponse';
import {
  IdashboardUsuario,
  IDeletedUserResponse,
  IUsuarioResponse,
} from '@shared/Interface/IdashboardUsuario';
import { baseUrl, moduleService, subModuleService } from '../routesServices';
import { ISociosNotificacionesResponse } from '@shared/Interface/ISociosNotificacionesResponse';
import { MsalService } from '@azure/msal-angular';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

const { USUARIOS } = moduleService;
const { AUTOREGISTRO } = subModuleService;

@Injectable({
  providedIn: 'root',
})
export class UsuariosService {
  //** Esta clase maneja todo lo relacionado con el usuario en SociosFormadores */
  baseUrl = environment.apiUrl;
  currentUser!: IUser;

  queryFilter$ = new EventEmitter();

  constructor(
    private http: HttpClient,
    private accountService: AccountService,
    private cookieService: CookieService,
    private msalService: MsalService,
    private toastr : ToastrService,
    private route: Router

  ) {}

  /** Api que obtiene los usuarios socios formadores por orden
   *
   * @param orden parametro para saber el orden
   * @returns lista de objetos tipo IUsuariosSFResponse
   */
  getUsuariosSFByFiltro(orden: any): Observable<IUsuariosSFResponse[]> {
    return this.http.get<IUsuariosSFResponse[]>(
      this.baseUrl + 'usuarios/socios?orden=' + orden
    );
  }

  /** Api que obtiene los usuarios cvdp por orden
   *
   * @param orden parametro para saber el orden
   * @returns lista de objetos tipo IUsuariosCVDPResponse
   */
  getUsuariosCVDPByFiltro(orden: any): Observable<IUsuariosCVDPResponse[]> {
    return this.http.get<IUsuariosCVDPResponse[]>(
      this.baseUrl + 'usuarios/cvdp?orden=' + orden
    );
  }

  /** Api que obtiene los usuarios de academia por orden
   *
   * @param orden parametro para saber el orden
   * @returns lista de objetos tipo IUsuariosAcademiaResponse
   */
  getUsuariosAcademiaByFiltro(
    orden: any,
    filtro?: any,
    campus?: any,
    idEscuela?: any,
    avisoPrivacidad?:any
  ): Observable<IUsuariosAcademiaResponse[]> {
    return this.http.get<IUsuariosAcademiaResponse[]>(
      this.baseUrl + 'usuarios/academia?orden=' + orden + '&estatus=' + (filtro??'') + `&idCampus=${(campus??'0')}&idEscuela=${(idEscuela??'0')}&avisoPrivacidad=${avisoPrivacidad??'0'}`
    );
  }

  /** Obtiene los usuarios academicos de aceurdo a su campus y region
   *
   */
  getUsuariosAcademiaRegion(
    orden: any,
    filtro?: any,
    campus?: any,
    idEscuela?: any,
    avisoPrivacidad?:any,
    idRolAcademico?: any
  ): Observable<IUsuariosAcademiaResponse[]> {
    return this.http.get<IUsuariosAcademiaResponse[]>(
      this.baseUrl + `usuarios/academia/region?idCampus=${(campus??'0')}&idEscuela=${(idEscuela??'0')}&idRolAcademico=${idRolAcademico??'0'}`
    );
  }


  /**
   * Api que obtiene los datos del usuario por su ID
   * @param idUsuario indentifica a l usuario
   * @returns objeto tipo IUsuarioGetResponse
   */
  getUsuarioById(idUsuario: any): Observable<IUsuarioGetResponse> {
    return this.http.get<IUsuarioGetResponse>(
      this.baseUrl + 'usuarios/' + idUsuario
    ).pipe(map(response => {

      response.rolAcademico = (response.rolAcademico == null ? '': response.rolAcademico);
      response.apellidosUsuario = (response.apellidosUsuario == null ? '': response.apellidosUsuario);
      response.departamento = (response.departamento == null ? '': response.departamento);
      response.domicilio = (response.domicilio == null ? '': response.domicilio);
      response.nomina = (response.nomina == null ? '': response.nomina);
      response.escuelaDepartamento = (response.escuelaDepartamento == null ? '': response.escuelaDepartamento);

      return response;
    }));
  }

  getUsuario(username: string, registraLogin:boolean = false) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('username', username);
    queryParams = queryParams.append('login', registraLogin);
    return this.http.get<IdashboardUsuario>(`${baseUrl}${USUARIOS}`, {
      params: queryParams,
    });
  }


  /**
   * Api que regresa las notificaciones del usuario
   * @param idUsuario Identificador del Usuario
   * @returns Objeto tipo ISociosNotificacionesResponse
   */
   getNotificacionesById(idUsuario: any): Observable<ISociosNotificacionesResponse> {
    return this.http.get<ISociosNotificacionesResponse>(this.baseUrl + "usuarios/" + idUsuario + "/notificaciones");
  }

  /**
   * Api que regresa las notificaciones del usuario
   * @param idUsuario Identificador del Usuario
   * @returns Objeto tipo ISociosNotificacionesResponse
   */
   getNotificacionesByIdAcacemia(idUsuario: any): Observable<ISociosNotificacionesResponse> {
    return this.http.get<ISociosNotificacionesResponse>(this.baseUrl + "usuarios/academia/" + idUsuario + "/notificaciones");
  }

  /**
   * Api que regresa las notificaciones del usuario
   * @param idUsuario Identificador del Usuario
   * @returns Objeto tipo ISociosNotificacionesResponse
   */
   getNotificacionesByIdCVDP(idUsuario: any): Observable<ISociosNotificacionesResponse> {
    return this.http.get<ISociosNotificacionesResponse>(this.baseUrl + "usuarios/cvdp/" + idUsuario + "/notificaciones");
  }

  getCurrentUserData() {
    if(!this.cookieService.check('usrActual'))
    {
      return this.updateCurrentUserData(this.msalService.instance.getAllAccounts()[0].username);

    }
    else{
      return JSON.parse(this.cookieService.get('usrActual'));
    }

  }

  getCurrentUserEmail() {
    return this.cookieService.get('email');
  }

  logout() {
    this.accountService.logout();
  }

  putUsuarios(body: any): Observable<IUsuarioResponse> {
    return this.http.put<IUsuarioResponse>(`${baseUrl}${USUARIOS}`, body);
  }

  postUsuario(body: any): Observable<IUsuarioResponse> {
    return this.http.post<IUsuarioResponse>(`${baseUrl}${USUARIOS}`, body);
  }

  postUsuarioAutoRegistro(body: any): Observable<IUsuarioResponse> {
    return this.http.post<IUsuarioResponse>(`${baseUrl}${USUARIOS}/${AUTOREGISTRO}`, body);
  }

  async updateCurrentUserData(username) {
    let data = await  lastValueFrom(this.getUsuario(username));
    this.cookieService.delete('usrActual');
    this.cookieService.set('usrActual', JSON.stringify(data));
    return JSON.stringify(data);
  }

  delteUser(idUser: number): Observable<IDeletedUserResponse> {
    return this.http.delete<IDeletedUserResponse>(
      `${baseUrl}${USUARIOS}/${idUser}`
    );
  }

  putUserAvisoPrivacidad(idUser: number, body: any){
    return this.http.put<IUserAvisoPrivacidadResponse>(
      `${baseUrl}${USUARIOS}/${idUser}/avisoPrivacidad`, body
    );
  }

  putFotos(request:any):Observable<any>{
    return this.http.put(`${this.baseUrl}usuarios/fotos`, request);
  }

  putUsuarioActivacion(idUser:number, status: string, clave?: string ){
    return this.http.put(`${this.baseUrl}usuarios/academia/activaciones`,{idUsuario: idUser, claveEstatus: status, clave: clave});
  }

  updateCurrentObjectUserData(key, value){
    let userData = this.getCurrentUserData();
    userData[key] = `${value}?d=${Date.now()}`;
    this.cookieService.delete('usrActual');
    this.cookieService.set('usrActual', JSON.stringify(userData));
  }

  getUsuariosCVDPByCampus(orden:string, idCampus: number,  estatus:string, avisoPrivacidad:number): Observable<UsuarioGetResponse[]> {
    return this.http.get<UsuarioGetResponse[]>(`${this.baseUrl}usuarios/cvdp/campus/${idCampus}?orden=${orden}&estatus=${estatus}&avisoPrivacidad=${avisoPrivacidad}`);
  }

  getUsuariosCVDPByCampusResponsable(idCampus: number):Observable<UsuarioCVDPResponsableGetResponse[]>{
    return this.http.get<any>(`${this.baseUrl}catalogos/campus/${idCampus}/responsables`)
      .pipe(map(response => {
      let localCampus = response.campus;
      let localRegion = response.region;
      return response.responsables.map( x => ({
        nombres: x.nombres,
        apellidos: x.apellidos,
        email: x.email,
        idUsuario: x.idUsuario,
        urlFotoUsuario: x.urlFotoUsuario,
        campus: localCampus,
        region: localRegion
      }));

    }));
  }

  hasRole(sRol: string): boolean{
    let user = this.getCurrentUserData();
    return user.claveRoles.includes(sRol) || user.clavePerfil == sRol;
  }

  searchEmail(email: string):Observable<boolean>{
    return this.http.get<boolean>(`${this.baseUrl}usuarios?username=${email}&existe=1`)
  }

  enviarCodigoVerificacion(body){
    return this.http.post(`${this.baseUrl}usuarios/contrasena/verificacion`, body);
  }

  validaCodigo(body){
    return this.http.post(`${this.baseUrl}usuarios/contrasena/confirmacion`,body);
  }

  cambioClave(body){
    return this.http.post(`${this.baseUrl}usuarios/contrasena/cambio`, body);
  }


  async regitraIniciarSesion() {
    if (this.msalService.instance.getAllAccounts().length > 0) {
      try{

      let account = this.msalService.instance.getAllAccounts()[0];
      this.msalService.instance.setActiveAccount(account);

      // this.frmModal = new window.bootstrap.Modal(
      //   document.getElementById('idAvisoPrivacidadModal')
      // );

      // guardamos el userAccount del b2c
      localStorage.removeItem('b2c');
      localStorage.setItem('b2c', JSON.stringify(account));


      // ponemo la cookie del correo
      this.cookieService.delete('email');
      this.cookieService.set('email', account.username);

      // ponemos la cookie del usuario
      let data = await lastValueFrom(this.getUsuario(account.username, true))

          // Validar si el usuario esta activo
          if(data.claveEstatus !== 'activo'){
            Swal.fire( {
              title:'MPS - Usuario',
              text: 'Tu usuario no esta activo',
              timer: 3000
            })
              .then(() =>{
                this.accountService.logout();
              }
              );
              return;
          }
          this.cookieService.delete('usrActual');
          this.cookieService.set('usrActual', JSON.stringify(data));
          //this.userData = data;

          if (!data.avisoPrivacidad) {
            this.route.navigate(['/validasesion'])

          }

      }
        catch (error)  {
          this.accountService.logout();
          this.toastr.error('El usuario no tiene cuenta en el sistema');
        }

    }

  }

  getAcademicosxNivelGestion(idUsuario:number):Observable<any>{
    return this.http.get<any>(`${this.baseUrl}usuarios/academia/${idUsuario}/nivelGestion`);
  }

}
