<app-header-buscador [showSearchBar]="false"></app-header-buscador>

<app-btn-regresar></app-btn-regresar>
<app-title-show-more [showMoreObject]="{title:'gestion_socioformador.titulo' | translate} "></app-title-show-more>

<div id="sec_ad" class="mt-4">
    <div class="
      dashboard
      d-flex1
      align-items-md-stretch
      flex-wrap flex-md-nowrap
      sec_cnt
    ">
        <!-- Sección para el contacto principal de un Socio Formador -->
        <div class="col-12">

            <div id="sec_tab" class="nav nav-pills d-flex flex-md-nowrap flex-wrap" role="presentation">

                <div prm="re_hs" id="datosempresa" [ngClass]="{active: activeTab === 'datosempresa'}"
                    (click)="changetab('datosempresa')" data-bs-toggle="pill" aria-controls="datos-empresa"
                    class="active" role="tab" type="button" aria-selected="false">
                    Datos de Empresa
                </div>

                <div prm="re_hs" id="miscontactos" [ngClass]="{active: activeTab == 'miscontactos'}"
                    (click)="changetab('miscontactos')" data-bs-toggle="pill" aria-controls="datos-contacto" role="tab"
                    type="button" aria-selected="false">
                    Contactos
                </div>

                <div prm="re_hs" id="convenios" [ngClass]="{active: activeTab === 'convenios'}"
                    *ngIf="this.idSocio > 0"
                    (click)="changetab('convenios')" data-bs-toggle="pill" aria-controls="datos-empresa-convenios"
                    class="" role="tab" type="button" aria-selected="false">
                    Documentos
                </div>

            </div>

            <div class="tab-content" id="pills-tabContent">

                <!-- Sección de datos de empresa -->
                <div id="datos-empresa" class="tab-pane fade show active "
                    [ngClass]="{active: activeTab === 'datosempresa'}" role="tabpanel"
                    aria-labelledby="datos-empresa-tab">
                    <app-datos-empresa-tab (socioSeleccionado)="actualizarContactos($event)"
                        (socioCreado)="crearContacto($event)"></app-datos-empresa-tab>
                </div>

                <!-- Seccion de datos de contacto-->
                <div id="datos-contacto" class="tab-pane fade show" [ngClass]="{active: activeTab == 'miscontactos'}"
                    role="tabpanel" aria-labelledby="datos-contacto-tab">

                    <div>
                        <app-contactos-tab (callbackNewContact)="newContact()"
                            (callbackContacts)="editContactSelected($event)"
                            (callbackDeletedContact)="showContacts($event)" [contactsData]="contactsData"
                            [idSocioFormador]="idSocio" [userData]="mainContactData"
                            *ngIf="!editContact"></app-contactos-tab>
                    </div>

                    <app-perfil-contact *ngIf="editContact" [mainContactObj]="editContactData"
                        [newContact]="newContactCreate"
                        (callbackShowContacts)="showContacts($event)"></app-perfil-contact>

                </div>

                <!-- Sección de mis documentos -->
                <!-- Sección de convenio -->
                <div id="datos-empresa-convenios" class="tab-pane fade show "
                    [ngClass]="{active: activeTab === 'convenios'}" role="tabpanel" aria-labelledby="datos-empresa-tab">

                    <div class="row">

                        <!-- cartas convenio y colaboracion -->
                        <div class="row">
                            <div class="col-md-12">
                                <div>
                                    <!-- Listado de Convenio -->
                                    <span class="txtcard">
                                        <label class="subtitlecard d-none">{{
                                            "pagina_socio_formador_detalles.carta_solicitud_convenio" | translate
                                            }}</label>
                                            <div class="row d-none">
                                                <div class="col-12 float-end">
                                                    <button type="button" class="btn-blue" (click)="renovarConvenio()">Renovar Convenio</button>
                                                </div>
                                            </div>
                                        <app-table [backgroundColor]="backgroundTable" [columns]="convenioColumns"
                                            [data]="listCartaConvenio"></app-table>
                                    </span>

                                </div>

                                <!-- Listado de Cartas Colaboracion -->
                                <div>
                                    <label class="subtitlecard">{{ "pagina_socio_formador_detalles.colaboracion" |
                                        translate
                                        }}</label>
                                    <span class="txtcard">
                                        <app-table [backgroundColor]="backgroundTable" [columns]="colaboracionColumns"
                                            [data]="listCartaColaboracion"></app-table>

                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        </div>
    </div>

    <!-- Sección para el socio formador contacto -->
    <div *ngIf="false">
        <div class="offset-1 col-md-10 uno">
            <app-perfil-contact *ngIf="updateSocio" [mainContactObj]="mainContactData"
                [updateSocio]="updateSocio"></app-perfil-contact>
        </div>
    </div>

</div>


<!-- Modal de la carta solicitud -->
<ng-template #content>
    <div class="modal-header">

      <button type="button" class="btn-close" aria-label="Close"
      (click)="closeConvenio()"
      ></button>
    </div>
    <div class="modal-body">
      <app-convenio-viewer (idSocioFormador)="0" [showFilePDF]="filePdfFirmado"
      ></app-convenio-viewer>

    </div>
    <div class="modal-footer">
      <button type="button"
        class="btn btn-amarillo"
        (click)="downloadPDF()">Descargar PDF</button>
    </div>


  </ng-template>
