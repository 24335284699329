import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { ICartaConvenio } from "@shared/Interface/ICartaConvenio";
import { IContacto } from "@shared/Interface/IContacto.interface";
import { ISolicitudSocioComentarios } from "@shared/Interface/ISolicitudSocioComentarios";
import { IUser } from "@shared/Interface/user";
import {
  Column,
  optionsTextCol,
} from "@shared/components/table/table.component";
import { AzureBlobStorageService } from "@shared/service/AzureBlobStorage.Service";
import { ContactosService } from "@shared/service/Contactos/contactos.service";
import { InicioService } from "@shared/service/Inicio/inicio.service";
import { SociosFormadoresService } from "@shared/service/Socios_Formadores/socios-formadores.service";
import { SolicitudesService } from "@shared/service/Solicitudes/solicitudes.service";
import { UsuariosService } from "@shared/service/Usuarios/usuarios.service";
import { ToastrService } from "ngx-toastr";
import { lastValueFrom, tap } from "rxjs";
import Swal from "sweetalert2";
import { saveAs } from "file-saver";
import { NgbModal, NgbModalConfig } from "@ng-bootstrap/ng-bootstrap";
import Utils from "@shared/helpers/utils";



@Component({
  selector: "app-gestion-socioformador",
  templateUrl: "./gestion-socioformador.component.html",
  styleUrls: ["./gestion-socioformador.component.css"],
})
export class GestionSocioformadorComponent implements OnInit {
  @ViewChild("content") content: ElementRef;

  content_modal;

  userData: any;
  idUser: any;

  activeTab: string = "datosempresa";
  contactsData: any = [];
  mainContactData: any;
  editContact = false;
  newContactCreate = false;
  editContactData: any;
  updateSocio = false;
  idSocio: any;

  img: string = "";

  userStore: IUser;
  listCartaConvenio: any;
  listCartaColaboracion: any;

  backgroundTable = "#F6F6F6";

  userType: string;
  constructor(
    private contactosService: ContactosService,
    private router: Router,
    private route: ActivatedRoute,
    private usuariosService: UsuariosService,
    private inicio: InicioService,
    private socioFormadorService: SociosFormadoresService,
    private readonly fb: UntypedFormBuilder,
    private azureBlobStorage: AzureBlobStorageService,
    private toastr: ToastrService,
    private solicitudesService: SolicitudesService,
    private config: NgbModalConfig,
    private modalService: NgbModal
  ) {
    config.backdrop = "static";
    config.keyboard = false;
  }

  ngOnInit(): void {
    this.idUser = this.route.snapshot.paramMap.get("idUsuario");
    this.userType = this.route.snapshot.paramMap.get("userType") ?? "";

    if (sessionStorage.getItem("idSocioFormador_banco_b") === "true") {
      this.idSocio = Number(sessionStorage.getItem("idSocioFormador_banco"));
    }

    if (this.idSocio != 0) {
      this.getUserDataRequest(this.idSocio, false, false);
      this.updateSocio = true;
    }

    this.inicio.currentSubMenuOption.subscribe(
      (active) =>
        (this.activeTab = active == "miperfil" ? "datosempresa" : active)
    );
    this.getAllCartaConvenios();
    this.getAllCartaColaboracion();

    if (["cvdp", "cvdp_admin"].includes(this.getUserData().clavePerfil)) {
      this.convenioColumns[this.convenioColumns.length - 1].hide = false;
      this.colaboracionColumns[
        this.colaboracionColumns.length - 1
      ].hide = false;
    }
  }

  actualizarContactos(idSocioFormador) {
    this.idSocio = idSocioFormador;
    this.getUserDataRequest(idSocioFormador, false, false);
    this.updateSocio = true;
  }

  getUserData() {
    this.userData = this.usuariosService.getCurrentUserData();
    if (!this.userData) this.router.navigate(["/"]);
    return this.userData;
  }

  getMainContact(contacts: IContacto[]) {
    return contacts
      .filter((contact) => contact.contactoPrincipal)
      .map((contact) => ({
        ...contact,
        img: "avatar_sin",
        isContact: contact.contactoPrincipal,
      }));
  }

  getContacts(contacts: IContacto[]) {
    return (
      contacts
        //.filter((contact) => !contact.contactoPrincipal)
        .map((contact) => ({
          ...contact,
          img: "avatar_sin",
          nombre: `${contact.nombre}`,
          isContact: contact.contactoPrincipal,
        }))
    );
  }

  async getUserDataRequest(idSocio, isMainUser = false, urlParams = false) {

    if(this.idSocio == 0){
      this.contactsData = [];
      return;
    }
    this.contactosService.getContactos(idSocio, isMainUser).subscribe({
      next: (resultado: IContacto[]) => {
        this.contactsData = this.getContacts(resultado);
      },
      error: (err) => {
        this.contactsData = [];
      },
    });
  }

  editContactSelected(contactObj: any) {
    this.editContact = true;
    this.newContactCreate = false;
    this.editContactData = contactObj;
  }

  async showContacts(event) {
    await this.getUserDataRequest(event.idSocioFormador ?? this.idSocio, false, true);
    this.editContact = false;
  }

  newContact() {
    this.editContact = true;
    this.newContactCreate = true;
  }

  changetab(tab: string) {
    this.editContact = false;
    this.inicio.changeSubMenuItem(tab);
  }

  esAcademico() {
    if (this.getUserData().clavePerfil == "academia") return true;
    return false;
  }

  crearContacto(idSocioFormador) {
    this.idSocio = idSocioFormador;
    this.changetab("miscontactos");
    this.newContact();
  }

  getEstatus(status: string, fechaFin: string) {
    if (status) {
      return status;
    }

    let fecHoy = new Date();
    let fecFin = new Date(fechaFin);

    // Fecha inicio menor a fecha fin
    if (fecHoy > fecFin) return "Vencido";
    return "Vigente";
  }
  getAllCartaConvenios() {
    let userStore = this.usuariosService.getCurrentUserData();

    if(this.idSocio == 0){
      return;
    }

    this.listCartaConvenio = [];
    this.socioFormadorService.getCartaConvenio(this.idSocio).subscribe({
      next: (res: ICartaConvenio[]) => {
        let listado;
        if (["academia"].includes(userStore.clavePerfil)) {
          listado = res.pop() ?? {};
          this.listCartaConvenio.push({
            fechaInicio: listado.fechaInicio,
            fechaTermino: listado.fechaFin,
            personaFirma: {
              title: listado.personaFirma,
              subtitle: listado.correo ?? "",
            },
            estatus: this.getEstatus(listado.estatus, listado.fechaFin),
            link: {
              link: listado.urlCartaConvenio,
              title: `Convenio ${listado.fechaInicio} ${listado.fechaFin}`,
              openNewTab: false,
              objParams:{
                urlCarta: listado.urlCartaConvenio
              },
              callbackAction:(objParams) => {
                this.verDocumento(objParams.urlCarta);
              }
            },
          });
        } else {
          listado = res;
          this.listCartaConvenio = listado.map((x) => ({
            fechaInicio: x.fechaInicio,
            fechaTermino: x.fechaFin,
            personaFirma: {
              title: x.personaFirma,
              subtitle: x.correo ?? "",
            },
            estatus: this.getEstatus(x.estatus, x.fechaFin),
            link: {
              link: x.urlCartaConvenio,
              title: `Convenio ${x.fechaInicio} ${x.fechaFin}`,
              openNewTab: false,
              objParams:{
                urlCarta: x.urlCartaConvenio
              },
              callbackAction:(objParams) => {
                this.verDocumento(objParams.urlCarta);
              }
            },
            buttons: [
              {
                className: "delete",
                objParams: x.idConvenio,
                callbackAction: (idConvenio) => {
                  this.eliminarCartaConvenio(idConvenio);
                },
              },
            ],
          }));
        }
      },
      error: (err: any) => {
        Utils.processErrorResponse(err);
      },
    });
  }

  getAllCartaColaboracion() {
    let userStore = this.usuariosService.getCurrentUserData();

    this.listCartaColaboracion = [];
    if(this.idSocio == 0){
      return;
    }

    this.socioFormadorService.getCartaColaboracion(this.idSocio).subscribe({
      next: (resOk) => {
        let listado;

        if (["academia"].includes(userStore.clavePerfil)) {
          listado = resOk.pop() ?? {};
          this.listCartaColaboracion.push({
            fechaInicio: listado.fechaFirma,
            contactPerson: {
              title: listado.nombreContacto ?? "",
              subtitle: listado.correoContacto ?? "",
            },
            personaFirma: {
              title: listado.nombreAcademico,
              subtitle: listado.correo ?? "",
            },
            reto: listado.reto,
            link: {
              title: `Carta Colaboración ${listado.fechaFirma.substring(
                0,
                10
              )}`,
              link: listado.urlCartaColaboracion,
              openNewTab: false,
              objParams:{
                urlCarta: listado.urlCartaColaboracion
              },
              callbackAction:(objParams) => {
                this.verDocumento(objParams.urlCarta);
              }
            },
          });
        } else {
          listado = resOk;
          this.listCartaColaboracion = listado.map((x) => ({
            fechaInicio: x.fechaFirma,
            contactPerson: {
              title: listado.nombreContacto ?? "",
              subtitle: listado.correoContacto ?? "",
            },
            personaFirma: {
              title: x.nombreAcademico,
              subtitle: x.correo ?? "",
            },
            reto: x.reto,
            link: {
              title: `Carta Colaboración ${x.fechaFirma.substring(0, 10)}`,
              link: x.urlCartaColaboracion,
              openNewTab: false,
              objParams:{
                urlCarta: x.urlCartaColaboracion
              },
              callbackAction:(objParams) => {
                this.verDocumento(objParams.urlCarta);
              }
            },
            buttons: [
              {
                className: "delete",
                objParams: x.idColaboracion,
                callbackAction: (idCartaColaboracion) => {
                  this.eliminarCartaColaboracion(idCartaColaboracion);
                },
              },
            ],
          }));
        }
      },
      error: (err:any) => {
        Utils.processErrorResponse(err);
      },
    });
  }

  renovarConvenio() {
    Swal.fire({
      title: "¿Renovar Convenio?",
      text:
        "¿Desea renovar el convenio con el Socio Formador?, al hacerlo se debe hacer el proceso para cargar o generar un nuevo Convenio.",
      confirmButtonText: "Generar",
      cancelButtonText: "Cancelar",
      showCancelButton: true,
      buttonsStyling: false,
      customClass: {
        confirmButton: "btn-amarillo order-2 mx-1",
        cancelButton: "btn-brown order-1 mx-1",
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        // Cambiamos el estatus a revisión, y agregamos un comentario

        let cveEstatus: string = "revision";
        let cveEstatusSeguimiento: string = "conversacion";
        let comentarios: string;
        comentarios = `Convenio Vencido, inicia proceso de Regeneración de Convenio por ${
          this.usuariosService.getCurrentUserData().nombreUsuario
        } el ${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()}`;

        let idSocioFormador = this.idSocio;
        let idUsuario = this.usuariosService.getCurrentUserData().idUsuario;

        let request: ISolicitudSocioComentarios = {
          idSocioFormador: idSocioFormador,
          claveEstatus: cveEstatus,
          claveEstatusSeguimiento: cveEstatusSeguimiento,
          comentarios: comentarios,
          idUsuarioRegistro: idUsuario,
        };

        await lastValueFrom(
          this.solicitudesService.postSolicitudSFP_comentarios(request)
        );

        Swal.fire({ title: "Actualización exitosa", icon: "success" });
      }
    });
  }

  verDocumento(doc){
    this.filePdfFirmado = doc;
    this.content_modal = this.modalService.open(this.content, { size: "xl" });
  }

  closeConvenio() {
    this.content_modal.close();
  }

  downloadPDF() {
    //let nombre = this.filePdfFirmado.split("/")[4];
    saveAs(this.filePdfFirmado, "Carta.pdf");
  }

  eliminarCartaConvenio(idConvenio: number) {
    let idUsuario = this.usuariosService.getCurrentUserData().idUsuario;
    Swal.fire({
      title: "Convenios",
      text: "¿Desea eliminar el convenio?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Eliminar",
    }).then((result) => {
      if (result.isConfirmed) {
        this.socioFormadorService
          .deleteCartaConvenio(idConvenio, idUsuario)
          .pipe(
            tap((resultado: any) => {
              if (resultado) {
                Swal.fire(
                  "Socio Formador - Convenio",
                  "Convenio eliminado.",
                  "success"
                );
                this.getAllCartaConvenios();
              } else {
                this.toastr.error("Algo Salió mal!");
              }
            })
          )
          .subscribe();
      }
    });
  }

  eliminarCartaColaboracion(idCartacolaboracion: number) {
    let idUsuario = this.usuariosService.getCurrentUserData().idUsuario;
    Swal.fire({
      title: "Carta Colaboración",
      text: "¿Desea eliminar la carta de colaboración?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Eliminar",
    }).then((result) => {
      if (result.isConfirmed) {
        this.socioFormadorService
          .deleteCartaColaboracion(idCartacolaboracion, idUsuario)
          .pipe(
            tap((resultado: any) => {
              if (resultado) {
                Swal.fire(
                  "Socio Formador - colaboración",
                  "Carta colaboración eliminada.",
                  "success"
                );
                this.getAllCartaColaboracion();
              } else {
                this.toastr.error("Algo Salió mal!");
              }
            })
          )
          .subscribe();
      }
    });
  }

  filePdfFirmado: string;

  convenioColumns: Column[] = [
    {
      title: "Fecha de inicio",
      sizeCol: "2",
      classType: "dashbd_rows_thin justify-content-md-center",
      typeColumn: optionsTextCol.date,
      key: "fechaInicio",
      headerClassName: "dashbd_header",
    },
    {
      title: "Fecha de Término",
      sizeCol: "2",
      classType: "dashbd_rows_thin justify-content-md-center",
      typeColumn: optionsTextCol.date,
      key: "fechaTermino",
      headerClassName: "dashbd_header",
    },
    {
      title: "Persona que firma",
      sizeCol: "2",
      classType: "dashbd_rows_thin justify-content-md-center",
      typeColumn: optionsTextCol.imageTitle,
      key: "personaFirma",
      headerClassName: "dashbd_header",
    },
    {
      title: "Documento",
      sizeCol: "3",
      classType: "dashbd_rows_thin justify-content-md-center",
      typeColumn: optionsTextCol.link,
      key: "link",
      headerClassName: "dashbd_header",
    },
    {
      title: "Estatus",
      sizeCol: "2",
      classType: "dashbd_rows_thin justify-content-md-center",
      typeColumn: optionsTextCol.text,
      key: "estatus",
      headerClassName: "dashbd_header",
    },
    {
      title: "Acciones",
      sizeCol: "1",
      classType: "dashbd_rows_thin justify-content-md-center",
      typeColumn: optionsTextCol.buttons,
      key: "buttons",
      headerClassName: "dashbd_header",
      hide: true,
    },
  ];

  colaboracionColumns: Column[] = [
    {
      title: "Fecha de firma",
      sizeCol: "2",
      classType: "dashbd_rows_thin justify-content-md-center",
      typeColumn: optionsTextCol.date,
      key: "fechaInicio",
      headerClassName: "dashbd_header",
    },
    {
      title: "Mi contacto que firma",
      sizeCol: "2",
      classType: "dashbd_rows_thin justify-content-md-center",
      typeColumn: optionsTextCol.imageTitle,
      key: "contactPerson",
      headerClassName: "dashbd_header",
    },
    {
      title: "Académico que firma",
      sizeCol: "2",
      classType: "dashbd_rows_thin justify-content-md-center",
      typeColumn: optionsTextCol.imageTitle,
      key: "personaFirma",
      headerClassName: "dashbd_header",
    },
    {
      title: "Reto",
      sizeCol: "2",
      classType: "dashbd_rows_thin justify-content-md-center",
      typeColumn: optionsTextCol.text,
      key: "reto",
      headerClassName: "dashbd_header",
    },
    {
      title: "Documento",
      sizeCol: "3",
      classType: "dashbd_rows_thin justify-content-md-center",
      typeColumn: optionsTextCol.link,
      key: "link",
      headerClassName: "dashbd_header",
    },
    {
      title: "Acciones",
      sizeCol: "1",
      classType: "dashbd_rows_thin justify-content-md-center",
      typeColumn: optionsTextCol.buttons,
      key: "buttons",
      headerClassName: "dashbd_header",
      hide: true,
    },
  ];
}
