<app-header-buscador lbl_placeholder="Buscar ... " (fatherNameFunction)="buscadorPress($event)"></app-header-buscador>


<div>
  <div class="sec_ad">
    <app-title-show-more [showMoreObject]="titleSociosFormadores"></app-title-show-more>

    <!-- Filters -->
    <div class="row">

      <!-- Filtro de Periodo Semestral -->
      <div class="col-md">
        <div class="btn-group order-by">
          <button class="btn dropdown-toggle" type="button" id="listado" data-bs-toggle="dropdown"
            data-bs-auto-close="true" aria-expanded="false" data-bs-offset="0,0"
            title="{{ filter.idPeriodoSemestral ? getValueCatalogo(periodoSemestralData, 'idPeriodoSemestral', filter.idPeriodoSemestral, 'periodoSemestral') : 'Periodo Semestral' }}">
            {{ filter.idPeriodoSemestral ? getValueCatalogo(periodoSemestralData, 'idPeriodoSemestral', filter.idPeriodoSemestral,
            'periodoSemestral') : 'Periodo Semestral' }}
          </button>
          <ul class="dropdown-menu dropdown-menu-start" aria-labelledby="listado">
            <li *ngFor="let periodo of periodoSemestralData; let i = index">
              <a class="dropdown-item {{ i === 0 ? 'active' : '' }}"
                (click)="addFilter('idPeriodoSemestral', periodo.idPeriodoSemestral)">{{
                periodo.periodoSemestral }}</a>
            </li>
          </ul>
        </div>
      </div>

      <!-- Filtro de Periodo Semanal -->
      <div class="col-md">
        <div class="btn-group order-by">
          <button class="btn dropdown-toggle" type="button" id="listado" data-bs-toggle="dropdown"
            data-bs-auto-close="true" aria-expanded="false" data-bs-offset="0,0"
            title="{{ filter.idPeriodoSemanal ? getValueCatalogo(periodoSemanalData, 'idPeriodoSemanal', filter.idPeriodoSemanal, 'periodoSemanal') : 'Periodo Semanal' }}">
            {{ filter.idPeriodoSemanal ? getValueCatalogo(periodoSemanalData, 'idPeriodoSemanal', filter.idPeriodoSemanal,
            'periodoSemanal') : 'Periodo Semanal' }}
          </button>
          <ul class="dropdown-menu dropdown-menu-start" aria-labelledby="listado">
            <li *ngFor="let periodo of periodoSemanalData; let i = index">
              <a class="dropdown-item {{ i === 0 ? 'active' : '' }}"
                (click)="addFilter('idPeriodoSemanal', periodo.idPeriodoSemanal)">{{
                periodo.periodoSemanal }}</a>
            </li>
          </ul>
        </div>
      </div>

      <!-- Filtro de Departamento -->
      <div class="col-md">
        <div class="btn-group order-by">
          <button class="btn dropdown-toggle" type="button" id="listado" data-bs-toggle="dropdown"
            data-bs-auto-close="true" aria-expanded="false" data-bs-offset="0,0"
            title="{{ filter.departamento ? getValueCatalogo(departamentoData, 'idDepartamento', filter.departamento, 'departamento') : 'Departamento' }}">
            {{ filter.departamento ? getValueCatalogo(departamentoData, 'idDepartamento', filter.departamento,
            'departamento') : 'Departamento' }}
          </button>
          <ul class="dropdown-menu dropdown-menu-start" aria-labelledby="listado">
            <li *ngFor="let departamento of departamentoData; let i = index">
              <a class="dropdown-item {{ i === 0 ? 'active' : '' }}"
                (click)="addFilter('departamento', departamento.idDepartamento)">{{
                departamento.departamento }}</a>
            </li>
          </ul>
        </div>
      </div>


      <!-- Reestablecer Filtros -->
      <div class="col-md d-flex justify-content-end">
        <app-button (callbackAction)="cleanFilters()"> Restablecer </app-button>
      </div>
    </div>

    <!-- Table results -->
    <div id="sec_tab" class="nav nav-pills d-flex flex-md-nowrap flex-wrap" role="presentation">

      <div class="active" data-bs-toggle="pill" data-bs-target="#tab-socios-formadores-aprobar"
        aria-controls="tab-socios-formadores-aprobar" prm="re_ac" role="tab" type="button" aria-selected="true">
        Solicitudes por aprobar
      </div>
      <div prm="re_hs" data-bs-toggle="pill" data-bs-target="#tab-socios-formadores-aceptadas"
        aria-controls="tab-socios-formadores-aceptadas" class="" role="tab" type="button" aria-selected="false">
        Solicitudes aceptadas
      </div>
      <div prm="re_hs" data-bs-toggle="pill" data-bs-target="#tab-socios-formadores-rechazadas"
        aria-controls="tab-socios-formadores-rechazadas" class="" role="tab" type="button" aria-selected="false">
        Solicitudes rechazadas
      </div>

    </div>

    <div class="tab-content" id="pills-tabContent">

      <div class="tab-pane fade show active" id="tab-socios-formadores-aprobar" role="tabpanel"
        aria-labelledby="tab-socios-formadores-aprobar-tab">
        <app-table [backgroundColor]="backgroundTable" [columns]="sociosFormadoresColumns"
          [data]="sociosFormadoresDataPage"></app-table>

        <app-pagination *ngIf="sociosFormadoresData.length"
          [objPagination]="sociosFormadoresData"
          (paginationDataChange)="showPaginationDataAprobar($event)">
        </app-pagination>

      </div>
      <div class="tab-pane fade" id="tab-socios-formadores-aceptadas" role="tabpanel"
        aria-labelledby="tab-socios-formadores-aceptadas-tab">
        <app-table [backgroundColor]="backgroundTable" [columns]="sociosFormadoresColumns"
          [data]="sociosFormadoresAceptadasDataPage"></app-table>

        <app-pagination *ngIf="sociosFormadoresAceptadasData.length"
          [objPagination]="sociosFormadoresAceptadasData"
          (paginationDataChange)="showPaginationDataAceptada($event)">
        </app-pagination>
      </div>
      <div class="tab-pane fade" id="tab-socios-formadores-rechazadas" role="tabpanel"
        aria-labelledby="tab-socios-formadores-rechazadas-tab">
        <app-table [backgroundColor]="backgroundTable" [columns]="sociosFormadoresColumns"
          [data]="sociosFormadoresRechazadoaDataPage"></app-table>

        <app-pagination *ngIf="sociosFormadoresRechazadoaData.length"
          [objPagination]="sociosFormadoresRechazadoaData"
          (paginationDataChange)="showPaginationDataRechazada($event)">
        </app-pagination>
      </div>

    </div>
  </div>

</div>
