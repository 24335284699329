<div class="row fondocard_h">
    <div class="row">
        <div class="col-md-4">
            <img alt="Reto" class="imgcarrousel_izq" src="assets/img/{{ retosObj.img }}.jpg" />
        </div>
        <div class="col-md-5">
            <div class="row">
                <div class="col colmatch">
                    <span class="titsans">{{ retosObj.reto }}</span><br />
                    <span class="subtit1col">UF: {{ retosObj.carrera }}</span>
                </div>
                <div class="row">
                    <div class="col colmatch row_spacer3">
                        <div class="row progwidth">
                            <div class="col">
                                <div class="row">{{ retosObj.match }}% Match</div>
                            </div>
                            <div class="progress" style="height: 10px">
                <div
                  class="progress-bar bg-info"
                  role="progressbar"
                  attr.style="width: {{ retosObj.match }}%"
                  attr.aria-valuenow="{{ retosObj.match }}"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col clearfix">
                        <br />
                        <div *ngFor="let chip of retosObj.chipData">
                            <app-chip-item [chipObject]="chip"></app-chip-item>
                            <br />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-3 align-self-end">
            <app-button buttonType="yell">Más información</app-button>
        </div>
    </div>
</div>