import { Column, optionsTextCol } from "@shared/components/table/table.component";

export const COLS_RETOS_QUIERO_PARTICIPAR: Column[] = [
    {
      title: 'Fecha',
      sizeCol: '1',
      classType: 'dashbd_rows_img',
      typeColumn: optionsTextCol.date,
      key: 'fechaSolicitud',
    },
    {
      title: 'Nombre del Reto',
      sizeCol: '4',
      classType: 'dashbd_rows_img',
      typeColumn: optionsTextCol.imageTitle,
      key: 'reto',
    },
    {
      title: 'Duración',
      sizeCol: '1',
      classType: 'dashbd_rows_img',
      typeColumn: optionsTextCol.text,
      key: 'duracion',
    },
    {
      title: 'Periodo Semanal',
      sizeCol: '1',
      classType: 'dashbd_rows_img',
      typeColumn: optionsTextCol.text,
      key: 'periodoSemanal',
    },
    {
      title: 'Periodo Semestral',
      sizeCol: '1',
      classType: 'dashbd_rows_img',
      typeColumn: optionsTextCol.text,
      key: 'periodoSemestral',
    },
    {
      title: 'Campus Solicitados',
      sizeCol: '2',
      classType: 'dashbd_rows_img',
      typeColumn: optionsTextCol.text,
      icon: true,
      key: 'campusCoordinador',
    },
    {
      title: 'Estatus',
      sizeCol: '1',
      classType: 'dashbd_rows_img',
      typeColumn: optionsTextCol.status,
      key: 'status',
    },
    {
      title: "Acciones",
      sizeCol: "1",
      classType: "dashbd_rows_img justify-content-md-center",
      typeColumn: optionsTextCol.status,
      key: "claveEstatusSolicitud",
    },
  ];

  export const COLS_MIS_RETOS: Column[] = [
    {
      title:'Fecha',
      sizeCol: '1',
      classType: 'dashbd_rows_img justify-content-md-center',
      typeColumn: optionsTextCol.date,
      key: 'fechaRegistro'
    },
    {
      title: 'Nombre del Reto',
      sizeCol: '3',
      classType: 'dashbd_rows_img',
      typeColumn: optionsTextCol.imageTitle,
      key: 'proyecto',
    },
    {
      title: 'Contacto',
      sizeCol: '2',
      classType: 'dashbd_rows_img',
      typeColumn: optionsTextCol.imageTitle,
      key: 'contactPerson',
    },
    {
      title: 'Duración',
      sizeCol: '1',
      classType: 'dashbd_rows_img',
      typeColumn: optionsTextCol.text,
      key: 'duracionSemanas',
    },
    {
      title: 'Periodo Semanal',
      sizeCol: '1',
      classType: 'dashbd_rows_img',
      typeColumn: optionsTextCol.text,
      key: 'periodoSemanal',
    },
    {
      title: 'Periodo Semestral',
      sizeCol: '1',
      classType: 'dashbd_rows_img',
      typeColumn: optionsTextCol.text,
      key: 'periodoSemestral',
    },
    
    {
      title: 'Campus',
      sizeCol: '2',
      classType: 'dashbd_rows_img',
      typeColumn: optionsTextCol.text,
      key: 'campusCoordinador',
    },
    {
      title: "Acciones",
      sizeCol: "1",
      classType: "dashbd_rows_img justify-content-md-center",
      typeColumn: optionsTextCol.status,
      key: "claveEstatusSolicitud",
    },
    /*
{
      title: 'Avance',
      sizeCol: '1',
      classType: 'dashbd_rows_prog',
      typeColumn: optionsTextCol.progress,
      key: 'porcentajeAvance',
    },
  */
  ];

  export const COLS_PROBLEMATICAS: Column[] = [
    {
      title: 'Fecha',
      sizeCol: '1',
      classType: 'dashbd_rows_img justify-content-md-center',
      typeColumn: optionsTextCol.date,
      key: 'fechaRegistro',
    },
    {
      title: 'Nombre de la Problemática',
      sizeCol: '3',
      classType: 'dashbd_rows_img justify-content-md-center',
      typeColumn: optionsTextCol.link,
      key: 'proyecto',
    },
    {
      title: 'Contacto',
      sizeCol: '2',
      classType: 'dashbd_rows_img justify-content-md-center',
      typeColumn: optionsTextCol.imageTitle,
      key: 'contactPerson',
    },
    {
      title: 'Escuela',
      sizeCol: '1',
      classType: 'dashbd_rows_img justify-content-md-center',
      typeColumn: optionsTextCol.text,
      key: 'alcance',
    },
    {
      title: 'Programa Académico',
      sizeCol: '2',
      classType: 'dashbd_rows_img justify-content-md-center',
      typeColumn: optionsTextCol.text,
      key: 'programaAcademico',
    },
    {
      title: 'Semestre',
      sizeCol: '1',
      classType: 'dashbd_rows_img justify-content-md-center',
      typeColumn: optionsTextCol.text,
      key: 'semestre',
    },
    {
      title: 'Campus',
      sizeCol: '1',
      classType: 'dashbd_rows_img justify-content-md-center',
      typeColumn: optionsTextCol.text,
      key: 'campus',
    },
    {
      title: "Acciones",
      sizeCol: "1",
      classType: "dashbd_rows_img justify-content-md-center",
      typeColumn: optionsTextCol.status,
      key: "claveEstatusSolicitud",
    },
  ];

  export const COLS_ACADEMICO_ME_BUSCA: Column[] = [
    {
      title: 'Fecha',
      sizeCol: '1',
      classType: 'dashbd_rows_img justify-content-md-center',
      typeColumn: optionsTextCol.date,
      key: 'fechaRegistro',
    },
    {
      title: 'Académico, Correo y Campus',
      sizeCol: '3',
      classType: 'dashbd_rows_img justify-content-md-center',
      typeColumn: optionsTextCol.imageTitle,
      key: 'contactPerson',
    },
    {
      title: 'Reto a vincular y Escuela',
      sizeCol: '3',
      classType: 'dashbd_rows_img justify-content-md-center',
      typeColumn: optionsTextCol.imageTitle,
      key: 'unidadFormativa',
    },
    {
      title: 'Periodo Semestral',
      sizeCol: '2',
      classType: 'dashbd_rows_img justify-content-md-center',
      typeColumn: optionsTextCol.text,
      key: 'periodoSemestral',
    },
    {
      title: 'Periodo Semanal',
      sizeCol: '2',
      classType: 'dashbd_rows_img justify-content-md-center',
      typeColumn: optionsTextCol.text,
      key: 'periodoSemanal',
    },
    {
      title: 'Acciones',
      sizeCol: '1',
      classType: 'dashbd_rows_img justify-content-md-center',
      typeColumn: optionsTextCol.status,
      key: 'claveEstatusSolicitud',
    },
  ];