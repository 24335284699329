<app-header></app-header>
<div class="container">
    <div class="hero-2 row">
        
        <div class="hero-izq col-6 px-0 ">
            <div class="hero-texto1"> Registro de Docentes</div>
            <div class="hero-texto2"> Crea una cuenta de académico del Tec de Monterrey para que puedas buscar Socios Formadores y llevar registro de los retos de las Unidades Formativas en las que participas.
            </div>    
        </div>
        <div class="hero-der col-6 ">
            <app-academico-perfil-registro></app-academico-perfil-registro>
        </div>
    </div>

    <app-footer></app-footer>
</div>