import { Component, OnInit } from "@angular/core";
import { CasosDeExitoService } from "@shared/service/Casos_de_Exito/casos-de-exito.service";
import { ICasosExito } from "@shared/Interface/ICasosExito";
import { lastValueFrom, map, tap } from "rxjs";
import { IEscuela } from "@shared/Interface/ICatalogos.interface";

@Component({
  selector: "app-homesf",
  templateUrl: "homesf.component.html",
  styleUrls: ["./homesf.component.css"],
})
export class HomesfComponent implements OnInit {
  casosList: ICasosExito[] = [];
  showModalBox: boolean = false;
  detalle: any;

  listadoEscuelasData: IEscuela[];
  escuelasData:any[];
  // model del ddl que muestra las escuelas
  ngEscuelaDefault;
  // Database de los casos de exito
  escuelaSeleccionada = {regs3:[]};

  slides = [
    { logoHome: "/assets/img/Beck_Logo.png" },
    { logoHome: "/assets/img/BMW_Logo.png" },
    { logoHome: "/assets/img/Henkel_Logo.png" },
    { logoHome: "/assets/img/P_G_Logo.png" },
  ];
  
  slideConfig = {
    slidesToShow: 4,
    slidesToScroll: 4,
    arrows: true,
    dots: true,
    variableWidth:false,
    centerMode: false,
    responsive: [
      {
        breakpoint: 570,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          dots: true,
          infinite: true,
        },
      },
    ],
  };

  lang: string = "es";
  constructor(
    private _casosService: CasosDeExitoService
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.lang = localStorage.getItem("lang") ?? "es";
    await this.getCasosDeExito();
    this.getLogosHome();
  }

  procesarResultados(response) {
    // Variable para agrupar por las escuelas
    const escuelas: any = [];

    // Proceso para separar las escuelas
    response.forEach((element) => {
      let escuela = escuelas.find(
        (x: ICasosExito) => x.idEscuela == element.idEscuela
      );
      if (!escuela) {
        escuelas.push({
          idEscuela: element.idEscuela,
          escuela: element.escuela,
          registros: [element],
        });
      } else {
        escuela.registros.push(element);
      }
    });

    // Proceso para separar las rebanadas
    escuelas.forEach((escuela) => {
      // tamaño de la rebanada
      let size = 3;
      let arrayOfArrays = escuela.registros;

      // generamos propiedad
      escuela.regs3 = [];

      for (let i = 0; i < arrayOfArrays.length; i += size) {
        let chunckedArray = arrayOfArrays.slice(i, i + size);
        escuela.regs3.push(chunckedArray);
      }
    });

    return escuelas;
  }

  procesarEscuelas(escuelas){
    // obtener listado de escuelas:
    let temp = escuelas.map((x) => {
      return {
        idEscuela: x.idEscuela,
        escuela: x.escuela,
      };
    });

    return temp;

  }

  
  async getCasosDeExito() {
    let estatus = "activo";

    try {
      let response = <ICasosExito[]>(
        await lastValueFrom(
          this._casosService
            .getCasosEstatus(estatus)
            .pipe(map((x) => x.filter((y) => y.publicadoHome)))
        )
      );

      let escuelas = this.procesarResultados(response);

      this.listadoEscuelasData = this.procesarEscuelas(escuelas);

      this.escuelasData = escuelas;

      
      // Seleccionar la primer escuela
      this.ngEscuelaDefault = escuelas[0].idEscuela ?? 0;
      this.escuelaSeleccionada = escuelas[0] ?? {};
    

    } catch (error) {
      console.log(error);
    }
  }

  openModal(caso: any) {
    this.detalle = caso;
    this.showModalBox = true;
  }

  mostrar(escuela) {
    this.ngEscuelaDefault = escuela.idEscuela; 
    this.escuelaSeleccionada = this.escuelasData.find( x => x.idEscuela == escuela.idEscuela);
  }

 
  getLogosHome(){
    this._casosService
    .getLogosHome()
         .pipe(
           tap((resultado: any) => {
            this.slides = resultado;
           })
         )
         .subscribe();
     }
   

}
