<app-header-buscador [showSearchBar]="false"></app-header-buscador>

<div class="sec_ad">
    <app-title-show-more [showMoreObject]="titleSolicitud"></app-title-show-more>
    <div>
      <button class="dashboard_btn d-md-block">
        <svg
          width="12"
          height="24"
          viewBox="0 0 12 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.98157 24L0.968321 14.48C0.348159 13.8228 0 12.9333 0 12.006C0 11.0787 0.348159 10.1892 0.968321 9.532L9.992 0L12 2.121L2.97632 11.652C2.88746 11.7458 2.83753 11.8729 2.83753 12.0055C2.83753 12.1381 2.88746 12.2652 2.97632 12.359L11.9886 21.879L9.98157 24Z"
            fill="#4A6BB6"
          />
        </svg>
        <span>&nbsp;Regresar</span>
      </button>
    </div>
    <div class="row mt-5">
      <div class="d-flex align-items-center">
        <span class="titsans"
          >Inteligencia de mercado para la generación de conocimiento</span
        >
      </div>
    </div>
    <div class="row mt-3">
      <div class="d-flex align-items-center row_spacer">
        <span class="subtit1col">UF: Cultura Financiera</span>
      </div>
    </div>
    <div class="row">
      <div class="col row_spacer">
        <span class="subtit1col"><strong>Profesor que solicita:</strong></span
        ><br /><br />
        <div class="row">
          <div class="col-3 atomic_spc">
            <img class="img_avt_mn" alt="Imagen" src="assets/img/avatar_1.jpg" />
          </div>
          <div class="col-9 atomic_spc">
            <span class="titsans_contact_b"><strong>Julia Andaluz Rodríguez</strong></span
            ><br />
            <span class="subtgris">Escuela de Negocios</span><br />
            <span class="subtgris">Santa Fe</span>
          </div>
        </div>
      </div>
      <div class="col row_spacer">
        <span class="subtit1col"><strong>Socio Formador de interés:</strong></span
        ><br /><br />
        <div class="row">
          <div class="col-3 atomic_spc">
            <img class="img_avt_mn" alt="Imagen" src="assets/img/avatar_2.jpg" />
          </div>
          <div class="col-9 atomic_spc">
            <span class="titsans_contact_b"><strong>Armando Mendoza</strong></span
            ><br />
            <span class="subtgris">Liverpool</span><br />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col bkgatomic_alert_w">
        <div class="row">
          <div class="col data_spacer">
            <div class="row">
              <div class="col data_spacer">
                <strong>Escuela:</strong>&nbsp;{{ solicitudObj.escuela }}
              </div>
            </div>
            <div class="row">
              <div class="col data_spacer">
                <strong>Fecha de Inicio:</strong>&nbsp;{{ solicitudObj.fechaInicio }}
              </div>
            </div>
            <div class="row">
              <div class="col data_spacer">
                <strong>Duración del Reto:</strong>&nbsp;{{ solicitudObj.reto }}
              </div>
            </div>
          </div>
          <div class="col data_spacer">
            <div class="row">
              <div class="col data_spacer">
                <strong>Departamento:</strong>&nbsp;{{ solicitudObj.departamento }}
              </div>
            </div>
            <div class="row">
              <div class="col data_spacer">
                <strong>Fecha de Término:</strong>&nbsp;{{ solicitudObj.fechaTermino }}
              </div>
            </div>
            <div class="row">
              <div class="col data_spacer">
                <strong>Profesor Coordinador:</strong>&nbsp;{{ solicitudObj.coordinador }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col bkgatomic_alert_w">
        <div class="row">
          <div class="col">
            <div class="row">
              <div class="col data_spacer">
                <strong>Descripción:</strong><br /><br />
                {{ solicitudObj.decripcion }}
              </div>
            </div>
            <div class="row">
              <div class="col data_spacer">
                <strong>Entregable:</strong><br /><br />
                {{ solicitudObj.entrgable }}
              </div>
            </div>
            <div class="row">
              <div class="col data_spacer">
                <strong>Tipo de Socio Formador asignado al Reto:</strong><br /><br />
                {{ solicitudObj.reto }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <span class="subtit1col"><strong>Información del reto a vincular:</strong></span>
      <div class="col-1 container-icons" *ngFor="let infoIcon of generalInfoIcons">
        <app-icon-border [icon]="infoIcon.icon" [title]="infoIcon.title"></app-icon-border>
      </div>

      <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-3">
          <span class="#"
            ><button class="green_button">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                id="Layer_1"
                data-name="Layer 1"
                viewBox="0 0 15.2 11.06"
                class="iCn replaced-svg"
              >
                <defs>
                  <style>
                    .cls-1 {
                      fill: #4c964d;
                    }
                  </style>
                </defs>
                <path
                  class="cls-1"
                  d="M5.28,13.71a1.74,1.74,0,0,1-.64-.13,1.65,1.65,0,0,1-.54-.36L.44,9.56l1.4-1.39,3.44,3.44,9-9L15.64,4,6.47,13.22a1.69,1.69,0,0,1-.55.36A1.74,1.74,0,0,1,5.28,13.71Z"
                  transform="translate(-0.44 -2.65)"
                ></path></svg>
                Aceptar
            </button></span
          >
        </div>
        <div class="col-md-3">
          <span class="#"
            ><button class="pink_button">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                id="Layer_1"
                data-name="Layer 1"
                viewBox="0 0 12.91 12.91"
                class="iCn replaced-svg"
              >
                <defs>
                  <style>
                    .cls-1 {
                      fill: #ce0032;
                    }
                  </style>
                </defs>
                <path
                  class="cls-1"
                  d="M14.47,13.14l-1.13,1.14L8,9,2.7,14.28,1.56,13.14,6.88,7.82,1.56,2.5,2.7,1.37,8,6.69l5.32-5.32L14.47,2.5,9.15,7.82Z"
                  transform="translate(-1.56 -1.37)"
                ></path></svg>
                Rechazar
            </button></span
          ><br />
        </div>
        <div class="col-md-3"></div>
      </div>
    </div>
</div>
