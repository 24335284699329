<app-header-buscador [showSearchBar]="false"></app-header-buscador>

<div class="sec_ad">

  <app-title-show-more [showMoreObject]="{title: 'sf_solicitando_reto.titulo' | translate }"></app-title-show-more>


  <div class="row">

    <!-- Filtro de Campus -->
    <div class="col-md">
      <div class="btn-group order-by">
        <button class="btn dropdown-toggle" type="button" id="listado" data-bs-toggle="dropdown"
          data-bs-auto-close="true" aria-expanded="false" data-bs-offset="0,0"
          title="{{ filter.campus ? getValueCatalogo(campusData, 'idCampus', filter.campus, 'campus') : 'Campus' }}">
          {{ filter.campus ? getValueCatalogo(campusData, 'idCampus', filter.campus, 'campus') : 'Campus' }}
        </button>
        <ul class="dropdown-menu dropdown-menu-start" aria-labelledby="listado">
          <li *ngIf="campusData.length == 0" class="dropdown-item">Selecciona una escuela</li>
          <li *ngFor="let campus of campusData; let i = index">
            <a class="dropdown-item {{ i === 0 ? 'active' : '' }}" (click)="addFilter('campus', campus.idCampus)">{{
              campus.campus }}</a>
          </li>
        </ul>
      </div>
    </div>


    <!-- Filtro de escuela -->
    <div class="col-md">
      <div class="btn-group order-by">
        <button class="btn dropdown-toggle" type="button" id="listado" data-bs-toggle="dropdown"
          data-bs-auto-close="true" aria-expanded="false" data-bs-offset="0,0"
          title="{{ filter.escuela ? getValueCatalogo(escuelaData, 'idEscuela', filter.escuela, 'escuela') : 'Escuela' }}">
          {{ filter.escuela ? getValueCatalogo(escuelaData, 'idEscuela', filter.escuela, 'escuela') : 'Escuela' }}
        </button>
        <ul class="dropdown-menu dropdown-menu-start" aria-labelledby="listado">
          <li *ngFor="let escuela of escuelaData; let i = index">
            <a class="dropdown-item {{ i === 0 ? 'active' : '' }}" (click)="addFilter('escuela', escuela.idEscuela)">{{
              escuela.escuela }}</a>
          </li>
        </ul>
      </div>
    </div>

    <!-- Filtro de Periodo -->
    <div class="col-md">
      <div class="btn-group order-by">
        <button class="btn dropdown-toggle" type="button" id="listado" data-bs-toggle="dropdown"
          data-bs-auto-close="true" aria-expanded="false" data-bs-offset="0,0"
          title="{{ filter.periodo ? getValueCatalogo(periodoData, 'idPeriodo', filter.periodo, 'periodo') : 'Periodo' }}"
          >
          {{ filter.periodo ? getValueCatalogo(periodoData, 'idPeriodo', filter.periodo, 'periodo') : 'Periodo' }}
        </button>
        <ul class="dropdown-menu dropdown-menu-start" aria-labelledby="listado">
          <li *ngFor="let periodo of periodoData; let i = index">
            <a class="dropdown-item {{ i === 0 ? 'active' : '' }}" (click)="addFilter('periodo', periodo.idPeriodo)">{{
              periodo.periodo }}</a>
          </li>
        </ul>
      </div>
    </div>


    <!-- Reestablecer Filtros -->
    <div class="col-md d-flex justify-content-end">
      <app-button (callbackAction)="cleanFilters()"> Restablecer </app-button>
    </div>
  </div>


  <div id="sec_tab" class="nav nav-pills d-flex flex-md-nowrap flex-wrap" role="presentation">
    <div class="active" data-bs-toggle="pill" data-bs-target="#tab-retos-registrado" aria-controls="tab-retos-registrado"
      prm="re_ac" role="tab" type="button" aria-selected="true">
      {{ "sf_solicitando_reto.tab_reto_registrado" | translate }}
    </div>
    <div class="" data-bs-toggle="pill" data-bs-target="#tab-retos-aprobar" aria-controls="tab-retos-aprobar"
      prm="re_ac" role="tab" type="button" aria-selected="true">
      {{ "sf_solicitando_reto.tab_reto_por_aprobado" | translate }}
    </div>
    <div prm="re_hs" data-bs-toggle="pill" data-bs-target="#tab-retos-aprobadas" aria-controls="tab-retos-aprobadas"
      class="" role="tab" type="button" aria-selected="false">
      {{ "sf_solicitando_reto.tab_reto_aprobada" | translate }}
    </div>
    <div prm="re_hs" data-bs-toggle="pill" data-bs-target="#tab-retos-rechazadas" aria-controls="tab-retos-rechazadas"
      class="" role="tab" type="button" aria-selected="false">
      {{ "sf_solicitando_reto.tab_reto_rechazado" | translate }}
    </div>

  </div>
  <div class="tab-content" id="pills-tabContent">

    <div class="tab-pane fade show active" id="tab-retos-registrado" role="tabpanel"
      aria-labelledby="tab-retos-registrado-tab">

      <app-table [backgroundColor]="backgroundTable" [columns]="retoColumns"
        [data]="solicitudesRetosRegistradosDataPage"></app-table>

      <app-pagination *ngIf="solicitudesRetosRegistradosData.length" [objPagination]="solicitudesRetosRegistradosData"
        (paginationDataChange)="showPaginationDataRegistrado($event)"></app-pagination>

    </div>

    <div class="tab-pane fade" id="tab-retos-aprobar" role="tabpanel"
      aria-labelledby="tab-retos-aprobar-tab">

      <app-table [backgroundColor]="backgroundTable" [columns]="retoColumns"
        [data]="solicitudesRetosDataPage"></app-table>

      <app-pagination *ngIf="solicitudesRetosData.length" [objPagination]="solicitudesRetosData"
        (paginationDataChange)="showPaginationDataRevision($event)"></app-pagination>

    </div>

    <div class="tab-pane fade" id="tab-retos-aprobadas" role="tabpanel" aria-labelledby="tab-retos-aprobadas-tab">

      <app-table [backgroundColor]="backgroundTable" [columns]="retoColumns"
        [data]="solicitudesAprobadasRetosDataPage"></app-table>

      <app-pagination *ngIf="solicitudesAprobadasRetosData.length" [objPagination]="solicitudesAprobadasRetosData"
        (paginationDataChange)="showPaginationDataAprobado($event)"></app-pagination>

    </div>

    <div class="tab-pane fade" id="tab-retos-rechazadas" role="tabpanel" aria-labelledby="tab-retos-rechazadas-tab">

      <app-table [backgroundColor]="backgroundTable" [columns]="retoColumns"
        [data]="solicitudesRechazadasRetosDataPage"></app-table>

      <app-pagination *ngIf="solicitudesRechazadasRetosData.length" [objPagination]="solicitudesRechazadasRetosData"
        (paginationDataChange)="showPaginationDataRechazado($event)"></app-pagination>

    </div>
  </div>
