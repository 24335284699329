
import { IAlcance, ICampus, IIdiomas, IIndustria, IPeriodoSemanal, IPeriodoSemestral, IPresencia, IProgramaAcademico, ISector, ISemestre, ITamanoEmpresa } from "./ICatalogos.interface";

export interface IReto {
  idReto: number;
  reto: string;
  descripcion: string;
  entregable: string;
  fechaRegistro: string;
  claveEstatus: string;
  idUsuarioTec: number;
  nombreUsuarioTec: string;
  fechaSocioFormador: string;
  idUsuarioSocioFormador: number;
  idEscuela: number;
  escuela: string;
  idUnidadFormativa: number;
  unidadFormativa: string;
  idProgramaAcademico: number;
  programaAcademico: string;
  semestre: number;
  idAlcance: number;
  alcance: string;
  idRegion: number;
  region: string;
  idCampus: number;
  campus: string;
  idPeriodoAcademico: number;
  periodoAcademico: string;
  idDuracionReto: number;
  duracionReto: string;
  idIdioma: number;
  idioma: string;
  idTipoSocioFormador: number;
  tipoSocioFormador: string;
  fechaInicio: string;
  fechaFin: string;
  idSector: number;
  sector: string;
  idIndustria: number;
  industria: string;
  idTamano: number;
  tamano: string;
  palabrasClave: string;
  idRetoMaestro: number;
  idGiro: number;
  giro: string;
}

export interface IRetoAcademico {
  idReto: number;
  reto: string;
}

export interface IRetoMaestro {
  idRetoMaestro: number;
  reto: string;
  idUsuarioTec:number;
  idEscuela:number;
  escuela: string, 
  descripcion: string,
  entregable: string,
  objetivo: string, 
  idUnidadFormativa: number;
  unidadFormativa: string,  
  programasOtrasEscuelas: IProgramaAcademico[],
  programasAcademicos: IProgramaAcademico[],
  programasAcademicosOtrasEscuelas: IProgramaAcademico[],
  idSemanas: number,
  semanas: string,
  idiomas:IIdiomas[],
  periodosSemestrales: IPeriodoSemestral[],
  periodosSemanales: IPeriodoSemanal[],
  semestres: ISemestre[],
  alcances: IAlcance[],
  sectores: ISector[],
  industrias: IIndustria[] 
  tamanos: ITamanoEmpresa[],
  presencias: IPresencia[],
  descripcionSocioFormador: string, 
  urlFotoReto:string,
  palabrasClave: string,
  idCampus: number,
  campus: ICampus[],
  idAcademia: number,
  claveEstatus: string,
  sentidoHumano: boolean,
  horasServicioSocial: number,
  evaluacion: number,
  evaluadores: number
}

export class cRetoMaestro implements IRetoMaestro{
  static retoMaestroFromJSon(obj:Object){
    return new cRetoMaestro(
      obj['idRetoMaestro'],
      obj['reto'],
      obj['idUsuarioTec'],
      obj['idEscuela'],
      obj['escuela'],
      obj['descripcion'],
      obj['entregable'],
      obj['objetivo'],
      obj['idUnidadFormativa'],
      obj['unidadFormativa'],
      obj['programasOtrasEscuelas'],
      obj['programasAcademicos'],
      obj['programasAcademicosOtrasEscuelas'],
      obj['idSemanas'],
      obj['Semanas'],
      obj['idiomas'],
      obj['periodosSemestrales'],
      obj['periodosSemanales'],
      obj['semestres'],
      obj['alcances'],
      obj['sectores'],
      obj['industrias'],
      obj['tamanos'],
      obj['presencias'],
      obj['descripcionSocioFormador'],
      obj['urlFotoReto'],
      obj['palabrasClave'],
      obj['idCampus'],
      obj['campus'],
      obj['idAcademia'],
      obj['claveEstatus'],
      obj['sentidoHumano'],
      obj['horasServicioSocial'],
      obj['evaluacion'],
      obj['evaluadores'],
    );
  }

  constructor(
    public idRetoMaestro,
    public reto,
    public idUsuarioTec,
    public idEscuela,
    public escuela,
    public descripcion,
    public entregable,
    public objetivo,
    public idUnidadFormativa,
    public unidadFormativa,
    public programasOtrasEscuelas,
    public programasAcademicos,
    public programasAcademicosOtrasEscuelas,
    public idSemanas,
    public semanas,
    public idiomas,
    public periodosSemestrales,
    public periodosSemanales,
    public semestres,
    public alcances,
    public sectores,
    public industrias,
    public tamanos,
    public presencias,
    public descripcionSocioFormador,
    public urlFotoReto,
    public palabrasClave,
    public idCampus,
    public campus,
    public idAcademia,
    public claveEstatus,
    public sentidoHumano,
    public horasServicioSocial,
    public evaluacion,
    public evaluadores
  ){

  }
}

export interface IRetoResponse {
  idReto: number;
  claveEstatus: string;
}

export interface IActivacionResponse {
  idReto: number;
  idRetoMaestro: number;
  claveEstatus: string;
}

export interface IsocioMatch {
  idSocioFormador: number;
  socioFormador: string;
  matchScore: number;
  descripcion: string;
  sector: string;
  industria: string;
  presencia: string;
  tamano: string;
  urlLogoSocioFormador: string;
}
export interface IRetoVnculacion {
  idReto: number;
  sociosMatch: IsocioMatch[];
}

interface IGiro {
  idGiro: number;
  giro: string;
}

/**
 * @deprecated  Utilizar IRetoMaestroResponse
 */
export interface IRetoMaestroDetail {
  idRetoMaestro: number;
  reto: string;
  descripcion: string;
  entregable: string;
  fechaRegistro: string;
  idUsuarioTec: number;
  idSector: number;
  idIndustria: number;
  idTamano: number;
  palabrasClave: string;
  claveEstatus: string;
  idEscuela: number;
  idProgramaAcademico: number;
  idUnidadFormativa: number;
  semestre: number;
  idIdioma: number;
  urlFotoReto: string;
  empresaFamiliar: boolean;
  idAlcance: number;
  idSemanas: number;
  estatus: string;
  usuarioTec: string;
  escuela: string;
  unidadFormativa: string;
  idioma: string;
  sector: string;
  industria: string;
  tamano: string;
  programaAcademico: string;
  alcance: string;
  semanas: string;
  giros: IGiro[];
}
export class RetoMaestroDetail implements IRetoMaestroDetail{
  idRetoMaestro: number;
  reto: string;
  descripcion: string;
  entregable: string;
  fechaRegistro: string;
  idUsuarioTec: number;
  idSector: number;
  idIndustria: number;
  idTamano: number;
  palabrasClave: string;
  claveEstatus: string;
  idEscuela: number;
  idProgramaAcademico: number;
  idUnidadFormativa: number;
  semestre: number;
  idIdioma: number;
  urlFotoReto: string;
  empresaFamiliar: boolean;
  idAlcance: number;
  idSemanas: number;
  estatus: string;
  usuarioTec: string;
  escuela: string;
  unidadFormativa: string;
  idioma: string;
  sector: string;
  industria: string;
  tamano: string;
  programaAcademico: string;
  alcance: string;
  semanas: string;
  giros: IGiro[];

  constructor(){
    this.idRetoMaestro=0;
    this.reto='';
    this.descripcion='';
    this.entregable='';
    this.fechaRegistro='';
    this.idUsuarioTec = 0;
    this.idSector = 0;
    this.idIndustria = 0;
    this.idTamano = 0;
    this.palabrasClave='';
    this.claveEstatus='';
    this.idEscuela = 0;
    this.idProgramaAcademico = 0;
    this.idUnidadFormativa = 0;
    this.semestre = 0;
    this.idIdioma = 0;
    this.urlFotoReto='';
    this.empresaFamiliar = false;
    this.idAlcance = 0;
    this.idSemanas = 0;
    this.estatus='';
    this.usuarioTec='';
    this.escuela='';
    this.unidadFormativa='';
    this.idioma='';
    this.sector='';
    this.industria='';
    this.tamano='';
    this.programaAcademico='';
    this.alcance='';
    this.semanas='';
    this.giros = [];
  }
}


export interface IRetosMatch{
  idBusqueda: number;
  fhRegistro: string;
  idUsuario: number;
  parametros: string;
  porcentajeMatch: number;
  idSocioFormador: number;
  idReto: number;
}

export interface ICartaColaboracion{
  idColaboracion:number,
  idSocioFormador:number,
  idReto:number,
  urlCartaColaboracion: string,
  paso1Firma: boolean,
  paso2Firma: boolean,
  idUsuarioPaso1Firma: number,
  idUsuarioPaso2Firma: number,
  nombreFirmante: string

}