import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-card-socio-search',
  templateUrl: './card-socio-search.component.html',
  styleUrls: ['./card-socio-search.component.css'],
})
export class CardSocioSearchComponent  {
  @Input() objCompany: any;
  
}
