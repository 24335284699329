import { Component, OnInit } from '@angular/core';
import { RetosService } from '@shared/service/Retos/retos.service';
import { tap } from 'rxjs';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { cRetosResponse } from '@shared/Interface/IRetosResponse';
import { RetoMaestroDetail } from '@shared/Interface/IReto.interface';

@Component({
  selector: 'app-reto-resumen',
  templateUrl: './reto-resumen.component.html',
  styleUrls: ['./reto-resumen.component.css'],
})
export class RetoResumenComponent implements OnInit {
  retoResponse: any;
  titleVinculacion = {
    title: `Registra un nuevo reto `,
  };
  retoData: any;

  constructor(
    private retosServices: RetosService,
    private router: Router,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    const retoStorage: any = localStorage.getItem('newReto');
    this.retoResponse = JSON.parse(retoStorage);
    if (this.retoResponse.idReto) {
      this.getReto(this.retoResponse.idReto);
      this.titleVinculacion = {
        title: `Registra un nuevo reto programado`,
      };
    } else if (this.retoResponse.idRetoMaestro) {
      this.getRetoMaestro(this.retoResponse.idRetoMaestro);
      this.titleVinculacion = {
        title: `Registra un nuevo reto semilla`,
      };
    }
  }

  getReto(idReto: any) {
    this.retoData = new cRetosResponse();
    this.retosServices
      .getRetos_idReto(idReto)
      .pipe(tap((response) => (this.retoData = response)))
      .subscribe();
  }

  getRetoMaestro(idRetoMaestro: any) {
    this.retoData = new RetoMaestroDetail();
    this.retosServices
      .getRetoMaestroDetail(idRetoMaestro)
      .pipe(tap((response) => (this.retoData = response)))
      .subscribe();
  }

  getJoinArray(campus: any, type: string) {
    return campus.map((camp: any) => camp[type]).join(',');
  }

  updateReto() {
    this.router.navigate(['gestion-reto']);
  }

  createReto() {
    this.retosServices
      .putActiveReto({
        ...this.retoResponse,
        claveEstatus: 'activo',
      })
      .subscribe((resultado: any) => {
        if (resultado) {
          localStorage.removeItem('newReto');
          localStorage.setItem('idReto',resultado.idReto);
          this.toastr.success('Reto Activado Correctamente!');
          this.router.navigate(['reto-registrado']);
        } else {
          this.toastr.error('Algo salió mal!');
        }
      });
  }
}
