<app-header [MostrarBotonInicio]="false"></app-header>

<div class="container">
    <button class="dashboard_btn d-md-block" (click)="regresa()">
        <svg
          width="12"
          height="24"
          viewBox="0 0 12 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.98157 24L0.968321 14.48C0.348159 13.8228 0 12.9333 0 12.006C0 11.0787 0.348159 10.1892 0.968321 9.532L9.992 0L12 2.121L2.97632 11.652C2.88746 11.7458 2.83753 11.8729 2.83753 12.0055C2.83753 12.1381 2.88746 12.2652 2.97632 12.359L11.9886 21.879L9.98157 24Z"
            fill="#4A6BB6"
          />
        </svg>
        <span>&nbsp;&nbsp;&nbsp;Regresar</span>
      </button>
      <app-title-show-more [showMoreObject]="{title: 'Preguntas Frecuentes'}"></app-title-show-more>

      <div class="row sec_pregunta">
        <div class="col-12 pregunta">Pregunta A</div>
        <div class="col-12 respuesta">Respuesta A</div>
      </div>
      <div class="row sec_pregunta">
        <div class="col-12 pregunta">Pregunta B</div>
        <div class="col-12 respuesta">Respuesta B</div>
      </div>
      <div class="row sec_pregunta">
        <div class="col-12 pregunta">Pregunta C</div>
        <div class="col-12 respuesta">Respuesta C</div>
      </div>

    <app-footer></app-footer>
</div>