<div class="sec_ad">
    <div class="d-flex align-items-md-stretch flex-wrap flex-md-nowrap sec_cnt">
      <div class="offset-1 col-md-10">
        <app-title-show-more
          [showMoreObject]="titleGestion"
        ></app-title-show-more>
        <div
          id="sec_tab"
          class="nav nav-pills d-flex flex-md-nowrap flex-wrap"
          role="presentation"
        >
          <div
            class="active"
            data-bs-toggle="pill"
            data-bs-target="#mis-datos"
            aria-controls="mis-datos"
            prm="re_ac"
            role="tab"
            type="button"
            aria-selected="true"
          >
            Socios Formadores
          </div>
          <div
            prm="re_hs"
            data-bs-toggle="pill"
            data-bs-target="#datos-contacto"
            aria-controls="datos-contacto"
            class=""
            role="tab"
            type="button"
            aria-selected="false"
          >
            Academia
          </div>
          <div
            prm="re_hs"
            data-bs-toggle="pill"
            data-bs-target="#datos-empresa"
            aria-controls="datos-empresa"
            class=""
            role="tab"
            type="button"
            aria-selected="false"
          >
            CVDP
          </div>
          <div class="flex-fill">
            <div class="d-flex justify-content-end">
              <div class="btn-group order-by">
                <button
                  class="btn dropdown-toggle"
                  type="button"
                  id="listado"
                  data-bs-toggle="dropdown"
                  data-bs-auto-close="true"
                  aria-expanded="false"
                  data-bs-offset="0,0"
                >
                  Ordenar por
                </button>
                <ul
                  class="dropdown-menu dropdown-menu-end"
                  aria-labelledby="listado"
                >
                  <li><a class="dropdown-item active" href="#">Nombre A-Z</a></li>
                  <li class="">
                    <a class="dropdown-item" href="#">Socio Formador</a>
                  </li>
                  <li class="visually-hidden">
                    <a class="dropdown-item" href="#">Campus</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-content" id="pills-tabContent">
          <div
            class="tab-pane fade show active"
            id="mis-datos"
            role="tabpanel"
            aria-labelledby="mis-datos-tab"
          >
            <app-socios-tab></app-socios-tab>
          </div>
          <div
            class="tab-pane fade show"
            id="datos-contacto"
            role="tabpanel"
            aria-labelledby="datos-contacto-tab"
          >
            MC
          </div>
          <div
            class="tab-pane fade show"
            id="datos-empresa"
            role="tabpanel"
            aria-labelledby="datos-empresa-tab"
          >
            Mi Empresa
          </div>
        </div>
      </div>
    </div>
</div>
