import { Injectable } from '@angular/core';
import { BlobServiceClient, BlobItem } from '@azure/storage-blob';


/*
AzureBlob_ACCOUNT_NAME:'mvretosstorage',
  AzureBlob_SAS:'sp=rawdl&st=2022-09-22T00:44:16Z&se=2025-09-22T08:44:16Z&spr=https&sv=2021-06-08&sr=c&sig=ysau9yJ88xq5UQsLx9N3LymISX6UgX4LUKx23sPk1z8%3D',
  AzureBlob_containerNameSocio: 'mvpretos-socios-logos',
*/

// https://mvretosstorage.blob.core.windows.net/mvpretos-socios-logos?sp=rawdl&st=2022-09-22T00:44:16Z&se=2025-09-22T08:44:16Z&spr=https&sv=2021-06-08&sr=c&sig=ysau9yJ88xq5UQsLx9N3LymISX6UgX4LUKx23sPk1z8%3D

export interface BLOBItem extends BlobItem {}

export interface FILEAZURECONTENT {
  containerName: string; // desired container name
  file: any; // file to upload
  filename: string; // filename as desired with path
}

@Injectable({
  providedIn: 'root',
})

export class AzureBlobStorageService{
  public async deleteBlob(containerName: string, filename: string, accountName:string, accountKey: string) {
    // BlobClientServiceString
    const blobServiceClient = new BlobServiceClient(`https://${accountName}.blob.core.windows.net/${accountKey}`);

    const containerClient = blobServiceClient.getContainerClient(containerName);
    const blockBlobClient = containerClient.getBlockBlobClient(filename.toLowerCase());
    const deleteBlob = await blockBlobClient.delete();
    return `Deleted Blob ${filename} successfully ${deleteBlob.requestId}`;
  }

  public async uploadFile(content: FILEAZURECONTENT, accountName:string, accountKey: string) {
    // BlobClientServiceString

    const blobServiceClient = new BlobServiceClient(`https://${accountName}.blob.core.windows.net/${accountKey}`);

    const containerClient = blobServiceClient.getContainerClient(content.containerName);
    const blockBlobClient = containerClient.getBlockBlobClient(content.filename.toLowerCase());
    const uploadBlobResponse = await blockBlobClient.uploadData(
      content.file,
      {
        maxSingleShotSize: 4 * 1024 * 1024,
        blobHTTPHeaders: { blobContentType: content.file.type }, // set mimetype
      }
    );
    return `Upload block blob ${content.filename} successfully ${uploadBlobResponse.requestId}`;
  }

}


