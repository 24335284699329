import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { baseUrl } from '../routesServices';
import { IGestionNotificacionListadoResponse, IGestionNotificacionRequest, IGestionNotificacionUsuarioResponse } from '@shared/Interface/INotification';

@Injectable({
  providedIn: 'root'
})
export class NotificacionesService {

  constructor(private http: HttpClient) { }

  getByIdUsuario(idUsuario:number):Observable<IGestionNotificacionUsuarioResponse[]>{
    return this.http.get<IGestionNotificacionUsuarioResponse[]>(`${baseUrl}administracion/notificaciones/usuarios/${idUsuario}`);
  }

  postRegistraExcepcion(body: IGestionNotificacionRequest){
    return this.http.post(`${baseUrl}administracion/notificaciones/usuarios`, body);
  }
  
  deleteRegistraExcepcion(request){
    let options = {
      body: request
    };
    return this.http.delete(`${baseUrl}administracion/notificaciones/usuarios`, options);
  }

  getNotificacionesByPerfil(cvePerfil: string):Observable<IGestionNotificacionListadoResponse[]>{
    return this.http.get<IGestionNotificacionListadoResponse[]>(`${baseUrl}administracion/notificaciones/perfiles/${cvePerfil}`);

  }

}
