<div class="card-body">
  <img class="imgcarrousel_match" alt="Imagen Reto" src="{{ retoObj?.urlFotoReto ?? 'assets/img/img_reto_fullsize.jpg' }}" />
  <div class="card_container position-relative">
    <div class="row_spacer"></div>
    <div class="row">
      <div class="">
        <div class="row progwidth">
          <div class="col contmatch">
            <span class="matchgde">{{ retoObj.matchScore }}% Match</span>
          </div>
          <div class="progress" style="height: 16px">
            <div class="progress-bar bg-info" role="progressbar" attr.style="width: {{ retoObj.matchScore }}%"
              attr.aria-valuenow="{{ retoObj.matchScore }}" aria-valuemin="0" aria-valuemax="100"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="row_spacer"></div>
    <div class="row">
      <div class="col subtitlecard">
        <span class="subtitlecard" title="{{ retoObj.reto }}">{{ retoNombre }}</span><br />
        <span class="subtitleseccard"> {{ retoObj.escuela }}</span><br />
      </div>
    </div>
    <div class="row_spacer"></div>
    <div class="row">
      <div class="col">

      </div>
    </div>
    <div class="row_spacer"></div>
    <div class="row position-absolute bottom-10 ">
      <div class="col clearfix">
        <div>
          <app-chip-item *ngIf="retoObj.descripcion" [icon]="'icon_chip_carrera'" [placeholder]="retoObj.descripcion" [title]="retoDescripcion">
          </app-chip-item>
        </div>
        <div>
          <app-chip-item *ngIf="retoObj.semestres" [icon]="'icon_chip_semestre'" [placeholder]="semestres" [title]="'Semestres: ' +semestres">
          </app-chip-item>
        </div>
        <div>
          <app-chip-item *ngIf="false" [icon]="'icon_chip_idiomas'" [placeholder]="idiomas" [title]="idiomas">
          </app-chip-item>
        </div>
      </div>
    </div>
    <div class="row_spacer"></div>
    <div class="row position-absolute bottom-0 start-50 translate-middle-x">
      <div class="col">
        <span class="#">
          <app-button buttonType="yell" (callbackAction)="callbackJoinReto(retoObj)">Más información</app-button>
        </span>
      </div>
    </div>
    <div class="row_spacer"></div>
  </div>
</div>