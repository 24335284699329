<app-header-buscador [showSearchBar]="false"></app-header-buscador>

<app-typeform idForm="lY2Q6SnV"></app-typeform>

<div class="sec_ad">
    <div class="row">
      <div class="col-md-7 order-1 order-md-0">
        <div id="sec_tit">
          <div class="d-flex align-items-center">
            <span class="subtit1col">HEMOS RECIBIDO TU SOLICITUD</span>
          </div>
          <app-title-show-more [showMoreObject]="titleConfirmReto"></app-title-show-more>
          <div class="flex-fill">
          </div>
          <br />
        </div>
        <div class="d-flex align-items-center">
          <span class="subtit1col_b"></span
          ><br /><br />
        </div>
        <div class="d-flex align-items-center">
          Una vez que el Centro de Vinculación revise tu solicitud, te hará llegar por este medio el contacto de la organización para que entablen comunicación.
          <br/><br/>En breve nos pondremos en contacto contigo.
          <!--A la brevedad recibirás un correo a la cuenta que tienes registrada.-->
          <br /><br /><br />
          <br /><br /><br />
        </div>
        <div class="row">
          <div class="col-md">
          <app-button extraClass="" (callbackAction)="irAmisSolicitudes()" id="btnIrSolicitudes">Ir a mis solicitudes</app-button>
          </div>
          &nbsp;
        </div>
      </div>
      <div class="col-md-5" *ngIf="false">
        <img class="img_hdr" alt="Logotipo"  src="{{ imgUrlSocio||'https://mvretosstorage.blob.core.windows.net/mvpretos-socios-logos/socio_1.jpg' }}" />
      </div>
    </div>
</div>
